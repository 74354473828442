import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import clsx from "clsx";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import MomentUtils from "@date-io/moment";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const useStyle = makeStyles(theme => ({
    root: {
        '& .MuiInputBase-input.Mui-disabled': {
            color: theme.colors.palette.neutral.greyMain
        },
    },
    error: {
        '& .MuiInputBase-input': {
            color: theme.colors.palette.corporate.redMain
        }
    },
    active: {
        '& .MuiPickersBasePicker-pickerView': {
            display: 'none'
        }
    }
}))

function CustomTimePicker({
                              initialDate,
                              onSubmit,
                              label,
                              autoOk,
                              minutesStep,
                              ampm,
                              showNowButton,
                              disabled,
                              error,
                              staticVariant,
                              disableToolbar,
                              submitOnChange,
                              ...props
                          }) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);
    const [minutesSet, setMinutesSet] = useState(false)

    const [selectedDate, handleDateChange] = useState(moment(initialDate));
    const [mouseState, setMouseState] = useState(0);

    useEffect(() => {
        handleDateChange(moment(initialDate))
    }, [initialDate])

    document.body.onmousedown = function () {
        setMouseState(mouseState + 1);
    }
    document.body.onmouseup = function () {
        setMouseState(mouseState - 1);
    }

    return (
        <div className={clsx(classes.root, props.active && classes.active)}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
                <KeyboardTimePicker
                    className={clsx(classes.root, error && classes.error)}
                    autoOk={autoOk}
                    showTodayButton={showNowButton}
                    ampm={ampm}
                    todayLabel={t('now')}
                    label={label}
                    value={selectedDate}
                    placeholder={'HH:mm'}
                    minutesStep={minutesStep}
                    onChange={(time) => {
                        handleDateChange(time)
                        if (submitOnChange && time && time.isValid()) {
                            onSubmit(time)
                        }
                    }}
                    onAccept={(time) => {
                        if (mouseState === 0) {
                            setMinutesSet(!minutesSet)
                            onSubmit(time, minutesSet)
                        } else {
                            onSubmit(time)
                        }
                    }}
                    disabled={disabled}
                    disableToolbar={disableToolbar}
                    InputProps={{className: classes.root}}
                    variant={staticVariant ? 'static' : 'dialog'}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}

CustomTimePicker.propTypes = {
    initialDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment)
    ]),
    onSubmit: PropTypes.func.isRequired,
    label: PropTypes.string,
    autoOk: PropTypes.bool,
    ampm: PropTypes.bool,
    minutesStep: PropTypes.number,
    showNowButton: PropTypes.bool,
    disabled: PropTypes.bool,
    textColor: PropTypes.string,
    error: PropTypes.bool,
    staticVariant: PropTypes.bool,
    disableToolbar: PropTypes.bool,
    //if submit should be fired onChange too
    submitOnChange: PropTypes.bool,
}

CustomTimePicker.defaultProps = {
    initialDate: new Date(),
    autoOk: false,
    ampm: false,
    minutesStep: 5,
    showNowButton: false,
    staticVariant: false,
    submitOnChange: false
}

export default CustomTimePicker