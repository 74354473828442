import * as THREE from 'three'
import {SVGLoader} from "three/examples/jsm/loaders/SVGLoader.js";
import {createMarker, drawingSize, getDrawingTypesMaterial, getTextGeometry} from './drawConstants'

function DrawCurrentHereMarker() {
    THREE.Mesh.apply(this, arguments)
    this.loader = new SVGLoader()

    this.init = function (position, radius, text) {
        this.reInit(position, radius, text)
    }

    this.reInit = function (position, radius = drawingSize.currentHereMarker, text) {

        // const material = new THREE.MeshNormalMaterial()
        // let svgPath = '<svg width="3" height="4" viewBox="0 0 36 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9998 0C8.5198 0 .8398 7.68.8398 17.16.8398 30.9 17.9998 48 17.9998 48 17.9998 48 35.1598 30.9 35.1598 17.16 35.1498 7.68 27.4698 0 17.9998 0ZM17.9998 24.92C13.7098 24.92 10.2298 21.44 10.2298 17.15 10.2298 12.86 13.7098 9.38 17.9998 9.38 22.2898 9.38 25.7698 12.86 25.7698 17.15 25.7698 21.44 22.2898 24.92 17.9998 24.92Z" fill="#A47ECD"/></svg>'
        //
        // this.markerMesh = new THREE.Group();
        // const svgData = this.loader.parse(svgPath)
        //
        // console.log('svg data', svgData, Vector)
        //
        // svgData.paths.forEach((path, i) => {
        //     const shapes = path.toShapes(true);
        //
        //     // Each path has array of shapes
        //     shapes.forEach((shape, j) => {
        //         // Finally we can take each shape and extrude it
        //         const geometry = new THREE.ShapeGeometry(shape);
        //         geometry.scale(.3, .3, .3)
        //
        //         // Create a mesh and add it to the group
        //         const mesh = new THREE.Mesh(geometry, material);
        //         // mesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))
        //         this.markerMesh.add(mesh);
        //     });
        // });
        //
        // console.log(this.markerMesh)

        const {material} = getDrawingTypesMaterial('currentHereMarker')
        this.markerMesh = createMarker(radius, material, material)

        let textGeometry = getTextGeometry('Dein Standort', .24);
        let textMesh = new THREE.Mesh(textGeometry, material);
        textMesh.geometry.center()
        textMesh.position.set(0, -.6, 0)

        this.markerMesh.add(textMesh)

        this.position.set(position.x, position.y, 0.05)

        this.add(this.markerMesh)
    }

    this.updatePosition = function (position) {
        this.position.set(position.x, 0.05, position.z)
    }

    this.getGeometryAsJSON = function () {
        return {
            geometryId: this.geometryId,
            vertices: [{
                x: this.position.x,
                y: -this.position.z
            }],
            radius: this.radius,
            parentElementId: this.parentElementId,
            geometryType: this.geometryType
        }
    }

    this.setGeometryFromJSON = function (json) {
        this.parentElementId = json.parentElementId
        this.geometryId = json.geometryId
        this.geometryType = json.geometryType
        const vertices = json.vertices
        let flippedVertex = []
        vertices.forEach(vertex => flippedVertex = [vertex.x, -vertex.y])

        this.reInit(new THREE.Vector3(flippedVertex[0], 0.05, flippedVertex[1]), json.radius)
    }

}

DrawCurrentHereMarker.prototype = Object.create(THREE.Mesh.prototype)
DrawCurrentHereMarker.prototype.constructor = DrawCurrentHereMarker
export {DrawCurrentHereMarker}