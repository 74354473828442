import React, {useEffect, useState} from 'react';
import {createWorkingGroup,} from "../../actions/workingGroups-action";
import {connect} from "react-redux";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import {navigate, PATH_WORKING_GROUP, PATH_WORKING_GROUPS} from "../../common/utils/NavigationUtils";
import CustomTextField from "../CustomTextField";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import StaticBottomSheet from "../../common/elements/StaticBottomSheet.js";
import CustomButton from "../CustomButton.js";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import SelectPersonsWithModal from "../people/SelectPersonsWithModal.js";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap,
    },
}))

function WorkingGroupCreation({user, personGroups, createWorkingGroup, createGroupPending}) {

    const theme = useTheme()
    const history = useHistory()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const [groupName, setGroupName] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);

    useEffect(() => {
        if (!personGroups)
            routeToWorkingGroups()
        // eslint-disable-next-line
    }, []);

    useDidMountEffect(() => {
        if (user)
            setSelectedMembers([user])
        // eslint-disable-next-line
    }, [user]);

    const routeToWorkingGroups = () => {
        navigate(history, PATH_WORKING_GROUPS)
    }

    function onCreateGroup() {
        createWorkingGroup(groupName, selectedMembers)
            .then((result) => {
                    const createdGroupId = result.value.data.id
                    setSelectedMembers(null)
                    navigate(history, PATH_WORKING_GROUP + createdGroupId)
                }
            )
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <HeaderWithTitleAndBackButton title={t('create_working_group')} backLabel={t("working_groups")}
                                              onNavigateBack={routeToWorkingGroups}/>
                <div className={classes.content}>
                    <CustomTextField title={t('name')}
                                     placeholder={t('working_group_name_placeholder')}
                                     onChange={(event) => setGroupName(event.target.value)}
                                     value={groupName}
                                     autoFocus/>
                    <SelectPersonsWithModal onConfirmCallback={(persons) => {
                        if (persons)
                            setSelectedMembers(persons)
                    }}
                                            initialSelectedPersons={selectedMembers}/>
                </div>
            </div>
            <StaticBottomSheet>
                <CustomButton text={t('create_group')}
                              disabled={selectedMembers == null || !groupName}
                              primary
                              onClick={onCreateGroup}
                              isLoading={createGroupPending}/>
            </StaticBottomSheet>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.person,
        personGroups: state.workingGroups.personGroups,
        createGroupPending: state.workingGroups.createGroupPending
    }
}

const mapDispatchToProps = {
    createWorkingGroup: createWorkingGroup,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkingGroupCreation)
