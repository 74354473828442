import * as THREE from 'three'
import {
    createMarker,
    drawingSegments,
    drawingSize,
    drawingTypes,
    getCircleGeometry,
    getDrawingTypesIconMaterial,
    getDrawingTypesMaterial,
    getIcon,
    threeMaterial
} from './drawConstants'

function DrawMarker() {
    THREE.Mesh.apply(this, arguments)

    this.init = function (position, drawingRepresentationType, radius = null) {
        this.reInit(position, drawingRepresentationType, radius)
    }

    this.reInit = function (position, drawingRepresentationType, radius = null) {
        this.drawingRepresentationType = drawingRepresentationType

        const {material, innerMaterial} = getDrawingTypesMaterial(this.drawingRepresentationType)
        this.radius = radius ? radius : this.getRadius()

        this.markerMesh = drawingRepresentationType === drawingTypes.pillar ?
            createMarker(this.radius, material, innerMaterial, drawingSegments.pillar) :
            createMarker(this.radius, material, innerMaterial)

        const {activeMaterial} = getDrawingTypesIconMaterial(this.drawingRepresentationType)

        this.iconMesh = getIcon(activeMaterial, drawingSize.iconMarkerSize, drawingSize.iconMarkerSize)
        this.iconMesh.position.set(0, 0.01, 0)

        this.position.set(position.x, 0.05, position.z)

        this.add(this.iconMesh)
        this.add(this.markerMesh)
    }

    this.updatePosition = function (position) {
        this.position.set(position.x, 0.05, position.z)
    }

    this.increaseRadius = function (delta) {
        if (this.radius + delta > drawingSize.markerMinSize) {
            this.radius += delta
            this.markerMesh.geometry.dispose()
            this.markerMesh.geometry = this.drawingRepresentationType === drawingTypes.pillar ? getCircleGeometry(this.radius, drawingSegments.pillar) : getCircleGeometry(this.radius)
        }
    }

    this.getRadius = function () {
        let radius = 0

        switch (this.drawingRepresentationType) {
            case drawingTypes.door:
                radius = drawingSize.door
                break
            case drawingTypes.window:
                radius = drawingSize.window
                break
            case drawingTypes.pillar:
                radius = drawingSize.pillar
                break
            default:
                break
        }

        return radius
    }

    this.updateRadius = function (radius) {
        this.radius = radius
        this.markerMesh.geometry.dispose()
        this.markerMesh.geometry = this.drawingRepresentationType === drawingTypes.pillar ? getCircleGeometry(this.radius, drawingSegments.pillar) : getCircleGeometry(this.radius)
    }

    this.setPlaceable = function (placeable) {
        if (this.markerMesh) {
            const {material, innerMaterial} = getDrawingTypesMaterial(this.drawingRepresentationType)

            this.markerMesh.material = placeable ? material : threeMaterial.disabledMarker
            if (this.markerMesh.children.length > 0)
                this.markerMesh.children[0].material = placeable ? innerMaterial : threeMaterial.disabledMarkerInner
        }
    }

    this.setActive = function (active) {
        if (this.markerMesh) {
            const {material, innerMaterial} = getDrawingTypesMaterial(this.drawingRepresentationType)

            this.markerMesh.material = active ? innerMaterial : material
        }
    }

    this.getGeometryAsJSON = function () {
        return {
            geometryId: this.geometryId,
            drawingRepresentationType: this.drawingRepresentationType,
            vertices: [{
                x: this.position.x,
                y: -this.position.z
            }],
            radius: this.radius,
            isValidate: this.isValidate,
            error: this.errorMessage,
            parentElementId: this.parentElementId,
            geometryType: this.geometryType
        }
    }

    this.setGeometryFromJSON = function (json) {
        this.drawingRepresentationType = json.drawingRepresentationType
        this.isValidate = json.isValidate
        this.errorMessage = json.error
        this.parentElementId = json.parentElementId
        this.geometryId = json.geometryId
        this.geometryType = json.geometryType
        const vertices = json.vertices
        let flippedVertex = []
        vertices.forEach(vertex => flippedVertex = [vertex.x, -vertex.y])

        this.reInit(new THREE.Vector3(flippedVertex[0], 0.05, flippedVertex[1]), json.drawingRepresentationType, json.radius)
    }

}

DrawMarker.prototype = Object.create(THREE.Mesh.prototype)
DrawMarker.prototype.constructor = DrawMarker
export {DrawMarker}