const initialState = {
    vacationList: [],
    assignVacationPending: false
};

function vacationReducer(state = initialState, action) {

    switch (action.type) {

        case 'FETCH_VACATION_FULFILLED': {
            return {
                ...state,
                vacationList: action.payload.data
            }
        }

        case 'CREATE_VACATION_PENDING':
            return {
                ...state,
                assignVacationPending: true
            }
        case 'CREATE_VACATION_REJECTED':
            return {
                ...state,
                assignVacationPending: false
            }
        case 'CREATE_VACATION_FULFILLED': {
            let vacations = [...state.vacationList, action.payload.data]

            return {
                ...state,
                vacationList: vacations,
                assignVacationPending: false
            }
        }
        case 'DELETE_WORKPLACE_ASSIGNMENT_FULFILLED': {
            let deletedAssignments = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data]
            deletedAssignments = deletedAssignments.map(a => a.id)
            
            let vacations = [...state.vacationList].filter(vacation => !deletedAssignments.includes(vacation.assignmentId))

            return {
                ...state,
                vacationList: vacations,
                assignVacationPending: false
            }
        }

        default:
            return {...state}
    }

}

export default vacationReducer;