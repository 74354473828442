import React, {useState} from 'react';

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "../../CustomTextField.js";
import PasswordRequirements from "../../profile/PasswordRequirements.js";
import {getEndAdornment, isPasswordValid} from "../../../common/utils/PasswordAndEmailUtils.js";
import queryString from "query-string";
import CustomButton from "../../CustomButton.js";


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    },
    container: {
        minHeight: `calc(100% - ${theme.staticBottomActionSheet.height})`,
        padding: theme.paddingContentContainer.padding,
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        paddingTop: '2.4rem',
    },
    icon: {
        stroke: theme.colors.palette.neutral.darkMain
    },
    textFieldHidden: {
        height: 0,
        visibility: 'hidden'
    }
}));

function InitialPassword() {

    const theme = useTheme()
    const {t} = useTranslation()
    const history = useHistory()
    const classes = useStyle(theme);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

    const initialMail = queryString.parse(history.location.search).mail
    const initialOldPassword = queryString.parse(history.location.search).temporary
    const [mail, setMail] = useState(initialMail || '');
    const [oldPass, setOldPass] = useState(initialOldPassword || '');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

    function submitEnabled() {
        return isPasswordValid(newPassword) && newPasswordConfirmation === newPassword && mail && oldPass
    }

    return (
        <Paper className={classes.root}>
            <form className={classes.container} method={'POST'}
                  action={'/api/person/initialPassword'}

            >
                <div className={classes.content}>
                    <CustomTextField title={t('password')}
                                     value={oldPass}
                                     autoComplete={false}
                                     wrapperClassName={!initialOldPassword ? '' : classes.textFieldHidden}
                                     min={8}
                                     name={'currentPassword'}
                                     type={'password'}
                                     onChange={e => {
                                         e.preventDefault();
                                         const {value} = e.target;
                                         setOldPass(value)
                                     }}
                    />

                    <CustomTextField title={t('email')}
                                     value={mail}
                                     wrapperClassName={!initialMail ? '' : classes.textFieldHidden}
                                     autoComplete={false}
                                     type={'email'}
                                     name={'userMail'}
                                     hidden={true}
                                     onChange={e => {
                                         e.preventDefault();
                                         const {value} = e.target;
                                         setMail(value)
                                     }}
                    />

                    <CustomTextField title={t('new_password')}
                                     value={newPassword}
                                     autoComplete={false}
                                     type={showNewPassword ? 'text' : 'password'}
                                     onChange={e => {
                                         e.preventDefault();
                                         const {value} = e.target;
                                         setNewPassword(value)
                                     }}
                                     name={'newPassword'}
                                     error={!isPasswordValid(newPassword) && newPassword !== ''}
                                     contentRight={getEndAdornment(showNewPassword, setShowNewPassword, classes.icon)}
                    />

                    <CustomTextField title={t('confirm_new_password')}
                                     autoComplete={false}
                                     value={newPasswordConfirmation}
                                     type={showNewPasswordConfirmation ? 'text' : 'password'}
                                     onChange={e => {
                                         e.preventDefault();
                                         const {value} = e.target;
                                         setNewPasswordConfirmation(value)
                                     }}
                                     error={newPasswordConfirmation !== '' && newPasswordConfirmation !== newPassword}
                                     contentRight={getEndAdornment(showNewPasswordConfirmation, setShowNewPasswordConfirmation, classes.icon)}
                    />
                    <PasswordRequirements password={newPassword} passwordConfirmation={newPasswordConfirmation}/>
                    <CustomButton text={t('set_password')} primary
                                  disabled={!submitEnabled()}
                                  type={'submit'}
                                  isLoading={false}
                    />
                </div>
            </form>
        </Paper>
    );
}

export default InitialPassword;