import AvatarEditor from "react-avatar-editor";
import SliderZoomMinusSvgIcon from "../../common/icons/SliderZoomMinusSvgIcon.js";
import {makeStyles, Slider, useTheme} from "@material-ui/core";
import SliderZoomPlusSvgIcon from "../../common/icons/SliderZoomPlusSvgIcon.js";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
    root: {},
    zoomSlider: {
        color: theme.colors.palette.corporate.skinMain,
        marginLeft: '7px',
        marginRight: '7px'
    },
    sliderSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
}))

function CustomImageEditor({image, editor, setEditor, initialScaleValue}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    const setEditorRef = (editor1) => setEditor(editor1)

    const [scaleValue, setScaleValue] = useState(initialScaleValue)

    useEffect(() => {
        setScaleValue(initialScaleValue)
    }, [initialScaleValue]);


    const onChangeScaleValue = (value) => {
        if (scaleValue + value < -0.4 || scaleValue + value > 4) {
            return
        }
        setScaleValue(scaleValue + value)
    }

    return (
        <div>
            <AvatarEditor
                ref={setEditorRef}
                image={image}
                width={305}
                height={305}
                border={0}
                borderRadius={250}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scaleValue}
                rotate={0}
            />
            <div className={classes.sliderSection}>
                <SliderZoomMinusSvgIcon
                    onClick={() => onChangeScaleValue(-0.5)}/>
                <Slider className={classes.zoomSlider}
                        onChange={(e, value) => setScaleValue(value)}
                        aria-labelledby="discrete-slider"
                        step={0.2}
                        min={0.4}
                        max={4}
                        value={scaleValue}
                />
                <SliderZoomPlusSvgIcon onClick={() => onChangeScaleValue(+0.5)}/>
            </div>
        </div>
    )
}

CustomImageEditor.propTypes = {
    image: PropTypes.any.isRequired,
    editor: PropTypes.any,
    initialScaleValue: PropTypes.number
}

export default CustomImageEditor