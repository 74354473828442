import * as React from "react"
import PropTypes from "prop-types";

function SearchSvgIcon(props) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
            <path
                d="M10.5 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM14.684 15.523l5.816 6.199"
                stroke={props.stroke}
                strokeWidth={props.strokeWidth}
                fill="none"
                strokeMiterlimit={10}
            />
        </svg>
    )
}

SearchSvgIcon.propTypes = {
    size: PropTypes.number,
    stroke: PropTypes.string,
    strokeWidth: PropTypes.number
}

SearchSvgIcon.defaultProps = {
    size: 24,
    stroke: '#333',
    strokeWidth: 1
}

export default SearchSvgIcon
