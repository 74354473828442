import React from 'react';

import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '50%'
    },
    inline: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
        padding: '0 1rem',
        boxSizing: 'border-box'
    },
    icon: {
        width: '24px',
        height: '24px',
        stroke: theme.colors.palette.neutral.white
    },
    white: {
        color: theme.colors.palette.neutral.white
    }
})

function CurrentOccupancySwipeItem({
                                       onClick,
                                       classes,
                                       icon,
                                       total,
                                       occupied,
                                       name,
                                   }) {

    return (
        <div className={classes.root} onClick={() => onClick ? onClick() : ''}>
            {icon && React.cloneElement(icon, {className: classes.icon})}
            <div className={classes.inline}>
                <Typography variant={'h3'} className={classes.white}>
                    {occupied}
                </Typography>
                {total !== undefined && <Typography variant={'body2'}>
                    &nbsp;/ {total}
                </Typography>}
            </div>
            <Typography variant={'caption'} className={classes.ellipsis}>
                {name}
            </Typography>
        </div>
    );
}

CurrentOccupancySwipeItem.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.element,
    occupied: PropTypes.number,
    total: PropTypes.number,
    name: PropTypes.string,
};

export default withStyles(styles)(CurrentOccupancySwipeItem)