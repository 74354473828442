import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        display: 'flex',
    },
}));

function CustomTab(props) {

    const {value, index} = props;
    const theme = useTheme();
    const classes = useStyle(theme);

    if (value === undefined || index === undefined || value !== index || !props.children)
        return null;

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    );
}

CustomTab.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
};

CustomTab.defaultProps = {
    label: '',
    disabled: false,
};

export default CustomTab;