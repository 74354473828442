import React from 'react';
import PropTypes from "prop-types";

import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import NavigationArrowSvgIcon from "../icons/NavigationArrowSvgIcon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = props => makeStyles(theme => ({
    root: {
        '& .MuiDivider-root': {
            margin: props.stretchDivider ? '0 !important' : '0 0 0 ' + (props.startAdornmentWidth + 18) + 'px !important',
        },
        display: 'block',
        width: '100%',
        '& .MuiAvatar-root ': {
            height: props.avatarHeight ? props.avatarHeight + 'px' : '48px',
            width: props.avatarHeight ? props.avatarHeight + 'px' : '48px',
            fontWeight: '400'
        },
    },
    textElement: {
        display: 'flex',
        //height -1px because divider takes 1 px in height, so parent element is desired height
        height: props.height ? props.height - 1 + 'px' : '70px',
        alignItems: 'center',
        width: '100%',
        gap: theme.outerGap,
    },
    text: {
        width: '100%',
        paddingRight: theme.innerGap,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        color: props.textColor ? props.textColor : theme.colors.palette.neutral.darkMain,
    },
    flexWrapper: {
        width: 'calc(100% - 24px)',
        display: 'flex',
        alignItems: 'center',
        gap: theme.innerGap
    },
    endAdornments: {
        flexShrink: 0,
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        gap: theme.innerSmallGap
    },
    selected: {
        border: 'solid 2px !important',
        borderColor: `${theme.colors.palette.corporate.skinMain} !important`,
        borderRadius: '12px',
    },
    clickable: {
        cursor: 'pointer'
    },
    marginRight: {
        marginRight: '2rem'
    },
    texts: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'start'
    },
    textMain: {
        ...props.innerClasses?.textMain
    },
    textSecondRow: {
        ...props.innerClasses?.textSecondRow
    }
}))

function ActionListItem({
                            text,
                            action,
                            startAdornment,
                            centerAdornment,
                            endAdornment,
                            className,
                            hideArrow,
                            hideDivider,
                            startDivider,
                            selected,
                            disabled,
                            t,
                            textColor,
                            saveArrowSpace,
                            textSecondRow,
                            textColorSecondRow,
                            textSecondRowVariant,
                            textVariant,
                            arrowColor,
                            dividerColor,
                            ...props
                        }) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);

    delete props.avatarHeight //prevent html error

    const textColorFinale = disabled
        ? theme.colors.palette.neutral.greyMain
        : textColor

    return (
        <div
            className={`${classes.root} ${className} ${selected && !disabled && classes.selected} ${!disabled && !hideArrow && action && classes.clickable}`}>
            {startDivider && <Divider style={{background: dividerColor}}/>}
            <div className={classes.textElement}
                 onClick={action && !disabled ? action : () => {
                 }}>
                {startAdornment && React.cloneElement(startAdornment,
                    {
                        className: clsx(startAdornment.props.className)
                    })}
                <div className={classes.text}>
                    <div className={classes.flexWrapper}>
                        {centerAdornment && React.cloneElement(centerAdornment,
                            {className: clsx(centerAdornment.props.className)})}
                        <div className={classes.texts}>
                            <Typography variant={textVariant} noWrap
                                        className={classes.textMain}
                                        style={{color: textColorFinale, userSelect: 'none'}}>
                                {text}
                            </Typography>

                            {textSecondRow &&
                                <Typography variant={textSecondRowVariant}
                                            className={classes.textSecondRow}
                                            style={{color: textColorSecondRow, userSelect: 'none'}}
                                            noWrap>
                                    {textSecondRow}
                                </Typography>
                            }
                        </div>
                    </div>
                    <div
                        className={clsx(classes.endAdornments, hideArrow && saveArrowSpace ? classes.marginRight : '')}>

                        {endAdornment && React.cloneElement(endAdornment,
                            {className: endAdornment.props.className, style: {color: textColor}, ...props})}

                        {action && !hideArrow &&
                            <NavigationArrowSvgIcon reverse
                                                    size={props.startAdornmentWidth ? props.startAdornmentWidth / 2 : 16}
                                                    style={{transform: 'scale(-1)'}}
                                                    fill={arrowColor || theme.colors.palette.neutral.greyMain}/>}
                    </div>
                </div>
            </div>
            {!hideDivider && <Divider style={{background: dividerColor}}/>}
        </div>
    );
}

ActionListItem.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    endAdornment: PropTypes.element,
    centerAdornment: PropTypes.element,
    startAdornment: PropTypes.element,
    startAdornmentWidth: PropTypes.number,
    action: PropTypes.func,
    hideArrow: PropTypes.bool,
    hideDivider: PropTypes.bool,
    startDivider: PropTypes.bool,
    stretchDivider: PropTypes.bool,
    height: PropTypes.number,
    avatarHeight: PropTypes.number,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    textSecondRow: PropTypes.string,
    textColorSecondRow: PropTypes.string,
    //adds margin-right so endAdornments are aligned vertically
    saveArrowSpace: PropTypes.bool,
    textVariant: PropTypes.string,
    textSecondRowVariant: PropTypes.string,
    dividerColor: PropTypes.string,
    arrowColor: PropTypes.string,
    innerClasses: PropTypes.object,
}

ActionListItem.defaultProps = {
    textColor: '#333',
    textColorSecondRow: '#b2b2b2',
    startAdornmentWidth: 48,
    height: 67,
    textVariant: 'body1',
    textSecondRowVariant: 'h6',
    innerClasses: {},
}

export default ActionListItem;
