import * as React from "react"
import PropTypes from 'prop-types'

function BookingsEmptyStateSvg({width, height, ...props}) {
    return (
        <svg width={width} height={height} viewBox="0 0 330 215"{...props}>
            <defs>
                <filter x="-7%" y="-10.5%" width="113.9%" height="120.9%" filterUnits="objectBoundingBox"
                        id="prefix_bookingsEmpty_a">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation={2.5} in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                    <feColorMatrix
                        values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 1 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <filter x="-10.6%" y="-16%" width="121.1%" height="131.9%" filterUnits="objectBoundingBox"
                        id="prefix_bookingsEmpty_b">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                    <feColorMatrix
                        values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 1 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <filter x="-17.8%" y="-26.9%" width="135.5%" height="153.8%" filterUnits="objectBoundingBox"
                        id="prefix_bookingsEmpty_c">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation={10} in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                    <feColorMatrix
                        values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 1 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <linearGradient x1="82.794%" y1="48.182%" x2="46.739%" y2="50.328%" id="prefix_bookingsEmpty_g">
                    <stop stopColor="#EEE" offset="0%"/>
                    <stop stopColor="#FFF" offset="100%"/>
                </linearGradient>
                <linearGradient x1="24.441%" y1="45.95%" x2="42.378%" y2="51.347%" id="prefix_bookingsEmpty_i">
                    <stop stopColor="#EEE" offset="0%"/>
                    <stop stopColor="#FFF" offset="100%"/>
                </linearGradient>
                <rect id="prefix_bookingsEmpty_d" x={0} y={0} width={242} height={160} rx={11.889}/>
                <rect id="prefix_bookingsEmpty_j" x={0} y={0} width={242} height={160} rx={11.889}/>
                <path id="prefix_bookingsEmpty_f" d="M0 0h242v160H0z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g filter="url(#prefix_bookingsEmpty_a)" transform="translate(67.845 16)">
                    <rect stroke="#E5E5E5" strokeWidth={2} fill="#FAFAFA" x={1.053} y={1}
                          width={191.507} height={126.198} rx={9.522}
                    />
                    <rect fill="#333" opacity={0.75} x={15.987} y={14.513}
                          width={68.937} height={8.466} rx={3.174}
                    />
                    <path d="M174.42 9.675a8.466 8.466 0 110 16.932 8.466 8.466 0 010-16.932z" fill="#333"
                          opacity={0.75}/>
                    <rect fill="#FAFAFA" opacity={0.75} x={0.053} width={193.507} height={128.198} rx={9.522}/>
                </g>
                <g filter="url(#prefix_bookingsEmpty_b)" transform="translate(55.923 35.26)">
                    <rect stroke="#E5E5E5" strokeWidth={2} fill="#FAFAFA" x={1.296} y={1} width={215.278}
                          height={141.946} rx={10.692}/>
                    <rect fill="#0C4043" opacity={0.75} x={18.188} y={15.296} width={77.405} height={9.506} rx={3.564}/>
                    <path d="M196.084 10.864a9.506 9.506 0 110 19.012 9.506 9.506 0 010-19.012z" fill="#0C4043"
                          opacity={0.75}/>
                    <rect fill="#FFF" opacity={0.25} x={0.296} width={217.278} height={143.946} rx={10.692}/>
                </g>
                <g filter="url(#prefix_bookingsEmpty_c)" transform="translate(44 55)">
                    <mask id="prefix_bookingsEmpty_e" fill="#fff">
                        <use xlinkHref="#prefix_bookingsEmpty_d"/>
                    </mask>
                    <rect stroke="#E5E5E5" strokeWidth={2} x={1} y={1} width={240} height={158} rx={11.889}/>
                    <g style={{mixBlendMode: "multiply"}} mask="url(#prefix_bookingsEmpty_e)">
                        <mask id="prefix_bookingsEmpty_h" fill="#fff">
                            <use xlinkHref="#prefix_bookingsEmpty_f"/>
                        </mask>
                        <use fill="#FFF" xlinkHref="#prefix_bookingsEmpty_f"/>
                        <path
                            d="M-277.705-21.333h703.427l-9.315.976-2.327.247-4.646.5C337.489-11.77 269.809.293 275.346 76.76c5.663 78.192-56.245 56.702-137.758 26.41l-9.872-3.672C48.033 69.88-47.949 35.5-117.791 76.76c-64.334 38.006-127.765-17.689-159.914-98.092z"
                            fill="url(#prefix_bookingsEmpty_g)"
                            mask="url(#prefix_bookingsEmpty_h)"
                        />
                        <path
                            d="M468.208 37.847c-4.003 11.017-12.417 58.957-20.117 69.073C325.798 267.584 241.847-.321 153.525 130.887c-26.574 39.477-78.522 47.926-144.332 22.468l-6.953-2.692c-47.687-18.447-98.732-37.38-143.796-19.776-62.045 24.238-91.922-34.254-95.154-93.04h704.918z"
                            fill="url(#prefix_bookingsEmpty_i)"
                            mask="url(#prefix_bookingsEmpty_h)"
                            transform="matrix(1 0 0 -1 0 205.694)"
                        />
                    </g>
                    <rect stroke="#E5E5E5" strokeWidth={2} x={1} y={1} width={240} height={158} rx={11.889}/>
                    <rect fill="#D9D9D9" opacity={0.657} x={21.379} y={127.879} width={141.951} height={10.571}
                          rx={2.642}/>
                    <rect fill="#B2B2B2" opacity={0.818} x={21.379} y={94.656} width={90.607} height={19.816}
                          rx={3.963}/>
                    <rect fill="#B2B2B2" opacity={0.818} x={21.379} y={70.494} width={169.133} height={19.816}
                          rx={3.963}/>
                    <rect fill="#FF985C" opacity={0.75} x={21.379} y={17.64} width={86.076} height={10.571} rx={3.963}/>
                    <path
                        d="M219.203 11.6c5.838 0 10.571 4.733 10.571 10.57 0 5.839-4.733 10.571-10.57 10.571-5.839 0-10.571-4.732-10.571-10.57s4.732-10.571 10.57-10.571z"
                        fill="#FF985C"
                        opacity={0.75}
                    />
                </g>
            </g>
        </svg>
    )
}

BookingsEmptyStateSvg.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

BookingsEmptyStateSvg.defaultProps = {
    width: 330,
    height: 215,
}


export default BookingsEmptyStateSvg
