import {Scene} from 'three'
import {getRenderer, resize} from './Renderer'
import {getOrthographicCamera} from './Camera'
import {start, stop} from './RenderLoop'
import {getOrbitControls} from './OrbitControls'
import {addOrUpdateBackgroundImage} from './Background'
import {addCatchPlane} from './CatchPlane'
import {addDrawingObjects} from "./DrawingObjects";
import {freeMemory} from "../drawingTool/three/common/memory";

export function setUp(mount, image, drawingObjects, initialZoomRef, imageScale, onZoomChange, zoomRange) {
    const scene = new Scene()

    const renderer = getRenderer()
    mount.current.appendChild(renderer.domElement)

    const camera = getOrthographicCamera(mount.current)
    scene.add(camera)

    const orbitControls = getOrbitControls(camera, renderer, initialZoomRef.current, onZoomChange, zoomRange)

    addOrUpdateBackgroundImage(scene, image, imageScale, mount, camera, initialZoomRef, orbitControls)

    addCatchPlane(scene)
    addDrawingObjects(scene, drawingObjects)

    resize(mount, renderer, camera)
    const frameId = start(renderer, scene, camera)
    return {
        scene: scene,
        renderer: renderer,
        camera: camera,
        frameId: frameId,
        orbitControls: orbitControls,
    }
}

export function cleanUp(mount, renderer, frameId, drawingObjects, scene) {
    stop(frameId)
    drawingObjects.forEach(object => object.geometry.dispose())
    mount.current?.removeChild(renderer.domElement)
    freeMemory(scene)
}
