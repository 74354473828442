import React from 'react';
import {Divider, makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import PropTypes from "prop-types";
import CardWrapper from "../../../common/elements/CardWrapper.js";
import {getBreakHoursAsString, getTimeAsString, getWorkedHoursAsString} from "../../../common/utils/TimeUtils.js";
import OptionsSvgIcon from "../../../common/icons/OptionsSvgIcon";
import BreakSvgIcon from "../../../common/icons/BreakSvgIcon.js";
import WorkTimeSvgIcon from "../../../common/icons/WorkTimeSvgIcon.js";

const useStyle = props => makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    info: {
        padding: theme.innerGap,
        justifyContent: 'space-between',
        display: 'flex',
        gap: theme.innerGap,
        boxSizing: 'border-box',
        width: '100%'
    },
    break: {
        width: '100%',
        boxSizing: 'border-box',
        paddingLeft: '5.4rem',
        display: 'flex',
        flexDirection: 'column'
    },
    breakContent: {
        padding: theme.innerGap,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between'
    },
    inline: {
        display: 'flex',
        gap: theme.innerSmallGap,
        alignItems: 'center',
    },
    columnwise: {
        display: 'flex',
        flexDirection: 'column',
    }
}))

function DayTrackingCard({
                             timeTracking,
                             onTimeTrackingClick,
                             onBreakClick,
                             ...props
                         }) {
    const theme = useTheme()
    const classes = useStyle(props)(theme);

    function getBreakElement(breakEntry) {
        return (
            <div className={classes.break} key={breakEntry.id} onClick={() => onBreakClick(breakEntry)}>
                <Divider/>
                <div className={classes.breakContent}>
                    <div className={classes.columnwise}>
                        <Typography variant={'caption'}>
                            {getTimeAsString(breakEntry.startTime)}
                        </Typography>
                        <div className={classes.inline}>
                            <BreakSvgIcon color={theme.colors.palette.neutral.darkMain}/>
                            <Typography
                                variant={'body1'}>{getBreakHoursAsString(breakEntry)}</Typography>
                        </div>
                    </div>
                    <OptionsSvgIcon/>
                </div>
            </div>)
    }

    return (
        <CardWrapper fullWidth>
            <div className={classes.root}>
                <div className={classes.info} onClick={onTimeTrackingClick}>
                    <div className={classes.inline}>
                        <WorkTimeSvgIcon/>
                        <Typography>
                            {getWorkedHoursAsString([timeTracking],)}
                        </Typography>
                    </div>
                    <OptionsSvgIcon/>
                </div>
                {timeTracking.breaks && timeTracking.breaks.sort((b1, b2) => b1.startTime > b2.startTime ? 1 : -1).map(breakEntry => {
                    return getBreakElement(breakEntry)
                })}
            </div>
        </CardWrapper>
    );
}

DayTrackingCard.propTypes = {
    timeTracking: PropTypes.object.isRequired,
    onTimeTrackingClick: PropTypes.func.isRequired,
    onBreakClick: PropTypes.func.isRequired
};

export default DayTrackingCard
