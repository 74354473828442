import {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import {getDateAtEndOfWorkingDay, getDateAtStartOfWorkingDay, getTimePeriodAtDate} from "../utils/TimeUtils.js";

/**
 * returns the working hours of the currently logged-in user for a specified date,
 * checking the day of week of the passed date and matching with the users working hours,
 * if no working hours specified the default values (7:00-19:00) will be used
 */
export const usePersonWorkingHours = (selectedDate) => {
    const workingHours = useSelector(state => state.user.person.workingHours);
    const [timeperiod, setTimeperiod] = useState({
        startDate: getDateAtStartOfWorkingDay(moment(selectedDate).clone() ?? moment()),
        endDate: getDateAtEndOfWorkingDay(moment(selectedDate).clone() ?? moment())
    });

    useEffect(() => {
        setTimeperiod(getWorkingHoursOrDefaultHoursForDate(selectedDate, workingHours))
        // eslint-disable-next-line
    }, [selectedDate, workingHours])

    return timeperiod
}

export function getWorkingHoursOrDefaultHoursForDate(date, workingHours) {
    if (!!workingHours && Object.keys(workingHours).length) {
        const workingHoursMap = new Map(Object.entries(workingHours))
        const datesDayOfWeek = moment(date).locale('en').format('dddd').toUpperCase()

        let timePeriodForDayOfWeek = workingHoursMap.get(datesDayOfWeek);

        if (timePeriodForDayOfWeek) {
            return getTimePeriodAtDate(timePeriodForDayOfWeek, date)
        }
    }

    return {
        startDate: getDateAtStartOfWorkingDay(moment(date).clone() ?? moment()),
        endDate: getDateAtEndOfWorkingDay(moment(date).clone() ?? moment())
    }
}

export function isWorkingdayOfUser(date, workingHours) {
    if (!!workingHours && Object.keys(workingHours).length) {
        const workingHoursMap = new Map(Object.entries(workingHours))
        const datesDayOfWeek = moment(date).locale('en').format('dddd').toUpperCase()

        let timePeriodForDayOfWeek = workingHoursMap.get(datesDayOfWeek);

        if (timePeriodForDayOfWeek) {
            return true
        }
    }

    return false
}
