import axios from 'axios'

export function createSpaces(buildingId, floorId, spaceDtos) {
    if (!spaceDtos.length) {
        spaceDtos = [spaceDtos]
    }

    return {
        type: 'CREATE_SPACES',
        payload: axios.post(`/api/building/${buildingId}/floor/${floorId}/space`, spaceDtos)
    }
}

export function updateSpaces(buildingId, floorId, spaceDtos) {
    if (!spaceDtos.length) {
        spaceDtos = [spaceDtos]
    }
    return {
        type: 'UPDATE_SPACES',
        payload: axios.put(`/api/building/${buildingId}/floor/${floorId}/space`, spaceDtos)
    }
}

export function deleteSpaces(buildingId, floorId, spaceIds) {
    if (!spaceIds.length) {
        spaceIds = [spaceIds]
    }
    return {
        type: 'DELETE_SPACES',
        payload: axios.delete(`/api/building/${buildingId}/floor/${floorId}/space`, {data: spaceIds})
    }
}

export function setSelectedSpace(space) {
    return {
        type: 'SET_SELECTED_SPACE',
        payload: space
    }
}

export function addThirdPartyCalendarToSpace(spaceId, calendar) {

    return {
        type: 'ADD_CALENDAR_TO_SPACE',
        payload: axios.post(`/api/building/-1/floor/-1/space/${spaceId}/calendar`, calendar)
    }
}