import * as React from "react"
import PropTypes from "prop-types";

function FilterSvgIcon({size, stroke, ...props}) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 25"
            fill="none"
            {...props}
        >
            <path
                stroke={stroke}
                strokeLinecap="square"
                d="M2.5 7.945h19M6.5 12.945h11M10.5 17.945h3"
            />
        </svg>
    )
}

FilterSvgIcon.propTypes = {
    size: PropTypes.number,
    stroke: PropTypes.string,
}

FilterSvgIcon.defaultProps = {
    size: 24,
    stroke: '#333',
}

export default FilterSvgIcon
