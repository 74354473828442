import * as THREE from 'three'

import backgroundPatternImage from '../../common/images/CCD_Background_Pattern.jpg'
import iconRoom from '../../common/images/icon_room.png'
import scalePatternLowImage from '../../common/images/Scale_pattern_Low.png'
import scalePatternHighImage from '../../common/images/Scale_Pattern_High.png'

export const materialTypes = {
    // Drawing
    background: 'background',
    placeholder: 'placeholder',

    // Floorplan
    default: 'default',
    defaultShadow: 'defaultShadow',
    defaultLine: 'defaultLine',

    black: 'black',
    turquoise: 'turquoise',
    grey: 'grey',
    white: 'white',
    transparent: 'transparent',

    scalePatternLow: 'scalePatternLow',
    scalePatternHigh: 'scalePatternHigh',
}

const materialColors = {
    default: '#ffffff',
    defaultShadow: '#ffffff',
    defaultLine: '#000000',

    black: '#000000',
    turquoise: '#a9ecea',
    grey: '#666666',
    white: '#ffffff',
}

const materials = {
    background: new THREE.MeshBasicMaterial({map: getTexture(materialTypes.background)}).clone(),
    placeholder: new THREE.MeshBasicMaterial({map: getTexture(materialTypes.placeholder)}).clone(),
    scalePatternLow: new THREE.MeshBasicMaterial({
        map: getTexture(materialTypes.scalePatternLow),
        depthWrite: true,
        transparent: true
    }).clone(),
    scalePatternHigh: new THREE.MeshBasicMaterial({
        map: getTexture(materialTypes.scalePatternHigh),
        depthWrite: true,
        transparent: true
    }).clone(),
    default: new THREE.MeshBasicMaterial({color: materialColors.default}),
    defaultShadow: new THREE.MeshBasicMaterial({
        color: materialColors.defaultShadow,
        depthWrite: true,
        shadowSide: THREE.FrontSide,
        side: THREE.BackSide
    }),
    defaultLine: new THREE.LineBasicMaterial({color: materialColors.defaultLine}),

    black: new THREE.MeshBasicMaterial({name: materialTypes.black, color: materialColors.black, depthWrite: true}),
    turquoise: new THREE.MeshBasicMaterial({
        name: materialTypes.turquoise,
        color: materialColors.turquoise,
        depthWrite: true
    }),
    grey: new THREE.MeshBasicMaterial({name: materialTypes.grey, color: materialColors.grey, depthWrite: true}),
    white: new THREE.MeshBasicMaterial({name: materialTypes.white, color: materialColors.white, depthWrite: true}),
    transparent: new THREE.MeshBasicMaterial({name: materialTypes.transparent, visible: false}),
}

export function getMaterial(materialType) {
    return materials[materialType]
}

export function getTexture(materialType) {
    const textureLoader = new THREE.TextureLoader()

    switch (materialType) {
        case materialTypes.background:
            return textureLoader.load(backgroundPatternImage, function (texture) {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping
                texture.offset.set(0, 0)
                texture.repeat.set(75, 75)
            })
        case materialTypes.placeholder:
            return textureLoader.load(iconRoom, function (texture) {
                texture.offset.set(0, 0)
                texture.repeat.set(1, 1)
            })
        case materialTypes.scalePatternLow:
            return textureLoader.load(scalePatternLowImage, function (texture) {
                texture.wrapT = texture.wrapS = THREE.RepeatWrapping
                texture.offset.set(0, 0)
                texture.repeat.set(1, 3.9)
            })
        case materialTypes.scalePatternHigh:
            return textureLoader.load(scalePatternHighImage, function (texture) {
                texture.wrapT = texture.wrapS = THREE.RepeatWrapping
                texture.offset.set(0, 0)
                texture.repeat.set(1, 3.9)
            })
        default:
            return
    }
}

