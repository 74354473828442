import React from 'react';

import clsx from "clsx";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import TrophySvgIcon from "../../common/icons/TrophySvgIcon.js";
import ProgressBar from "../../common/elements/ProgressBar.js";

import {navigate, PATH_USER_ACHIEVEMENTS} from "../../common/utils/NavigationUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    card: {
        border: '1px solid ' + theme.colors.palette.neutral.greyMedium,
        borderRadius: '.9rem',
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
        boxSizing: 'border-box',
        padding: '1.8rem',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)'
    },
    line: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'start',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: '.3rem'
    },
    trophy: {
        fill: theme.colors.palette.corporate.skinMain,
        width: '1.6rem',
        height: '1.6rem',
    }
}))

function LevelPanel({gameStatus, asCard}) {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);

    if (!gameStatus || gameStatus.score === null || !gameStatus.nextLevel || !gameStatus.nextLevel.minScore) {
        return null
    }

    const currentProgress = () => {
        return Math.round((gameStatus.score / gameStatus.nextLevel.minScore) * 100)
    }

    return (
        <div className={clsx(classes.root, asCard ? classes.card : '')}
             onClick={() => navigate(history, PATH_USER_ACHIEVEMENTS)}>
            <div className={classes.line}>
                <Typography variant={'h5'}>{t('office_level')}</Typography>
                <div className={classes.flex}>
                    <Typography variant={'h5'}>{gameStatus.level && gameStatus.level.level}</Typography>
                    <TrophySvgIcon className={classes.trophy}/>
                </div>
            </div>
            <div className={classes.line}>
                <Typography variant={'caption'}>{t('be_active')}</Typography>
                <Typography
                    variant={'caption'}>{gameStatus.score ? gameStatus.score : 0}/{gameStatus.nextLevel.minScore}</Typography>
            </div>
            <div style={{marginTop: '4px'}}>
                <ProgressBar value={currentProgress()}/>
            </div>
        </div>
    )

}

const mapStateToProps = state => {
    return {
        gameStatus: state.user.person.gameStatus
    }
}

export default connect(mapStateToProps, {})(LevelPanel)