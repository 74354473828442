import React from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {withStyles} from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline'

import SearchableList from "../../common/elements/SearchableList";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import {setFloor} from "../../actions/floors-actions";
import {setMeetingSpace} from "../../actions/meeting-actions";
import {TYPE_OF_USE_MEETING} from "../../common/utils/NameUtils";
import {getBuildings} from "../../actions/buildings-actions.js";
import LoadingIndicator from "../../common/elements/LoadingIndicator";

const styles = theme => ({
    root: {
        height: '100%',
        minHeight: '100%',
        width: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        height: '100%',
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-around',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
})

class ChooseSpacePage extends React.Component {

    getRelevantSpaces = () => {
        if (!this.props.buildingsList) {
            if (!this.props.buildingsPending) {
                this.props.loadBuildings()
            }

            return []
        }

        if (!this.props.building || !this.props.floor)
            return []

        let spaces = null
        let building = this.props.buildingsList.find(b => b.id === this.props.building.id)

        if (building && building.floors && this.props.floor) {
            spaces = building.floors.find(f => f.id === this.props.floor.id).spaces
        }

        if (!spaces) {
            if (!this.props.buildingsPending) {
                this.props.loadBuildings()
            }

            return []
        }

        return spaces.filter(s => s.typeOfUse === TYPE_OF_USE_MEETING)
            .map(space => ({element: space}))
    }

    handleFloorSelected = (space) => {
        this.props.setMeetingSpace(space)
        setTimeout(() => {
            this.routeToBefore()
        }, 250);
    }

    routeToBefore = () => {
        this.props.history.goBack()
    }

    render() {
        const {classes, t, floor} = this.props;
        return (
            <Grid container component={Paper} className={classes.root}>
                <CssBaseline/>
                <Grid item sm={12} className={classes.container}>
                    <div className={classes.content}>
                        <HeaderWithTitleAndBackButton title={t('spaces')} backLabel={t('back')}
                                                      onNavigateBack={this.routeToBefore}/>

                        {this.props.buildingsPending ? <LoadingIndicator/> :
                            <SearchableList options={this.getRelevantSpaces()} onSelect={this.handleFloorSelected}
                                            selected={floor} placeholder={t('search_floors')}
                                            emptySearchString={t('no_spaces_available')}
                            />
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        building: state.buildings.building,
        buildingsList: state.buildings.buildingList,
        floor: state.floors.floor,
        space: state.meetings.space,
        buildingsPending: state.buildings.buildingsPending,
    }
}

const mapDispatchToProps = {
    setSelectedFloor: setFloor,
    setMeetingSpace: setMeetingSpace,
    loadBuildings: getBuildings,
}

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(ChooseSpacePage))