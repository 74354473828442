import React from 'react';
import PropTypes from "prop-types";

import clsx from "clsx";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";

import Grid from '@material-ui/core/Grid';
import {makeStyles, Typography} from "@material-ui/core";

import CustomList from "../CustomList.js";
import ActionListItem from "../../common/elements/ActionListItem";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy";
import MultipleAvatarsGroup from "../../common/elements/MultipleAvatarsGroup.js";
import ActionListItemAccordion from "../../common/elements/ActionListItemAccordion";

import XSvgIcon from "../../common/icons/XSvgIcon";
import AddPeopleSvgIcon from "../../common/icons/AddPeopleSvgIcon";

import {sortByName} from "../../common/utils/CommonUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex', '& .MuiCardContent-root': {
            margin: '0 auto', padding: '10px 14px', textAlign: 'right'
        }, flexDirection: 'column', alignItems: 'center', backgroundColor: theme.colors.palette.neutral.greyNeutral
    }, container: {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-around',
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
        border: `solid 1px ${theme.colors.palette.neutral.greyMedium}`,
        borderRadius: '12px',
    }, content: {
        width: '100%', display: 'flex', boxSizing: 'border-box', flexDirection: 'column', paddingLeft: '18px',
    }, addMembers: {
        display: 'flex',
        height: '60px',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer'
    }, name: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '18px',
        '& .MuiIconButton-root': {
            padding: '0',
        }
    }, icon: {
        width: '48px', height: '48px',
    }, deleteIcon: {
        cursor: 'pointer',
    }
}));

function SelectedPersonsComponent({
                                      user,
                                      selectedItems,
                                      onDeletePersonCallback,
                                      onDeleteGroupCallback,
                                      hideDeleteIcon,
                                      modifyWorkingGroups,
                                      setPopupOpenCallback,
                                      loggedInUserAlwaysSelected,
                                      className,
                                      addTitle
                                  }) {

    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme)

    const getItem = (object) => {
        return object.email ? getPersonItem(object) : getWorkinggroupItem(object);
    }

    const getWorkinggroupItem = (group) => {
        return (
            <ActionListItemAccordion
                backgroundColor={theme.colors.palette.neutral.greyNeutral}
                startAdornment={<MultipleAvatarsGroup persons={group.members}/>}
                key={group.id}
                height={60}
                text={group.name}
                endAdornment={
                    <div className={classes.deleteIcon}>
                        <XSvgIcon onClick={() => onDeleteGroupCallback(group)} width={24} height={24}/>
                    </div>
                }>

                <CustomList dividerPaddingRight={false} hideDivider>
                    {group.members && group.members.map(person => {
                        return getPersonItem(person, true)
                    })}
                </CustomList>

            </ActionListItemAccordion>
        )
    }

    const getPersonItem = (person, selectedByGroup) => {
        if (person.id) {
            return <ActionListItem
                startAdornment={<AvatarWithOccupancy person={person} hideAvailabilityStatus
                                                     size={selectedByGroup ? 32 : 48}/>}
                key={person.id}
                height={selectedByGroup ? 50 : 60}
                avatarHeight={selectedByGroup ? 32 : 40}
                text={person.name + " " + person.surname}
                startAdornmentWidth={selectedByGroup && 32}
                endAdornment={(user.id !== person.id || !loggedInUserAlwaysSelected) && !hideDeleteIcon && (!selectedByGroup || modifyWorkingGroups) ?
                    <div className={classes.deleteIcon}>
                        <XSvgIcon width={24} height={24}
                                  onClick={() => onDeletePersonCallback && onDeletePersonCallback(person, modifyWorkingGroups)}
                        />
                    </div> : null}/>
        }

        return <ActionListItem startAdornment={<AvatarWithOccupancy person={person}
                                                                    hideAvailabilityStatus/>}
                               key={person.email}
                               height={60}
                               avatarHeight={40}
                               text={person.email}
                               textSecondRow={t('not_in_organization')}
                               endAdornment={user.email !== person.email && !hideDeleteIcon ?
                                   <div className={classes.deleteIcon}>
                                       <XSvgIcon width={24} height={24}
                                                 onClick={() => onDeletePersonCallback && onDeletePersonCallback(person, selectedByGroup)}
                                       />
                                   </div> : null}/>
    }

    return (<Grid item sm={9} className={clsx(classes.container, className)}>
        <div className={classes.content}>
            {loggedInUserAlwaysSelected &&
                <ActionListItem startAdornment={<AvatarWithOccupancy person={user} hideAvailabilityStatus/>}
                                height={60}
                                text={user.name + " " + user.surname}
                />}

            {selectedItems && selectedItems.filter(item => item !== null && (!item.email || !loggedInUserAlwaysSelected || item.id !== user.id))
                .sort(sortByName)
                .map(item => {
                    return getItem(item)
                })}

            <div className={classes.addMembers} onClick={setPopupOpenCallback}>
                <AddPeopleSvgIcon className={classes.icon}/>
                <div className={classes.name}>
                    <Typography variant={'body1'}>{addTitle ?? t('add_members')}</Typography>
                </div>
            </div>
        </div>
    </Grid>)
}

SelectedPersonsComponent.propTypes = {
    setPopupOpenCallback: PropTypes.func.isRequired,
    onDeletePersonCallback: PropTypes.func,
    onDeleteGroupCallback: PropTypes.func,
    hideDeleteIcon: PropTypes.bool,
    selectedItems: PropTypes.array,
    loggedInUserAlwaysSelected: PropTypes.bool,
    modifyWorkingGroups: PropTypes.bool,
    addTitle: PropTypes.string
}

SelectedPersonsComponent.defaultProps = {
    modifyWorkingGroups: true,
    loggedInUserAlwaysSelected: true,
}

const mapStateToProps = state => {
    return {
        user: state.user.person,
    }
};

export default connect(mapStateToProps, {})(SelectedPersonsComponent)
