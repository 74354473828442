import React, {useState} from 'react';
import PropTypes from "prop-types";

import {ICalendar} from "datebook";
import {connect} from "react-redux";
import * as FileSaver from 'file-saver';
import {useTranslation} from "react-i18next";

import {makeStyles, useTheme} from "@material-ui/core";

import CustomButton from "../CustomButton.js";
import EditMeetingContent from "./EditMeetingContent.js";
import ClickableText from "../../common/elements/ClickableText";
import ModalWrapper from "../../common/elements/ModalWrapper.js";
import MeetingInformationContent from "./MeetingInformationContent";

import TrashIcon from "../../common/icons/TrashSvgIcon.js";
import EditSvgIcon from "../../common/icons/EditSvgIcon.js";
import CalendarExportSvgIcon from "../../common/icons/CalendarExportSvgIcon.js";

import {updateMeeting} from "../../actions/meeting-actions";

import {isInitiator} from "../../common/utils/AssignmentUtils";
import {ATTENDANCE_TYPE_EXTERN, ATTENDANCE_TYPE_ORGANISATION_INTERN} from "../../common/utils/NameUtils.js";

const useStyle = makeStyles(theme => ({
    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1.4rem'
    },
    headerIcons: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0px  !important',
        height: '24px !important',
        cursor: 'pointer',
    },
    editIcon: {
        display: 'flex',
        alignItems: 'flex'
    },
    headerActionsRight: {
        display: 'flex',
        gap: theme.innerGap,
    }
}))

function MeetingModal({
                          onClose,
                          onDelete,
                          open,
                          userId,
                          assignment,
                          updateMeeting,
                          meetingGroups,
                          ...props
                      }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const [editMode, setEditMode] = useState(false)
    const [doneDisabled, setDoneDisabled] = useState(false)

    const event = {
        title: assignment.title,
        description: assignment.description + '\n' + assignment.onlineLink,
        start: new Date(assignment.timePeriod.startDate),
        end: new Date(assignment.timePeriod.endDate),
        location: assignment.space?.name,
    }

    const iCalendar = new ICalendar(event)

    const downloadMeeting = () => {
        const ics = iCalendar.render()
        const blob = new Blob([ics], {
            type: 'text/calendar'
        })

        FileSaver.saveAs(blob, assignment.title + '.ics')
    }

    function editMeetingDone() {
        let newAttendees = []
        let newExternalAttendees = []

        for (let person of props.attendees) {
            if (person) {
                newAttendees.push({
                    person: {id: person.id},
                    meetingAttendanceStatus: null,
                    meetingAttendanceType: ATTENDANCE_TYPE_ORGANISATION_INTERN,
                })
            }
        }

        for (let extern of props.externalAttendees) {
            newExternalAttendees.push({
                email: extern.email,
                meetingAttendanceStatus: null,
                meetingAttendanceType: ATTENDANCE_TYPE_EXTERN,
            })
        }

        for (let group of meetingGroups) {
            for (let person of group.members) {
                if (!newAttendees.some(a => a.person.id === person.id)) {
                    newAttendees.push({
                        person: {id: person.id},
                        meetingAttendanceStatus: null
                    })
                }
            }
        }

        let assignmentBuilder = {
            id: assignment.id,
            person: assignment.person,
            timePeriod: props.timePeriod,
            title: props.title,
            space: props.space,
            description: props.description,
            onlineLink: props.onlineLink,
            attendances: newAttendees,
            externalAttendances: newExternalAttendees
        }

        updateMeeting(assignmentBuilder)
        setEditMode(false)
    }

    const getHeaderActions = () => {
        return <div className={classes.headerActionsRight}>
            {isInitiator(assignment, userId)
                ? editMode
                    ? <ClickableText text={t('done')}
                                     disabled={doneDisabled}
                                     textColor={theme.colors.palette.corporate.skinMain}
                                     onClick={editMeetingDone}/>
                    : <div className={classes.iconContainer}>
                        <CustomButton
                            icon={<EditSvgIcon className={classes.editIcon} height={24} width={24}/>}
                            onClick={() => setEditMode(true)}
                            textColor={theme.colors.palette.neutral.darkMain}
                            customClassName={classes.headerIcons}
                        />
                    </div>
                : null
            }
            {editMode
                ? null
                : <div className={classes.iconContainer}>
                    <CustomButton icon={<CalendarExportSvgIcon/>}
                                  onClick={() => downloadMeeting()}
                                  textColor={theme.colors.palette.neutral.darkMain}
                                  customClassName={classes.headerIcons}
                    />
                    <CustomButton icon={<TrashIcon color={theme.colors.palette.corporate.redMain}/>}
                                  onClick={() => {
                                      onDelete()
                                      onClose()
                                  }}
                                  textColor={theme.colors.palette.corporate.redMain}
                                  customClassName={classes.headerIcons}
                    />
                </div>
            }
        </div>

    }

    return (
        <ModalWrapper open={open} onClose={onClose}
                      headerActions={getHeaderActions()}>
            {editMode
                ? <EditMeetingContent assignment={assignment} disableDoneCallback={setDoneDisabled}/>
                : <MeetingInformationContent assignment={assignment} showReplyOptions/>
            }
        </ModalWrapper>
    )
}

MeetingModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    assignment: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        title: state.meetings.title,
        timePeriod: state.meetings.timePeriod,
        onlineOnly: state.meetings.onlineOnly,
        description: state.meetings.description,
        onlineLink: state.meetings.onlineLink,
        attendees: state.meetings.meetingAttendees,
        externalAttendees: state.meetings.externalAttendees,
        meetingGroups: state.meetings.meetingGroups,
        space: state.meetings.space,
        buildingName: state.meetings.buildingName,
        floorName: state.meetings.floorName,
    }
}

const mapDispatchToProps = {
    updateMeeting: updateMeeting,
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingModal)