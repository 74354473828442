import React, {useEffect} from 'react';
import PropTypes from "prop-types";

function QRCodeExportModal({content, callBackFunc}) {
    const printRef = React.useRef();

    useEffect(() => {
        callBackFunc(printRef)
        //eslint-disable-next-line
    }, []);

    return (
        <div ref={printRef} style={{width: '770px'}}>
            {React.cloneElement(content)}
        </div>
    );
}

QRCodeExportModal.propTypes = {
    content: PropTypes.object.isRequired,
    callBackFunc: PropTypes.func
}

export default QRCodeExportModal