import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from "prop-types";

import moment from "moment";
import {compose} from "redux";
import {withFormik} from "formik";
import {connect} from "react-redux";
import * as DOMPurify from 'dompurify';
import {withTranslation} from "react-i18next";

import {Typography, withStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import CustomTextField from "../CustomTextField.js";
import {
    copyMeetingAssignment,
    getAssignmentsOfSpaceAtDate,
    setMeetingInformation,
    updateExternalMeetingAttendees,
    updateMeetingAttendees,
    updateMeetingInvitedGroups,
    updateTimePeriodMeeting
} from "../../actions/meeting-actions.js";
import {getDateWithoutTime, getTimePeriodAtDate} from "../../common/utils/TimeUtils";
import {getAssignmentsAsIntervals} from "../../common/utils/AssignmentUtils.js";
import {setSelectedDate} from "../../actions/workplaceAssignment-action.js";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import CustomDateRangePicker from "../CustomDateRangePicker.js";
import TimeperiodSelectionWithPopupsAndTimeline
    from "../../common/elements/TimeperiodSelectionWithPopupsAndTimeline.js";
import SelectPersonsWithModal from "../people/SelectPersonsWithModal.js";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        paddingBottom: '1rem',
    },
    subContentGapContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
    textSkin: {
        color: theme.colors.palette.corporate.skinMain,
        [theme.breakpoints.down('md')]: {
            maxWidth: '200px'
        }
    },
    meetingroomText: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row'
    }
})

function EditMeetingContentClass({
                                     classes,
                                     t,
                                     assignment,
                                     theme,
                                     userId,
                                     timePeriod,
                                     space,
                                     title,
                                     person,
                                     description,
                                     onlineLink,
                                     onlineOnly,
                                     values,
                                     setFieldValue,
                                     handleSubmit,
                                     updateTimePeriodMeeting,
                                     updateMeetingAttendees,
                                     updateExternalMeetingAttendees,
                                     copyMeetingAssignment,
                                     getAssignmentsOfSpaceAtDate,
                                     buildingName,
                                     floorName,
                                     meetingGroups,
                                     updateMeetingInvitedGroups,
                                     meetingAttendees,
                                     externalAttendees,
                                     spaceAssignments,
                                     setSelectedDate,
                                     selectedDate,
                                     disableDoneCallback,
                                     ...props
                                 }) {
    const [externalAttendeesState, setExternalAttendeesState] = useState(externalAttendees && externalAttendees.length ? externalAttendees : []);
    const [meetingAttendeesState, setMeetingAttendeesState] = useState(meetingAttendees && meetingAttendees.length ? meetingAttendees : []);
    const [selectedGroupsState, setSelectedGroupsState] = useState(meetingGroups && meetingGroups.length ? meetingGroups : []);

    const disabledIntervals = useMemo(() => {
        let date = getDateWithoutTime()
        const assignmentsOfSpace = spaceAssignments[assignment.space.id]

        if (assignmentsOfSpace) {
            const assignmentsOfSpaceAtDate = assignmentsOfSpace[date]
            if (assignmentsOfSpaceAtDate) {
                return getAssignmentsAsIntervals(assignmentsOfSpaceAtDate.filter(a => a.id !== assignment.id))
            }
        }

        return []
        // eslint-disable-next-line
    }, [spaceAssignments])

    useEffect(() => {
        copyMeetingAssignment(assignment)
        let startDate = moment(assignment.timePeriod.startDate);
        setSelectedDate(startDate)

        getAssignmentsOfSpaceAtDate(assignment.space.id, moment(startDate).startOf('day'), moment(startDate).endOf('day'))
        setMeetingAttendeesState(assignment.attendances.map(a => a.person))
        setExternalAttendeesState(assignment.externalAttendances)
        // eslint-disable-next-line
    }, []);

    useDidMountEffect(() => {
        if (selectedDate) {
            getAssignmentsOfSpaceAtDate(assignment.space.id, moment(selectedDate).startOf('day'), moment(selectedDate).endOf('day'))
        }
        // eslint-disable-next-line
    }, [selectedDate])


    useEffect(() => {
        updateMeetingInvitedGroups([...selectedGroupsState])
        // eslint-disable-next-line
    }, [selectedGroupsState]);

    useDidMountEffect(() => {
        updateMeetingAttendees([...meetingAttendeesState])
        // eslint-disable-next-line
    }, [meetingAttendeesState]);

    useDidMountEffect(() => {
        updateExternalMeetingAttendees([...externalAttendeesState])
        // eslint-disable-next-line
    }, [externalAttendeesState]);

    function setDate(date) {
        setSelectedDate(moment(date))
        let newTimeperiod = getTimePeriodAtDate(timePeriod, date)
        updateTimePeriodMeeting(newTimeperiod)
    }

    if (!assignment)
        return

    return (
        <div className={classes.root}>
            <div className={classes.subContentGapContainer}>
                <Typography variant={"h6"}>{t('title')}</Typography>
                <CustomTextField
                    placeholder={t('title')}
                    autoFocus
                    value={values.title}
                    onChange={e => {
                        e.preventDefault();
                        const {value} = e.target;
                        setFieldValue("title", value);
                    }}
                    onBlur={handleSubmit}
                />

                <CustomDateRangePicker rangeMode={false}
                                       onDateChange={(value) => {
                                           setDate(value.startDate)
                                       }}
                                       centered
                                       maxDate={moment().add(1, 'year')}
                                       disableEarlierThanNow
                                       initialSelected={moment(timePeriod.startDate)}
                />

                {timePeriod.startDate &&
                    <TimeperiodSelectionWithPopupsAndTimeline startTime={moment(timePeriod.startDate)}
                                                              endTime={moment(timePeriod.endDate)}
                                                              setEndTime={time => {
                                                                  let newTp = getTimePeriodAtDate({
                                                                      startDate: timePeriod.startDate,
                                                                      endDate: time
                                                                  }, selectedDate)
                                                                  updateTimePeriodMeeting(newTp)
                                                              }}
                                                              setStartTime={time => {
                                                                  let newTp = getTimePeriodAtDate({
                                                                      startDate: time,
                                                                      endDate: timePeriod.endDate
                                                                  }, selectedDate)
                                                                  updateTimePeriodMeeting(newTp)
                                                              }}
                                                              disabledIntervals={disabledIntervals}
                                                              errorCallback={disableDoneCallback}
                    />
                }

                <div className={classes.meetingroomText}>
                    <Typography variant={"h6"}>
                        {t('meeting_room')}
                    </Typography>
                    <Typography variant={"h6"} className={classes.textSkin}>
                        {!onlineOnly && space
                            ? buildingName + ", " + floorName + ", " + space.name
                            : t('online_meeting')
                        }
                    </Typography>
                </div>
            </div>

            <Divider className={classes.divider}/>

            <div className={classes.subContentGapContainer}>
                <Typography variant={"h6"}>{t('description')}</Typography>
                <CustomTextField
                    placeholder={t('description')}
                    value={values.description}
                    onChange={e => {
                        e.preventDefault();
                        const {value} = e.target;
                        setFieldValue("description", value)
                    }}
                    onBlur={handleSubmit}
                    rows={5}
                />
            </div>

            <div className={classes.subContentGapContainer}>
                <Typography variant={"h6"}>{t('meeting_link')}</Typography>
                <CustomTextField
                    placeholder={t('meeting_link')}
                    value={values.onlineLink}
                    onChange={e => {
                        e.preventDefault();
                        const {value} = e.target;
                        setFieldValue("onlineLink", value);
                    }}
                    onBlur={handleSubmit}
                />
            </div>

            <Divider/>

            <SelectPersonsWithModal initialSelectedPersons={meetingAttendeesState}
                                    initialSelectedExternalPersons={externalAttendeesState}
                                    initialSelectedWorkingGroups={selectedGroupsState}
                                    withWorkingGroups
                                    allowExternalPersons
                                    hideAvailabilityStatus
                                    onConfirmCallback={(persons, groups, externals) => {
                                        if (persons)
                                            setMeetingAttendeesState(persons)
                                        if (groups)
                                            setSelectedGroupsState(groups)
                                        if (externals)
                                            setExternalAttendeesState(externals)
                                    }}/>
        </div>
    )
}

const EditMeetingContent = withFormik({
    mapPropsToValues: props => {
        return ({
            title: props.title,
            description: props.description,
            onlineLink: props.onlineLink,
            meetingAttendees: props.meetingAttendees,
        })
    },
    enableReinitialize: true,
    handleSubmit: (values, {props}) => {
        props.setMeetingDetails(values.title, DOMPurify.sanitize(values.description), values.onlineLink)
    }
})(EditMeetingContentClass)

EditMeetingContent.propTypes = {
    assignment: PropTypes.any.isRequired,
    disableDoneCallback: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        title: state.meetings.title,
        timePeriod: state.meetings.timePeriod,
        onlineOnly: state.meetings.onlineOnly,
        description: state.meetings.description,
        onlineLink: state.meetings.onlineLink,
        meetingAttendees: state.meetings.meetingAttendees,
        externalAttendees: state.meetings.externalAttendees,
        meetingGroups: state.meetings.meetingGroups,
        space: state.meetings.space,
        buildingName: state.meetings.buildingName,
        floorName: state.meetings.floorName,
        spaceAssignments: state.meetings.spaceAssignments,
        selectedDate: state.assignmentBuilder.selectedDate,
    }
}

const mapDispatchToProps = {
    updateTimePeriodMeeting: updateTimePeriodMeeting,
    copyMeetingAssignment: copyMeetingAssignment,
    getAssignmentsOfSpaceAtDate: getAssignmentsOfSpaceAtDate,
    updateMeetingAttendees: updateMeetingAttendees,
    updateExternalMeetingAttendees: updateExternalMeetingAttendees,
    setMeetingDetails: setMeetingInformation,
    updateMeetingInvitedGroups: updateMeetingInvitedGroups,
    setSelectedDate: setSelectedDate
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(EditMeetingContent))