import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";

import CustomButton from "../CustomButton.js";

import UndoActiveSvgIcon from "../../common/icons/UndoActiveSvgIcon";
import RedoActiveSvgIcon from "../../common/icons/RedoActiveSvgIcon";

const styles = theme => ({
    root: {
        position: 'absolute',
        top: '154px',
        right: '50%',
        height: '3.8rem',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiIconButton-root:hover': {
            opacity: 1,
            backgroundColor: theme.colors.palette.neutral.greyNeutral,
        }
    },
})

function ModificationOverview({undoAvailable, redoAvailable, onUndo, onRedo, classes}) {
    return (
        <div className={'modification-overview ' + classes.root}>
            <CustomButton customClassName={'undo-button'} icon={<UndoActiveSvgIcon/>} disabled={!undoAvailable}
                          onClick={onUndo}/>
            <CustomButton disabled={!redoAvailable} icon={<RedoActiveSvgIcon/>} onClick={onRedo}/>
        </div>
    )
}

export default withStyles(styles)(ModificationOverview)