import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import BottomSpreadsheet from "../../common/elements/BottomSpreadsheet.js";
import MeetingroomSelectionSpreadsheetHeader from "./headerContent/MeetingroomSelectionSpreadsheetHeader.js";
import WorkplaceSelectionSpreadsheetHeader from "./headerContent/WorkplaceSelectionSpreadsheetHeader.js";
import WorkplaceSelectionSpreadsheetBottom from "./bottomContent/WorkplaceSelectionSpreadsheetBottom";
import {getAssignmentsOfUser} from "../../actions/user-action.js";
import MeetingroomSelectionSpreadsheetBottom from "./bottomContent/MeetingroomSelectionSpreadsheetBottom.js";
import {getCoworkersAssignmentsAtDate} from "../../actions/workplaceAssignment-action.js";
import {getAssignmentsOfSpaceAtDate} from "../../actions/meeting-actions.js";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import {getProfilePictures} from "../../actions/occupancy-actions.js";
import MeetingroomSelectionSpreadsheetContent from "./content/MeetingroomSelectionSpreadsheetContent.js";
import WorkplaceSelectionSpreadsheetContent from "./content/WorkplaceSelectionSpreadsheetContent.js";
import {usePrevious} from "../../common/customHooks/usePrevious.js";
import moment from "moment";
import {getWorkingGroupsFromUser} from "../../actions/workingGroups-action.js";

function WorkplaceAndSpaceSelectionSpreadsheet({
                                                   userAssignments,
                                                   userId,
                                                   selectedWorkplace,
                                                   getAssignmentsOfUser,
                                                   selectedFloor,
                                                   showDetailsInitially,
                                                   selectedDate,
                                                   selectedSpace,
                                                   getCoworkersWorkplaceAssignmentsAtDate,
                                                   getAssignmentsOfSpaceAtDate,
                                                   getProfilePictures,
                                                   workingGroups,
                                                   getWorkingGroupsFromUser
                                               }) {
    const [detailsView, setDetailsView] = useState(showDetailsInitially);
    const [showThirdPage, setShowThirdPage] = useState(false);
    const [confirmationButtonsDisabled, setConfirmationButtonsDisabled] = useState(false);
    const prevSelectedFloor = usePrevious(selectedFloor)

    //load the users future assignments
    useEffect(() => {
        if (userId && (!userAssignments || !userAssignments.length))
            getAssignmentsOfUser(userId, false, true)

        if (!selectedDate)
            return

        if (prevSelectedFloor && selectedFloor && selectedFloor.id === prevSelectedFloor.id)
            return

        loadWorkplaceAssignments(selectedDate)

        if (selectedSpace)
            loadSpaceAssignments(selectedDate)
        //eslint-disable-next-line
    }, [selectedFloor, userId]);

    useDidMountEffect(() => {
        if (!selectedDate)
            return

        if (selectedSpace)
            loadSpaceAssignments(selectedDate)
    }, [selectedSpace])

    useEffect(() => {
        if (!userId || selectedSpace || (workingGroups && workingGroups.length > 0))
            return

        getWorkingGroupsFromUser(userId)
        //eslint-disable-next-line
    }, [userId])

    //load the assignments and profilepictures of colleagues at specified date
    function loadWorkplaceAssignments(date) {
        if (selectedFloor && userId) {
            getCoworkersWorkplaceAssignmentsAtDate(userId, moment(date).startOf('day').toISOString(),
                moment(date).endOf('day').toISOString(), selectedFloor.id, true)
            getProfilePictures(userId, selectedFloor.id, date.toISOString())
        }
    }

    function loadSpaceAssignments(date) {
        if (selectedSpace) {
            getAssignmentsOfSpaceAtDate(selectedSpace.id, moment(date).startOf('day'), moment(date).endOf('day'))
        }
    }

    function getHeaderContent() {
        return selectedSpace
            ? <MeetingroomSelectionSpreadsheetHeader onDateChange={loadSpaceAssignments}
                                                     detailsView={detailsView}
                                                     setDetailsView={setDetailsView}/>
            : <WorkplaceSelectionSpreadsheetHeader onDateChange={loadWorkplaceAssignments}
                                                   detailsView={detailsView}
                                                   setDetailsView={setDetailsView}/>
    }

    function getContent() {
        return selectedSpace
            ? <MeetingroomSelectionSpreadsheetContent detailsView={detailsView}
                                                      onTimeRangeErrorChangeCallback={setConfirmationButtonsDisabled}
                                                      createDifferentMeetingMode={showThirdPage}
            />
            : <WorkplaceSelectionSpreadsheetContent detailsView={detailsView}
                                                    onTimeRangeErrorChangeCallback={setConfirmationButtonsDisabled}
            />
    }

    function getBottomContent() {
        return selectedSpace
            ? <MeetingroomSelectionSpreadsheetBottom detailsView={detailsView}
                                                     confirmationButtonsDisabled={confirmationButtonsDisabled}
                                                     onCreateDifferentMeeting={setShowThirdPage}
                                                     createDifferentMeetingMode={showThirdPage}/>
            : <WorkplaceSelectionSpreadsheetBottom detailsView={detailsView}
                                                   confirmationButtonsDisabled={confirmationButtonsDisabled}/>
    }

    return (
        <BottomSpreadsheet
            transformToSmall={!(selectedWorkplace || selectedSpace)}
            isOpen={true}
            header={getHeaderContent()}

            content={getContent()}

            footer={getBottomContent()}>>
        </BottomSpreadsheet>
    )
}

WorkplaceAndSpaceSelectionSpreadsheet.propTypes = {
    showDetailsInitially: PropTypes.bool
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        selectedWorkplace: state.workplace.favoredWorkplace,
        selectedSpace: state.spaces.selectedSpace,
        selectedFloor: state.floors.floor,
        selectedDate: state.assignmentBuilder.selectedDate,
        userAssignments: state.user.userAssignments,
        workingGroups: state.workingGroups.personGroups,
    }
}

const mapDispatchToProps = {
    getAssignmentsOfUser: getAssignmentsOfUser,
    getCoworkersWorkplaceAssignmentsAtDate: getCoworkersAssignmentsAtDate,
    getAssignmentsOfSpaceAtDate: getAssignmentsOfSpaceAtDate,
    getProfilePictures: getProfilePictures,
    getWorkingGroupsFromUser: getWorkingGroupsFromUser

}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceAndSpaceSelectionSpreadsheet)