import React, {useEffect} from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {Grid, withStyles} from "@material-ui/core";
import Dropdown from "../../common/elements/Dropdown";


import FloorplanWithWorkplaces from "../floorplan/FloorplanWithWorkplaces.js";
import WorkplaceAndSpaceSelectionSpreadsheet from "../selectionSpreadsheet/WorkplaceAndSpaceSelectionSpreadsheet.js";


import {setFloor} from "../../actions/floors-actions";
import {getHomezone} from "../../actions/user-action";
import {getBuildings, setBuilding} from "../../actions/buildings-actions";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'

    },
    dropdowns: {
        width: '100%',
        margin: '0',
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 320px)',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        maxWidth: theme.gridSizes.maxWidth,
    },
    textLeft: {
        display: 'flex'
    },
    leftDropdown: {
        paddingLeft: '0 !important',
    },
    rightDropdown: {
        paddingRight: '0 !important',
    },
    date: {
        marginBottom: '12px',
        display: 'flex',
        alignSelf: 'flex-end'
    }
})

function CurrentOccupancyFloorplan({
                                       classes,
                                       setBuilding,
                                       setFloor,
                                       buildings,
                                       building,
                                       floor,
                                       userId,
                                       homeZone,
                                       getBuildings,
                                       getHomezone,
                                   }) {

    useEffect(() => {
        if (!buildings.length) {
            getBuildings(true)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (floor && buildings && buildings.length) {
            let buildingToSet = buildings.find(b => b?.floors.some(f => f.id === floor.id))
            if (!building || building.id !== buildingToSet.id) {
                setBuilding(buildingToSet, false)
            }
        }
        // eslint-disable-next-line
    }, [floor, buildings]);

    useDidMountEffect(() => {
        if (!floor) {
            if (homeZone) {
                setBuilding(homeZone.building)
                setFloor(homeZone.floor)
            } else if (userId && !homeZone) {
                getHomezone(userId)
            }
        }
        // eslint-disable-next-line
    }, [userId, homeZone]);

    const convertOptions = (options) => {
        if (!options)
            return null

        if (Array.isArray(options)) {
            return options.map(option => {
                return {id: option.id, name: option.name}
            })
        } else {
            return {id: options.id, name: options.name}
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2} className={classes.dropdowns}>
                <Grid item xs={6} className={classes.leftDropdown}>
                    <Dropdown onClick={setBuilding}
                              options={buildings != null ? convertOptions(buildings.sort((a, b) => a.name - b.name)) : building != null ? convertOptions([building]) : []}
                              initialSelected={convertOptions(building)}
                              fullWidth/>
                </Grid>
                <Grid item xs={6} className={classes.rightDropdown}>
                    <Dropdown onClick={setFloor} disabled={!building}
                              options={building && building.floors != null ? convertOptions(building.floors.sort((a, b) => a.level - b.level)) : floor != null ? convertOptions([floor]) : []}
                              initialSelected={convertOptions(floor)}
                              fullWidth/>
                </Grid>
            </Grid>
            <div className={classes.content}>
                <FloorplanWithWorkplaces showMeetingRooms centerInitially/>
                <WorkplaceAndSpaceSelectionSpreadsheet showDetailsInitially/>
            </div>
        </div>
    )
}

CurrentOccupancyFloorplan.defaultProps = {
    dateFormat: 'dddd, MMM D',
    local: 'en',
};

const mapStateToProps = state => {
    return {
        floor: state.floors.floor,
        userId: state.user.person.id,
        homeZone: state.user.homeZone,
        building: state.buildings.building,
        buildings: state.buildings.buildingList,
    }
}


const mapDispatchToProps = {
    setBuilding: setBuilding,
    getBuildings: getBuildings,
    setFloor: setFloor,
    getHomezone: getHomezone
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(CurrentOccupancyFloorplan))