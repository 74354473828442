import * as React from "react"

function ShareLocationSvgIcon(props) {
    return (
        <svg width={40} height={40} viewBox={"0 0 40 40"} {...props}>
            <g
                stroke={props.stroke || '#333'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <g transform="translate(12 7)">
                    <circle cx={8} cy={5} r={5}/>
                    <path d="M0 24v-5a6 6 0 016-6h4a6 6 0 016 6v5h0"/>
                </g>
                <path
                    d="M20 38c10-7.81 15-14.477 15-20 0-8.284-6.716-15-15-15C11.716 3 5 9.716 5 18c0 5.523 5 12.19 15 20z"/>
            </g>
        </svg>
    )
}

export default ShareLocationSvgIcon