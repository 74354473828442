import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "./LoadingIndicator";

const styles = theme => ({
    root: {
        cursor: 'pointer',
        alignSelf: 'center'
    },
    dotTyping: {
        position: 'relative',
        left: '-9999px',
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: theme.colors.palette.neutral.white,
        boxShadow: '9984px 0 0 0 #58BCCC, 9999px 0 0 0 #58BCCC, 10014px 0 0 0 #58BCCC',
        animation: `$dotTyping 1.5s infinite linear`,
    },
    '@keyframes dotTyping': {
        '0%': {
            boxShadow: '9984px 0 0 0 #58BCCC, 9999px 0 0 0 #58BCCC, 10014px 0 0 0 #58BCCC',
        },
        '16.667%': {
            boxShadow: '9984px -10px 0 0 #58BCCC, 9999px 0 0 0 #58BCCC, 10014px 0 0 0 #58BCCC',
        },
        '33.333%': {
            boxShadow: '9984px 0 0 0 #58BCCC, 9999px 0 0 0 #58BCCC, 10014px 0 0 0 #58BCCC',
        },
        '50%': {
            boxShadow: '9984px 0 0 0 #58BCCC, 9999px -10px 0 0 #58BCCC, 10014px 0 0 0 #58BCCC',
        },
        '66.667%': {
            boxShadow: '9984px 0 0 0 #58BCCC, 9999px 0 0 0 #58BCCC, 10014px 0 0 0 #58BCCC',
        },
        '83.333%': {
            boxShadow: '9984px 0 0 0 #58BCCC, 9999px 0 0 0 #58BCCC, 10014px -10px 0 0 #58BCCC',
        },
        '100%': {
            boxShadow: '9984px 0 0 0 #58BCCC, 9999px 0 0 0 #58BCCC, 10014px 0 0 0 #58BCCC',
        }
    }
});

function ClickableText({
                           textColor,
                           text,
                           variant,
                           onClick,
                           disabled,
                           className,
                           isLoading,
                           underlined,
                           ...props
                       }) {

    const {classes, theme} = props

    textColor = textColor ? textColor : theme.colors.palette.neutral.black

    function handleOnClick(e) {
        if (e) {
            e.stopPropagation()
        }

        if (typeof onClick === 'function')
            onClick()
    }

    if (isLoading) {
        return <div style={{width: '4.4rem', display: 'contents'}}>
            <LoadingIndicator/>
        </div>
    } else {
        return <Typography className={clsx(classes.root, className)}
                           variant={variant ? variant : 'body1'}
                           onClick={!isLoading && !disabled ? handleOnClick : () => {
                           }}
                           style={{
                               color: disabled ? theme.colors.palette.neutral.greyMedium : textColor,
                               textDecoration: underlined ? 'underline' : '',
                           }}>
            {text}
        </Typography>
    }
}

ClickableText.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    onClick: PropTypes.func,
    underlined: PropTypes.bool,
    isLoading: PropTypes.bool,
}

export default withStyles(styles, {withTheme: true})(ClickableText);