import axios from "axios";
import moment from "moment";

export function exportTimeTrackings(personId, startDate, endDate) {
    return {
        type: 'TIME_TRACKING_EXPORT',
        payload: axios.get(`/api/timeTracking/${personId}/timeTrackings/xlsx`, {
            responseType: 'blob',
            params: {
                startDate: startDate,
                endDate: endDate
            }
        })
            .then((response) => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers['content-disposition'].split('=')[1]);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error))
    }
}

export function getCurrentClockState(personId) {
    return {
        type: 'GET_CLOCK_STATE',
        payload: axios.get(`/api/timeTracking/${personId}/clockState`)
    }
}

export function getTodaysTimeTrackings(personId) {
    let startDate = moment().startOf('day').utc().toDate()
    let endDate = moment().endOf('day').utc().toDate()

    return {
        type: 'GET_TODAYS_TIME_TRACKINGS',
        payload: axios.get(`/api/timeTracking/${personId}/timeTrackings`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getUserTimeTrackingStatistics(personId, startDate, endDate) {
    return {
        type: 'GET_USER_TIME_TRACKING_STATISTICS',
        payload: axios.get(`/api/timeTracking/${personId}/timeTrackings`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function clockUserIn(personId, timezone) {
    return {
        type: 'CLOCK_USER_IN',
        payload: axios.post(`/api/timeTracking/${personId}/clockIn`, null, {
            params: {
                timezone: timezone
            }
        })
    }
}

export function clockUserOut(personId) {
    return {
        type: 'CLOCK_USER_OUT',
        payload: axios.post(`/api/timeTracking/${personId}/clockOut`)
    }
}

export function clockBreak(personId, isStart) {
    return {
        type: isStart ? 'START_CLOCK_BREAK' : 'STOP_CLOCK_BREAK',
        payload: axios.post(`/api/timeTracking/${personId}/clockBreak`)
    }
}

export function deleteTimeTracking(personId, timeTrackingId) {
    return {
        type: 'DELETE_TIMETRACKING',
        payload: axios.delete(`/api/timeTracking/${personId}/deleteTimeTracking`, {
            params: {
                timeTrackingId: timeTrackingId
            }
        })
    }
}

export function deleteTimeTrackingBreak(personId, breakId) {
    return {
        type: 'DELETE_TIMETRACKING_BREAK',
        payload: axios.delete(`/api/timeTracking/${personId}/deleteTimeTrackingBreak`, {
            params: {
                timeTrackingBreakId: breakId
            }
        })
    }
}

export function updateTimeTracking(personId, timeTracking, convertedFromBreak) {
    return {
        type: 'UPDATE_TIMETRACKING',
        payload: axios.patch(`/api/timeTracking/${personId}/updateTimeTracking`, timeTracking, {
            params: {
                convertedFromBreak: convertedFromBreak
            }
        })
    }
}

export function updateTimeTrackingBreak(personId, breakObj, convertedFromTimeTracking) {
    return {
        type: 'UPDATE_TIMETRACKING_BREAK',
        payload: axios.patch(`/api/timeTracking/${personId}/updateTimeTrackingBreak`, breakObj, {
            params: {
                convertedFromTimeTracking: convertedFromTimeTracking
            }
        })
    }
}

export function addTimeTracking(personId, timeTracking) {
    return {
        type: 'ADD_TIMETRACKING',
        payload: axios.post(`/api/timeTracking/${personId}/addTimeTracking`, timeTracking)
    }
}

export function addTimeTrackingBreak(personId, breakObj) {
    return {
        type: 'ADD_TIMETRACKING_BREAK',
        payload: axios.post(`/api/timeTracking/${personId}/addTimeTrackingBreak`, breakObj)
    }
}