import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPersonWithCredential, getUser} from "../../actions/user-action.js";
import {setUserTimezone} from "../../actions/timezone-action.js";
import moment from "moment";

const usePerson = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.user);
    const userPending = useSelector(state => state.user.userPending);
    const person = useSelector(state => state.user.person);
    const personPending = useSelector(state => state.user.personPending);

    useEffect(() => {
        if ((!user || !user.userName) && !userPending) {
            dispatch(getUser())
        } else if ((!person || !person.id) && !personPending && !userPending) {
            dispatch(getPersonWithCredential(user.userName))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!person || !person.id)
            return

        const savedTimezone = person.timezoneId
        const currentTimezone = moment.tz.guess();

        if (savedTimezone === null || savedTimezone !== currentTimezone) {
            dispatch(setUserTimezone(person.id, currentTimezone))
        }
        // eslint-disable-next-line
    }, [person])
}

export const withPersonHOC = (Component) => {
    return (props) => {
        usePerson();
        return <Component {...props} />;
    };
}