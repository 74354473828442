import * as React from "react"
import PropTypes from 'prop-types'

function ExitSvgIcon({color, width, height, ...props}) {
    return (
        <svg
            width={width || '100%'}
            height={height || '100%'}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                strokeWidth={1.5}
                fill={'none'}
                stroke={color}
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path d="M24 31H12.53C10.58 31 9 29.358 9 27.333V12.667C9 10.642 10.58 9 12.53 9H24"/>
                <path d="M24 25l5-5-5-5m5 5H16"/>
            </g>
        </svg>
    )
}

ExitSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

ExitSvgIcon.defaultProps = {
    color: '#333',
};

export default ExitSvgIcon
