import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";

import {useTranslation} from "react-i18next";

import useTheme from "@material-ui/core/styles/useTheme.js";
import {Input, makeStyles, Typography} from "@material-ui/core";

import AdminButton from "../../../common/elements/AdminButton.js";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        right: 0,
        top: 200,
        alignItems: 'flex-start',
        gap: '1.2rem',
    },
    input: {
        width: '80px',
        alignSelf: 'center',
        border: '1px solid',
        borderRadius: '5px',
        padding: '3px',
    },
    inline: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1.2rem',
    },
}))

function DistanceInput({setValueCallback, initialValue, active, withSaveButton, setSaveCallback, label}) {
    const {t} = useTranslation();
    const theme = useTheme()
    const classes = useStyles(theme);
    const timer = useRef(0)

    let [value, setValue] = useState(initialValue ? Number(initialValue).toFixed(2) : 1)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        if (withSaveButton)
            return

        if (timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
            if (value !== initialValue)
                onSave(value)
        }, 500)

        // eslint-disable-next-line
    }, [value]);

    function onSave() {
        if (value)
            setValueCallback(value / 2)
    }

    return (
        <div className={classes.root}>
            <div className={classes.inline}>
                <Typography>{label}</Typography>
                <Input
                    type={'number'}
                    value={value}
                    disabled={!active}
                    onChange={(event) => {
                        setValue(event.target.value)
                    }}
                    className={classes.input}
                />
                <Typography>m</Typography>
            </div>

            {withSaveButton &&
                <AdminButton onClick={() => setSaveCallback(value)} text={t('save')} disabled={!value} primary
                             fullWidth/>
            }
        </div>
    );
}

DistanceInput.propTypes = {
    setValueCallback: PropTypes.func,
    setSaveCallback: PropTypes.func,
    active: PropTypes.bool,
    withSaveButton: PropTypes.bool,
    label: PropTypes.string,
}

export default DistanceInput

