import * as React from "react"
import PropTypes from "prop-types";

function OneOnOneSvgIcon({color, width, height, strokeWidth, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={color}
                strokeWidth={strokeWidth}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path
                    d="M24 15c5.4 0 9.778 3.582 9.778 8 0 1.517-.516 2.936-1.413 4.145l1.606 4.824h0l-.006.003-5.526-1.842A11.53 11.53 0 0124 31c-5.4 0-9.778-3.582-9.778-8S18.6 15 24 15z"/>
                <path
                    d="M28.912 16.273C28.194 11.611 23.188 8 17.118 8c-6.562 0-11.882 4.22-11.882 9.427 0 1.788.627 3.46 1.717 4.884l-1.95 5.677c0 .002.001.005.004.006h.003l6.714-2.164h0c1.149.465 2.406.786 3.733.934"/>
            </g>
        </svg>
    )
}

OneOnOneSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    strokeWidth: PropTypes.number,
};

OneOnOneSvgIcon.defaultProps = {
    color: '#333',
    width: 40,
    height: 40,
    strokeWidth: 1.5,
};

export default OneOnOneSvgIcon