import React from 'react';
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme";
import ActionListItem from "../../common/elements/ActionListItem.js";

const createStyles = (theme) => ({
    textMain: {
        fontSize: '17px',
        [theme.breakpoints.up('xlll')]: {
            fontSize: '17px',
        },
        [theme.breakpoints.up('xll')]: {
            fontSize: '22px',
            lineHeight: theme.typography.h3.lineHeight,
        },
        [theme.breakpoints.up('xxl')]: {
            fontSize: '28px',
            lineHeight: theme.typography.h2.lineHeight,
        },
        [theme.breakpoints.up('xxxl')]: {
            fontSize: '40px',
            lineHeight: theme.typography.h1.lineHeight,
        }
    },
    textSecondRow: {
        fontSize: '13px',
        [theme.breakpoints.up('xlll')]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up('xll')]: {
            fontSize: '17px',
        },
        [theme.breakpoints.up('xxl')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.up('xxxl')]: {
            fontSize: '28px',
            lineHeight: theme.typography.h2.lineHeight,
        }
    }
})

function TerminalActionListItem({...props}) {
    const theme = useTheme()
    const styles = createStyles(theme);

    return (
        <ActionListItem innerClasses={styles} {...props}/>
    );
}

TerminalActionListItem.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    endAdornment: PropTypes.element,
    centerAdornment: PropTypes.element,
    startAdornment: PropTypes.element,
    startAdornmentWidth: PropTypes.number,
    action: PropTypes.func,
    hideArrow: PropTypes.bool,
    hideDivider: PropTypes.bool,
    startDivider: PropTypes.bool,
    stretchDivider: PropTypes.bool,
    height: PropTypes.number,
    avatarHeight: PropTypes.number,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    textSecondRow: PropTypes.string,
    textColorSecondRow: PropTypes.string,
    //adds margin-right so endAdornments are aligned vertically
    saveArrowSpace: PropTypes.bool,
    textVariant: PropTypes.string,
    textSecondRowVariant: PropTypes.string,
    dividerColor: PropTypes.string,
    arrowColor: PropTypes.string
}

export default TerminalActionListItem;
