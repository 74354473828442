import React from 'react';
import PropTypes from 'prop-types';
import {DialogActions, DialogContent, DialogContentText, ListItemText, makeStyles, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {withTranslation} from "react-i18next";
import DialogHeaderSvgIcon from "../../common/icons/DialogHeaderSvgIcon";
import {connect} from "react-redux";
import {setSubscription} from "../../actions/user-action.js";
import {urlB64ToUint8Array, VAPID_KEY} from "../../serviceWorkerRegistration.js";

const useStyle = makeStyles(theme => ({
    modal: {
        '& .MuiPaper-root': {
            borderRadius: '18px !important',
            padding: 0,
        },

        '& .MuiDialogContent-root': {
            padding: '28px 24px 16px 24px',
        },
        '& .MuiDialogActions-root': {
            padding: '0px 24px 16px 24px',
        }
    },
    header: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
    },
    headerLabel: {
        position: "relative",
        zIndex: 1,
        top: 66,
        textAlign: 'center'
    },
    text: {
        color: theme.colors.palette.neutral.darkMain,
        textAlign: 'left'
    },
}));

function ActivateNotificationDialog({t, open, handleClose, onDecline, personId, setSubscription, ...props}) {
    const classes = useStyle(props.theme);

    const handleDecline = () => {
        onDecline()
        handleClose()
    }

    const handleSubmit = () => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                createNotificationSubscription()
                console.log("Permission for Notifications granted")
            } else {
                console.log("Permission for Notifications declined")
            }
        })

        handleClose()
    }

    function createNotificationSubscription() {
        navigator.serviceWorker.ready.then((registration) => {
            // subscribe and return the subscription
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlB64ToUint8Array(VAPID_KEY)
            }).then((subscription) => {
                let sub = {
                    endpoint: subscription.endpoint,
                    auth: subscription.toJSON().keys.auth.toString(),
                    p256dh: subscription.toJSON().keys.p256dh.toString()
                }
                setSubscription(personId, sub)
            })
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} className={classes.modal}>
            <DialogHeaderSvgIcon className={classes.header}/>
            <div className={classes.headerLabel}>
                <Typography variant={"body2"}>{t("activate_notifications")}</Typography>
                <Typography variant={"h3"} style={{color: 'white'}}>{t("stay_updated")}</Typography>
            </div>
            <DialogContent style={{marginTop: '12rem'}}>
                <DialogContentText className={classes.text} id="alert-dialog-description">
                    {t("allow_browser")}
                </DialogContentText>
                <ul>
                    <li><ListItemText primary={t("invitation_groups_meetings")}/></li>
                    <li><ListItemText primary={t("meeting_updates")}/></li>
                    <li><ListItemText primary={t("news_bookings")}/></li>
                </ul>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDecline} color="primary">
                    {t("decline")}
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    {t("confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        personId: state.user.person.id,
    }
}

ActivateNotificationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    onDecline: PropTypes.func,
};
ActivateNotificationDialog.defaultPropTypes = {};

const mapDispatchToProps = {
    setSubscription: setSubscription
}

export default (withTranslation())(connect(mapStateToProps, mapDispatchToProps)(ActivateNotificationDialog));