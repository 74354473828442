import * as React from "react"
import PropTypes from "prop-types";

function FocusSeatFloorplanSvgIcon({color, width, height, strokeWidth, ...props}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 40 40"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <g transform="translate(12 12)">
                    <circle cx={8} cy={8} r={7.25} stroke={color} strokeWidth={strokeWidth}/>
                    <circle cx={8} cy={8} r={3} fill={color}/>
                </g>
                <rect
                    width={24}
                    height={24}
                    x={8}
                    y={8}
                    stroke={color}
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                    rx={12}
                />
            </g>
        </svg>
    )
}

FocusSeatFloorplanSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    strokeWidth: PropTypes.number,
};

FocusSeatFloorplanSvgIcon.defaultProps = {
    color: '#333',
    width: 40,
    height: 40,
    strokeWidth: 1.5,
};

export default FocusSeatFloorplanSvgIcon
