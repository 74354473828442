import moment from "moment";

import {cloneDeep, isNumber} from "lodash";

import {
    CLOCKED_IN,
    CLOCKED_OUT,
    ON_BREAK,
    WORKDURATION_AFTERNOON,
    WORKDURATION_FULL_DAY,
    WORKDURATION_MORNING
} from "./NameUtils";

export const TIME_REGEX = '([0-1][0-9]|2[0-3]):[0-5][0-9]'
export const TIMETRACKING_MORNING_LIMIT = 5
export const TIMETRACKING_EVENING_LIMIT = 21

export function isToday(date) {
    return isSameDay(date, moment())
}

export function isSameDay(date1, date2) {
    date1 = new Date(date1)
    date2 = new Date(date2)
    return (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate())
}

export function isSameDayOrBefore(date1, date2) {
    date1 = new Date(date1)
    date2 = new Date(date2)
    return date1 <= date2 || isSameDay(date1, date2)
}

export function isWeekend(date) {
    const parsedDate = new Date(date)
    return parsedDate.getDay() === 0 || parsedDate.getDay() === 6;
}

export function getDateWithoutTime(date) {
    return moment(date).format('YYYY-MM-DD')
}

export function takesPlaceRightNow(timePeriod) {
    if (!timePeriod || !timePeriod.startDate || !timePeriod.endDate)
        return false
    return moment(timePeriod.startDate).isBefore(moment.utc(moment())) && moment(timePeriod.endDate).isAfter(moment.utc(moment.now()))
}

export function startingInNext15Minutes(timePeriod) {
    if (!timePeriod || !timePeriod.startDate || !timePeriod.endDate)
        return false

    let minutes = Math.round(moment(timePeriod.startDate).minute() / 15) * 15;
    let relevantMoment = moment.utc(moment.now()).toDate()
    relevantMoment.setMinutes(minutes)
    return moment(timePeriod.startDate).isSame(relevantMoment, 'm') && moment(timePeriod.endDate).isAfter(relevantMoment)
}

export function inOneHour(dateTime, considerEndOfWorkingDay) {
    let inOneHourDateTime = moment.utc(moment(dateTime)).add(1, 'h').toDate()
    if (considerEndOfWorkingDay && inOneHourDateTime.getHours() >= getEndOfWorkingDay()) {
        inOneHourDateTime.setHours(getEndOfWorkingDay(), 0, 0, 0)
    }
    return inOneHourDateTime
}

export function getNextWeekDay() {
    let today = new Date();
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    while (isWeekend(tomorrow)) {
        tomorrow.setDate(tomorrow.getDate() + 1)
    }
    return tomorrow
}

export function sortAssignments(assignments) {
    if (!assignments)
        return []
    return assignments.sort((x, y) => {
        let diff = moment(x.timePeriod.startDate).diff(y.timePeriod.startDate)
        if (diff !== 0)
            return diff

        if (x.title)
            return x.title.localeCompare(y.title)

        return 0
    })
}

export function isAfterRightNow(someDate) {
    const today = new Date()
    return someDate > today
}

export function getDaytimeAsTimePeriod(daytime, specificDate, workingHours) {
    let date = specificDate ? specificDate : new Date() + 1
    const halfTime = getHalfTime(workingHours.startDate, workingHours.endDate)

    switch (daytime) {
        case WORKDURATION_MORNING :
            return getTimePeriodAtDate({startDate: workingHours.startDate, endDate: halfTime}, date)
        case WORKDURATION_AFTERNOON :
            return getTimePeriodAtDate({startDate: halfTime, endDate: workingHours.endDate}, date)
        case WORKDURATION_FULL_DAY :
        default:
            return getTimePeriodAtDate(workingHours, date)
    }
}

/**
 * Returns full-day if there are no workplace assignments for that day, morning/afternoon if there is a workplace assignment for half a day, else null
 */
export function getFreeDaytimeFromDisabledIntervals(disabledIntervals, date, workingHoursTimeperiod) {
    let morningTimeperiod = getDaytimeAsTimePeriod(WORKDURATION_MORNING, date, workingHoursTimeperiod)
    let morning = isTimeperiodOverlappingDisabledIntervals(morningTimeperiod, disabledIntervals)
    let afternoonTimeperiod = getDaytimeAsTimePeriod(WORKDURATION_AFTERNOON, date, workingHoursTimeperiod)
    let afternoon = isTimeperiodOverlappingDisabledIntervals(afternoonTimeperiod, disabledIntervals)

    if (morning && afternoon) {
        return null
    }

    const halfTime = getHalfTime(workingHoursTimeperiod.startDate, workingHoursTimeperiod.endDate)

    if (morning) {
        return {startDate: halfTime, endDate: workingHoursTimeperiod.endDate}
    } else if (afternoon) {
        return {startDate: workingHoursTimeperiod.startDate, endDate: halfTime}
    } else {
        return workingHoursTimeperiod
    }
}

export function getEndOfWorkingDay() {
    return Number(process.env.REACT_APP_MORNING_WORKING_DAY_HOUR) + Number(process.env.REACT_APP_DURATION_WORKING_DAY_IN_HOUR)
}

export function getMiddleOfWorkingDay() {
    return Number(process.env.REACT_APP_MORNING_WORKING_DAY_HOUR) + Number(process.env.REACT_APP_DURATION_HALF_WORKING_DAY_IN_HOUR)
}

export function getHalfTime(startTime, endTime) {
    return moment(startTime).clone().add(Math.round(getDiffInMinutesAbsolute(startTime, endTime) / 2), 'minutes')
}

export function getStartOfWorkingDay() {
    return Number(process.env.REACT_APP_MORNING_WORKING_DAY_HOUR)
}

export function getDateAtHours(date, hours, minutes) {
    let parsedDate = moment(date)
    parsedDate.set({hour: hours, minute: minutes ? minutes : 0, second: 0, millisecond: 0})
    return parsedDate
}

export function getTodayAtDefaultStartOfWorkingDay() {
    return moment().set({hour: getStartOfWorkingDay(), minute: 0, second: 0, millisecond: 0})
}

export function getTodayAtDefaultEndOfWorkingDay() {
    return moment().set({hour: getEndOfWorkingDay(), minute: 0, second: 0, millisecond: 0})
}

export function getDateAtStartOfWorkingDay(date) {
    return getDateAtHours(date, getStartOfWorkingDay())
}

export function getDateAtMiddleOfWorkingDay(date) {
    return getDateAtHours(date, getMiddleOfWorkingDay())
}

export function getDateAtEndOfWorkingDay(date) {
    return getDateAtHours(date, getEndOfWorkingDay())
}

export function getDaytimeAsTimeString(dayTime) {
    let start, end
    switch (dayTime) {
        case WORKDURATION_MORNING:
            start = process.env.REACT_APP_MORNING_WORKING_DAY_HOUR
            end = parseInt(start) + parseInt(process.env.REACT_APP_DURATION_HALF_WORKING_DAY_IN_HOUR)
            break
        case WORKDURATION_AFTERNOON:
            start = process.env.REACT_APP_AFTERNOON_WORKING_DAY_HOUR
            end = parseInt(start) + parseInt(process.env.REACT_APP_DURATION_HALF_WORKING_DAY_IN_HOUR)
            break
        case WORKDURATION_FULL_DAY:
            start = process.env.REACT_APP_MORNING_WORKING_DAY_HOUR
            end = parseInt(start) + parseInt(process.env.REACT_APP_DURATION_WORKING_DAY_IN_HOUR)
            break
        default:
            return ''
    }
    return start + ':' + process.env.REACT_APP_START_WORKING_DAY_MINUTE + ' - ' + end + ':' + process.env.REACT_APP_START_WORKING_DAY_MINUTE
}

export function getTimeAsString(time) {
    return moment(time).format('HH:mm')
}

export function getTimePeriodAsString(timePeriod) {
    if (!timePeriod)
        return '- - -'
    return moment(timePeriod.startDate).format('HH:mm') + ' - ' + moment(timePeriod.endDate).format('HH:mm')
}

export function getDurationAsString(timePeriod) {
    return minutesToDurationString(getDurationInMinutes(timePeriod))
}

export function getDurationInMinutes(timePeriod) {
    let diff = moment.duration(moment(timePeriod.endDate).diff(moment(timePeriod.startDate)));
    return diff.asMinutes()
}

//returns passed minutes as hour as float to display in diagramm, e.g. 90 min = 1h 30 min -> 1:3
export function minutesToDurationString(minutes) {
    let hours = Math.floor(minutes / 60)
    if (hours < 0)
        hours = 0
    minutes = minutes - hours * 60
    minutes = String(Math.floor(minutes)).padStart(2, '0')
    return hours + ':' + minutes
}

//returns the diff between 2 dates as string with units
export function dateDiffToDurationStringWithUnits(date1, date2) {
    let diff = moment.duration(moment(date2).diff(moment(date1)));
    let minutes = diff.asMinutes()
    return minutesToDurationStringWithUnits(minutes)
}

//returns the absolute diff between 2 dates in minutes
export function getDiffInMinutesAbsolute(date1, date2) {
    let diff = moment.duration(moment(date2).diff(moment(date1)));
    return Math.abs(diff.asMinutes())
}

//returns passed minutes separated in hours and minutes, e.g. 90 min = 1h 30 min
export function minutesToDurationStringWithUnits(minutes, showZeros) {
    if (Math.floor(minutes) === 0 && !showZeros)
        return '-'

    let timeString = ''
    let hours = Math.floor(minutes / 60)

    if (hours < 0 || !isNumber(hours))
        hours = 0
    else
        timeString = hours.toString() + '\u{202F}h '

    minutes = minutes - hours * 60
    minutes = String(Math.floor(minutes)).padStart(2, '0')

    return timeString.concat(minutes.toString() + '\u{202F}m')
}

//returns passed minutes as hour as float, e.g. 90 min = 1h 30 min -> 1.5
export function minutesToDecimalHour(minutes) {
    let hours = Math.floor(minutes / 60)
    minutes = Math.floor(minutes - hours * 60)
    minutes = Math.round(minutes / 60 * 100) / 100
    return hours + minutes
}

export function minutesToHours(minutes) {
    if (minutes <= 0)
        return 0
    return (Math.round(minutes / 15) / 4).toFixed(2)
}

export function isOnBreak(clockState) {
    return clockState === ON_BREAK
}

export function isClockedIn(clockState) {
    return clockState === CLOCKED_IN
}

export function isClockedOut(clockState) {
    return clockState === CLOCKED_OUT
}

export function getWorkingTimeInMinutes(timeTrackings) {
    let totalWorkingMinutes = 0

    for (let timeTracking of timeTrackings) {
        if (!timeTracking.clockInTime)
            continue

        const end = timeTracking.clockOutTime ?? moment.now()

        let durationInMinutes = getDurationInMinutes({
            startDate: timeTracking.clockInTime,
            endDate: end
        })

        if (durationInMinutes > 0)
            totalWorkingMinutes += durationInMinutes
    }

    let breakMinutes = getTotalBreakMinutes(timeTrackings)
    let result = totalWorkingMinutes - breakMinutes
    if (result < 0)
        return 0
    return totalWorkingMinutes - breakMinutes
}

export function getWorkedHoursAsString(timeTrackings, showZeros) {
    return minutesToDurationStringWithUnits(getWorkingTimeInMinutes(timeTrackings), showZeros)
}

export function getTotalBreakMinutes(timeTrackings) {
    let totalBreakTime = 0

    for (let timeTracking of timeTrackings) {
        if (!timeTracking || !timeTracking.breaks)
            continue

        for (let breakObj of timeTracking.breaks) {
            totalBreakTime += getBreakMinutes(breakObj)
        }
    }
    return totalBreakTime
}

export function getBreakMinutes(breakObj) {
    const end = breakObj.endTime ? breakObj.endTime : new Date()
    const breakMinutes = getDurationInMinutes({startDate: breakObj.startTime, endDate: end})
    return breakMinutes >= 0 ? breakMinutes : 0
}

export function getTrackingsBreakHoursAsString(timeTrackings, showZeros) {
    return minutesToDurationStringWithUnits(getTotalBreakMinutes(timeTrackings), showZeros)
}

export function getBreakHoursAsString(breakEntry) {
    return minutesToDurationStringWithUnits(getBreakMinutes(breakEntry))
}

export function getDayDiffOfTimePeriod(timeperiod) {
    return getDayDiff(timeperiod.startDate, timeperiod.endDate)
}

export function getDayDiff(start, end) {
    return moment(end).diff(moment(start), 'days')
}

export function setNewDateForTime(originalDate, dateToSetTo) {
    let dateToSetToAsDate = new Date(dateToSetTo)
    let newDate = new Date(originalDate)
    newDate.setFullYear(dateToSetToAsDate.getFullYear(), dateToSetToAsDate.getMonth(), dateToSetToAsDate.getDate())
    return newDate
}

export function isSameHoursAndMinute(date1, date2, roundToNearestMinutes) {
    if (roundToNearestMinutes) {
        let roundedDate1 = moment(date1).seconds() >= 30 ? moment(date1).add(1, 'minute') : moment(date1)
        let roundedDate2 = moment(date2).seconds() >= 30 ? moment(date2).add(1, 'minute') : moment(date2)

        return roundedDate1.format('HH:mm') === roundedDate2.format('HH:mm')
    }
    return moment(date1).format('HH:mm') === moment(date2).format('HH:mm')
}

export function roundToNearestXMinutes(dateTime, minutesToRoundTo) {
    const coeff = 1000 * 60 * minutesToRoundTo;
    return moment(Math.round(new Date(dateTime).getTime() / coeff) * coeff)
}

export function roundToNext15Minutes(date) {
    date = moment(date).toDate()
    date.setMinutes(Math.ceil(moment().minute() / 15) * 15, 0, 0)
    return date
}

export function getTimePeriodAtDate(timePeriod, date) {
    if (!timePeriod || !date)
        return null

    const start = getTimeAtDate(timePeriod.startDate, date)
    const end = getTimeAtDate(timePeriod.endDate, date)

    return {startDate: start, endDate: end}
}

export function getDateAsTimePeriod(date) {
    if (!date)
        return null

    const start = moment(date).startOf('day')
    const end = moment(date).endOf('day')

    return {startDate: start, endDate: end}
}

export function getTimeAtDate(time, date) {
    if (!time || !date)
        return null
    time = new Date(time)

    return moment(date).set({
        hour: time.getHours(),
        minute: time.getMinutes(),
        second: 0
    })
}

export function isTimeperiodOverlappingDisabledIntervals(timePeriod, disabledIntervals) {
    if (!timePeriod || !disabledIntervals)
        return false

    const tpStart = moment(timePeriod.startDate)
    const tpEnd = moment(timePeriod.endDate)
    const granularity = 'minute'

    return disabledIntervals.some(interval => moment(interval.start).isBefore(tpEnd, granularity) && moment(interval.end).isAfter(tpStart, granularity))
}

export function areTimeperiodsEqual(tp1, tp2, unit) {
    return moment(tp1.startDate).isSame(moment(tp2.startDate), unit ?? 'minute') && moment(tp1.endDate).isSame(moment(tp2.endDate), unit ?? 'minute')
}

export function getEndTimeOfLastInterval(intervals, dayFilter) {
    let relevantIntervals = cloneDeep(intervals)

    if (dayFilter) {
        let day = moment(dayFilter).dayOfYear()
        relevantIntervals = relevantIntervals.filter(i => moment(i.start).dayOfYear() === day)
    }

    if (!relevantIntervals || !relevantIntervals.length)
        return new Date(0, 0, 0, 0, 0)

    if (relevantIntervals.length === 1)
        return relevantIntervals[0].end

    let lastTime = relevantIntervals[0].end

    for (let interval of relevantIntervals) {
        if (interval.end > lastTime)
            lastTime = interval.end
    }

    return lastTime
}

