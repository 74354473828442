import React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel, FormGroup, makeStyles, Switch} from "@material-ui/core";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',

        '& .MuiSwitch-switchBase': {
            color: theme.colors.palette.neutral.greyMain,
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            opacity: '0.6',
            backgroundColor: theme.colors.palette.neutral.greyMedium,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: theme.colors.palette.neutral.darkMain,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: '0.6',
            backgroundColor: theme.colors.palette.neutral.darkMain,
        },
    },
    switch: {
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        fontVariant: theme.typography.h5
    },
}));

function ToggleButton({title, value, onChange, ...props}) {
    const classes = useStyle(props.theme);

    return (
        <FormGroup className={classes.root}>
            <FormControlLabel labelPlacement="start" className={classes.switch}
                              control={<Switch/>} label={title} checked={value}
                              onChange={onChange}/>
        </FormGroup>
    );
}

ToggleButton.propTypes = {
    title: PropTypes.string,
    value: PropTypes.bool.isRequired
};
ToggleButton.defaultPropTypes = {};
export default ToggleButton;