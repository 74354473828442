import * as React from "react"
import PropTypes from 'prop-types';

function NavigationArrowSvgIcon({reverse, fill, strokeWidth, size, ...props}) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" style={{transform: reverse ? '' : 'rotate(180deg)'}}
             transform={reverse ? '' : 'rotate(180)'} {...props}>
            <path fill={fill} stroke={fill} strokeWidth={strokeWidth} fillRule="evenodd"
                  d="M5 8l5.263-5 .737.7L6.474 8 11 12.3l-.737.7z"
            />
        </svg>
    )
}

NavigationArrowSvgIcon.propTypes = {
    reverse: PropTypes.bool,
    fill: PropTypes.string,
    size: PropTypes.number
}

NavigationArrowSvgIcon.defaultProps = {
    reverse: false,
    fill: '#333',
    strokeWidth: 0.1,
    size: 16
}

export default NavigationArrowSvgIcon