import * as React from "react"
import PropTypes from 'prop-types'

function AllBuildingsSvgIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <g
                {...props}
                stroke={props.color}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round">
                <rect x={10.5} y={10.5} width={3} height={3} rx={1}/>
                <rect x={10.5} y={4.5} width={3} height={3} rx={1}/>
                <rect x={10.5} y={16.5} width={3} height={3} rx={1}/>
                <rect x={4.5} y={10.5} width={3} height={3} rx={1}/>
                <rect x={4.5} y={4.5} width={3} height={3} rx={1}/>
                <rect x={4.5} y={16.5} width={3} height={3} rx={1}/>
                <rect x={16.5} y={10.5} width={3} height={3} rx={1}/>
                <rect x={16.5} y={4.5} width={3} height={3} rx={1}/>
                <rect x={16.5} y={16.5} width={3} height={3} rx={1}/>
            </g>
        </svg>
    )
}

AllBuildingsSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
}

AllBuildingsSvgIcon.defaultProps = {
    width: 24,
    height: 24,
    color: '#333',
}


export default AllBuildingsSvgIcon
