import React, {useEffect} from 'react';

import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

import SingleLineDatePicker from "../SingleLineDatePicker";
import CurrentOccupancyListComponent from "./CurrentOccupancyListComponent";

import {setSelectedDate} from "../../actions/workplaceAssignment-action.js";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    datePicker: {
        boxSizing: 'border-box',
        marginBottom: '1.2rem',
    },
}));

function CurrentOccupancyList({selectedDate, setSelectedDate, onSelectAssignment}) {

    const theme = useTheme()
    const classes = useStyle(theme)

    useEffect(() => {
        setSelectedDate(new Date())
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>
            <SingleLineDatePicker className={classes.datePicker} onChange={date => setSelectedDate(date)}
                                  startDate={new Date()} selected={selectedDate || new Date()}
                                  color={theme.colors.gradients.skinMain}
            />
            <CurrentOccupancyListComponent onSelectAssignmentCallback={onSelectAssignment}/>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedDate: state.assignmentBuilder.selectedDate,
    }
}

const mapDispatchToProps = {
    setSelectedDate: setSelectedDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentOccupancyList)