import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import CustomButton from "../../components/CustomButton.js";

const useStyles = (props) => makeStyles(theme => ({
    root: {
        ...props.innerClasses.root,
    },
    buttonText: {
        ...props.innerClasses.buttonText,
    },
}));

function AdminButton({
                         fullWidth,
                         halfWidth,
                         className,
                         preventDefault,
                         ...props
                     }) {

    const theme = useTheme()
    const classes = useStyles(props)(theme);

    return (
        <CustomButton customClassName={classes.root} style={{
            width: fullWidth ? '100%' : halfWidth ? '50%' : '20rem',
            maxWidth: !fullWidth && !halfWidth ? '20rem' : null,
            height: '4rem',
            maxHeight: '4rem',
            minHeight: '4rem',
            borderRadius: theme.admin.borderRadius,
        }} variant={'h4'} {...props}/>
    );
}

AdminButton.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    background: PropTypes.string,
    fullWidth: PropTypes.bool,
    halfWidth: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.any,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    preventDefault: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    innerClasses: PropTypes.object,
}

AdminButton.defaultProps = {
    background: '#ffffff',
    textColor: '#000',
    type: 'button',
    innerClasses: {},
};

export default AdminButton;