import * as React from "react"
import PropTypes from "prop-types";
import theme from "../../theme.js";

function MorningSvgIcon({color, size, ...props}) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 40 40"
            {...props}
        >
            <path
                d="M11.5 14.5h17v14a3 3 0 01-3 3h-12a3 3 0 01-3-3v-13a1 1 0 011-1zm17 0h1c2.21 0 4 1.79 4 4s-1.79 4-4 4h-1 0v-8zm-8.5-3a3.41 3.41 0 00-2.175-3.179l-1.65-.642A3.411 3.411 0 0114 4.5h0"
                stroke={color}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            />
        </svg>

    )
}

MorningSvgIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

MorningSvgIcon.defaultProps = {
    color: theme.colors.palette.neutral.darkMain,
    size: 24,
};


export default MorningSvgIcon