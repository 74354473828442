import React from 'react';
import PropTypes from 'prop-types';

import {withTranslation} from "react-i18next";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import {DialogActions, DialogContent, DialogTitle, makeStyles} from "@material-ui/core";

import ActivityButtonRound from "./ActivityButtonRound";

import ConcentrateWorkingSvgIcon from "../../common/icons/ConcentrateWorkingSvgIcon";
import OneOnOneSvgIcon from "../../common/icons/OneOnOneSvgIcon";
import PhoneSvgIcon from "../../common/icons/PhoneSvgIcon";
import BrainstormingSvgIcon from "../../common/icons/BrainstormingSvgIcon";
import UndefinedSvgIcon from "../../common/icons/UndefinedSvgIcon";
import ActivityExplanation from "./ActivityExplanation";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    modalPaper: {
        width: '100%',
        borderRadius: '18px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2.1rem',
    },
}));

function ActivitiesExplanationModal({open, onClose, t}) {

    const theme = useTheme()
    const classes = useStyles(theme);

    const iconSize = 22
    const circleSize = 34

    const onCloseModal = () => {
        if (onClose)
            onClose()
    }

    return (
        <Dialog open={open} className={classes.root} onClose={() => onCloseModal()}
                classes={{paper: classes.modalPaper}}>
            <DialogTitle disableTypography>
                <Typography variant={'h3'}>
                    {t("activities")}
                </Typography>
            </DialogTitle>

            <DialogContent className={classes.content}>
                <ActivityExplanation title={t('activity_concentrate')} description={t('activity_concentrate_desc')}
                                     icon={
                                         <ActivityButtonRound size={circleSize}
                                                              color={theme.colors.palette.corporate.skinMain}
                                                              selectable={false}>
                                             <ConcentrateWorkingSvgIcon width={iconSize} height={iconSize}
                                                                        color={theme.colors.palette.neutral.white}/>
                                         </ActivityButtonRound>
                                     }/>
                <ActivityExplanation title={t('activity_talk')} description={t('activity_talk_desc')}
                                     icon={
                                         <ActivityButtonRound size={circleSize}
                                                              color={theme.colors.palette.corporate.blueMain}
                                                              selectable={false}>
                                             <OneOnOneSvgIcon width={iconSize} height={iconSize}
                                                              color={theme.colors.palette.neutral.white}/>
                                         </ActivityButtonRound>
                                     }/>
                <ActivityExplanation title={t('activity_calls')} description={t('activity_calls_desc')}
                                     icon={
                                         <ActivityButtonRound size={circleSize}
                                                              color={theme.colors.palette.indicator.greenMain}
                                                              selectable={false}>
                                             <PhoneSvgIcon width={iconSize} height={iconSize}
                                                           color={theme.colors.palette.neutral.white}/>
                                         </ActivityButtonRound>
                                     }/>
                <ActivityExplanation title={t('activity_creative')} description={t('activity_creative_desc')}
                                     icon={
                                         <ActivityButtonRound size={circleSize}
                                                              color={theme.colors.palette.indicator.purpleMain}
                                                              selectable={false}>
                                             <BrainstormingSvgIcon width={iconSize} height={iconSize}
                                                                   color={theme.colors.palette.neutral.white}/>
                                         </ActivityButtonRound>
                                     }/>
                <ActivityExplanation title={t('activity_undefined')} description={t('activity_undefined_desc')}
                                     icon={
                                         <ActivityButtonRound size={circleSize}
                                                              color={theme.colors.palette.indicator.pink}
                                                              selectable={false}>
                                             <UndefinedSvgIcon width={iconSize} height={iconSize}
                                                               color={theme.colors.palette.neutral.white}/>
                                         </ActivityButtonRound>
                                     }/>
            </DialogContent>

            <DialogActions>
                <Button onClick={onCloseModal}>
                    {t("got_it")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ActivitiesExplanationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

ActivitiesExplanationModal.defaultProps = {
    open: false,
};

export default withTranslation()(ActivitiesExplanationModal);