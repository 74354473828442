import * as React from "react"

function ZoomResetFloorplanSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g stroke="#333" strokeWidth={1.5} fill="none" fillRule="evenodd">
                <path d="M9.742 31l21.78-21.866M9.581 21v10h10m2-22h10v10"/>
            </g>
        </svg>
    )
}

export default ZoomResetFloorplanSvgIcon
