const initialState = {
    resourceCategories: [],
    selectedCategory: null,
    bookableFreeResources: [],
    assignResourcePending: false,
    resourceAssignments: [],
    tagsInCategory: [],

}

function resourceReducer(state = initialState, action) {

    switch (action.type) {

        //region category
        case 'GET_RESOURCE_CATEGORIES_FULFILLED':
            return {
                ...state,
                resourceCategories: action.payload.data
            }

        case 'CREATE_RESOURCE_CATEGORY_FULFILLED':
            return {
                ...state,
                resourceCategories: [...state.resourceCategories.filter(category => category.id !== action.payload.data.id), action.payload.data]
            }

        case 'UPDATE_RESOURCE_CATEGORY_FULFILLED':
            return {
                ...state,
                resourceCategories: [...state.resourceCategories.filter(category => category.id !== action.meta.updatedCategoryId), action.payload.data]
            }

        case 'DELETE_RESOURCE_CATEGORY_FULFILLED':
            return {
                ...state,
                resourceCategories: [...state.resourceCategories.filter(category => category.id !== action.payload.data.id)]
            }

        case 'SET_SELECTED_RESOURCE_CATEGORY':
            return {
                ...state,
                selectedCategory: action.payload
            }
        //endregion

        //region resource
        case 'CREATE_RESOURCE_FULFILLED': {
            let categories = [...state.resourceCategories]
            let relevantCategory = categories.find(category => category.name === action.payload.data.categoryName)

            if (relevantCategory && relevantCategory.resources)
                relevantCategory.resources = [...relevantCategory.resources.filter(resource => resource.id !== action.payload.data.id), action.payload.data]

            return {
                ...state,
                resourceCategories: categories
            }
        }

        case 'UPDATE_RESOURCE_FULFILLED': {
            let categories = [...state.resourceCategories]
            let relevantCategory = categories.find(category => category.name === action.payload.data.categoryName)

            if (relevantCategory && relevantCategory.resources) {
                relevantCategory.resources = [...relevantCategory.resources.filter(resource => resource.id !== action.meta.updatedResourceId), action.payload.data]
            }

            return {
                ...state,
                resourceCategories: categories
            }
        }

        case 'DELETE_RESOURCE_FULFILLED': {
            let categories = [...state.resourceCategories]
            let relevantCategory = categories.find(category => category.name === action.payload.data.categoryName)

            if (relevantCategory && relevantCategory.resources)
                relevantCategory.resources = relevantCategory.resources.filter(resource => resource.id !== action.payload.data.id)

            return {
                ...state,
                resourceCategories: categories
            }
        }

        case 'GET_BOOKABLE_FREE_RESOURCES_FULFILLED': {
            return {
                ...state,
                bookableFreeResources: action.payload.data
            }
        }

        case 'ASSIGN_RESOURCE_TO_USER_PENDING': {
            return {
                ...state,
                assignResourcePending: true
            }
        }

        case 'ASSIGN_RESOURCE_TO_USER_FULFILLED':
        case 'ASSIGN_RESOURCE_TO_USER_REJECTED': {
            return {
                ...state,
                assignResourcePending: false
            }
        }

        case 'GET_RESOURCE_ASSIGNMENTS_FULFILLED': {
            return {
                ...state,
                resourceAssignments: action.payload.data
            }
        }

        case 'DELETE_RESOURCE_ASSIGNMENT_FULFILLED':
            let assignments = [...state.resourceAssignments].filter(assign => assign.id !== action.payload.data.id)

            return {
                ...state,
                resourceAssignments: assignments,
            }

        //endregion

        default:
            return state
    }
}

export default resourceReducer