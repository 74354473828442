import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = props => makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${props.error ? theme.colors.palette.corporate.redMain : props.color || theme.colors.palette.neutral.darkMain} !important`,
        },
        "& .MuiInputBase-input:invalid": {
            color: theme.colors.palette.corporate.redMain,
        },
        ...props.innerClasses.root,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...props.innerClasses.header,
    },
    textField: {
        borderRadius: props.admin ? theme.admin.borderRadius : '12px',
        minHeight: props.maxHeightManual ? props.maxHeightManual : props.admin ? '4.1rem' : '5.4rem',
        height: props.rows ? null : props.admin ? '4.1rem' : '5.4rem',
        boxSizing: 'border-box',
        color: props.error ? theme.colors.palette.corporate.redMain : theme.colors.palette.neutral.darkMain,
        ...props.innerClasses.textField,
    },
    innerInput: {
        marginBottom: '0px !important', //prevent autofill margin
        ...props.innerClasses.innerInput,
    },
    outline: {
        borderColor: `${props.error
            ? theme.colors.palette.corporate.redMain
            : theme.colors.palette.neutral.greyMedium} !important`,
        ...props.innerClasses.outline,
    },
    active: {
        border: 'solid 2px',
        borderColor: `${props.error
            ? theme.colors.palette.corporate.redMain
            : theme.colors.palette.neutral.black
        } !important`,
        ...props.innerClasses.active,
    },
    disabled: {
        '& .MuiOutlinedInput-input': {
            color: theme.colors.palette.neutral.greyMain
        },
        ...props.innerClasses.disabled,
    },
    text: {
        color: `${props.error
            ? theme.colors.palette.corporate.redMain
            : theme.colors.palette.neutral.greyMain
        } !important`,
        ...props.innerClasses.text,
    }
}));

function CustomTextField({
                             title,
                             info,
                             value,
                             onChange,
                             onValidChange,
                             onKeyDown,
                             className,
                             autoComplete,
                             contentLeft,
                             contentRight,
                             pattern,
                             min,
                             max,
                             placeholder,
                             autoFocus,
                             type,
                             disabled,
                             inputProps,
                             wrapperClassName,
                             onFocus,
                             onBlur,
                             active,
                             readOnly,
                             variant,
                             ...props
                         }) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);

    const [isValid, setIsValid] = useState(!props.error);

    const {rows, error, admin} = props
    delete props.error
    delete props.innerClasses
    delete props.maxHeightManual
    delete props.admin
    delete props.rows

    const input = useRef()

    const onValueChanged = (value) => {
        if (onChange)
            onChange(value)

        if (input.current && input.current.validity.valid !== isValid) {
            if (onValidChange)
                onValidChange(input.current.validity.valid)

            setIsValid(input.current.validity.valid)
        }
    }

    return (
        <div className={clsx(classes.root, wrapperClassName)}>

            {(title || info) &&
                <div className={classes.header}>
                    {title
                        ? <Typography variant={admin ? 'caption' : 'body1'} className={classes.text}
                                      style={{textAlign: 'left'}}>
                            {title}
                        </Typography>
                        : null}

                    {info ? info : null}
                </div>}

            <TextField className={clsx(classes.textField, className, disabled && classes.disabled)}
                       onFocus={onFocus}
                       error={!!error}
                       value={value}
                       inputRef={input}
                       onChange={onValueChanged}
                       onKeyDown={onKeyDown}
                       onBlur={onBlur}
                       disabled={disabled}
                       type={type ?? 'text'}
                       variant={variant ?? 'outlined'}
                       autoComplete={autoComplete ? 'on' : 'off'}
                       placeholder={placeholder ?? ''}
                       minRows={rows ? rows : 1}
                       multiline={rows > 1}
                       autoFocus={autoFocus}
                       {...props}
                       InputProps={{
                           classes: {
                               root: classes.textField,
                               notchedOutline: active ? classes.active : classes.outline,
                           },
                           inputProps: {
                               className: classes.innerInput,
                               pattern: pattern ? pattern : null,
                               minLength: min ? min : 0,
                               maxLength: max ? max : 100,
                           },
                           readOnly: readOnly,
                           startAdornment: contentLeft ? contentLeft : null,
                           endAdornment: contentRight ? contentRight : null,
                           ...inputProps
                       }}>

            </TextField>
        </div>
    )
}

CustomTextField.propsTypes = {
    title: PropTypes.string,
    info: PropTypes.node,
    color: PropTypes.string,
    autComplete: PropTypes.bool,
    onValidChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    contentLeft: PropTypes.node,
    contentRight: PropTypes.node,
    error: PropTypes.bool,
    min: PropTypes.number,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    rows: PropTypes.number,
    autoFocus: PropTypes.bool,
    max: PropTypes.number,
    maxHeightManual: PropTypes.number,
    disabled: PropTypes.bool,
    inputProps: PropTypes.any,
    wrapperClassName: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    readOnly: PropTypes.bool,
    innerClasses: PropTypes.object,
    admin: PropTypes.bool,
}

CustomTextField.defaultProps = {
    color: null,
    autoComplete: true,
    innerClasses: {},
    admin: false,
    onKeyDown: (e) => {
    }
}

export default CustomTextField;