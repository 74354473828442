import React from 'react';
import {navigate} from "../../common/utils/NavigationUtils.js";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import SimpleListItem from "../../common/elements/SimpleListItem.js";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        alignItems: 'center',
    },
    settingsContainer: {
        width: '100%',
    },
    icon: {
        width: '3rem',
        height: '3rem',
    }
}))

function ProfileMenuSettings({menuItems, title}) {
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);


    return (
        <div className={classes.root}>
            <div className={classes.settingsContainer}>
                {menuItems && menuItems.map(item => {
                    const icon = React.cloneElement(item.icon, {className: classes.icon})
                    return <SimpleListItem onClick={() => navigate(history, item.path)}
                                           title={item.title}
                                           key={item.title}
                                           icon={icon}/>
                })}
            </div>
        </div>
    )
}

ProfileMenuSettings.propTypes = {
    menuItems: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
}

export default ProfileMenuSettings