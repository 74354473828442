import * as React from "react"

function SettingsIconSvg(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g
                {...props}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path
                    d="M35.798 17.281a3.742 3.742 0 01-2.572-6.532.945.945 0 00.106-1.288 17.14 17.14 0 00-2.698-2.72.95.95 0 00-1.296.105A3.91 3.91 0 0125.1 7.8a3.788 3.788 0 01-2.293-3.693.952.952 0 00-.837-.996 17.14 17.14 0 00-3.823-.01.949.949 0 00-.844.969 3.778 3.778 0 01-2.33 3.625 3.947 3.947 0 01-4.21-.956.946.946 0 00-1.283-.105 16.882 16.882 0 00-2.749 2.72.955.955 0 00.099 1.296 3.763 3.763 0 01.963 4.25 3.932 3.932 0 01-3.701 2.285.925.925 0 00-.98.837c-.15 1.283-.15 2.58 0 3.862.044.497.623.837 1.109.837a3.742 3.742 0 013.497 2.333 3.784 3.784 0 01-.95 4.202.945.945 0 00-.105 1.286 16.821 16.821 0 002.69 2.72.95.95 0 001.297-.099 3.923 3.923 0 014.245-.962 3.764 3.764 0 012.296 3.69c-.027.5.34.936.837.996.65.074 1.305.111 1.96.112a16.19 16.19 0 001.867-.105.949.949 0 00.843-.976 3.77 3.77 0 012.324-3.625 3.926 3.926 0 014.2.969.946.946 0 001.283.105 17.151 17.151 0 002.748-2.72.948.948 0 00-.098-1.295 3.74 3.74 0 012.53-6.546h.211a.952.952 0 00.997-.837c.143-1.28.143-2.572 0-3.852-.054-.48-.612-.837-1.095-.837z"/>
                <circle cx={20} cy={20} r={6}/>
            </g>
        </svg>
    )
}

export default SettingsIconSvg
