import * as React from "react"

function ProfileSvgIcon(props) {
    return (
        <svg width={'100%'} height={'100%'} viewBox="0 0 24 24" {...props}>
            <path
                d="M9 3c2.1 0 3.8 1.7 3.8 3.7s-1.7 3.8-3.8 3.8-3.8-1.7-3.8-3.8S6.9 3 9 3zm0-1.5c-2.9 0-5.2 2.3-5.2 5.2S6.1 12 9 12s5.2-2.4 5.2-5.3S11.9 1.5 9 1.5zm7.5 21H15v-3.8c0-2.1-1.7-3.7-3.8-3.7H6.8C4.7 15 3 16.7 3 18.7v3.8H1.5v-3.8c0-2.9 2.3-5.2 5.2-5.2h4.5c2.9 0 5.2 2.4 5.2 5.2v3.8zm0-19.5H24v1.5h-7.5V3zm0 3.7H24v1.5h-7.5V6.7zm0 3.8h5.2V12h-5.2v-1.5z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default ProfileSvgIcon