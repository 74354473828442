import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import ListSvgIcon from "../../common/icons/ListSvgIcon";
import LocationSvgIcon from "../../common/icons/LocationSvgIcon";
import {useHistory} from "react-router-dom";
import CurrentOccupancyList from "./CurrentOccupancyList";
import CurrentOccupancyFloorplan from "./CurrentOccupancyFloorplan";
import {navigate, PATH_HOME} from "../../common/utils/NavigationUtils";
import SwitchButtonGroup from "../SwitchButtonGroup.js";
import queryString from "query-string";
import {selectFavoriteWorkplace} from "../../actions/workplaceAssignment-action.js";
import OccupancyTimeSettingsModal from "./OccupancyTimeSettingsModal.js";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const styles = theme => ({
    root: {
        flex: 1,
        padding: theme.paddingContentContainer.padding,
        paddingBottom: '0px',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
    },
    content: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        maxWidth: theme.gridSizes.maxWidth,
        overflow: 'hidden'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    selection: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.innerSmallGap,
    },
    selectedIcon: {
        stroke: theme.colors.palette.neutral.white,
    },
    icon: {
        stroke: theme.colors.palette.neutral.greyMain,
        color: theme.colors.palette.neutral.greyMain,
    },
    switch: {
        maxWidth: '8.8rem',
        maxHeight: '5rem',
    }
})

function OccupancyPage({classes, t, selectFavoriteWorkplace, ...props}) {
    const history = useHistory()
    const modeSelectionParam = Number(queryString.parse(history.location.search).mode)
    const [listMode, setListMode] = useState(isNaN(modeSelectionParam) ? 1 : Number(queryString.parse(history.location.search).mode));
    const [timeSettingsModalOpen, setTimeSettingsModalOpen] = useState(false);

    const changeSelection = () => {
        setListMode(listMode ? 0 : 1)
    }

    useEffect(() => {
        const nMode = Number(queryString.parse(props.location.search).mode)

        if (nMode !== listMode)
            setListMode(nMode)
        // eslint-disable-next-line
    }, [props.location.search])

    useEffect(() => {
        if (modeSelectionParam !== listMode)
            navigate(history, `/occupancy?mode=${listMode}`)
        // eslint-disable-next-line
    }, [listMode]);

    return (
        <Grid container component={Paper} className={classes.root}>
            <CssBaseline/>
            <Grid item sm={12} className={classes.container}>
                <div className={classes.header}>
                    <HeaderWithTitleAndBackButton backLabel={t("home")} title={t('occupancy')}
                                                  onNavigateBack={() => navigate(history, PATH_HOME)}/>
                    <div className={classes.selection}>
                        <AccessTimeIcon onClick={() => setTimeSettingsModalOpen(true)}
                                        className={classes.icon}
                                        style={{
                                            cursor: 'pointer',
                                            width: '24px',
                                            height: '24px',
                                            strokeWidth: 0
                                        }}/>
                        <SwitchButtonGroup
                            options={[<ListSvgIcon className={listMode ? classes.selectedIcon : classes.icon}/>,
                                <LocationSvgIcon className={!listMode ? classes.selectedIcon : classes.icon}/>]}
                            onOptionClicked={changeSelection}
                            initialSelected={listMode ? 0 : 1}
                            className={classes.switch}/>
                    </div>
                </div>

                <div className={classes.content}>
                    {listMode
                        ? <CurrentOccupancyList onSelectAssignment={changeSelection}/>
                        : <CurrentOccupancyFloorplan/>
                    }
                </div>
            </Grid>
            <OccupancyTimeSettingsModal onClose={() => setTimeSettingsModalOpen(false)} open={timeSettingsModalOpen}/>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        userAssignments: state.user.userAssignments,
        assignments: state.workplace.assignments,
    }
}

const mapDispatchToProps = {
    selectFavoriteWorkplace: selectFavoriteWorkplace
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(OccupancyPage))