import * as React from "react"
import PropTypes from 'prop-types'

function FloorSvgIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fillRule="nonzero" stroke={props.color} fill="none" {...props}>
                <path d="m12 2.522 8.75 5.165L12 12.853 3.25 7.687z"/>
                <path d="M17.135 9.866 20.75 12 12 17.166 3.25 12l3.614-2.134L12 12.898l5.135-3.032Z"/>
                <path d="m17.248 14.12 3.502 2.067L12 21.353l-8.75-5.166 3.501-2.067L12 17.219l5.248-3.099Z"/>
            </g>
        </svg>
    )
}

FloorSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
}

FloorSvgIcon.defaultProps = {
    width: 24,
    height: 24,
    color: '#333',
}

export default FloorSvgIcon
