import * as React from "react"
import PropTypes from 'prop-types';

const InfoAlertSvgIcon = ({width, height, color, ...props}) => (
    <svg width={width} height={height} viewBox={'0 0 24 25'} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 5.3a7.2 7.2 0 1 1 0 14.4 7.2 7.2 0 0 1 0-14.4zm0 9.9a.9.9 0 1 0 0 1.8.9.9 0 0 0 0-1.8zm.55-7.2h-1v6.3h1V8z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)

InfoAlertSvgIcon.propTypes = {
    color: PropTypes.string,
};

InfoAlertSvgIcon.defaultProps = {
    width: 24,
    height: 25,
    color: '#F74E44'
};

export default InfoAlertSvgIcon