import * as React from "react"
import PropTypes from 'prop-types'

function TrashSvgIcon(props) {
    return (
        <svg {...props} width={props.width} height={props.height} viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd" strokeLinejoin="round" stroke={props.color} strokeWidth={1.5}>
                <path d="M11 10.5h18v22H11zM8 10.5h24M15 6.5h10M17.5 16v11M22.5 16v11"/>
            </g>
        </svg>
    )
}

TrashSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
}

TrashSvgIcon.defaultProps = {
    width: 24,
    height: 24,
    color: '#333',
}

export default TrashSvgIcon
