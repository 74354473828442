import React from "react";

import {
    TYPE_OF_ACTIVITY_CALLS,
    TYPE_OF_ACTIVITY_CONCENTRATE,
    TYPE_OF_ACTIVITY_CREATIVE,
    TYPE_OF_ACTIVITY_TALK,
    TYPE_OF_ACTIVITY_UNDEFINED
} from "./NameUtils";
import PhoneSvgIcon from "../icons/PhoneSvgIcon.js";
import ConcentrateWorkingSvgIcon from "../icons/ConcentrateWorkingSvgIcon.js";
import BrainstormingSvgIcon from "../icons/BrainstormingSvgIcon.js";
import UndefinedSvgIcon from "../icons/UndefinedSvgIcon.js";
import OneOnOneSvgIcon from "../icons/OneOnOneSvgIcon.js";
import i18n from "i18next";
import {getActivityColor, getWorkplaceImage} from "./AssignmentUtils.js";

export function getActivityText(activity) {
    switch (activity) {
        case TYPE_OF_ACTIVITY_CONCENTRATE:
            return {
                textBold: i18n.t('concentrate'),
                text: i18n.t('working')
            }
        case TYPE_OF_ACTIVITY_CALLS:
            return {
                textBold: i18n.t('making'),
                text: i18n.t('calls')
            }
        case TYPE_OF_ACTIVITY_CREATIVE:
            return {
                textBold: i18n.t('Brainstorming'),
                text: i18n.t('ideas')
            }
        case TYPE_OF_ACTIVITY_TALK:
            return {
                textBold: i18n.t('1_on_1'),
                text: i18n.t('conversations')
            }
        case TYPE_OF_ACTIVITY_UNDEFINED:
            return {
                textBold: i18n.t('undefined'),
                text: i18n.t('activities')
            }
        default:
            return {
                textBold: '',
                text: ''
            }
    }
}

export function getImageForAvatar(image, activity, size) {
    let style = {
        height: size ?? '100%',
        width: size ?? '100%',
        borderRadius: '24px',
        pointerEvents: 'none',
        backgroundColor: getActivityColor(activity)
    }

    if (image)
        return <img src={image} style={style} alt=''/>

    return <img src={getWorkplaceImage(null, activity)} style={style} alt=''/>
}

export function getActivityIcon(activity, key, className, style) {
    switch (activity) {
        case TYPE_OF_ACTIVITY_CALLS:
            return <PhoneSvgIcon className={className} key={key} style={style}/>
        case TYPE_OF_ACTIVITY_CONCENTRATE:
            return <ConcentrateWorkingSvgIcon className={className} key={key} style={style}/>
        case TYPE_OF_ACTIVITY_CREATIVE:
            return <BrainstormingSvgIcon className={className} key={key} style={style}/>
        case TYPE_OF_ACTIVITY_UNDEFINED:
            return <UndefinedSvgIcon className={className} key={key} style={style}/>
        case TYPE_OF_ACTIVITY_TALK:
            return <OneOnOneSvgIcon className={className} key={key} style={style}/>
        default:
            return null
    }
}