const initialState = {
    scrollPositionBottom: false,
}

function uiReducer(state = initialState, action) {
    switch (action.type) {
        case 'SCROLL_POSITION_BOTTOM':
            return {
                ...state,
                scrollPositionBottom: action.payload
            }

        default:
            return {...state}
    }
}

export default uiReducer;