import axios from "axios";

let cancelTokenSource;

export function getTotalWorkplaces(buildingId = -1, floorId = -1, excludeDisabled = false) {
    return {
        type: 'GET_TOTAL_WORKPLACES',
        payload: excludeDisabled ?
            axios.get(`/api/building/${buildingId}/floor/${floorId}/space/-1/workplace/totalWorkplaces?excludeDisabled=true`) :
            axios.get(`/api/building/${buildingId}/floor/${floorId}/space/-1/workplace/totalWorkplaces`)
    }
}

export function getCoworkerAssignmentsAtDateFiltered(personId, timePeriod, paginationToken, searchString, loadOffice, loadHomeoffice, loadOutOfOffice, loadVacation, resetPaginationToken) {
    if (cancelTokenSource && resetPaginationToken) {
        cancelTokenSource.cancel("Cancel request getCoworkerAssignmentsAtDateFiltered.")
    }
    cancelTokenSource = axios.CancelToken.source()

    return {
        type: 'GET_COWORKER_ASSIGNMENTS_AT_DATE_FILTERED',
        payload: axios.get(`/api/person/${personId}/coworkersAssignmentsFilter`, {
            params: {
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
                paginationToken: resetPaginationToken ? 0 : paginationToken,
                searchString: searchString,
                loadOffice: loadOffice,
                loadHomeoffice: loadHomeoffice,
                loadOutOfOffice: loadOutOfOffice,
                loadVacation: loadVacation,
            },
            cancelToken: cancelTokenSource.token
        }),
        meta: {
            resetPaginationToken: resetPaginationToken,
        }
    }
}

export function getWorkplaceAssignmentsStatistics(personId, date, rightNow, buildingId, floorId, spaceId) {
    return {
        type: 'GET_WORKPLACE_ASSIGNMENTS_STATISTICS',
        payload: axios.get(`/api/person/${personId}/occupancy/workplaceAssignments`, {
            params: {
                date: date,
                rightNow: rightNow,
                buildingId: buildingId,
                floorId: floorId,
                spaceId: spaceId,
            }
        })
    }
}

export function getProfilePictures(personId, floorId, date) {
    return {
        type: 'GET_PROFILE_PICTURES',
        payload: axios.get(`/api/person/${personId}/profilePictures`, {
            params: {
                floorId: floorId,
                date: date
            }
        }),
    }
}

export function getProfilePictureOfCoworker(personId) {
    return {
        type: 'GET_PROFILE_PICTURE_OF_COWORKER',
        payload: axios.get(`/api/person/${personId}/image`),
        meta: {
            personId: personId
        }
    }
}