import {Typography} from "@material-ui/core";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        marginTop: '6rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.4rem',
        height: '10rem'
    },
    loginText: {
        textAlign: 'left',
        color: theme.colors.palette.neutral.white,
    },
});

function NavigationBarDrawerContent({classes, text, boldText}) {

    return (
        <div className={classes.root}>
            <Typography className={classes.loginText}>
                {text}
            </Typography>
            <Typography variant={'h3'} className={classes.loginText}>
                {boldText}
            </Typography>
        </div>
    );
}

NavigationBarDrawerContent.propTypes = {
    text: PropTypes.string,
    boldText: PropTypes.string,
}

export default withStyles(styles)(NavigationBarDrawerContent);

