import React from 'react';
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {getTimeAsString} from "../utils/TimeUtils.js";

const useStyle = makeStyles(theme => ({
    dayTimes: {
        width: '4.4rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
}))

function StartAndEndTime({startTime, endTime, textVariant}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={classes.dayTimes}>
            <Typography
                variant={textVariant}>{getTimeAsString(startTime)}</Typography>
            <Typography
                variant={textVariant}>{getTimeAsString(endTime)}</Typography>
        </div>
    )
}

StartAndEndTime.propTypes = {
    startTime: PropTypes.any.isRequired,
    endTime: PropTypes.any.isRequired,
    textVariant: PropTypes.string,
}

StartAndEndTime.defaultProps = {
    textVariant: 'caption',
}

export default StartAndEndTime
