import React, {useState} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles, useTheme} from "@material-ui/core";

import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import {getTimePeriodAtDate} from "../../../common/utils/TimeUtils.js";
import {navigate, PATH_BOOKINGS} from "../../../common/utils/NavigationUtils.js";
import moment from "moment";
import {assignMeetingToPerson, joinMeeting} from "../../../actions/user-action.js";
import {findCurrentMeeting, getMeetingsOfSpaceAtDate, isMemberOfMeeting} from "../../../common/utils/MeetingUtils.js";
import useDidMountEffect from "../../../common/customHooks/useDidMountEffect.js";
import CustomButton from "../../CustomButton.js";

const useStyle = makeStyles(theme => ({
    bottomContent: {
        display: 'flex',
        padding: '1.8rem',
        paddingBottom: '2.8rem',
    },
    flex: {
        display: 'flex',
        width: '100%',
        gap: theme.innerGap,
    }
}))

function MeetingroomSelectionSpreadsheetBottom({
                                                   userId,
                                                   timePeriod,
                                                   selectedSpace,
                                                   assignMeetingToPerson,
                                                   assignMeetingPending,
                                                   spaceAssignments,
                                                   selectedDate,
                                                   joinMeeting,
                                                   detailsView,
                                                   createDifferentMeetingMode,
                                                   onCreateDifferentMeeting,
                                                   confirmationButtonsDisabled
                                               }) {
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const [assignmentsOfSelectedSpace, setAssignmentsOfSelectedSpace] = useState(getMeetingsOfSpaceAtDate(selectedSpace, spaceAssignments, selectedDate));

    useDidMountEffect(() => {
        setAssignmentsOfSelectedSpace(getMeetingsOfSpaceAtDate(selectedSpace, spaceAssignments, selectedDate))
    }, [spaceAssignments])

    function assignMeeting() {
        let timePeriodToUse

        if (timePeriod && timePeriod.startDate) {
            timePeriodToUse = timePeriod
        } else {
            let rightNow = new Date()
            timePeriodToUse = {startDate: rightNow, endDate: moment(rightNow).add(1, 'h').toDate()}
        }

        let timePeriodAtDate = getTimePeriodAtDate(timePeriodToUse, selectedDate)
        let assignment = {
            timePeriod: timePeriodAtDate,
            space: selectedSpace,
            title: "Meeting",
        }
        assignMeetingToPerson(userId, assignment, [])
            .then(() => {
                    navigate(history, PATH_BOOKINGS)
                }
            )
    }

    function onJoinMeeting() {
        let currentMeeting = findCurrentMeeting(assignmentsOfSelectedSpace)
        if (currentMeeting) {
            joinMeeting(userId, currentMeeting.id)
                .then(() => navigate(history, PATH_BOOKINGS))
        }
    }

    function getBottomContent() {
        let currentMeeting = findCurrentMeeting(assignmentsOfSelectedSpace)
        if (!createDifferentMeetingMode && currentMeeting) {
            return <div className={classes.flex}>
                <CustomButton text={t('join_meeting')}
                              onClick={onJoinMeeting}
                              primary halfWidth
                              disabled={isMemberOfMeeting(currentMeeting, userId)}
                              isLoading={assignMeetingPending}/>
                <CustomButton text={t('create_different_meeting')}
                              halfWidth
                              onClick={() => onCreateDifferentMeeting(true)}/>
            </div>
        } else {
            return <CustomButton text={t('create_meeting')}
                                 onClick={assignMeeting}
                                 disabled={confirmationButtonsDisabled}
                                 fullWidth primary
                                 isLoading={assignMeetingPending}/>
        }
    }

    if (!selectedSpace || detailsView)
        return null

    return <div className={classes.bottomContent}>
        {getBottomContent()}
    </div>
}

MeetingroomSelectionSpreadsheetBottom.propTypes = {
    detailsView: PropTypes.bool,
    //to disable e.g. button for booking when selected timerange is invalid
    confirmationButtonsDisabled: PropTypes.bool
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        timePeriod: state.meetings.timePeriod,
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedSpace: state.spaces.selectedSpace,
        assignMeetingPending: state.user.assignMeetingPending,
        spaceAssignments: state.meetings.spaceAssignments,
    }
}

const mapDispatchToProps = {
    assignMeetingToPerson: assignMeetingToPerson,
    joinMeeting: joinMeeting,
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingroomSelectionSpreadsheetBottom)