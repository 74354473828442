import {
    deleteProfilePicture,
    setUploadProfilePicturePending,
    updateUser,
    uploadProfilePicture
} from "../../actions/user-action.js";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Dialog, DialogActions, DialogContent, makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import React, {useEffect} from "react";
import XSvgIcon from "../../common/icons/XSvgIcon.js";
import PropTypes from "prop-types";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy.js";
import CustomImageEditor from "./CustomImageEditor.js";
import ImageUploading from "react-images-uploading";
import Avatar from "@material-ui/core/Avatar";
import PictureSvgIcon from "../../common/icons/PictureSvgIcon.js";
import ClickableText from "../../common/elements/ClickableText";
import {compressImage} from "../../common/utils/ImageUtils.js";


const useStyle = makeStyles(theme => ({
    root: {
        '& .MuiDialog-paper': {
            backgroundColor: theme.colors.palette.neutral.greyNeutral,
            margin: '0',
            borderRadius: '18px 18px 0 0',
            width: '100%',
            maxWidth: theme.gridSizes.maxWidth,
            position: 'absolute',
            bottom: 0,
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
            }
        },
        '& .MuiDialog-paperWidthSm': {
            height: '80%'
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2.4rem'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    modifiers: {
        display: 'flex',
        width: '100%',
        maxWidth: '40rem',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '8.3rem'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {},
    clickable: {
        cursor: 'pointer'
    },
    selected: {
        margin: 0,
        boxShadow: '0 0 0 2px rgb(88, 188, 204)',
        border: '2px solid',
        borderColor: theme.colors.palette.neutral.white,
        width: '79px !important',
        height: '79px !important',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: '7.5rem',
        height: '7.5rem',
    },
    avatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))

function ChangeProfileImageOrColorModal({
                                            open,
                                            onClose,
                                            user,
                                            profilePicture,
                                            updateUser,
                                            uploadProfilePicture,
                                            deleteProfilePicture,
                                            setUploadProfilePicturePending
                                        }) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);

    const skinMain = theme.colors.palette.corporate.skinMain.toString()
    const blueMain = theme.colors.palette.corporate.blueMain.toString()
    const dark = theme.colors.palette.neutral.darkMain.toString()

    const [scaleValue, setScaleValue] = React.useState(1);
    const [uploading, setUploading] = React.useState(false);
    const [changed, setChanged] = React.useState(false);

    const [editor, setEditor] = React.useState(null);
    const [imageArray, setImageArray] = React.useState([]);
    const [selectedColor, setSelectedColor] = React.useState(null);

    useEffect(() => {
        if (user && user.profilePictureId) {
            if (profilePicture) {
                setImageArray([{data_url: profilePicture, file: profilePicture}])
            } else {
                setImageArray([true])
            }
        } else if (user && user.color) {
            setSelectedColor(user.color)
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        if (profilePicture) {
            setImageArray([{data_url: profilePicture, file: profilePicture}])
        }
        // eslint-disable-next-line
    }, [profilePicture])

    function setProfileColorOrFoto() {
        if (!changed) {
            onClose()
            return
        }

        if (editor === null && !uploading && selectedColor) {
            updateUser(user.id, {color: selectedColor})
        } else if (uploading) {
            const canvas = editor.getImage()
            setUploadProfilePicturePending()

            canvas.toBlob(async function (blob) {
                await compressImage(blob)
                    .then((compressedImage) => {
                        uploadProfilePicture(user.id, compressedImage, user.email)
                    })

                setScaleValue(1)
                setUploading(uploading => !uploading)
            }, "image/jpeg", .9);
        }
        onClose()
    }

    function onUploadImageChange(imageToUpload) {
        if (imageToUpload[0].file.size / 1024 > 10000) {
            //implement notification
            console.log("file too large")
        } else {
            setImageArray(imageToUpload)
            setScaleValue(0.75)
            setUploading(uploading => !uploading)
            setChanged(true)
        }
    }

    function isColorSelected(color) {
        if (imageArray && imageArray.length)
            return false
        return selectedColor === color
    }

    return (
        <Dialog className={classes.root} open={open} onClose={onClose}>
            <DialogActions className={classes.actions}>
                <XSvgIcon className={`${classes.icon} ${classes.clickable}`} onClick={onClose} width={24} height={24}/>
                <ClickableText text={t('submit')} variant={'h5'} onClick={setProfileColorOrFoto}
                               textColor={theme.colors.palette.corporate.blueMain}/>
            </DialogActions>
            <DialogContent className={classes.content}>
                <Typography variant={"h3"} align={'left'}>{t('profile_picture')}</Typography>
                <div className={classes.wrapper}>
                    {uploading
                        ? <CustomImageEditor image={imageArray[0]['data_url']} editor={editor} setEditor={setEditor}
                                             initialScaleValue={scaleValue}/>
                        : <AvatarWithOccupancy person={user}
                                               hideAvailabilityStatus
                                               size={305}
                                               initialsOnly={!imageArray || !imageArray.length}
                                               colorOfBackground={selectedColor ? selectedColor : user.color}
                                               key={user.id}/>}
                </div>
                <div className={classes.modifiers}>
                    <ImageUploading
                        value={imageArray}
                        onChange={onUploadImageChange}
                        dataURLKey="data_url"
                        acceptType={['jpg', 'png', 'jpeg']}
                    >
                        {({
                              onImageUpload
                          }) => (
                            <div className="upload__image-wrapper">
                                <Avatar
                                    size={75}
                                    className={`${classes.avatar}`}
                                    style={{
                                        backgroundColor: theme.colors.palette.neutral.greyMedium,
                                        color: theme.palette.getContrastText(theme.colors.palette.corporate.blueMain)
                                    }}
                                    onClick={onImageUpload}
                                >
                                    <div className={classes.avatarContainer}>
                                        <PictureSvgIcon/>
                                        <Typography variant={"caption"}>{t('upload')}</Typography>
                                    </div>
                                </Avatar>
                            </div>
                        )}
                    </ImageUploading>
                    <AvatarWithOccupancy person={user}
                                         hideAvailabilityStatus
                                         size={75}
                                         initialsOnly
                                         className={`${isColorSelected(blueMain) && classes.selected}`}
                                         colorOfBackground={theme.colors.palette.corporate.blueMain}
                                         onClick={() => {
                                             setImageArray(null)
                                             setUploading(false)
                                             setSelectedColor(blueMain);
                                             setChanged(true)
                                         }}/>
                    <AvatarWithOccupancy person={user}
                                         hideAvailabilityStatus
                                         size={75}
                                         initialsOnly
                                         colorOfBackground={theme.colors.palette.corporate.skinMain}
                                         className={`${isColorSelected(skinMain) && classes.selected}`}
                                         onClick={() => {
                                             setImageArray(null)
                                             setUploading(false)
                                             setSelectedColor(skinMain);
                                             setChanged(true)
                                         }}/>
                    <AvatarWithOccupancy person={user}
                                         hideAvailabilityStatus
                                         size={75}
                                         initialsOnly
                                         className={`${isColorSelected(dark) && classes.selected}`}
                                         colorOfBackground={theme.colors.palette.neutral.darkMain}
                                         onClick={() => {
                                             setImageArray(null)
                                             setUploading(false)
                                             setSelectedColor(dark);
                                             setChanged(true)
                                         }}/>
                </div>
            </DialogContent>
        </Dialog>
    )
}

ChangeProfileImageOrColorModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    return {
        user: state.user.person,
        profilePicture: state.user.profilePicture
    }
}

const mapDispatchToProps = {
    updateUser: updateUser,
    uploadProfilePicture: uploadProfilePicture,
    deleteProfilePicture: deleteProfilePicture,
    setUploadProfilePicturePending: setUploadProfilePicturePending
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfileImageOrColorModal)