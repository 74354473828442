import * as React from "react";

import useTheme from "@material-ui/core/styles/useTheme.js";

function HomeOfficeTippsWaterSvg(props) {

    const theme = useTheme()

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={288} height={226} viewBox="0 0 288 226"{...props}>
            <defs>
                <linearGradient id="prefix__a" x1="22.792%" x2="39.396%" y1="54.539%" y2="70.775%">
                    <stop offset="0%" stopColor="#FF7942"/>
                    <stop offset="100%" stopColor="#FFB082"/>
                </linearGradient>
                <linearGradient id="prefix__b" x1="38.555%" x2="63.196%" y1="13.447%" y2="74.326%">
                    <stop offset="0%" stopColor="#333"/>
                    <stop offset="100%"/>
                </linearGradient>
                <linearGradient id="prefix__c" x1="6.026%" x2="91.617%" y1="35.254%" y2="74.303%">
                    <stop offset="0%" stopColor="#FF7942"/>
                    <stop offset="100%" stopColor="#FFB082"/>
                </linearGradient>
                <linearGradient id="prefix__d" x1="5%" x2="80%" y1="70%" y2="35%">
                    <stop offset="0%" stopColor={theme.colors.palette.corporate.greenLightest}/>
                    <stop offset="100%" stopColor={theme.colors.palette.corporate.greenLight}/>
                </linearGradient>
                <linearGradient id="prefix__e" x1="24.265%" x2="79.671%" y1="13.447%" y2="74.326%">
                    <stop offset="0%" stopColor="#333"/>
                    <stop offset="100%"/>
                </linearGradient>
            </defs>
            <g fillRule="nonzero" fill="none">
                <path
                    fill="url(#prefix__a)"
                    d="M58.315 136.186c16.228 28.685 50.942 41.263 81.779 29.63 61.004-23.012 98.13-44.318 111.375-63.916 16.558-24.498 2.165-44.668-43.179-60.509a56.046 56.046 0 00-3.866-1.195l-34.548-9.332a84.443 84.443 0 00-67.985 10.685L77.637 57.287c-26.285 17.056-34.751 51.627-19.322 78.899z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="url(#prefix__b)"
                    d="M154.4 90.367c-1.395 7.64-2.57 15.725-3.523 24.257a428.404 428.404 0 00-2.547 39.54c-.356 18.965 14.73 34.626 33.693 34.982a34.347 34.347 0 0017.774-4.57l24.637-14.179a48.445 48.445 0 0024.282-41.989 57.727 57.727 0 00-22.009-45.35l-17.186-13.536c-14.924-11.754-36.551-9.184-48.306 5.74a34.395 34.395 0 00-6.815 15.105z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="url(#prefix__c)"
                    d="M41.288 43.707A30.643 30.643 0 0153.529 25.03l1.848-1.313c5.314-3.774 12.682-2.526 16.456 2.788.227.32.438.65.633.991 3.145 5.52 1.22 12.545-4.3 15.69-.417.238-.849.449-1.292.633l-16.976 7.033a6.325 6.325 0 01-8.61-7.145z"
                    transform="rotate(-130 59.459 36.045)"
                />
                <path
                    fill="url(#prefix__d)"
                    d="M52.481 94.57C54.643 102.44 56 108.916 56.55 114c4.986 46.093 46.394 79.417 92.487 74.43a83.942 83.942 0 0068.242-50.653c2.354-5.545 4.802-10.464 7.344-14.755 8.862-14.961 16.79-29.573 23.786-43.836 4.5-9.177.71-20.264-8.467-24.765a18.509 18.509 0 00-11.392-1.604l-13.114 2.334a100.003 100.003 0 01-42.284-1.567l-64.274-16.427c-24.9-6.363-50.245 8.664-56.609 33.564a46.537 46.537 0 00.213 23.848z"
                    transform="matrix(-1 0 0 1 287.385 .847)"
                />
                <path
                    fill="url(#prefix__e)"
                    d="M242.017 52.845l-4-2.537a12.079 12.079 0 01-5.578-11.016 7.003 7.003 0 017.46-6.513c.06.004.119.01.178.015l4.09.382c5.972.558 10.36 5.85 9.801 11.821-.013.141-.029.282-.047.423l-.266 1.996a7.622 7.622 0 01-11.638 5.429z"
                    transform="rotate(15 240.853 49.059)"
                />
                <path
                    fill="url(#prefix__d)"
                    d="M220.003 38.047l-1.041-2.106a21.911 21.911 0 01-.349-18.692 7.51 7.51 0 0111.893-2.488l4.558 4.13c5.731 5.193 6.167 14.05.974 19.78-.106.118-.215.233-.325.347l-1.255 1.294a8.954 8.954 0 01-14.455-2.265z"
                    transform="translate(.779 .847)"
                />
                <g fill="#FFF">
                    <path
                        d="M175.779 69.847c-1.1-1.3-2.8-2-4.5-2h-52.9c-1.7 0-3.4.8-4.5 2-1.1 1.3-1.7 3-1.4 4.7l9.3 71.4v5.9c0 3.3 2.7 6 6 6h34c3.3 0 6-2.7 6-6v-5.9l9.4-71.4c.2-1.6-.3-3.4-1.4-4.7zm-12 82c0 1.1-.9 2-2 2h-34c-1.1 0-2-.9-2-2v-4h38v4zm.2-8h-38.4l-6-45.2 3.5 1.2c2.9 1 5.9 1.5 8.9 1.5 4.8 0 9.5-1.3 13.8-3.8 5.8-3.5 12.9-4.2 19.3-1.9l5 1.8-6.1 46.4zm9.2-69.7l-2.5 19.2-4.2-1.5c-7.5-2.7-15.8-1.8-22.7 2.3-5.8 3.5-12.9 4.2-19.3 1.9l-5.5-1.9-2.6-20c-.1-.6.1-1.1.5-1.6.3-.5.9-.7 1.5-.7h52.9c.6 0 1.1.2 1.5.7.3.4.5 1 .4 1.6z"/>
                    <path
                        d="M160.479 109.847c-1.1-.1-2.1.6-2.2 1.7l-3.2 24.1c-.1 1.2.6 2.2 1.7 2.3h.3c1 0 1.9-.7 2-1.7l3.2-24.1c.1-1.1-.7-2.1-1.8-2.3z"/>
                </g>
            </g>
        </svg>
    )
}

export default HomeOfficeTippsWaterSvg