const initialState = {
    showTimeTracking: true,
};

function userConfigReducer(state = initialState, action) {

    switch (action.type) {

        case 'SET_SHOW_TIME_TRACKING': {
            return {
                ...state,
                showTimeTracking: action.payload
            }
        }

        default:
            return {...state}
    }

}

export default userConfigReducer;