import * as React from "react"
import PropTypes from "prop-types";

function UndefinedSvgIcon({color, width, height, strokeWidth, ...props}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={color}
                strokeWidth={strokeWidth}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path
                    d="M20 31.942l-2.731 1.789-1.839-2.698-3.208.608-.667-3.196-3.196-.667.608-3.208-2.698-1.839L8.058 20l-1.789-2.731 2.698-1.839-.608-3.208 3.196-.667.667-3.196 3.208.608 1.839-2.698L20 8.058l2.731-1.789 1.839 2.698 3.208-.608.667 3.196 3.196.667-.608 3.208 2.698 1.839L31.942 20l1.789 2.731-2.698 1.839.608 3.208-3.196.667-.667 3.196-3.208-.608-1.839 2.698zM20 25v1.5"/>
                <path d="M20 23.5v-2.857a3.572 3.572 0 10-3.571-3.572"/>
            </g>
        </svg>
    )
}

UndefinedSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    strokeWidth: PropTypes.number,
};

UndefinedSvgIcon.defaultProps = {
    color: '#333',
    width: 40,
    height: 40,
    strokeWidth: 1.5,
};

export default UndefinedSvgIcon