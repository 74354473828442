import React from 'react';
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {makeStyles, useTheme} from "@material-ui/core";
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'

import SearchableList from "../../common/elements/SearchableList";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import {withCookies} from "react-cookie";
import moment from "moment";

const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100%',
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        height: '100%',
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-around',
    },
    content: {
        flexDirection: 'column',
        width: '100%',
    },
}));

function ChooseLanguagePage({cookies, i18n, t, ...props}) {

    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme)

    const handleLanguageSelected = (language) => {
        i18n.changeLanguage(language.id)
        cookies.set("language", language.id, {path: '/'})
        moment.locale(language.id)
        setTimeout(() => {
            history.goBack()
        }, 250);
    }

    const getSelectedLanguage = () => {
        let selectedLanguage = cookies.get('language')

        if (selectedLanguage) {
            if (selectedLanguage.includes('de'))
                return {id: 'de'}
            if (selectedLanguage.includes('en'))
                return {id: 'en'}
            if (selectedLanguage.includes('es'))
                return {id: 'es'}
        }

        i18n.changeLanguage('en')
        cookies.set("language", 'en', {path: '/'})
        return 'en'
    }

    const getSupportedLanguages = () => {
        return [
            {element: {name: "Deutsch", id: 'de'}},
            {element: {name: "English", id: 'en'}},
            {element: {name: "Español", id: 'es'}},
        ]
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <CssBaseline/>
            <Grid item sm={12} className={classes.container}>
                <div className={classes.content}>
                    <HeaderWithTitleAndBackButton title={t('language')} backLabel={t("settings")}
                                                  onNavigateBack={() => history.goBack()}/>

                    <SearchableList options={getSupportedLanguages()}
                                    onSelect={handleLanguageSelected}
                                    selected={getSelectedLanguage()}
                                    placeholder={t('language')}/>
                </div>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(withCookies(ChooseLanguagePage));