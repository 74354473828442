import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from "prop-types";

import moment from "moment";

import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";

import CustomTimePicker from "./CustomTimePicker.js";
import CustomTextField from "../../components/CustomTextField.js";
import TimelineWithHeaderAndError from "./TimelineWithHeaderAndError.js";

import {
    getDateAtEndOfWorkingDay,
    getDateAtStartOfWorkingDay,
    getTimeAsString,
    isTimeperiodOverlappingDisabledIntervals,
    TIME_REGEX
} from "../utils/TimeUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: "column"
    },
    times: {
        display: 'flex',
        gap: theme.innerGap
    },
    picker: {
        justifyContent: 'center',
        width: '100%',
        display: 'flex'
    }
}))

function TimeperiodSelectionWithClocksAndTimeline({
                                                      startTime,
                                                      setStartTime,
                                                      endTime,
                                                      setEndTime,
                                                      disabled,
                                                      disabledIntervals,
                                                      errorCallback,
                                                  }) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);

    const [activePicker, setActivePicker] = useState(0)
    const [startValueString, setStartValueString] = useState(getTimeAsString(startTime));
    const [endValueString, setEndValueString] = useState(getTimeAsString(endTime));

    useEffect(() => {
        const timeString = getTimeAsString(startTime)
        if (timeString !== startValueString)
            setStartValueString(timeString)
        // eslint-disable-next-line
    }, [startTime])

    useEffect(() => {
        const timeString = getTimeAsString(endTime)
        if (timeString !== endValueString)
            setEndValueString(timeString)
        // eslint-disable-next-line
    }, [endTime])

    const showFrom = useMemo(() => {
        let start = getDateAtStartOfWorkingDay(startTime)

        if (start.isAfter(moment(startTime)))
            start = moment(startTime)

        return start.clone().subtract(1, 'hour').toDate()
        //eslint-disable-next-line
    }, [startTime])

    const showUntil = useMemo(() => {
        let end = getDateAtEndOfWorkingDay(endTime)

        if (end.isBefore(endTime))
            end = moment(endTime)

        return end.add(1, 'hour').clone().toDate()
        //eslint-disable-next-line
    }, [endTime])

    const error = useMemo(() => {
        let isError = isTimeperiodOverlappingDisabledIntervals({
            startDate: startTime,
            endDate: endTime
        }, disabledIntervals) || startTime >= endTime

        errorCallback && errorCallback(isError)

        return isError
        // eslint-disable-next-line
    }, [startTime, endTime, disabledIntervals])

    function updateStartTime(value) {
        const [hours, minutes] = value.split(':').slice(0, 2).map(Number)

        let start = moment(startTime)
        start.set({hour: hours, minute: minutes})
        setStartTime(start)
    }

    function updateEndTime(value) {
        const [hours, minutes] = value.split(':').slice(0, 2).map(Number)

        let end = moment(endTime)
        end.set({hour: hours, minute: minutes})
        if (!moment(end).isSame(endTime, 'm')) {
            setEndTime(end)
        }
    }

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.times}>
                    <CustomTextField
                        disabled={disabled}
                        active={!disabled && activePicker === 0}
                        error={error}
                        value={startValueString}
                        onChange={(event) => {
                            let value = event.target.value
                            setStartValueString(value)
                            if (value.match(TIME_REGEX))
                                updateStartTime(value)
                        }}
                        onBlur={(event) => {
                            let value = event.target.value
                            if (value.match(TIME_REGEX))
                                updateStartTime(value)
                        }}
                        pattern={TIME_REGEX}
                        max={5}
                        onFocus={() => setActivePicker(0)}
                        title={t('from')}/>
                    <CustomTextField
                        disabled={disabled}
                        active={activePicker === 1}
                        error={error}
                        value={endValueString}
                        onChange={e => {
                            let value = e.target.value
                            setEndValueString(value)
                            if (value.match(TIME_REGEX))
                                updateEndTime(value)
                        }}
                        onBlur={(event) => {
                            let value = event.target.value
                            if (value.match(TIME_REGEX))
                                updateEndTime(value)
                        }}
                        pattern={TIME_REGEX}
                        max={5}
                        onFocus={() => setActivePicker(1)}
                        title={t('to')}/>
                </div>

                <div className={classes.picker}>
                    {activePicker === 0 &&
                        <CustomTimePicker
                            disabled={disabled}
                            disableToolbar
                            autoOk
                            initialDate={startTime}
                            onSubmit={(time, minutesSet) => {
                                setStartTime(time)
                                if (minutesSet)
                                    setActivePicker(1)
                            }}
                            staticVariant/>}
                    {activePicker === 1 &&
                        <CustomTimePicker
                            disabled={disabled}
                            disableToolbar
                            autoOk
                            initialDate={endTime}
                            onSubmit={(time, minutesSet) => {
                                setEndTime(time)
                                if (minutesSet)
                                    setActivePicker(0)
                            }}
                            staticVariant/>}
                </div>
            </div>
            <TimelineWithHeaderAndError
                hideTimeHeader
                timePeriod={{startDate: startTime, endDate: endTime}}
                showFrom={showFrom}
                showUntil={showUntil}
                errorCallback={errorCallback}
                disabledIntervals={disabledIntervals}/>
        </div>
    )
}

TimeperiodSelectionWithClocksAndTimeline.propTypes = {
    startTime: PropTypes.any.isRequired,
    setStartTime: PropTypes.func.isRequired,
    endTime: PropTypes.any.isRequired,
    setEndTime: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    disabledIntervals: PropTypes.array,
    errorCallback: PropTypes.func,
}

export default TimeperiodSelectionWithClocksAndTimeline