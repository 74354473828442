import * as React from 'react'

const DrawSeatsSvgIcon = props =>
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g stroke={props.stroke} fill="none" fillRule="evenodd" strokeLinejoin="round">
            <path
                d="M12 22v-6M4.5 14v-4M5.5 12h13v1a3 3 0 0 1-3 3h-7a3 3 0 0 1-3-3v-1ZM6.5 12h11V6a3 3 0 0 0-3-3h-5a3 3 0 0 0-3 3v6ZM17.5 21.75a4.936 4.936 0 0 0-1.292-1.576c-1.077-.88-2.565-1.424-4.208-1.424-1.643 0-3.131.544-4.208 1.424-.549.447-.99.982-1.292 1.576M19.5 14v-4"/>
        </g>
    </svg>

export default DrawSeatsSvgIcon