import * as React from "react"

function IntegrationsSvgIcon(props) {
    return (
        <svg viewBox="0 0 24 24"
             {...props}>
            <path
                d="M13.5 3a2.5 2.5 0 0 1 2 4.001L20 7v4.208a2.5 2.5 0 1 0 0 4.583V20h-4.208a2.5 2.5 0 1 0-4.583 0H7l.001-4.5a2.5 2.5 0 1 1 0-3.999L7 7l4.5.001A2.5 2.5 0 0 1 13.5 3Z"
                fillRule="nonzero"
                stroke="#333"
                fill="none"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IntegrationsSvgIcon