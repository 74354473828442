import React from 'react';
import PropTypes from 'prop-types';
import {Typography, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '50px',
        gap: theme.innerSmallGap
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: '16px',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
})

function DonutLegendEntry(props) {
    const {classes} = props

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                {props.icon}
            </div>
            <div className={classes.textContainer}>
                <Typography variant={'caption'}>
                    {props.title}
                </Typography>
                <Typography variant={'h3'} style={{color: props.color}}>
                    {props.value}{props.postfix && '\u{202F}' + props.postfix}
                </Typography>
            </div>
        </div>
    );
}

DonutLegendEntry.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    icon: PropTypes.object,
    color: PropTypes.string,
    postfix: PropTypes.string
}

export default withStyles(styles)(DonutLegendEntry);