import * as React from "react"

function CheckCircledSvgIcon(props) {
    return (
        <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                {...props}
                stroke={props.stroke || '#60BF82'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <rect width={18} height={18} x={6} y={6} rx={9}/>
                <path d="M18.479 11.078l-3.977 6.954-3.007-2.643"/>
            </g>
        </svg>
    )
}

export default CheckCircledSvgIcon
