import * as React from "react"
import PropTypes from "prop-types";

function LocationSvgIcon({size, ...props}) {
    return (
        <svg width={size} height={size} viewBox={"0 0 40 40"} xmlns="http://www.w3.org/2000/svg" {...props}>
            <g
                transform="translate(11.5 9)"
                strokeWidth={1.5}
                stroke={props.stroke || '#333'}
                fill={props.fill || 'none'}
                fillRule="evenodd"
                strokeLinejoin="round"
                {...props}
            >
                <rect x={4.5} y={4} width={8} height={8} rx={4}/>
                <path
                    d="M8.5 0C3.868-.058.064 3.617 0 8.212a8.032 8.032 0 001.7 4.93L8.5 23l6.8-9.859a8.032 8.032 0 001.7-4.93c-.064-4.595-3.868-8.27-8.5-8.21z"/>
            </g>
        </svg>
    )
}

LocationSvgIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

LocationSvgIcon.defaultProps = {
    size: 40,
    color: '#333',
};

export default LocationSvgIcon
