import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import LoadingIndicator from "../../common/elements/LoadingIndicator";
import {useHistory} from "react-router-dom";
import {navigate, PATH_CHOOSE_SEAT, PATH_SOCIAL_BOOKING_SELECT} from "../../common/utils/NavigationUtils";
import useTheme from "@material-ui/core/styles/useTheme";
import ListSvgIcon from "../../common/icons/ListSvgIcon";
import LocationSvgIcon from "../../common/icons/LocationSvgIcon";
import WorkplaceSelectionViaList from "./WorkplaceSelectionViaList";
import {
    assignWorkplaceToPerson,
    getCoworkersAssignmentsAtDate,
    selectFavoriteWorkplace
} from "../../actions/workplaceAssignment-action";
import {getProfilePictures} from "../../actions/occupancy-actions";
import {selectCoworker} from "../../actions/user-action.js";
import FloorplanWithWorkplaces from "../floorplan/FloorplanWithWorkplaces.js";
import WorkplaceAndSpaceSelectionSpreadsheet from "../selectionSpreadsheet/WorkplaceAndSpaceSelectionSpreadsheet";
import moment from "moment";

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        minHeight: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
        [theme.breakpoints.between('lg', 'xl')]: {
            padding: theme.paddingContentContainer.padding,
        },
    },
    container: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: theme.gridSizes.maxWidth,
        }
    },
    content: {
        height: 'calc(100vh - 190px)',
        display: 'flex',
        flexDirection: 'column',
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '1.8rem',
        marginBottom: '-0.8rem'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '18px',
    },
    headerElement: {
        display: 'flex',
        alignItems: 'center'
    },
    selectedIcon: {
        backgroundColor: theme.colors.palette.neutral.greyMedium,
        borderRadius: '12px'
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '12px',
    },
    dateAndTime: {
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    date: {
        color: theme.colors.palette.corporate.skinMain
    },
}));

function WorkplaceSelectionViaColleaguePage({
                                                t, userId,
                                                selectedFloor,
                                                timePeriod,
                                                selectedDate,
                                                assignments,
                                                userAssignments,
                                                assignWorkplaceToPerson,
                                                getCoworkersAssignmentsAtDate,
                                                getProfilePictures,
                                                selectedWorkplace,
                                                selectWorkplace,
                                                selectedCoworker,
                                                selectCoworker,
                                                ...props
                                            }) {

    const theme = useTheme()
    const classes = useStyle(theme);
    const history = useHistory();
    const [listMode, setListMode] = React.useState(false)

    useEffect(() => {
        if (props.location.pathname === PATH_SOCIAL_BOOKING_SELECT) {
            if (!timePeriod || !selectedFloor)
                navigate(history, PATH_CHOOSE_SEAT + '/3')
        }
        if (selectedFloor) {
            getCoworkersAssignmentsAtDate(userId, moment(selectedDate).startOf('day').toISOString(),
                moment(selectedDate).endOf('day').toISOString(), selectedFloor.id, true, true)
        }
        if (userId && selectedFloor.id) {
            getProfilePictures(userId, selectedFloor.id, selectedDate.toISOString())
        }
        //eslint-disable-next-line
    }, [])

    function routeBack() {
        navigate(history, PATH_CHOOSE_SEAT + '/3')
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <CssBaseline/>
            <Grid item sm={12} className={classes.container}>
                <div className={classes.header}>
                    <HeaderWithTitleAndBackButton backLabel={t("work_together")}
                                                  onNavigateBack={routeBack}/>

                    <div className={classes.headerElement}>
                        <ListSvgIcon className={listMode ? classes.selectedIcon : ''}
                                     onClick={() => setListMode(true)}/>
                        <LocationSvgIcon className={!listMode ? classes.selectedIcon : ''}
                                         onClick={() => setListMode(false)}/>
                    </div>
                </div>

                {assignments
                    ? <div className={classes.content}>
                        {listMode
                            ? <WorkplaceSelectionViaList/>
                            : <FloorplanWithWorkplaces centerInitially/>
                        }
                    </div>
                    : <LoadingIndicator/>}
            </Grid>
            <WorkplaceAndSpaceSelectionSpreadsheet showDetailsInitially/>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        timePeriod: state.assignmentBuilder.timePeriod,
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedWorkplace: state.workplace.favoredWorkplace,
        selectedFloor: state.floors.floor,
        selectedCoworker: state.user.selectedCoworker,
        userAssignments: state.user.userAssignments,
        assignments: state.workplace.assignments,
    }
}

const mapDispatchToProps = {
    assignWorkplaceToPerson: assignWorkplaceToPerson,
    getProfilePictures: getProfilePictures,
    selectCoworker: selectCoworker,
    selectWorkplace: selectFavoriteWorkplace,
    getCoworkersAssignmentsAtDate: getCoworkersAssignmentsAtDate,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WorkplaceSelectionViaColleaguePage));