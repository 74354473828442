import React, {useEffect, useState} from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {Grid, Paper, Typography, withStyles} from "@material-ui/core";

import RadioGroup from "@material-ui/core/RadioGroup";
import RadioButtonWithDescription from "./RadioButtonWithDescription";
import CustomButton from "../CustomButton.js";
import WorkingGroupSvgIcon from "../../common/icons/WorkingGroupSvgIcon";
import {fetchShareStatus, postShareStatus} from "../../actions/user-action";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import {SHARE_STATUS_PRIVATE, SHARE_STATUS_PUBLIC, SHARE_STATUS_WORKINGGROUPS} from "../../common/utils/NameUtils.js";
import {PATH_PROFILE_SETTINGS, PATH_WORKING_GROUPS} from "../../common/utils/NavigationUtils.js";

const styles = theme => ({
    root: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        width: '100%',
        height: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: theme.outerGap
    },
    header: {
        width: theme.gridSizes.maxWidth,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        alignItems: 'start',
    },
    description: {
        fontWeight: 'normal',
        textAlign: 'start'
    },
    radioGroup: {
        width: '100%',
        gap: theme.innerGap,
    },
    workingGroupButton: {
        height: '3.6rem',
        width: '26.5rem',
        marginTop: '1rem',
    }
})

const LocationShareSettings = ({shareStatus, userId, fetchShareStatus, postShareStatus, classes, t, ...props}) => {

    const [selected, setSelected] = useState(SHARE_STATUS_PRIVATE);

    useEffect(() => {
        if (shareStatus == null && userId)
            fetchShareStatus(userId)
        else if (shareStatus !== null && shareStatus.status !== null && shareStatus.status !== selected) {
            setSelected(shareStatus.status)
        }
    }, [shareStatus, userId, fetchShareStatus, selected])

    const routeToAccount = () => {
        props.history.push(PATH_PROFILE_SETTINGS);
    }

    const routeToWorkingGroups = () => {
        props.history.push(PATH_WORKING_GROUPS);
    }

    const onRadioChange = (value) => {
        setSelected(value)
        postShareStatus(userId, value)
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item sm={12} className={classes.container}>
                <Grid item sm={12} className={classes.header}>
                    <HeaderWithTitleAndBackButton backLabel={t('settings')}
                                                  title={t("share_settings")}
                                                  onNavigateBack={routeToAccount}/>

                    <Typography variant={'h6'} className={classes.description}>
                        {t("share_description")}
                    </Typography>

                </Grid>
                <Grid item container direction={'column'}>
                    <RadioGroup className={classes.radioGroup}>
                        <RadioButtonWithDescription title={t('share_state_all')}
                                                    description={t('share_description_all')}
                                                    onChange={onRadioChange}
                                                    value={SHARE_STATUS_PUBLIC}
                                                    selected={selected === SHARE_STATUS_PUBLIC}/>

                        <RadioButtonWithDescription title={t('share_state_groups')}
                                                    description={t('share_description_groups')}
                                                    onChange={onRadioChange}
                                                    value={SHARE_STATUS_WORKINGGROUPS}
                                                    selected={selected === SHARE_STATUS_WORKINGGROUPS}>

                            <CustomButton icon={<WorkingGroupSvgIcon/>}
                                          text={t('link_to_working_groups')}
                                          customClassName={classes.workingGroupButton}
                                          onClick={routeToWorkingGroups}/>

                        </RadioButtonWithDescription>

                        <RadioButtonWithDescription title={t('share_state_nobody')}
                                                    description={t('share_description_nobody')}
                                                    onChange={onRadioChange}
                                                    value={SHARE_STATUS_PRIVATE}
                                                    selected={selected === SHARE_STATUS_PRIVATE}/>

                    </RadioGroup>


                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        shareStatus: state.user.shareStatus,
    }
}

const mapDispatchToProps = {
    fetchShareStatus: fetchShareStatus,
    postShareStatus: postShareStatus,
}

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(LocationShareSettings));
