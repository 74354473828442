import React, {useMemo} from 'react';
import PropTypes from "prop-types";
import {
    dateDiffToDurationStringWithUnits,
    getDateAtEndOfWorkingDay,
    getDateAtStartOfWorkingDay,
    isTimeperiodOverlappingDisabledIntervals
} from "../utils/TimeUtils.js";
import CustomTimePicker from "../../common/elements/CustomTimePicker.js";
import TimelineWithHeaderAndError from "../../common/elements/TimelineWithHeaderAndError.js";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles, Typography} from "@material-ui/core";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap,
    },
    times: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.innerGap,
        alignItems: 'baseline'
    },
    timeWrapper: {
        display: 'flex',
        flexDirection: "column"
    }
}))

function TimeperiodSelectionWithPopupsAndTimeline({
                                                      disabledIntervals,
                                                      startTime,
                                                      setStartTime,
                                                      endTime,
                                                      setEndTime,
                                                      errorCallback,
                                                      ...props
                                                  }) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);

    const errorState = useMemo(() => {
        let isError = isTimeperiodOverlappingDisabledIntervals({
            startDate: startTime,
            endDate: endTime
        }, disabledIntervals) || startTime >= endTime

        errorCallback && errorCallback(isError)

        return isError
        // eslint-disable-next-line
    }, [startTime, endTime, disabledIntervals])

    const showFrom = useMemo(() => {
        return getDateAtStartOfWorkingDay(startTime)
    }, [startTime])

    const showUntil = useMemo(() => {
        return getDateAtEndOfWorkingDay(endTime)
    }, [endTime])

    return (
        <div className={classes.root}>

            <div className={classes.times}>
                <div className={classes.textWrapper}>
                    <Typography className={classes.heading} variant={'h6'}>{t("timeslot")}</Typography>
                    <Typography variant={'h6'}>
                        ({dateDiffToDurationStringWithUnits(startTime, endTime)})
                    </Typography>
                </div>
                <CustomTimePicker submitOnChange
                                  onSubmit={setStartTime}
                                  label={t('from')}
                                  autoOk
                                  error={errorState}
                                  initialDate={startTime}/>

                <CustomTimePicker submitOnChange
                                  onSubmit={setEndTime}
                                  label={t('to')}
                                  autoOk
                                  error={errorState}
                                  initialDate={endTime}/>
            </div>

            <TimelineWithHeaderAndError
                hideTimeHeader
                timePeriod={{startDate: startTime, endDate: endTime}}
                showFrom={showFrom}
                showUntil={showUntil}
                disabledIntervals={disabledIntervals}/>
        </div>
    )
}

TimeperiodSelectionWithPopupsAndTimeline.propTypes = {
    startTime: PropTypes.any.isRequired,
    setStartTime: PropTypes.func.isRequired,
    endTime: PropTypes.any.isRequired,
    setEndTime: PropTypes.func.isRequired,
    disabledIntervals: PropTypes.array,
    errorCallback: PropTypes.func,
}

export default TimeperiodSelectionWithPopupsAndTimeline