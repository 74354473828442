import React from 'react';
import PropTypes from 'prop-types'

import {makeStyles, useTheme} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";


const useStyle = props => makeStyles(theme => ({
    root: {
        width: '100%',
    },
    divider: {
        marginRight: props.dividerPaddingRight ? null : '-1.8em',
    },
}));

function CustomList({topDivider, bottomDivider, children, hideDivider, ...props}) {
    const theme = useTheme()
    const classes = useStyle(props)(theme);

    return (
        <div className={classes.root}>
            {topDivider ? <Divider className={classes.divider}/> : null}
            {children?.length
                ? hideDivider
                    ? children
                    : children.reduce((prev, current, index) =>
                        [prev, <Divider key={'divider' + index} className={classes.divider}/>, current])
                : null}
            {bottomDivider ? <Divider className={classes.divider}/> : null}
        </div>
    );
}

CustomList.propTypes = {
    children: PropTypes.array,
    bottomDivider: PropTypes.bool,
    topDivider: PropTypes.bool,
    dividerPaddingRight: PropTypes.bool,
    hideDivider: PropTypes.bool
};

CustomList.defaultProps = {
    bottomDivider: false,
    topDivider: false,
    dividerPaddingRight: true,
};

export default CustomList;