import * as THREE from 'three';

import {getMaterial, materialTypes} from './materials'

import foundersGroteskRegular from '../../common/fonts/Founders_Grotesk_Regular_Regular.json'

export function getTextMesh(text = 'text', size = .5, fontType = fontTypes.foundersGroteskRegular, materialType = materialTypes.black) {

    const parameters = {
        font: getFont(fontType),
        size: size,
        height: 1,
        curveSegments: 12,
        bevelEnabled: false,
    }

    const textGeometry = new THREE.TextGeometry(text, parameters)

    const textMaterial = getMaterial(materialType)

    const textMesh = new THREE.Mesh(textGeometry, textMaterial)
    textMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))
    textMesh.size = size
    textMesh.type = 'text'
    textMesh.text = text
    textMesh.textScale = 1
    textMesh.parameters = parameters

    return textMesh
}

export function updateTextMesh(textMesh, text = 'updated text') {
    textMesh.text = text
    updateTextScaleMesh(textMesh)
}

export function updateTextScaleMesh(textMesh, scale = null) {
    const {parameters} = textMesh
    if (!scale) {
        scale = textMesh.textScale
    } else {
        textMesh.textScale = scale
    }
    parameters.size = textMesh.size * scale

    textMesh.geometry.dispose()
    textMesh.geometry = new THREE.TextGeometry(textMesh.text, parameters)
}

export const fontTypes = {
    foundersGroteskRegular: 'foundersGroteskRegular',
}

const foundersGroteskRegularFont = new THREE.FontLoader().parse(foundersGroteskRegular)

const fonts = {
    foundersGroteskRegular: foundersGroteskRegularFont,
}

function getFont(fontType) {
    return fonts[fontType]
}