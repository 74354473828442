import React, {useEffect, useRef, useState} from "react";
import {VariableSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import PropTypes from "prop-types";
import useDidMountEffect from "../customHooks/useDidMountEffect.js";
import LoadingIndicator from "./LoadingIndicator.js";

const useStyle = makeStyles(theme => ({
    infiniteList: {
        '& > div:first-child': {
            '& > *': {
                marginTop: '12px',
            },
        }
    }
}));

export default function InfiniteLoaderList({
                                               // Are there more items to load?
                                               // (This information comes from the most recent API request.)
                                               hasNextPage,

                                               // Are we currently loading a page of items?
                                               // (This may be an in-flight flag in your Redux store for example.)
                                               isNextPageLoading,

                                               // Array of items loaded so far.
                                               items,
                                               itemSize,

                                               // Callback function responsible for loading the next page of items.
                                               loadNextPage,

                                               listItem,

                                               emptyState,

                                               withDynamicHeightsItems
                                           }) {
    const theme = useTheme()
    const classes = useStyle(theme);

    const expandedStateRef = useRef([]);
    const [updateIndex, setUpdateIndex] = useState(null)

    const idPrefix = 'infiniteLoader-item'
    let list = null

    useDidMountEffect(() => {
        if (withDynamicHeightsItems && list)
            list.resetAfterIndex(!updateIndex || updateIndex < 0 ? 0 : updateIndex, true);
    }, [updateIndex]);

    useEffect(() => {
        setUpdateIndex(0)
    }, [])

    useDidMountEffect(() => {
        expandedStateRef.current = []

        setUpdateIndex(0)
    }, [items])

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage ? items.length + 1 : items.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isNextPageLoading ? () => {
    } : () => loadNextPage();

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = index => !hasNextPage || index < items.length

    // Render an item or a loading indicator.
    const Item = ({index, style}) => {
        let content
        const itemLoaded = isItemLoaded(index)

        if (!itemLoaded) {
            content = <LoadingIndicator style={{width: '100%'}} id={'loading-indicator-infinite-list'}/>;
        } else {
            content = listItem(items[index])
        }

        if (withDynamicHeightsItems && itemLoaded) {
            return <div style={style}
                        id={idPrefix + index.toString()}
                        key={index.toString()}
                        onClick={() => {
                            let newExpandedSate = [...expandedStateRef.current]
                            newExpandedSate[index] = newExpandedSate[index] ? !newExpandedSate[index] : true
                            expandedStateRef.current = newExpandedSate

                            if (index === updateIndex) {
                                if (index === 0) {
                                    setUpdateIndex(null)
                                }
                                setUpdateIndex(index - 1)
                            } else
                                setUpdateIndex(index)

                        }}>
                {React.cloneElement(content, {
                    initiallyExpanded: expandedStateRef.current[index]
                })}
            </div>
        } else {
            return <div style={style} key={index.toString()}>{content}</div>
        }
    };

    const getItemSize = index => {
        if (!withDynamicHeightsItems)
            return itemSize ?? 70

        let itemWrapper = document.getElementById(idPrefix + index.toString())
        return itemWrapper && itemWrapper.children[0] && itemWrapper.children[0].offsetHeight ? itemWrapper.children[0].offsetHeight : itemSize ?? 70
    };

    return (
        <AutoSizer>
            {({height, width}) => (
                (items && items.length) || isNextPageLoading
                    ? <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                    >
                        {({onItemsRendered, ref}) => (
                            <List className={itemSize ? classes.infiniteList : null}
                                  height={height}
                                  width={width}
                                  itemCount={itemCount}
                                  itemSize={getItemSize}
                                  ref={ref => (list = ref)}
                                  onItemsRendered={onItemsRendered}
                            >
                                {Item}
                            </List>
                        )}
                    </InfiniteLoader>
                    : React.cloneElement(emptyState, {height: height, width: width})
            )}
        </AutoSizer>
    );
}

InfiniteLoaderList.propTypes = {
    emptyState: PropTypes.element.isRequired,
    withDynamicHeightsItems: PropTypes.bool
}
