import * as THREE from "three";

export function addDrawingObjects(scene, drawingObjects) {
    let drawingsGroup = new THREE.Group()
    drawingsGroup.name = 'drawings'

    drawingObjects.forEach(object => {
        drawingsGroup.add(object)
    })

    scene.add(drawingsGroup)
}

export function clearDrawingObjects(scene) {
    scene.children.forEach(child => {
        if (child.name === "drawings") {
            child.children.forEach(drawing => {
                child.remove(drawing)
                drawing.geometry.dispose()
            })
            scene.remove(child)
        }
    })
}