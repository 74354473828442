import * as PropTypes from "prop-types";
import {
    areTimeperiodsEqual,
    getEndTimeOfLastInterval,
    getHalfTime,
    getTimePeriodAsString,
    getTimePeriodAtDate,
    isTimeperiodOverlappingDisabledIntervals
} from "../utils/TimeUtils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import clsx from "clsx";
import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import CardWrapper from "./CardWrapper.js";
import CalendarSvgIcon from "../icons/CalendarSvgIcon.js";
import MorningSvgIcon from "../icons/MorningSvgIcon.js";
import AfternoonSvgIcon from "../icons/AfternoonSvgIcon.js";
import CustomTimeperiodSelectionPopup from "./CustomTimeperiodSelectionPopup.js";
import EditSvgIcon from "../icons/EditSvgIcon.js";
import * as _ from 'lodash'
import Divider from "@material-ui/core/Divider";
import moment from "moment";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        gap: theme.innerGap,
        margin: 0
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardWrapper: {
        flex: 1,
        padding: 'calc(' + theme.innerSmallGap + ' - 1px) !important'
    }
}))

function DaytimeSelection({
                              date,
                              className,
                              setTimePeriodCallback,
                              selected,
                              withCustomTimes,
                              startTime,
                              endTime,
                              disabledIntervals,
                              errorCallback
                          }) {
    const theme = useTheme()
    const classes = useStyle(theme);

    const [selectedDayTime, setSelectedDayTime] = useState(selected);

    const [customTimeModalOpen, setCustomTimeModalOpen] = useState(false);
    const [customTimeActive, setCustomTimeActive] = useState(false);
    const [customTimeperiod, setCustomTimeperiod] = useState(null)

    const halfTime = useMemo(() => getHalfTime(startTime, endTime), [startTime, endTime]);
    const morningTimeperiod = getTimePeriodAtDate({startDate: startTime, endDate: halfTime}, date)
    const noonTimeperiod = getTimePeriodAtDate({startDate: halfTime, endDate: endTime}, date)
    const fullDayTimeperiod = getTimePeriodAtDate({startDate: startTime, endDate: endTime}, date)

    let setTimePeriod = useCallback((timeperiod, isCustom = false) => {
        if (isCustom)
            setCustomTimeperiod(timeperiod)
        else
            setCustomTimeActive(false)

        setSelectedDayTime(timeperiod)

        if (timeperiod)
            setTimePeriodCallback(timeperiod)
        //eslint-disable-next-line
    }, [date])

    useEffect(() => {
        if (selected !== null) {
            if (!_.isEqual(selected, selectedDayTime)) {
                let isCustom = !(areTimeperiodsEqual(selected, morningTimeperiod) || areTimeperiodsEqual(selected, noonTimeperiod) || areTimeperiodsEqual(selected, fullDayTimeperiod))
                if (isCustom) {
                    setCustomTimeActive(false)
                    setTimePeriod(selected, isCustom)
                } else {
                    setCustomTimeperiod(null);
                    setTimePeriod(selected)
                }
            }
            errorCallback(false)
        } else {
            setSelectedDayTime(null)
            setCustomTimeperiod(null);
            errorCallback(true)
        }
        // eslint-disable-next-line
    }, [selected, date]);

    function isSelected(daytime, isCustom) {
        if (isCustom && customTimeActive)
            return true
        if (!daytime || !daytime.startDate || !daytime.endDate || !selectedDayTime || !selectedDayTime.startDate || !selectedDayTime.endDate || isCustom || customTimeActive)
            return false

        return areTimeperiodsEqual(daytime, selectedDayTime)
    }

    return (
        <div className={clsx(classes.root, className)}>
            <CardWrapper
                isFlex
                className={classes.cardWrapper}
                onClick={() => setTimePeriod(morningTimeperiod)}
                isSelected={isSelected(morningTimeperiod)}
                isDisabled={isTimeperiodOverlappingDisabledIntervals(morningTimeperiod, disabledIntervals)}>
                <div className={classes.content}>
                    <MorningSvgIcon/>
                    <Typography
                        variant={isTimeperiodOverlappingDisabledIntervals(morningTimeperiod, disabledIntervals) ? 'caption' : 'h4'}>
                        {getTimePeriodAsString(morningTimeperiod)}
                    </Typography>
                </div>
            </CardWrapper>
            <CardWrapper
                isFlex
                className={classes.cardWrapper}
                onClick={() => {
                    setTimePeriod(noonTimeperiod)
                }}
                isSelected={isSelected(noonTimeperiod)}
                isDisabled={isTimeperiodOverlappingDisabledIntervals(noonTimeperiod, disabledIntervals)}>
                <div className={classes.content}>
                    <AfternoonSvgIcon/>
                    <Typography
                        variant={isTimeperiodOverlappingDisabledIntervals(noonTimeperiod, disabledIntervals) ? 'caption' : 'h4'}>
                        {getTimePeriodAsString(noonTimeperiod)}
                    </Typography>
                </div>
            </CardWrapper>
            <CardWrapper
                isFlex
                className={classes.cardWrapper}
                onClick={() => setTimePeriod(fullDayTimeperiod)}
                isSelected={isSelected(fullDayTimeperiod)}
                isDisabled={isTimeperiodOverlappingDisabledIntervals(fullDayTimeperiod, disabledIntervals)}>
                <div className={classes.content}>
                    <CalendarSvgIcon/>
                    <Typography
                        variant={isTimeperiodOverlappingDisabledIntervals(fullDayTimeperiod, disabledIntervals) ? 'caption' : 'h4'}>
                        {getTimePeriodAsString(fullDayTimeperiod)}
                    </Typography>
                </div>
            </CardWrapper>

            {withCustomTimes && <Divider orientation={'vertical'} style={{minHeight: '6.2rem', height: 'auto'}}/>}

            {withCustomTimes && <CardWrapper
                isFlex
                className={classes.cardWrapper}
                onClick={() => {
                    setCustomTimeModalOpen(true)
                }}
                isSelected={isSelected(customTimeperiod, true)}>
                <div className={classes.content}>
                    <EditSvgIcon size={24}/>
                    <Typography variant={'h4'}>{getTimePeriodAsString(customTimeperiod)}</Typography>
                </div>
            </CardWrapper>}

            {customTimeModalOpen &&
                <CustomTimeperiodSelectionPopup
                    onSave={tp => {
                        setCustomTimeperiod(tp)
                        setTimePeriod(tp, true)
                        setCustomTimeActive(true)
                    }}
                    open={customTimeModalOpen}
                    initialTimeperiod={customTimeperiod ??
                        {
                            startDate: _.max([getEndTimeOfLastInterval(disabledIntervals, date), fullDayTimeperiod.startDate]),
                            endDate: _.max([moment(getEndTimeOfLastInterval(disabledIntervals, date)).add('hour', 1), fullDayTimeperiod.endDate])
                        }}
                    disabledIntervals={disabledIntervals}
                    errorCallback={errorCallback}
                    onClose={() => {
                        setCustomTimeModalOpen(false)
                    }}/>}
        </div>
    )
}

DaytimeSelection.propTypes = {
    setTimePeriodCallback: PropTypes.func.isRequired,
    date: PropTypes.any.isRequired,
    selected: PropTypes.any,
    startTime: PropTypes.any.isRequired,
    endTime: PropTypes.any.isRequired,
    withCustomTimes: PropTypes.bool,
    disabledIntervals: PropTypes.array,
    errorCallback: PropTypes.func,
}

DaytimeSelection.defaultProps = {
    withCustomTimes: true,
    disabledIntervals: [],
    errorCallback: _.noop
}

export default DaytimeSelection
