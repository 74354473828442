import * as React from 'react'

function RedoActiveSvgIcon (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={props.color}
          fillRule="nonzero"
          d="M15 14.214L19.714 9.5 15 4.786z"
        />
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 18.5h-1a4.5 4.5 0 010-9H16"
        />
      </g>
    </svg>
  )
}

export default RedoActiveSvgIcon
