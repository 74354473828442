import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";
import {isEmpty} from "lodash";
import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import SearchBar from "./Searchbar";
import SelectionList from "./SelectionList";

import BookingsEmptyStateSvg from "../icons/BookingsEmptyStateSvg.js";


const useStyle = (props) => makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap,
    },
    searchContainer: {
        marginBottom: '0.6rem',
    },
    scrollbar: {
        minHeight: 'calc(100% - 87px)'
    }
}));

function SearchableList({
                            options,
                            onSelect,
                            selected,
                            placeholder,
                            autofocus,
                            emptySearchString,
                            hideSearchBar,
                            customListItem,
                            ...props
                        }) {

    const {theme, className} = props
    const classes = useStyle(props)(theme);
    const {t} = useTranslation();

    const [searchString, setSearchString] = useState('');
    const [optionList, setOptionList] = useState([]);

    useEffect(() => {
        setSearchString('')
    }, [hideSearchBar])

    useEffect(() => {
        if (!searchString)
            setOptionList(options)

        setOptionList(options?.filter(option =>
            option.element.name.toLowerCase()
                .includes(searchString.toLowerCase())))
    }, [searchString, options])

    return (
        <div className={clsx(classes.root, className)}>
            {!hideSearchBar && <SearchBar placeholder={placeholder}
                                          searchString={searchString}
                                          setSearchString={setSearchString}
                                          autofocus={autofocus}
                                          className={classes.searchContainer}/>
            }


            <div className={classes.scrollbar}>
                <Divider/>
                {!isEmpty(optionList) ?
                    <SelectionList options={optionList}
                                   customListItem={customListItem}
                                   onSelect={onSelect}
                                   selected={selected}
                    /> :

                    <div className={classes.emptyState}>
                        <BookingsEmptyStateSvg className={classes.marginBottom}/>
                        <Typography variant={'h5'} align={'center'}>
                            {t("general.no_entries_found")}
                        </Typography>
                    </div>
                }
            </div>

        </div>
    );
}

SearchableList.propTypes = {
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.any,
    placeholder: PropTypes.string,
    emptySearchString: PropTypes.string,
    autofocus: PropTypes.bool,
    className: PropTypes.string,
    hideSearchBar: PropTypes.bool,
    customListItem: PropTypes.func,
};

SearchableList.propTypes = {
    hideSearchBar: false
}

export default SearchableList;