import _, {isObject, isString} from "lodash";

const initState = {
    error: null,
    errorMessage: ''
};

export function errorReducer(state = initState, action) {
    const {error} = action;

    if (error) {
        if (!action.payload || !action.payload.response)
            return {...state}

        let message = action.payload.response.data

        if (isObject(message))
            message = message?.message

        if (isString(message) && message.includes('Missing') && message.includes('cookie')) {
            return {
                ...state,
                error: null
            }
        }

        if (action.type === 'CHANGE_PASSWORD_REJECTED') {
            return {
                ...state,
                errorMessage: 'Incorrect username or password.',
                error: error
            }
        }

        return {
            errorMessage: message ?? 'Something went wrong',
            error: error
        }
    }

    if (action.type === 'RESET_ERROR') {
        return {
            ...state,
            error: null,
        }
    } else if (action.type === 'SET_ERROR') {

        return {
            ...state,
            errorMessage: action.payload,
            error: true
        }
    } else if (action.payload && action.payload.data && !_.isEmpty(action.payload.data.exceptionWrappers)) {
        let message = ""
        if (action.payload.data.exceptionWrappers[0].errorCode)
            message = "[ErrorCode: " + action.payload.data.exceptionWrappers[0].errorCode + "] "

        message = message.concat(action.payload.data.exceptionWrappers[0].message)

        return {
            ...state,
            errorMessage: message,
            error: true
        }
    }

    return {...state};
}