import * as React from "react"

function AnalyticsSvgIcon(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill={props.fill ? props.fill : "#333"} {...props}>
                <path
                    d="M20 4.25c-9.332 0-16.75 7.572-16.75 17.082 0 4.677 1.803 9.02 4.964 12.197a.75.75 0 00.531.221h22.51c.2 0 .39-.08.531-.221 3.161-3.176 4.964-7.52 4.964-12.197 0-9.51-7.418-17.082-16.75-17.082zm0 1.5c8.497 0 15.25 6.894 15.25 15.582l-.005.413c-.099 3.984-1.626 7.67-4.247 10.442l-.061.063H9.062l.215.221c-2.882-2.896-4.527-6.86-4.527-11.139C4.75 12.644 11.503 5.75 20 5.75z"/>
                <path
                    d="M22.25 21.5a2.25 2.25 0 10-4.5 0 2.25 2.25 0 004.5 0zm-1.5 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"/>
                <path
                    d="M21.766 21.749a1.75 1.75 0 00-1.607-1.744l-.175-.006a1.75 1.75 0 00-.143 3.494l.175.006a1.75 1.75 0 001.75-1.75zm-1.5 0a.25.25 0 01-.25.25h-.032a.25.25 0 110-.5h.032a.25.25 0 01.25.25z"/>
                <path
                    d="M23.59 15.012l1.249.832-4.715 7.072-1.248-.832zM11.498 25.253l-.066.004-.09.008c-.606.11-1.022.56-1.081 1.106l-.008.14a1.24 1.24 0 001.249 1.236l.156-.012c.606-.11 1.022-.56 1.081-1.106l.008-.14a1.24 1.24 0 00-1.249-1.236zm-2-5l-.066.004-.09.008c-.606.11-1.022.56-1.081 1.106l-.008.14a1.24 1.24 0 001.249 1.236l.156-.012c.606-.11 1.022-.56 1.081-1.106l.008-.14a1.24 1.24 0 00-1.249-1.236zm19.004 5h-.012a1.24 1.24 0 00-1.237 1.249l.012.156c.11.606.56 1.022 1.106 1.081l.14.008a1.24 1.24 0 001.236-1.249l-.012-.156c-.12-.653-.63-1.084-1.233-1.089zm-14.004-14l-.066.004-.09.008c-.606.11-1.022.56-1.081 1.106l-.008.14a1.24 1.24 0 001.249 1.236l.156-.012c.606-.11 1.022-.56 1.081-1.106l.008-.14a1.24 1.24 0 00-1.249-1.236zm5.5-2l-.066.004-.09.008c-.606.11-1.022.56-1.081 1.106l-.008.14a1.24 1.24 0 001.249 1.236l.156-.012c.606-.11 1.022-.56 1.081-1.106l.008-.14a1.24 1.24 0 00-1.249-1.236zm5.504 2h-.012a1.24 1.24 0 00-1.237 1.249l.012.156c.11.606.56 1.022 1.106 1.081l.14.008a1.24 1.24 0 001.236-1.249l-.012-.156c-.12-.653-.63-1.084-1.233-1.089zm5 9h-.012a1.24 1.24 0 00-1.237 1.249l.012.156c.11.606.56 1.022 1.106 1.081l.14.008a1.24 1.24 0 001.236-1.249l-.012-.156c-.12-.653-.63-1.084-1.233-1.089zm-20.004-5l-.066.004-.09.008c-.606.11-1.022.56-1.081 1.106l-.008.14a1.24 1.24 0 001.249 1.236l.156-.012c.606-.11 1.022-.56 1.081-1.106l.008-.14a1.24 1.24 0 00-1.249-1.236zm19.004 0h-.012a1.24 1.24 0 00-1.237 1.249l.012.156c.11.606.56 1.022 1.106 1.081l.14.008a1.24 1.24 0 001.236-1.249l-.012-.156c-.12-.653-.63-1.084-1.233-1.089z"/>
            </g>
        </svg>
    )
}

export default AnalyticsSvgIcon