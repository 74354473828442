import React, {useState} from 'react';
import {makeStyles, Typography, useTheme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import TrashIcon from "../../common/icons/TrashSvgIcon";
import {connect} from "react-redux";
import CustomButton from "../CustomButton.js";
import ModalWrapper from "../../common/elements/ModalWrapper.js";
import EditSvgIcon from "../../common/icons/EditSvgIcon.js";
import ClickableText from "../../common/elements/ClickableText.js";
import SeatModalContentAssignment from "./SeatModalContentAssignment.js";
import EditAssignmentsTimePeriod from "./EditAssignmentsTimePeriod.js";
import {
    loadWorkplaceAssignments,
    setTimePeriodForAssignment,
    updateWorkplaceAssignment
} from "../../actions/workplaceAssignment-action.js";
import moment from "moment";
import {getTimePeriodAsString} from "../../common/utils/TimeUtils.js";
import Divider from "@material-ui/core/Divider";
import {TYPE_OF_BOOKING_HOMEOFFICE, TYPE_OF_BOOKING_OUT_OF_OFFICE} from "../../common/utils/NameUtils.js";
import HomeOfficeTippsStandUpSvg from "../../common/icons/HomeOfficeTippsStandUpSvg.js";
import HomeOfficeTippsWaterSvg from "../../common/icons/HomeOfficeTippsWaterSvg.js";
import HomeOfficeTippsPantsSvg from "../../common/icons/HomeOfficeTippsPantsSvg.js";
import {filterForAssignmentsBlockingNewBooking} from "../../common/utils/AssignmentUtils";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";

const useStyle = makeStyles(theme => ({
    headerIcon: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0px  !important',
        height: '24px !important',
        cursor: 'pointer',
        width: '24px !important',
        maxWidth: '24px !important',
    },
    headerActionsRight: {
        display: 'flex',
        gap: theme.innerGap,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem'
    },
    subContentGapContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
    orangeText: {
        color: theme.colors.palette.corporate.skinMain,
    },
}))

function WorkplaceBoookingModal({
                                    open,
                                    assignment,
                                    allAssignments,
                                    onClose,
                                    onDelete,
                                    timePeriod,
                                    setTimePeriod,
                                    personId,
                                    updateAssignment,
                                    loadWorkplaceAssignments,
                                    workplaceAssignments
                                }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [editError, setEditError] = useState(false);
    const [assignmentsToConsider, setAssignmentsToConsider] = useState([...filterForAssignmentsBlockingNewBooking(allAssignments), ...workplaceAssignments]);

    useDidMountEffect(() => {
        if (editMode) {
            if (assignment.workplace && assignment.workplace.id) {
                loadWorkplaceAssignments(assignment.workplace.id, moment(assignment.timePeriod.startDate).startOf('day').toISOString(),
                    moment(assignment.timePeriod.endDate).endOf('day').toISOString())
            }
        }
    }, [editMode])

    useDidMountEffect(() => {
        if (editMode) {
            setAssignmentsToConsider([...filterForAssignmentsBlockingNewBooking(allAssignments), ...workplaceAssignments])
        }
    }, [allAssignments, workplaceAssignments])

    if (!open)
        return null

    const onEditSubmit = () => {
        updateAssignment(personId, assignment.id, {...assignment, timePeriod: timePeriod})
        setEditMode(false)
    }

    const closePopup = () => {
        setEditMode(false)
        onClose()
    }

    const getHeaderAction = () => {
        return (
            <div>
                {editMode
                    ? <div className={classes.headerActionsRight}>
                        <ClickableText text={t('done')}
                                       disabled={editError}
                                       textColor={theme.colors.palette.corporate.skinMain}
                                       onClick={onEditSubmit}/>
                    </div>
                    : <div className={classes.headerActionsRight}>
                        <CustomButton icon={<EditSvgIcon width={24} height={24}/>}
                                      onClick={() => setEditMode(true)}
                                      customClassName={classes.headerIcon}
                        />
                        <CustomButton icon={<TrashIcon/>}
                                      onClick={() => {
                                          onDelete()
                                          onClose()
                                      }}
                                      textColor={theme.colors.palette.corporate.redMain}
                                      customClassName={classes.headerIcon}
                        />
                    </div>
                }
            </div>
        )
    }

    const getRandomHomeofficeTipp = () => {
        let randomNumber = Math.floor(Math.random() * 3);
        if (randomNumber === 0) {
            return <div className={classes.homeOfficeTips}>
                <HomeOfficeTippsStandUpSvg className={classes.svg}/>
                <Typography variant={'h3'}>{t("stand_up")}</Typography>
                <Typography>{t("stand_up_text")}</Typography>
                <Typography variant={'h5'}>{t("stand_up_text_bold")}</Typography>
            </div>
        } else if (randomNumber === 1) {
            return <div className={classes.homeOfficeTips}>
                <HomeOfficeTippsWaterSvg className={classes.svg}/>
                <Typography variant={'h3'}>{t("drink_water")}</Typography>
                <Typography>{t("drink_water_text")}</Typography>
                <Typography variant={'h5'}>{t("drink_water_text_bold")}</Typography>
            </div>
        } else {
            return <div className={classes.homeOfficeTips}>
                <HomeOfficeTippsPantsSvg className={classes.svg}/>
                <Typography variant={'h3'}>{t("without_pants")}</Typography>
                <Typography>{t("without_pants_text")}</Typography>
                <Typography variant={'h5'}>{t("without_pants_text_bold")}</Typography>
            </div>
        }
    }

    return (
        <ModalWrapper open={open}
                      onClose={closePopup}
                      headerActions={getHeaderAction()}>
            <div className={classes.content}>

                <Typography variant={"h6"}>{t('booking_details')}</Typography>

                <div className={classes.subContentGapContainer}>
                    <Typography variant={"h3"} className={classes.meetingTitle}>
                        {assignment.workplace.name}
                    </Typography>
                    <div className={classes.dateTimeWrapper}>
                        <Typography variant={"body1"} className={classes.orangeText}>
                            {assignment ? moment(assignment.timePeriod.startDate).format("dd, DD.MM.YYYY") : null}
                        </Typography>
                        {!editMode &&
                            <Typography variant={"body1"}
                                        className={classes.orangeText}> {getTimePeriodAsString(assignment.timePeriod)}</Typography>}
                    </div>
                    {assignment.buildingName && <Typography
                        variant={"h6"}> {assignment.buildingName + ", " + assignment.floorName}</Typography>}
                </div>

                <Divider/>

                {editMode
                    ? <EditAssignmentsTimePeriod assignment={assignment}
                                                 allAssignments={assignmentsToConsider}
                                                 onEditError={setEditError} onChange={setTimePeriod}/>
                    : assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_HOMEOFFICE || assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_OUT_OF_OFFICE
                        ? getRandomHomeofficeTipp()
                        : <SeatModalContentAssignment assignment={assignment}/>
                }
            </div>
        </ModalWrapper>
    )
}

WorkplaceBoookingModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.any.isRequired,
    assignment: PropTypes.any,
    onDelete: PropTypes.func.isRequired,
    allAssignments: PropTypes.array,
}

const mapStateToProps = state => {
    return {
        personId: state.user.person.id,
        timePeriod: state.assignmentBuilder.timePeriod,
        workplaceAssignments: state.workplace.workplaceAssignments,
    }
};

let mapDispatchToProps = {
    setTimePeriod: setTimePeriodForAssignment,
    updateAssignment: updateWorkplaceAssignment,
    loadWorkplaceAssignments: loadWorkplaceAssignments
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceBoookingModal)
