import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import clsx from "clsx";
import moment from "moment";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import TimeRange from 'react-timeline-range-slider';

import {Typography, withStyles} from "@material-ui/core";

import LoadingIndicator from "./LoadingIndicator";

import AlertRoundSvgIcon from "../icons/AlertRoundSvgIcon.js";

import {
    dateDiffToDurationStringWithUnits,
    getTimeAtDate,
    isSameDay,
    isSameHoursAndMinute,
    isTimeperiodOverlappingDisabledIntervals,
    roundToNearestXMinutes
} from "../utils/TimeUtils.js";


const styles = theme => ({
    root: {
        width: '100%',
        '& .react_time_range__track__disabled': {
            height: '20px',
            boxShadow: '1px 0 0 0px #C8CACC',
            borderRight: 'none',
            background: 'repeating-linear-gradient(-45deg, transparent, transparent 3px, #363a40 4px, #363a40 2px)'
        },
        '& .react_time_range__track': {
            background: theme.colors.gradients.skinMain,// + ' !important',
            height: '18px',
            borderRight: '1px solid #C8CACC',
        },
        '& .react_time_range__handle_marker': {
            backgroundColor: theme.colors.palette.corporate.skinMain + ' !important',
        },
        '& .react_time_range__time_range_container': {
            width: '96%',
            padding: '0px',
            margin: '40px 2% 0',
            height: '55px'
        },
        '& .react_time_range__tick_label': {
            fontSize: '13px',
            marginLeft: '-6px !important'
        },
        '& .react_time_range__rail__inner': {
            backgroundColor: 'transparent'
        }
    },
    error: {
        '& .react_time_range__track': {
            background: theme.colors.palette.corporate.redMain + ' !important',
        },
    },
    textWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'row',
    },
    errorMessage: {
        color: theme.colors.palette.corporate.redMain,
    },
    alertIcon: {
        height: '24px',
        width: '24px'
    },
    errorWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '66px',
        marginBottom: '-26px',
        alignItems: 'center',
        marginTop: '2px',
    },
    timeWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
})

function EditableTimelineWithHeaderAndError({
                                                classes,
                                                t,
                                                setTimeCallback,
                                                showFrom,
                                                showUntil,
                                                timePeriod,
                                                minutesPerStep,
                                                disableEndSlider,
                                                hideTimeHeader,
                                                disableEarlierThanNow,
                                                errorCallback,
                                                disabledIntervals,
                                                className,
                                                ...props
                                            }) {
    const stepSize = minutesPerStep * 60000
    const [error, setError] = useState(false);
    const [selectedInterval, setSelectedInterval] = useState([]);

    //used only to keep the time in the header up-to-date on slide, don't use selectedInterval as it will trigger the onChange function when newly set
    const [currentSelectedInterval, setCurrentSelectedInterval] = useState([]);

    useEffect(() => {
        if (timePeriod && timePeriod.startDate && (!isSameDay(timePeriod.startDate, selectedInterval[0]) || !isSameHoursAndMinute(timePeriod.startDate, selectedInterval[0]) || !isSameHoursAndMinute(timePeriod.endDate, selectedInterval[1]))) {
            let startDate = new Date(timePeriod.startDate)
            let endDate = new Date(timePeriod.endDate)

            setCurrentSelectedInterval([startDate, endDate])
            setSelectedInterval([startDate, endDate])
        }
        //eslint-disable-next-line
    }, [timePeriod])

    //because initial error is not detected by TimeRange
    useEffect(() => {
        const initialError = isTimeperiodOverlappingDisabledIntervals(timePeriod, disabledIntervals)
        setError(initialError)
        errorCallback && errorCallback(initialError)
        // eslint-disable-next-line
    }, [disabledIntervals]);

    function onUpdateTimeInterval(e) {
        if (e.error !== error) {
            setError(e.error)
            errorCallback && errorCallback(e.error)
        }
        //to prevent change of enddate
        if (disableEndSlider && !isSameHoursAndMinute(e.time[1], selectedInterval[1], true)) {
            const endDate = getEndDateToSetForDisabledEnd()
            setSelectedInterval([e.time[0], endDate])
            if (!hideTimeHeader)
                setCurrentSelectedInterval([e.time[0], endDate])
        } else {
            if (!hideTimeHeader)
                setCurrentSelectedInterval(e.time)
        }
    }

    function onChangeCallback(time) {
        if (!timePeriod.startDate || !timePeriod.endDate
            || !isSameHoursAndMinute(time[0], timePeriod.startDate) || !isSameHoursAndMinute(time[1], timePeriod.endDate)) {
            if (disableEndSlider) {
                setTimeCallback([time[0], getEndDateToSetForDisabledEnd()])
            } else {
                setTimeCallback(time)
            }

            errorCallback && errorCallback(error)
        }
    }

    function getEndDateToSetForDisabledEnd() {
        const roundedCurrentDateTime = roundToNearestXMinutes(new Date(), minutesPerStep)
        roundedCurrentDateTime.set('milliseconds', moment().get('millisecond'))
        return roundedCurrentDateTime > showUntil ? showUntil : roundedCurrentDateTime
    }

    return (
        <div className={clsx(classes.root, error && classes.error, className)}>
            {!hideTimeHeader && <div className={classes.textWrapper}>
                <Typography className={classes.heading} variant={'h6'}>{t("timeslot")}</Typography>
                <div className={classes.timeWrapper}>
                    <Typography className={error ? classes.errorMessage : ''} variant={'body1'}>
                        {moment(currentSelectedInterval[0]).format("HH:mm")}
                    </Typography>
                    <Typography variant={'body1'}>-</Typography>
                    <Typography className={error ? classes.errorMessage : ''} variant={'body1'}>
                        {moment(currentSelectedInterval[1]).format("HH:mm")}
                    </Typography>
                    <Typography variant={'h6'}>
                        &nbsp;({dateDiffToDurationStringWithUnits(currentSelectedInterval[0], currentSelectedInterval[1])})
                    </Typography>
                </div>
            </div>}

            {error &&
                <div className={classes.errorWrapper}>
                    <AlertRoundSvgIcon className={classes.alertIcon}/>
                    <Typography variant={'caption'}
                                className={classes.errorMessage}>{t("error_meeting_time")}</Typography>
                </div>}

            {disabledIntervals === null
                ? <LoadingIndicator/>
                : <TimeRange
                    ticksNumber={24}
                    step={stepSize}
                    error={error}
                    selectedInterval={selectedInterval}
                    timelineInterval={[new Date(showFrom), new Date(showUntil)]}
                    onUpdateCallback={onUpdateTimeInterval}
                    onChangeCallback={(time) => {
                        onChangeCallback(time)
                    }}
                    disabledIntervals={disableEarlierThanNow ? [...disabledIntervals, {
                        start: getTimeAtDate(showFrom, new Date()),
                        end: moment().toDate()
                    }] : disabledIntervals}
                    formatTick={ms => moment(ms).format('HH')}
                />
            }
        </div>
    )
}

EditableTimelineWithHeaderAndError.propTypes = {
    setTimeCallback: PropTypes.func,
    errorCallback: PropTypes.func,
    timePeriod: PropTypes.any,
    showFrom: PropTypes.any,
    showUntil: PropTypes.any,
    minutesPerStep: PropTypes.number,
    disableEndSlider: PropTypes.bool,
    hideTimeHeader: PropTypes.bool,
    //to disable all times earlier than right now
    disableEarlierThanNow: PropTypes.bool,
    disabledIntervals: PropTypes.array,
}

EditableTimelineWithHeaderAndError.defaultProps = {
    minutesPerStep: 15,
    disabledIntervals: []
}

export default compose(withStyles(styles), withTranslation())(EditableTimelineWithHeaderAndError)
