import React from 'react';
import PropTypes from "prop-types";

import moment from "moment";
import {connect} from "react-redux";
import {isArray, isEmpty} from "lodash";
import {useTranslation} from "react-i18next";


import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles, TableBody, TableContainer} from "@material-ui/core";

import TrashSvgIcon from "../../common/icons/TrashSvgIcon.js";

import {deleteWorkplaceAssignment} from "../../actions/user-action.js";


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    headerText: {
        textTransform: 'uppercase',
    },
    lastCell: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));


export function sortVacationByStartDate(vacations) {
    if (!vacations || !isArray(vacations))
        return []

    return vacations.sort((x, y) => {
        if (moment(x.timePeriod.startDate).isBefore(y.timePeriod.startDate)) {
            return -1
        }
        return 1
    })
}

function VacationTable({vacationList, userId, deleteAssignment}) {

    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyle(theme);

    function onDeleteVacation(assignmentId) {
        deleteAssignment(userId, assignmentId)
    }

    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableHead className={classes.header}>
                    <TableRow>
                        <TableCell>
                            <Typography className={classes.headerText} variant={'subtitle1'}>{t('start')}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.headerText} variant={'subtitle1'}>{t('end')}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.headerText}
                                        variant={'subtitle1'}>{t('vacation_days_of_work')}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                {!isEmpty(vacationList) ?
                    <TableBody>
                        {sortVacationByStartDate(vacationList).map((vac) => {
                            return <TableRow key={vac.id}>
                                <TableCell>{moment(vac.timePeriod.startDate).format('dd DD.MM.YYYY')}</TableCell>
                                <TableCell>{moment(vac.timePeriod.endDate).format('dd DD.MM.YYYY')}</TableCell>
                                <TableCell>
                                    <div className={classes.lastCell}>
                                        {moment(vac.timePeriod.endDate).diff(vac.timePeriod.startDate, 'd') + 1}
                                        <TrashSvgIcon onClick={() => onDeleteVacation(vac.assignmentId)}/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody> : null
                }
            </Table>
        </TableContainer>
    );
}

VacationTable.propTypes = {
    vacationList: PropTypes.array,
};

VacationTable.defaultProps = {
    vacationList: [],
};

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
    }
};

let mapDispatchToProps = {
    deleteAssignment: deleteWorkplaceAssignment,
}

export default connect(mapStateToProps, mapDispatchToProps)(VacationTable);