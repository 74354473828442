import React from 'react'

const DrawPillarSvgIcon = props =>
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fillRule="nonzero" stroke={props.stroke} fill="none">
            <path d="M5 21h14M5 3h14"/>
            <rect x={9} y={3} width={6} height={18} rx={1}/>
        </g>
    </svg>

export default DrawPillarSvgIcon