import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Background from '../../common/img/InstallationBackground.svg'
import GoconutLogo from "../../common/img/goconut_signet.png";
import CustomButton from "../CustomButton.js";

const useStyle = makeStyles(theme => ({
    root: {
        padding: '12px 18px 12px 18px',
        position: 'absolute',
        bottom: '0px',
        borderRadius: '18px 18px 0 0',
        left: 0,
        right: 0,
        margin: '0 auto',
        maxWidth: 'calc(800px - 18px - 18px)',
        boxShadow: '0px 1px 19px 8px rgba(0,0,0,0.25)',
        height: 'calc(217px - 24px)',
        overflowX: 'hidden',
        zIndex: 2,
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    installationHeader: {
        display: "flex",
        padding: "12px 0 13px 0"
    },
    infoText: {
        paddingLeft: "16px",
        textAlign: "left"
    },
    declineInstallation: {
        paddingTop: "12px",
        textTransform: "none",
        '& .MuiButton-label': {
            color: theme.colors.palette.neutral.greyMain,
            fontSize: '14px'
        }
    },
    logo: {
        height: '38px',
        width: '38px',
        background: theme.colors.palette.corporate.greenDark,
        padding: '10px',
        borderRadius: '8px',
    },
    white: {
        color: theme.colors.palette.neutral.white
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    }
}));

function InstallationDialog({deferredPrompt, closeDialog, ...props}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);

    const onDecline = () => {
        localStorage.setItem('installationSelection', false.toString())
        closeDialog()
    }

    const onInstall = async () => {
        localStorage.setItem('installationSelection', true.toString())
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt = null;
        }
        closeDialog()
    }

    return (
        <div className={classes.root}>
            <div className={classes.installationHeader}>
                <img src={GoconutLogo} alt='' className={classes.logo}/>
                <div className={classes.infoText}>
                    <Typography variant={'h5'} className={classes.white}>{t("installInfo1")}</Typography>
                    <Typography variant={'body1'} className={classes.white}>{t("installInfo2")}</Typography>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <CustomButton text={t('install')} primary
                              onClick={() => onInstall()}
                />
                <Button className={classes.declineInstallation}
                        onClick={() => onDecline()}>{t("decline_install")}</Button>
            </div>
        </div>
    );
}

InstallationDialog.propTypes = {
    deferredPrompt: PropTypes.any,
    setOpen: PropTypes.func
};
InstallationDialog.defaultPropTypes = {
    open: true
};
export default InstallationDialog;