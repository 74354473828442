import * as React from "react"

function LocationFilledSvgIcon(props) {
    return (
        <svg width={12} height={12} viewBox={"0 0 12 12"} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6 0c2.623 0 4.75 2.11 4.75 4.714a4.642 4.642 0 0 1-.956 2.828h-.001l-.149.193L6 12 2.357 7.736c-.02-.024-.15-.193-.15-.193a4.644 4.644 0 0 1-.957-2.829C1.25 2.111 3.377 0 6 0zm0 2.571c-1.192 0-2.16.96-2.16 2.143 0 1.184.968 2.143 2.16 2.143a2.153 2.153 0 0 0 2.16-2.143A2.151 2.151 0 0 0 6 2.571z"
                {...props}
                fillRule="evenodd"
            />
        </svg>
    )
}

export default LocationFilledSvgIcon
