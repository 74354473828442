import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {withTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";

import ActivityButtonRound from "./ActivityButtonRound";
import PhoneSvgIcon from "../../common/icons/PhoneSvgIcon";
import BrainstormingSvgIcon from "../../common/icons/BrainstormingSvgIcon";
import UndefinedSvgIcon from "../../common/icons/UndefinedSvgIcon";
import OneOnOneSvgIcon from "../../common/icons/OneOnOneSvgIcon";
import ConcentrateWorkingSvgIcon from "../../common/icons/ConcentrateWorkingSvgIcon";
import InfoSvgIcon from "../../common/icons/InfoSvgIcon";

import ActivitiesExplanationModal from "./ActivitiesExplanationModal";
import {
    TYPE_OF_ACTIVITY_CALLS,
    TYPE_OF_ACTIVITY_CONCENTRATE,
    TYPE_OF_ACTIVITY_CREATIVE,
    TYPE_OF_ACTIVITY_TALK,
    TYPE_OF_ACTIVITY_UNDEFINED,
    TYPES_OF_ACTIVITIES
} from "../../common/utils/NameUtils";


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
    },
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    selection: {
        textTransform: 'capitalize',
    }
}));

function ActivitySelectionGroup({onChange, initialSelected, t}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    const [selected, setSelected] = useState(initialSelected);
    const [modalOpen, setModalOpen] = useState(false);

    const onButtonClicked = (index) => {

        let newState = null
        const selectedActivity = TYPES_OF_ACTIVITIES[index]

        if (selected !== selectedActivity) {
            newState = selectedActivity
        }
        
        setSelected(newState)

        if (onChange)
            onChange(newState)
    }

    const isSelected = (activity) => {
        return selected === activity
    }

    const onInfoClicked = () => {
        setModalOpen(true)
    }
    const onModalClose = () => {
        setModalOpen(false)
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.leftHeader}>
                    <Typography variant={'h6'}>
                        {t('activities')}
                    </Typography>
                    <Typography variant={'h6'}>
                        {selected ? '1' : '0'}{'/1'}
                    </Typography>
                    <div className={classes.header} onClick={onInfoClicked}>
                        <InfoSvgIcon color={theme.colors.palette.neutral.greyMain}/>
                    </div>
                </div>
                <div className={classes.selection}>
                    <Typography variant={'h6'}>
                        {selected}
                    </Typography>
                </div>
            </div>
            <div className={classes.content}>
                <ActivityButtonRound color={theme.colors.palette.corporate.skinMain} index={0}
                                     selected={isSelected(TYPE_OF_ACTIVITY_CONCENTRATE)}
                                     onButtonClick={(index) => onButtonClicked(index)}>
                    <ConcentrateWorkingSvgIcon width={30} height={29} color={theme.colors.palette.neutral.white}/>
                </ActivityButtonRound>
                <ActivityButtonRound color={theme.colors.palette.indicator.greenMain} index={1}
                                     selected={isSelected(TYPE_OF_ACTIVITY_CALLS)}
                                     onButtonClick={(index) => onButtonClicked(index)}>
                    <PhoneSvgIcon width={30} height={29} color={theme.colors.palette.neutral.white}/>
                </ActivityButtonRound>
                <ActivityButtonRound color={theme.colors.palette.indicator.purpleMain} index={2}
                                     selected={isSelected(TYPE_OF_ACTIVITY_CREATIVE)}
                                     onButtonClick={(index) => onButtonClicked(index)}>
                    <BrainstormingSvgIcon width={30} height={29} color={theme.colors.palette.neutral.white}/>
                </ActivityButtonRound>
                <ActivityButtonRound color={theme.colors.palette.corporate.blueMain} index={3}
                                     selected={isSelected(TYPE_OF_ACTIVITY_TALK)}
                                     onButtonClick={(index) => onButtonClicked(index)}>
                    <OneOnOneSvgIcon width={30} height={29} color={theme.colors.palette.neutral.white}/>
                </ActivityButtonRound>
                <ActivityButtonRound color={theme.colors.palette.indicator.pink} index={4}
                                     selected={isSelected(TYPE_OF_ACTIVITY_UNDEFINED)}
                                     onButtonClick={(index) => onButtonClicked(index)}>
                    <UndefinedSvgIcon width={30} height={29} color={theme.colors.palette.neutral.white}/>
                </ActivityButtonRound>
            </div>

            <ActivitiesExplanationModal open={modalOpen} onClose={onModalClose}/>

        </div>
    );
}

ActivitySelectionGroup.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialSelected: PropTypes.string,
};

export default withTranslation()(ActivitySelectionGroup);