import React, {useEffect} from 'react';
import PropTypes from 'prop-types'

import moment from "moment";
import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {useTheme} from "@material-ui/core";

import LoadingIndicator from "../../common/elements/LoadingIndicator.js";
import PieChartWithTitleAndLegend from "./PieChartWithTitleAndLegend.js";

import MeetingRoomSvgIcon from "../../common/icons/MeetingRoomSvgIcon.js";
import MeetingAttendingOnlineSvgIcon from "../../common/icons/MeetingAttendingOnlineSvgIcon.js";

import {getUserMeetingStatistics} from "../../actions/dashboard-actions.js";

import {minutesToHours} from "../../common/utils/TimeUtils.js";

function UserStatisticsPieChart({
                                    startDate,
                                    endDate,
                                    getUserMeetingStatistics,
                                    statisticsPending,
                                    statistics,
                                }) {
    const theme = useTheme()
    const {t} = useTranslation()

    useEffect(() => {
        if (startDate && endDate) {
            getUserMeetingStatistics(moment(startDate).toDate(), moment(endDate).toDate())
        }
        //eslint-disable-next-line
    }, [endDate])

    const chartData = React.useMemo(
        () => {
            let totalMeetingInPerson = statistics.offlineMeetingHours
            let totalMeetingRemote = statistics.onlineMeetingHours
            let totalMeetingTime = statistics.onlineMeetingHours + statistics.offlineMeetingHours

            return [
                {
                    label: t('meetings_in_person'),
                    angle: totalMeetingTime === 0 ? 1 : totalMeetingInPerson,
                    color: theme.colors.palette.corporate.greenLightest,
                    value: (minutesToHours(totalMeetingInPerson)),
                    icon: <MeetingRoomSvgIcon width={18} height={18}/>
                },
                {
                    label: t('meetings_remote'),
                    angle: totalMeetingTime === 0 ? 1 : totalMeetingRemote,
                    color: theme.colors.palette.corporate.greenLight,
                    value: minutesToHours(totalMeetingRemote),
                    icon: <MeetingAttendingOnlineSvgIcon width={18} height={18}
                                                         stroke={theme.colors.palette.neutral.darkMain}/>
                },
            ]
        },
        //eslint-disable-next-line
        [statistics]
    )

    return <div>
        {statisticsPending || isEmpty(statistics) ?
            <LoadingIndicator/> :
            <PieChartWithTitleAndLegend headerTitle={t('meetings')}
                                        title={t('distribution_meeting_headline')}
                                        chartData={chartData}
                                        legendPostfix={'h'}
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxHeight/>
        }
    </div>
}

UserStatisticsPieChart.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        statistics: state.dashboard.userMeetingStatistics,
        statisticsPending: state.dashboard.userMeetingStatisticsPending,
    }
}

const mapDispatchToProps = {
    getUserMeetingStatistics: getUserMeetingStatistics,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserStatisticsPieChart);