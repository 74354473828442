import React from 'react';
import {makeStyles, Typography, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(theme => ({
    codes: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
}));

function QRCodeCategoryWithTitle({content, title}) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation()

    return (
        <div className={classes.codes}>
            <Typography variant={'h5'}>{t(title)}</Typography>
            {React.cloneElement(content)}
        </div>
    );
}

QRCodeCategoryWithTitle.propTypes = {
    content: PropTypes.element.isRequired,
    title: PropTypes.string,
}

export default QRCodeCategoryWithTitle