import * as React from "react"

function RatingsSvgIcon(props) {
    return (
        <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <path
                d="M7 12h3.5v11.25H7a1 1 0 01-1-1V13a1 1 0 011-1zm12.184.75h2.728a2 2 0 011.883 2.673l-1.847 5.172a4 4 0 01-3.767 2.655h-1.754a9.03 9.03 0 01-2.874-.471L10.5 21.75h0V13.5l3.322-3.305a14.748 14.748 0 002.43-3.185l.331-.583a1.26 1.26 0 011.792-.426 1.88 1.88 0 01.772 2.055l-.929 3.434a1 1 0 00.965 1.261z"
                stroke={props.stroke || '#333'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default RatingsSvgIcon