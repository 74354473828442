const initialState = {
    typesOfWorkplace: [],
    typesOfActivities: [],
    activityAttributes: [],
    typesOfUse: [],
}

function commonReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_TYPES_OF_ACTIVITIES_FULFILLED':

            return {
                ...state,
                typesOfActivities: [...action.payload.data]
            }

        case 'GET_ACTIVITY_ACTIONS_FULFILLED':

            return {
                ...state,
                activityAttributes: [...action.payload.data]
            }

        case 'GET_TYPES_OF_USE_FULFILLED':

            return {
                ...state,
                typesOfUse: [...action.payload.data]
            }

        default:
            return state
    }
}


export default commonReducer