import * as React from "react"
import PropTypes from "prop-types";
import theme from "../../theme.js";

function AfternoonSvgIcon({color, size, ...props}) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={color}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <circle cx={20} cy={20} r={7}/>
                <path
                    d="M20 9V5m0 30v-4M31 20h4M5 20h4M27.778 12.222l2.829-2.828M9.394 30.607l2.828-2.828M27.778 27.78l2.828 2.828M9.393 9.394l2.828 2.829"/>
            </g>
        </svg>
    )
}

AfternoonSvgIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

AfternoonSvgIcon.defaultProps = {
    color: theme.colors.palette.neutral.darkMain,
    size: 24,
};

export default AfternoonSvgIcon