import React, {useCallback, useEffect, useState} from 'react';
import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {useCookies} from "react-cookie";
import {useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";

import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import useTheme from "@material-ui/core/styles/useTheme";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";

import ChooseSeatDetails from "./ChooseSeatDetails";
import LoadingIndicator from "../../common/elements/LoadingIndicator";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import {getAssignmentsOfUser, selectCoworker} from "../../actions/user-action";
import {setSelectedDate, setTimePeriodForAssignment} from "../../actions/workplaceAssignment-action";
import {navigate, PATH_CHOOSE_SEAT, PATH_HOME} from "../../common/utils/NavigationUtils";
import WorkplaceBookingByActivity from "./WorkplaceBookingByActivity.js";
import {
    filterForAssignmentsBlockingNewBooking,
    getAssignmentsAsIntervals,
    isDateBookedByUserAllDay
} from "../../common/utils/AssignmentUtils.js";
import {getWorkingHoursOrDefaultHoursForDate} from "../../common/customHooks/usePersonWorkingHours.js";
import SingleLineDatePickerWithUsersWorkingHours from "../SingleLineDatePickerWithUsersWorkingHours";
import DaytimeSelectionWithUserWorkingHours from "../../common/elements/DaytimeSelectionWithUserWorkingHours.js";
import {getFreeDaytimeFromDisabledIntervals} from "../../common/utils/TimeUtils.js";
import ChooseWorkTogetherPage from "./ChooseWorkTogetherContent.js";
import {selectWorkingGroup} from "../../actions/workingGroups-action.js";
import CustomTabs from "../CustomTabs.js";
import CustomTab from "../CustomTab.js";
import ConcentrateWorkingSvgIcon from "../../common/icons/ConcentrateWorkingSvgIcon.js";
import WorkingGroupSvgIcon from "../../common/icons/WorkingGroupSvgIcon.js";
import LocationSvgIcon from "../../common/icons/LocationSvgIcon.js";
import PeopleSvgIcon from "../../common/icons/PeopleSvgIcon.js";

const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
        [theme.breakpoints.between('lg', 'xl')]: {
            padding: theme.paddingContentContainer.padding,
        },
    },
    container: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: theme.gridSizes.maxWidth,
        }
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem'
    },
}));

function BookSeatPage({
                          userId,
                          assignments,
                          selectedDate,
                          fetchAssignments,
                          assignmentsPending,
                          setSelectedDate,
                          setTimeperiod,
                          selectGroup,
                          selectCoworker,
                          t,
                          history
                      }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {selection} = useParams()
    const locale = useCookies(['language'])[0].language

    const localStorageSelection = localStorage.getItem('selectedBookSeatOption')

    const [pageSelection, setPageSelection] = useState(selection ? Number(selection) : localStorageSelection ? Number(localStorageSelection) : 0);
    const [confirmationButtonsDisabled, setConfirmationButtonsDisabled] = useState(false);

    useEffect(() => {
        if (isEmpty(assignments) && userId)
            fetchAssignments(userId, false, true)
        // eslint-disable-next-line
    }, [userId, fetchAssignments])

    useEffect(() => {
        localStorage.setItem('selectedBookSeatOption', pageSelection.toString())
    }, [pageSelection])

    function onSwitchButtonClicked(index) {
        navigate(history, PATH_CHOOSE_SEAT + '/' + index)
        setPageSelection(index)
    }

    function routeToHome() {
        navigate(history, PATH_HOME)
    }

    function setDate(date) {
        setSelectedDate(date)
        if (pageSelection === 2 || pageSelection === 3) {
            selectCoworker(null)
            selectGroup(null)
        }
    }

    const showDateIndicator = useCallback((date) => {
        return isDateBookedByUserAllDay(date, assignments, getWorkingHoursOrDefaultHoursForDate(date))
    }, [assignments])

    const content = () => {
        switch (pageSelection) {
            case 1:
                return <WorkplaceBookingByActivity confirmationButtonsDisabled={confirmationButtonsDisabled}/>
            case 2:
            case 3:
                return <ChooseWorkTogetherPage confirmationButtonsDisabled={confirmationButtonsDisabled}
                                               pageSelection={pageSelection}/>
            case 0:
            default:
                return <ChooseSeatDetails/>
        }
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item sm={12} className={classes.container}>
                <CssBaseline/>
                <div className={classes.content}>
                    <HeaderWithTitleAndBackButton title={t('workplace_details')}
                                                  backLabel={t("home")}
                                                  onNavigateBack={routeToHome}/>
                    <div>
                        <CustomTabs
                            onChange={(index) => {
                                if (pageSelection !== index)
                                    onSwitchButtonClicked(index)
                            }}
                            initialSelected={pageSelection}>
                            <CustomTab
                                // label={t('activities')}
                                icon={<LocationSvgIcon size={24}/>}/>
                            <CustomTab
                                // label={t('choose')}
                                icon={<ConcentrateWorkingSvgIcon width={24} height={24}/>}/>
                            <CustomTab
                                // label={t('working_groups')}
                                icon={<WorkingGroupSvgIcon/>}/>
                            <CustomTab
                                // label={t('colleagues')}
                                icon={<PeopleSvgIcon height={24} width={24}/>}/>
                        </CustomTabs>
                    </div>

                    {!assignmentsPending &&
                        <SingleLineDatePickerWithUsersWorkingHours onChange={(date) => setDate(date)}
                                                                   startDate={new Date()}
                                                                   color={theme.colors.palette.corporate.skinMain}
                                                                   caption={t('date')} local={locale}
                                                                   showIndicator={showDateIndicator}
                                                                   skipDate={showDateIndicator}
                                                                   selected={selectedDate ? selectedDate : new Date()}/>}
                    {(pageSelection === 1 || pageSelection === 2) && !assignmentsPending &&
                        <DaytimeSelectionWithUserWorkingHours setTimePeriodCallback={setTimeperiod}
                                                              date={selectedDate}
                                                              disabledIntervals={getAssignmentsAsIntervals(filterForAssignmentsBlockingNewBooking(assignments))}
                                                              errorCallback={setConfirmationButtonsDisabled}
                                                              selectedCallback={getFreeDaytimeFromDisabledIntervals}/>}


                    {!assignmentsPending
                        ? content()
                        : <LoadingIndicator/>}
                </div>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        assignments: state.user.userAssignments,
        assignmentsPending: state.user.getAssignmentsPending,
        selectedDate: state.assignmentBuilder.selectedDate,
    }
}

const mapDispatchToProps = {
    fetchAssignments: getAssignmentsOfUser,
    setSelectedDate: setSelectedDate,
    setTimeperiod: setTimePeriodForAssignment,
    selectCoworker: selectCoworker,
    selectGroup: selectWorkingGroup,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BookSeatPage));