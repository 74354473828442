import React from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {withStyles} from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline'

import SearchableList from "../../common/elements/SearchableList";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import {setFloor} from "../../actions/floors-actions";
import {getBuilding} from "../../actions/buildings-actions.js";

const styles = theme => ({
    root: {
        height: '100%',
        minHeight: '100%',
        width: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-around',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
})

class ChooseFloorPage extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.buildingsPending)
            return

        if ((this.props.building && !prevProps.building) || (this.props.building && !this.props.building.floors))
            this.props.getBuilding(this.props.building.id, true)
    }

    getRelevantFloors = () => {
        if (!this.props.building || !this.props.building.floors)
            return []

        return this.props.building?.floors?.map(floor => ({element: floor}))
    }

    handleFloorSelected = (floor) => {
        this.props.setSelectedFloor(floor)
        setTimeout(() => {
            this.routeToBefore()
        }, 250);
    }

    routeToBefore = () => {
        this.props.history.goBack()
    }

    render() {
        const {classes, t, floor} = this.props;
        return (
            <Grid container component={Paper} className={classes.root}>
                <CssBaseline/>
                <Grid item sm={12} className={classes.container}>
                    <div className={classes.content}>
                        <HeaderWithTitleAndBackButton title={t('floors')} backLabel={t('back')}
                                                      onNavigateBack={this.routeToBefore}/>


                        <SearchableList options={this.getRelevantFloors()} onSelect={this.handleFloorSelected}
                                        selected={floor} placeholder={t('search_floors')}
                                        emptySearchString={t('no_floors_available')}
                        />
                    </div>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        buildings: state.buildings.buildingList,
        building: state.buildings.building,
        floor: state.floors.floor,
        buildingsPending: state.buildings.buildingsPending
    }
}

const mapDispatchToProps = {
    setSelectedFloor: setFloor,
    getBuilding: getBuilding,
}

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(ChooseFloorPage))