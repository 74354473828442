import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import LoadingIndicator from "../../common/elements/LoadingIndicator";
import FloorplanButtonGroup from "./FloorplanButtonGroup";
import FloorPlaner from "../../components/threejs/FloorPlaner";

import floorplanPlaceholder from "../../common/img/floorplanImg.png";
import ZoomInFloorplanSvgIcon from "../../common/icons/ZoomInFloorplanSvgIcon";
import ZoomOutFloorplanSvgIcon from "../../common/icons/ZoomOutFloorplanSvgIcon";
import FocusSeatFloorplanSvgIcon from "../../common/icons/FocusSeatFloorplanSvgIcon";
import ZoomResetFloorplanSvgIcon from "../../common/icons/ZoomResetFloorplanSvgIcon";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import {getFloor, getFloorPlanImage} from "../../actions/floors-actions.js";
import {matchProfilePicturesWithAssignments} from "../../common/utils/AssignmentUtils.js";
import {
    getSpaceAsDrawingObject,
    getTerminalAsDrawingObject,
    getWorkplaceAsDrawingObject
} from "../../common/utils/DrawingUtils.js";

import {
    DRAWING_REPRESENTATION_TYPE_SPACE,
    DRAWING_REPRESENTATION_TYPE_WORKPLACE,
    STORAGE_OCCUPANCY_TIME,
    TYPE_OF_USE_MEETING,
    TYPE_OF_WORKPLACE_SHARED
} from "../../common/utils/NameUtils.js";

const styles = ({
    root: {
        height: '100%',
        width: '100% ',
        boxSizing: 'border-box',
        display: 'flex',
    },
    loadingIndicator: {
        position: 'fixed',
        top: '50%',
        left: '50%',
    },
    tools: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: '-50px',
        marginTop: '18px',
        marginBottom: '36px'
    },
    button: {
        disableTouchRipple: 'true',
        '&:hover': {
            background: 'none',
        }
    }
})

function FloorplanWithWorkplaces({
                                     theme,
                                     classes,
                                     selectedWorkplace,
                                     showZoomButtons,
                                     centerInitially,
                                     disableSelection,
                                     assignments,
                                     loadFloor,
                                     getFloorplanPending,
                                     showOnlyAssignments,
                                     floor,
                                     floorPlanImages,
                                     profilePictures,
                                     getFloorPlanImage,
                                     selectedSpace,
                                     showMeetingRooms
                                 }) {
    const floorPlanerRef = useRef()
    const focusDisabled = !selectedWorkplace && !selectedSpace

    const [floorplanImage, setFloorplanImage] = useState({image: null, scaling: 1})
    const [drawingObjects, setDrawingObjects] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(false);

    const zoomFunctionRef = useRef(null);

    useEffect(() => {
        window.addEventListener(STORAGE_OCCUPANCY_TIME, () => {
            setUpdateTrigger(!updateTrigger)
        })

        return function cleanup() {
            window.removeEventListener(STORAGE_OCCUPANCY_TIME, updateObjects)
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (floor && !floor.spaces && !getFloorplanPending) {
            loadFloor(-1, floor.id)
        }
        // eslint-disable-next-line
    }, [floor]);


    //all workplaces or image scale loaded -> create threejs objects for workplaces
    useEffect(() => {
        updateObjects()
        // eslint-disable-next-line
    }, [assignments, profilePictures])

    useDidMountEffect(() => {
        updateObjects()
    }, [updateTrigger, floor])

    //(de-)activate workplace in drawingObjects
    useDidMountEffect(() => {
        setDrawingObjects(deActivateDrawingObjects(drawingObjects))
        // eslint-disable-next-line
    }, [selectedWorkplace, selectedSpace])

    function updateObjects() {
        let objects = getWorkplacesAsDrawWorkplace
        objects.push(...getTerminalsAsDrawTerminal())
        if (showMeetingRooms)
            objects.push(...getMeetingroomsAsDrawSpaces())
        deActivateDrawingObjects(objects)
        setDrawingObjects(objects)

        if (zoomFunctionRef.current)
            clearTimeout(zoomFunctionRef.current)

        if (centerInitially && objects) {
            zoomFunctionRef.current = setTimeout(() => {
                centerObject()
            }, 400)
        }
    }

    let getProfilePicturesWithAssignments = useMemo(() => {
        function getWorkplacesOfSelectedFloor() {
            let workplaces = []

            if (!floor || !floor.spaces)
                return workplaces

            for (let space of floor.spaces) {
                space.workplaces.filter(workplace => TYPE_OF_WORKPLACE_SHARED === workplace.typeOfWorkplace).forEach(workplace => workplaces.push(workplace))
            }

            return workplaces
        }

        return matchProfilePicturesWithAssignments(assignments, profilePictures, showOnlyAssignments ? assignments.map(assignment => assignment.workplace) : getWorkplacesOfSelectedFloor())
        // eslint-disable-next-line
    }, [assignments, profilePictures, floor])

    let getWorkplacesAsDrawWorkplace = useMemo(() => {
        let workplaceObjects = []
        let wps = getProfilePicturesWithAssignments

        if (!wps)
            return workplaceObjects

        for (let workplace of wps) {
            workplaceObjects.push(getWorkplaceAsDrawingObject(workplace, floorplanImage.scaling))
        }

        return workplaceObjects
        // eslint-disable-next-line
    }, [assignments, profilePictures, floorplanImage.scaling, updateTrigger, floor])

    let getMeetingroomsAsDrawSpaces = useCallback(() => {
        if (!floor || !floor.spaces)
            return []
        let meetingRoomsOfFloor = floor.spaces.filter(space => space.typeOfUse === TYPE_OF_USE_MEETING)
        let spaceObjects = []

        for (let space of meetingRoomsOfFloor) {
            if (space.outline) {
                spaceObjects.push(getSpaceAsDrawingObject(space, selectedSpace && space.id === selectedSpace.id))
            }
        }
        return spaceObjects
        // eslint-disable-next-line
    }, [assignments, profilePictures, floorplanImage.scaling, floor])

    let getTerminalsAsDrawTerminal = useCallback(() => {
        if (!floor || !floor.spaces)
            return []

        return floor.terminals.map((terminal) => getTerminalAsDrawingObject(terminal))
    }, [floor])

    const deActivateDrawingObjects = useCallback((elements) => {
        if (disableSelection || !elements || !elements.length)
            return elements

        for (let drawingObject of elements) {
            if (drawingObject.drawingRepresentationType === DRAWING_REPRESENTATION_TYPE_SPACE) {
                if (drawingObject && selectedSpace && drawingObject.uuid === selectedSpace.id) {
                    drawingObject.setActive(true)
                } else {
                    drawingObject.setActive(false)
                }
            } else if (drawingObject.drawingRepresentationType === DRAWING_REPRESENTATION_TYPE_WORKPLACE) {
                if (drawingObject && selectedWorkplace && drawingObject.workplace.id === selectedWorkplace.id) {
                    drawingObject.setActive(true)
                } else {
                    drawingObject.setActive(false)
                }
            }
        }
        return elements
        // eslint-disable-next-line
    }, [selectedWorkplace, selectedSpace])

    let centerObject = useCallback(() => {
        let position
        if (selectedWorkplace) {
            position = {
                x: selectedWorkplace.geometry.vertices[0].x,
                y: -selectedWorkplace.geometry.vertices[0].y
            }
            floorPlanerRef.current && floorPlanerRef.current.centerCameraOnObject(position)
        } else if (selectedSpace) {
            let centroid = {x: 0, y: 0};
            let points = selectedSpace.outline.vertices
            for (let i = 0; i < points.length; i++) {
                let point = points[i];
                centroid.x += point.x;
                centroid.y += point.y;
            }
            centroid.x /= points.length;
            centroid.y /= points.length;

            floorPlanerRef.current && floorPlanerRef.current.centerCameraOnObject(centroid)
        }
        // eslint-disable-next-line
    }, [selectedWorkplace, selectedSpace])

    function getFloorplanImageFromReduxStore(floorId) {
        let index = floorPlanImages.findIndex(imageObj => imageObj.id === floorId)
        if (index > -1)
            return floorPlanImages[index]
        return null
    }

    //set the foorplan and the corresponding image scale
    useEffect(() => {
        if (!floor && !selectedWorkplace)
            return

        let floorId = selectedWorkplace ? selectedWorkplace.floorId : floor.id
        let floorPlanFromRedux = getFloorplanImageFromReduxStore(floorId)

        if (floorPlanFromRedux === null && !getFloorplanPending) {
            setFloorplanImage({image: null, scaling: 1})
            getFloorPlanImage(-1, floorId)
                .then(() => {
                    floorPlanFromRedux = getFloorplanImageFromReduxStore(floorId)
                    setFloorplanAndImageScale(floorPlanFromRedux)
                })
        } else {
            setFloorplanAndImageScale(floorPlanFromRedux)
        }
        // eslint-disable-next-line
    }, [floorPlanImages, floor, selectedWorkplace])

    function setFloorplanAndImageScale(floorPlanFromRedux) {
        if (floorPlanFromRedux != null) {
            let {floorplan, imageScale} = floorPlanFromRedux
            let floorplanString = 'data:image/png;base64,' + floorplan
            setFloorplanImage({image: floorplanString, scaling: imageScale})
        }
    }

    const zoomIn = () => {
        floorPlanerRef.current.zoomIn()
    }

    function zoomOut() {
        floorPlanerRef.current.zoomOut()
    }

    function resetZoom() {
        floorPlanerRef.current.resetZoom()
    }

    const zoomButtons = [
        <Button className={classes.button} key={'button-key-zoomIn'} onClick={zoomIn}>
            <ZoomInFloorplanSvgIcon/>
        </Button>,
        <Button className={classes.button} key={'button-key-zoomOut'} onClick={zoomOut}>
            <ZoomOutFloorplanSvgIcon/>
        </Button>,
    ];
    const focusButtons = [
        <Button className={classes.button} key={'button-key-focus'} onClick={centerObject} disabled={focusDisabled}>
            <FocusSeatFloorplanSvgIcon color={focusDisabled ?
                theme.colors.palette.neutral.greyMain : theme.colors.palette.neutral.darkMain}/>
        </Button>,
        <Button className={classes.button} key={"button-key-zoom-reset"} onClick={resetZoom}>
            <ZoomResetFloorplanSvgIcon/>
        </Button>
    ];

    return (
        <div className={classes.root}>
            {getFloorplanPending
                ? <div className={classes.loadingIndicator}>
                    <LoadingIndicator/>
                </div>
                : !floorplanImage.image || !drawingObjects
                    ? <img className={classes.floorplanImage}
                           src={floorplanPlaceholder}
                           alt="floorplan"/>
                    : <FloorPlaner
                        image={floorplanImage.image}
                        imageScale={floorplanImage.scaling}
                        drawingObjects={drawingObjects}
                        ref={floorPlanerRef}
                    />
            }

            <div className={classes.tools} style={{height: showZoomButtons ? '' : '10rem'}}>
                {showZoomButtons &&
                    <FloorplanButtonGroup buttons={zoomButtons}/>}
                <FloorplanButtonGroup buttons={focusButtons}/>
            </div>
        </div>
    )
}

FloorplanWithWorkplaces.propTypes = {
    showZoomButtons: PropTypes.bool,
    centerInitially: PropTypes.bool,
    disableSelection: PropTypes.bool,
    //prevents displaying all workplaces of the corresponding floor
    showOnlyAssignments: PropTypes.bool,
    showMeetingRooms: PropTypes.bool
};

let mapStateToProps = (state) => {
    return {
        userId: state.user.person.id,
        floorPlanImages: state.floors.floorPlanImages,
        getFloorplanPending: state.floors.getFloorplanPending,
        floor: state.floors.floor,
        profilePictures: state.occupancy.profilePicturesBase64,
        assignments: state.workplace.assignments,
        selectedWorkplace: state.workplace.favoredWorkplace,
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedSpace: state.spaces.selectedSpace
    }
}

let mapDispatchToProps = {
    getFloorPlanImage: getFloorPlanImage,
    loadFloor: getFloor,
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(FloorplanWithWorkplaces))