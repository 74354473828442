import React, {useEffect, useState} from 'react';

import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

import RatingsCard from "./RatingsCard";
import RatingsModal from "./RatingsModal.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import {navigate, PATH_HOME, PATH_RATINGS} from "../../common/utils/NavigationUtils";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2.4rem'
    },
    content: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
    },
    description: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
}))

function Ratings({unratedAssignments, backLabel}) {
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [rateModalOpen, setRateModalOpen] = useState(false);

    useEffect(() => {
        if (isEmpty(unratedAssignments))
            routeToHome()
    })

    function routeToHome() {
        navigate(history, PATH_HOME)
    }

    function onRate(assignment) {
        setSelectedAssignment(assignment)
        setRateModalOpen(true)
    }

    function onFinishRating() {
        setSelectedAssignment(null)
        setRateModalOpen(false)

        if (unratedAssignments && unratedAssignments.length >= 1) {
            routeToHome()
        } else {
            navigate(history, PATH_RATINGS)
        }
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <CssBaseline/>
            <HeaderWithTitleAndBackButton title={t('ratings')} backLabel={backLabel}
                                          onNavigateBack={routeToHome}/>
            <Grid item sm={12} className={classes.container}>

                <Grid item>
                    <Typography variant={'h6'} className={classes.description}>
                        {t("ratings_description")}
                    </Typography>
                </Grid>
                {unratedAssignments && unratedAssignments.map(assignment => {
                    return <RatingsCard key={
                        assignment.person.id.toString() +
                        assignment.workplace.id.toString() +
                        assignment.timePeriod.startDate} assignment={assignment}
                                        onRate={() => onRate(assignment)}/>
                })}
            </Grid>
            {rateModalOpen && <RatingsModal open={rateModalOpen}
                                            onCloseCallback={() => setRateModalOpen(false)}
                                            submitCallback={onFinishRating}
                                            assignment={selectedAssignment}/>}
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        unratedAssignments: state.workplace.unratedAssignments,
    }
}

export default connect(mapStateToProps)(Ratings)