import * as React from "react"
import PropTypes from "prop-types";

const InfoSvgIcon = ({color, width, height, strokeWidth, ...props}) => (
    <svg width={width} height={height} stroke={color} fill={color} strokeWidth={strokeWidth}
         viewBox="0 0 16 16" {...props}>
        <path
            d="M8.5 11V6.5h-2v1h1V11H6v1h4v-1H8.5zM8 3.5c-.4 0-.8.3-.8.8s.4.7.8.7.8-.3.8-.8-.4-.7-.8-.7zM8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z"
            fill={props.fill || '#none'}
            stroke={color}
            fillRule="evenodd"
        />
    </svg>
)

InfoSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    strokeWidth: PropTypes.number,
};

InfoSvgIcon.defaultProps = {
    color: '#333',
    width: 16,
    height: 16,
    strokeWidth: 0.5,
};

export default InfoSvgIcon