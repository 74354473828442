import * as React from "react"

function AttendanceStatusMaybeSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g strokeWidth={1.5} fill="none" fillRule="evenodd">
                <g stroke={props.stroke || '#B2B2B2'} strokeLinejoin="round" transform="translate(18 18)">
                    <rect width={11.25} height={11.25} rx={5.625}/>
                    <path
                        d="M5.625 8.25v1.167m0-2V6.083c1.036 0 1.875-.746 1.875-1.666 0-.92-.84-1.667-1.875-1.667-1.036 0-1.875.746-1.875 1.667"/>
                </g>
                <g stroke={props.stroke || '#58BCCC'} transform="translate(4.875 4.5)">
                    <circle cx={4.5} cy={3} r={3}/>
                    <path d="M0 14.25v-3.38a2.62 2.62 0 012.62-2.62H4.5"/>
                    <circle cx={15.75} cy={3} r={3}/>
                    <path d="M15.75 8.25h1.875a2.625 2.625 0 012.625 2.625h0"/>
                    <circle cx={10.125} cy={9.375} r={3}/>
                    <path d="M5.625 20.625V17.25a2.625 2.625 0 012.625-2.625H12"/>
                </g>
            </g>
        </svg>
    )
}

export default AttendanceStatusMaybeSvgIcon