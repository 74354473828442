import * as React from 'react'
import PropTypes from 'prop-types'

function XSvgIcon({color, className, width, height, ...props}) {
    return (
        <svg className={className} width={width || '100%'} height={height || '100%'} viewBox="0 0 32 32" {...props}>
            <g fill="none" fillRule="evenodd" {...props}>
                <path
                    d="M22.894 10.166L17.061 16l5.833 5.834-1.06 1.06L16 17.061l-5.834 5.833-1.06-1.06L14.939 16l-5.833-5.834 1.06-1.06L16 14.939l5.834-5.833 1.06 1.06z"
                    fill={color}
                />
            </g>
        </svg>
    )
}

XSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

XSvgIcon.defaultProps = {
    fill: '#333',
    color: '#333',
};


export default XSvgIcon
