import * as React from "react"
import PropTypes from 'prop-types'

function BuildingSvgIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 40 40"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <g
                {...props}
                strokeWidth={1.5}
                stroke={props.color}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path d="M33.578 33.606h-27V19.067h12.461V6.606h14.539z"/>
                <path
                    d="M13.167 32.782V24.09h5.625v8.692m4.661-20.432h1.125v1.175h-1.125zm0 5.87h1.125v1.175h-1.125zm-.161 5.87h1.125v1.175h-1.125zm4.661-11.74h1.125v1.175h-1.125zm0 5.87h1.125v1.175h-1.125zm-.161 5.87h1.125v1.175h-1.125z"/>
            </g>
        </svg>
    )
}

BuildingSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
}

BuildingSvgIcon.defaultProps = {
    width: 24,
    height: 24,
    color: '#333',
}


export default BuildingSvgIcon
