import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import Divider from "@material-ui/core/Divider";
import {useHistory} from "react-router-dom";
import {navigate, PATH_USER_STATISTICS} from "../../common/utils/NavigationUtils.js";
import PropTypes from "prop-types";
import clsx from "clsx";
import {minutesToHours} from "../../common/utils/TimeUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        gap: '.9rem',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    content: {
        gap: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        border: '1px solid ' + theme.colors.palette.neutral.greyMedium,
        borderRadius: '.9rem',
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
        boxSizing: 'border-box',
        padding: '1.8rem',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)'
    },
    info: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'left'
    },
    title: {
        alignSelf: 'flex-start'
    }
}))

function StatisticsPanel({statistics, asCard, withTitle}) {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={classes.root} onClick={() => navigate(history, PATH_USER_STATISTICS)}>
            {withTitle && <Typography variant={'h6'} className={classes.title}>{t('statistics')}</Typography>}

            <div className={clsx(classes.content, asCard ? classes.card : '')}>
                <div className={classes.info}>
                    <Typography variant={'body1'}>{t('booked_seats')}</Typography>
                    <Typography variant={'h5'}>{statistics && statistics.company ? statistics.company : 0}</Typography>
                </div>
                <Divider/>
                <div className={classes.info}>
                    <Typography variant={'body1'}>{t('days_homeoffice')}</Typography>
                    <Typography
                        variant={'h5'}>{statistics && statistics.homeoffice ? statistics.homeoffice : 0}</Typography>
                </div>
                <Divider/>
                <div className={classes.info}>
                    <Typography variant={'body1'}>{t('days_out_of_office')}</Typography>
                    <Typography
                        variant={'h5'}>{statistics && statistics.out_of_office ? statistics.out_of_office : 0}</Typography>
                </div>
                <Divider/>
                <div className={classes.info}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant={'body1'}>{t('meetings_count')}</Typography>
                        <Typography variant={'caption'}>{t('meetings_time_caption')}</Typography>
                    </div>
                    <Typography variant={'h5'}>
                        {statistics && statistics.offlineMeetingHours ? minutesToHours(statistics.offlineMeetingHours) : 0}/
                        {statistics && statistics.onlineMeetingHours ? minutesToHours(statistics.onlineMeetingHours) : 0}
                    </Typography>
                </div>
            </div>
        </div>
    )

}


StatisticsPanel.propTypes = {
    asCard: PropTypes.bool,
    withTitle: PropTypes.bool,
    statistics: PropTypes.any.isRequired
}


export default StatisticsPanel