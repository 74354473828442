import React from 'react';

import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme.js";
import GoconutSvgLogo from "../common/icons/GoconutSvgLogo.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: theme.colors.palette.neutral.white,
    }
}));

function LandscapeBlockScreen() {

    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyle(theme);

    return (
        <div className={classes.root}>
            <GoconutSvgLogo/>
            <Typography variant={'h5'} className={classes.text}>
                {t('general.mobile.landscape')}
            </Typography>
        </div>
    );
}

export default LandscapeBlockScreen;