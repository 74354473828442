import * as THREE from 'three'
import {DRAWING_REPRESENTATION_TYPE_SPACE} from "../../../common/utils/NameUtils";
import {
    createCircleWithImageActiveMarker,
    createEdge,
    createFaceGeometry,
    createVertex,
    drawingColor,
    drawingSize,
    threeMaterial
} from "../../drawingTool/drawing/drawConstants.js";
import {getFaceCentroidMatrix} from "../../../common/utils/DrawingUtils.js";
import MeetingImg from '../../../common/img/Group@2x.png'

function SpaceObject() {
    THREE.Mesh.apply(this, arguments)
    this.vertexGroup = new THREE.Group()
    this.vertexGroup.name = 'vertices'
    this.edgeGroup = new THREE.Group()
    this.edgeGroup.name = 'edges'
    this.face = null
    this.space = null

    this.active = false

    this.add(this.vertexGroup)
    this.add(this.edgeGroup)

    const edgeThickness = drawingSize.edgeThickness
    const material = threeMaterial.space
    const innerMaterial = threeMaterial.spaceHandler
    const transparentMaterial = threeMaterial.transparent
    const radius = drawingSize.workplace

    this.reInit = function (vertices) {
        vertices.forEach(vertex => {
            const vertexMesh = createVertex(transparentMaterial, transparentMaterial, 1, edgeThickness, edgeThickness)
            vertexMesh.children.forEach(vertex => vertex.material = transparentMaterial)
            vertexMesh.position.set(vertex[0], 0.05, vertex[1])

            this.vertexGroup.add(vertexMesh)
        })

        for (let i = 0; i < this.vertexGroup.children.length; i++) {
            const previousVertex = this.vertexGroup.children[i]
            const nextVertex = this.vertexGroup.children[(i + 1) % this.vertexGroup.children.length]
            const edgeMesh = createEdge(previousVertex, nextVertex, transparentMaterial, edgeThickness)

            this.edgeGroup.add(edgeMesh)
        }

        const faceGeometry = createFaceGeometry(this.vertexGroup.children)
        const faceMesh = new THREE.Mesh(faceGeometry, innerMaterial)

        let centroidMatrix = getFaceCentroidMatrix(faceGeometry, faceMesh)
        const markerMesh = createCircleWithImageActiveMarker(radius, MeetingImg, null, false, null, drawingColor.space)
        markerMesh.position.set(centroidMatrix.x, 0, centroidMatrix.z)
        markerMesh.name = 'markerMesh'

        this.add(markerMesh)
        faceMesh.name = 'face'
        this.face = faceMesh
        this.add(this.face)
    }

    this.setActive = function (active) {
        if (active !== this.active) {
            this.active = active
            this.vertexGroup.children.forEach(vertex => vertex.material = active ? material : transparentMaterial)
            this.edgeGroup.children.forEach(edge => edge.material = active ? material : transparentMaterial)

            let markerIndex = this.children.findIndex(mesh => mesh.name === 'markerMesh')
            let markerMesh = this.children[markerIndex]
            let markerMeshToMove = markerMesh.children.find(mesh => mesh.name === 'circleMesh')

            const activeMovementY = 1.5 * radius

            if (active) {
                if (markerMeshToMove)
                    markerMeshToMove.position.setY(markerMeshToMove.position.y + activeMovementY)
            } else {
                if (markerMeshToMove)
                    markerMeshToMove.position.setY(markerMeshToMove.position.y - activeMovementY)
            }
        }
    }

    this.initialize = function (space) {
        this.uuid = space.id
        this.space = space
        this.drawingRepresentationType = DRAWING_REPRESENTATION_TYPE_SPACE

        const vertices = space.outline.vertices
        const flippedVertices = []
        vertices.sort((a, b) => a.index - b.index)
            .forEach(vertex => flippedVertices.push([vertex.x, -vertex.y]))

        this.reInit(flippedVertices)
    }
}

SpaceObject.prototype = Object.create(THREE.Mesh.prototype)
SpaceObject.prototype.constructor = SpaceObject
export {SpaceObject}