export const CREATE_WORKPLACE_ASSIGNMENT = 'CREATE_WORKPLACE_ASSIGNMENT'
export const CREATE_MEETING_ASSIGNMENT = 'CREATE_MEETING_ASSIGNMENT'
export const CREATE_RESOURCE_ASSIGNMENT = 'CREATE_RESOURCE_ASSIGNMENT'
export const WORKPLACE_RATING = 'WORKPLACE_RATING'
export const SET_ATTENDANCE = 'SET_ATTENDANCE'
export const SET_HOMEZONE = 'SET_HOMEZONE'
export const THIRD_PARTY_INTEGRATION = 'THIRD_PARTY_INTEGRATION'
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE'
export const SET_WORKING_HOURS = 'SET_WORKING_HOURS'

