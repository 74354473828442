import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from "prop-types";

import moment from "moment";
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import TimeperiodSelectionWithClocksAndTimeline
    from "../../common/elements/TimeperiodSelectionWithClocksAndTimeline.js";
import {getAssignmentsAsIntervals} from "../../common/utils/AssignmentUtils.js";

const useStyle = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem'
    },
}));

function EditAssignmentsTimePeriod({assignment, allAssignments, onEditError, onChange}) {

    const theme = useTheme();
    const classes = useStyle(theme);
    const [timePeriod, setTimePeriod] = useState({
        startDate: assignment.timePeriod.startDate,
        endDate: assignment.timePeriod.endDate
    });

    useEffect(() => {
        if (typeof onChange === 'function')
            onChange(timePeriod)
    }, [timePeriod, onChange]);

    let blockingIntervals = useMemo(() => {
        return getAssignmentsAsIntervals(allAssignments
            .filter(assign => moment(assign.timePeriod.startDate).dayOfYear() === moment(assignment.timePeriod.startDate).dayOfYear())
            .filter(assign => assign.id !== assignment.id))
    }, [allAssignments, assignment]);

    return (
        <div className={classes.content}>
            <TimeperiodSelectionWithClocksAndTimeline
                startTime={timePeriod.startDate}
                endTime={timePeriod.endDate}
                setStartTime={(date) => setTimePeriod({startDate: date, endDate: timePeriod.endDate})}
                setEndTime={(date) => setTimePeriod({startDate: timePeriod.startDate, endDate: date})}
                disabledIntervals={blockingIntervals}
                errorCallback={onEditError}
            />
        </div>
    );
}

EditAssignmentsTimePeriod.propTypes = {
    allAssignments: PropTypes.array,
    onEditError: PropTypes.func,
    onChange: PropTypes.func,
};

EditAssignmentsTimePeriod.defaultProps = {
    allAssignments: [],
    onEditError: console.log
};

export default EditAssignmentsTimePeriod;