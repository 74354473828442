import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

function ModalHandler({children, onClose, preventHistoryGoBack, className, ...props}) {
    const history = useHistory();

    window.addEventListener("popstate", onPopState);

    useEffect(() => {
        if (children.props.open) {
            history.push(window.location)
        }
        return () => {
            window.removeEventListener('popstate', onPopState)
        }
        // eslint-disable-next-line
    }, [children.props.open]);

    function onPopState() {
        if (children.props.open) {
            onClose()
        }
    }

    function onCloseModal() {
        if (!preventHistoryGoBack)
            history.goBack()
        onClose()
    }

    return (
        <div className={className}>
            {React.cloneElement(children, {onClose: onCloseModal, ...props})}
        </div>
    )
}

ModalHandler.propTypes = {
    onClose: PropTypes.func.isRequired,
    preventHistoryGoBack: PropTypes.bool,
    className: PropTypes.object,
}

export default ModalHandler