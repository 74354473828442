import * as React from "react"
import PropTypes from 'prop-types'

const GoconutSvgLogo = ({color, width, iconColor, ...props}) => (
    <svg viewBox="0 0 200 37.51" width={width}{...props}>
        <defs>
            <style>{`.cls-1{fill:${iconColor}}.cls-2{fill:${color}`}</style>
        </defs>
        <path
            className="cls-1"
            d="M40.66 25.38a17.34 17.34 0 0 1-10.53 3.09 31.88 31.88 0 0 1-13.31-3.19c-5.74-2.69-10.35-6.59-13-11A15.83 15.83 0 0 1 1.46 7 .21.21 0 0 0 1 7c-3.15 12 1.15 24.13 10.93 28.71a19.18 19.18 0 0 0 8.17 1.8c8 0 15.71-4.51 20.86-11.84a.21.21 0 0 0-.3-.29Z"
        />
        <path
            className="cls-1"
            d="M19.44 19.66C27.87 23.6 36.49 23 38.7 18.27S35.85 6.54 27.42 2.59 10.37-.73 8.17 4 11 15.71 19.44 19.66Z"
        />
        <g id="HJIUYI.tif">
            <path
                className="cls-2"
                d="M57 26a5.07 5.07 0 0 1-.38-8.33 7 7 0 0 1-.65-6.92 7.28 7.28 0 0 1 7.77-4.13 74.36 74.36 0 0 0 8.89.38h.57v3.51h-3a17.94 17.94 0 0 1 .46 1.86 6.82 6.82 0 0 1-6.57 8.08 17.35 17.35 0 0 1-4.27-.54c-.47-.09-.69-.06-.91.36a1.87 1.87 0 0 0 1.49 2.94c1.68.14 3.37 0 5.06.11a15 15 0 0 1 3.41.44 4.45 4.45 0 0 1 3.26 3.74 6.28 6.28 0 0 1-4.85 7.61 14 14 0 0 1-8.65-.4A5.24 5.24 0 0 1 55 29.5c0-1.5.43-2.32 2-3.5Zm6.54.68a22.29 22.29 0 0 0-2.38.13 2.23 2.23 0 0 0-2 1.86 2.54 2.54 0 0 0 1 2.74 7.34 7.34 0 0 0 6.79.15 3 3 0 0 0 1.19-3 2.31 2.31 0 0 0-2.31-1.73c-.76-.15-1.5-.15-2.34-.19Zm-.38-9.41a3.48 3.48 0 0 0 3.61-3.65 3.67 3.67 0 1 0-7.33 0 3.5 3.5 0 0 0 3.67 3.61ZM85 27.18c-6 0-10.19-4.33-10.19-10.35a10.2 10.2 0 1 1 20.39 0C95.21 22.9 91 27.2 85 27.18Zm0-16.81c-3.81 0-6.4 3.22-5.9 7.36.3 2.44 1.39 4.37 3.78 5.26a5.4 5.4 0 0 0 6-1.07c2.22-2.15 2.48-4.84 1.6-7.65a5.43 5.43 0 0 0-5.41-3.9ZM139.9 16.88c0 6.05-4.25 10.32-10.2 10.3s-10.15-4.3-10.14-10.36 4.26-10.32 10.2-10.3 10.16 4.33 10.14 10.36Zm-4.27-.23v-.8a5.76 5.76 0 0 0-5.27-5.46 5.67 5.67 0 0 0-6.36 4.67 9.52 9.52 0 0 0-.15 2.82 5.89 5.89 0 0 0 7.56 5.28c2.59-.75 4.23-3.16 4.22-6.51ZM116.87 20.21a8.22 8.22 0 0 1-6.56 6.73c-8 1.56-13.52-4.88-11.79-12.74 1.7-7.68 9.54-9.14 14.41-6.47a7.71 7.71 0 0 1 3.93 5.74h-3.69a.65.65 0 0 1-.45-.33 4.87 4.87 0 0 0-5.32-2.7A5.07 5.07 0 0 0 103 14a7.38 7.38 0 0 0 .63 7c2.59 3.89 7.88 2.68 9-.28a.79.79 0 0 1 .54-.44c1.28-.09 2.49-.07 3.7-.07Z"
            />
        </g>
        <path
            className="cls-2"
            d="M143.73 7.25h3.86V9.4a7.3 7.3 0 0 1 2.64-2 8.71 8.71 0 0 1 3.69-.73 7.88 7.88 0 0 1 3.54.71 6.1 6.1 0 0 1 2.24 1.83 8.27 8.27 0 0 1 1.22 2.38 12.42 12.42 0 0 1 .51 2.38c.07.76.11 1.36.11 1.81v10.84h-4.37v-9.33a17.15 17.15 0 0 0-.14-2 8 8 0 0 0-.57-2.14 4.1 4.1 0 0 0-1.36-1.7 4 4 0 0 0-2.45-.68 5 5 0 0 0-2.15.5 3.84 3.84 0 0 0-1.72 1.73 8 8 0 0 0-.67 3.64v10h-4.38ZM169.93 26.47a6.06 6.06 0 0 1-2.23-1.83 8 8 0 0 1-1.22-2.38 11.06 11.06 0 0 1-.51-2.38c-.08-.76-.12-1.36-.12-1.81V7.25h4.38v9.33a17 17 0 0 0 .13 2 8 8 0 0 0 .57 2.14 4.1 4.1 0 0 0 1.36 1.7 4.06 4.06 0 0 0 2.46.68 4.94 4.94 0 0 0 2.15-.5 3.87 3.87 0 0 0 1.71-1.77 8 8 0 0 0 .67-3.64V7.25h4.35v19.37h-3.82v-2.15a7.42 7.42 0 0 1-2.65 2 8.71 8.71 0 0 1-3.69.73 7.88 7.88 0 0 1-3.54-.73ZM196.25 26.94a8.59 8.59 0 0 1-3.3-.69 4.48 4.48 0 0 1-2.2-2 5.63 5.63 0 0 1-.7-2.56V10.64h-3.55V7.25h3.5V1.87h4.31v5.38H200v3.39h-5.68v9.82a2.88 2.88 0 0 0 .37 1.44 2.49 2.49 0 0 0 2.06 1.2A13.46 13.46 0 0 0 200 23v3.62a17.93 17.93 0 0 1-3.75.32Z"
        />
    </svg>
)

GoconutSvgLogo.propTypes = {
    color: PropTypes.string,
    iconColor: PropTypes.string,
    width: PropTypes.number,
};

GoconutSvgLogo.defaultProps = {
    color: '#fff',
    iconColor: '#f1886d',
    width: 124,
};


export default GoconutSvgLogo