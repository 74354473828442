import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";

import {makeStyles, useTheme} from "@material-ui/core";
import {connect} from "react-redux";
import {
    getThirdPartyLoginUrls,
    getUserAuthenticatedMap,
    thirdPartyUserLogout
} from "../../actions/thirdPartyAuthentication-actions.js";
import {
    navigate,
    PATH_PROFILE_INTEGRATIONS_EXCHANGE,
    PATH_PROFILE_SETTINGS
} from "../../common/utils/NavigationUtils.js";
import {useTranslation} from "react-i18next";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import GoogleSvgIcon from "../../common/icons/GoogleSvgIcon.js";
import MicrosoftSvgIcon from "../../common/icons/MicrosoftSvgIcon.js";
import {EXCHANGE, GOOGLE, MICROSOFT, SLACK} from "../../common/utils/IntegrationsUtils.js";
import SlackSvgIcon from "../../common/icons/SlackSvgIcon.js";
import Typography from "@material-ui/core/Typography";
import ExchangeSvgIcon from "../../common/icons/ExchangeSvgIcon.js";
import CardWrapper from "../../common/elements/CardWrapper.js";
import LoadingIndicator from "../../common/elements/LoadingIndicator.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        maxWidth: theme.gridSizes.maxWidth,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
    },
    content: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '2.4rem',
        flex: 1
    },
    integrationsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: theme.outerGap,
    },
    integrationsRow: {
        flex: 1,
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: theme.outerGap,
    },
    thirdPartyCard: {
        padding: theme.innerGap,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.innerGap,
    }
}));

function IntegrationsPage({
                              getThirdPartyLoginUrls,
                              getUserAuthenticatedMap,
                              thirdPartyUserLogout,
                              authenticatedMap,
                              loginUrls,
                              logoutPending,
                              ...props
                          }) {

    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme)
    const {t} = useTranslation()

    useEffect(() => {
        getUserAuthenticatedMap()
        getThirdPartyLoginUrls()
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton title={t("integrations")}
                                          backLabel={t("settings")}
                                          onNavigateBack={() => navigate(history, PATH_PROFILE_SETTINGS)}/>
            <div className={classes.content}>
                <Typography variant={"body1"} align={'left'}>
                    {t("integrations.general.text")}
                </Typography>

                <div className={classes.integrationsContainer}>
                    <div className={classes.integrationsRow}>

                        <CardWrapper isFlex className={classes.thirdPartyCard}
                                     onClick={() => {
                                         authenticatedMap[GOOGLE]
                                             ? thirdPartyUserLogout(GOOGLE)
                                             : window.open(loginUrls[GOOGLE], '_self')
                                     }}>
                            <>
                                <GoogleSvgIcon/>
                                <Typography style={{color: authenticatedMap[GOOGLE] ? 'red' : null}}>
                                    {t(`integrations.google.${!authenticatedMap[GOOGLE] ? 'signIn' : 'signOut'}`)}
                                </Typography>
                            </>
                        </CardWrapper>
                        <CardWrapper isFlex className={classes.thirdPartyCard}
                                     onClick={() => {
                                         authenticatedMap[MICROSOFT]
                                             ? thirdPartyUserLogout(MICROSOFT)
                                             : window.open(loginUrls[MICROSOFT], '_self')
                                     }}>
                            <>
                                <MicrosoftSvgIcon/>
                                <Typography style={{color: authenticatedMap[MICROSOFT] ? 'red' : null}}>
                                    {t(`integrations.microsoft.${!authenticatedMap[MICROSOFT] ? 'signIn' : 'signOut'}`)}
                                </Typography>
                            </>
                        </CardWrapper>
                    </div>

                    <div className={classes.integrationsRow}>

                        <CardWrapper isFlex className={classes.thirdPartyCard}
                                     onClick={() => {
                                         authenticatedMap[EXCHANGE]
                                             ? thirdPartyUserLogout(EXCHANGE)
                                             : navigate(history, PATH_PROFILE_INTEGRATIONS_EXCHANGE)
                                     }}>
                            <>
                                <ExchangeSvgIcon/>
                                {
                                    !logoutPending ?
                                        <Typography style={{color: authenticatedMap[EXCHANGE] ? 'red' : null}}>
                                            {t(`integrations.exchange.${!authenticatedMap[EXCHANGE] ? 'signIn' : 'signOut'}`)}
                                        </Typography> :
                                        <LoadingIndicator/>
                                }
                            </>
                        </CardWrapper>
                        <CardWrapper isFlex className={classes.thirdPartyCard}
                                     onClick={() => {
                                         authenticatedMap[SLACK]
                                             ? thirdPartyUserLogout(SLACK)
                                             : window.open(loginUrls[SLACK], '_self')
                                     }}>
                            <>
                                <SlackSvgIcon/>
                                <Typography style={{color: authenticatedMap[SLACK] ? 'red' : null}}>
                                    {t(`integrations.slack.${!authenticatedMap[SLACK] ? 'signIn' : 'signOut'}`)}
                                </Typography>
                            </>
                        </CardWrapper>
                    </div>
                </div>

                <div>
                    <Typography variant={"h5"} align={'left'}>
                        {t("integrations.general.calendar.headline")}
                    </Typography>
                    <Typography variant={"body1"} align={'left'}>
                        {t("integrations.general.calendar.text")}
                    </Typography>
                </div>

                <div>
                    <Typography variant={"h5"} align={'left'}>
                        {t("integrations.general.status.headline")}
                    </Typography>
                    <Typography variant={"body1"} align={'left'}>
                        {t("integrations.general.status.text")}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loginUrls: state.thirdPartyAuthentication.loginUrls,
        logoutPending: state.thirdPartyAuthentication.logoutPending,
        authenticatedMap: state.thirdPartyAuthentication.authenticatedMap,
    }
}

const mapDispatchToProps = {
    getThirdPartyLoginUrls: getThirdPartyLoginUrls,
    getUserAuthenticatedMap: getUserAuthenticatedMap,
    thirdPartyUserLogout: thirdPartyUserLogout,
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsPage)