import * as React from "react"

function ChooseSeatSvgIcon(props) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path fill={props.fill || '#none'}
                      d="M29 36a9.736 9.736 0 00-2.114-3.153A9.715 9.715 0 0020 30a9.715 9.715 0 00-6.886 2.847A9.736 9.736 0 0011 36m9-1.5v-9.057M7.5 22v-8m25 8v-8M9 19h22v3a3 3 0 01-3 3H12a3 3 0 01-3-3v-3h0zM11 19h18V7a3 3 0 00-3-3H14a3 3 0 00-3 3v12h0z"/>
            </g>
        </svg>
    )
}

export default ChooseSeatSvgIcon