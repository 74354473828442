import React from "react";

import {compose} from "redux";
import {isEqual} from "lodash";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import SearchBar from "../../common/elements/Searchbar";
import SelectionList from "../../common/elements/SelectionList";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy";

import {selectFavoriteWorkplace} from "../../actions/workplaceAssignment-action";

import {TYPE_OF_WORKPLACE_SHARED} from "../../common/utils/NameUtils";
import {filterNamesBySearchString} from "../../common/utils/FilterUtlis";

const styles = ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    searchContainer: {
        marginBottom: '1rem',
    }
})


class WorkplaceSelectionViaList extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            searchString: ''
        }
    }

    setFavoriteWorkplace = (workplace) => {
        this.props.selectFavoriteWorkplace(workplace)
    }

    setSearchString = (value) => {
        this.setState({searchString: value})
    }

    getFilteredWorkplacesWithIcons = () => {
        const relevantWorkplaces = this.getFilteredWorkplaces()
        const relevantWorkplacesWithAssignments = this.getWorkplacesWithAssignments(relevantWorkplaces)
        let workplacesWithIcons = []

        for (let item of relevantWorkplacesWithAssignments) {
            let icon = <AvatarWithOccupancy assignments={item.assignments}
                                            showOccupancy
                                            workplace={item.workplace}
                                            size={42}/>
            workplacesWithIcons.push({element: item.workplace, icon: icon})
        }
        return workplacesWithIcons
    }

    getFilteredWorkplaces = () => {
        let workplaces = []
        if (!this.props.floor)
            return workplaces
        for (let space of this.props.floor.spaces) {
            space.workplaces.filter(workplace => TYPE_OF_WORKPLACE_SHARED === workplace.typeOfWorkplace)
                .forEach(workplace => workplaces.push(workplace))
        }
        return filterNamesBySearchString(workplaces, this.state.searchString)
    }

    getWorkplacesWithAssignments = (workplaces) => {
        let workplacesWithAssignments = []
        for (let workplace of workplaces) {
            let relevantAssignments = this.props.assignments.filter(element => element.workplace && isEqual(element.workplace.id, workplace.id))

            if (relevantAssignments.length) {
                let assignmentsForWorkplace = []
                for (let assignment of relevantAssignments) {
                    assignmentsForWorkplace.push(assignment)
                    workplace.seatAvailable = false
                }

                workplacesWithAssignments.push({workplace: workplace, assignments: assignmentsForWorkplace})
            } else {
                workplace.seatAvailable = true
                workplacesWithAssignments.push({workplace: workplace, assignments: []})
            }
        }
        return workplacesWithAssignments
    }

    render() {
        const {classes, t, selectedWorkplace} = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.searchContainer}>
                    <SearchBar placeholder={t('search_workplace')}
                               searchString={this.state.searchString}
                               setSearchString={this.setSearchString}
                    />
                </div>
                <div>
                    {this.getFilteredWorkplaces().length
                        ? <SelectionList options={this.getFilteredWorkplacesWithIcons()}
                                         onSelect={this.setFavoriteWorkplace}
                                         selected={selectedWorkplace}/>
                        : <Typography>{t('no_workplace_left')}</Typography>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedWorkplace: state.workplace.favoredWorkplace,
        building: state.buildings.building,
        floor: state.floors.floor,
        assignments: state.workplace.assignments,
        profilePictures: state.occupancy.profilePicturesBase64,
    }
};

const mapDispatchToProps = {
    selectFavoriteWorkplace: selectFavoriteWorkplace
}

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(WorkplaceSelectionViaList))