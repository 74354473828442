import React from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";
import moment from "moment";
import {useTranslation} from "react-i18next";

import Card from '@material-ui/core/Card';
import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import OptionsSvgIcon from "../../common/icons/OptionsSvgIcon.js";

import {
    getAssignmentColor,
    getAssignmentsLocationAsString,
    getBackgroundImg,
    getTypeOfBookingIcon,
} from "../../common/utils/AssignmentUtils.js";
import {getTimePeriodAsString} from "../../common/utils/TimeUtils.js";
import {
    TYPE_OF_BOOKING_MEETING_ROOM,
    TYPE_OF_BOOKING_RESOURCE,
    TYPE_OF_BOOKING_VACATION
} from "../../common/utils/NameUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '18rem',
        padding: '1.8rem',
        borderRadius: '1.2rem',
        border: 'solid 1px ' + theme.colors.palette.neutral.greyMedium,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
        boxSizing: 'border-box',
        backgroundSize: '100% 100%',
        cursor: 'pointer'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%'
    },
    leftContent: {
        alignItems: 'flex-start',
        paddingRight: '1.2rem'
    },
    rightContent: {
        borderLeft: '1px dashed ' + theme.colors.palette.neutral.greyMedium,
        alignItems: 'center',
        paddingLeft: '1.8rem'
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'left'
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        width: '2.4rem',
        height: '2. 4rem',
        alignSelf: 'center'
    },
    dateTimeText: {
        fontSize: '16px',
    }
}))

function AssignmentCard({assignment, onSelectAssignment, active, userId}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);

    return (
        <Card className={clsx(classes.root)}
              key={assignment.id}
              style={{
                  backgroundImage: active
                      ? 'url(' + getBackgroundImg() + ')'
                      : '',
                  backgroundSize: '200% 200%',
                  backgroundPosition: "center",
              }}
              variant="outlined"
              onClick={onSelectAssignment}>
            <div className={classes.container}>
                <div className={clsx(classes.leftContent, classes.vertical)}>
                    <div className={classes.vertical}>
                        <Typography variant={'body1'} className={classes.dateTimeText} align={'left'}
                                    style={{color: getAssignmentColor(assignment, theme)}}
                        >
                            {assignment && moment(assignment.timePeriod.startDate).format("dd, DD.MM.YYYY")}
                        </Typography>

                        <Typography variant={'body1'} className={classes.dateTimeText} align={'left'}
                                    style={{color: getAssignmentColor(assignment, theme)}}
                        >
                            {
                                assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION
                                    ? assignment && moment(assignment.timePeriod.endDate).format("dd, DD.MM.YYYY")
                                    : getTimePeriodAsString(assignment.timePeriod)
                            }
                        </Typography>

                    </div>
                    <div className={classes.section}>
                        <Typography variant={'h3'}>
                            {assignment
                                ? assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION
                                    ? t('vacation')
                                    : assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_MEETING_ROOM
                                        ? assignment.title
                                        : assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_RESOURCE
                                            ? assignment.resource.name
                                            : assignment.workplace.name
                                : null}
                        </Typography>

                        <Typography variant={'caption'}>
                            {getAssignmentsLocationAsString(assignment, t)}
                        </Typography>
                    </div>
                </div>

                <div className={clsx(classes.rightContent, classes.vertical)}>
                    <div className={classes.vertical}>
                        {getTypeOfBookingIcon(assignment, userId)}
                    </div>
                    <OptionsSvgIcon className={classes.icon}/>
                </div>

            </div>
        </Card>
    )
}

AssignmentCard.propTypes = {
    assignment: PropTypes.object.isRequired,
    onSelectAssignment: PropTypes.func.isRequired,
    active: PropTypes.bool,
    userId: PropTypes.number
}

export default AssignmentCard
