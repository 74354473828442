import React, {useEffect, useState} from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {Select, Typography, withStyles} from "@material-ui/core";

import LoadingIndicator from "../../common/elements/LoadingIndicator";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import {getBuildings} from "../../actions/buildings-actions";
import {getHomezone, setHomezone} from "../../actions/user-action";
import {PATH_PROFILE_SETTINGS} from "../../common/utils/NavigationUtils.js";


const styles = theme => ({
    root: {
        flex: 1,
        minHeight: '100%',
        display: 'flex',
        padding: theme.paddingContentContainer.padding,
        '& .MuiCardContent-root': {
            margin: '0 auto',
            padding: '10px 14px',
            textAlign: 'right'
        },
        '& .MuiDivider-root': {
            width: '100%',
            marginBottom: '24px'
        },
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.colors.palette.neutral.white,
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
        },
        '& .MuiAvatar-root ': {
            fontWeight: '500'
        },
        '& .MuiSelect-select': {
            "&:focus": {
                borderRadius: '12px',
                backgroundColor: theme.colors.palette.neutral.white,
            }
        },
        '& .MuiFormHelperText-contained': {
            color: theme.colors.palette.corporate.redMain,
            marginLeft: 0
        }
    },
    header: {
        maxWidth: theme.gridSizes.maxWidth,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        alignItems: 'start',
    },
    container: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: theme.outerGap
    },
    containerFormControl: {
        gap: theme.innerGap
    },
    content: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '52px',
        alignItems: 'start'
    },
    description: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    label: {
        textAlign: 'start',
        width: '100%'
    },
    formControl: {
        minWidth: '100%',
        '& .MuiInputLabel-outlined': {
            transform: 'translate(24px, 14px) scale(1)',
        },
        '& .MuiPopover-paper': {
            borderRadius: '12px',
        },
        '& .MuiInputBase-input': {
            textAlign: 'left',
            verticalAlign: 'middle',
            display: 'flex',
        },

        '& .MuiDivider-root': {
            width: '100%',
        },

    },
    dropdownStyle: {
        borderRadius: '12px',
        maxWidth: '800px',
    },
    selectField: {
        height: '56px',
    },
    menuItem: {
        color: theme.colors.palette.neutral.darkMain,
        justifyContent: 'left',
        paddingLeft: '24px',
        paddingRight: '24px',
        verticalAlign: 'middle',
        display: 'flex',
        height: '56px',
        width: '100%',
    },

})

function HomezoneComponent({
                               classes,
                               t,
                               buildings,
                               getBuildings,
                               history,
                               userId,
                               homeZone,
                               setHomezone,
                               getHomezone,
                               buildingsPending
                           }) {
    const [floor, setFloor] = useState(homeZone ? homeZone.floor : null)
    const [buildingState, setBuildingState] = useState(homeZone ? homeZone.building : null)

    useEffect(() => {
        if (userId && !homeZone) {
            getHomezone(userId)
        }
    }, [getHomezone, homeZone, userId])

    useEffect(() => {
        if (!buildings.length) {
            getBuildings()
        }
    }, [getBuildings, buildings.length])

    useEffect(() => {
        if (homeZone) {
            setFloor(homeZone.floor)
            setBuildingState(homeZone.building)
        }
    }, [homeZone])

    const routeToAccount = () => {
        history.push(PATH_PROFILE_SETTINGS);
    }

    function handleChangeBuilding(event) {
        setBuildingState(event.target.value);
        setFloor("");
    }

    function handleChangeFloor(event) {
        setFloor(event.target.value)
        setHomezone(userId, event.target.value)
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item sm={12} className={classes.container}>
                <Grid item sm={12} className={classes.header}>
                    <HeaderWithTitleAndBackButton backLabel={t('settings')}
                                                  title={t('homezone')}
                                                  onNavigateBack={routeToAccount}/>
                    <Grid item>
                        <Typography variant={'h6'} className={classes.description}>
                            {t("homezone_info")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container className={classes.containerFormControl}>
                    <Typography variant='body1' className={classes.label}>{t('homezone_building')}</Typography>
                    {!buildingsPending ?
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                className={classes.selectField}
                                value={buildingState}
                                onChange={handleChangeBuilding}
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                MenuProps={{classes: {paper: classes.dropdownStyle}}}
                                renderValue={value =>
                                    <MenuItem className={classes.menuItem} key={value.id} value={value}>
                                        <Typography variant={'h5'}>
                                            {value.name}
                                        </Typography>
                                    </MenuItem>
                                }
                            >
                                {buildings.map((option) =>
                                    <MenuItem className={classes.menuItem} key={option.id} value={option}>
                                        <Typography variant={'h5'}>
                                            {option.name}
                                        </Typography>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl> : <div style={{margin: '0 auto'}}><LoadingIndicator/></div>}
                </Grid>
                {
                    !buildingsPending &&
                    <Grid item container className={classes.containerFormControl}>
                        <Typography variant='body1' className={classes.label}>{t('homezone_floor')}</Typography>
                        {buildings.length !== 0 ?
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    className={classes.selectField}
                                    margin="dense"
                                    variant="outlined"
                                    value={floor ? floor : ''}
                                    onChange={handleChangeFloor}
                                    fullWidth
                                    MenuProps={{classes: {paper: classes.dropdownStyle}}}
                                    renderValue={value =>
                                        <MenuItem className={classes.menuItem} key={value.id} value={value}>
                                            <Typography variant={'h5'}>
                                                {value.name}
                                            </Typography>
                                        </MenuItem>
                                    }
                                >
                                    {buildingState && buildings.find((building) => building.id === buildingState.id)
                                        .floors?.map((option) => (
                                            <MenuItem className={classes.menuItem} key={option.id} value={option}>
                                                <Typography variant={'h5'}>
                                                    {option.name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl> : null}
                    </Grid>
                }

            </Grid>
        </Grid>

    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        person: state.user.person,
        homeZone: state.user.homeZone,
        buildings: state.buildings.buildingList,
        buildingsPending: state.buildings.buildingsPending,
    }
}

const mapDispatchToProps = {
    getBuildings: getBuildings,
    setHomezone: setHomezone,
    getHomezone: getHomezone
}


export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(HomezoneComponent))