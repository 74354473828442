import * as React from "react"
import PropTypes from "prop-types";

function OutOfOfficeSvgIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" {...props}>
            <g fillRule="nonzero" stroke={props.stroke || '#333'} fill="none">
                <path
                    d="M14.5 11H5.894L4.5 13l1.394 2H14.5zM9.5 5h8.606L19.5 7l-1.394 2H9.5zM12 22v-6.875M12 11.125v-2M12 5.125V2"/>
            </g>
        </svg>

    )
}

OutOfOfficeSvgIcon.propTypes = {
    stroke: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
}

OutOfOfficeSvgIcon.defaultProps = {
    stroke: '#fff',
    width: 24,
    height: 24,
}

export default OutOfOfficeSvgIcon