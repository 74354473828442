import React, {useState} from 'react';
import PropTypes from "prop-types";

import clsx from "clsx";
import {withFormik} from "formik";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators, compose} from "redux";

import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core";
import Select from "@material-ui/core/Select/Select";
import Typography from "@material-ui/core/Typography";

import AdminButton from "../../../common/elements/AdminButton.js";
import QRCodeExportModal from "../../qrCodeGeneration/QRCodeExportModal.js";
import CustomizedSwitch from "../../../common/elements/CustomizedSwitch.js";
import useDidMountEffect from "../../../common/customHooks/useDidMountEffect.js";
import QRCodesToExportSectionWithName from "../../qrCodeGeneration/QRCodesToExportSectionWithName.js";

import {setElement} from "../../../actions/modalElementSkeleton-actions.js";
import {getTypesOfActivity} from "../../../actions/common-action.js";
import {
    TYPE_OF_ACTIVITY_UNDEFINED,
    TYPE_OF_WORKPLACE_SHARED,
    TYPES_OF_WORKPLACE,
    WORKPLACE_STATUS_ACTIVE_MANUAL,
    WORKPLACE_STATUS_INACTIVE_AUTO,
    WORKPLACE_STATUS_INACTIVE_MANUAL
} from "../../../common/utils/NameUtils.js";
import NameComponent from "./NameComponent.js";
import InputBase from "@material-ui/core/InputBase/InputBase";
import Tag from "../../../common/elements/Tag.js";

const styles = theme => ({
    root: {
        maxHeight: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: '1.2rem',
        ...theme.modalContentInput,
    },
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: theme.outerGap
    },
    panel: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        textAlign: 'left',
        gap: '.6rem',
        padding: 0,
        '& .MuiInputBase-input': {
            height: '3.6rem !important',
            boxSizing: 'border-box !important',
            minWidth: '100%',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    menuItem: {
        color: theme.colors.palette.neutral.darkMain,
        fontSize: '14px',
        width: '100%',
    },
    select: {
        border: '0px',
        '&::before': {
            borderBottom: '0px',
        },
        '&&&&:hover:before': {
            borderBottom: '0px',
        },
        fontSize: '14px',
        width: '100%',
    },
    cross: {
        stroke: theme.colors.palette.neutral.greyMain,
        width: '1.8rem',
        height: '1.8rem',
    },
    tagSection: {
        display: 'flex',
        gap: '.9rem',
        flexWrap: 'wrap',
    },
    modal: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
    }
})

function WorkplaceInfoComponent({
                                    classes,
                                    t,
                                    values,
                                    setFieldValue,
                                    handleSubmit,
                                    onValidationCorrect,
                                    typesOfActivities,
                                    getTypesOfActivity,
                                    element,
                                    onSave,
                                    disableAutofocus,
                                    update,
                                    ...props
                                }) {

    const [tagInput, setTagInput] = useState('');
    const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);

    useDidMountEffect(() => {
        if (typesOfActivities.length <= 0)
            getTypesOfActivity()
    })

    function newBestMatchActivity(selectedActivity, type) {
        if (selectedActivity === type && selectedActivity === element.activityBestMatch)
            return element.activityBestMatch !== element.activityCreatedWith
        return false
    }

    function onEnterTag(value) {
        if (!value || value === '')
            return

        value = value.trim()

        let existingValues = values.element.tags ? [...values.element.tags] : []
        const tag = {displayName: value}
        existingValues.push(tag)
        setTagInput('')
        setFieldValue("element.tags", existingValues)
        handleSubmit()
        if (update) {
            let x = {...values.element}
            x.tags = existingValues
            onSave(x)
        }
    }

    function onRemoveTag(tagToRemove) {
        let existingValues = values.element.tags
        let filteredValues = existingValues.filter(tag => tag.displayName !== tagToRemove.displayName)
        setFieldValue("element.tags", filteredValues)
        handleSubmit()
        if (update) {
            let x = {...values.element}
            x.tags = filteredValues
            onSave(x)
        }
    }

    return (
        <div className={classes.root}>
            <form className={classes.form}>
                <div className={classes.content}>
                    <NameComponent onValidationCorrect={onValidationCorrect} className={classes.panel}
                                   disableAutofocus={disableAutofocus} saveOnChange={update ? onSave : null}
                                   variant={'overline'}/>
                    <div className={classes.panel}>
                        <Typography variant={'overline'}>{t('drawingTool.workplace.type')}</Typography>
                        <Select className={classes.select}
                                fullWidth
                                onChange={(event) => {
                                    setFieldValue("element.typeOfWorkplace", event.target.value)
                                    handleSubmit()
                                    if (update) {
                                        let x = {...values.element}
                                        x.typeOfWorkplace = event.target.value
                                        onSave(x)
                                    }
                                }}
                                value={values.element.typeOfWorkplace ? values.element.typeOfWorkplace : ''}
                        >
                            {TYPES_OF_WORKPLACE.map(type => (
                                <MenuItem className={classes.menuItem} key={type}
                                          value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={classes.panel}>
                        <Typography variant={'overline'}>{t('drawingTool.workplace.activity')}</Typography>
                        <Select className={classes.select}
                                onChange={(event) => {
                                    setFieldValue("element.activityCreatedWith", event.target.value)
                                    setFieldValue("element.activityBestMatch", event.target.value)
                                    setFieldValue("selectedActivity", event.target.value)
                                    handleSubmit()
                                    if (update) {
                                        let x = {...values.element}
                                        x.activityCreatedWith = event.target.value
                                        x.activityBestMatch = event.target.value
                                        onSave(x)
                                    }
                                }}
                                value={values.selectedActivity ? values.selectedActivity : ''}
                        >
                            {typesOfActivities && typesOfActivities.map(type => (
                                <MenuItem className={classes.menuItem} key={type}
                                          value={type}>{type}{newBestMatchActivity(values.selectedActivity, type) && '*'}</MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div className={classes.panel}>
                        <Typography variant={'overline'}>{t('drawingTool.workplace.blocked')}</Typography>
                        <CustomizedSwitch
                            checked={values.element.status === WORKPLACE_STATUS_INACTIVE_MANUAL || values.element.status === WORKPLACE_STATUS_INACTIVE_AUTO}
                            onChange={(event) => {
                                setFieldValue("element.status", event.target.checked ? WORKPLACE_STATUS_INACTIVE_MANUAL : WORKPLACE_STATUS_ACTIVE_MANUAL)
                                handleSubmit()
                                if (update) {
                                    let x = {...values.element}
                                    x.status = event.target.checked ? WORKPLACE_STATUS_INACTIVE_MANUAL : WORKPLACE_STATUS_ACTIVE_MANUAL
                                    onSave(x)
                                }
                            }}/>
                    </div>
                    <div className={classes.panel}>
                        <Typography variant={'overline'}>{t('tags')}</Typography>
                        <InputBase
                            className={classes.textField}
                            type="text"
                            placeholder={t('add_tag')}
                            value={tagInput}
                            onChange={(e) => setTagInput(e.target.value)}
                            style={{width: '100%'}}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    onEnterTag(e.target.value);
                                }
                            }}
                        />
                    </div>
                    {values.element.tags && values.element.tags.length ?
                        <div className={clsx(classes.tagSection)}>
                            {values.element.tags.map(tag => {
                                return <Tag tag={tag} onDelete={() => onRemoveTag(tag)}/>
                            })}
                        </div>
                        : null}
                    {newBestMatchActivity(values.selectedActivity, values.selectedActivity) &&
                        <div className={classes.panel}>
                            <Typography variant={'caption'}>
                                {'*' + t('activity_changed')}
                            </Typography>
                        </div>}
                </div>
                {update ? <AdminButton onClick={() => setQrCodeModalOpen(true)}
                                       text={t('export_qr_code')} fullWidth outlined
                                       preventDefault/>
                    : <AdminButton onClick={onSave} text={t('add')} className={classes.button}
                                   primary fullWidth type={'submit'}
                                   preventDefault
                                   disabled={!element.name}
                    />}
            </form>
            {qrCodeModalOpen &&
                <QRCodeExportModal contents={[<QRCodesToExportSectionWithName elements={[values.element]}/>]}
                                   open={qrCodeModalOpen}
                                   onCloseModal={() => setQrCodeModalOpen(false)}/>}
        </div>
    )
}

const WorkplaceInfo = withFormik({
    mapPropsToValues: props => {
        let element = props.element
        if (!element.typeOfWorkplace) {
            element.typeOfWorkplace = TYPE_OF_WORKPLACE_SHARED
        }

        let selectedActivity = props.element.activityBestMatch
            ? props.element.activityBestMatch
            : TYPE_OF_ACTIVITY_UNDEFINED
        if (!element.activityCreatedWith)
            element.activityCreatedWith = TYPE_OF_ACTIVITY_UNDEFINED
        return ({element: element, selectedActivity: selectedActivity})
    },
    enableReinitialize: true,
    handleSubmit: (values, {props}) => {
        values.element.activityCreatedWith = values.selectedActivity
        props.setElement(values.element)
    }
})(WorkplaceInfoComponent)

WorkplaceInfo.propTypes = {
    onSave: PropTypes.func.isRequired,
    disableAutofocus: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setElement,
    getTypesOfActivity
}, dispatch)

const mapStateToProps = state => {
    return {
        typesOfActivities: state.common.typesOfActivities,
        element: state.modalElementSkeleton.element,
    }
}

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(WorkplaceInfo);