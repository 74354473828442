import * as React from "react"

const ExchangeSvgIcon = ({width, height, ...props}) => (
    <svg width={width} height={height} viewBox="4 8 55 48" {...props}>
        <title>{"Exchange"}</title>
        <path
            fill="#28a8ea"
            d="M55.51 8H43.303a3.488 3.488 0 0 0-2.468 1.022L12.022 37.835A3.488 3.488 0 0 0 11 40.303V52.51A3.49 3.49 0 0 0 14.49 56h12.207a3.488 3.488 0 0 0 2.468-1.022l28.813-28.813A3.49 3.49 0 0 0 59 23.697V11.49A3.49 3.49 0 0 0 55.51 8Z"
        />
        <path
            fill="#0078d4"
            d="M55.51 56H43.303a3.49 3.49 0 0 1-2.468-1.022L35 49.143V38.24A6.24 6.24 0 0 1 41.24 32h10.903l5.835 5.835A3.49 3.49 0 0 1 59 40.303V52.51A3.49 3.49 0 0 1 55.51 56Z"
        />
        <path
            fill="#50d9ff"
            d="M14.49 8h12.207a3.49 3.49 0 0 1 2.468 1.022L35 14.857V25.76A6.24 6.24 0 0 1 28.76 32H17.857l-5.835-5.835A3.49 3.49 0 0 1 11 23.697V11.49A3.49 3.49 0 0 1 14.49 8Z"
        />
        <path
            d="M33 20.33v26.34a1.734 1.734 0 0 1-.04.4A2.314 2.314 0 0 1 30.67 49H11V18h19.67A2.326 2.326 0 0 1 33 20.33Z"
            opacity={0.2}
        />
        <path
            d="M34 20.33v24.34A3.362 3.362 0 0 1 30.67 48H11V17h19.67A3.342 3.342 0 0 1 34 20.33Z"
            opacity={0.1}
        />
        <path
            d="M33 20.33v24.34A2.326 2.326 0 0 1 30.67 47H11V18h19.67A2.326 2.326 0 0 1 33 20.33Z"
            opacity={0.2}
        />
        <path
            d="M32 20.33v24.34A2.326 2.326 0 0 1 29.67 47H11V18h18.67A2.326 2.326 0 0 1 32 20.33Z"
            opacity={0.1}
        />
        <rect width={28} height={28} x={4} y={18} fill="#0078d4" rx={2.333}/>
        <path
            fill="#fff"
            d="M22.585 26.881h-6.547v3.829h6.145v2.454h-6.145v3.976h6.896v2.443h-9.868V24.417h9.52Z"
        />
    </svg>
)

ExchangeSvgIcon.defaultProps = {
    width: 24,
    height: 24,
}

export default ExchangeSvgIcon