import React, {useEffect, useState} from "react";

import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

import CustomButton from "../CustomButton.js";
import SelectPersonsWithModal from "../people/SelectPersonsWithModal.js";
import CustomizedSwitch from "../../common/elements/CustomizedSwitch.js";
import StaticBottomSheet from "../../common/elements/StaticBottomSheet.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";

import {SHARE_STATUS_WORKINGGROUPS} from "../../common/utils/NameUtils.js";
import {navigate, PATH_WORKING_GROUPS} from "../../common/utils/NavigationUtils.js";
import {
    addPersonToWorkingGroup,
    changeShareStatusOfGroups,
    fetchSharedWorkingGroups,
    getWorkingGroup,
    removePersonFromWorkingGroup
} from "../../actions/workingGroups-action.js";
import {fetchShareStatus, postShareStatus} from "../../actions/user-action.js";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flex: 1,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.6rem',
        alignItems: 'flex-start'
    },
    shareContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}))

function WorkingGroupPage({
                              user,
                              workingGroup,
                              postShareStatus,
                              sharedGroups,
                              shareStatus,
                              changeShareStatusOfGroups,
                              removePersonFromGroup,
                              addPersonToGroup,
                              getWorkingGroup,
                              fetchSharedWorkingGroups,
                              fetchShareStatus,
                          }) {
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyle(theme);
    const {groupId} = useParams()
    const {t} = useTranslation();

    const [showShareSwitch, setShowShareSwitch] = useState(shareStatus && shareStatus.status === SHARE_STATUS_WORKINGGROUPS);
    const [shared, setShared] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);

    useEffect(() => {
        getWorkingGroup(groupId)
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!shareStatus && user && user.id)
            fetchShareStatus(user.id)
        if (isEmpty(sharedGroups) && user && user.id)
            fetchSharedWorkingGroups(user.id)
        //eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (workingGroup) {
            setSelectedMembers([...workingGroup.members])

            if (!shared && workingGroup && sharedGroups.find(group => group.id === workingGroup.id))
                setShared(true)
        }
        if (shareStatus && shareStatus.status === SHARE_STATUS_WORKINGGROUPS)
            setShowShareSwitch(true)
        //eslint-disable-next-line
    }, [workingGroup, sharedGroups]);

    useEffect(() => {
        if (shareStatus !== null && shareStatus.status === SHARE_STATUS_WORKINGGROUPS)
            setShowShareSwitch(true)
        else if (shareStatus !== null && shareStatus.status !== null)
            setShowShareSwitch(false)
    }, [shareStatus]);

    const routeToWorkingGroups = () => {
        navigate(history, PATH_WORKING_GROUPS)
    }

    function leaveGroup() {
        removePersonFromGroup(workingGroup.id, user.id)
        routeToWorkingGroups()
    }

    function onShareStatusChange(event) {
        const status = event.target.checked
        setShared(status)
        changeShareStatusOfGroups(status, [workingGroup])

        if (status)
            postShareStatus(user.id, SHARE_STATUS_WORKINGGROUPS, [...sharedGroups, workingGroup])
        else
            postShareStatus(user.id, SHARE_STATUS_WORKINGGROUPS, sharedGroups.filter(group => group.id !== workingGroup.id))
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>

                <HeaderWithTitleAndBackButton title={workingGroup && workingGroup.name} backLabel={t("working_groups")}
                                              onNavigateBack={routeToWorkingGroups}
                />

                <div className={classes.content}>
                    {showShareSwitch &&
                        <div className={classes.shareContainer}>
                            <Typography variant={'h6'}>
                                {t('share_location')}
                            </Typography>
                            <CustomizedSwitch checked={shared} onChange={onShareStatusChange}/>
                        </div>}
                    <Typography variant={'h6'}>
                        {t("members")} ({workingGroup && workingGroup.members.length})
                    </Typography>


                    <SelectPersonsWithModal onConfirmCallback={(persons) => {
                        if (persons)
                            addPersonToGroup(workingGroup.id, persons)
                    }}
                                            hideDeleteIcon
                                            initialSelectedPersons={selectedMembers}/>
                </div>

            </div>

            <StaticBottomSheet>
                <CustomButton text={t('leave_working_group')}
                              textColor={theme.colors.palette.corporate.redMain}
                              onClick={leaveGroup}/>
            </StaticBottomSheet>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.person,
        workingGroup: state.workingGroups.selected,
        shareStatus: state.user.shareStatus,
        sharedGroups: state.workingGroups.sharedWorkingGroups,
    }
}

const mapDispatchToProps = {
    removePersonFromGroup: removePersonFromWorkingGroup,
    addPersonToGroup: addPersonToWorkingGroup,
    getWorkingGroup: getWorkingGroup,
    changeShareStatusOfGroups: changeShareStatusOfGroups,
    postShareStatus: postShareStatus,
    fetchShareStatus: fetchShareStatus,
    fetchSharedWorkingGroups: fetchSharedWorkingGroups,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkingGroupPage)
