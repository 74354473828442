import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton, makeStyles, Typography} from "@material-ui/core";
import moment from "moment";
import SwitchButtonGroup from "../../SwitchButtonGroup.js";
import NavigationBackSvgIcon from "../../../common/icons/NavigationBackSvgIcon.js";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import {useTranslation} from "react-i18next";

const useStyle = theme => makeStyles(customClasses => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            rowGap: theme.innerSmallGap,
            flexWrap: 'wrap',
        },
        ...customClasses.root
    },
    buttonGroupContainer: {
        width: '30rem',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    specificSelection: {
        display: 'flex',
        width: '24rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            border: '1px solid #E5E5E5',
            borderRadius: '9px',
            padding: '0 9px',
        },
    },
    rangeSelection: {
        height: '30px',
        minHeight: '30px',
    },
    indicator: {
        backgroundColor: theme.colors.palette.neutral.darkMain,
    },
    tab: {
        height: '30px',
        minHeight: '30px',
        width: '80px',
        minWidth: '80px',
        textTransform: 'capitalize',
        color: theme.colors.palette.neutral.darkMain,
    },

    specificText: {
        margin: '0px 5px',
    },
    selectionButton: {
        cursor: 'pointer',
    },
    arrowIcon: {
        width: '1.8rem',
        height: '1.8rem',
    },
    disabled: {
        color: theme.colors.palette.neutral.greyMain,
        fill: theme.colors.palette.neutral.greyMain,
    },
    iconButton: {
        padding: 0
    }
}));

function DashboardTimeSelection({
                                    onTimeSelect,
                                    allTime,
                                    days,
                                    selectedRange,
                                    selectedMoment,
                                    initialRange,
                                    customClasses
                                }) {

    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme)(customClasses)
    /**
     * Range be like
     * 0 = day
     * 1 = week
     * 2 = month
     * 3 = year
     * 4 = all time
     */
    const [range, setRange] = useState(initialRange != null ? initialRange : 1);
    const [selection, setSelection] = useState(initialRange != null ? initialRange : 1);

    const timer = useRef(0)
    const currentMoment = useRef(moment())

    const handleRangeChange = (value) => {
        setRange(value);
    }

    useEffect(() => {
        let newSelection
        if (range === 0) {
            newSelection = currentMoment.current.format('d')
        } else if (range === 1) {
            newSelection = currentMoment.current.format('w')
        } else if (range === 2) {
            newSelection = currentMoment.current.format('M')
        } else if (range === 3) {
            newSelection = currentMoment.current.format('YYYY')
        } else {
            setSelection(null)
            return
        }

        if (newSelection === selection)
            updateDates()
        else
            setSelection(newSelection)
        // eslint-disable-next-line
    }, [range]);

    useEffect(() => {
        updateDates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection])

    useEffect(() => {
        if (selectedRange !== undefined && selectedRange !== null) {
            setRange(selectedRange)
        }
    }, [selectedRange]);

    useEffect(() => {
        if (selectedMoment !== undefined && selectedRange !== null) {
            currentMoment.current = selectedMoment
            if (selectedRange !== undefined) {
                if (selectedRange === range) {
                    setSelection(selectedMoment.format('d'))
                    return
                }
                setRange(selectedRange)
            }
        }
        // eslint-disable-next-line
    }, [selectedMoment]);

    function updateDates() {
        let startDate, endDate, date
        if (range === 0) {
            date = currentMoment.current.day(selection)
            startDate = date.clone().startOf('day')
            endDate = date.clone().endOf('day');
        } else if (range === 1) {
            date = currentMoment.current.week(selection)
            startDate = date.clone().startOf('week')
            endDate = date.clone().endOf('week')
        } else if (range === 2) {
            date = currentMoment.current.month(selection - 1)
            startDate = date.clone().startOf('month')
            endDate = date.clone().endOf('month')
        } else if (range === 3) {
            date = currentMoment.current.year(selection)
            startDate = date.clone().startOf('year')
            endDate = date.clone().endOf('year')
        } else {
            date = null
            startDate = null
            endDate = null
        }
        if (date != null)
            currentMoment.current = date

        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            onTimeSelect(startDate, endDate, currentMoment.current)
        }, 1000)
    }

    const handleSelectPrev = () => {
        let value

        if (range === 0) {
            value = currentMoment.current.day(selection).subtract(1, 'days').format('d')
        } else if (range === 1) {
            value = currentMoment.current.week(selection).subtract(1, 'weeks').format('w')
        } else if (range === 2) {
            value = currentMoment.current.month(selection - 1).subtract(1, 'month').format('M')
        } else if (range === 3) {
            value = currentMoment.current.year(selection).subtract(1, 'years').format('YYYY')
        } else {
            setSelection(null)
            return
        }
        setSelection(Number(value))
    }

    const handleSelectNext = () => {
        let value

        if (range === 0) {
            value = currentMoment.current.day(selection).add(1, 'days').format('d')
        } else if (range === 1) {
            value = currentMoment.current.week(selection).add(1, 'weeks').format('w')
        } else if (range === 2) {
            value = currentMoment.current.month(selection - 1).add(1, 'month').format('M')
        } else if (range === 3) {
            value = currentMoment.current.year(selection).add(1, 'years').format('YYYY')
        } else {
            setSelection(null)
            return
        }

        setSelection(Number(value))
    }

    const getSelectionString = () => {
        if (range === 0 && selection != null) {
            return currentMoment.current.clone().day(selection).format('ddd DD.MM.YYYY')
        } else if (range === 1 && selection) {
            return t('cw') + ' ' + currentMoment.current.clone().week(selection).format('w yy')
        } else if (range === 2 && selection) {
            return currentMoment.current.clone().month(selection - 1).format('MMM yy')
        } else if (range === 3 && selection) {
            return currentMoment.current.clone().year(selection).format('YYYY')
        } else {
            return t('all_time')
        }
    }

    const options = () => {
        let optionsArray = days ? [t('daily')] : []
        optionsArray.push(...[t('weekly'), t('monthly'), t('annually')])
        if (allTime)
            optionsArray.push(t('all_time'))
        return optionsArray
    }

    return (
        <div className={classes.root}>
            <div className={classes.buttonGroupContainer}>
                <SwitchButtonGroup onOptionClicked={(value) => handleRangeChange(days ? value : value + 1)}
                                   initialSelected={days ? range : range - 1}
                                   options={options()}/>
            </div>

            <div className={classes.specificSelection}>
                <IconButton onClick={handleSelectPrev} disabled={selection == null} className={classes.iconButton}>
                    <NavigationBackSvgIcon
                        className={clsx(classes.arrowIcon, selection == null ? classes.disabled : '')}/>
                </IconButton>
                <Typography className={clsx(classes.specificText, selection == null ? classes.disabled : '')}>
                    {getSelectionString()}
                </Typography>
                <IconButton onClick={handleSelectNext} disabled={selection == null} className={classes.iconButton}>
                    <NavigationBackSvgIcon className={clsx(classes.arrowIcon, !selection ? classes.disabled : '')}
                                           style={{transform: 'rotate(180deg)'}}
                    />
                </IconButton>
            </div>
        </div>
    )
}

DashboardTimeSelection.propTypes = {
    onTimeSelect: PropTypes.func.isRequired,
    displayDate: PropTypes.instanceOf(Date),
    allTime: PropTypes.bool,
    days: PropTypes.bool,
    selectedRange: PropTypes.number,
    selectedMoment: PropTypes.any,
    initialRange: PropTypes.number,
    customClasses: PropTypes.object,
}

DashboardTimeSelection.defaultProps = {
    customClasses: {},
}

export default DashboardTimeSelection;