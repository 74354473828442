import React, {Component} from 'react';
import {compose} from "redux";
import {DialogActions, DialogContent, DialogContentText, Slider, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import CardComponent from "../../common/elements/CardComponent";
import {
    ACTIVITY_ATTRIBUTE_FOCUS,
    ACTIVITY_ATTRIBUTE_HYGGE,
    ACTIVITY_ATTRIBUTE_PLACE,
    ACTIVITY_ATTRIBUTE_VOLUME,
    TYPE_OF_ACTIVITY_CALLS,
    TYPE_OF_ACTIVITY_CONCENTRATE,
    TYPE_OF_ACTIVITY_CREATIVE,
    TYPE_OF_ACTIVITY_TALK,
    TYPE_OF_ACTIVITY_UNDEFINED
} from "../../common/utils/NameUtils";
import PhoneSvgIcon from "../../common/icons/PhoneSvgIcon";
import ConcentrateWorkingSvgIcon from "../../common/icons/ConcentrateWorkingSvgIcon";
import BrainstormingSvgIcon from "../../common/icons/BrainstormingSvgIcon";
import UndefinedSvgIcon from "../../common/icons/UndefinedSvgIcon";
import OneOnOneSvgIcon from "../../common/icons/OneOnOneSvgIcon";
import {getActivityText} from "../../common/utils/ActivityUtils";
import {rateWorkplace} from "../../actions/workplaceAssignment-action";

const styles = theme => ({
    modal: {
        '& .MuiDialog-paper': {
            padding: '28px 24px 16px 24px',
        },
        '& .MuiPaper-root': {
            borderRadius: '18px !important',
        },
        '& .MuiDialogTitle-root': {
            padding: 0,
        },
        '& .MuiDialogContent-root': {
            padding: 0,
            overflow: 'initial !important'
        },
        '& .MuiDialogActions-root': {
            padding: 0,
        },
        '& .MuiSlider-root': {
            color: theme.colors.palette.corporate.skinMain,
            padding: '14px 0px'
        },
        '& .MuiDivider-middle': {
            marginLeft: '0px',
            marginRight: '0px'
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '400px'
        }
    },
    heading: {
        display: 'flex',
        textTransform: 'capitalize',
        paddingBottom: '6px'
    },
    icon: {
        width: '100%'
    },
    divider: {
        marginTop: '36px',
        marginBottom: '23px',
    },
    text: {
        display: 'flex',
        marginBottom: '12px',
        textAlign: 'start',
    },
    textSlider: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    sliderText: {
        margin: '0px'
    },
    containerActivities: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '29px'
    },
    descriptionMargin: {
        marginTop: '16px',
        marginBottom: '30px'
    }
})

class RatingsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {selectedValue: 3}
    }

    onCloseModal = () => {
        this.props.onCloseCallback()
    }

    handleSliderChange = (event, newValue) => {
        this.setState({selectedValue: newValue});
    }

    loadActivityIcon = (activity) => {
        switch (activity) {
            case TYPE_OF_ACTIVITY_CALLS:
                return <PhoneSvgIcon/>
            case TYPE_OF_ACTIVITY_CONCENTRATE:
                return <ConcentrateWorkingSvgIcon/>
            case TYPE_OF_ACTIVITY_CREATIVE:
                return <BrainstormingSvgIcon/>
            case TYPE_OF_ACTIVITY_UNDEFINED:
                return <UndefinedSvgIcon/>
            case TYPE_OF_ACTIVITY_TALK:
                return <OneOnOneSvgIcon/>
            default:
                return null
        }
    }

    loadHeading = () => {
        const {t, classes} = this.props
        let activity = this.props.assignment.ratingAttribute
        switch (activity) {
            case ACTIVITY_ATTRIBUTE_VOLUME:
                return <DialogContentText className={classes.text} variant={'body1'} id="alert-dialog-description">
                    {t("rate_accoustic")}
                </DialogContentText>
            case ACTIVITY_ATTRIBUTE_PLACE:
                return <DialogContentText className={classes.text} variant={'body1'} id="alert-dialog-description">
                    {t("rate_space")}
                </DialogContentText>
            case ACTIVITY_ATTRIBUTE_HYGGE:
                return <DialogContentText className={classes.text} variant={'body1'} id="alert-dialog-description">
                    {t("rate_atmosphere")}
                </DialogContentText>
            case ACTIVITY_ATTRIBUTE_FOCUS:
                return <DialogContentText className={classes.text} variant={'body1'} id="alert-dialog-description">
                    {t("rate_focus")}
                </DialogContentText>

            default:
                return null
        }
    }

    loadLabels = () => {
        const {t, classes} = this.props
        let activity = this.props.assignment.ratingAttribute
        switch (activity) {
            case ACTIVITY_ATTRIBUTE_VOLUME:
                return <div className={classes.textSlider}>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("noisy")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("moderate")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("quite")}
                    </Typography>
                </div>
            case ACTIVITY_ATTRIBUTE_PLACE:
                return <div className={classes.textSlider}>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("narrowed")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("moderate")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("roomy")}
                    </Typography>
                </div>
            case ACTIVITY_ATTRIBUTE_HYGGE:
                return <div className={classes.textSlider}>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("uncomfortable")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("moderate")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("inspiring")}
                    </Typography>
                </div>
            case ACTIVITY_ATTRIBUTE_FOCUS:
                return <div className={classes.textSlider}>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("disturbing")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("moderate")}
                    </Typography>
                    <Typography className={classes.sliderText} id="discrete-slider" gutterBottom variant={'overline'}>
                        {t("focused")}
                    </Typography>
                </div>
            default:
                return null
        }
    }

    loadActivityText = () => {
        const {t, classes} = this.props
        let activity = this.props.assignment.ratingAttribute
        switch (activity) {
            case ACTIVITY_ATTRIBUTE_VOLUME:
                if (this.state.selectedValue === 1 || this.state.selectedValue === 2) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('volume_noisy_heading')}</Typography>
                        <Typography variant={'overline'}>{t('volume_noisy_text1')}</Typography>
                        <Typography variant={'overline'}>{t('volume_noisy_text2')}</Typography>
                        <Typography variant={'overline'}>{t('volume_noisy_text3')}</Typography>
                    </div>
                } else if (this.state.selectedValue === 3) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('moderate_heading')}</Typography>
                        <Typography variant={'overline'}>{t('volume_moderate_text1')}</Typography>
                        <Typography variant={'overline'}>{t('volume_moderate_text2')}</Typography>
                        <Typography variant={'overline'}>{t('volume_moderate_text3')}</Typography>
                    </div>
                } else {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('volume_quite_heading')}</Typography>
                        <Typography variant={'overline'}>{t('volume_quite_text1')}</Typography>
                        <Typography variant={'overline'}>{t('volume_quite_text2')}</Typography>
                        <Typography variant={'overline'}>{t('volume_quite_text3')}</Typography>
                    </div>
                }
            case ACTIVITY_ATTRIBUTE_PLACE:
                if (this.state.selectedValue === 1 || this.state.selectedValue === 2) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('narrowed_heading')}</Typography>
                        <Typography variant={'overline'}>{t('place_narrowed_text1')}</Typography>
                        <Typography variant={'overline'}>{t('place_narrowed_text2')}</Typography>
                        <Typography variant={'overline'}>{t('place_narrowed_text3')}</Typography>
                    </div>
                } else if (this.state.selectedValue === 3) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('moderate_heading')}</Typography>
                        <Typography variant={'overline'}>{t('place_moderate_text1')}</Typography>
                        <Typography variant={'overline'}>{t('place_moderate_text2')}</Typography>
                        <Typography variant={'overline'}>{t('place_moderate_text3')}</Typography>
                    </div>
                } else {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('place_roomy_heading')}</Typography>
                        <Typography variant={'overline'}>{t('place_roomy_text1')}</Typography>
                        <Typography variant={'overline'}>{t('place_roomy_text2')}</Typography>
                        <Typography variant={'overline'}>{t('place_roomy_text3')}</Typography>
                    </div>
                }
            case ACTIVITY_ATTRIBUTE_HYGGE:
                if (this.state.selectedValue === 1 || this.state.selectedValue === 2) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('hygge_uncomfortable_heading')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_uncomfortable_text1')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_uncomfortable_text2')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_uncomfortable_text3')}</Typography>
                    </div>
                } else if (this.state.selectedValue === 3) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('moderate_heading')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_moderate_text1')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_moderate_text2')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_moderate_text3')}</Typography>
                    </div>
                } else {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('hygge_inspiring_heading')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_inspiring_text1')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_inspiring_text2')}</Typography>
                        <Typography variant={'overline'}>{t('hygge_inspiring_text3')}</Typography>
                    </div>
                }
            case ACTIVITY_ATTRIBUTE_FOCUS:
                if (this.state.selectedValue === 1 || this.state.selectedValue === 2) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('focus_disturbing_heading')}</Typography>
                        <Typography variant={'overline'}>{t('focus_disturbing_text1')}</Typography>
                        <Typography variant={'overline'}>{t('focus_disturbing_text2')}</Typography>
                        <Typography variant={'overline'}>{t('focus_disturbing_text3')}</Typography>
                    </div>
                } else if (this.state.selectedValue === 3) {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('moderate_heading')}</Typography>
                        <Typography variant={'overline'}>{t('focus_moderate_text1')}</Typography>
                        <Typography variant={'overline'}>{t('focus_moderate_text2')}</Typography>
                        <Typography variant={'overline'}>{t('focus_moderate_text3')}</Typography>
                    </div>
                } else {
                    return <div className={classes.descriptionMargin}>
                        <Typography variant={'h4'}>{t('focus_focused_heading')}</Typography>
                        <Typography variant={'overline'}>{t('focus_focused_text1')}</Typography>
                        <Typography variant={'overline'}>{t('focus_focused_text2')}</Typography>
                        <Typography variant={'overline'}>{t('focus_focused_text3')}</Typography>
                    </div>
                }
            default:
                return null
        }
    }


    getActivityCard = (activity, key) => {
        return <CardComponent
            key={key}
            icon={this.loadActivityIcon(activity)}
            boldText={this.props.t(getActivityText(activity).textBold)}
            text={this.props.t(getActivityText(activity).text)}/>
    }

    rateWorkplace = () => {
        let assignment = this.props.assignment
        assignment.ratingValue = this.state.selectedValue
        this.props.rateWorkplace(assignment.person.id, assignment)
        this.props.submitCallback()
    }

    render() {
        const {classes, t, assignment} = this.props


        return (
            <Dialog open={this.props.open} onClose={() => this.onCloseModal()} className={classes.modal}>
                <div id="customized-dialog-title">
                    <Typography className={classes.heading} variant={'h6'}>
                        {t("rating")}
                    </Typography>

                    <div>
                        <Typography variant={'h3'}>
                            {assignment ? moment(assignment.timePeriod.startDate).format("dd, DD.MM.YYYY") : null}
                        </Typography>
                        <Typography variant={'body1'}>
                            {t("you_booked_a_workplace_for")}
                        </Typography>
                    </div>

                    <div className={classes.containerActivities}>
                        {this.getActivityCard(assignment.activity, assignment.id + assignment.activity)}
                    </div>

                </div>
                <Divider className={classes.divider} variant="middle"/>
                <DialogContent>
                    {this.loadHeading()}
                    {this.loadLabels()}
                    <Slider
                        onChange={this.handleSliderChange}
                        aria-labelledby="discrete-slider"
                        step={1}
                        marks
                        min={1}
                        max={5}
                        value={this.state.selectedValue}
                    />
                    {this.loadActivityText()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCloseModal} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.rateWorkplace} color="primary"
                            autoFocus>
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


RatingsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseCallback: PropTypes.any.isRequired,
    submitCallback: PropTypes.any.isRequired,
    assignment: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {
    rateWorkplace: rateWorkplace,
}

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(RatingsModal))
