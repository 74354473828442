import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import SearchableList from "../../common/elements/SearchableList";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import {getAllResourceCategories, setSelectedResourceCategory} from "../../actions/resource-actions.js";
import {useHistory} from "react-router-dom";
import useTheme from "@material-ui/core/styles/useTheme.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-around',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
}))

function ChooseResourceCategoryPage({
                                        resourceCategories,
                                        getAllResourceCategories,
                                        setSelectedResourceCategory,
                                        selectedCategory
                                    }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation()
    const history = useHistory();

    useEffect(() => {
        getAllResourceCategories()
    }, [getAllResourceCategories])

    const handleCategorySelected = (category) => {
        setSelectedResourceCategory(category)
        setTimeout(() => {
            routeToBefore()
        }, 250);
    }

    const routeToBefore = () => {
        history.goBack()
    }

    const getOptions = () => {
        if (!resourceCategories)
            return []
        return resourceCategories.map(category => ({element: category}))

    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item sm={12} className={classes.container}>
                <div className={classes.content}>

                    <HeaderWithTitleAndBackButton title={t('resource.category')} backLabel={t('back')}
                                                  onNavigateBack={routeToBefore}/>


                    <SearchableList options={getOptions()}
                                    onSelect={handleCategorySelected}
                                    selected={selectedCategory}
                                    placeholder={t('resources.search.categories')}
                    />

                </div>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        buildings: state.buildings.buildingList,
        selectedCategory: state.resource.selectedCategory,
        resourceCategories: state.resource.resourceCategories,
    }
}

const mapDispatchToProps = {
    getAllResourceCategories: getAllResourceCategories,
    setSelectedResourceCategory: setSelectedResourceCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseResourceCategoryPage)