const initialState = {
    userTimezoneId: null,
}

function timezoneReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_USER_TIMEZONE_FULFILLED':
            return {
                ...state,
                userTimezoneId: action.payload.data
            }
        case 'GET_PERSON_FULFILLED':
            return {
                ...state,
                userTimezoneId: action.payload.data.timezoneId
            }

        default:
            return {...state}
    }
}

export default timezoneReducer;