import * as React from "react"
import PropTypes from 'prop-types'
import theme from "../../theme.js";

function PauseSvgIcon({color, width, height, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            {...props}>
            <path
                d="M7 6h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Zm7 0h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}

PauseSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

PauseSvgIcon.defaultProps = {
    color: theme.colors.palette.corporate.skinMain,
    width: 24,
    height: 24
};

export default PauseSvgIcon