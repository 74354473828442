import * as React from "react"
import PropTypes from "prop-types";

function EditSvgIcon({color, size, ...props}) {
    return (
        <svg width={size} height={size} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"{...props}>
            <g fill='none' fillRule="evenodd" strokeLinejoin="round" stroke={color} strokeWidth={1.5}>
                <path d="M14.562 26.807h-4.549V22.26L27.202 5.093l4.134 4.238-7.148 7.447zM24 9l4 4M9 30.082h23"/>
            </g>
        </svg>
    )
}

EditSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

EditSvgIcon.defaultProps = {
    color: '#333',
    size: 30,
};


export default EditSvgIcon
