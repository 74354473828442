import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useTranslation} from "react-i18next";
import ActionListItem from "../../common/elements/ActionListItem.js";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy.js";
import PropTypes from "prop-types";
import MultipleAvatarsGroup from "./MultipleAvatarsGroup.js";
import {getFilteredUsers} from "../../actions/user-action.js";
import {getAllWorkingGroups} from "../../actions/workingGroups-action.js";
import InfiniteLoaderListWithSearchbar from "../../components/InfiniteLoaderListWithSearchbar.js";
import CustomCheckbox from "../../components/CustomCheckbox.js";
import {isArray} from "lodash";
import ModalWrapper from "./ModalWrapper.js";
import ClickableText from "./ClickableText";
import {isEmailValid} from "../utils/PasswordAndEmailUtils.js";
import {sortByName} from "../utils/CommonUtils.js";

const useStyle = makeStyles(theme => ({
    root: {},
}))

function PersonAndWorkingGroupSelectionModal({
                                                 userId,
                                                 open,
                                                 onConfirm,
                                                 onClose,
                                                 loading,
                                                 loadUsersWithFilter,
                                                 getAllWorkingGroups,
                                                 users,
                                                 workingGroups,
                                                 pageToken,
                                                 hideAvailabilityStatus,
                                                 withWorkingGroups,
                                                 withUsers,
                                                 allowExternalPersons,
                                                 initialSelectedPersons,
                                                 initialSelectedExternalPersons,
                                                 initialSelectedGroups,
                                                 preventHistoryGoBack,
                                                 loggedInUserAlwaysSelected,
                                                 submitText
                                             }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const [selectedPersons, setSelectedPersons] = useState(initialSelectedPersons);
    const [externalSelectedExternalPersons, setExternalSelectedExternalPersons] = useState(initialSelectedExternalPersons);
    const [selectedGroups, setSelectedGroups] = useState(initialSelectedGroups);

    useEffect(() => {
        if (withWorkingGroups)
            getAllWorkingGroups()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isArray(initialSelectedPersons))
            setSelectedPersons(initialSelectedPersons)
        // eslint-disable-next-line
    }, [initialSelectedPersons]);

    useEffect(() => {
        if (isArray(initialSelectedExternalPersons))
            setExternalSelectedExternalPersons(initialSelectedExternalPersons)
        // eslint-disable-next-line
    }, [initialSelectedExternalPersons]);

    useEffect(() => {
        if (isArray(initialSelectedGroups)) {
            setSelectedGroups(initialSelectedGroups)
        }
        // eslint-disable-next-line
    }, [initialSelectedGroups]);

    const items = useMemo(() => {
        if (withWorkingGroups && withUsers)
            return [...externalSelectedExternalPersons, ...[...workingGroups, ...users].sort(sortByName)]

        if (withWorkingGroups && !withUsers)
            return [...workingGroups].sort(sortByName)

        return [...users].sort(sortByName)
        // eslint-disable-next-line
    }, [users, workingGroups])

    const loadUsers = (searchString, resetToken = false) => {
        if (withUsers)
            loadUsersWithFilter(searchString, resetToken ? 0 : pageToken + 1)
    }

    const getItem = (object) => {
        return !withWorkingGroups || object.email ? getPersonItem(object) : getWorkinggroupItem(object);
    }

    const getWorkinggroupItem = (group) => {
        return <ActionListItem startAdornment={<MultipleAvatarsGroup persons={group.members}/>}
                               text={group.name}
                               action={() => onSelectGroup(group)}
                               height={70}
                               avatarHeight={40}
                               hideArrow
                               key={group.id}
                               endAdornment={<CustomCheckbox name={'inviteMember'}
                                                             selected={isGroupSelected(group)}/>}
        />
    }

    const getPersonItem = (person) => {
        if (person.id) {
            return <ActionListItem startAdornment={<AvatarWithOccupancy person={person}
                                                                        hideAvailabilityStatus={hideAvailabilityStatus}/>}
                                   text={person.name + " " + person.surname}
                                   action={() => onSelectPerson(person)}
                                   height={70}
                                   avatarHeight={40}
                                   hideArrow
                                   key={person.id}
                                   endAdornment={
                                       <CustomCheckbox name={'inviteMember'}
                                                       selected={isPersonSelected(person)}/>
                                   }/>
        }

        return <ActionListItem startAdornment={<AvatarWithOccupancy person={person}
                                                                    hideAvailabilityStatus/>}
                               text={person.email}
                               textSecondRow={t('not_in_organization')}
                               action={() => onSelectPerson(person)}
                               height={70}
                               avatarHeight={40}
                               hideArrow
                               key={person.email}
                               endAdornment={
                                   <CustomCheckbox name={'inviteMember'}
                                                   selected={isPersonSelected(person)}/>
                               }/>
    }

    const onSelectGroup = (group) => {
        if (selectedGroups.some(elementGroup => group.id === elementGroup.id)) {
            setSelectedGroups(selectedGroups.filter(elementGroup => elementGroup.id !== group.id))
        } else {
            setSelectedGroups([...selectedGroups, group])
        }
    }

    const onSelectPerson = (person) => {
        if (person.id) {
            if (!selectedPersons.some(p => (p.id && person.id === p.id) || person.email === p.email) && !isMemberOfSelectedGroup(person)) {
                setSelectedPersons([...selectedPersons, person])
            } else {
                setSelectedPersons(selectedPersons.filter(p => p.id && p.id !== person.id))
                if (isMemberOfSelectedGroup(person)) {
                    removePersonFromGroups(person)
                }
            }
        } else if (!person.id && person.email) {
            if (externalSelectedExternalPersons.some(p => p.email === person.email)) {
                setExternalSelectedExternalPersons(externalSelectedExternalPersons.filter(p => p.email !== person.email))
            } else {
                setExternalSelectedExternalPersons([...externalSelectedExternalPersons, person])
            }
        }
    }

    function removePersonFromGroups(person) {
        let newGroups = []
        for (let group of selectedGroups) {
            let newGroup = {...group}
            newGroup.members = group.members.filter(p => p.id !== person.id)
            newGroups.push(newGroup)
        }
        setSelectedGroups(newGroups)
    }

    const isPersonSelected = (person) => {
        if (!person)
            return false
        if (person.id) {
            return selectedPersons.some(member => member.id === person.id) || (loggedInUserAlwaysSelected && person.id === userId) || isMemberOfSelectedGroup(person)
        } else {
            return externalSelectedExternalPersons.some(member => member.email === person.email)
        }
    }

    const isGroupSelected = (selectedGroup) => {
        return selectedGroups.some(group => group.id === selectedGroup.id)
    }

    const isMemberOfSelectedGroup = (person) => {
        if (!withWorkingGroups)
            return false

        let isMember = false
        selectedGroups.forEach(group => {
            if (group.members.some(p => person.id && person.id === p.id)) {
                isMember = true
            }
        })
        return isMember
    }

    return (
        <ModalWrapper onClose={onClose} preventHistoryGoBack={preventHistoryGoBack} open={open} maxHeight
                      headerActions={<ClickableText text={submitText ?? t('invite')}
                                                    onClick={() => {
                                                        onConfirm(selectedPersons, withWorkingGroups ? selectedGroups : null, allowExternalPersons ? externalSelectedExternalPersons : null)
                                                        onClose()
                                                    }}
                                                    className={classes.clickable}/>}>
            <InfiniteLoaderListWithSearchbar open={open}
                                             confirmText={t('invite')}
                                             searchCallback={(items, searchString) => {
                                                 return items.filter(e => (e.name + ' ' + e.surname).toLowerCase().includes(searchString.toLowerCase())
                                                     || (e.email && e.email.toLowerCase().includes(searchString.toLowerCase()))
                                                     || (e.title && e.title.toLowerCase().includes(searchString.toLowerCase())))
                                             }}
                                             specialItemsValidation={isEmailValid}
                                             specialItemsCreation={(searchString => {
                                                 return {email: searchString}
                                             })}
                                             showSpecialSelectedItems={allowExternalPersons}
                                             items={items}
                                             getItem={getItem}
                                             pageLoading={loading}
                                             hasNextPage={null !== pageToken}
                                             searchPlaceholder={t('enter_name_or_mail')}
                                             loadNextPage={loadUsers}/>
        </ModalWrapper>
    )
}

PersonAndWorkingGroupSelectionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,

    initialSelectedPersons: PropTypes.array,
    initialSelectedExternalPersons: PropTypes.array,
    initialSelectedGroups: PropTypes.array,

    withWorkingGroups: PropTypes.bool,
    withUsers: PropTypes.bool,
    allowExternalPersons: PropTypes.bool,
    hideAvailabilityStatus: PropTypes.bool,
    loggedInUserAlwaysSelected: PropTypes.bool,

    preventHistoryGoBack: PropTypes.bool,
    submitText: PropTypes.string,
}

PersonAndWorkingGroupSelectionModal.defaultProps = {
    initialSelectedPersons: [],
    initialSelectedExternalPersons: [],
    initialSelectedGroups: [],
    loggedInUserAlwaysSelected: true,
    withUsers: true,
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        users: state.user.users,
        loading: state.user.usersLoading,
        pageToken: state.user.paginationToken,
        workingGroups: state.workingGroups.workingGroups,
    }
}

const mapDispatchToProps = {
    loadUsersWithFilter: getFilteredUsers,
    getAllWorkingGroups: getAllWorkingGroups,
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonAndWorkingGroupSelectionModal)
