import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import {navigate, PATH_PROFILE} from "../../common/utils/NavigationUtils.js";

import {createVacationAndLoadAssignments, fetchVacationOfPersonAtTime} from "../../actions/vacation-actions.js";
import SwitchButtonGroup from "../SwitchButtonGroup.js";
import VacationBooking from "./VacationBooking.js";
import VacationOverview from "./VacationOverview.js";


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
    },
    innerCalendar: {
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-evenly',
        }
    }
}));


function VacationPage({userId, fetchVacations, vacationList}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const history = useHistory();
    const classes = useStyle(theme);

    const [pageSelection, setPageSelection] = useState(0);

    useEffect(() => {
        if (userId)
            fetchVacations(userId)
        // eslint-disable-next-line
    }, [userId])

    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton title={t('vacation')}
                                          onNavigateBack={() => navigate(history, PATH_PROFILE)}
                                          backLabel={t('profile')}
            />

            <SwitchButtonGroup options={[t('overview'), t('book')]}
                               initialSelected={pageSelection}
                               onOptionClicked={(index) => setPageSelection(index)}/>

            {pageSelection === 0
                ? <VacationOverview vacationList={vacationList}/>
                : <VacationBooking vacationList={vacationList} afterCreationCallback={() => setPageSelection(0)}/>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        vacationList: state.vacation.vacationList,
    }
};

let mapDispatchToProps = {
    createVacationEndpoint: createVacationAndLoadAssignments,
    fetchVacations: fetchVacationOfPersonAtTime,
}

export default connect(mapStateToProps, mapDispatchToProps)(VacationPage);