import * as React from "react"
import PropTypes from "prop-types";

function ShareLocationActiveSvgIcon({stroke, strokeHighlight, ...props}) {
    return (
        <svg width={props.size} height={props.size} viewBox={'0 0 24 24'} {...props}>
            <g
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <g transform="translate(3 1.8)" stroke={stroke}>
                    <circle cx={9} cy={5.4} r={3}/>
                    <path d="M4.2 16.8v-.667a5.933 5.933 0 015.933-5.933"/>
                    <path d="M9 21c-6-4.686-9-8.686-9-12a9 9 0 0118 0"/>
                </g>
                <rect
                    stroke={strokeHighlight}
                    x={12}
                    y={12}
                    width={10.8}
                    height={10.8}
                    rx={5.4}
                />
                <path stroke={strokeHighlight}
                      d="M19.487 15.047l-2.386 4.173-1.804-1.586"/>
            </g>
        </svg>
    )
}

ShareLocationActiveSvgIcon.propTypes = {
    stroke: PropTypes.string,
    strokeHighlight: PropTypes.string,
    size: PropTypes.number,
}

ShareLocationActiveSvgIcon.defaultProps = {
    size: 24,
    stroke: '#333',
    strokeHighlight: '#58BCCC',
}

export default ShareLocationActiveSvgIcon