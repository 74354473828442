import * as React from 'react'

const DrawSelectSvgIcon = props =>
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <path
            d="M9.097 3.726a.15.15 0 0 1 .097.046l9.052 9.39a.25.25 0 0 1-.206.423l-4.575-.481 2.382 6.542a.495.495 0 0 1-.22.605l-.083.037-.938.319a.505.505 0 0 1-.647-.317l-2.27-6.455-3.243 3.397a.25.25 0 0 1-.43-.19l.92-13.177a.15.15 0 0 1 .16-.139Z"
            fillRule="nonzero"
            stroke={props.stroke}
            fill="none"
        />
    </svg>

export default DrawSelectSvgIcon
