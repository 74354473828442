import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import moment from "moment";
import hash from "object-hash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import XSvgIcon from "../../common/icons/XSvgIcon";
import Dialog from "@material-ui/core/Dialog/Dialog";
import CssBaseline from '@material-ui/core/CssBaseline'
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles, Slide, Typography} from "@material-ui/core";

import ModalHandler from "./ModalHandler.js";
import SimpleListItem from "./SimpleListItem.js";
import CustomTextField from "../../components/CustomTextField.js";
import RadioButtonWithDescription from "../../components/profile/RadioButtonWithDescription";

import {getEndOfWorkingDay} from "../utils/TimeUtils.js";
import {fetchAvailabilityStatus, setAvailabilityStatus,} from "../../actions/user-action.js";

import NavigationBackSvgIcon from "../icons/NavigationBackSvgIcon.js";
import {
    AVAILABILITY_STATUS_ABSENT,
    AVAILABILITY_STATUS_MEETING,
    AVAILABILITY_STATUS_OFFLINE,
    AVAILABILITY_STATUS_ONLINE,
    STATUS_TIMER_1_HOUR,
    STATUS_TIMER_1_HOUR_SECONDS,
    STATUS_TIMER_30_MINUTES,
    STATUS_TIMER_30_MINUTES_SECONDS,
    STATUS_TIMER_4_HOURS,
    STATUS_TIMER_4_HOURS_SECONDS,
    STATUS_TIMER_DO_NOT_DELETE,
    STATUS_TIMER_TODAY
} from "../utils/NameUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        '& .MuiDialog-paper': {
            margin: 0,
            boxSizing: 'border-box',
            borderRadius: '18px 18px 0 0',
            width: '100%',
            maxWidth: theme.gridSizes.maxWidth,
            bottom: 0,
            position: 'absolute',
        },
    },
    headerActions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '18px 18px 12px 18px',
        boxSizing: 'border-box',
    },
    button: {
        textTransform: 'capitalize'
    },
    textField: {
        padding: '1.2rem 0',
    },
    scrollContainer: {
        overflowY: 'auto',
        padding: '0 18px',
        '& > div:last-child': {
            boxSizing: 'border-box',
            marginBottom: '1.8rem',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    exitIcon: {
        cursor: 'pointer',
        width: '34px',
        height: '34px',
    },
    navigateIcon: {
        cursor: 'pointer',
        width: '24px',
        height: '24px',
    },
    radioButton: {
        height: '20px',
        width: '20px'
    }
}))

function AvailabilityStatusPopUp({
                                     onCloseCallback,
                                     setAvailabilityStatus,
                                     open,
                                     userId,
                                     availabilityStatus,
                                     fetchAvailabilityStatus
                                 }) {

    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const [timeSelectionOpen, setTimeSelectionOpen] = useState(false)
    const [selectedExpiration, setSelectedExpiration] = useState(null)
    const [selectedStatusText, setSelectedStatusText] = useState('')
    const [isCustomTypedText, setIsCustomTypedText] = useState(false)
    const [typeOfSelectedStatus, setTypeOfSelectedStatus] = useState(null)

    const statusSuggestions = [
        {
            type: AVAILABILITY_STATUS_ONLINE,
            durationText: STATUS_TIMER_TODAY,
            durationInSec: STATUS_TIMER_TODAY
        },
        {
            type: AVAILABILITY_STATUS_MEETING,
            durationText: STATUS_TIMER_1_HOUR,
            durationInSec: STATUS_TIMER_1_HOUR_SECONDS
        },
        {
            type: AVAILABILITY_STATUS_ABSENT,
            durationText: STATUS_TIMER_1_HOUR,
            durationInSec: STATUS_TIMER_1_HOUR_SECONDS
        },
        {
            type: AVAILABILITY_STATUS_OFFLINE,
            durationText: STATUS_TIMER_DO_NOT_DELETE,
            durationInSec: null
        },
    ]

    const expirationDurations = [
        {durationText: STATUS_TIMER_DO_NOT_DELETE, durationInSec: null},
        {durationText: STATUS_TIMER_30_MINUTES, durationInSec: STATUS_TIMER_30_MINUTES_SECONDS},
        {durationText: STATUS_TIMER_1_HOUR, durationInSec: STATUS_TIMER_1_HOUR_SECONDS},
        {durationText: STATUS_TIMER_4_HOURS, durationInSec: STATUS_TIMER_4_HOURS_SECONDS},
        {durationText: STATUS_TIMER_TODAY, durationInSec: null},
    ]

    useEffect(() => {
        if (open && userId && availabilityStatus === null) {
            fetchAvailabilityStatus(userId)
        }
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        if (availabilityStatus !== null) {
            setIsCustomTypedText(!!availabilityStatus.customText)
            setSelectedStatusText(availabilityStatus.customText ? availabilityStatus.customText : availabilityStatus.statusText)
            setTypeOfSelectedStatus(availabilityStatus.typeOfAvailabilityStatus)
            if (availabilityStatus.statusExpiration)
                setSelectedExpiration(availabilityStatus.statusExpiration)
            else
                setSelectedExpiration({durationText: STATUS_TIMER_DO_NOT_DELETE, durationInSec: null})
        }
        // eslint-disable-next-line
    }, [availabilityStatus])

    function onSelectExpiration(expirationDuration) {
        setSelectedExpiration(expirationDuration)
        setTimeout(() => {
            setTimeSelectionOpen(false)
        }, 250);
    }

    function onSelectStatus(status) {
        if (!isCustomTypedText)
            setSelectedStatusText(status.type)
        setTypeOfSelectedStatus(status.type)
        setSelectedExpiration(status)
    }

    function saveAvailabilityStatus() {
        let currentUnixTimeInSec = null;
        if (selectedExpiration && !selectedExpiration.durationText && !selectedExpiration.durationInSec) {
            currentUnixTimeInSec = selectedExpiration
        } else if (selectedExpiration && STATUS_TIMER_TODAY === selectedExpiration.durationText) {
            let today = moment()
            today.set({hours: getEndOfWorkingDay(), minutes: 0, seconds: 0})
            currentUnixTimeInSec = today.valueOf() / 1000
        } else if (selectedExpiration && selectedExpiration.durationInSec !== null) {
            let rightNow = moment()
            rightNow.add('seconds', selectedExpiration.durationInSec)
            currentUnixTimeInSec += rightNow.valueOf() / 1000
        }
        setAvailabilityStatus(userId, typeOfSelectedStatus, selectedStatusText, currentUnixTimeInSec, false)
        onCloseCallback()
    }

    function getExpirationTime() {


        if (selectedExpiration && !selectedExpiration.durationText && !selectedExpiration.durationInSec) {
            let today = moment(selectedExpiration)
            return today.format('HH:mm')
        } else if (selectedExpiration && STATUS_TIMER_TODAY === selectedExpiration.durationText) {
            return moment().set({hours: getEndOfWorkingDay(), minutes: 0, seconds: 0}).format('HH:mm')
        } else if (selectedExpiration && selectedExpiration.durationInSec !== null) {
            return moment().add({seconds: selectedExpiration.durationInSec}).format('HH:mm')
        } else if (selectedExpiration) {
            return t(selectedExpiration.durationText)
        } else {
            return t(STATUS_TIMER_DO_NOT_DELETE)
        }
    }

    return (
        <ModalHandler onClose={onCloseCallback}>
            <Dialog open={open}
                    className={classes.root}
                    onClose={onCloseCallback}>
                {!timeSelectionOpen ? (
                    <Slide direction="right" in={!timeSelectionOpen} mountOnEnter unmountOnExit>
                        <div className={classes.contentWrapper}>
                            <div className={classes.headerActions}>
                                <XSvgIcon className={classes.exitIcon}
                                          onClick={onCloseCallback}/>
                                <Button className={classes.button} onClick={saveAvailabilityStatus}>{t('save')}</Button>
                            </div>
                            <Divider style={{margin: '0 1.8rem'}}/>
                            <div className={classes.scrollContainer}>
                                <div className={classes.textField}>
                                    <CustomTextField title={t('whats_your_status')}
                                                     type="text"
                                                     placeholder={t('enter_status_here')}
                                                     value={isCustomTypedText ? selectedStatusText : t(selectedStatusText.toLowerCase())}
                                                     onChange={(event) => {
                                                         if (event.target.value)
                                                             setIsCustomTypedText(true)
                                                         else
                                                             setIsCustomTypedText(false)
                                                         setSelectedStatusText(event.target.value)
                                                     }}
                                                     autoFocus={false}/>
                                </div>
                                <Divider/>
                                <SimpleListItem title={t('delete_after')} onClick={() => setTimeSelectionOpen(true)}
                                                alternativeEndAdornment={<Typography
                                                    variant={"h6"}>{getExpirationTime()}</Typography>}
                                                textColor={theme.colors.palette.neutral.greyMain}/>
                                <SimpleListItem title={t('suggestions')} hideArrow topBorder={false}
                                                textColor={theme.colors.palette.neutral.greyMain}/>

                                {statusSuggestions.map(statusSuggestion => {
                                    return <RadioButtonWithDescription title={t(statusSuggestion.type.toLowerCase())}
                                                                       value={typeOfSelectedStatus}
                                                                       selected={statusSuggestion.type === typeOfSelectedStatus}
                                                                       key={hash(statusSuggestion)}
                                                                       onChange={() => onSelectStatus(statusSuggestion)}/>
                                })}
                            </div>
                        </div>
                    </Slide>
                ) : null}

                {timeSelectionOpen ? (
                    <Slide direction="left" in={timeSelectionOpen} mountOnEnter unmountOnExit>
                        <div className={classes.contentWrapper}>
                            <div className={classes.headerActions}>
                                <NavigationBackSvgIcon className={classes.navigateIcon}
                                                       onClick={() => setTimeSelectionOpen(false)}/>
                            </div>
                            <div className={classes.scrollContainer}>
                                <SimpleListItem title={t('delete_after')} hideArrow
                                                textColor={theme.colors.palette.neutral.greyMain}/>
                                {expirationDurations.map(duration => {
                                    return <RadioButtonWithDescription title={t(duration.durationText)}
                                                                       onChange={() => onSelectExpiration(duration)}
                                                                       key={duration.durationText}
                                                                       value={duration.durationInSec}
                                                                       selected={selectedExpiration && selectedExpiration.durationText === duration.durationText}/>
                                })}
                            </div>
                        </div>
                    </Slide>
                ) : null}
                <CssBaseline/>
            </Dialog>
        </ModalHandler>
    )
}

AvailabilityStatusPopUp.propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseCallback: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
    return {
        availabilityStatus: state.user.availabilityStatus,
        userId: state.user.person.id,
    }
}

const mapDispatchToProps = {
    setAvailabilityStatus: setAvailabilityStatus,
    fetchAvailabilityStatus: fetchAvailabilityStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityStatusPopUp)

