import axios from 'axios'

export function getFloors(buildingId) {

    return {
        type: 'GET_FLOORS',
        payload: axios.get(`/api/building/${buildingId}/floor`)
    }
}

export function getFloor(buildingId, floorId) {

    return {
        type: 'GET_FLOOR',
        payload: axios.get(`/api/building/${buildingId}/floor/${floorId}`)
    }
}

export function getFloorByWorkplaceId(workplaceId) {

    return {
        type: 'GET_FLOOR_BY_WORKPLACE_ID',
        payload: axios.get(`/api/building/-1/floor/getByWorkplace/${workplaceId}`),
        meta: {
            workplaceId: workplaceId
        }

    }
}

export function getFloorBySpaceId(spaceId) {

    return {
        type: 'GET_FLOOR_BY_SPACE_ID',
        payload: axios.get(`/api/building/-1/floor/getBySpace/${spaceId}`),
        meta: {
            spaceId: spaceId
        }

    }
}

export function createFloor(buildingId, floor) {

    return {
        type: 'CREATE_FLOOR',
        payload: axios.post(`/api/building/${buildingId}/floor`, floor)
    }
}

export function updateFloors(buildingId, floorDtos) {

    return {
        type: 'UPDATE_FLOORS',
        payload: axios.patch(`/api/building/${buildingId}/floor`, floorDtos)
    }
}

export function updateFloorLevels(buildingId, floorDtos) {

    return {
        type: 'UPDATE_FLOOR_LEVELS',
        payload: axios.patch(`/api/building/${buildingId}/floor/updateLevels`, floorDtos)
    }
}

export function deleteFloor(buildingId, floorId) {
    return {
        type: 'DELETE_FLOOR',
        payload: axios.delete(`/api/building/${buildingId}/floor/${floorId}`)
    }
}


export function getFloorPlanImage(buildingId, floorId) {
    return {
        type: 'GET_FLOORPLAN_IMAGE',
        payload: axios.get(`/api/building/${buildingId}/floor/${floorId}/image`),
        meta: floorId
    }
}

export function setUploadFloorplanPending() {
    return {
        type: 'SET_UPLOAD_FLOORPLAN_PENDING'
    }
}

export function uploadFloorImageCall(buildingId, floorId, file, deleteGeometries, grayScale) {
    let formData = new FormData();
    formData.append("multipartFile", file)

    return {
        type: 'UPLOAD_IMAGE',
        payload: axios.post(`/api/building/${buildingId}/floor/${floorId}/image`, formData, {
            params: {
                deleteGeometries: deleteGeometries,
                grayScale: grayScale
            }
        }),
        meta: floorId
    }
}

export function uploadFloorImage(buildingId, floorId, file, deleteGeometries, grayScale) {
    return (dispatch) => {
        return dispatch(uploadFloorImageCall(buildingId, floorId, file, deleteGeometries, grayScale))
            .then(() => {
                if (deleteGeometries) {
                    dispatch(getFloor(buildingId, floorId))
                }
            })
    }
}

export function setFloor(floor, setGeometries) {
    return {
        type: 'SET_FLOOR',
        payload: floor,
        meta: setGeometries
    }
}

export function setImageScale(buildingId, floorId, scaleDrawing) {
    return {
        type: 'SET_IMAGE_SCALE',
        payload: axios.put(`/api/building/${buildingId}/floor/${floorId}/scale`, scaleDrawing),
    }
}

export function saveFloorGeometries(buildingId, floorId, geometries) {
    return {
        type: 'SAVE_FLOOR_GEOMETRIES',
        payload: axios.put(`/api/building/${buildingId}/floor/${floorId}/geometries`, geometries),
    }
}
