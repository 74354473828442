import React, {Component} from 'react';
import {connect} from "react-redux";

import MuiAlert from '@material-ui/lab/Alert';
import {resetError} from "../actions/error-actions";
import {Grow, withStyles} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import {resetActionFeedback} from "../actions/actionFeedback-actions";

const styles = theme => ({
    root: {
        position: 'absolute',
        height: 0,
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
});

class SnackbarContainer extends Component {

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        if (this.props.error)
            this.props.resetError()
        if (this.props.actionFeedback)
            this.props.resetActionFeedback()
    };

    growTransition(props) {
        return <Grow {...props} />;
    }

    render() {

        const {classes, error, actionFeedback, theme} = this.props

        if (!error && !actionFeedback) {
            return null
        }
        return (
            <div className={classes.root}>
                {error ?
                    <Snackbar open={true} autoHideDuration={4000}
                              onClose={this.handleClose}
                              TransitionComponent={this.growTransition}
                              anchorOrigin={window.screen.width <= theme.breakpoints.values.xl
                                  ? {vertical: 'top', horizontal: 'center'}
                                  : {vertical: 'top', horizontal: 'right'}}>

                        <MuiAlert
                            onClose={this.handleClose}
                            severity="error" elevation={6}>
                            {this.props.errorMessage}
                        </MuiAlert>
                    </Snackbar>
                    : <Snackbar open={true} autoHideDuration={4000}
                                onClose={() => this.handleClose()}
                                TransitionComponent={this.growTransition}
                                anchorOrigin={window.screen.width <= theme.breakpoints.values.xl
                                    ? {vertical: 'top', horizontal: 'center'}
                                    : {vertical: 'top', horizontal: 'right'}}>
                        <MuiAlert
                            onClose={() => this.handleClose()}
                            elevation={6}>
                            {this.props.actionFeedback}
                        </MuiAlert>
                    </Snackbar>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.error.error,
        errorMessage: state.error.errorMessage,
        actionFeedback: state.actionFeedback.actionFeedback,
    }
}


const mapDispatchToProps = {
    resetError: resetError,
    resetActionFeedback: resetActionFeedback,
}


Snackbar.propTypes = {};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer))