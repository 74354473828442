import React, {useEffect, useState} from 'react';

import moment from "moment";
import {useFormik} from "formik";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import CustomDateRangePicker from "../CustomDateRangePicker.js";
import BottomActionSheet from "../../common/elements/BottomActionSheet";

import {createVacationAndLoadAssignments, fetchVacationOfPersonAtTime} from "../../actions/vacation-actions.js";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import {getDateAtHours} from "../../common/utils/TimeUtils.js";
import CustomButton from "../CustomButton.js";


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        paddingTop: theme.outerGap,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    innerCalendar: {
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-evenly',
        }
    }
}));

function VacationBooking({
                             userId,
                             createVacationEndpoint,
                             fetchVacations,
                             vacationList,
                             assignVacationPending,
                             afterCreationCallback
                         }) {
    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyle(theme);
    const [formError, setFormError] = useState(false);
    const formik = useFormik({
        initialValues: {
            dateRange: {
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day')
            },
            dayTime: {
                startDate: getDateAtHours(moment(), process.env.REACT_APP_MORNING_WORKING_DAY_HOUR),
                endDate: getDateAtHours(moment(), Number(process.env.REACT_APP_MORNING_WORKING_DAY_HOUR) + Number(process.env.REACT_APP_DURATION_WORKING_DAY_IN_HOUR))
            },
        },
        onSubmit: (values) => {
            createVacationEndpoint(
                userId,
                values.dateRange
            )
                .then(() => afterCreationCallback())
        }
    })
    const [tp, setTp] = useState({startDate: moment().startOf('day'), endDate: moment().endOf('day')});


    useEffect(() => {
        if (userId)
            fetchVacations(userId)
        // eslint-disable-next-line
    }, [userId])

    useDidMountEffect(() => {
        setDateRange()
        setTp({startDate: moment().startOf('day'), endDate: moment().endOf('day')})
        // eslint-disable-next-line
    }, [vacationList])

    const setDateRange = (range) => {
        if (!range)
            return
        setTp(range)
        formik.setFieldValue('dateRange', range)
    }

    return (
        <div className={classes.root}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <CustomDateRangePicker minDate={moment()} maxDate={moment().add(18, 'month')}
                                       onDateChange={(date) => setDateRange(date)}
                                       disabledIntervals={vacationList.map((vac) => {
                                           return vac.timePeriod
                                       })}
                                       initialSelected={tp}
                                       disableEarlierThanNow
                                       onError={setFormError}
                                       innerClassName={classes.innerCalendar}/>

                {/*<DaytimeSelection setTimePeriodCallback={(dayTime) => setDayTime(dayTime)}/>*/}

                <BottomActionSheet content={<CustomButton type={'submit'} text={t('book')} primary disabled={formError}
                                                          isLoading={assignVacationPending}/>
                }/>
            </form>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        assignVacationPending: state.vacation.assignVacationPending
    }
};

let mapDispatchToProps = {
    createVacationEndpoint: createVacationAndLoadAssignments,
    fetchVacations: fetchVacationOfPersonAtTime,
}

export default connect(mapStateToProps, mapDispatchToProps)(VacationBooking);