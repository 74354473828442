import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";

import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import useTheme from "@material-ui/core/styles/useTheme";
import {isNumber} from "lodash";


const useStyles = props => makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        border: `solid 1px ${theme.colors.palette.neutral.greyMedium}`,
        borderRadius: '9px',
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
    },
    button: {
        width: '50%',
        minHeight: '3.2rem',
        padding: 0,
        border: 'none',
        color: 'red',
        textColor: 'green',
        borderRadius: '9px !important',
        '& .MuiTouchRipple-root span': {
            backgroundColor: `${props.color || theme.colors.palette.corporate.skinMain} !important`,
        },
    },
    buttonActive: {
        backgroundColor: `${props.color || theme.colors.palette.corporate.skinMain} !important`,
    },
    text: {
        textTransform: 'none',
        color: theme.colors.palette.neutral.white,
        display: 'flex',
        alignItems: 'center'
    },
    textInactive: {
        color: theme.colors.palette.neutral.greyMain,
    }
}));

function SwitchButtonGroup({initialSelected, options, onOptionClicked, height, ...props}) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);

    const [selected, setSelected] = useState(initialSelected);

    useEffect(() => {
        if (initialSelected !== selected)
            setSelected(initialSelected)
    }, [initialSelected, selected]);

    const onButtonClicked = (value) => {
        if (onOptionClicked)
            onOptionClicked(value)

        setSelected(value)
    }

    return (
        <ButtonGroup className={clsx(classes.root, props.className)}>
            {options.map((option, index) => {
                return <Button
                    key={index} style={{height: isNumber(height) ? height - 2 : null}}
                    className={selected === index ? clsx(classes.button, classes.buttonActive) : classes.button}
                    onClick={() => onButtonClicked(index)}>
                    <Typography variant={selected === index ? 'h5' : 'body2'}
                                className={selected === index ? classes.text : clsx(classes.text, classes.textInactive)}>
                        {option}
                    </Typography>
                </Button>
            })}
        </ButtonGroup>
    );
}

SwitchButtonGroup.propTypes = {
    options: PropTypes.array.isRequired,
    onOptionClicked: PropTypes.func.isRequired,
    initialSelected: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.number,
};

SwitchButtonGroup.defaultProps = {
    initialSelected: 0,
    color: null,
};

export default SwitchButtonGroup;