import * as React from "react"

function MeetingRoomSvgIcon(props) {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                transform="translate(6.5 6)"
                stroke={props.stroke || '#333'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <circle cx={6} cy={4} r={4} />
                <path d="M0 19v-4c0-2.21 1.79-4 4-4h2" />
                <circle cx={21} cy={4} r={4} />
                <path d="M21 11h2c2.21 0 4 1.79 4 4v4h0" />
                <circle cx={13.5} cy={12.5} r={4} />
                <path d="M7.5 27.5v-4c0-2.21 1.79-4 4-4h4c2.21 0 4 1.79 4 4v4h0" />
            </g>
        </svg>
    )
}

export default MeetingRoomSvgIcon