import React from 'react';

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import InitialPassword from "./InitialPassword.js";
import GoconutSvgLogo from "../../../common/icons/GoconutSvgLogo.js";


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: 'auto',
        boxSizing: 'border-box',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.outerGap,
        maxWidth: theme.gridSizes.maxWidth,
        padding: theme.paddingContentContainer.padding,
    }
}));

function InitialPasswordContainer(props) {
    const theme = useTheme();
    const classes = useStyle(theme);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <GoconutSvgLogo width={250}/>
                <InitialPassword/>
            </div>
        </div>
    );
}

InitialPasswordContainer.propTypes = {};

InitialPasswordContainer.defaultProps = {};

export default InitialPasswordContainer;