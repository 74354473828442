import i18n from 'i18next';

const initState = {
    actionFeedback: null,
};

export function actionFeedbackReducer(state = initState, action) {
    switch (action.type) {

        case 'ASSIGN_WORKPLACE_TO_PERSON_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('workplace_booking_successful')
            }

        case 'UPDATE_WORKPLACE_ASSIGNMENT_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('update_workplace_booking_successful')
            }

        case 'UPDATE_WORKPLACES_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('general.updated.successfully')
            }

        case 'ASSIGN_MEETING_TO_PERSON_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('meeting_booking_successful')
            }

        case 'CHANGE_PASSWORD_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('change_password_successful')
            }

        case 'REMOVE_PERSON_FROM_GROUP_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('leave_workinggroup_successful')
            }

        case 'UPDATE_USER_FULFILLED':
        case 'ADMIN_UPDATE_USER_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('update_user_successful')
            }

        case 'RATE_WORKPLACE_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('rate_workplace_successful')
            }

        case 'DELETE_WORKPLACE_ASSIGNMENT_FULFILLED':
        case 'DELETE_MEETING_ASSIGNMENT_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('delete_assignment_successful')
            }

        case 'SAVE_FLOOR_GEOMETRIES_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('save_geometries_successfull')
            }

        case 'UPLOAD_IMAGE_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('upload_floorplan_successfull')
            }

        case 'CREATE_USER_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('create_user_successfull')
            }

        case 'DELETE_USER_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('delete_user_successfull')
            }

        case 'UPDATE_MEETING_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('update_meeting_successfull')
            }

        case 'LOGIN_WITH_SLACK_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('sign_in_thirdparty_successfull')
            }

        case 'THIRD_PARTY_USER_LOGOUT_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('revoke_thirdparty_successfull')
            }
        case 'IMPORT_USERS_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('user_import_successfull')
            }
        case 'SET_WORKING_HOURS_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('set_workinghours')
            }

        case 'CREATE_VACATION_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('vacation_booked_successfully')
            }

        case 'ADD_CALENDAR_TO_SPACE_FULFILLED':
            return {
                ...state,
                actionFeedback: i18n.t('floorInspector.space.add.calendar.fulfilled')
            }

        case 'DELETE_RESOURCE_ASSIGNMENT_FULFILLED': {
            return {
                ...state,
                actionFeedback: i18n.t('delete_assignment_successful')
            }
        }

        case 'ASSIGN_RESOURCE_TO_USER_FULFILLED': {
            return {
                ...state,
                actionFeedback: i18n.t('resources.assignment.add.fulfilled')
            }
        }


        case 'RESET_ACTION_FEEDBACK':
            return {
                ...state,
                actionFeedback: null
            }

        default:
            return state
    }
}