const initialState = {
    spaces: [],
    selectedSpace: null
}

function spaceReducer(state = initialState, action) {

    switch (action.type) {

        case 'CREATE_SPACES_FULFILLED':
            return {
                ...state,
                spaces: [...state.spaces, action.payload.data],
            }

        case 'SET_SELECTED_SPACE':
            return {
                ...state,
                selectedSpace: action.payload,
            }

        case 'GET_FLOOR_BY_SPACE_ID_FULFILLED': {
            let loadedSpace
            action.payload.data.spaces.forEach(space => {
                if (Number(space.id) === Number(action.meta.spaceId)) {
                    loadedSpace = space
                }
            })

            return {
                ...state,
                selectedSpace: loadedSpace
            }
        }

        default:
            return state

    }

}

export default spaceReducer
