import React from 'react';

import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import ActivitySelectionGroup from "../Activities/ActivitySelectionGroup";
import CustomButton from "../CustomButton.js";

import {getTimePeriodAtDate} from "../../common/utils/TimeUtils";
import {assignWorkplaceToPerson, setSelectedActivity} from "../../actions/workplaceAssignment-action";
import {navigate, PATH_BOOKINGS} from "../../common/utils/NavigationUtils";
import {TYPE_OF_BOOKING_RANDOM} from "../../common/utils/NameUtils";


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2.5rem',
        justifyContent: 'space-between',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap
    }
}));

function WorkplaceBookingByActivity({
                                        timePeriod,
                                        selectedDate,
                                        selectedActivity,
                                        setSelectedActivity,
                                        userId,
                                        bookWorkplace,
                                        t,
                                        assignWorkplaceToPersonPending,
                                        confirmationButtonsDisabled
                                    }) {
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyle(theme);

    function bookSeatButtonClicked() {
        let timePeriodAtDate = getTimePeriodAtDate(timePeriod, selectedDate)
        const assignment = {
            timePeriod: timePeriodAtDate,
            activity: selectedActivity,
            typeOfWorkplaceBooking: TYPE_OF_BOOKING_RANDOM,
        }

        //reset activity on booking
        bookWorkplace(userId, assignment)
            .then(() => {
                setSelectedActivity(null)
                navigate(history, PATH_BOOKINGS)
            })
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ActivitySelectionGroup initialSelected={selectedActivity}
                                        onChange={(activity) => setSelectedActivity(activity)}/>

            </div>
            <CustomButton text={t('book')}
                          onClick={bookSeatButtonClicked}
                          fullWidth
                          primary
                          disabled={!selectedActivity || confirmationButtonsDisabled}
                          isLoading={assignWorkplaceToPersonPending}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        timePeriod: state.assignmentBuilder.timePeriod,
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedActivity: state.workplace.selectedActivity,
        assignWorkplaceToPersonPending: state.workplace.assignWorkplaceToPersonPending,
    }
};

const mapDispatchToProps = {
    bookWorkplace: assignWorkplaceToPerson,
    setSelectedActivity: setSelectedActivity,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WorkplaceBookingByActivity));