export function setElement(value) {
    return {
        type: 'SET_ELEMENT',
        payload: value
    }
}

export function setModalResult(value) {
    return {
        type: 'SET_MODALRESULT',
        payload: value
    }
}


