import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {makeStyles, useTheme} from "@material-ui/core";

import WorkplaceSelectionViaList from "./WorkplaceSelectionViaList";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import ListSvgIcon from "../../common/icons/ListSvgIcon";
import LocationSvgIcon from "../../common/icons/LocationSvgIcon";
import {navigate, PATH_CHOOSE_SEAT} from "../../common/utils/NavigationUtils";
import SwitchButtonGroup from "../SwitchButtonGroup.js";
import {useHistory, useParams} from "react-router-dom";
import {getFloorBySpaceId, getFloorByWorkplaceId} from "../../actions/floors-actions.js";
import FloorplanWithWorkplaces from "../floorplan/FloorplanWithWorkplaces.js";
import WorkplaceAndSpaceSelectionSpreadsheet from "../selectionSpreadsheet/WorkplaceAndSpaceSelectionSpreadsheet.js";
import {setSelectedDate} from "../../actions/workplaceAssignment-action.js";

const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100%',
        flex: 1,
        display: 'flex',
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 190px)',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        maxWidth: theme.gridSizes.maxWidth,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '18px',
    },
    selectedIcon: {
        backgroundColor: theme.colors.palette.corporate.skinMain,
        stroke: theme.colors.palette.neutral.white,
        borderRadius: '9px'
    },
    switch: {
        maxWidth: '8.8rem',
        maxHeight: '5rem',
    },
    icon: {
        stroke: theme.colors.palette.neutral.greyMain
    },
}))

function WorkplaceSelection({
                                userId,
                                selectedFloor,
                                getFloorByWorkplaceId,
                                getFloorBySpaceId,
                                setSelectedDate,
                                ...props
                            }) {
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);
    const {workplaceId, spaceId} = useParams()
    const {t} = useTranslation();

    const [listMode, setListMode] = useState(false);

    //get the floor of the selected workplace from id in url
    useEffect(() => {
        if (workplaceId) {
            getFloorByWorkplaceId(workplaceId)
            setSelectedDate(new Date())
        } else if (spaceId) {
            getFloorBySpaceId(spaceId)
            setSelectedDate(new Date())
        } else if (!selectedFloor) {
            routeBack()
        }
        //eslint-disable-next-line
    }, []);

    function routeBack() {
        navigate(history, PATH_CHOOSE_SEAT)
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item sm={12} className={classes.container}>
                <div className={classes.header}>
                    <HeaderWithTitleAndBackButton backLabel={t("workplace_details")}
                                                  onNavigateBack={routeBack} title={''}/>
                    <SwitchButtonGroup
                        options={[<ListSvgIcon
                            className={listMode ? classes.selectedIcon : classes.icon}/>,
                            <LocationSvgIcon
                                className={!listMode ? classes.selectedIcon : classes.icon}/>]}
                        onOptionClicked={(value) => setListMode(!value)}
                        initialSelected={listMode ? 0 : 1}
                        className={classes.switch}/>
                </div>
                <div className={classes.content}>
                    {listMode
                        ? <WorkplaceSelectionViaList/>
                        : <FloorplanWithWorkplaces centerInitially showMeetingRooms/>
                    }
                </div>
            </Grid>
            <WorkplaceAndSpaceSelectionSpreadsheet/>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        selectedFloor: state.floors.floor,
    }
}

const mapDispatchToProps = {
    getFloorByWorkplaceId: getFloorByWorkplaceId,
    getFloorBySpaceId: getFloorBySpaceId,
    setSelectedDate: setSelectedDate
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSelection)