import React, {useRef, useState} from 'react';
import PropTypes from "prop-types";

import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography, useTheme} from "@material-ui/core";
import AdminButton from "../../common/elements/AdminButton.js";
import ModalContainer from "../../common/elements/ModalWrapper.js";
import QRCodeCategoryWithTitle from "./QRCodeCategoryWithTitle.js";
import QRCodesToExportSectionWithName from "./QRCodesToExportSectionWithName.js";

import {TYPE_OF_USE_MEETING} from "../../common/utils/NameUtils.js";
import QRCodeExportModalItem from "./QRCodeExportModalItem.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.innerGap,
        flexDirection: 'column'
    },
    button: {
        maxHeight: '3.5rem !important',
        width: '16.4rem !important'
    },

}));

export function getQrCodeExportContentBuilding(building) {
    if (!building)
        return []

    let elements = [<Typography variant={'h5'}>{building.name}</Typography>]

    building.floors.forEach(floor => {
        const floorElements = getQrCodeExportContentFloor(floor)
        if (floorElements.length > 1)
            elements.push(...floorElements)
    })

    return elements
}

export function getQrCodeExportContentFloor(floor) {
    if (!floor)
        return []
    let elements = [<Typography variant={'h5'}>{floor.name}</Typography>]
    let meetingRooms = floor.spaces.filter(space => space.typeOfUse === TYPE_OF_USE_MEETING)
    const maxQRCodesPerPage = 16;

    if (meetingRooms && meetingRooms.length) {
        elements.push(
            <QRCodeCategoryWithTitle title={'meeting_rooms'}
                                     content={<QRCodesToExportSectionWithName elements={meetingRooms}/>}/>)
    }

    floor.spaces.filter(space => space.typeOfUse !== TYPE_OF_USE_MEETING && space.workplaces.length)
        .forEach((space, spaceIndex) => {
            for (let i = 0; i < space.workplaces.length; i += maxQRCodesPerPage) {
                const workplacesForOnePage = space.workplaces.slice(i, i + maxQRCodesPerPage);
                if (elements.length === 2 && spaceIndex === 0 && i === 0) {
                    elements.push(
                        <QRCodeCategoryWithTitle title={'workplaces'}
                                                 content={<QRCodesToExportSectionWithName
                                                     elements={workplacesForOnePage}
                                                     title={space.name}/>}/>
                    )
                } else {
                    elements.push(<QRCodesToExportSectionWithName elements={workplacesForOnePage}
                                                                  title={space.name}/>)
                }
            }
        })
    return elements
}

function QRCodeExportModal({contents, onCloseModal, open}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);
    const [downloadPending, setDownloadPending] = useState(false);
    const printRefs = useRef([])

    const handleDownloadPdf = async () => {
        setDownloadPending(true)
        const pdf = new JsPDF('p', 'pt', 'a4');

        const pageMargin = 40;
        const imageGap = 10;
        const pdfWidth = pdf.internal.pageSize.getWidth() - (pageMargin * 2);
        const pdfHeight = pdf.internal.pageSize.getHeight() - (pageMargin * 2);
        let currentYPosition = pageMargin

        for (const printRef of printRefs.current) {
            const element = printRef.current;
            const canvas = await html2canvas(element, {scale: 4,});
            const data = canvas.toDataURL('image/jpeg', .7);

            const imgProperties = pdf.getImageProperties(data);
            const imageHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

            if (currentYPosition + imageHeight > pdfHeight) {
                pdf.addPage()
                currentYPosition = pageMargin
            }

            pdf.addImage(data, 'PNG', pageMargin, currentYPosition, pdfWidth, imageHeight);
            currentYPosition += imageHeight + imageGap
        }

        await pdf.save('QR_Codes.pdf', {returnPromise: true})
            .then(() => {
                setDownloadPending(false)
            });
    };

    function addPrintRef(printRef) {
        printRefs.current.push(printRef)
    }

    return (
        <ModalContainer onClose={onCloseModal}
                        open={open}
                        headerActions={<AdminButton text={t('download')}
                                                    disabled={downloadPending}
                                                    isLoading={downloadPending}
                                                    className={classes.button}
                                                    primary
                                                    onClick={handleDownloadPdf}/>}>
            <div className={classes.root}>
                {contents.map((item, index) => (
                    <QRCodeExportModalItem content={item} callBackFunc={addPrintRef} key={index}/>
                ))}
            </div>
        </ModalContainer>
    );
}

QRCodeExportModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    contents: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
}

export default QRCodeExportModal