import * as React from 'react';
import {LinearProgress, makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
    root: {
        borderRadius: 5,
        '& .MuiLinearProgress-colorPrimary': {
            borderRadius: '.3rem',
            backgroundColor: theme.colors.palette.neutral.white,
            borderLeft: '.1rem solid ' + theme.colors.palette.corporate.skinDark,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            borderRadius: '.3rem',
            border: '.1rem solid ' + theme.colors.palette.corporate.skinDark,
            backgroundColor: theme.colors.palette.corporate.skinMain,
            boxShadow: '0 1px 4px 0 rgba(255, 152, 92, 1)'
        },
        '& .MuiLinearProgress-root': {
            height: '.6rem',
        },
    }
}))

function ProgressBar({value}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={value}/>
        </div>
    );
}

ProgressBar.propTypes = {
    value: PropTypes.number.isRequired
}

export default ProgressBar