const initialState = {
    terminal: null,
    announcements: [],
    statistics: new Map(),
    buildings: [],
    floor: {},
    floorplan: null,
    assignments: [],
    selectedTerminal: null,
    filteredPersonAssignments: [],
    filteredMeetings: [],
    filteredPersonAssignmentsPending: false,
    filteredPersonAssignmentsPaginationToken: 0,
    filteredMeetingsPending: false,
    filteredMeetingsPaginationToken: 0,
}

function terminalReducer(state = initialState, action) {

    switch (action.type) {

        case 'TERMINAL_GET_TERMINAL_FULFILLED': {
            return {
                ...state,
                terminal: action.payload.data,
            }
        }

        case 'TERMINAL_GET_TERMINAL_REJECTED': {
            return {
                ...state,
                terminal: null,
            }

        }

        case 'TERMINAL_GET_ANNOUNCEMENTS_FULFILLED': {
            return {
                ...state,
                announcements: action.payload.data,
            }
        }

        case 'TERMINAL_GET_STATISTICS_FULFILLED': {
            return {
                ...state,
                statistics: action.payload.data
            }
        }

        case 'TERMINAL_GET_BUILDING_NAMES_FULFILLED': {
            return {
                ...state,
                buildings: action.payload.data,
            }
        }

        case 'TERMINAL_GET_FLOOR_FULFILLED': {
            return {
                ...state,
                floor: action.payload.data,
            }
        }

        case 'TERMINAL_GET_FLOOR_PLAN_FULFILLED': {

            let floorplan = action.payload.data

            floorplan.image = 'data:image/png;base64,' + floorplan.image

            return {
                ...state,
                floorplan: floorplan
            }
        }

        case 'TERMINAL_GET_FLOOR_ASSIGNMENTS_FULFILLED': {
            return {
                ...state,
                assignments: action.payload.data.data,
            }
        }

        case 'TERMINAL_GET_PERSONS_ASSIGNMENTS_FILTERED_PENDING': {
            return {
                ...state,
                filteredPersonAssignmentsPending: true
            }
        }

        case 'TERMINAL_GET_PERSONS_ASSIGNMENTS_FILTERED_FULFILLED': {
            if (action.meta.resetPaginationToken === true || (action.payload.data.paginationToken !== null && action.payload.data.paginationToken <= 1)) {
                return {
                    ...state,
                    filteredPersonAssignments: action.payload.data.personAssignmentsMap,
                    filteredPersonAssignmentsPaginationToken: action.payload.data.paginationToken === null ? null : parseInt(action.payload.data.paginationToken),
                    filteredPersonAssignmentsPending: false
                }
            }
            return {
                ...state,
                filteredPersonAssignments: Object.assign(state.filteredPersonAssignments, action.payload.data.personAssignmentsMap),
                filteredPersonAssignmentsPaginationToken: action.payload.data.paginationToken,
                filteredPersonAssignmentsPending: false
            }
        }
        case 'TERMINAL_GET_PERSONS_ASSIGNMENTS_FILTERED_REJECTED': {
            return {
                ...state,
                filteredPersonAssignments: [],
                filteredPersonAssignmentsPaginationToken: null,
                filteredPersonAssignmentsPending: false
            }
        }

        case 'TERMINAL_GET_MEETINGS_FILTERED_PENDING': {
            return {
                ...state,
                filteredMeetingsPending: true
            }
        }

        case 'TERMINAL_GET_MEETINGS_FILTERED_FULFILLED': {
            return {
                ...state,
                filteredMeetings: action.payload.data.assignments,
                filteredMeetingsPaginationToken: action.payload.data.paginationToken,
                filteredMeetingsPending: false
            }
        }
        case 'TERMINAL_GET_MEETINGS_FILTERED_REJECTED': {
            return {
                ...state,
                filteredMeetings: [],
                filteredMeetingsPaginationToken: null,
                filteredMeetingsPending: false
            }
        }

        case 'TERMINAL_SET_SELECTED':
            return {
                ...state,
                selectedTerminal: action.payload,
            }

        default:
            return {...state}
    }
}

export default terminalReducer