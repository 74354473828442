import React, {useRef} from "react";

import {compose} from "redux";
import {withTranslation} from "react-i18next";

import {withStyles} from "@material-ui/core";
import {BottomSheet} from "react-spring-bottom-sheet";
import PropTypes from "prop-types";
import "../css/bottomSpreadsheet.css";
import useDidMountEffect from "../customHooks/useDidMountEffect.js";

const styles = theme => ({})

function BottomSpreadsheet({
                               classes,
                               t,
                               header,
                               content,
                               footer,
                               isOpen,
                               transformToSmall,
                               onDismissCallback,
                               ...props
                           }) {
    const sheetRef = useRef()

    const small = 117
    const medium = 310
    const big = window.innerHeight - 90

    useDidMountEffect(() => {
        if (transformToSmall)
            sheetRef.current.snapTo(() => small)
        else
            sheetRef.current.snapTo(() => medium)
    }, [transformToSmall])

    function onDismiss() {
        if (onDismissCallback) {
            onDismissCallback()
        }
    }

    return (
        <BottomSheet open={isOpen}
                     ref={sheetRef}
                     skipInitialTransition={true}
                     blocking={false}
                     onDismiss={onDismiss}
                     defaultSnap={!transformToSmall ? medium : small}
                     snapPoints={() => [
                         small, medium, big
                     ]}
                     header={
                         header && React.cloneElement(header)
                     }
                     footer={
                         footer && React.cloneElement(footer)
                     }
                     className={classes.root}>
            {/*min height so spreadsheet is displayed even if content doesn't render anything*/}
            <div style={{minHeight: '1px'}} data-body-scroll-lock-ignore="true">
                {content && React.cloneElement(content)}
            </div>
        </BottomSheet>
    )
}

BottomSpreadsheet.propTypes = {
    header: PropTypes.element,
    content: PropTypes.element.isRequired,
    footer: PropTypes.element,
    transformToSmall: PropTypes.bool,
    onDismissCallback: PropTypes.func,
}

export default compose(withStyles(styles), withTranslation())(BottomSpreadsheet)