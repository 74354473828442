import * as THREE from 'three'
import {Mesh} from 'three'
import {getMaterial, materialTypes} from "../drawingTool/three/common/materials";
import {setCameraInitialZoom} from "./Camera";

export function addOrUpdateBackgroundImage(scene, image, imageScale, mount, camera, initialZoom, orbitControls) {
    const loader = new THREE.TextureLoader();
    const material = getMaterial(materialTypes.background).clone()

    material.map = loader.load(image, () => {
        const width = material.map.image && material.map.image.width > 0 ? material.map.image.width : 1
        const height = material.map.image && material.map.image.height > 0 ? material.map.image.height : 1

        const scaleWidth = (width > height ? 1 : width / height) * imageScale
        const scaleHeight = (height > width ? 1 : height / width) * imageScale

        let background = scene.children.find(child => child.name === 'background')

        if (background) {
            background.geometry.dispose()
            background.geometry = new THREE.PlaneGeometry(100 * scaleWidth, 100 * scaleHeight)
            background.material = material
            background.position.set(0, 0, -1)
        } else {
            const geometry = new THREE.PlaneGeometry(100 * scaleWidth, 100 * scaleHeight);

            background = new Mesh(geometry, material)
            background.name = 'background'
            background.position.set(0, 0, -1)

            scene.add(background)
        }

        return setCameraInitialZoom(mount, camera, scene, initialZoom, background, orbitControls)
    })
}