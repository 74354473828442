import React, {useMemo, useState} from 'react';

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useTranslation} from "react-i18next";
import ModalWrapper from "../../common/elements/ModalWrapper.js";
import CustomizedSwitchWithLabel from "../../common/elements/CustomizedSwitchWithLabel.js";
import moment from "moment";
import {getTodayAtDefaultEndOfWorkingDay, getTodayAtDefaultStartOfWorkingDay} from "../../common/utils/TimeUtils.js";
import OccupancyTimeExplenation from "./OccupancyTimeExplenation.js";
import CustomTimePicker from "../../common/elements/CustomTimePicker.js";
import {STORAGE_OCCUPANCY_TIME} from "../../common/utils/NameUtils.js";
import ClickableText from "../../common/elements/ClickableText.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: theme.outerGap
    },
    inline: {
        display: 'flex',
        justifyContent: 'space-evenly',
        gap: theme.innerGap
    }
}))

function OccupancyTimeSettingsModal({
                                        open,
                                        onClose,
                                    }) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);

    const [is24hActive, setIs24hActive] = useState(false)
    const [timeperiod, setTimeperiod] = useState(localStorage.getItem('occupancyTimeSettings')
        ? JSON.parse(localStorage.getItem('occupancyTimeSettings'))
        : {startDate: getTodayAtDefaultStartOfWorkingDay(), endDate: getTodayAtDefaultEndOfWorkingDay()}
    )

    function onSave() {
        localStorage.setItem('occupancyTimeSettings', JSON.stringify(timeperiod))
        window.dispatchEvent(new Event(STORAGE_OCCUPANCY_TIME));
    }

    function onChange24h(selectedState) {
        setIs24hActive(selectedState)
        if (selectedState) {
            setTimeperiod({startDate: moment().startOf('day'), endDate: moment().endOf('day')})
        }
    }

    const error = useMemo(() => {
        const start = moment(timeperiod.startDate)
        const end = moment(timeperiod.endDate)
        return start.hour() > end.hour() || (start.hour() === end.hour() && start.minutes() > end.minutes())
        //eslint-disable-next-line
    }, [timeperiod])

    return (
        <ModalWrapper open={open}
                      onClose={onClose}
                      headerActions={<ClickableText text={t('save')}
                                                    onClick={() => {
                                                        onSave()
                                                        onClose()
                                                    }}
                                                    disabled={error}/>}>
            <div className={classes.root}>
                <CustomizedSwitchWithLabel onChange={() => onChange24h(!is24hActive)} checked={is24hActive}
                                           label={t('24_h')}/>
                <Typography variant={'caption'}>{t('occupancy_time_info')}</Typography>
                <div className={classes.inline}>
                    <CustomTimePicker onSubmit={time => setTimeperiod({startDate: time, endDate: timeperiod.endDate})}
                                      submitOnChange
                                      label={t('from')}
                                      disabled={is24hActive}
                                      autoOk
                                      error={error}
                                      initialDate={moment(timeperiod.startDate)}/>

                    <CustomTimePicker onSubmit={time => setTimeperiod({startDate: timeperiod.startDate, endDate: time})}
                                      submitOnChange
                                      label={t('to')}
                                      disabled={is24hActive}
                                      autoOk
                                      error={error}
                                      initialDate={moment(timeperiod.endDate)}/>
                </div>

                <OccupancyTimeExplenation startTime={timeperiod.startDate} endTime={timeperiod.endDate}/>
            </div>
        </ModalWrapper>
    )
}

export default OccupancyTimeSettingsModal