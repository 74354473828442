import {OrthographicCamera} from 'three'

export function getOrthographicCamera(mount) {
    const {clientWidth, clientHeight} = mount

    const camera = new OrthographicCamera(
        clientWidth / -16,
        clientWidth / 16,
        clientHeight / 16,
        clientHeight / -16,
        -1,
        100
    )

    camera.enableRotate = false
    camera.position.z = 20

    return camera
}

export function setCameraInitialZoom(mount, camera, scene, initialZoom, mesh, orbitControls) {
    if (!mount.current)
        return

    const {clientWidth, clientHeight} = mount.current

    const widthThree = mesh.geometry.parameters.width
    const heightThree = mesh.geometry.parameters.height

    let cameraZoom = 1

    if (widthThree > 0 && heightThree > 0) {
        if (widthThree / heightThree > clientWidth / clientHeight) {
            const screenHeightThree = clientWidth / 10

            cameraZoom = (screenHeightThree / (widthThree * 1.1)) * 1.25
        } else {
            const screenWidthThree = clientHeight / 10

            cameraZoom = (screenWidthThree / (heightThree * 1.1)) * 1.25
        }
    }
    initialZoom.current = cameraZoom
    orbitControls.minZoom = cameraZoom
    camera.zoom = initialZoom.current
    camera.updateProjectionMatrix()
    return initialZoom.current
}