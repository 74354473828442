import * as React from 'react'

const DrawWallSvgIcon = props =>
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fillRule="nonzero" stroke={props.stroke} fill="none">
            <path
                d="M2.5 7.5h19M12 3v4.5M12 12v4.5M16.5 7.5V12M16.5 16.5V21M7.5 7.5V12M7.5 16.5V21M2.5 12h19M2.5 16.5h19"/>
            <rect x={2.5} y={3} width={19} height={18} rx={1}/>
        </g>
    </svg>

export default DrawWallSvgIcon