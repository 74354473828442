import React from 'react';
import PropTypes from 'prop-types';

import {isEmpty} from "lodash";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import CustomTextField from "../../CustomTextField.js";
import AdminButton from "../../../common/elements/AdminButton.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
    },
    form: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    innerForm: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap,
    }
}));

function TerminalInspectorContent({element, onSave, update, autofocus}) {

    const theme = useTheme();
    const {t} = useTranslation()
    const classes = useStyle(theme);

    const formik = useFormik({
        initialValues: {
            name: element?.name || '',
            accessCode: element?.accessCode || '-',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            if (typeof onSave === "function")
                onSave(values)
        },
    })

    return (
        <div className={classes.root}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <div className={classes.innerForm}>
                    <CustomTextField
                        title={t('name')}
                        name={'name'}
                        autoFocus={autofocus}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    <CustomTextField
                        title={t('terminal.accessCode')}
                        name={'accessCode'}
                        readOnly={true}
                        value={formik.values.accessCode}
                        onChange={formik.handleChange}
                    />
                </div>
                <AdminButton disabled={isEmpty(formik.values.name)} primary fullWidth
                             text={update ? t('terminal.update') : t('add')} type={'submit'}
                />
            </form>
        </div>
    );
}

TerminalInspectorContent.propTypes = {
    element: PropTypes.object,
    onSave: PropTypes.func,
    autofocus: PropTypes.bool,
};

TerminalInspectorContent.defaultProps = {
    autofocus: true
};

export default TerminalInspectorContent;