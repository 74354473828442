import React, {useRef, useState} from 'react';

import clsx from "clsx";
import moment from "moment";
import {isNull} from "lodash";
import {connect} from "react-redux";
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Divider from "@material-ui/core/Divider";
import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

import OccupancyFilterSearch from "./OccupancyFilterSearch.js";
import EmptyStateColleagueBookings from "./EmptyStateColleagueBookings.js";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";

import HidePasswordSvgIcon from "../../common/icons/HidePasswordSvgIcon.js";

import {setFloor} from "../../actions/floors-actions";
import {selectFavoriteWorkplace} from "../../actions/workplaceAssignment-action";
import {getWorkplaceAssignmentsStatistics,} from "../../actions/occupancy-actions";

import {getTypeOfWorkplaceSymbol, isRemote} from "../../common/utils/AssignmentUtils.js";
import {TYPE_OF_BOOKING_VACATION} from "../../common/utils/NameUtils.js";

import {getCoworkersWithAssignmentAtDateFiltered} from "../../actions/user-action.js";
import ActionListItemAccordion from "../../common/elements/ActionListItemAccordion";
import ActionListItem from "../../common/elements/ActionListItem.js";
import InfiniteLoaderList from "../../common/elements/InfiniteLoaderList";
import {getTimePeriodAsString} from "../../common/utils/TimeUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap
    },
    list: {
        flex: 1,
        height: '100%',
        boxSizing: 'border-box',
    },
    emptyState: {
        width: '100%',
    },
    icon: {
        width: '2.4rem',
        height: '2.4rem',
    },
    smallIcon: {
        width: '1.8rem',
        height: '1.8rem',
    },
    grey: {
        color: theme.colors.palette.neutral.greyMain,
        stroke: theme.colors.palette.neutral.greyMain,
    },
    iconWithNumber: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

function CurrentOccupancyListComponent({
                                           userId,
                                           selectedDate,
                                           getWorkplaceAssignmentsStatistics,
                                           selectFavoriteWorkplace,
                                           setFloor,
                                           onSelectAssignmentCallback,
                                           totalWorkplaces,
                                           totalWorkplaceAssignments,
                                           totalHomeofficeAssignments,
                                           totalOutOfOfficeAssignments,
                                           notSharedWorkplaceAssignments,
                                           totalVacation,
                                           getCoworkersWithAssignmentAtDateFiltered,
                                           filteredCoworkersWithAssignments,
                                           filteredCoworkersWithAssignmentsToken,
                                           coworkersPending,
                                       }) {
    const theme = useTheme();
    const history = useHistory()
    const classes = useStyle(theme)
    const {t} = useTranslation();

    const [searchString, setSearchString] = useState('');
    const timer = useRef(0);

    const showOffice = queryString.parse(history.location.search).showOffice
    const showHomeoffice = queryString.parse(history.location.search).showHomeoffice
    const showOutOfOffice = queryString.parse(history.location.search).showOutOfOffice
    const showVacation = queryString.parse(history.location.search).showVacation

    const [showSearchbar, setShowSearchbar] = useState(false);
    const [showOfficeAssignments, setShowOfficeAssignments] = useState(showOffice === 'true');
    const [showHomeofficeAssignments, setShowHomeofficeAssignments] = useState(showHomeoffice === 'true');
    const [showOutOfOfficeAssignments, setShowOutOfOfficeAssignments] = useState(showOutOfOffice === 'true');
    const [showVacationAssignments, setShowVacationAssignments] = useState(showVacation === 'true');

    useDidMountEffect(() => {
        if (!userId)
            return

        getWorkplaceAssignmentsStatistics(userId, selectedDate)
        loadNextPage(true, true)
        // eslint-disable-next-line
    }, [userId, selectedDate]);

    useDidMountEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
            loadNextPage(true, true)
        }, 500)
        // eslint-disable-next-line
    }, [searchString]);

    useDidMountEffect(() => {
        if (showOfficeAssignments && showHomeofficeAssignments && showOutOfOfficeAssignments && showVacationAssignments) {
            setShowOfficeAssignments(false)
            setShowHomeofficeAssignments(false)
            setShowOutOfOfficeAssignments(false)
            setShowVacationAssignments(false)
        }

        loadNextPage(true, true)
        // eslint-disable-next-line
    }, [showOfficeAssignments, showHomeofficeAssignments, showOutOfOfficeAssignments, showVacationAssignments]);

    function loadNextPage(resetPaginationToken, forceReload) {
        if (!userId || (!!coworkersPending && !forceReload))
            return

        let startDate = moment(selectedDate).startOf('day').utc().format()
        let endDate = moment(selectedDate).endOf('day').utc().format()

        if (searchString) {
            if (resetPaginationToken === true || filteredCoworkersWithAssignmentsToken !== null) {
                getCoworkersWithAssignmentAtDateFiltered(userId, startDate, endDate, searchString, filteredCoworkersWithAssignmentsToken, resetPaginationToken)

            }
        } else {
            if (resetPaginationToken === true || filteredCoworkersWithAssignmentsToken !== null) {
                if (!showOfficeAssignments && !showHomeofficeAssignments && !showOutOfOfficeAssignments && !showVacationAssignments) {
                    getCoworkersWithAssignmentAtDateFiltered(userId, startDate, endDate, searchString, filteredCoworkersWithAssignmentsToken, resetPaginationToken)

                } else {
                    getCoworkersWithAssignmentAtDateFiltered(userId, startDate, endDate, searchString, filteredCoworkersWithAssignmentsToken, resetPaginationToken, showOfficeAssignments, showHomeofficeAssignments, showOutOfOfficeAssignments, showVacationAssignments)

                }
            }
        }
    }

    function onSelectAssignment(assignment) {
        let floorId = assignment?.workplace?.floorId

        //TODO: try to clean this up, double setFloor prevents wrong loading from WorkplaceAndSpaceSpreadSheet
        if (floorId) {
            setFloor({id: floorId, name: assignment.floorName, workplaces: [assignment.workplace]})
            selectFavoriteWorkplace(assignment.workplace)
        } else {
            console.log('Could not find floorId!')
        }

        onSelectAssignmentCallback()
    }

    function getItem([, assignments]) {
        const person = assignments[0].person
        const workplace = assignments[0].workplace

        return (
            <ActionListItemAccordion startAdornment={<AvatarWithOccupancy assignments={assignments}
                                                                          showOccupancy
                                                                          person={person}
                                                                          workplace={workplace}
                                                                          size={48}/>}
                                     key={person.id}
                                     text={person.name + " " + person.surname}>
                <div style={{width: '100%'}}>
                    {assignments.map((assignment, index) => {
                            return <ActionListItem startAdornment={getTypeOfWorkplaceSymbol(assignment.workplace)}
                                                   key={assignment.id}
                                                   text={getTimePeriodAsString(assignment.timePeriod)}
                                                   action={() => {
                                                       if (isRemote(assignment) || assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION)
                                                           return
                                                       onSelectAssignment(assignment)
                                                   }}
                                                   startAdornmentWidth={32}
                                                   stretchDivider={index === assignments.length - 1}
                                                   height={50}
                                                   hideArrow={isRemote(assignment) || assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION}
                                                   saveArrowSpace/>
                        }
                    )}
                </div>
            </ActionListItemAccordion>)
    }

    function sortOccupancy(occupancyArray) {
        return occupancyArray.sort((a, b) => {
            if (a[1][0].person.name < b[1][0].person.name) return -1;
            if (a[1][0].person.name > b[1][0].person.name) return 1;
            return 0;
        })
    }

    return (
        <div className={classes.root}>

            <OccupancyFilterSearch showSearchbar={showSearchbar} handleShowSearchbar={setShowSearchbar}
                                   searchString={searchString} onSearchChange={setSearchString}
                                   totalWorkplaces={totalWorkplaces}
                                   totalWorkplaceAssignments={totalWorkplaceAssignments}
                                   totalHomeofficeAssignments={totalHomeofficeAssignments}
                                   totalOutOfOfficeAssignments={totalOutOfOfficeAssignments}
                                   totalVacationAssignments={totalVacation}
                                   filter={{
                                       officeAssignments: {
                                           state: showOfficeAssignments,
                                           onChange: setShowOfficeAssignments,
                                       },
                                       homeOffice: {
                                           state: showHomeofficeAssignments,
                                           onChange: setShowHomeofficeAssignments
                                       },
                                       outOfOffice: {
                                           state: showOutOfOfficeAssignments,
                                           onChange: setShowOutOfOfficeAssignments
                                       },
                                       vacation: {
                                           state: showVacationAssignments,
                                           onChange: setShowVacationAssignments
                                       }
                                   }}
            />

            <div className={classes.iconWithNumber}>
                <HidePasswordSvgIcon className={clsx(classes.grey, classes.smallIcon)}/>
                <Typography variant={'caption'}>&nbsp;{t('not_visible_for_anyone')}:</Typography>
                <Typography variant={'caption'}>&nbsp;{notSharedWorkplaceAssignments}</Typography>
            </div>

            <div className={classes.list}>
                <Divider style={{width: '100%'}}/>
                <InfiniteLoaderList hasNextPage={!isNull(filteredCoworkersWithAssignmentsToken)}
                                    withDynamicHeightsItems
                                    emptyState={<EmptyStateColleagueBookings/>}
                                    isNextPageLoading={!!coworkersPending}
                                    items={sortOccupancy(Object.entries(filteredCoworkersWithAssignments))}
                                    loadNextPage={loadNextPage}
                                    listItem={getItem}/>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        selectedDate: state.assignmentBuilder.selectedDate,

        filteredCoworkersWithAssignmentsToken: state.user.filteredCoworkersWithAssignmentsToken,
        filteredCoworkersWithAssignments: state.user.filteredCoworkersWithAssignments,
        coworkersPending: state.user.coworkersPending,

        totalWorkplaces: state.occupancy.totalWorkplaces,
        totalWorkplaceAssignments: state.occupancy.totalWorkplaceAssignments,
        totalHomeofficeAssignments: state.occupancy.totalHomeofficeAssignments,
        totalOutOfOfficeAssignments: state.occupancy.totalOutOfOfficeAssignments,
        notSharedWorkplaceAssignments: state.occupancy.notSharedWorkplaceAssignments,
        totalVacation: state.occupancy.totalVacation,
    }
}

const mapDispatchToProps = {
    setFloor: setFloor,
    getCoworkersWithAssignmentAtDateFiltered: getCoworkersWithAssignmentAtDateFiltered,
    selectFavoriteWorkplace: selectFavoriteWorkplace,
    getWorkplaceAssignmentsStatistics: getWorkplaceAssignmentsStatistics,
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentOccupancyListComponent)
