import {
    ATTENDANCE_STATUS_ATTENDING_IN_PERSON,
    ATTENDANCE_STATUS_ATTENDING_ONLINE,
    ATTENDANCE_STATUS_CANCELED,
    ATTENDANCE_STATUS_INITIATOR,
    ATTENDANCE_STATUS_UNKNOWN
} from "./NameUtils";
import {getDateWithoutTime, startingInNext15Minutes, takesPlaceRightNow} from "./TimeUtils.js";

export function sortAttendees(a, b) {
    if (a.meetingAttendanceStatus === ATTENDANCE_STATUS_INITIATOR)
        return -1

    if (b.meetingAttendanceStatus === ATTENDANCE_STATUS_INITIATOR)
        return 1

    if (a.meetingAttendanceStatus === b.meetingAttendanceStatus) {
        return (a.person.name > b.person.name) ? 1 : (a.person.name < b.person.name) ? -1 : 0
    }

    let meetingStateArray = [
        ATTENDANCE_STATUS_INITIATOR,
        ATTENDANCE_STATUS_ATTENDING_IN_PERSON,
        ATTENDANCE_STATUS_ATTENDING_ONLINE,
        ATTENDANCE_STATUS_CANCELED,
        ATTENDANCE_STATUS_UNKNOWN
    ]

    return (meetingStateArray.indexOf(a.meetingAttendanceStatus) > meetingStateArray.indexOf(b.meetingAttendanceStatus)) ?
        1 : (meetingStateArray.indexOf(a.meetingAttendanceStatus) < meetingStateArray.indexOf(b.meetingAttendanceStatus)) ?
            -1 : 0

}

export function isMemberOfMeeting(meeting, userId) {
    return !!meeting.attendances.find(value => value.person.id === userId)
}

export function findCurrentMeeting(assignments) {
    let currentMeeting
    if (assignments && assignments.length) {
        currentMeeting = assignments.find(meeting => takesPlaceRightNow(meeting.timePeriod))
        if (!currentMeeting) {
            currentMeeting = assignments.find(meeting => startingInNext15Minutes(meeting.timePeriod))
        }
    }
    return currentMeeting
}

export function getMeetingsOfSpaceAtDate(selectedSpace, spaceAssignments, selectedDate) {
    if (!selectedSpace || !spaceAssignments || !spaceAssignments[selectedSpace.id])
        return null

    let date = selectedDate ? getDateWithoutTime(selectedDate) : getDateWithoutTime(new Date())
    return spaceAssignments[selectedSpace.id][date] ? spaceAssignments[selectedSpace.id][date] : []
}