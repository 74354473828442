import * as React from "react"

function OutstandingMeetingInvitationSvgIcon(props) {
    return (
        <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                stroke={props.stroke || '#333'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <rect width={18} height={18} x={6} y={6} rx={9}/>
                <path d="M15 18.75v1.125m0-2.25v-2.143a2.679 2.679 0 10-2.679-2.678"/>
            </g>
        </svg>
    )
}

export default OutstandingMeetingInvitationSvgIcon
