import * as React from "react"
import PropTypes from 'prop-types'

function SeatTakenSvgIcon({width, height, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 234 159"
            {...props}>
            <defs>
                <clipPath id="b">
                    <path
                        d="M58.171-.001a3 3 0 1 0 5.657 0L178 .001c6.627 0 12 5.372 12 12v89c0 6.627-5.373 12-12 12H63.236a3 3 0 1 0-4.472 0H12c-6.627 0-12-5.373-12-12V78c11.598 0 21-9.402 21-21S11.598 36 0 36V12C0 5.373 5.373 0 12 0l46.171-.002ZM61 97.001a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"/>
                </clipPath>
                <clipPath id="c">
                    <path
                        d="M62 0c-9.941 0-18 8.059-18 18 0 9.94 8.059 18 18 18 9.763 0 17.711-7.774 17.992-17.47l.007-.53v62H0V0h62Z"/>
                </clipPath>
                <clipPath id="d">
                    <path d="M41 0a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h38Z"/>
                </clipPath>
                <clipPath id="e">
                    <path d="M36 0v24a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V0h36Z"/>
                </clipPath>
                <clipPath id="f">
                    <path d="M36 0v4H0V0h36Z"/>
                </clipPath>
                <clipPath id="h">
                    <path d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0Z"/>
                </clipPath>
                <linearGradient
                    id="g"
                    x1={18}
                    y1={0}
                    x2={18}
                    y2={4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="rgba(237, 237, 237, 0)" offset="0%"/>
                    <stop stopColor="#D7D7D7" offset="100%"/>
                </linearGradient>
                <filter
                    id="a"
                    x="-26.957%"
                    y="-39.216%"
                    filterUnits="userSpaceOnUse"
                    width="136.522%"
                    height="154.901%"
                >
                    <feGaussianBlur stdDeviation={10} in="SourceAlpha" result="i1"/>
                    <feOffset dy={2} in="i1" result="i2"/>
                    <feColorMatrix
                        values="0 0 0 0.8509803921568627 0 0 0 0 0.8509803921568627 0 0 0 0 0.8509803921568627 0 0 0 0 1.0 0"
                        in="i2"
                    />
                </filter>
            </defs>
            <path
                d="M178 0c6.627 0 12 5.373 12 12v89c0 6.628-5.373 12-12 12H63.236a3 3 0 1 0-4.472 0H12c-6.627 0-12-5.372-12-12V78c11.598 0 21-9.401 21-21 0-11.597-9.402-21-21-21V12C0 5.374 5.373 0 12 0L58.171 0a3 3 0 1 0 5.657 0L178 .001ZM61 97a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                filter="url(#a)"
                transform="translate(22 23)"
            />
            <g clipPath="url(#b)" transform="translate(22 23)">
                <path fill="#FAFAFA" d="M0-.001h190v113.002H0V-.001z"/>
                <path
                    d="M178 0c6.627 0 12 5.373 12 12v89c0 6.628-5.373 12-12 12H63.236a3 3 0 1 0-4.472 0H12c-6.627 0-12-5.372-12-12V78c11.598 0 21-9.401 21-21 0-11.597-9.402-21-21-21V12C0 5.374 5.373 0 12 0L58.171 0a3 3 0 1 0 5.657 0L178 .001ZM61 97a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                    stroke="#E5E5E5"
                    strokeWidth={4}
                    fill="none"
                    strokeMiterlimit={5}
                />
            </g>
            <g clipPath="url(#c)" transform="translate(106 40)">
                <path
                    d="M58 71.999a19.468 19.468 0 0 0-4.227-6.306A19.435 19.435 0 0 0 40 60a19.435 19.435 0 0 0-13.773 5.694A19.468 19.468 0 0 0 22 72M40 68.999V51.885M14 44V28M66 44V28"
                    stroke="#E5E5E5"
                    strokeWidth={4}
                    fill="none"
                    strokeLinejoin="round"
                />
                <g clipPath="url(#d)" transform="translate(18 38)">
                    <path fill="#E5E5E5" d="M0 0h44v12H0V0z"/>
                </g>
                <g clipPath="url(#e)" transform="matrix(1 0 0 -1 22 38)">
                    <path fill="#E5E5E5" d="M0 0h36v30H0V0z"/>
                </g>
            </g>
            <g clipPath="url(#f)" transform="translate(128 74)">
                <path fill="url(#g)" d="M0 0h36v4H0V0z"/>
            </g>
            <g clipPath="url(#h)" transform="translate(146 40)">
                <path fill="#FAFAFA" d="M0 0h40v40H0V0z"/>
            </g>
            <path
                d="M166 47c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13ZM175.533 50.467l-19.066 19.066"
                stroke="#B2B2B2"
                strokeWidth={4}
                fill="none"
                strokeLinejoin="round"
            />
        </svg>
    )
}

SeatTakenSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

SeatTakenSvgIcon.defaultProps = {
    width: 210,
    height: 133
};

export default SeatTakenSvgIcon