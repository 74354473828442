export function getTextColorDependingOnProps(theme, disabled, primary, secondary, textColor) {
    return disabled
        ? theme.colors.palette.neutral.greyMain
        : primary || secondary
            ? theme.colors.palette.neutral.white
            : textColor
}

export function getBackgroundColorDependingOnProps(theme, disabled, primary, secondary, background) {
    return disabled
        ? theme.colors.palette.neutral.greyMedium
        : primary
            ? theme.colors.palette.corporate.skinMain
            : secondary
                ? theme.colors.palette.corporate.greenMain
                : background
}