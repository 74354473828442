import * as THREE from 'three'
import {
    createEdge,
    createFaceGeometry,
    createVertex,
    drawingSegments,
    drawingSize,
    getCircleGeometry,
    getDrawingTypesMaterial
} from './drawConstants'
import {getMaterial, materialTypes} from '../three/common/materials'

function DrawLine() {
    THREE.Mesh.apply(this, arguments)
    this.vertexGroup = new THREE.Group()
    this.vertexGroup.name = 'vertices'
    this.edgeGroup = new THREE.Group()
    this.edgeGroup.name = 'edges'

    this.handlerScale = 1

    this.measureLines = new THREE.Group()

    this.add(this.vertexGroup)
    this.add(this.edgeGroup)
    this.add(this.measureLines)

    this.init = function (drawingRepresentationType, position) {
        const vertices = []
        vertices.push([position.x, position.z])
        vertices.push([position.x, position.z])

        this.updateArea()

        return this.reInit(vertices, drawingRepresentationType)
    }

    this.reInit = function (vertices, drawingRepresentationType) {
        if (vertices.length !== 2)
            return null

        const positionA = new THREE.Vector3(vertices[0][0], 0.05, vertices[0][1])
        const positionB = new THREE.Vector3(vertices[1][0], 0.05, vertices[1][1])

        const {innerMaterial, material} = getDrawingTypesMaterial(drawingRepresentationType)

        const vertexMesh = createVertex(material, innerMaterial, this.handlerScale)
        vertexMesh.position.set(positionA.x, positionA.y, positionA.z)
        const vertexMeshClone = createVertex(material, innerMaterial, this.handlerScale)
        vertexMeshClone.position.set(positionB.x, positionB.y, positionB.z)

        this.vertexGroup.add(vertexMesh)
        this.vertexGroup.add(vertexMeshClone)

        const edgeMesh = createEdge(vertexMesh, vertexMeshClone)
        edgeMesh.material = innerMaterial

        this.edgeGroup.add(edgeMesh)

        this.drawingRepresentationType = drawingRepresentationType
        this.scale.y = 0.01

        this.updateArea()

        return vertexMeshClone
    }

    this.updateVertex = function (vertex, position) {
        vertex.position.set(position.x, 0.05, position.z)
        this.updateEdge()
        this.updateArea()
    }

    this.updateEdge = function () {
        if (this.vertexGroup.children.length !== 2 && this.edgeGroup.children.length !== 1)
            return

        const path = new THREE.LineCurve3(this.vertexGroup.children[0].position, this.vertexGroup.children[1].position)
        this.edgeGroup.children[0].geometry.dispose()
        this.edgeGroup.children[0].geometry = new THREE.TubeGeometry(path, 1, drawingSize.edgeThickness, 3)
    }

    this.setHandlerScale = function (handlerScale, update = true) {
        this.handlerScale = handlerScale

        if (update) {
            this.vertexGroup.children.forEach(v => {
                v.geometry.dispose()
                v.geometry = getCircleGeometry(drawingSize.edgeHandlerSize * handlerScale, drawingSegments.vertex)
            })

            if (this.face) {
                this.updateArea()
            }
        }
    }

    this.getOtherVertexPosition = function (vertex) {
        const otherVertex = this.vertexGroup.children.find(v => v.uuid !== vertex.uuid)

        return otherVertex.position
    }

    this.getDistance = function () {
        if (this.vertexGroup.children.length !== 2)
            return 0

        return this.vertexGroup.children[0].position.distanceTo(this.vertexGroup.children[1].position)
    }

    this.getMiddlePoint = function () {
        if (this.vertexGroup.children.length !== 2)
            return new THREE.Vector3(0, 0, 0)

        return new THREE.Vector3().lerpVectors(this.vertexGroup.children[0].position.clone(), this.vertexGroup.children[1].position.clone(), .5)
    }

    this.setActive = function (active) {
        this.visible = active
    }

    this.updateArea = function () {
        if (this.vertexGroup.children.length !== 2)
            return

        const vertex_0 = this.vertexGroup.children[0].position
        const vertex_1 = this.vertexGroup.children[1].position

        let center_0 = new THREE.Vector2(vertex_0.x, vertex_0.z)
        let center_1 = new THREE.Vector2(vertex_1.x, vertex_1.z)

        const distance = center_0.distanceTo(center_1)

        if (center_1.x < center_0.x) {
            const temp = center_0
            center_0 = center_1
            center_1 = temp
        }

        const diff_0 = center_1.clone()
            .add(center_0.clone()
                .multiplyScalar(-1))
            .normalize()

        const size = this.handlerScale * 0.75

        const vertices = [
            {position: new THREE.Vector3(0, 0.05, 0)},
            {position: new THREE.Vector3(0, 0.05, -size)},
            {position: new THREE.Vector3(distance, 0.05, -size)},
            {position: new THREE.Vector3(distance, 0.05, 0)},
        ]

        const faceGeometry = createFaceGeometry(vertices, false)
        faceGeometry.rotateY(Math.atan2(-diff_0.y, diff_0.x))

        if (this.face) {
            this.face.material = this.handlerScale > .35 ? getMaterial(materialTypes.scalePatternLow) : getMaterial(materialTypes.scalePatternHigh)
            this.face.position.set(center_0.x, 0.05, center_0.y)
            this.face.geometry.dispose()
            this.face.geometry = faceGeometry
        } else {
            this.face = new THREE.Mesh(faceGeometry, this.handlerScale > .35 ? getMaterial(materialTypes.scalePatternLow) : getMaterial(materialTypes.scalePatternHigh))
            this.face.position.set(center_0.x, 0.05, center_0.y)

            this.add(this.face)
        }
    }

    this.getGeometryAsJSON = function () {
        const vertices = []
        const geometryObject = {
            geometryId: this.geometryId,
            uuid: this.uuid,
            drawingRepresentationType: this.drawingRepresentationType,
            vertices: vertices,
            isValidate: this.isValidate,
            error: this.errorMessage,
            radius: this.radius,
            geometryType: this.geometryType
        }

        this.vertexGroup.children.forEach((vertex) => {
            const position = vertex.position
            vertices.push({x: position.x, y: -position.z})
        })

        return geometryObject
    }

    this.setGeometryFromJSON = function (json) {
        this.uuid = json.uuid
        this.isValidate = json.isValidate
        this.errorMessage = json.error
        this.radius = json.radius
        this.geometryId = json.geometryId
        this.geometryType = json.geometryType
        const vertices = json.vertices
        const flippedVertices = []
        vertices.forEach(vertex => flippedVertices.push([vertex.x, -vertex.y]))

        this.reInit(flippedVertices, json.drawingRepresentationType)
    }

}

DrawLine.prototype = Object.create(THREE.Mesh.prototype)
DrawLine.prototype.constructor = DrawLine
export {DrawLine}