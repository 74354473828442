import React, {useEffect} from 'react';

import clsx from "clsx";
import {compose} from "redux";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";

import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";

import {getTotalWorkplaces, getWorkplaceAssignmentsStatistics} from "../actions/occupancy-actions";
import {navigate, PATH_OCCUPANCY, PATH_PROFILE_HOMEZONE} from "../common/utils/NavigationUtils";
import AllBuildingsSvgIcon from "../common/icons/AllBuildingsSvgIcon.js";
import HomezoneSvgIcon from "../common/icons/HomezoneSvgIcon.js";
import {getHomezone} from "../actions/user-action.js";

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {A11y, Navigation, Pagination, Scrollbar} from 'swiper';
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import CurrentOccupancySwipeItem from "./occupancy/CurrentOccupancySwipeItem";
import FloorSvgIcon from "../common/icons/FloorSvgIcon.js";
import BuildingSvgIcon from "../common/icons/BuildingSvgIcon.js";
import RemoteSvgIcon from "../common/icons/RemoteSvgIcon.js";
import {setFloor} from "../actions/floors-actions.js";


const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    inline: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
    },
    white: {
        color: theme.colors.palette.neutral.white,
        width: '100%',
        padding: '0 1rem',
        boxSizing: 'border-box',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divider: {
        backgroundColor: theme.colors.palette.corporate.greenDark,
        height: '6.8rem'
    },
    occupancy: {
        backgroundColor: theme.colors.palette.corporate.greenLight,
        border: 'solid 1px' + theme.colors.palette.corporate.greenDark,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0 18px 0',
        height: '7.3rem',
        cursor: 'pointer'
    },
    occupancyInfoNoHomeZone: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '100%',
    },
    icon: {
        width: '24px',
        height: '24px',
        stroke: theme.colors.palette.neutral.white
    },
    welcomeText: {
        color: theme.colors.palette.neutral.white,
        padding: '1.2rem 0'
    },
    mySwiper: {
        width: '100% !important',
        '&.swiper-container-horizontal>.swiper-pagination-bullets': {
            bottom: '3px'
        },
        '& .swiper-pagination-bullet': {
            height: '6px',
            width: '6px',
            background: theme.colors.palette.neutral.white,
        },
    },
})

function NavigationBarHomescreenContent({
                                            classes,
                                            t,
                                            theme,
                                            person,
                                            totalWorkplaceAssignments,
                                            totalWorkplaceHomezoneBuildingAssignments,
                                            totalWorkplaceHomezoneFloorAssignments,
                                            totalHomeofficeAssignments,
                                            totalOutOfOfficeAssignments,
                                            totalWorkplaces,
                                            totalWorkplacesBuilding,
                                            totalWorkplacesFloor,
                                            getTotalWorkplaces,
                                            getWorkplaceAssignmentsStatistics,
                                            className,
                                            homeZone,
                                            getHomezone,
                                            setFloor,
                                            userAssignments
                                        }) {
    let history = useHistory()

    useEffect(() => {
        if (person && person.id && (null === homeZone || homeZone.building === null || homeZone.floor === null))
            getHomezone(person.id)
    }, [person, getHomezone, homeZone])

    useEffect(() => {
        if (!totalWorkplaces)
            getTotalWorkplaces(-1, -1, true) // all buildings

        if (person && person.id) {
            getWorkplaceAssignmentsStatistics(person.id, null, true)
        }
        // eslint-disable-next-line
    }, [userAssignments]);

    useEffect(() => {
        if (person && person.id && homeZone && homeZone.building && homeZone.floor) {
            getWorkplaceAssignmentsStatistics(person.id, null, true, homeZone.building.id)
            getWorkplaceAssignmentsStatistics(person.id, null, true, null, homeZone.floor.id)
        }
        // eslint-disable-next-line
    }, [person, homeZone])

    function routeToHomeZone() {
        navigate(history, PATH_PROFILE_HOMEZONE)
    }

    function routeToOccupancyPage(showMap, showOffice = false, showHomeoffice = false, showOutOfOffice = false, showVacation = false) {
        navigate(history, PATH_OCCUPANCY + (showMap ? '?mode=0' : '?mode=1') + ('&showHomeoffice=' + showHomeoffice) + ('&showOutOfOffice=' + showOutOfOffice) + ('&showVacation=' + showVacation))
    }

    function routeToOccupancyHomezoneMap() {
        setFloor(homeZone.floor)
        navigate(history, PATH_OCCUPANCY + '?mode=0');
    }

    SwiperCore.use([Pagination]);

    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant={'h3'}
                        className={classes.welcomeText}>{t('hi')}, {person.name}</Typography>
            <Swiper
                spaceBetween={18}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                pagination={{clickable: false}}
                className={classes.mySwiper}>

                <SwiperSlide>
                    {homeZone && homeZone.building && homeZone.floor
                        ? <div className={classes.occupancy} onClick={() => routeToOccupancyHomezoneMap()}>
                            <CurrentOccupancySwipeItem icon={<BuildingSvgIcon/>}
                                                       total={totalWorkplacesBuilding}
                                                       occupied={totalWorkplaceHomezoneBuildingAssignments}
                                                       name={homeZone && homeZone.building ? homeZone.building.name : ''}/>

                            <Divider orientation="vertical" className={classes.divider}/>

                            <CurrentOccupancySwipeItem icon={<FloorSvgIcon/>}
                                                       total={totalWorkplacesFloor}
                                                       occupied={totalWorkplaceHomezoneFloorAssignments}
                                                       name={homeZone && homeZone.floor ? homeZone.floor.name : ''}/>
                        </div>
                        : <div className={classes.occupancy} onClick={routeToHomeZone}>
                            <div className={classes.occupancyInfoNoHomeZone}>
                                <HomezoneSvgIcon color={theme.colors.palette.neutral.white}
                                                 className={classes.icon}/>
                                <div className={classes.inline}>
                                    <Typography variant={'body2'} className={classes.white}>
                                        {t('no_homezone_defined')}
                                    </Typography>
                                </div>
                                <Typography variant={'caption'}>
                                    {t('tap_here_set_up')}
                                </Typography>
                            </div>
                        </div>
                    }
                </SwiperSlide>

                <SwiperSlide>
                    <div className={classes.occupancy}>
                        <CurrentOccupancySwipeItem icon={<AllBuildingsSvgIcon/>}
                                                   onClick={() => routeToOccupancyPage(false, true)}
                                                   total={totalWorkplaces ? totalWorkplaces : 0}
                                                   occupied={totalWorkplaceAssignments}
                                                   name={t('all_buildings')}/>

                        <Divider orientation="vertical" className={classes.divider}/>

                        <CurrentOccupancySwipeItem icon={<RemoteSvgIcon className={classes.icon}/>}
                                                   onClick={() => routeToOccupancyPage(false, false, true, true)}
                                                   occupied={totalHomeofficeAssignments + totalOutOfOfficeAssignments}
                                                   name={t('remote')}/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        totalWorkplaceAssignments: state.occupancy.totalWorkplaceAssignments,
        totalWorkplaceHomezoneBuildingAssignments: state.occupancy.totalWorkplaceHomezoneBuildingAssignments,
        totalWorkplaceHomezoneFloorAssignments: state.occupancy.totalWorkplaceHomezoneFloorAssignments,
        totalHomeofficeAssignments: state.occupancy.totalHomeofficeAssignments,
        totalOutOfOfficeAssignments: state.occupancy.totalOutOfOfficeAssignments,
        totalWorkplaces: state.occupancy.totalWorkplaces,
        totalWorkplacesBuilding: state.occupancy.totalWorkplacesHomezoneBuilding,
        totalWorkplacesFloor: state.occupancy.totalWorkplacesHomezoneFloor,
        person: state.user.person,
        homeZone: state.user.homeZone,
        userAssignments: state.user.userAssignments,
    }
};

const mapDispatchToProps = {
    getTotalWorkplaces: getTotalWorkplaces,
    getWorkplaceAssignmentsStatistics: getWorkplaceAssignmentsStatistics,
    getHomezone: getHomezone,
    setFloor: setFloor,
};

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(NavigationBarHomescreenContent))