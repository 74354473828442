import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";
import {withFormik} from "formik";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators, compose} from "redux";

import {withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import {setElement} from "../../../actions/modalElementSkeleton-actions.js";

const styles = theme => ({
    root: theme.modalContentInput,
    panelFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '14px 20px 14px 20px'
    },
    reqWarning: {
        color: theme.colors.palette.corporate.redMain,
    },
    input: {
        height: '3.6rem !important',
        boxSizing: 'border-box !important',
        minWidth: '100%',
    }
})

function NameComponentClass({
                                classes,
                                t,
                                values,
                                setFieldValue,
                                handleSubmit,
                                variant,
                                disableAutofocus,
                                className,
                                saveOnChange,
                                onValidationCorrect
                            }) {
    const timer = useRef(0)

    useEffect(() => {

        if (timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
            handleSubmit()
        }, 200)

// eslint-disable-next-line
    }, [values.element.name]);

    return (
        <div className={classes.root}>
            <div className={clsx(classes.panel, classes.panelFlex, className)}>
                <Typography variant={variant ? variant : 'body1'}>{'Name'}</Typography>

                {onValidationCorrect ?
                    <Typography className={classes.reqWarning}>{'*'}</Typography> : null
                }

                <TextField
                    autoFocus={disableAutofocus !== true}
                    type="text"
                    placeholder={t('name')}
                    value={values.element.name ? values.element.name : ''}
                    name="element.name"
                    id="name"
                    fullWidth
                    onChange={e => {
                        e.preventDefault();
                        const {value} = e.target;
                        setFieldValue("element.name", value);
                        if (saveOnChange) {
                            let x = {...values.element}
                            x.name = value
                            saveOnChange(x)
                        }
                    }}
                />
            </div>
        </div>
    );
}

const NameComponent = withFormik({
    mapPropsToValues: props => {
        return ({element: props.element})
    },
    enableReinitialize: true,
    handleSubmit: (values, {props}) => {
        props.setElement(values.element)
    },
    validate: (values, props) => {
        if (!values.element.name) {
            if (props.onValidationCorrect)
                props.onValidationCorrect(false)
        } else {
            if (props.onValidationCorrect)
                props.onValidationCorrect(true)
        }
    }
})(NameComponentClass)


const mapDispatchToProps = (dispatch) => bindActionCreators({
    setElement,
}, dispatch)

const mapStateToProps = state => {
    return {
        element: state.modalElementSkeleton.element,
    }
}

NameComponent.propTypes = {
    onValidationCorrect: PropTypes.func,
    disableAutofocus: PropTypes.bool,
    saveOnChange: PropTypes.func,
};


export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NameComponent);