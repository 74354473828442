import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";

import ActionListItem from "../../common/elements/ActionListItem";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy";
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useTranslation} from "react-i18next";
import {sortPersonsAndWorkingGroups} from "../../common/utils/CommonUtils.js";
import MultipleAvatarsGroup from "../../common/elements/MultipleAvatarsGroup.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'start',
        textAlign: 'left',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
}))

function PeopleAndWorkingGroupList({
                                       persons,
                                       externalPersons,
                                       workingGroups,
                                       title,
                                       ...props
                                   }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const getItemAsElement = (item) => {
        if (item.surname) {
            return <ActionListItem text={item.name + ' ' + item.surname}
                                   avatarHeight={40}
                                   height={40}
                                   key={item.id}
                                   hideDivider
                                   startAdornment={<AvatarWithOccupancy size={40} person={item}
                                                                        hideAvailabilityStatus/>}/>
        } else if (item.email) {
            return <ActionListItem text={item.email}
                                   textSecondRow={t('not_in_organization')}
                                   avatarHeight={40}
                                   height={40}
                                   key={item.email}
                                   hideDivider
                                   startAdornment={<AvatarWithOccupancy size={40} person={item}
                                                                        hideAvailabilityStatus/>}/>
        } else {
            return <ActionListItem text={item.name}
                                   avatarHeight={40}
                                   height={40}
                                   key={item.id}
                                   hideDivider
                                   startAdornment={<MultipleAvatarsGroup persons={item.members}/>}/>
        }
    }

    return (
        <div className={classes.root}>
            {title && <Typography variant={"h6"}>
                {title}
            </Typography>}

            {[...persons, ...externalPersons, ...workingGroups]
                .sort(sortPersonsAndWorkingGroups)
                .map(item => getItemAsElement(item))}
        </div>
    )
}

PeopleAndWorkingGroupList.propTypes = {
    persons: PropTypes.array.isRequired,
    externalPersons: PropTypes.array,
    workingGroups: PropTypes.array,
    title: PropTypes.string
}

PeopleAndWorkingGroupList.defaultProps = {
    persons: [],
    externalPersons: [],
    workingGroups: [],
}

export default PeopleAndWorkingGroupList
