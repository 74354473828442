import React from 'react';
import PropTypes from "prop-types";
import {Chip, makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import XSvgIcon from "../icons/XSvgIcon.js";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
    root: {
        backgroundColor: 'transparent',
        color: theme.colors.palette.corporate.greenLightest,
        border: theme.colors.palette.corporate.greenLightest + ' solid .1rem',
        borderRadius: '1.8rem',
        height: '3.2rem'
    },
    selected: {
        backgroundColor: theme.colors.palette.corporate.greenLightest + " !important",
        color: theme.colors.palette.neutral.white,
    }
}))

function Tag({tag, onDelete, onClick, selected}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return <Chip className={clsx(classes.root, selected ? classes.selected : '')}
                 label={tag.displayName}
                 key={tag.displayName}
                 onClick={onClick ? () => onClick(tag) : null}
                 onDelete={onDelete ? () => onDelete(tag) : null}
                 deleteIcon={onDelete &&
                     <XSvgIcon color={theme.colors.palette.corporate.greenLightest} className={classes.cross}/>}/>
}

Tag.prototype = {
    tag: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
}

export default Tag