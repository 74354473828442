import * as React from "react"

function TimePickerSvgIcon(props) {
    return (
        <svg
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd">
                <path strokeLinejoin="round" d="M14.75 31.5H9v-22h22v6"/>
                <path d="M31.75 15.5H9h22.75z"/>
                <path strokeLinejoin="round" d="M15 6.5v6M25 6.5v6M25 21.5v4l2 2"/>
                <circle cx={25} cy={25.5} r={6} strokeLinejoin="round"/>
            </g>
        </svg>
    )
}

export default TimePickerSvgIcon