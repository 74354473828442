import React, {useEffect, useState} from 'react';

import moment from "moment";
import {connect} from "react-redux";
import queryString from "query-string";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import CustomButton from "../../CustomButton.js";
import ExportTimeTrackingModal from "./ExportTimeTrackingModal";
import TimeTrackingOverviewContent from "./TimeTrackingOverviewContent";
import LoadingIndicator from "../../../common/elements/LoadingIndicator.js";
import DashboardTimeSelection from "../../admin/Dashboard/DashboardTimeSelection.js";
import HeaderWithTitleAndBackButton from "../../../common/elements/HeaderWithTitleAndBackButton.js";

import PlusSvgIcon from "../../../common/icons/PlusSvgIcon.js";
import CalendarTodaySvgIcon from "../../../common/icons/CalendarTodaySvgIcon";
import CalendarExportSvgIcon from "../../../common/icons/CalendarExportSvgIcon.js";

import {getDayDiffOfTimePeriod} from "../../../common/utils/TimeUtils.js";
import {navigate, PATH_PROFILE} from "../../../common/utils/NavigationUtils.js";
import {getUserTimeTrackingStatistics} from "../../../actions/timetracking-actions.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },
    headerElements: {
        display: 'flex',
        gap: '0.9rem'
    }
}))

function TimeTrackingOverviewPage({
                                      userTimeTrackingStatsPending,
                                      getUserTimeTrackingStatistics,
                                      userId,
                                      ...props
                                  }) {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);
    const cookies = useCookies(['language'])[0] //get cookie only
    const routBackToProfile = !queryString.parse(history.location.search).shortcut

    const [timePeriod, setTimePeriod] = useState({startDate: null, endDate: null})
    const [selectedTimeRange, setSelectedTimeRange] = useState(null)
    const [selectedMoment, setSelectedMoment] = useState(moment().locale(cookies['language']))
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [exportModalOpen, setExportModalOpen] = useState(false);

    useEffect(() => {
        if (userId && timePeriod && timePeriod.startDate && timePeriod.endDate) {
            getUserTimeTrackingStatistics(userId, moment(timePeriod.startDate).toDate(), moment(timePeriod.endDate).toDate())
        }
        //eslint-disable-next-line
    }, [timePeriod, userId])

    function handleTimeSelection(startDate, endDate, moment, setNewSelectedTimeOption, overrideTimeRange) {
        if (newModalOpen)
            setNewModalOpen(false)
        setTimePeriod({startDate: startDate, endDate: endDate})
        setSelectedMoment(moment)
        if (setNewSelectedTimeOption) {
            setSelectedTimeRange(isNaN(overrideTimeRange) ? getTimeSelectionToSet() : overrideTimeRange)
        }
    }

    function getTimeSelectionToSet() {
        let dayDiff = getDayDiffOfTimePeriod(timePeriod)
        if (dayDiff >= 364) {
            return 2
        } else if (dayDiff >= 27) {
            return 1
        } else if (dayDiff >= 6) {
            return 0
        }
    }

    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton backLabel={routBackToProfile ? t("profile") : t("home")}
                                          title={t('time_entries')}
                                          onNavigateBack={() => routBackToProfile ? navigate(history, PATH_PROFILE) : history.goBack()}
                                          additionalElement={
                                              <div className={classes.headerElements}>
                                                  <CustomButton icon={<CalendarExportSvgIcon size={24}/>}
                                                                textColor={theme.colors.palette.neutral.darkMain}
                                                                style={{
                                                                    height: '4rem',
                                                                    width: '4rem',
                                                                    padding: 0,
                                                                }}
                                                                tooltip={t('export')}
                                                                onClick={() => setExportModalOpen(true)}/>
                                                  <CustomButton icon={<CalendarTodaySvgIcon size={24}/>}
                                                                textColor={theme.colors.palette.neutral.darkMain}
                                                                style={{
                                                                    height: '4rem',
                                                                    width: '4rem',
                                                                    padding: 0,
                                                                }}
                                                                tooltip={t('today')}
                                                                onClick={() => handleTimeSelection(
                                                                    moment().startOf('day'),
                                                                    moment().endOf('day'),
                                                                    moment(),
                                                                    true, 0)}/>

                                                  <CustomButton icon={<PlusSvgIcon size={24}/>}
                                                                textColor={theme.colors.palette.neutral.darkMain}
                                                                style={{
                                                                    height: '4rem',
                                                                    width: '4rem',
                                                                    padding: 0,
                                                                }}
                                                                tooltip={t('add')}
                                                                onClick={() => setNewModalOpen(true)}/>
                                              </div>
                                          }
            />
            <div className={classes.content}>
                <DashboardTimeSelection
                    selectedRange={selectedTimeRange}
                    initialRange={0}
                    selectedMoment={selectedMoment}
                    days
                    onTimeSelect={(startDate, endDate, currentSelectedMoment) => handleTimeSelection(startDate, endDate, currentSelectedMoment)}/>
                {userTimeTrackingStatsPending
                    ? <LoadingIndicator/>
                    : <TimeTrackingOverviewContent timePeriod={timePeriod} selectedMoment={selectedMoment}
                                                   newModalOpen={newModalOpen}
                                                   onCloseNewModal={() => setNewModalOpen(false)}
                                                   onCardClickCallback={(start, end, currentSelectedMoment) => handleTimeSelection(start, end, currentSelectedMoment, true)}/>}
            </div>

            <ExportTimeTrackingModal open={exportModalOpen} onClose={() => setExportModalOpen(false)}/>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        userTimeTrackingStatsPending: state.dashboard.userTimeTrackingStatsPending,
    }
}

const mapDispatchToProps = {
    getUserTimeTrackingStatistics: getUserTimeTrackingStatistics
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingOverviewPage)