import * as React from "react"
import PropTypes from "prop-types";
import theme from "../../theme.js";

function WorkTimeSvgIcon({color, width, height, ...props}) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>{"Icons/Main/worktime"}</title>
            <g fillRule="nonzero" stroke={color} fill="none">
                <path
                    d="M8.438 20H3.5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h17a1 1 0 0 1 1 1v2.25M8 7V5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H8Z"/>
                <g transform="translate(11 11)">
                    <circle cx={5} cy={5} r={5}/>
                    <path d="M5 1.5V5l1.5 1.5"/>
                </g>
            </g>
        </svg>
    )
}

WorkTimeSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

WorkTimeSvgIcon.defaultProps = {
    color: theme.colors.palette.neutral.darkMain,
    width: 24,
    height: 24
};

export default WorkTimeSvgIcon