import axios from 'axios';
import moment from "moment";

export function getMeetingRooms(buildingId, floorId, timePeriod) {
    return {
        type: 'GET_MEETINGROOMS',
        payload: axios.get(`/api/building/${buildingId}/floor/${floorId}/space/meetingRooms`, {
            params: {
                startDate: timePeriod ? moment(timePeriod.startDate).toISOString() : moment().toISOString(),
                endDate: timePeriod ? moment(timePeriod.endDate).toISOString() : moment().toISOString(),
            }
        })
    }
}

export function setMeetingInformation(meetingTitle, meetingDescription, onlineLink, duration, onlineOnly, shareStatus) {
    return {
        type: 'SET_MEETING_INFOS',
        payload: {
            title: meetingTitle,
            description: meetingDescription,
            onlineLink: onlineLink,
            duration: duration,
            onlineOnly: onlineOnly,
            shareStatus: shareStatus
        }
    }
}

export function setMeetingDetails(date, timePeriod) {
    return {
        type: 'SET_MEETING_DETAILS',
        payload: {
            date: date,
            timePeriod: timePeriod,
        }
    }
}

export function setMeetingSpace(space) {
    return {
        type: 'SET_MEETING_SPACE',
        payload: space,
    }
}

export function updateMeetingDetails(meeting) {

    return {
        type: 'UPDATE_MEETING_DETAILS',
        payload: axios.post(`/api/personSpaceAssignment`, meeting)
    }
}

export function clearMeetingRooms() {
    return {
        type: 'CLEAR_MEETINGROOMS',
    }
}

export function setMeetingOnlineOnly(boolValue) {
    return {
        type: 'SET_MEETING_ONLINE_ONLY',
        payload: boolValue,
    }
}

export function getAssignmentsOfSpaceAtDate(spaceId, startDate, endDate, loadThirdPartyEvents = true) {
    return {
        type: 'GET_ASSIGNMENTS_OF_SPACE_AT_DATE',
        payload: axios.get(`/api/personSpaceAssignment/${spaceId}/assignments`, {
            params: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                loadThirdPartyEvents: loadThirdPartyEvents
            }
        }),
        meta: {
            date: moment(startDate).toISOString(),
            spaceId: spaceId
        }
    }
}

export function clearMeetingDetails() {
    return {
        type: 'CLEAR_MEETING_DETAILS',
    }
}

export function copyMeetingAssignment(assignment) {
    return {
        type: 'COPY_MEETING_ASSIGNMENT',
        payload: assignment,
    }
}

export function updateTimePeriodMeeting(timePeriod) {
    return {
        type: 'UPDATE_TIME_PERIOD_MEETING',
        payload: timePeriod,
    }
}

export function updateMeetingAttendees(attendees) {
    return {
        type: 'UPDATE_MEETING_ATTENDEES',
        payload: attendees,
    }
}

export function updateExternalMeetingAttendees(attendees) {
    return {
        type: 'UPDATE_EXTERNAL_MEETING_ATTENDEES',
        payload: attendees,
    }
}

export function updateMeetingInvitedGroups(groups) {
    return {
        type: 'UPDATE_MEETING_GROUPS',
        payload: groups,
    }
}

export function updateMeeting(updatedMeeting) {
    return {
        type: 'UPDATE_MEETING',
        payload: axios.patch("/api/personSpaceAssignment", updatedMeeting)
    }
}

export function getSuggestedMeetingSlots(meetingDetails, personId) {
    return {
        type: 'GET_MEETING_SUGGESTIONS',
        payload: axios.post(`/api/personSpaceAssignment/${personId}/suggestedSlots`, meetingDetails)
    }
}

