import {combineReducers} from 'redux'
import workingGroupsReducer from "./workingGroups-reducer";
import userReducer from "./user-reducer";
import workplaceReducer from "./workplaceAssignment-reducer";
import assignmentBuilderReducer from "./assignmentBuilder-reducer";
import {errorReducer} from "./error-reducer";
import buildingsReducer from "./buildings-reducer";
import modalElementSkeletonReducer from "./modalElementSkeleton-reducer";
import floorsReducer from "./floors-reducer";
import spaceReducer from "./space-reducer";
import commonReducer from "./common-reducer";
import dashboardReducer from "./dashboard-reducer";
import {serviceWorkerReducer} from "./serviceWorker-reducer";
import meetingReducer from "./meeting-reducer";
import {actionFeedbackReducer} from "./actionFeedback-reducer";
import drawingReducer from "./drawing-reducer";
import occupancyReducer from "./occupancy-reducer";
import uiReducer from "./ui-reducer";
import adminConfigReducer from "./adminConfig-reducer.js";
import thirdPartyAuthenticationReducer from "./thirdPartyAuthentication-reducer.js";
import userConfigReducer from "./userConfig-reducer.js";
import vacationReducer from "./vacation-reducer.js";
import timetrackingReducer from "./timetracking-reducer.js";
import timezoneReducer from "./timezone-reducer.js";
import terminalReducer from "./terminal-reducer.js";
import resourceReducer from "./resource-reducer.js";


const reducers = combineReducers({
    workingGroups: workingGroupsReducer,
    user: userReducer,
    workplace: workplaceReducer,
    assignmentBuilder: assignmentBuilderReducer,
    buildings: buildingsReducer,
    modalElementSkeleton: modalElementSkeletonReducer,
    floors: floorsReducer,
    spaces: spaceReducer,
    common: commonReducer,
    error: errorReducer,
    dashboard: dashboardReducer,
    serviceWorker: serviceWorkerReducer,
    meetings: meetingReducer,
    actionFeedback: actionFeedbackReducer,
    drawing: drawingReducer,
    occupancy: occupancyReducer,
    uiState: uiReducer,
    thirdPartyAuthentication: thirdPartyAuthenticationReducer,
    adminConfig: adminConfigReducer,
    userConfig: userConfigReducer,
    vacation: vacationReducer,
    timetracking: timetrackingReducer,
    timezone: timezoneReducer,
    terminal: terminalReducer,
    resource: resourceReducer
});

export default reducers
