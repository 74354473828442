import React from 'react';

import {Divider, makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import BreakSvgIcon from "../../../common/icons/BreakSvgIcon.js";
import {getTrackingsBreakHoursAsString, getWorkedHoursAsString} from "../../../common/utils/TimeUtils.js";
import PropTypes from "prop-types";
import WorkTimeSvgIcon from "../../../common/icons/WorkTimeSvgIcon.js";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.innerSmallGap
    },
    leftContainer: {
        display: 'flex',
        gap: theme.innerSmallGap,
    }
}))

function WorkAndBreakTime({timeTrackings, align, hideWorkIcon, className}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.leftContainer}
                 style={{minWidth: align ? '8rem' : ''}}>

                {!hideWorkIcon && <WorkTimeSvgIcon color={theme.colors.palette.neutral.greyMain}/>}
                <Typography
                    variant={hideWorkIcon ? 'h5' : 'body1'}>{getWorkedHoursAsString(timeTrackings)}</Typography>
            </div>
            <Divider orientation="vertical" className={classes.divider}/>
            <BreakSvgIcon color={theme.colors.palette.neutral.greyMain}/>
            <Typography
                variant={hideWorkIcon ? 'h6' : 'body1'}>{getTrackingsBreakHoursAsString(timeTrackings)}</Typography>
        </div>
    )
}

WorkAndBreakTime.propTypes = {
    timeTrackings: PropTypes.array.isRequired,
    align: PropTypes.bool,
    hideWorkIcon: PropTypes.bool,
}

export default WorkAndBreakTime