import * as React from "react"
import PropTypes from "prop-types";
import theme from "../../theme.js";

function OptionsIconSvg({color, size, ...props}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 30 30"
            {...props}
        >
            <g fill={color} fillRule="evenodd">
                <circle cx={15} cy={8} r={1.5}/>
                <circle cx={15} cy={15} r={1.5}/>
                <circle cx={15} cy={22} r={1.5}/>
            </g>
        </svg>
    )
}

OptionsIconSvg.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

OptionsIconSvg.defaultProps = {
    color: theme.colors.palette.neutral.darkMain,
    size: 30,
};

export default OptionsIconSvg
