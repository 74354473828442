import * as React from "react";

import useTheme from "@material-ui/core/styles/useTheme.js";

function HomeOfficeTippsPantsSvg(props) {

    const theme = useTheme()

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={288} height={226} viewBox="0 0 288 226"{...props}>
            <defs>
                <linearGradient id="prefix__a" x1="27.443%" x2="41.208%" y1="58.748%" y2="90.046%">
                    <stop offset="0%" stopColor="#FF7942"/>
                    <stop offset="100%" stopColor="#FFB082"/>
                </linearGradient>
                <linearGradient id="prefix__b" x1="38.232%" x2="63.568%" y1="13.447%" y2="74.326%">
                    <stop offset="0%" stopColor="#333"/>
                    <stop offset="100%"/>
                </linearGradient>
                <linearGradient id="prefix__c" x1="56.745%" x2="55.783%" y1="79.373%" y2="15.267%">
                    <stop offset="0%" stopColor="#FF7942"/>
                    <stop offset="100%" stopColor="#FFB082"/>
                </linearGradient>
                <linearGradient id="prefix__d" x1="30%" x2="70%" y1="70%" y2="60%">
                    <stop offset="0%" stopColor={theme.colors.palette.corporate.greenLightest}/>
                    <stop offset="100%" stopColor={theme.colors.palette.corporate.greenLight}/>
                </linearGradient>
                <linearGradient id="prefix__e" x1="18.308%" x2="86.538%" y1="13.447%" y2="74.326%">
                    <stop offset="0%" stopColor="#333"/>
                    <stop offset="100%"/>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="url(#prefix__a)"
                    fillRule="nonzero"
                    d="M59.726 170.057c33.823 27.52 51.068 43.052 76.808 5.473 10.076-14.71 21.239-32.042 33.487-51.994 15.66-25.511 8.49-58.827-16.28-75.636l-16.092-10.92c-24.682-16.75-57.912-12.874-78.078 9.106l-8.223 8.962c-29.579 32.24-27.422 82.353 4.817 111.932a80.07 80.07 0 003.56 3.077z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="url(#prefix__b)"
                    fillRule="nonzero"
                    d="M156.67 91.635c-1.387 7.568-2.558 15.567-3.513 23.997a426.652 426.652 0 00-2.598 39.277c-.402 19.505 15.084 35.642 34.59 36.044a35.336 35.336 0 0018.161-4.595l25.519-14.482a48.647 48.647 0 0024.635-42.307 57.869 57.869 0 00-22.37-45.701L213.28 70.032c-15.46-12.009-37.73-9.21-49.738 6.25a35.445 35.445 0 00-6.871 15.353z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="url(#prefix__c)"
                    fillRule="nonzero"
                    d="M158.07 197.828a23.354 23.354 0 01-10.92 7.277l-1.08.338a3.085 3.085 0 01-3.582-4.5l7.678-13.123a4.827 4.827 0 017.903-.614l.072.088a8.261 8.261 0 01-.072 10.534z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="url(#prefix__d)"
                    fillRule="nonzero"
                    d="M166.527 140.994c50.524 11.206 76.375 22.355 77.552 33.447 2.716 25.6 25.671 44.15 51.271 41.434a46.614 46.614 0 0036.017-24.058 301.31 301.31 0 014.047-7.195c14.645-25.204 15.94-51.987 3.887-80.348a56.021 56.021 0 00-8.24-13.61L315.827 72.09c-27.584-33.63-74.56-44.094-113.81-25.348L158.16 67.688c-19.446 9.288-27.682 32.582-18.394 52.028a39.018 39.018 0 0026.762 21.278z"
                    transform="matrix(-1 0 0 1 395.706 .847)"
                />
                <path
                    fill="url(#prefix__e)"
                    fillRule="nonzero"
                    d="M215.011 201.912l-.519-.026a6.695 6.695 0 01-5.074-10.636l.43-.59a73.197 73.197 0 0116.358-16.248 4.45 4.45 0 016.944 4.577l-2.43 10.949a15.312 15.312 0 01-15.709 11.974z"
                    transform="rotate(15 216.063 188.454)"
                />
                <path
                    fill="url(#prefix__d)"
                    fillRule="nonzero"
                    d="M173.907 33.506l-4.23-1.295a3.38 3.38 0 01.183-6.515l9.758-2.393a24.931 24.931 0 0127.964 12.535 6.327 6.327 0 01-8.361 8.649l-10.754-5.244a99.953 99.953 0 00-14.56-5.737z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="#FFF"
                    d="M101.452 74.761l47.877-12.829a2.599 2.599 0 013.167 1.747l23.957 79.327c.422 1.398-.406 2.872-1.829 3.253l-20.159 5.401a2.6 2.6 0 01-3.148-1.687L137.55 108.11c-.017-.055-2.703.576-2.703.576-.055.015 8.904 43.314 8.904 43.314a2.6 2.6 0 01-1.883 3.036l-20.159 5.402a2.598 2.598 0 01-3.21-1.905L99.584 77.857a2.599 2.599 0 011.869-3.096zm38.996 7.15l-20.35 5.453c.339.792.622 1.61.848 2.453 2.076 7.75-1.2 15.822-7.8 19.998l10.544 44.915 15.052-4.033-8.526-40.737c-.575-2.75 1.121-5.51 3.83-6.236l2.503-.67c2.704-.725 5.56.822 6.435 3.485l12.985 39.542 15.052-4.033-13.327-44.17c-7.805-.316-14.677-5.671-16.753-13.419a18.25 18.25 0 01-.493-2.547zm5.035-1.348c.057.842.196 1.683.42 2.517 1.292 4.824 5.155 8.348 9.727 9.258l-4.033-13.414-6.114 1.639zm-34.285 23.68c3.504-3.072 5.088-8.056 3.795-12.881a13.138 13.138 0 00-.895-2.39l-6.114 1.639 3.214 13.632zm-4.21-19.577l43.466-11.647-1.75-5.743-43.073 11.542 1.356 5.848z"
                />
            </g>
        </svg>
    )
}

export default HomeOfficeTippsPantsSvg