import React, {useState} from 'react';

import {compose} from "redux";
import {isEmpty} from "lodash";
import queryString from "query-string"
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";

import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../CustomTextField";
import ShowPasswordSvgIcon from "../../common/icons/ShowPasswordSvgIcon";
import IconButton from "@material-ui/core/IconButton";
import HidePasswordSvgIcon from "../../common/icons/HidePasswordSvgIcon";
import CustomCheckbox from "../CustomCheckbox";
import InfoAlertSvgIcon from "../../common/icons/InfoAlertSvgIcon";
import {forgotPassword} from "../../actions/user-action.js";
import {connect} from "react-redux";
import {navigate, PATH_FORGOT_PASSWORD} from "../../common/utils/NavigationUtils.js";
import ClickableText from "../../common/elements/ClickableText";
import CustomButton from "../CustomButton.js";

const styles = theme => ({
    root: {
        width: '100%',
        flex: 1,
        boxSizing: 'border-box',
        padding: theme.paddingContentContainer.padding,
    },
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2.4rem',
    },
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.6rem',
    },
    rememberContainer: {
        display: 'flex',
        gap: '1.4rem',
    },
    actionButton: {
        marginTop: '4.8rem',
    },
    link: {
        textDecoration: 'underline',
    },
    errorNode: {
        display: 'flex',
        gap: '0.4rem',
        alignItems: 'center',
    }
});

function LoginPage({theme, t, classes, forgotPassword, forgotPasswordPending, ...props}) {
    const history = useHistory()
    const [error, setError] = useState(!!queryString.parse(history.location.search).error);
    const [emptyEmailError, setEmptyEmailError] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [rememberMe, setRememberMe] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const [values, setValues] = React.useState({
        email: queryString.parse(history.location.search).email || '',
        password: queryString.parse(history.location.search).pass || '',
    })

    const handleChange = prop => event => {
        setValues({...values, [prop]: event.target.value})
    }

    const handleValidChange = valid => {
        if (valid !== formValid)
            setFormValid(valid)

        if (error && valid)
            setError(false)

        if (emptyEmailError && valid)
            setEmptyEmailError(false)
    }

    const handleForgotPassword = () => {
        forgotPassword(values.email)
            .then((response) => {
                if (response.value.data) {
                    navigate(history, PATH_FORGOT_PASSWORD)
                } else {
                    setEmptyEmailError(true)
                }
            })
    }

    return (
        <Paper className={classes.root}>
            <form className={classes.form} method={'POST'} action={'/api/login'}>
                <div className={classes.formContent}>

                    <CustomTextField id={'email-field'} title={t('email')} value={values['email']} name={'email'}
                                     type={'email'} onChange={handleChange('email')}
                                     onValidChange={handleValidChange} error={error || emptyEmailError}
                                     info={error
                                         ? <div className={classes.errorNode}>
                                             <InfoAlertSvgIcon/>
                                             <Typography variant={'caption'}
                                                         style={{color: theme.colors.palette.corporate.redMain}}>
                                                 {'E-Mail oder Password ist falsch'}
                                             </Typography>
                                         </div>
                                         : emptyEmailError
                                             ? <div className={classes.errorNode}>
                                                 <InfoAlertSvgIcon/>
                                                 <Typography variant={'caption'}
                                                             style={{color: theme.colors.palette.corporate.redMain}}>
                                                     {'Gültige E-Mail eingeben'}
                                                 </Typography>
                                             </div>
                                             : null
                                     }
                    />
                    <CustomTextField id={'password-field'} title={t('password')}
                                     name={'password'} min={8}
                                     type={showPassword ? 'text' : 'password'}
                                     value={values['password']} onChange={handleChange('password')}
                                     onValidChange={handleValidChange} error={error}
                                     contentRight={
                                         <IconButton onClick={() => setShowPassword(!showPassword)}
                                                     style={{padding: '0px'}}
                                                     disabled={isEmpty(values.password)}>
                                             {showPassword ?
                                                 <HidePasswordSvgIcon
                                                     style={{stroke: isEmpty(values.password) ? theme.colors.palette.neutral.greyMedium : theme.colors.palette.neutral.darkMain}}/> :
                                                 <ShowPasswordSvgIcon
                                                     style={{stroke: isEmpty(values.password) ? theme.colors.palette.neutral.greyMedium : theme.colors.palette.neutral.darkMain}}/>}
                                         </IconButton>
                                     }
                    />
                    <div className={classes.rememberContainer}>
                        <CustomCheckbox name={'remember'} onClick={() => setRememberMe(!rememberMe)}
                                        selected={rememberMe}/>
                        <Typography variant={'h6'}>
                            {t('remember_me')}
                        </Typography>

                    </div>
                </div>


                <div className={classes.footer}>
                    <CustomButton primary type={'submit'}
                                  text={'Log in'}
                                  customClassName={classes.actionButton}
                                  variant={'body1'} disabled={!formValid || error}
                    />
                    <ClickableText text={t('forgot_password')} variant={'body1'}
                                   onClick={values['email'] ? handleForgotPassword : () => setEmptyEmailError(true)}
                                   underlined
                                   isLoading={forgotPasswordPending}

                    />
                </div>
            </form>
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        forgotPasswordPending: state.user.forgotPasswordPending,
    }
}

const mapDispatchToProps = {
    forgotPassword: forgotPassword
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
