import * as React from "react"

function CalendarSvgIcon(props) {
    return (
        <svg width={24} height={24} viewBox={'0 0 24 24'} {...props}>
            <title>{"Icons/Main/fullday"}</title>
            <g fillRule="nonzero" stroke="#333" fill="none">
                <path
                    d="M4 5.16h16V21H4zM20 9.48H4h16ZM8 3v4.32M16 3v4.32M7.636 11.64v1.44M7.636 14.52v1.44M7.636 17.4v1.44M10.545 11.64v1.44M10.545 14.52v1.44M10.545 17.4v1.44M13.455 11.64v1.44M13.455 14.52v1.44M13.455 17.4v1.44M16.364 11.64v1.44M16.364 14.52v1.44M16.364 17.4v1.44"/>
            </g>
        </svg>
    )
}

export default CalendarSvgIcon