import * as React from "react"
import PropTypes from "prop-types";

function MultipleFilterSvgIcon({size, stroke, ...props}) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                transform="translate(0 .588)"
                stroke={stroke}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path d="M31 25.235H19M13 25.235H9M21.5 13.588H9"/>
                <ellipse cx={24.5} cy={13.588} rx={3} ry={2.912}/>
                <ellipse cx={16} cy={25.235} rx={3} ry={2.912}/>
                <path d="M31 13.588h-3.5"/>
            </g>
        </svg>
    )
}

MultipleFilterSvgIcon.propTypes = {
    size: PropTypes.number,
    stroke: PropTypes.string,
}

MultipleFilterSvgIcon.defaultProps = {
    size: 24,
    stroke: '#333',
}

export default MultipleFilterSvgIcon
