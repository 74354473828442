import * as React from "react"
import PropTypes from 'prop-types'
import theme from "../../theme.js";

function PlusSvgIcon({color, size, props}) {
    return (
        <svg {...props} width={size} height={size} viewBox="0 0 24 24">
            <g fillRule="nonzero" stroke={color} fill="none">
                <path d="M4 12h16M12 20V4"/>
            </g>
        </svg>
    )
}

PlusSvgIcon.propTypes = {
    color: PropTypes.string,
}

PlusSvgIcon.defaultProps = {
    color: theme.colors.palette.neutral.darkMain,
}

export default PlusSvgIcon
