import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ActionListItem from "./ActionListItem.js";
import NavigationArrowSvgIcon from "../icons/NavigationArrowSvgIcon.js";
import TerminalActionListItem from "../../components/terminal/TerminalActionListItem.js";


const useStyles = props => makeStyles(theme => ({
    root: {
        '& .MuiAccordionSummary-expandIcon': {
            transform: 'none',
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: props.height ?? '7.1rem',
        },
        '& .MuiPaper-root': {
            backgroundColor: props.backgroundColor ?? theme.colors.palette.neutral.white
        }
    },
    accordion: {
        boxShadow: 'none !important',
    },
    summary: {
        height: props.height ?? '7.1rem',
        padding: 0,
        cursor: 'pointer',
    },
    summaryContent: {
        width: '90%',
    },
    details: {
        padding: 0,
        marginLeft: props.marginChildren + 'px',
    },
    disabled: {
        color: theme.colors.palette.neutral.greyMedium
    },
}));

function ActionListAccordion({
                                 text,
                                 unmount,
                                 children,
                                 disabled,
                                 startAdornment,
                                 endAdornment,
                                 initiallyExpanded,
                                 textSecondRow,
                                 textColorSecondRow,
                                 textSecondRowVariant,
                                 hideDivider,
                                 textColor,
                                 arrowColor,
                                 dividerColor,
                                 textVariant,
                                 isTerminal,
                                 ...props
                             }) {
    const classes = useStyles(props)(props.theme);

    const [expanded, setExpanded] = useState(!!initiallyExpanded);

    const getExpandIcon = () => {
        return expanded
            ? <NavigationArrowSvgIcon fill={arrowColor}
                                      size={props.startAdornmentWidth ? props.startAdornmentWidth / 2 : 16}
                                      style={{transform: 'rotate(90deg)'}}/>
            : <NavigationArrowSvgIcon fill={arrowColor}
                                      size={props.startAdornmentWidth ? props.startAdornmentWidth / 2 : 16}
                                      style={{transform: 'rotate(-90deg)'}}/>
    }

    useEffect(() => {
        setExpanded(initiallyExpanded)
    }, [initiallyExpanded])

    return (
        <div className={classes.root}>
            <Accordion className={classes.accordion} TransitionProps={{unmountOnExit: true}} expanded={expanded}>
                <AccordionSummary classes={{root: classes.summary, content: classes.summaryContent}}
                                  onClick={() => setExpanded(!expanded)}>
                    {isTerminal
                        ? <TerminalActionListItem
                            hideDivider={hideDivider}
                            textVariant={textVariant}
                            textSecondRow={textSecondRow}
                            textSecondRowVariant={textSecondRowVariant}
                            textColorSecondRow={textColorSecondRow}
                            startAdornment={startAdornment}
                            textColor={textColor}
                            text={text}
                            arrowColor={arrowColor}
                            dividerColor={dividerColor}
                            centerAdornment={getExpandIcon()}
                            endAdornment={endAdornment}
                            height={props.height}
                        />
                        : <ActionListItem
                            hideDivider={hideDivider}
                            textVariant={textVariant}
                            textSecondRow={textSecondRow}
                            textSecondRowVariant={textSecondRowVariant}
                            textColorSecondRow={textColorSecondRow}
                            startAdornment={startAdornment}
                            textColor={textColor}
                            text={text}
                            arrowColor={arrowColor}
                            dividerColor={dividerColor}
                            centerAdornment={getExpandIcon()}
                            endAdornment={endAdornment}
                            height={props.height}
                        />}
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

ActionListAccordion.propTypes = {
    text: PropTypes.string,
    unmount: PropTypes.bool,
    children: PropTypes.node,
    startAdornment: PropTypes.element,
    endAdornment: PropTypes.element,
    startAdornmentWidth: PropTypes.number,
    height: PropTypes.number,
    initiallyExpanded: PropTypes.bool,
    hideDivider: PropTypes.bool,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    textVariant: PropTypes.string,
    textSecondRow: PropTypes.string,
    textColorSecondRow: PropTypes.string,
    textSecondRowVariant: PropTypes.string,
    marginChildren: PropTypes.number,
    dividerColor: PropTypes.string,
    arrowColor: PropTypes.string,
    isTerminal: PropTypes.bool
};

ActionListAccordion.defaultProps = {
    initiallyExpanded: false,
    hideDivider: false,
    marginChildren: 66,
    textVariant: 'body1',
    isTerminal: false
}

export default ActionListAccordion;