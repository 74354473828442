const initState = {
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
};

export function serviceWorkerReducer(state = initState, action) {

    switch (action.type) {
        case 'SERVICE_WORKER_UPDATE':
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            }

        case 'RESET_SERVICE_WORKER_UPDATE':
            return {
                ...state,
                serviceWorkerUpdated: false,
            }

        default:
            return state
    }
}