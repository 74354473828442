import React from 'react';

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy";
import moment from "moment";
import {getDateAtHours, getHalfTime, getTimeAsString} from "../../common/utils/TimeUtils.js";
import {TYPE_OF_ACTIVITY_UNDEFINED} from "../../common/utils/NameUtils.js";
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center'
    },
    inline: {
        display: "flex",
        alignItems: 'center',
        gap: theme.innerSmallGap
    },

}))

function OccupancyTimeExplenation({
                                      startTime,
                                      endTime,
                                  }) {
    const theme = useTheme()
    const classes = useStyle(theme);

    const halfTime = getHalfTime(startTime, endTime)
    const quarterTime = getHalfTime(startTime, halfTime)
    const threeQuarterTime = getHalfTime(halfTime, endTime)

    return (
        <div className={classes.root}>
            <div className={classes.inline}>
                <Typography>{getTimeAsString(endTime)}</Typography>
                <Typography>|</Typography>
                <Typography>{getTimeAsString(startTime)}</Typography>
            </div>
            <div className={classes.inline}>
                <Typography>{getTimeAsString(threeQuarterTime)}</Typography>
                <AvatarWithOccupancy size={150} hideAvailabilityStatus
                                     showOccupancy
                                     preventInitials
                                     workplace={{activityBestMatch: TYPE_OF_ACTIVITY_UNDEFINED}}
                                     showFrom={startTime}
                                     showUntil={endTime}
                                     assignments={[{
                                         timePeriod: {
                                             startDate: getDateAtHours(moment(), 9),
                                             endDate: getDateAtHours(moment(), 17)
                                         }
                                     }]}/>
                <Typography>{getTimeAsString(quarterTime)}</Typography>
            </div>
            <Typography>{getTimeAsString(halfTime)}</Typography>
        </div>
    )
}

export default OccupancyTimeExplenation