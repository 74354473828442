import React, {useEffect} from 'react';

import clsx from "clsx";
import {connect} from "react-redux";
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import {AppBar, Typography, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import UpdateModal from "./UpdateModal.js";
import ProfileSvgIcon from "../common/icons/ProfileSvgIcon";
import NavigationBarBackgroundHomescreenSvg from "../common/icons/NavigationBarBackgroundHomescreenSvg";

import {
    navigate,
    PATH_FORGOT_PASSWORD,
    PATH_HOME,
    PATH_LOGIN,
    PATH_PROFILE,
    PATH_RESET_PASSWORD,
    PATH_RESET_PASSWORD_SUCCESSFULL
} from "../common/utils/NavigationUtils";
import {animated, useSpring} from "react-spring";
import GoconutLogo from "../common/img/goconutLogo.png";
import StatusPopUp from "../common/elements/AvailabilityStatusPopUp.js";
import {fetchAvailabilityStatus} from "../actions/user-action.js";
import NavigationArrowSvgIcon from "../common/icons/NavigationArrowSvgIcon.js";
import {useTranslation} from "react-i18next";
// import Snowfall from "react-snowfall";

const styles = theme => ({
    appbar: {
        backgroundColor: 'transparent !important',
        boxSizing: 'border-box',
        boxShadow: 'none !important',
    },
    toolbarContent: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        boxSizing: 'border-box',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '66px 18px 0 18px',
    },
    actions: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        padding: '1.8rem',
        boxSizing: 'border-box',
        position: 'fixed',
        top: '0px',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 5,
        '& .MuiIconButton-root': {
            padding: 0,
            margin: 0,
        }
    },
    backgroundImage: {
        zIndex: 1,
        margin: 'auto',
        position: 'absolute',
        width: '100%',
        maxWidth: '800px',
        height: '75px',
        minHeight: '7.6rem !important'
    },
    backgroundImageHomescreen: {
        height: theme.header.big.height,
    },
    fixedBackgroundImg: {
        top: '0px',
        position: 'fixed'
    },
    ticketIcon: {
        height: '40px',
        width: '40px',
        boxSizing: 'border-box',
        backgroundColor: theme.colors.palette.neutral.white,
        padding: '8px',
        borderRadius: '12px',
        boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.15)',
        cursor: 'pointer',
        fill: theme.colors.palette.neutral.darkMain,
    },
    statusAndProfile: {
        display: 'flex',
        alignItems: 'center',
        gap: '1.2rem'
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        gap: '.6rem',
        cursor: 'pointer',
        maxWidth: '12rem',
    },
    capitalize: {
        maxWidth: '90%',
        textTransform: 'capitalize'
    }
})

function NavigationBarDrawer({
                                 classes,
                                 user,
                                 userId,
                                 availabilityStatus,
                                 fetchAvailabilityStatus,
                                 content,
                                 isAnimated
                             }) {
    const [StatusPopUpOpen, setStatusPopUpOpen] = React.useState(false)
    const location = useLocation()
    const history = useHistory()
    const theme = useTheme()
    const {t} = useTranslation();

    const smallBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

    // const [snow, setSnow] = useState(false);
    const requiredTaps = 5

    let taps = 0
    let clickTimeout = false
    let timeout
    // let myInterval = null

    useEffect(() => {
        if (availabilityStatus === null && userId) {
            fetchAvailabilityStatus(userId)
        }
        // eslint-disable-next-line
    }, [userId])

    const handleStatusPopUp = () => {
        setStatusPopUpOpen(!StatusPopUpOpen)
    }

    function handleIconClicked() {
        if (user.userName && location.pathname !== PATH_HOME) {
            navigate(history, PATH_HOME)
        } else if (!user || !user.userName) {
            navigate(history, PATH_LOGIN)
        } else if (location.pathname === '/') {
            if (taps === requiredTaps) {
                //activate easter egg
                // setSnow(true)
                // setTimeout(function () {
                //     setSnow(false)
                //     taps = 0
                //     deactivate easter egg
                // }, 30000);
                //
                // #halloween
                // let appContainer = document.getElementById('appContainer')
                // if (appContainer) {
                //     myInterval = setInterval(function () {
                //         appContainer.style.filter = 'hue-rotate(' + rotation++ + 'deg) invert(1)'
                //         rotation += 15
                //     }, 100);
                // }
                //
                // setTimeout(function () {
                //     taps = 0
                //     appContainer.style.filter = 'none'
                //     clearInterval(myInterval);
                //     myInterval = null
                //     rotation = 45
                //
                //     // deactivate easter egg
                // }, 6000);
            } else if (taps === 1 || (taps < requiredTaps && clickTimeout === true)) {
                taps++
                clickTimeout = true
                clearTimeout(timeout)
                timeout = setTimeout(() => {
                    clickTimeout = false
                }, 1500)
            } else {
                taps = 1
            }
        }
    }

    function handleProfileClicked() {
        navigate(history, PATH_PROFILE)
    }

    function showProfileIcon() {
        const path = location.pathname

        return !(path === PATH_LOGIN || path === PATH_FORGOT_PASSWORD || path === PATH_RESET_PASSWORD || path === PATH_RESET_PASSWORD_SUCCESSFULL);
    }

    const contentProps = useSpring({
        width: '100%',
        opacity: isAnimated ? 0 : 1,
        immediate: isAnimated
    });

    return (
        <AppBar position={'sticky'}
                style={{top: content ? -170 : 0, minHeight: content ? theme.header.big.height : theme.header.minHeight}}
                className={classes.appbar}>
            <NavigationBarBackgroundHomescreenSvg r={smallBreakpoint ? 18 : 12}
                                                  className={clsx(classes.backgroundImage, content ? classes.backgroundImageHomescreen : classes.fixedBackgroundImg)}
            />

            {/*{snow &&*/}
            {/*    <Snowfall style={{zIndex: 20, height: '100vh'}} speed={[0, 1.5]} wind={[-1, 0.5]} radius={[1, 5]}/>*/}
            {/*}*/}

            <div className={classes.actions}>
                <IconButton
                    color="inherit"
                    edge="start"
                    style={{backgroundColor: 'transparent', borderRadius: '8px'}}
                    onClick={handleIconClicked}
                >
                    <img src={GoconutLogo} alt='' width={'144px'}/>
                </IconButton>

                {showProfileIcon() ?
                    <div className={classes.statusAndProfile}>
                        <div className={classes.status} onClick={handleStatusPopUp}>
                            <Typography className={classes.capitalize} noWrap variant={'h6'}>
                                {availabilityStatus
                                    ? availabilityStatus.customText
                                        ? availabilityStatus.customText
                                        : t(availabilityStatus.statusText.toLowerCase())
                                    : ''}
                            </Typography>
                            <NavigationArrowSvgIcon fill={theme.colors.palette.neutral.greyMain} strokeWidth={.5}
                                                    style={{transform: 'rotate(-90deg)', minWidth: '16px'}}/>
                        </div>
                        <IconButton onClick={handleProfileClicked}>
                            <ProfileSvgIcon className={classes.ticketIcon}/>
                        </IconButton>
                    </div> : null}
            </div>
            <div className={content && classes.toolbarContent}>
                {contentProps.opacity !== 0
                    ? <animated.div style={contentProps}>{content}</animated.div>
                    : null}
            </div>
            <UpdateModal/>
            <StatusPopUp open={StatusPopUpOpen}
                         onCloseCallback={handleStatusPopUp}/>
        </AppBar>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        userId: state.user.person.id,
        availabilityStatus: state.user.availabilityStatus,
    }
}

const mapDispatchToProps = {
    fetchAvailabilityStatus: fetchAvailabilityStatus,
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBarDrawer)))
