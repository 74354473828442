import * as React from "react"

function PasswordSvgIcon(props) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={props.stroke || '#333'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path d="M14.803 29.304a2.75 2.75 0 11-3.89-3.889 2.75 2.75 0 013.89 3.889z" />
                <path d="M31.137 8.303l.39 5.056-2.042 2.041-1.459-.097.098 1.458-.681.681-1.458-.097.097 1.458-.68.68-1.46-.096.098 1.458-1.523 1.523a8.802 8.802 0 01-14.326 9.659 8.8 8.8 0 018.5-14.725l9.39-9.388 5.055.389z" />
            </g>
        </svg>
    )
}

export default PasswordSvgIcon