import {applyMiddleware, compose, createStore} from 'redux'
import reducers from './reducers'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import * as Sentry from "@sentry/react";

const middleware = applyMiddleware(thunk, promise)
const sentryEnhancer = Sentry.createReduxEnhancer({
    actionTransformer(action) {
        if (action.type === "GET_PROFILE_PICTURE_OF_COWORKER_FULFILLED") {
            return null
        }

        if (action.type === "GET_PERSON_FULFILLED") {
            Sentry.setUser(action.payload.data.id)
        }
    },
    stateTransformer(state) {
        //remove profile picture
        return {
            ...state,
            user: null,
            occupancy: {
                ...state.occupancy,
                profilePicturesBase64: null,
            },
            floors: {
                ...state.floors,
                floorPlanImages: null,
            },
            drawing: {
                ...state.drawing,
                image: null,
            }
        }
    }
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(middleware, sentryEnhancer)

const store = createStore(reducers, enhancer)

export default store
