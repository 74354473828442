import {drawingTypes} from "../components/drawingTool/drawing/drawConstants";
import store from '../store'
import {floorPlanActionTypes} from "../components/drawingTool/constants";
import {toolsTree} from "../components/drawingTool/drawing/drawingTabsAndTools";
import {clone} from "lodash";

const geometries = {
    drawScale: [],
    drawOutline: [],
    drawWalls: [],
    drawDoors: [],
    drawPillars: [],
    drawSpaces: [],
    drawWorkplaces: [],
    drawTerminals: [],
    selectedGeometryId: null,
}

const initialState = {
    image: {
        base64Image: '',
        scale: 1,
    },
    settings: {
        snapDistance: .5,
        polygonCloseDistance: .5,
    },
    markerSize: {
        door: .45,
        pillar: .3,
    },

    currentDrawingTab: null,
    selectedTool: null,
    selectedElement: null,

    storedGeometries: [geometries],
    currentStoreIndex: 0,
    undoAvailable: false,
    redoAvailable: false,
}

function drawingReducer(state = initialState, action) {

    let selected;

    switch (action.type) {
        case 'DRAWING_SET_SELECTED_ELEMENT':
            return {
                ...state,
                selectedElement: action.payload,
            }

        case 'TERMINAL_ADD_ANNOUNCEMENT_FULFILLED':

            selected = clone(state.selectedElement)
            let saved = action.payload.data


            if (selected.terminal && selected.terminal.accessCode === saved.accessCode) {
                selected.terminal = saved
            }

            return {
                ...state,
                selectedElement: selected
            }

        case 'TERMINAL_DELETE_ANNOUNCEMENT_FULFILLED':

            selected = clone(state.selectedElement)

            selected.terminal = action.payload.data

            return {
                ...state,
                selectedElement: selected
            }

        case 'SET_CURRENT_DRAWING_TAB':
            let tab = toolsTree.find(item => item.tab === action.payload)
            if (!tab)
                return {...state}

            let tool = tab.tools[0]

            return {
                ...state,
                currentDrawingTab: action.payload,
                selectedTool: tool,
            }

        case 'SET_SELECTED_DRAWING_TOOL':
            return {
                ...state,
                selectedTool: action.selectedTool,
            }

        case 'SET_FLOOR': {
            if (!action.meta)
                return {...state}

            let drawScale = []
            let drawOutline = []
            let drawWalls = []
            let drawDoors = []
            let drawPillars = []
            let drawSpaces = []
            let drawWorkplaces = []
            let drawTerminals = []

            if (action.payload) {

                drawScale = action.payload.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.scale)
                drawOutline = action.payload.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.outline)
                drawWalls = action.payload.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.wall)
                drawDoors = action.payload.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.door)
                drawPillars = action.payload.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.pillar)

                drawSpaces = action.payload.spaces
                    .filter(space => space.outline != null)
                    .map(space => {
                        let geometry = space.outline
                        geometry.space = space
                        return geometry
                    })

                drawTerminals = action.payload.terminals
                    .map(terminal => {
                        let geometry = terminal.geometry
                        geometry.terminal = terminal
                        return terminal
                    })

                for (let space of action.payload.spaces) {
                    drawWorkplaces.push(...space.workplaces)
                }
            }

            const geometries = {
                drawScale: drawScale,
                drawWalls: drawWalls,
                drawOutline: drawOutline,
                drawDoors: drawDoors,
                drawPillars: drawPillars,
                drawSpaces: drawSpaces,
                drawWorkplaces: drawWorkplaces,
                drawTerminals: drawTerminals,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
                image: {
                    base64Image: state.image.base64Image,
                    scale: action.payload.imageScale
                }
            }
        }
        case 'SAVE_FLOOR_GEOMETRIES_FULFILLED': {
            let drawScale = []
            let drawOutline = []
            let drawWalls = []
            let drawDoors = []
            let drawPillars = []

            if (action.payload.data) {

                drawScale = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.scale)
                drawOutline = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.outline)
                drawWalls = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.wall)
                drawDoors = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.door)
                drawPillars = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.pillar)

            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawScale: drawScale,
                drawWalls: drawWalls,
                drawOutline: drawOutline,
                drawDoors: drawDoors,
                drawPillars: drawPillars,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
                image: {
                    base64Image: state.image.base64Image,
                    scale: action.payload.data.imageScale
                }
            }
        }
        case 'GET_FLOOR_FULFILLED':
        case 'SET_IMAGE_SCALE_FULFILLED':
            let drawScale = []
            let drawOutline = []
            let drawWalls = []
            let drawDoors = []
            let drawPillars = []
            let drawSpaces = []
            let drawWorkplaces = []
            let drawTerminals = []

            if (action.payload.data) {

                drawScale = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.scale)
                drawOutline = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.outline)
                drawWalls = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.wall)
                drawDoors = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.door)
                drawPillars = action.payload.data.geometries.filter(geometry => geometry.drawingRepresentationType === drawingTypes.pillar)

                drawSpaces = action.payload.data.spaces
                    .filter(space => space.outline != null)
                    .map(space => {
                        let geometry = space.outline
                        geometry.space = space
                        return geometry
                    })

                drawTerminals = action.payload.data.terminals
                    .map(terminal => {
                        let geometry = terminal.geometry
                        geometry.terminal = terminal
                        return terminal
                    })

                for (let space of action.payload.data.spaces) {
                    drawWorkplaces.push(...space.workplaces)
                }
            }

            const geometries = {
                drawScale: drawScale,
                drawWalls: drawWalls,
                drawOutline: drawOutline,
                drawDoors: drawDoors,
                drawPillars: drawPillars,
                drawSpaces: drawSpaces,
                drawWorkplaces: drawWorkplaces,
                drawTerminals: drawTerminals,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
                image: {
                    base64Image: state.image.base64Image,
                    scale: action.payload.data.imageScale
                }
            }

        case 'CREATE_SPACES_FULFILLED': {
            let drawSpaces = []

            if (action.payload.data) {

                drawSpaces = action.payload.data
                    .filter(space => space.outline != null)
                    .map(space => {
                        let geometry = space.outline
                        geometry.space = space
                        geometry.space.outline = null
                        return geometry
                    })

            }

            state.storedGeometries[state.currentStoreIndex].drawSpaces
                .filter(item => item.space && item.space.id != null)
                .forEach(space => drawSpaces.push(space))

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawSpaces: drawSpaces,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'UPDATE_SPACES_FULFILLED': {
            let drawSpaces = []

            state.storedGeometries[state.currentStoreIndex].drawSpaces.forEach(space => drawSpaces.push(space))

            if (action.payload.data) {
                for (let updatedSpace of action.payload.data) {
                    let index = drawSpaces.findIndex(geometry => geometry.space && geometry.space.id === updatedSpace.id)
                    if (index > -1) {
                        let geometry = updatedSpace.outline
                        geometry.space = updatedSpace
                        geometry.space.outline = null
                        drawSpaces[index] = geometry
                    }
                }
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawSpaces: drawSpaces,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'ADD_CALENDAR_TO_SPACE_FULFILLED': {
            let drawSpaces = []

            state.storedGeometries[state.currentStoreIndex].drawSpaces.forEach(space => drawSpaces.push(space))
            let updatedSpace

            if ((updatedSpace = action.payload.data.data)) {
                let index = drawSpaces.findIndex(geometry => geometry.space && geometry.space.id === updatedSpace.id)
                if (index > -1) {
                    let geometry = updatedSpace.outline
                    geometry.space = updatedSpace
                    geometry.space.outline = null
                    drawSpaces[index] = geometry
                }
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawSpaces: drawSpaces,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'DELETE_SPACES_FULFILLED': {
            let drawSpaces = []
            let updatedWorkplaces = [...state.storedGeometries[state.currentStoreIndex].drawWorkplaces]

            state.storedGeometries[state.currentStoreIndex].drawSpaces
                .forEach(geometry => drawSpaces.push(geometry))

            for (let deletedSpace of action.payload.data) {
                let index = drawSpaces.findIndex(geometry => geometry.space && geometry.space.id === deletedSpace.id)
                if (index > -1) {
                    drawSpaces.splice(index, 1);
                }

                if (deletedSpace.workplaces) {
                    deletedSpace.workplaces.forEach(workplace => {
                        let workplaceIndex = updatedWorkplaces.findIndex(w => w.workplace.id === workplace.id);
                        if (workplaceIndex > -1) {
                            updatedWorkplaces.splice(workplaceIndex, 1);
                        }
                    })
                }
            }

            const geometries = {
                drawScale: state.storedGeometries[state.currentStoreIndex].drawScale,
                drawOutline: state.storedGeometries[state.currentStoreIndex].drawOutline,
                drawWalls: state.storedGeometries[state.currentStoreIndex].drawWalls,
                drawDoors: state.storedGeometries[state.currentStoreIndex].drawDoors,
                drawPillars: state.storedGeometries[state.currentStoreIndex].drawPillars,
                drawTerminals: state.storedGeometries[state.currentStoreIndex].drawTerminals,
                drawSpaces: drawSpaces,
                drawWorkplaces: updatedWorkplaces,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'CREATE_WORKPLACES_FULFILLED': {
            let drawWorkplaces = []

            state.storedGeometries[state.currentStoreIndex].drawWorkplaces.forEach(workplace => {
                if (workplace.id || workplace.geometryId)
                    drawWorkplaces.push(workplace)
            })

            if (action.payload.data) {
                drawWorkplaces.push(...action.payload.data)
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawWorkplaces: drawWorkplaces,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'CHANGE_WORKPLACE_STATUS_FULFILLED':
        case 'UPDATE_WORKPLACES_FULFILLED': {
            let drawWorkplaces = []

            state.storedGeometries[state.currentStoreIndex].drawWorkplaces.forEach(workplace => drawWorkplaces.push(workplace))

            if (action.payload.data) {
                for (let updatedWorkplace of action.payload.data) {
                    let index = drawWorkplaces.findIndex(geometry => geometry.id === updatedWorkplace.id)
                    if (index > -1) {
                        drawWorkplaces[index] = updatedWorkplace
                    }
                }
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawWorkplaces: drawWorkplaces,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'DELETE_WORKPLACES_FULFILLED': {
            let drawWorkplaces = []
            state.storedGeometries[state.currentStoreIndex].drawWorkplaces.forEach(workplace => drawWorkplaces.push(workplace))

            if (action.payload.data) {
                for (let deletedWorkplace of action.payload.data) {
                    let index = drawWorkplaces.findIndex(geometry => geometry.workplace.id === deletedWorkplace.id)
                    if (index > -1) {
                        drawWorkplaces.splice(index, 1)
                    }
                }
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawWorkplaces: drawWorkplaces,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'TERMINAL_CREATE_TERMINALS_FULFILLED': {
            let drawTerminals = []

            state.storedGeometries[state.currentStoreIndex].drawTerminals.forEach(terminal => {
                if (terminal.id || terminal.geometryId)
                    drawTerminals.push(terminal)
            })

            if (action.payload.data) {
                drawTerminals.push(...action.payload.data)
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawTerminals: drawTerminals,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'TERMINAL_UPDATE_TERMINALS_FULFILLED': {
            let drawTerminals = []

            state.storedGeometries[state.currentStoreIndex].drawTerminals.forEach(terminal => drawTerminals.push(terminal))

            if (action.payload.data) {
                for (let updatedTerminal of action.payload.data) {
                    let index = drawTerminals.findIndex(geometry => geometry.id === updatedTerminal.id)
                    if (index > -1) {
                        drawTerminals[index] = updatedTerminal
                    }
                }
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawTerminals: drawTerminals,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'TERMINAL_DELETE_TERMINALS_FULFILLED': {
            let drawTerminals = []

            state.storedGeometries[state.currentStoreIndex].drawTerminals.forEach(terminal => drawTerminals.push(terminal))

            if (action.payload.data) {
                for (let deletedTerminal of action.payload.data) {
                    let index = drawTerminals.findIndex(geometry => geometry.id === deletedTerminal.id)
                    if (index > -1) {
                        drawTerminals.splice(index, 1)
                    }
                }
            }

            const geometries = {
                ...state.storedGeometries[state.currentStoreIndex],
                drawTerminals: drawTerminals,
                selectedGeometryId: null,
            }

            return {
                ...state,
                currentStoreIndex: 0,
                storedGeometries: [geometries],
                undoAvailable: false,
                redoAvailable: false,
            }
        }

        case 'GET_FLOORPLAN_IMAGE_FULFILLED':
            const image = {
                base64Image: action.payload.data.image.startsWith('data:image/png;base64,') ? action.payload.data.image : 'data:image/png;base64,' + action.payload.data.image,
                scale: action.payload.data.scale,
            }

            return {
                ...state,
                image: image,
            }

        case 'UPLOAD_IMAGE_FULFILLED': {
            const image = {
                base64Image: action.payload.data.image.startsWith('data:image/png;base64,') ? action.payload.data.image : 'data:image/png;base64,' + action.payload.data.image,
                scale: state.image.scale,
            }
            return {
                ...state,
                image: image,
            }
        }

        case floorPlanActionTypes.FETCH_CURRENT_DRAWING_TAB_FULFILLED:
            const currentDrawingTab = action.payload.data.toLowerCase()
            return {
                ...state,
                currentDrawingTab: currentDrawingTab,
            }

        case 'STORE_GEOMETRY_CHANGES':
            const storedGeometries = state.storedGeometries.splice(0, state.currentStoreIndex + 1)

            storedGeometries.push(action.payload)

            const currentStoreIndex = storedGeometries.length - 1

            const undoAvailable = currentStoreIndex > 0 && storedGeometries.length > 1

            return {
                ...state,
                storedGeometries: storedGeometries,
                currentStoreIndex: currentStoreIndex,
                undoAvailable: undoAvailable,
                redoAvailable: false,
            }
        case 'UNDO_GEOMETRY_CHANGE':
            const undoStoreIndex = state.currentStoreIndex > 0 ? state.currentStoreIndex - 1 : 0

            const undoAvailableAfterUndo = undoStoreIndex > 0 && state.storedGeometries.length > 1
            const redoAvailableAfterUndo = undoStoreIndex < state.storedGeometries.length - 1

            return {
                ...state,
                currentStoreIndex: undoStoreIndex,
                undoAvailable: undoAvailableAfterUndo,
                redoAvailable: redoAvailableAfterUndo,
            }
        case 'REDO_GEOMETRY_CHANGE':
            const redoStoreIndex = state.currentStoreIndex + 1 < state.storedGeometries.length ? state.currentStoreIndex + 1 : state.currentStoreIndex

            const undoAvailableAfterRedo = redoStoreIndex > 0 && state.storedGeometries.length > 1
            const redoAvailableAfterRedo = redoStoreIndex < state.storedGeometries.length - 1

            return {
                ...state,
                currentStoreIndex: redoStoreIndex,
                undoAvailable: undoAvailableAfterRedo,
                redoAvailable: redoAvailableAfterRedo,
            }
        case 'RESET_GEOMETRIES': {
            return {
                ...state,
                storedGeometries: [state.storedGeometries[0]],
                currentStoreIndex: 0,
                undoAvailable: false,
                redoAvailable: false,
            }
        }
        case 'SET_MARKER_SIZE':
            const markerSize = state.markerSize

            if (markerSize.hasOwnProperty(action.markerType)) {
                markerSize[action.markerType] = action.size
            }

            return {
                ...state,
                markerSize: markerSize,
            }
        case 'CLEAR_PROJECT':
            return {...initialState}

        case 'SET_FLOORPLAN_BASE64': {
            const image = {
                base64Image: action.payload,
                scale: state.image.scale !== 0 ? state.image.scale : 1,
            }
            return {
                ...state,
                image: image
            }
        }

        default:
            return state
    }
}

export default drawingReducer

export function getCurrentGeometryObjects(state) {
    return state.drawing.currentStoreIndex < state.drawing.storedGeometries.length ? state.drawing.storedGeometries[state.drawing.currentStoreIndex] : []
}

export function getGeometryObjectsToSave() {
    const state = store.getState()

    const storedGeometries = getCurrentGeometryObjects(state)

    const geometries = [];

    ['drawScale',
        'drawOutline',
        'drawWalls',
        'drawDoors',
        'drawPillars',
        'drawSpaces',
        'drawWorkplaces',
        'drawTerminals'].forEach(property => {
            storedGeometries[property].forEach(element => {
                geometries.push(element)
            })
        },
    )

    return geometries
}