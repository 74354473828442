import * as React from "react"

import useTheme from "@material-ui/core/styles/useTheme";

function HomeOfficeTippsStandUpSvg(props) {

    const theme = useTheme()

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={288} height={226} viewBox="0 0 288 226"{...props}>
            <defs>
                <linearGradient id="prefix__a" x1="22.792%" x2="39.396%" y1="52.108%" y2="59.648%">
                    <stop offset="0%" stopColor="#FF7942"/>
                    <stop offset="100%" stopColor="#FFB082"/>
                </linearGradient>
                <linearGradient id="prefix__b" x1="38.555%" x2="63.196%" y1="13.447%" y2="74.326%">
                    <stop offset="0%" stopColor="#333"/>
                    <stop offset="100%"/>
                </linearGradient>
                <linearGradient id="prefix__c" x1="20.686%" x2="77.743%" y1="34.628%" y2="75.335%">
                    <stop offset="0%" stopColor="#FF7942"/>
                    <stop offset="100%" stopColor="#FFB082"/>
                </linearGradient>
                <linearGradient id="prefix__d" x1="5%" x2="80%" y1="70%" y2="35%">
                    <stop offset="0%" stopColor={theme.colors.palette.corporate.greenLightest}/>
                    <stop offset="100%" stopColor={theme.colors.palette.corporate.greenLight}/>
                </linearGradient>
                <linearGradient id="prefix__e" x1="15.639%" x2="89.615%" y1="27.64%" y2="64.881%">
                    <stop offset="0%" stopColor="#333"/>
                    <stop offset="100%"/>
                </linearGradient>
            </defs>
            <g fillRule="nonzero" fill="none">
                <path
                    fill="url(#prefix__a)"
                    d="M34.284 126.653l11.092 11.323a99.999 99.999 0 0057.406 29.034c92.102 13.053 145.668 8.46 160.698-13.777 12.69-18.776 10.115-44.703-7.726-77.78-11.29-20.93-34.567-32.448-58.055-28.725l-82.235 13.036a100.117 100.117 0 00-11.524 2.532l-56.79 16.04C31.7 82.7 22.711 98.762 27.075 114.212a29.056 29.056 0 007.208 12.44z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="url(#prefix__b)"
                    d="M58.4 82.367c-1.395 7.64-2.57 15.725-3.523 24.257a428.404 428.404 0 00-2.547 39.54c-.356 18.965 14.73 34.626 33.693 34.982a34.347 34.347 0 0017.774-4.57l24.637-14.179a48.445 48.445 0 0024.282-41.989 57.727 57.727 0 00-22.009-45.35l-17.186-13.536c-14.924-11.754-36.551-9.184-48.306 5.74A34.395 34.395 0 0058.4 82.367z"
                    transform="translate(.779 .847)"
                />
                <path
                    fill="url(#prefix__c)"
                    d="M60.231 53.19a38.224 38.224 0 0111.193-19.957l4.823-4.545c4.253-4.008 10.95-3.809 14.958.444a10.584 10.584 0 012.628 4.963L96.966 48.2c1.895 8.535-3.487 16.99-12.022 18.886-1.7.377-3.45.472-5.18.28l-10.082-1.12c-5.975-.664-10.28-6.045-9.617-12.02.039-.349.094-.695.166-1.038z"
                    transform="rotate(-130 80.352 43.107)"
                />
                <path
                    fill="url(#prefix__d)"
                    d="M47.875 72.035c5.669 18.356 9.056 32.642 10.16 42.858 5.455 50.42 50.75 86.871 101.17 81.417a91.829 91.829 0 0052.656-24.05c20.698-19.248 34.085-34.001 40.162-44.26 14.774-24.943 6.531-57.14-18.412-71.914a52.455 52.455 0 00-16.622-6.342l-83.373-16.397a99.964 99.964 0 00-16.75-1.847l-37.556-.957c-17.693-.45-32.401 13.527-32.852 31.22a32.025 32.025 0 001.417 10.272z"
                    transform="matrix(-1 0 0 1 307.055 .847)"
                />
                <path
                    d="M167.479 83.647l3.4.4c.7.1 1.3-.4 1.4-1.1l1-9.2 1.6 1.1c1.5 1.1 2.4 2.8 2.4 4.7v9.7h-3.8c-.7 0-1.3.6-1.3 1.3v2.7h14.4v-2.7c0-.7-.6-1.3-1.3-1.3h-3.5v-9.7c0-3.3-1.6-6.4-4.3-8.3l-3.6-2.5 1.4-12.5c.1-.7-.4-1.3-1.1-1.4l-3.4-.5c-.7-.1-1.3.4-1.4 1.1l-3 26.8c-.1.7.4 1.3 1.1 1.4zm25.3 12.6h-47.4c-1.3 0-2.5.8-2.8 2.1-.5 1.8.9 3.5 2.7 3.5h21v42h-6.8c-.3 0-.7.1-1 .1l-7 1.8c-.5.1-.9.6-.9 1.1v1.3c0 .6.5 1.1 1.1 1.1h34.6c.6 0 1.1-.5 1.1-1.1v-1.3c0-.5-.4-1-.9-1.1l-7-1.8c-.3 0-.7-.1-1-.1h-6.7v-42h20.9c1.3 0 2.5-.8 2.8-2.1.4-1.8-.9-3.5-2.7-3.5zm-58.6 12.8c.5.5 1.3.8 2 .8s1.4-.3 2-.8c1.1-1.1 1.1-2.8 0-3.9l-8.6-8.6c-1-1-2.9-1-3.9 0l-8.6 8.6c-1.1 1.1-1.1 2.8 0 3.9s2.8 1.1 3.9 0l3.9-3.9v34.6l-4.1-4c-1.1-1.1-2.8-1.1-3.9 0s-1.1 2.8 0 3.9l8.6 8.6c.5.5 1.3.8 2 .8s1.4-.3 2-.8l8.6-8.6c1.1-1.1 1.1-2.8 0-3.9s-2.8-1.1-3.9 0l-3.9 3.9v-34.5l3.9 3.9z"
                    fill="#FFF"
                />
                <path
                    fill="url(#prefix__e)"
                    d="M24.674 136.984a6.18 6.18 0 013.992-9.466l3.225-.622c6.972-1.347 13.826 2.823 15.837 9.633l.673 2.28a6.593 6.593 0 01-7.746 8.302l-1.89-.418a22.749 22.749 0 01-14.091-9.709z"
                    transform="rotate(15 32.752 140.403)"
                />
                <path
                    fill="url(#prefix__d)"
                    d="M92.003 31.047l-1.285-2.598a7.647 7.647 0 015.223-10.861 19.866 19.866 0 0117.042 4.22L117.956 26a7.66 7.66 0 01-3.576 13.393l-4.27.771a16.861 16.861 0 01-18.107-9.117z"
                    transform="translate(.779 .847)"
                />
            </g>
        </svg>
    )
}

export default HomeOfficeTippsStandUpSvg