import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {withFormik} from "formik";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {bindActionCreators, compose} from "redux";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select/Select";
import InputBase from "@material-ui/core/InputBase/InputBase";

import NameComponent from "./NameComponent.js";
import AdminButton from "../../../common/elements/AdminButton.js";
import QRCodeExportModal from "../../qrCodeGeneration/QRCodeExportModal.js";
import QRCodesToExportSectionWithName from "../../qrCodeGeneration/QRCodesToExportSectionWithName.js";

import {getTypesOfUse} from "../../../actions/common-action.js";
import {TYPE_OF_USE_MEETING} from "../../../common/utils/NameUtils.js";
import {setElement} from "../../../actions/modalElementSkeleton-actions.js";

const styles = theme => ({
    root: {
        maxHeight: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: '1.2rem',
        ...theme.modalContentInput,
    },
    panel: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        textAlign: 'left',
        gap: '.6rem',
        padding: 0,
        '& .MuiInputBase-input': {
            height: '3.6rem',
            boxSizing: 'border-box',
            minWidth: '100%',
        }
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    menuItem: {
        color: theme.colors.palette.neutral.darkMain,
        fontSize: '14px'
    },
    select: {
        border: '0px',
        width: '100%',
        '&::before': {
            borderBottom: '0px',
        },
        '&&&&:hover:before': {
            borderBottom: '0px',
        },
        fontSize: '14px',
    },
})

function SpaceInfoComponent({
                                classes,
                                element,
                                typesOfUse,
                                getTypesOfUse,
                                values,
                                setFieldValue,
                                handleSubmit,
                                onValidationCorrect,
                                onSave,
                                disableAutofocus,
                                update,
                                t, ...props
                            }) {
    const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);

    useEffect(() => {
        if (typesOfUse.length <= 0)
            getTypesOfUse()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>
            <form className={classes.content}>
                <div className={classes.content}>
                    <NameComponent onValidationCorrect={onValidationCorrect} className={classes.panel}
                                   disableAutofocus={disableAutofocus} saveOnChange={update ? onSave : null}
                                   variant={'overline'}/>
                    <div className={classes.panel}>
                        <Typography variant={'overline'}>{t('type_of_use')}</Typography>
                        <Select className={classes.select}
                                onChange={(event) => {
                                    setFieldValue("element.typeOfUse", event.target.value)
                                    handleSubmit()
                                    if (update) {
                                        let x = {...values.element}
                                        x.typeOfUse = event.target.value
                                        onSave(x)
                                    }
                                }}
                                value={values.element.typeOfUse ? values.element.typeOfUse : ''}>
                            {typesOfUse && typesOfUse.map(type => (
                                <MenuItem className={classes.menuItem} key={type}
                                          value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    {values.element.typeOfUse === TYPE_OF_USE_MEETING &&
                        <div className={classes.panel}>
                            <Typography variant={'overline'}>{t('available_space')}</Typography>
                            <InputBase
                                className={classes.select}
                                type="number"
                                value={values.element.availableSpace}
                                name="element.availableSpace"
                                id="availableSpace"
                                onChange={e => {
                                    e.preventDefault();
                                    const {value} = e.target;
                                    const regex = /^[1-9][0-9]?$|^100$/;
                                    if (!value || regex.test(value.toString())) {
                                        setFieldValue("element.availableSpace", parseFloat(value));
                                        handleSubmit()
                                        if (update) {
                                            let x = {...values.element}
                                            x.availableSpace = parseFloat(value)
                                            onSave(x)
                                        }
                                    }
                                }}
                            />
                        </div>}
                </div>
                {update ? values.element.typeOfUse === TYPE_OF_USE_MEETING
                        ? <AdminButton onClick={() => setQrCodeModalOpen(true)}
                                       fullWidth outlined
                                       text={t('export_qr_code')}
                                       preventDefault/>
                        : null
                    : <AdminButton onClick={onSave} text={t('create')}
                                   disabled={!element.name} fullWidth
                                   primary type={'submit'}
                                   preventDefault/>}
            </form>
            {qrCodeModalOpen &&
                <QRCodeExportModal contents={[<QRCodesToExportSectionWithName elements={[values.element]}/>]}
                                   open={qrCodeModalOpen}
                                   onCloseModal={() => setQrCodeModalOpen(false)}/>}
        </div>
    )
}

const SpaceInfo = withFormik({
    mapPropsToValues: props => {
        let element = props.element
        if (!element.typeOfUse && props.typesOfUse && props.typesOfUse.length) {
            element.typeOfUse = props.typesOfUse[0]
        }
        if (!element.availableSpace) {
            element.availableSpace = 0
        }

        return ({element: element})
    },
    enableReinitialize: true,
    handleSubmit: (values, {props}) => {
        props.setElement({...values.element})
    }
})(SpaceInfoComponent)

SpaceInfo.propTypes = {
    onSave: PropTypes.func.isRequired,
    disableAutofocus: PropTypes.bool,
    update: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setElement,
    getTypesOfUse,
}, dispatch)

const mapStateToProps = state => {
    return {
        typesOfUse: state.common.typesOfUse,
        element: state.modalElementSkeleton.element,
    }
}

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SpaceInfo);
