import React, {useState} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles, Typography, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import StartAndEndTime from "../../../common/elements/StartAndEndTime.js";
import ActionListItem from "../../../common/elements/ActionListItem.js";
import {findCurrentMeeting, getMeetingsOfSpaceAtDate} from "../../../common/utils/MeetingUtils.js";
import MultipleAvatarsGroup from "../../../common/elements/MultipleAvatarsGroup.js";
import useDidMountEffect from "../../../common/customHooks/useDidMountEffect.js";
import {
    getDateAtEndOfWorkingDay,
    getDateAtStartOfWorkingDay,
    getDiffInMinutesAbsolute,
    getTimeAsString,
    inOneHour,
    isToday,
    roundToNext15Minutes
} from "../../../common/utils/TimeUtils";
import {updateTimePeriodMeeting} from "../../../actions/meeting-actions.js";
import moment from "moment";
import MeetingInformationContent from "../../meetings/MeetingInformationContent";
import {getAssignmentsAsIntervals} from "../../../common/utils/AssignmentUtils.js";
import TimelineWithDisabledIntervals from "../../../common/elements/EditableTimelineWithHeaderAndError.js";
import hash from "object-hash";
import LoadingIndicator from "../../../common/elements/LoadingIndicator.js";
import BookingsEmptyStateSvg from "../../../common/icons/BookingsEmptyStateSvg.js";

const useStyle = makeStyles(theme => ({
    expandedContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.innerGap + ' ' + theme.outerGap,
        boxSizing: 'border-box',
        gap: theme.outerGap,
    },
    assignmentEntry: {
        display: 'flex',
        width: '100%',
        gap: theme.innerGap,
    },
    orangeText: {
        color: theme.colors.palette.corporate.skinMain
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    timeLine: {
        marginTop: '-12px'
    }
}))

function MeetingroomSelectionSpreadsheetContent({
                                                    selectedSpace,
                                                    updateTimePeriodMeeting,
                                                    detailsView,
                                                    createDifferentMeetingMode,
                                                    spaceAssignments,
                                                    selectedDate,
                                                    timePeriod,
                                                    meetingsPending,
                                                    onTimeRangeErrorChangeCallback,
                                                }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();


    const [assignmentsOfSelectedSpace, setAssignmentsOfSelectedSpace] = useState(getMeetingsOfSpaceAtDate(selectedSpace, spaceAssignments, selectedDate));
    const [selectedMeeting, setSelectedMeeting] = useState(null);

    const showFrom = getDateAtStartOfWorkingDay(selectedDate)
    const showUntil = getDateAtEndOfWorkingDay(selectedDate);

    const disabledIntervals = () => {
        if (!assignmentsOfSelectedSpace || meetingsPending)
            return null

        return getAssignmentsAsIntervals(assignmentsOfSelectedSpace.filter(assignment =>
            assignment.timePeriod.startDate !== timePeriod.startDate ||
            assignment.timePeriod.endDate !== timePeriod.endDate))
    }


    useDidMountEffect(() => {
        const meetingsAtNewDate = getMeetingsOfSpaceAtDate(selectedSpace, spaceAssignments, selectedDate)
        setAssignmentsOfSelectedSpace(meetingsAtNewDate)
        updateTimePeriodMeeting(getInitialTimePeriod(meetingsAtNewDate))
    }, [spaceAssignments, selectedDate])

    useDidMountEffect(() => {
        if (!detailsView)
            setSelectedMeeting(null)
    }, [detailsView])

    useDidMountEffect(() => {
        const meetingsAtNewDate = getMeetingsOfSpaceAtDate(selectedSpace, spaceAssignments, selectedDate)
        updateTimePeriodMeeting(getInitialTimePeriod(meetingsAtNewDate))
    }, [createDifferentMeetingMode])

    const getInitialTimePeriod = (assignmentsOfSpaceAtDate) => {
        let timeLimitStart, timeLimitEnd, endInOneHour
        let earliestPossibleStart = moment(selectedDate).toDate()
        let sortedMeetings = assignmentsOfSpaceAtDate.sort((a, b) => a.timePeriod.startDate > b.timePeriod.startDate ? 1 : -1)

        if (isToday(selectedDate)) {
            earliestPossibleStart = roundToNext15Minutes(earliestPossibleStart)
        } else {
            earliestPossibleStart = getDateAtStartOfWorkingDay(earliestPossibleStart)
        }

        for (const [index, meeting] of sortedMeetings.entries()) {
            const meetingStart = moment(meeting.timePeriod.startDate).toDate()
            const meetingEnd = moment(meeting.timePeriod.endDate).toDate()

            //free time between earliestPossibleStart and meeting
            if (meetingStart > earliestPossibleStart && getDiffInMinutesAbsolute(earliestPossibleStart, meetingStart) >= 15 && (index === 0 || sortedMeetings[index - 1].timePeriod.endDate < meetingStart)) {
                let endInOneHour = inOneHour(earliestPossibleStart, true)
                timeLimitStart = earliestPossibleStart
                timeLimitEnd = endInOneHour > meetingStart ? meetingStart : endInOneHour
                break
                //after last meeting
            } else if (index === (sortedMeetings.length - 1)) {
                if (meetingEnd > earliestPossibleStart) {
                    timeLimitStart = meetingEnd
                    endInOneHour = inOneHour(meetingEnd, true)
                } else {
                    timeLimitStart = earliestPossibleStart
                    endInOneHour = inOneHour(earliestPossibleStart, true)
                }
                timeLimitEnd = endInOneHour
                break
                //free time between two meetings
            } else if (meetingEnd > earliestPossibleStart && (sortedMeetings[index + 1] && getDiffInMinutesAbsolute(sortedMeetings[index + 1].timePeriod.startDate, meetingEnd) >= 15)) {
                timeLimitStart = meetingEnd
                endInOneHour = inOneHour(meetingEnd, true)
                let startNextMeeting = moment(sortedMeetings[index + 1].timePeriod.startDate).toDate()
                timeLimitEnd = startNextMeeting < endInOneHour ? startNextMeeting : endInOneHour
                break
            }
        }

        if ((!timeLimitStart || !timeLimitEnd) && !sortedMeetings.length) {
            timeLimitStart = earliestPossibleStart
            timeLimitEnd = inOneHour(earliestPossibleStart, true)
        }

        return {
            startDate: timeLimitStart,
            endDate: timeLimitEnd
        }
    }

    function setTime(time) {
        let startDate = moment(time[0]);
        let endDate = moment(time[1])
        updateTimePeriodMeeting({startDate: startDate.toDate(), endDate: endDate.toDate()})
    }

    const getAssignmentItem = (assignment) => {
        return <div className={classes.assignmentEntry} key={assignment.id ?? hash(assignment)}>
            <StartAndEndTime startTime={assignment.timePeriod.startDate} endTime={assignment.timePeriod.endDate}/>
            <ActionListItem
                startDivider
                stretchDivider
                startAdornment={<MultipleAvatarsGroup
                    persons={assignment.attendances.map(attendance => attendance.person)}/>}
                text={assignment.title ?? t('general.noInformation')}
                height={60}
                action={() => setSelectedMeeting(assignment)}
                key={assignment.person && assignment.person.id ? assignment.person.id : hash(assignment.person)}/>
        </div>
    }

    function getContent() {
        const currentMeeting = findCurrentMeeting(assignmentsOfSelectedSpace)

        if (detailsView) {
            if (selectedMeeting) {
                return <MeetingInformationContent assignment={selectedMeeting} showReplyOptions={false}/>
            } else if (assignmentsOfSelectedSpace && assignmentsOfSelectedSpace.length) {
                return assignmentsOfSelectedSpace.map(assignment => getAssignmentItem(assignment))
            } else {
                if (meetingsPending) {
                    return <LoadingIndicator/>
                }

                return <div
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.innerGap}}>
                    <BookingsEmptyStateSvg height={130}/>
                    <Typography variant={'caption'}>{t('no_meetings_yet')}</Typography>
                </div>
            }

        } else if (currentMeeting && !createDifferentMeetingMode) {
            return <div className={classes.flexColumn}>
                <Typography variant={'caption'}>
                    {t('take_place_right_now')} ({t('until')} {getTimeAsString(currentMeeting.timePeriod.endDate)})
                </Typography>
                <MeetingInformationContent assignment={currentMeeting} showReplyOptions={false}/>
            </div>
        } else {
            return <TimelineWithDisabledIntervals
                className={classes.timeLine}
                hideTimeHeader
                timePeriod={timePeriod}
                showFrom={showFrom} showUntil={showUntil}
                disabledIntervals={disabledIntervals()}
                errorCallback={onTimeRangeErrorChangeCallback}
                setTimeCallback={(time) => setTime(time)}/>
        }
    }

    if (!selectedSpace)
        return null

    return <div className={classes.expandedContent}>
        {getContent()}
    </div>
}

MeetingroomSelectionSpreadsheetContent.propTypes = {
    detailsView: PropTypes.bool,
    onTimeRangeErrorChangeCallback: PropTypes.func
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedSpace: state.spaces.selectedSpace,
        userAssignments: state.user.userAssignments,
        spaceAssignments: state.meetings.spaceAssignments,
        timePeriod: state.meetings.timePeriod,
        meetingsPending: state.meetings.spaceAssignmentsPending,
    }
}

const mapDispatchToProps = {
    updateTimePeriodMeeting: updateTimePeriodMeeting,

}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingroomSelectionSpreadsheetContent)