import axios from 'axios'

export function getBuildings(excludeDetails = false) {

    return {
        type: 'GET_BUILDINGS',
        payload: axios.get('/api/building?excludeDetails=' + !!excludeDetails)
    }
}

export function deleteBuilding(buildingId) {

    return {
        type: 'DELETE_BUILDING',
        payload: axios.delete(`/api/building/${buildingId}`)
    }
}

export function createBuilding(buildingDto) {

    return {
        type: 'CREATE_BUILDING',
        payload: axios.post('/api/building', buildingDto)
    }
}

export function updateBuilding(buildingId, buildingDto) {

    return {
        type: 'UPDATE_BUILDING',
        payload: axios.patch(`/api/building/${buildingId}`, buildingDto)
    }
}

export function getBuilding(buildingId, excludeDetails = false) {

    return {
        type: 'GET_BUILDING',
        payload: axios.get(`/api/building/${buildingId}?excludeDetails=${!!excludeDetails}`)
    }
}

export function setBuilding(buildingNew, reset = true) {

    return (dispatch, getState) => {
        const {buildingList} = getState().buildings;

        if (buildingList && buildingList.length && buildingNew) {
            let buildingToSet = buildingList.find(b => b.id === buildingNew.id)
            dispatch(setBuildingDispatch(buildingToSet, reset))
        } else {
            dispatch(setBuildingDispatch(buildingNew, reset))
        }
    }
}

function setBuildingDispatch(building, reset) {
    return {
        type: 'SET_BUILDING',
        payload: building,
        meta: {
            reset: reset,
        },
    }
}

export function unsetBuilding() {

    return {
        type: 'UNSET_BUILDING',
    }
}