import * as React from "react"
import PropTypes from "prop-types";

function ConcentrateWorkingSvgIcon({color, width, height, strokeWidth, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={color}
                strokeWidth={strokeWidth}
                fillRule="evenodd"
                strokeLinejoin="round"
                fill="none"
            >
                <circle cx={20} cy={20} r={13}/>
                <circle cx={20} cy={20} r={9}/>
                <circle cx={20} cy={20} r={5}/>
                <path fill={props.fill || '#none'} d="M20 20l10 10 1.25 1.25M20 20l10 10 1.25 1.25M31 35.375V31h4.375"/>
            </g>
        </svg>
    )
}

ConcentrateWorkingSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    strokeWidth: PropTypes.number,
};

ConcentrateWorkingSvgIcon.defaultProps = {
    color: '#333',
    width: 40,
    height: 40,
    strokeWidth: 1.5,
};


export default ConcentrateWorkingSvgIcon