import React, {Component} from 'react'
import * as _ from 'lodash'
import {isEmpty, isEqual} from 'lodash'
import * as THREE from 'three'
import OrbitControls from './three/orbit'

import Measure from 'react-measure'

import {
    redoGeometryChange,
    resetGeometries,
    setCurrentDrawingTab,
    setFloorImage,
    setMarkerSize,
    setSelectedDrawingTool,
    setSelectedElement,
    storeGeometryChanges,
    undoGeometryChange
} from '../../actions/drawing-actions'

import {
    createVertex,
    drawingOpacity,
    drawingSegments,
    drawingSize,
    drawingTypes,
    getBoundingBoxPositions,
    getCircleGeometry,
    getDrawingTypesMaterial,
    orientation,
    removeAllChildren,
} from './drawing/drawConstants'
import {getMaterial, materialTypes} from "./three/common/materials";

import {DrawLine} from './drawing/DrawLine'
import {DrawPolygon} from './drawing/DrawPolygon'
import {DrawPolyline} from './drawing/DrawPolyline'
import {DrawStraight} from './drawing/DrawStraight'
import {DrawMarker} from './drawing/DrawMarker'

import {getTextMesh, updateTextMesh, updateTextScaleMesh} from './three/common/texts'
import {getCurrentGeometryObjects} from '../../reducers/drawing-reducer'


import {freeMemory} from './three/common/memory'
import {getFloor, getFloorPlanImage, saveFloorGeometries, setImageScale} from "../../actions/floors-actions";
import DrawingToolsBar from "./DrawingToolsBar";
import LoadingIndicator from "../../common/elements/LoadingIndicator";
import {DrawWorkplace} from "./drawing/DrawWorkplace";
import WorkplaceInfo from "./inspector/WorkplaceInfoComponent.js";
import {
    createWorkplaces,
    deleteWorkplaces,
    getCoworkersAssignmentsAtDate,
    updateWorkplaces
} from "../../actions/workplaceAssignment-action";
import {setElement} from "../../actions/modalElementSkeleton-actions";
import {
    DRAWING_REPRESENTATION_TYPE_SCALE,
    GEOMETRY_TYPE_LINE,
    TYPE_OF_ACTIVITY_CALLS,
    TYPE_OF_ACTIVITY_CONCENTRATE,
    TYPE_OF_ACTIVITY_CREATIVE,
    TYPE_OF_ACTIVITY_TALK,
    TYPE_OF_ACTIVITY_UNDEFINED,
    TYPE_OF_USE_MEETING
} from "../../common/utils/NameUtils";
import SpaceInfo from "./inspector/SpaceInfoComponent.js";
import {createSpaces, deleteSpaces, updateSpaces} from "../../actions/space-actions";
import PropTypes from "prop-types";
import {setError} from "../../actions/error-actions";
import ModificationOverview from "./ModificationOverview";
import DrawingToolInspector from "./inspector/DrawingToolInspector";
import {tabsNames, tools} from "./drawing/drawingTabsAndTools";
import moment from "moment";
import {getProfilePictures} from "../../actions/occupancy-actions";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import DistanceInput from "./inspector/DistanceInput.js";
import AdminButton from "../../common/elements/AdminButton.js";
import {matchProfilePicturesWithAssignments} from "../../common/utils/AssignmentUtils.js";
import {DrawTerminal} from "./drawing/DrawTerminal.js";
import TerminalInspectorContent from "./inspector/TerminalInspectorContent.js";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {createTerminals, deleteTerminals, updateTerminals} from "../../actions/terminal-actions.js";

const orbitControlsConstants = {
    initMinZoomOut: .5,
    initMaxZoomIn: 8,

    handlerScaleMinZoomLevel: 3,
    handlerScaleMaxZoomLevel: 1,

    start: 'start',
    end: 'end',
    change: 'change',
}

const dimensioningDigits = 2

const htmlClassNames = {
    buildingsPlanPanel: 'buildings-plan-panel',
    modificationOverview: 'modification-overview',
}

const htmlElementsOverEditor = [
    htmlClassNames.modificationOverview,
]

const keycodes = {
    backspace: 'Backspace',
    enter: 'Enter',
    space: 'Space',
    altLeft: 'AltLeft',
    altRight: 'AltRight',
    KeyD: 'KeyD',
    KeyF: 'KeyF',
    KeyL: 'KeyL',
    KeyS: 'KeyS',
    KeyY: 'KeyY',
    KeyZ: 'KeyZ',
    KeyC: 'KeyC',
}

export const mouseActionTypes = {
    mouseDown: 'mousedown',
    mouseMove: 'mousemove',
    mouseUp: 'mouseup',
    wheel: 'wheel',
    keydown: 'keydown',
    keyup: 'keyup',
    dblclick: 'dblclick',
    beforeunload: 'beforeunload',

    none: 'none',

    newScale: 'newScale',
    dragVertexScale: 'dragVertexScale',

    newOutline: 'newOutline',
    addVertexOutline: 'addVertexOutline',
    dragVertexOutline: 'dragVertexOutline',
    selectOutline: 'selectedOutline',
    selectedVertexOutline: 'selectedVertexOutline',
    dragFaceOutline: 'dragFaceOutline',

    newWall: 'newWall',
    addVertexWall: 'addVertexWall',
    dragVertexWall: 'dragVertexWall',
    selectedWall: 'selectedWall',
    selectedVertexWall: 'selectedVertexWall',

    newDoor: 'newDoor',
    dragDoor: 'dragDoor',
    selectDoor: 'selectDoor',

    newPillar: 'newPillar',
    dragPillar: 'dragPillar',
    selectPillar: 'selectPillar',

    newSpace: 'newSpace',
    addVertexSpace: 'addVertexSpace',
    selectSpace: 'selectSpace',
    selectVertexSpace: 'selectVertexSpace',
    dragVertexSpace: 'dragVertexSpace',
    dragFaceSpace: 'dragFaceSpace',

    newWorkplace: 'newWorkplace',
    dragWorkplace: 'dragWorkplace',
    selectWorkplace: 'selectWorkplace',
    editWorkplace: 'editWorkplace',

    newTerminal: 'newTerminal',
    selectTerminal: 'selectTerminal',
    dragTerminal: 'dragTerminal',
}

const styles = theme => ({
    root: {
        height: '100%',
        width: '100% ',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
    },
    drawingEditorPanel: {
        height: '100%',

    },
    buildingsPlanContainer: {
        height: '100%'
    },
    content: {
        flexShrink: 0,
        width: '100%',
        minHeight: '100%',
        height: '100%'
    },
    contentWrapper: {
        margin: '0 auto',
        width: '100%',
        height: '100%',
        display: 'flex'

    },
    loadingIndicator: {
        position: 'fixed',
        top: '50%',
        left: '50%',
    },
    drawingToolContent: {
        display: 'flex',
        height: 'calc(100% - 54px)',
        width: '100% ',
    },
    backHeader: {
        height: '7.2rem',
        minHeight: '7.2rem',
        padding: `0 ${theme.outerGap}`,
        backgroundColor: theme.colors.palette.neutral.darkMain,
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        border: 'none !important',
        maxWidth: '25rem !important',
        maxHeight: '3.2rem !important',
    }
})

class EditOutlinePanel extends Component {

    constructor(props, context) {
        super(props, context)
        const {buildingId, floorId} = this.props.match.params

        if (!this.props.floor || !isEqual(this.props.floor.id.toString(), floorId.toString())) {
            props.getFloor(buildingId, floorId)
                .then(() => {
                        if ((!this.props.base64Image || this.props.base64Image === '') && this.props.floor.floorPlanId !== null) {
                            const {buildingId, floorId} = this.props.match.params
                            this.props.getFloorPlanImage(buildingId, floorId)
                                .then(() => {
                                    for (let item of this.props.floorPlanImages) {
                                        if (item.id.toString() === floorId.toString()) {
                                            let i = new Image();
                                            i.src = 'data:image/png;base64,' + item.floorplan;

                                            this.props.setFloorImage(i.src)
                                        }
                                    }
                                })
                        }
                    }
                )
        } else if ((!this.props.base64Image || this.props.base64Image === '') && this.props.floor.floorPlanId !== null) {
            const {buildingId, floorId} = this.props.match.params

            this.props.getFloorPlanImage(buildingId, floorId)
                .then(() => {
                    for (let item of this.props.floorPlanImages) {
                        if (item.id.toString() === floorId.toString()) {
                            let i = new Image();
                            i.src = 'data:image/png;base64,' + item.floorplan;

                            this.props.setFloorImage(i.src)
                        }
                    }
                })
        }

        let date = moment()
        this.props.getProfilePictures(this.props.personId, floorId, date.toISOString())
        this.props.getCoworkersAssignmentsAtDate(this.props.personId, date.startOf('day').toISOString(),
            date.endOf('day').toISOString(), floorId, true)

        this.initState = {
            dirtyFlag: false,

            hovered: null,

            surroundingSpace: null,

            selectedWorkplace: null,

            showSpaceInfo: false,

            scaleInputActive: false,

            showWorkplaceInfo: false,

            showTerminalInfo: false,

            savePopupOpen: false
        }

        this.state = this.initState

        window.addEventListener('resize', () => {
            if (window.innerWidth < 768) {
                this.props.history.push('/admin')
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.floorplanHighlighted !== prevProps.floorplanHighlighted) {
            this.onToggleOpacity()
        }

        if (!isEqual(this.props.currentTab, prevProps.currentTab)) {
            this.handleChangedTab()
        }

        if (!isEqual(this.props.selectedTool, prevProps.selectedTool)) {
            this.lastSelectedTool = prevProps.selectedTool
            this.handleChangedTool()
        }

        if (!isEqual(this.props.imageScale, prevProps.imageScale) && this.props.base64Image) {
            this.updateBackgroundImage(this.props.base64Image, this.props.imageScale)
            this.drawDrawings()
        }

        if (!isEqual(this.props.geometries, prevProps.geometries)) {
            this.drawDrawings()

            if (this.props.geometries.selectedGeometryId) {
                this.resetCurrentDrawObject()
                this.selectGeometryById(this.props.geometries.selectedGeometryId)
            } else {
                this.setState({showWorkplaceInfo: false, showSpaceInfo: false, showTerminalInfo: false})
            }
        }

        if (!isEqual(this.props.base64Image, prevProps.base64Image) ||
            !isEmpty(this.props.imageScale, prevProps.imageScale)) {
            this.updateBackgroundImage(this.props.base64Image, this.props.imageScale)
        }

        if (this.props.workplaceAssignments !== prevProps.workplaceAssignments)
            this.drawDrawings()

        if (this.props.profilePictures !== prevProps.profilePictures)
            this.drawDrawings()

        if (!isEqual(this.props.doorSize, prevProps.doorSize) && this.currentDrawObject.drawingRepresentationType === drawingTypes.door) {
            this.currentDrawObject.updateRadius(this.props.doorSize)
        }

        if (!isEqual(this.props.pillarSize, prevProps.pillarSize) && this.currentDrawObject.drawingRepresentationType === drawingTypes.pillar) {
            this.currentDrawObject.updateRadius(this.props.pillarSize)
        }

        if (this.props.editMode && !prevProps.editMode) {
            this.resize()
        }

    }

    //region MOUNT

    componentDidMount() {

        this.width = this.mount.clientWidth
        this.height = this.mount.clientHeight

        this.topbar = 152
        this.sidebar = 0

        this.offsetHeight = this.topbar

        //ADD SCENE

        this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)

        this.onEditorGUI = true

        this.raycaster = new THREE.Raycaster(new THREE.Vector3(0, 20, 0), new THREE.Vector3(0, -1, 0), 0, 100)

        this.currentMousePosition = new THREE.Vector2()
        this.currentIntersectionPosition = new THREE.Vector3()
        this.lastMousePositionLeftClickDown = new THREE.Vector3()
        this.lastMouseMovePosition = new THREE.Vector3()

        this.mouseAction = mouseActionTypes.none
        this.lastSelectedTool = null

        this.hoveredResizeHandlerRectangle = null

        this.previewVertex = null

        this.currentHoveredVertex = null
        this.currentHoveredEdge = null

        this.currentSelectedVertex = null

        this.lastMarkerPosition = null

        //ADD RENDERER
        this.mountRenderer()

        this.scene = new THREE.Scene()

        this.mountCamera()

        this.mountOrbitControls()

        this.mountMouseAndKeyboardActions()

        this.mountDrawingHierarchy()

        this.mountBackground()

        this.mountBackgroundImage()

        this.mountCatchPlane()

        this.centerCamera()

        this.drawDrawings()

        this.start()

        this.htmlElementsOverEditor = {}

        this.handleChangedTab()
        this.handleChangedTool()
    }

    mountRenderer() {
        this.renderer = new THREE.WebGLRenderer({antialias: true})

        this.renderer.setClearColor('#cdd0d6')

        this.renderer.setPixelRatio(window.devicePixelRatio)

        this.mount.appendChild(this.renderer.domElement)
    }

    mountOrbitControls() {
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
        this.orbitControls.enableRotate = false
        this.orbitControls.enableKeys = false
        this.orbitControls.minZoom = orbitControlsConstants.initMinZoomOut
        this.orbitControls.maxZoom = orbitControlsConstants.initMaxZoomIn
        this.orbitControls.mouseButtons = {ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.RIGHT}

        this.orbitControls.addEventListener(orbitControlsConstants.change, (event) => this.updateCameraZoom(event))
    }

    mountMouseAndKeyboardActions() {
        const planPanel = document.getElementById(htmlClassNames.buildingsPlanPanel)

        document.addEventListener(mouseActionTypes.keydown, this.onDocumentKeydown, true)
        document.addEventListener(mouseActionTypes.keyup, this.onDocumentKeyup, true)
        planPanel.addEventListener(mouseActionTypes.mouseDown, this.onDocumentMouseDown, true)
        planPanel.addEventListener(mouseActionTypes.mouseMove, this.onDocumentMouseMove, true)
        planPanel.addEventListener(mouseActionTypes.mouseUp, this.onDocumentMouseUp, true)

        window.addEventListener(mouseActionTypes.beforeunload, this.onWindowClose)
    }

    mountCamera() {
        this.camera = new THREE.OrthographicCamera(
            this.width / -16,
            this.width / 16,
            this.height / 16,
            this.height / -16,
            -1,
            100,
        )
        this.camera.enableRotate = false
        this.camera.position.y = 20

        this.lastCameraPosition = this.camera.position

        this.scene.add(this.camera)
    }

    mountDrawingHierarchy() {
        this.drawingFloor = new THREE.Group()
        this.drawingFloor.name = 'drawingFloor'

        this.drawScale = new THREE.Group()
        this.drawScale.name = 'drawScale'
        this.drawOutline = new THREE.Group()
        this.drawOutline.name = 'drawOutline'
        this.drawWalls = new THREE.Group()
        this.drawWalls.name = 'drawWalls'
        this.drawDoors = new THREE.Group()
        this.drawDoors.name = 'drawDoors'
        this.drawPillars = new THREE.Group()
        this.drawPillars.name = 'drawPillars'
        this.drawSpaces = new THREE.Group()
        this.drawSpaces.name = 'drawSpaces'
        this.drawWorkplaces = new THREE.Group()
        this.drawWorkplaces.name = 'drawWorkplaces'
        this.drawTerminals = new THREE.Group()
        this.drawTerminals.name = 'drawTerminals'

        this.currentDrawObject = null

        this.drawingFloor.add(this.drawScale)
        this.drawingFloor.add(this.drawOutline)
        this.drawingFloor.add(this.drawWalls)
        this.drawingFloor.add(this.drawDoors)
        this.drawingFloor.add(this.drawPillars)
        this.drawingFloor.add(this.drawSpaces)
        this.drawingFloor.add(this.drawWorkplaces)
        this.drawingFloor.add(this.drawTerminals)

        this.drawMagicGuidelineHorizontal = new DrawStraight()
        this.drawMagicGuidelineHorizontal.name = 'magicGuideline'
        this.drawMagicGuidelineHorizontal.init(new THREE.Vector3(0, 0.06, 0), orientation.horizontal)
        this.drawMagicGuidelineHorizontal.setActive(false)
        this.drawMagicGuidelineVertical = new DrawStraight()
        this.drawMagicGuidelineVertical.name = 'magicGuideline'
        this.drawMagicGuidelineVertical.init(new THREE.Vector3(0, 0.06, 0), orientation.vertical)
        this.drawMagicGuidelineVertical.setActive(false)
        this.drawingFloor.add(this.drawMagicGuidelineHorizontal)
        this.drawingFloor.add(this.drawMagicGuidelineVertical)

        this.drawDimensioningText = getTextMesh('', 1.5)
        this.drawDimensioningText.visible = false
        this.drawingFloor.add(this.drawDimensioningText)

        this.scene.add(this.drawingFloor)
    }

    mountCatchPlane() {
        let catchPlanGeometry = new THREE.PlaneGeometry(1000, 1000, 8, 8)
        this.catchPlane = new THREE.Mesh(catchPlanGeometry, getMaterial(materialTypes.transparent))

        this.catchPlane.applyMatrix4(this.planeRotation)
        this.catchPlane.position.set(0, -.9, 0)
        this.catchPlane.name = 'catchPlane'
        this.scene.add(this.catchPlane)

    }

    mountBackground() {
        // Create the Background
        const backgroundPlane = new THREE.PlaneGeometry(2000, 2000, 8, 8)

        const backgroundMaterial = getMaterial(materialTypes.background)

        this.background = new THREE.Mesh(backgroundPlane, backgroundMaterial)
        this.background.applyMatrix4(this.planeRotation)
        this.background.position.set(0, -1, 0)
        this.background.name = 'background'
        this.scene.add(this.background)
    }

    mountBackgroundImage() {
        this.backgroundImageMaterial = getMaterial(materialTypes.placeholder)
        this.backgroundImageMaterial.visible = false
        const geometry = new THREE.PlaneGeometry(100, 100, 8, 8)
        this.backgroundImage = new THREE.Mesh(geometry, this.backgroundImageMaterial)

        this.backgroundImage.applyMatrix4(this.planeRotation)
        this.backgroundImage.name = 'backgroundImage'
        this.backgroundImage.position.y = 0

        this.scene.add(this.backgroundImage)

        this.updateBackgroundImage(this.props.base64Image, this.props.imageScale)
    }

    updateBackgroundImage(image, scale = 1) {
        if (!image || scale === 0) {
            this.backgroundImageMaterial.visible = false

            return
        }

        this.backgroundImageMaterial.map = new THREE.TextureLoader().load(image, () => {
            this.backgroundImageMaterial.visible = true
            this.backgroundImageMaterial.opacity = this.props.floorplanHighlighted ? drawingOpacity.backGroundImageHighlighted : drawingOpacity.backGroundImage
            this.backgroundImageMaterial.transparent = true

            let width = this.backgroundImageMaterial.map.image && this.backgroundImageMaterial.map.image.width > 0 ? this.backgroundImageMaterial.map.image.width : 1
            let height = this.backgroundImageMaterial.map.image && this.backgroundImageMaterial.map.image.height > 0 ? this.backgroundImageMaterial.map.image.height : 1

            let scaleWidth = (width > height ? 1 : width / height) * scale
            let scaleHeight = (height > width ? 1 : height / width) * scale

            this.backgroundImage.geometry.dispose()
            this.backgroundImage.geometry = new THREE.PlaneGeometry(100 * scaleWidth, 100 * scaleHeight, 8, 8)
        })

        this.backgroundImageMaterial.visible = true
        this.backgroundImageMaterial.needsUpdate = true

    }

    //endregion

    componentWillUnmount() {
        this.props.setSelectedDrawingTool(null)
        this.props.setSelectedElement(null)
        this.unmountMouseAndKeyboardActions()

        this.stop()

        freeMemory(this.scene)
        this.mount.removeChild(this.renderer.domElement)
    }

    unmountMouseAndKeyboardActions() {
        const planPanel = document.getElementById(htmlClassNames.buildingsPlanPanel)

        document.removeEventListener(mouseActionTypes.keydown, this.onDocumentKeydown, true)
        planPanel.removeEventListener(mouseActionTypes.mouseDown, this.onDocumentMouseDown, true)
        planPanel.removeEventListener(mouseActionTypes.mouseMove, this.onDocumentMouseMove, true)
        planPanel.removeEventListener(mouseActionTypes.mouseUp, this.onDocumentMouseUp, true)

        window.addEventListener(mouseActionTypes.beforeunload, this.onWindowClose)
    }

    onWindowClose = (event) => {
        if (this.state.dirtyFlag) {
            event.preventDefault()
            event.returnValue = ''
        }
    }

    onToggleOpacity = () => {
        this.backgroundImageMaterial.opacity = this.props.floorplanHighlighted ? drawingOpacity.backGroundImageHighlighted : drawingOpacity.backGroundImage

        let opacity = this.props.floorplanHighlighted ? .3 : .9
        this.drawOutline.children.forEach(outline => this.setOpacity(outline, opacity))
        this.drawWalls.children.forEach(wall => this.setOpacity(wall, opacity))
        this.drawDoors.children.forEach(door => this.setOpacity(door, opacity))
        this.drawPillars.children.forEach(pillar => this.setOpacity(pillar, opacity))
        this.drawSpaces.children.forEach(space => this.setOpacity(space, opacity))
        this.drawWorkplaces.children.forEach(workplace => this.setOpacity(workplace, opacity))
    }

    setOpacity = (obj, opacity) => {
        obj.traverse(child => {
            if (child instanceof THREE.Mesh && child.name !== 'vertex') {
                // child.material = child.material.clone()
                // child.material.opacity = opacity
            }
        });
    };

    //region DOCUMENT EVENTS

    onDocumentMouseDown = (event) => {
        if (!this.onEditorGUI) {
            return
        }

        if (event.button === 0) {
            if (event.detail === 1) {
                this.onLeftMouseDown(event)
                this.isUnsavedChanges()
            }
        } else if (event.button === 2) {
            this.currentMousePosition.x = ((event.clientX - this.sidebar) / this.width) * 2 - 1
            this.currentMousePosition.y = -((event.clientY - this.topbar) / this.height) * 2 + 1
        }

    }

    onDocumentMouseMove = (event) => {
        this.checkMousePositionOnEditorGUI(event)

        this.currentMousePosition.x = ((event.clientX - this.sidebar) / this.width) * 2 - 1
        this.currentMousePosition.y = -((event.clientY - this.topbar) / this.height) * 2 + 1

        this.raycaster.setFromCamera(this.currentMousePosition, this.camera)

        const intersects = this.raycaster.intersectObject(this.catchPlane)

        if (this.props.selectedTool === tools.SELECT || this.props.selectedTool === null) {
            const hovered = this.raycaster.intersectObjects(this.drawWorkplaces.children, true);

            if (hovered.length) {
                let hoveredPosition
                let selectedWorkplace

                if (hovered[0].object.parent instanceof DrawWorkplace) {
                    hoveredPosition = hovered[0].object.parent.position.clone()
                    selectedWorkplace = hovered[0].object.parent
                } else {
                    hoveredPosition = hovered[0].object.parent.parent.position.clone()
                    selectedWorkplace = hovered[0].object.parent.parent
                }
                if (!this.state.hovered || this.state.hovered !== hoveredPosition) {
                    this.setState({hovered: hoveredPosition, selectedWorkplace: selectedWorkplace.workplace})
                }

            } else {
                this.setState({hovered: null, selectedWorkplace: null})
                if (this.mouseAction === mouseActionTypes.editWorkplace)
                    this.mouseAction = mouseActionTypes.none
            }
        }

        if (intersects.length > 0) {
            this.currentIntersectionPosition = intersects[0].point
        } else {
            return
        }

        this.onMouseMove(event)
    }

    onDocumentMouseUp = (event) => {
        if (!this.onEditorGUI) {
            return
        }

        if (event.button === 0) {
            this.onLeftMouseUp()
            this.isUnsavedChanges()
        }
    }

    onDocumentKeydown = (event) => {
        if (!this.onEditorGUI)
            return

        this.onKeydown(event)
    }

    onDocumentKeyup = (event) => {
        this.onKeyup(event)
    }

    //endregion

    //region SPLIT INTO TAB OR TOOL EVENTS

    onLeftMouseDown(event) {
        this.lastMousePositionLeftClickDown = this.currentIntersectionPosition.clone()

        switch (this.props.selectedTool) {
            case tools.SCALE:
                this.onLeftMouseDownScale()
                return
            case tools.OUTLINE:
                this.onLeftMouseDownOutline(event)
                return
            case tools.WALL:
                this.onLeftMouseDownWall(event)
                return
            case tools.DOOR:
                this.onLeftMouseDownDoor()
                return
            case tools.PILLAR:
                this.onLeftMouseDownPillar()
                return
            case tools.SPACE:
                this.onLeftMouseDownSpace(event)
                return
            case tools.WORKPLACE:
                this.onLeftMouseDownWorkplace(event)
                return
            case tools.TERMINAL:
                this.onLeftMouseDownTerminal(event)
                return
            case tools.SELECT:
            default:
                this.onLeftMouseDownSelect(event)
                return
        }
    }

    onMouseMove(event) {
        switch (this.props.selectedTool) {
            case tools.SELECT:
                this.onMouseMoveSelect(event)
                return
            case tools.SCALE:
                this.onMouseMoveScale(event)
                return
            case tools.OUTLINE:
                this.onMouseMoveOutline(event)
                return
            case tools.WALL:
                this.onMouseMoveWall(event)
                return
            case tools.DOOR:
                this.onMouseMoveDoor(event)
                return
            case tools.PILLAR:
                this.onMouseMovePillar(event)
                return
            case tools.SPACE:
                this.onMouseMoveSpace(event)
                return
            case tools.WORKPLACE:
                this.onMouseMoveWorkplace(event)
                return
            case tools.TERMINAL:
                this.onMouseMoveTerminal(event)
                return
            default:
                break
        }
    }

    onLeftMouseUp() {

        switch (this.props.selectedTool) {
            case tools.SELECT:
                this.onLeftMouseUpSelect()
                return
            case tools.OUTLINE:
                this.onLeftMouseUpOutline()
                return
            case tools.WALL:
                this.onLeftMouseUpWall()
                return
            case tools.DOOR:
                this.onLeftMouseUpDoor()
                return
            case tools.PILLAR:
                this.onLeftMouseUpPillar()
                return
            case tools.SPACE:
                this.onLeftMouseUpSpace()
                return
            case tools.WORKPLACE:
                this.onLeftMouseUpWorkplace()
                return
            case tools.TERMINAL:
                this.onLeftMouseUpTerminal()
                return;
            default:
                return

        }

    }

    onKeydown(event) {
        if (!event.altKey)
            return
        switch (event.code) {
            case keycodes.backspace:
                this.onBackspaceDown()
                return
            case keycodes.KeyC:
                this.onEscapeDown()
                event.preventDefault()
                return
            case keycodes.enter:
                this.onEnterDown()
                event.preventDefault()
                return
            case keycodes.space:
                this.onSpaceDown()
                event.preventDefault()
                return
            case keycodes.KeyD:
                this.onKeyDDown(event)
                event.preventDefault()
                return
            case keycodes.KeyS:
                this.onKeySDown(event)
                event.preventDefault()
                return
            case keycodes.KeyZ:
                this.onKeyZDown(event)
                event.preventDefault()
                return
            case keycodes.KeyY:
                this.onKeyYDown(event)
                event.preventDefault()
                break
            default:
                return
        }
    }

    onKeyup(event) {
        if (!event.altKey)
            return
        switch (event.code) {
            case keycodes.altLeft:
            case keycodes.altRight:
                this.onKeyAltUp(event)
                event.preventDefault()
                return
            default:
                return
        }
    }

    onBackspaceDown = () => {
        if (!this.currentDrawObject)
            return

        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.outline:
                this.onBackspaceDownOutline()
                return
            case drawingTypes.wall:
                this.onBackspaceDownWall()
                return
            case drawingTypes.door:
                this.onBackspaceDownDoor()
                return
            case drawingTypes.pillar:
                this.onBackspaceDownPillar()
                return
            case drawingTypes.space:
                this.onBackspaceDownSpace()
                return
            case drawingTypes.workplace:
                this.onBackspaceDownWorkplace()
                return
            case drawingTypes.terminal:
                this.onBackspaceDownTerminal()
                return
            default:
                return
        }
    }

    onEscapeDown() {
        if (!this.currentDrawObject)
            return
        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.scale:
                this.onEscapeDownScale()
                return
            case drawingTypes.outline:
                this.onEscapeDownOutline()
                return
            case drawingTypes.wall:
                this.onEscapeDownWall()
                return
            case drawingTypes.pillar:
                this.onEscapeDownPillar()
                return
            case drawingTypes.space:
                this.onEscapeDownSpace()
                return
            case drawingTypes.workplace:
                this.onEscapeDownWorkplace()
                return
            case drawingTypes.terminal:
                this.onEscapeDownTerminal()
                return;
            default:
                return
        }

    }

    onEnterDown() {
        if (!this.currentDrawObject)
            return

        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.outline:
                this.onEnterDownOutline()
                return
            case drawingTypes.wall:
                this.onEnterDownWall()
                return
            case drawingTypes.space:
                this.onEnterDownSpace()
                return
            default:
                return
        }
    }

    onSpaceDown() {
        this.toggleCurrentToolWithSelect()
    }

    onKeyDDown(event) {
        if (!this.currentDrawObject)
            return

        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.outline:
                this.onKeyDDownOutline(event)
                return
            case drawingTypes.door:
                this.onKeyDDownDoor(event)
                return
            case drawingTypes.pillar:
                this.onKeyDDownPillar(event)
                return
            case drawingTypes.space:
                // this.onKeyDDownPolygon(event)
                return
            default:
                return
        }
    }

    onKeySDown(event) {
        this.handleSaveDrawing()
    }

    onKeyZDown(event) {
        this.redoGeometryChange()
    }

    onKeyYDown(event) {
        this.undoGeometryChange()
    }

    onKeyAltUp() {
        switch (this.props.currentTab) {
            case tabsNames.DOORS:
            case tabsNames.PILLARS:
                this.onKeyAltUpBuild()
                return
            default:
                return
        }

    }

    toggleCurrentToolWithSelect() {
        if (this.lastSelectedTool && this.props.selectedTool === tools.SELECT) {
            this.props.setSelectedDrawingTool(this.lastSelectedTool)
        } else {
            this.props.setSelectedDrawingTool(tools.SELECT)
        }
    }

    //endregion

    handleChangedTool() {
        const tool = this.props.selectedTool
        this.resetCurrentDrawObject()
        this.setState({
            selectedWorkplace: null,
            showSpaceInfo: false,
            scaleInputActive: false,
            showWorkplaceInfo: false,
            showTerminalInfo: false
        })
        this.props.setElement({})
        this.setPreviewVertex(false)
        this.orbitControls.mouseButtons = {PAN: THREE.MOUSE.RIGHT}
        switch (tool) {
            case tools.SELECT:
                this.mouseAction = mouseActionTypes.none
                break
            case tools.SCALE:
                this.setPreviewVertex(true, drawingTypes.scale)
                this.mouseAction = mouseActionTypes.newScale
                break
            case tools.OUTLINE:
                this.setPreviewVertex(true, drawingTypes.outline)
                this.mouseAction = mouseActionTypes.newOutline
                break
            case tools.WALL:
                this.setPreviewVertex(true, drawingTypes.wall)
                this.mouseAction = mouseActionTypes.newWall
                break
            case tools.DOOR:
                this.mouseAction = mouseActionTypes.newDoor
                this.initMarker(drawingTypes.door, this.props.doorSize)
                break
            case tools.PILLAR:
                this.mouseAction = mouseActionTypes.newPillar
                this.initMarker(drawingTypes.pillar, this.props.pillarSize)
                break
            case tools.SPACE:
                this.setPreviewVertex(true, drawingTypes.space)
                this.mouseAction = mouseActionTypes.newSpace
                break
            case tools.WORKPLACE:
                this.mouseAction = mouseActionTypes.newWorkplace
                this.initWorkplace(drawingTypes.workplace, TYPE_OF_ACTIVITY_UNDEFINED)
                break
            case tools.TERMINAL:
                this.mouseAction = mouseActionTypes.newTerminal
                this.initTerminal()
                break
            default:
                break
        }
    }

    //region CHANGE TAB

    handleChangedTab(previousStep = null) {
        this.handleVisibilityOfDrawingObjectAfterChangeTab()

        if (previousStep) {
            this.savePreviousStep(previousStep)
        }

    }

    handleVisibilityOfDrawingObjectAfterChangeTab() {
        this.resetCurrentDrawObject()

        this.drawOutline.visible = this.props.currentTab !== tabsNames.SCALE
        this.drawWalls.visible = this.props.currentTab !== tabsNames.SCALE
        this.drawDoors.visible = this.props.currentTab !== tabsNames.SCALE
        this.drawPillars.visible = this.props.currentTab !== tabsNames.SCALE
        this.drawSpaces.visible = this.props.currentTab !== tabsNames.SCALE
        this.drawWorkplaces.visible = this.props.currentTab !== tabsNames.SCALE
        this.drawTerminals.visible = this.props.currentTab !== tabsNames.SCALE
    }

    savePreviousStep(previousTab) {
        if (this.isUnsavedChanges(previousTab)) {
            this.handleSaveDrawing()
        }
    }

    //endregion

    resetCurrentDrawObject(mouseActionTypeAfter) {
        if (this.currentDrawObject) {
            this.currentDrawObject.setActive(false)
            this.drawingFloor.remove(this.currentDrawObject)
            this.currentDrawObject.geometry.dispose()
        }

        this.drawDimensioningText.visible = false

        this.showMagicGuideline()
        this.currentDrawObject = null


        if (mouseActionTypeAfter)
            this.mouseAction = mouseActionTypeAfter
        else
            this.mouseAction = mouseActionTypes.newSpace
    }

    hasMouseMoved() {
        return this.lastMousePositionLeftClickDown.lengthSq() !== this.currentIntersectionPosition.lengthSq()
    }

    //region GENERAL

    setPreviewVertex(visible, type) {
        if (!this.previewVertex) {
            const {material, innerMaterial} = getDrawingTypesMaterial(type)
            this.previewVertex = createVertex(material, innerMaterial,
                this.camera.zoom < orbitControlsConstants.handlerScaleMinZoomLevel ? 1 / this.camera.zoom : 1 / orbitControlsConstants.handlerScaleMinZoomLevel)
            this.previewVertex.type = type
        } else if (this.previewVertex.type !== type) {
            const {material} = getDrawingTypesMaterial(type)
            this.previewVertex.material = material
        }

        if (visible) {
            this.previewVertex.position.set(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)
            this.drawingFloor.add(this.previewVertex)
        } else {
            this.drawingFloor.remove(this.previewVertex)
            this.previewVertex.geometry.dispose()
            this.previewVertex = null
        }

    }

    updatePreviewVertex(position) {
        if (this.previewVertex) {
            if (position) {
                this.previewVertex.position.set(position.x, 0.05, position.z)
            } else {
                this.previewVertex.position.set(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)
            }
        }
    }

    updateDimensioningText(position, distance = null, dimension = null) {
        let text = null

        if (distance !== null) {
            text = distance.toFixed(dimensioningDigits)
        } else if (dimension !== null) {
            text = dimension.width.toFixed(dimensioningDigits) + ' x ' + dimension.height.toFixed(dimensioningDigits)
        } else {
            this.drawDimensioningText.visible = false
        }

        updateTextMesh(this.drawDimensioningText, text + ' m')

        this.drawDimensioningText.position.set(position.x + 0.5, 0.05, position.z - 0.5)
    }

    selectGeometryById(geometryId) {

        if (this.selectGeometryByUuidAndGroup(geometryId, this.drawOutline, mouseActionTypes.selectOutline))
            return
        else if (this.selectGeometryByUuidAndGroup(geometryId, this.drawWalls, mouseActionTypes.selectedWall))
            return
        else if (this.selectGeometryByUuidAndGroup(geometryId, this.drawDoors, mouseActionTypes.selectDoor))
            return
        else if (this.selectGeometryByUuidAndGroup(geometryId, this.drawPillars, mouseActionTypes.selectPillar))
            return
        else if (this.selectGeometryByUuidAndGroup(geometryId, this.drawSpaces, mouseActionTypes.selectSpace))
            return
        else if (this.selectGeometryByUuidAndGroup(geometryId, this.drawWorkplaces, mouseActionTypes.selectWorkplace)) {
            return
        }

    }

    selectGeometryByUuidAndGroup(geometryId, threeGroup, mouseAction) {
        const threeObject = threeGroup.children.find(child => child.geometryId === geometryId)
        if (threeObject) {
            this.currentDrawObject = threeObject
            this.currentDrawObject.setActive(true)
            this.mouseAction = mouseAction

            if (threeObject.drawingRepresentationType === drawingTypes.workplace) {
                this.setShowWorkplaceModalOpen(true)
                this.props.setElement(this.currentDrawObject.workplace)
            } else if (threeObject.drawingRepresentationType === drawingTypes.space) {
                this.setState({showSpaceInfo: true})
                this.props.setElement(this.currentDrawObject.space)
            }

            return true
        }

        return false
    }

    //endregion

    //region SELECT

    onLeftMouseDownSelect() {
        if (this.currentDrawObject && this.onLeftMouseDownOnObject()) {
            return
        }

        let selectedElement = this.getSelectedElement()

        if (!selectedElement) {
            this.resetCurrentDrawObject()

            if (!this.props.editMode)
                this.props.setSelectedElement(null)

            return
        }

        if (!this.currentDrawObject || this.currentDrawObject.geometryId !== selectedElement.geometryId) {
            this.resetCurrentDrawObject()
            this.currentDrawObject = selectedElement
            this.currentDrawObject.setActive(true)
            switch (this.currentDrawObject.drawingRepresentationType) {
                case drawingTypes.outline:
                    this.mouseAction = mouseActionTypes.selectOutline
                    this.props.setSelectedElement(null)
                    break
                case drawingTypes.wall:
                    this.mouseAction = mouseActionTypes.selectedWall
                    this.props.setSelectedElement(null)
                    break
                case drawingTypes.space:
                    this.mouseAction = mouseActionTypes.selectSpace
                    this.onLeftMouseDownSpace()
                    this.setState({showSpaceInfo: true})
                    break
                case drawingTypes.door:
                    this.mouseAction = mouseActionTypes.selectDoor
                    this.props.setSelectedElement(null)
                    break
                case drawingTypes.pillar:
                    this.mouseAction = mouseActionTypes.selectPillar
                    this.props.setSelectedElement(null)
                    break
                case drawingTypes.workplace:
                    this.mouseAction = mouseActionTypes.selectWorkplace
                    this.onLeftMouseDownWorkplace()
                    this.setShowWorkplaceModalOpen(true)
                    break
                case drawingTypes.terminal:
                    this.mouseAction = mouseActionTypes.selectTerminal
                    this.onLeftMouseDownTerminal()
                    this.setState({showTerminalInfo: true})
                    break
                default:
                    break
            }
        } else if (this.currentDrawObject.geometryId === selectedElement.geometryId) {
            this.onLeftMouseDownOnObject()
        }
    }

    onLeftMouseDownOnObject() {
        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.outline:
                return this.onLeftMouseDownOutline()
            case drawingTypes.wall:
                return this.onLeftMouseDownWall()
            case drawingTypes.space:
                return this.onLeftMouseDownSpace()
            case drawingTypes.door:
                return this.onLeftMouseDownDoor()
            case drawingTypes.pillar:
                return this.onLeftMouseDownPillar()
            case drawingTypes.scale:
                return this.onLeftMouseDownScale()
            case drawingTypes.workplace:
                return this.onLeftMouseDownWorkplace()
            case drawingTypes.terminal:
                return this.onLeftMouseDownTerminal()
            default:
                return false
        }
    }

    getSelectedElement() {
        let doors = []
        let pillars = []
        let workplaces = []
        let terminals = []
        let walls = []

        this.drawDoors.children.forEach(door => doors = doors.concat(door.children))
        this.drawPillars.children.forEach(pillar => pillars = pillars.concat(pillar.children))
        this.drawWorkplaces.children.forEach(workplace => workplaces = workplaces.concat(workplace.children))
        this.drawTerminals.children.forEach(terminal => terminals = terminals.concat(terminal.children))
        this.drawWalls.children.forEach(wall => walls = walls.concat(wall.edgeGroup.children))

        const doorIntersects = this.raycaster.intersectObjects(doors)
        const pillarIntersects = this.raycaster.intersectObjects(pillars)
        const workplaceIntersects = this.raycaster.intersectObjects(workplaces)
        const terminalIntersects = this.raycaster.intersectObjects(terminals)
        const wallIntersects = this.raycaster.intersectObjects(walls, true)

        const spaceFaces = this.getAllSpaceFaces()
        const spaceIntersects = this.raycaster.intersectObjects(spaceFaces)

        const outlineFaces = this.getAllOutlineFaces()
        const outlineIntersects = this.raycaster.intersectObjects(outlineFaces)

        if (doorIntersects.length > 0) {
            return doorIntersects[0].object.parent
        } else if (pillarIntersects.length > 0) {
            return pillarIntersects[0].object.parent
        } else if (workplaceIntersects.length > 0) {
            return workplaceIntersects[0].object.parent
        } else if (terminalIntersects.length > 0) {
            return terminalIntersects[0].object.parent
        } else if (wallIntersects.length > 0) {
            return wallIntersects[0].object.name === drawingTypes.edge ? wallIntersects[0].object.parent.parent : wallIntersects[0].object.parent.parent.parent
        } else if (spaceIntersects.length) {
            return spaceIntersects[0].object.parent
        } else if (outlineIntersects.length) {
            return outlineIntersects[0].object.parent
        }
        return null
    }

    onMouseMoveSelect(event) {
        if (!this.currentDrawObject)
            return

        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.outline:
                this.onMouseMoveOutline(event)
                return
            case drawingTypes.wall:
                this.onMouseMoveWall(event)
                return
            case drawingTypes.door:
                this.onMouseMoveDoor(event)
                return
            case drawingTypes.pillar:
                this.onMouseMovePillar(event)
                return
            case drawingTypes.space:
                this.onMouseMoveSpace(event)
                return
            case drawingTypes.workplace:
                this.onMouseMoveWorkplace(event)
                return
            case drawingTypes.terminal:
                this.onMouseMoveTerminal(event)
                return
            default:
                return
        }
    }

    onLeftMouseUpSelect() {
        if (this.currentDrawObject) {
            switch (this.currentDrawObject.drawingRepresentationType) {
                case drawingTypes.outline:
                    this.onLeftMouseUpOutline()
                    return
                case drawingTypes.wall:
                    this.onLeftMouseUpWall()
                    return
                case drawingTypes.pillar:
                    this.onLeftMouseUpPillar()
                    return
                case drawingTypes.door:
                    this.onLeftMouseUpDoor()
                    return
                case drawingTypes.space:
                    this.onLeftMouseUpSpace()
                    return
                case drawingTypes.workplace:
                    this.onLeftMouseUpWorkplace()
                    return
                case drawingTypes.terminal:
                    this.onLeftMouseUpTerminal()
                    return
                default:
                    return
            }
        }
    }

    //endregion

    //region SCALE

    onLeftMouseDownScale() {
        switch (this.mouseAction) {
            case mouseActionTypes.none:
                let scaleVertices = []

                this.drawScale.children.forEach(child => scaleVertices = scaleVertices.concat(child.vertexGroup.children))

                const intersects = this.raycaster.intersectObjects(scaleVertices)

                if (intersects.length > 0) {
                    this.currentSelectedVertex = intersects[0].object
                    this.currentDrawObject = this.currentSelectedVertex.parent.parent

                    this.mouseAction = mouseActionTypes.dragVertexScale
                }
                break
            case mouseActionTypes.newScale:
                removeAllChildren(this.drawScale)

                this.setPreviewVertex(false)

                this.currentDrawObject = new DrawLine()
                this.setHandlerScale(this.currentDrawObject)
                this.currentSelectedVertex = this.currentDrawObject.init(drawingTypes.scale, new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z))

                this.drawingFloor.add(this.currentDrawObject)

                this.mouseAction = mouseActionTypes.dragVertexScale
                break
            case mouseActionTypes.dragVertexScale:
                this.drawScale.add(this.currentDrawObject)
                this.currentDrawObject = null
                this.mouseAction = mouseActionTypes.newScale

                this.storeChanges(this.drawScale.children[0].geometryId)

                this.handleShowScaleInput(true)

                break
            default:
                break
        }
    }

    onMouseMoveScale(event) {
        let currentPosition = null

        switch (this.mouseAction) {
            case mouseActionTypes.newScale:
                currentPosition = new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)

                this.updatePreviewVertex(currentPosition)
                break
            case mouseActionTypes.dragVertexScale:
                currentPosition = new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)

                if (event.shiftKey) {
                    const otherVertexPosition = this.currentDrawObject.getOtherVertexPosition(this.currentSelectedVertex)
                    const horizontal = Math.abs(otherVertexPosition.x - currentPosition.x)
                    const vertical = Math.abs(otherVertexPosition.z - currentPosition.z)

                    currentPosition = horizontal > vertical ?
                        new THREE.Vector3(currentPosition.x, 0.05, otherVertexPosition.z) :
                        new THREE.Vector3(otherVertexPosition.x, 0.05, currentPosition.z)
                }
                this.currentDrawObject.updateVertex(this.currentSelectedVertex, currentPosition)
                break
            default:
                break
        }
    }

    onLeftMouseUpScaleSelect() {
        switch (this.mouseAction) {
            case  mouseActionTypes.dragVertexScale:
                this.mouseAction = mouseActionTypes.none
                this.storeChanges(this.drawScale.children[0].geometryId)
                break
            default:
                break
        }
    }

    onEscapeDownScale() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragVertexScale:
                this.resetCurrentDrawObject()
                this.setPreviewVertex(true, drawingTypes.scale)
                this.mouseAction = mouseActionTypes.newScale
                break
            default:
                break
        }
    }

    //endregion

    //region OUTER & INNER WALLS

    //region OUTLINE

    onLeftMouseDownOutline(event) {
        let currentPosition, orientation = null
        let vertexIntersects, returnValue

        switch (this.mouseAction) {
            case mouseActionTypes.newOutline:
                this.setPreviewVertex(false)
                this.currentDrawObject = new DrawPolygon()
                this.setHandlerScale(this.currentDrawObject);

                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllOutlineVertices()))

                this.currentDrawObject.init(currentPosition, drawingTypes.outline)

                this.drawingFloor.add(this.currentDrawObject)

                this.drawDimensioningText.visible = true
                this.updateDimensioningText(currentPosition, 0.00)

                this.mouseAction = mouseActionTypes.addVertexOutline
                returnValue = true
                break
            case mouseActionTypes.addVertexOutline:
                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllOutlineVertices()))

                const firstVertex = this.currentDrawObject.getFirstVertex()
                const distanceToFirstVertex = currentPosition.distanceTo(firstVertex.position)

                if (distanceToFirstVertex < this.props.settings.polygonCloseDistance) {
                    if (!this.currentDrawObject.closePolygon()) {
                        returnValue = false
                        break
                    }
                    this.drawOutline.add(this.currentDrawObject)

                    this.setPreviewVertex(true, drawingTypes.outline)
                    this.mouseAction = mouseActionTypes.newOutline
                    this.currentDrawObject.setActive(true)
                    this.storeChanges(this.currentDrawObject.geometryId)
                } else {
                    this.currentDrawObject.addNewVertex(currentPosition)
                }
                returnValue = true
                break
            case mouseActionTypes.selectOutline:
                if (!this.currentDrawObject) {
                    returnValue = false
                    break
                }

                this.setPreviewVertex(false, drawingTypes.outline)

                this.currentHoveredVertex = null
                this.currentHoveredEdge = null

                vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)
                const edgeIntersects = this.raycaster.intersectObjects(this.currentDrawObject.edgeGroup.children, true)
                const faceIntersects = this.raycaster.intersectObject(this.currentDrawObject.face)

                // Drag or select a vertex
                if (vertexIntersects.length > 0) {
                    this.currentHoveredVertex = vertexIntersects[0].object
                    this.mouseAction = mouseActionTypes.dragVertexOutline
                }
                // Add vertex to edge
                else if (edgeIntersects.length > 0) {
                    this.setPreviewVertex(false, drawingTypes.outline)
                    this.currentHoveredEdge = edgeIntersects[0].object.name === drawingTypes.edge ? edgeIntersects[0].object : edgeIntersects[0].object.parent
                    this.currentHoveredVertex = this.currentDrawObject.addVertexToEdge(this.currentHoveredEdge, this.currentIntersectionPosition.clone())
                    this.mouseAction = mouseActionTypes.dragVertexOutline
                }
                // Drag the face
                else if (faceIntersects.length > 0) {
                    this.lastMouseMovePosition = this.currentIntersectionPosition.clone()
                    this.mouseAction = mouseActionTypes.dragFaceOutline
                    returnValue = false
                    break
                } else {
                    returnValue = false
                    break
                }
                returnValue = true
                break
            case mouseActionTypes.selectedVertexOutline:
                if (!this.currentDrawObject) {
                    returnValue = false
                    break
                }

                this.setPreviewVertex(false, drawingTypes.outline)

                this.currentHoveredVertex = null

                vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)

                if (vertexIntersects.length > 0) {
                    this.currentHoveredVertex = vertexIntersects[0].object
                    this.mouseAction = mouseActionTypes.dragVertexOutline
                } else {
                    this.mouseAction = mouseActionTypes.selectOutline
                    returnValue = false
                    break
                }

                returnValue = true
                break
            default:
                returnValue = false
                break
        }

        this.showMagicGuideline(currentPosition, orientation)
        return returnValue
    }

    onMouseMoveOutline(event) {
        let currentPosition, orientation = null
        switch (this.mouseAction) {
            case mouseActionTypes.newOutline:
                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllOutlineVertices()))

                this.updatePreviewVertex(currentPosition)
                break
            case mouseActionTypes.addVertexOutline:
                currentPosition = new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)

                if (event.shiftKey && (event.ctrlKey || event.metaKey)) {
                    currentPosition = this.getOrthogonalPosition(currentPosition, this.currentDrawObject.vertexGroup.children)

                    let vertices = this.getAllOutlineVertices()
                        .concat(this.currentDrawObject.vertexGroup.children)
                        .filter(vertex => vertex !== this.currentDrawObject.lastVertex);

                    ({
                        currentPosition,
                        orientation
                    } = this.getNearestVerticesValuesAsPosition(currentPosition, vertices))

                    currentPosition = this.getOrthogonalPosition(currentPosition, this.currentDrawObject.vertexGroup.children)
                } else if (event.shiftKey) {
                    currentPosition = this.getOrthogonalPosition(currentPosition, this.currentDrawObject.vertexGroup.children)
                } else if (event.ctrlKey || event.metaKey) {
                    let vertices = this.getAllOutlineVertices()
                        .concat(this.currentDrawObject.vertexGroup.children)
                        .filter(vertex => vertex !== this.currentDrawObject.lastVertex);

                    ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, vertices))
                }

                this.currentDrawObject.updateVertex(null, currentPosition)

                const distance = this.currentDrawObject.getDistanceToPreviousVertex()

                this.updateDimensioningText(currentPosition, distance)

                break
            case mouseActionTypes.dragVertexOutline:
                let vertices = this.getAllOutlineVertices()
                    .concat(this.currentDrawObject.vertexGroup.children)
                    .filter(vertex => vertex !== this.currentHoveredVertex);

                ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, vertices))

                this.currentDrawObject.updateVertex(this.currentHoveredVertex, currentPosition)
                break
            case mouseActionTypes.dragFaceOutline:
                const deltaPosition = new THREE.Vector3(
                    this.currentIntersectionPosition.x - this.lastMouseMovePosition.x,
                    0,
                    this.currentIntersectionPosition.z - this.lastMouseMovePosition.z,
                )
                if (deltaPosition.distanceTo(new THREE.Vector3(0, 0, 0))) {
                    this.currentDrawObject.moveDelta(deltaPosition)
                    this.lastMouseMovePosition = this.currentIntersectionPosition.clone()
                }
                break
            case mouseActionTypes.selectOutline:
                this.setPreviewVertex(false, drawingTypes.outline)

                const vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)
                const edgeIntersects = this.raycaster.intersectObjects(this.currentDrawObject.edgeGroup.children, true)

                if (edgeIntersects.length > 0 && vertexIntersects.length === 0) {
                    this.setPreviewVertex(true, drawingTypes.outline)
                }

                return
            default:
                break
        }

        this.showMagicGuideline(currentPosition, orientation)
    }

    onLeftMouseUpOutline() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragVertexOutline:
                if (this.currentIntersectionPosition.distanceTo(this.lastMousePositionLeftClickDown) === 0) {
                    this.currentDrawObject.selectVertex(this.currentHoveredVertex)
                    this.currentSelectedVertex = this.currentHoveredVertex

                    this.mouseAction = mouseActionTypes.selectedVertexOutline
                } else {
                    this.storeChanges(this.currentDrawObject.geometryId)
                }
                this.showMagicGuideline()
                break
            case mouseActionTypes.dragFaceOutline:
                if (this.hasMouseMoved()) {
                    this.storeChanges(this.currentDrawObject.geometryId)
                }
                this.mouseAction = mouseActionTypes.selectOutline
                return
            default:
                break
        }
    }

    onBackspaceDownOutline() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexOutline:
                this.currentDrawObject.removeNewVertex()
                this.currentDrawObject.updateVertex(null, new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z))
                this.mouseAction = mouseActionTypes.selectOutline
                this.storeChanges(this.currentDrawObject.geometryId)
                return
            case mouseActionTypes.selectedVertexOutline:
                this.currentDrawObject.deleteVertex(this.currentHoveredVertex)
                this.currentHoveredVertex = null

                this.mouseAction = mouseActionTypes.selectOutline
                this.storeChanges(this.currentDrawObject.geometryId)
                return
            case mouseActionTypes.selectOutline:
                this.drawOutline.remove(this.currentDrawObject)
                this.resetCurrentDrawObject()
                this.storeChanges()
                break
            default:
                return
        }
    }

    onEscapeDownOutline() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexOutline:
                this.resetCurrentDrawObject()
                this.setPreviewVertex(true, drawingTypes.outline)

                this.mouseAction = mouseActionTypes.newOutline
                return
            case mouseActionTypes.selectedVertexOutline:
                this.currentDrawObject.deselectVertices()
                this.mouseAction = mouseActionTypes.selectOutline
                return
            case mouseActionTypes.selectOutline:
                this.resetCurrentDrawObject()
                return
            default:
                return
        }
    }

    onEnterDownOutline() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexOutline:
                if (!this.currentDrawObject.closePolygon())
                    break
                this.drawOutline.add(this.currentDrawObject)

                this.resetCurrentDrawObject()

                this.setPreviewVertex(true, drawingTypes.outline)
                this.mouseAction = mouseActionTypes.newOutline
                this.storeChanges()
                return
            case mouseActionTypes.selectOutline:
                this.resetCurrentDrawObject()
                return
            case mouseActionTypes.selectedVertexOutline:
                this.currentDrawObject.deselectVertices()
                this.mouseAction = mouseActionTypes.selectOutline
                return
            default:
                return
        }
    }

    onKeyDDownOutline(event) {
        if (this.mouseAction !== mouseActionTypes.selectOutline) {
            return
        }

        let vertexPositions = []
        this.currentDrawObject.vertexGroup.children.forEach(vertex => vertexPositions.push([vertex.position.x, vertex.position.z]))

        let minXValue = Number.MAX_VALUE
        let maxXValue = -Number.MAX_VALUE

        vertexPositions.forEach(position => {
            if (position[0] < minXValue) {
                minXValue = position[0]
            }
            if (position[0] > maxXValue) {
                maxXValue = position[0]
            }
        })

        const newOutline = new DrawPolygon()

        this.setHandlerScale(newOutline)
        newOutline.reInit(vertexPositions)

        newOutline.moveDelta(new THREE.Vector3(maxXValue - minXValue, 0, 0))
        this.drawOutline.add(newOutline)

        this.currentDrawObject = newOutline
        this.storeChanges(this.currentDrawObject.geometryId)

    }

    closeOutlineAndSaveChanges = () => {
        this.drawOutline.add(this.currentDrawObject)

        this.resetCurrentDrawObject()

        this.setPreviewVertex(true, drawingTypes.outline)
        this.mouseAction = mouseActionTypes.newOutline
        this.storeChanges()
    }

    getAllOutlineVertices() {
        let vertices = []

        this.drawOutline.children.forEach(outline => vertices = vertices.concat(outline.vertexGroup.children))

        return vertices
    }

    getAllOutlineFaces() {
        const outlineFaces = []

        this.drawOutline.children.filter(child => child.face)
            .forEach(child => outlineFaces.push(child.face))

        return outlineFaces
    }

    getOutlineIntersects() {
        let outlines = []

        this.drawOutline.children.forEach(outline => outlines = outlines.concat(outline.edgeGroup.children))

        return this.raycaster.intersectObjects(outlines, true)
    }

    //endregion

    //region WALL

    onLeftMouseDownWall(event) {
        let currentPosition, orientation = null
        let vertexIntersects, returnValue
        switch (this.mouseAction) {
            case mouseActionTypes.newWall:

                this.drawWalls.children.forEach(wall => wall.setActive(true))

                const vertices = []

                this.drawWalls.children.forEach(wall => {
                    vertices.push(wall.vertexGroup.children[0])
                    vertices.push(wall.vertexGroup.children[wall.vertexGroup.children.length - 1])
                })

                const intersects = this.raycaster.intersectObjects(vertices)

                this.drawWalls.children.forEach(wall => wall.setActive(false))

                this.setPreviewVertex(false);

                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()
                    .concat(this.getAllWallVertices())))

                // Merge with existing walls
                if (intersects.length > 0) {
                    const lastVertex = intersects[0].object
                    this.currentDrawObject = lastVertex.parent.parent

                    this.currentDrawObject.setActive(true)
                    this.currentDrawObject.reorderVertices(lastVertex)
                    this.currentDrawObject.addVertex(currentPosition)

                    this.drawWalls.remove(this.currentDrawObject)

                }
                // Create new wall
                else {

                    this.currentDrawObject = new DrawPolyline()
                    this.setHandlerScale(this.currentDrawObject)

                    this.currentDrawObject.init(currentPosition, drawingTypes.wall)
                }

                this.drawingFloor.add(this.currentDrawObject)

                this.mouseAction = mouseActionTypes.addVertexWall
                returnValue = true
                break
            case mouseActionTypes.addVertexWall:
                this.currentDrawObject.addVertex(new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z))
                returnValue = true
                break
            case mouseActionTypes.selectedWall:
                if (!this.currentDrawObject) {
                    returnValue = false
                    break
                }

                this.setPreviewVertex(false, drawingTypes.wall)

                this.currentHoveredVertex = null

                vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)

                // Drag or select a vertex
                if (vertexIntersects.length > 0) {
                    this.currentHoveredVertex = vertexIntersects[0].object
                    this.mouseAction = mouseActionTypes.dragVertexWall
                } else {
                    returnValue = false
                    break
                }

                returnValue = true
                break
            case mouseActionTypes.selectedVertexWall:
                if (!this.currentDrawObject) {
                    returnValue = false
                    break
                }

                this.setPreviewVertex(false, drawingTypes.space)
                this.currentHoveredVertex = null

                vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)

                if (vertexIntersects.length > 0) {
                    this.currentHoveredVertex = vertexIntersects[0].object
                    this.mouseAction = mouseActionTypes.dragVertexWall
                    returnValue = true
                    break
                } else {
                    this.mouseAction = mouseActionTypes.selectedWall
                }

                this.currentDrawObject.deselectVertices()

                returnValue = false
                break
            default:
                returnValue = false
                break
        }

        this.showMagicGuideline(currentPosition, orientation)
        return returnValue
    }

    onMouseMoveWall(event) {
        let currentPosition, orientation = null

        switch (this.mouseAction) {
            case mouseActionTypes.newWall:
                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()
                    .concat(this.getAllWallVertices())))

                this.updatePreviewVertex(currentPosition)
                break
            case mouseActionTypes.addVertexWall:
                currentPosition = new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)

                if (event.shiftKey && (event.ctrlKey || event.metaKey)) {
                    const lastVertexPosition = this.currentDrawObject.getSecondLastVertexPosition()
                    const horizontal = Math.abs(lastVertexPosition.x - currentPosition.x)
                    const vertical = Math.abs(lastVertexPosition.z - currentPosition.z)

                    let vertices = this.getAllSpaceVertices()
                        .concat(this.getAllWallVertices())
                        .concat(this.currentDrawObject.vertexGroup.children)
                        .filter(vertex => vertex !== this.currentDrawObject.lastVertex)

                    let result = this.getNearestVerticesValuesAsPosition(currentPosition, vertices)

                    currentPosition = result.currentPosition
                    orientation = result.orientation

                    if (horizontal > vertical) {
                        currentPosition.z = lastVertexPosition.z
                    } else {
                        currentPosition.x = lastVertexPosition.x
                    }
                } else if (event.shiftKey) {
                    const lastVertexPosition = this.currentDrawObject.getSecondLastVertexPosition()
                    const horizontal = Math.abs(lastVertexPosition.x - currentPosition.x)
                    const vertical = Math.abs(lastVertexPosition.z - currentPosition.z)

                    currentPosition = horizontal > vertical ? new THREE.Vector3(currentPosition.x, 0.05, lastVertexPosition.z) : new THREE.Vector3(lastVertexPosition.x, 0.05, currentPosition.z)
                } else if (event.ctrlKey || event.metaKey) {
                    let vertices = this.getAllSpaceVertices()
                        .concat(this.getAllWallVertices())
                        .concat(this.currentDrawObject.vertexGroup.children)
                        .filter(vertex => vertex !== this.currentDrawObject.lastVertex);

                    ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, vertices))
                }

                this.currentDrawObject.updateVertex(null, currentPosition)
                break
            case mouseActionTypes.dragVertexWall:
                let vertices = this.getAllSpaceVertices()
                    .concat(this.getAllWallVertices())
                    .concat(this.currentDrawObject.vertexGroup.children)
                    .filter(vertex => vertex !== this.currentHoveredVertex);

                ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, vertices))

                this.currentDrawObject.updateVertex(this.currentHoveredVertex, currentPosition)
                break
            default:
                break
        }

        this.showMagicGuideline(currentPosition, orientation)
    }

    onLeftMouseUpWall() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragVertexWall:
                if (this.currentIntersectionPosition.distanceTo(this.lastMousePositionLeftClickDown) === 0) {
                    this.currentDrawObject.selectVertex(this.currentHoveredVertex)
                    this.mouseAction = mouseActionTypes.selectedVertexWall
                } else {
                    this.mouseAction = mouseActionTypes.selectedWall
                    this.storeChanges(this.currentDrawObject.geometryId)
                }
                break
            default:
                break
        }

    }

    onBackspaceDownWall() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexWall:
                this.currentDrawObject.removeNewVertex()
                this.currentDrawObject.updateVertex(null, new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z))
                return
            case mouseActionTypes.selectedWall:
                this.drawWalls.remove(this.currentDrawObject)
                this.resetCurrentDrawObject()
                this.storeChanges()
                break
            case mouseActionTypes.selectedVertexWall:
                this.currentDrawObject.deleteVertex(this.currentHoveredVertex)
                this.currentHoveredVertex = null

                this.mouseAction = mouseActionTypes.selectedWall
                return
            default:
                return
        }
    }

    onEnterDownWall() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexWall:
                this.currentDrawObject.endPolyline()

                this.drawWalls.add(this.currentDrawObject)

                this.resetCurrentDrawObject()

                this.setPreviewVertex(true, drawingTypes.wall)

                this.mouseAction = mouseActionTypes.newWall
                this.storeChanges()
                break
            default:
                return
        }
    }

    onEscapeDownWall() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexWall:
                this.resetCurrentDrawObject()
                this.setPreviewVertex(true, drawingTypes.wall)
                this.mouseAction = mouseActionTypes.newWall
                return
            case mouseActionTypes.selectedVertexWall:
                this.currentDrawObject.deselectVertices()
                this.mouseAction = mouseActionTypes.selectedWall
                return
            default:
                this.resetCurrentDrawObject()
                return
        }
    }

    getAllWallVertices() {
        let vertices = []
        this.drawWalls.children.forEach(wall => vertices = vertices.concat(wall.vertexGroup.children))

        return vertices
    }

    getWallIntersects() {
        let walls = []

        this.drawWalls.children.forEach(wall => walls = walls.concat(wall.edgeGroup.children))

        return this.raycaster.intersectObjects(walls, true)
    }

    //endregion

    getOutlineAndWallIntersects() {
        let intersects = this.getOutlineIntersects()
        intersects.push(...this.getWallIntersects())
        return intersects
    }

    //endregion

    //region MARKER

    //region DOOR

    onLeftMouseDownDoor() {
        switch (this.mouseAction) {
            case mouseActionTypes.newDoor:
                this.newMarker(this.drawDoors, drawingTypes.door)
                break
            case mouseActionTypes.selectDoor:
                this.startDragMarker(mouseActionTypes.dragDoor)
                break
            default:
                break
        }
    }

    onMouseMoveDoor(event) {
        switch (this.mouseAction) {
            case mouseActionTypes.newDoor:
            case mouseActionTypes.dragDoor:
                this.drawDimensioningText.visible = false
                this.moveMarker(event)
                return
            default:
                break
        }

    }

    onLeftMouseUpDoor() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragDoor:
                this.endDragMarker(mouseActionTypes.selectDoor, drawingTypes.door)
                break
            case mouseActionTypes.selectDoor:
                this.drawDimensioningText.visible = true
                this.updateDimensioningText(this.currentIntersectionPosition.clone(), this.currentDrawObject.radius * 2)
                break
            default:
                break
        }

    }

    onBackspaceDownDoor() {
        switch (this.mouseAction) {
            case mouseActionTypes.newDoor:
                this.drawDoors.remove(this.drawDoors.children[this.drawDoors.children.length - 1])
                return
            case mouseActionTypes.selectDoor:
                this.deleteMarker(this.drawDoors)
                return
            default:
                return
        }
    }

    onKeyDDownDoor(event) {
        const newDoor = this.duplicateMarker(this.drawDoors, drawingTypes.door)

        if (newDoor) {
            this.drawDoors.add(newDoor)
            this.storeChanges()
        }
    }

    //endregion

    //region PILLAR

    onLeftMouseDownPillar() {
        switch (this.mouseAction) {
            case mouseActionTypes.newPillar:
                this.newMarker(this.drawPillars, drawingTypes.pillar, false)
                break
            case mouseActionTypes.selectPillar:
                this.startDragMarker(mouseActionTypes.dragPillar)
                break
            default:
                break
        }
    }

    onMouseMovePillar(event) {
        switch (this.mouseAction) {
            case mouseActionTypes.newPillar:
            case mouseActionTypes.dragPillar:
                this.drawDimensioningText.visible = false
                this.moveMarker(event, false, false)
                return
            default:
                break
        }

    }

    onLeftMouseUpPillar() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragPillar:
                this.endDragMarker(mouseActionTypes.selectPillar, drawingTypes.pillar, false)
                break
            case mouseActionTypes.selectPillar:
                this.drawDimensioningText.visible = true
                this.updateDimensioningText(this.currentIntersectionPosition.clone(), this.currentDrawObject.radius * 2)
                break
            default:
                break
        }
    }

    onBackspaceDownPillar() {
        switch (this.mouseAction) {
            case mouseActionTypes.newPillar:
                this.drawPillars.remove(this.drawPillars.children[this.drawPillars.children.length - 1])
                return
            case mouseActionTypes.selectPillar:
                this.deleteMarker(this.drawPillars)
                return
            default:
                return
        }
    }

    onEscapeDownPillar() {
        switch (this.mouseAction) {
            case mouseActionTypes.newDownPillar:
                this.mouseAction = mouseActionTypes.newPillar
                return
            default:
                return
        }
    }

    onKeyDDownPillar(event) {
        const newPillar = this.duplicateMarker(this.drawPillars, drawingTypes.pillar, false)

        if (newPillar) {
            this.drawPillars.add(newPillar)
            this.storeChanges()
        }
    }

    //endregion

    initMarker(type, radius = null) {
        this.currentDrawObject = new DrawMarker()
        this.currentDrawObject.init(new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z), type, radius)
        this.drawingFloor.add(this.currentDrawObject)
    }

    newMarker(group, drawingType, checkOutline = true) {
        let outlineIntersects = checkOutline ? this.getOutlineAndWallIntersects() : [checkOutline]

        if (outlineIntersects.length > 0) {
            if (outlineIntersects[0].object && outlineIntersects[0].object.parent && outlineIntersects[0].object.parent.parent && outlineIntersects[0].object.parent.parent.parent)
                this.currentDrawObject.parentElementId = outlineIntersects[0].object.parent.parent.parent.uuid
            group.add(this.currentDrawObject)

            this.props.setMarkerSize(drawingType, this.currentDrawObject.radius)
            this.initMarker(drawingType, this.currentDrawObject.radius)

            this.storeChanges()
        }
    }

    startDragMarker(newMouseActionType) {
        if (!this.currentDrawObject)
            return
        this.lastMarkerPosition = this.currentDrawObject.position.clone()
        this.mouseAction = newMouseActionType
    }

    moveMarker(event, checkOutline = true, autoSnap = true) {
        let currentPosition, orientation

        ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()
                .concat(this.getAllMarkerVertices()),
            autoSnap, 1))

        this.currentDrawObject.updatePosition(currentPosition)

        if (checkOutline) {
            const outlineIntersects = this.getOutlineAndWallIntersects()

            if (outlineIntersects.length > 0) {
                this.currentDrawObject.setPlaceable(true)
            } else {
                this.currentDrawObject.setPlaceable(false)
            }
        }

        this.lastMouseMovePosition = this.currentIntersectionPosition.clone()

        this.showMagicGuideline(currentPosition, orientation)
    }

    endDragMarker(selectMouseActionType, drawingType, checkOutline = true) {
        if (this.currentIntersectionPosition.distanceTo(this.lastMousePositionLeftClickDown) === 0) {
            this.mouseAction = selectMouseActionType
        } else {
            let storeChanges = true

            if (checkOutline) {

                const outlineIntersects = this.getOutlineAndWallIntersects()

                if (outlineIntersects.length === 0) {
                    this.currentDrawObject.updatePosition(this.lastMarkerPosition)
                }
            }

            this.currentDrawObject.setPlaceable(true)

            if (this.props[drawingType + 'Size'] !== this.currentDrawObject.radius) {
                storeChanges = true
                this.props.setMarkerSize(drawingType, this.currentDrawObject.radius)
            }
            if (storeChanges) {
                this.storeChanges(this.currentDrawObject.geometryId)
            }
        }
    }

    deleteMarker(group) {
        group.remove(this.currentDrawObject)
        this.resetCurrentDrawObject()
        this.storeChanges()
    }

    duplicateMarker(markerGroup, type, checkOutline = true) {
        if (markerGroup.children.length <= 1) {
            return null
        }

        const lastDoorPosition = markerGroup.children[markerGroup.children.length - 1].position
        const secondLastDoorPosition = markerGroup.children[markerGroup.children.length - 2].position
        const deltaVector = new THREE.Vector3(lastDoorPosition.x - secondLastDoorPosition.x, 0, lastDoorPosition.z - secondLastDoorPosition.z)
        const newPosition = new THREE.Vector3(lastDoorPosition.x + deltaVector.x, 0.05, lastDoorPosition.z + deltaVector.z)

        const newMarker = new DrawMarker()
        newMarker.init(newPosition, type, markerGroup.children[markerGroup.children.length - 1].radius)

        let edges = []

        this.drawSpaces.children.forEach(polygon => edges = edges.concat(polygon.edgeGroup.children))

        const rayOrigin = new THREE.Vector3(newPosition.x, 20, newPosition.z)

        return checkOutline ? new THREE.Raycaster(rayOrigin, new THREE.Vector3(0, -1, 0), 0, 100).intersectObjects(edges, true).length ? newMarker : null : newMarker
    }

    getAllMarkerVertices() {
        let vertices = [];

        [
            this.drawDoors,
            this.drawPillars,
        ].forEach(group => {
            group.children
                .filter(child => child !== this.currentDrawObject)
                .forEach(marker => vertices.push(marker))
        })

        return vertices
    }

    onKeyAltUpBuild() {
        this.drawDimensioningText.visible = false

        if (this.currentDrawObject)
            this.props.setMarkerSize(this.currentDrawObject.drawingRepresentationType, this.currentDrawObject.radius)
    }

    //endregion

    onLeftMouseDownBuildSelect(event) {
        if (this.currentDrawObject) {
            switch (this.currentDrawObject.drawingRepresentationType) {
                case drawingTypes.wall:
                    this.onLeftMouseDownWall(event)
                    return
                default:
                    break
            }
        }
    }

    onLeftMouseUpBuildSelect() {
        if (!this.currentDrawObject)
            return

        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.outline:
                this.onLeftMouseUpOutline()
                return
            case drawingTypes.wall:
                this.onLeftMouseUpWall()
                return
            case drawingTypes.door:
                this.onLeftMouseUpDoor()
                return
            case drawingTypes.pillar:
                this.onLeftMouseUpPillar()
                return

            case drawingTypes.workplace:
                this.onLeftMouseUpWorkplace()
                return
            default:
                return
        }
    }

    //endregion

    //region SPACE

    onLeftMouseDownSpace(event) {
        let currentPosition, orientation = null
        let vertexIntersects, returnValue

        switch (this.mouseAction) {
            case mouseActionTypes.newSpace:
                this.setPreviewVertex(false)
                this.currentDrawObject = new DrawPolygon()
                this.setHandlerScale(this.currentDrawObject);

                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()))

                this.currentDrawObject.init(currentPosition, drawingTypes.space)

                this.drawingFloor.add(this.currentDrawObject)

                this.drawDimensioningText.visible = true
                this.updateDimensioningText(currentPosition, 0.00)

                this.mouseAction = mouseActionTypes.addVertexSpace
                returnValue = true
                break
            case mouseActionTypes.addVertexSpace:
                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()))

                const firstVertex = this.currentDrawObject.getFirstVertex()
                const distanceToFirstVertex = currentPosition.distanceTo(firstVertex.position)

                if (distanceToFirstVertex < this.props.settings.polygonCloseDistance) {
                    if (!this.currentDrawObject.closePolygon()) {
                        returnValue = false
                        break
                    }
                    this.onCloseSpace()
                } else {
                    this.currentDrawObject.addNewVertex(currentPosition)
                }
                returnValue = true
                break
            case mouseActionTypes.selectSpace:
                if (!this.currentDrawObject) {
                    returnValue = false
                    break
                }

                if (!this.props.editMode)
                    this.props.setSelectedElement(this.currentDrawObject)

                this.setPreviewVertex(false, drawingTypes.space)

                this.currentHoveredVertex = null
                this.currentHoveredEdge = null

                vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)
                const edgeIntersects = this.raycaster.intersectObjects(this.currentDrawObject.edgeGroup.children, true)
                const faceIntersects = this.raycaster.intersectObject(this.currentDrawObject.face)

                // Drag or select a vertex
                if (vertexIntersects.length > 0) {
                    this.currentHoveredVertex = vertexIntersects[0].object
                    this.mouseAction = mouseActionTypes.dragVertexSpace
                }
                // Add vertex to edge
                else if (edgeIntersects.length > 0) {
                    this.currentHoveredEdge = edgeIntersects[0].object.name === drawingTypes.edge ? edgeIntersects[0].object : edgeIntersects[0].object.parent
                    this.currentHoveredVertex = this.currentDrawObject.addVertexToEdge(this.currentHoveredEdge, this.currentIntersectionPosition.clone())
                    this.mouseAction = mouseActionTypes.dragVertexSpace
                }
                // Drag the face
                else if (faceIntersects.length > 0) {
                    this.lastMouseMovePosition = this.currentIntersectionPosition.clone()
                    this.mouseAction = mouseActionTypes.dragFaceSpace
                    returnValue = false
                    break
                } else {
                    returnValue = false
                    break
                }
                returnValue = true
                break
            case mouseActionTypes.selectVertexSpace:
                if (!this.currentDrawObject) {
                    returnValue = false
                    break
                }

                this.setPreviewVertex(false, drawingTypes.space)

                this.currentHoveredVertex = null

                vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)
                if (vertexIntersects.length > 0) {
                    this.currentHoveredVertex = vertexIntersects[0].object
                    this.mouseAction = mouseActionTypes.dragVertexSpace
                    returnValue = true
                    break
                } else {
                    this.mouseAction = mouseActionTypes.selectSpace
                    returnValue = false
                    break
                }
            default:
                returnValue = false
                break
        }
        this.showMagicGuideline(currentPosition, orientation)
        return returnValue
    }

    onMouseMoveSpace(event) {
        let currentPosition, orientation = null

        switch (this.mouseAction) {
            case mouseActionTypes.newSpace:
                ({
                    currentPosition,
                    orientation
                } = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()))

                this.updatePreviewVertex(currentPosition)
                break
            case mouseActionTypes.addVertexSpace:
                currentPosition = new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)

                if (event.shiftKey && (event.ctrlKey || event.metaKey)) {
                    currentPosition = this.getOrthogonalPosition(currentPosition, this.currentDrawObject.vertexGroup.children)

                    let vertices = this.getAllSpaceVertices()
                        .concat(this.currentDrawObject.vertexGroup.children)
                        .filter(vertex => vertex !== this.currentDrawObject.lastVertex);

                    ({
                        currentPosition,
                        orientation
                    } = this.getNearestVerticesValuesAsPosition(currentPosition, vertices))

                    currentPosition = this.getOrthogonalPosition(currentPosition, this.currentDrawObject.vertexGroup.children)
                } else if (event.shiftKey) {
                    currentPosition = this.getOrthogonalPosition(currentPosition, this.currentDrawObject.vertexGroup.children)
                } else if (event.ctrlKey || event.metaKey) {
                    let vertices = this.getAllSpaceVertices()
                        .concat(this.currentDrawObject.vertexGroup.children)
                        .filter(vertex => vertex !== this.currentDrawObject.lastVertex);

                    ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, vertices))
                }
                this.currentDrawObject.updateVertex(null, currentPosition)

                const distance = this.currentDrawObject.getDistanceToPreviousVertex()

                this.updateDimensioningText(currentPosition, distance)

                break
            case mouseActionTypes.dragVertexSpace:
                let vertices = this.getAllSpaceVertices()
                    .concat(this.currentDrawObject.vertexGroup.children)
                    .filter(vertex => vertex !== this.currentHoveredVertex);

                ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, vertices))

                this.currentDrawObject.updateVertex(this.currentHoveredVertex, currentPosition)
                break
            case mouseActionTypes.dragFaceSpace:
                const deltaPosition = new THREE.Vector3(
                    this.currentIntersectionPosition.x - this.lastMouseMovePosition.x,
                    0,
                    this.currentIntersectionPosition.z - this.lastMouseMovePosition.z,
                )
                if (deltaPosition.distanceTo(new THREE.Vector3(0, 0, 0))) {
                    this.currentDrawObject.moveDelta(deltaPosition)
                    this.lastMouseMovePosition = this.currentIntersectionPosition.clone()
                }
                break
            case mouseActionTypes.selectSpace:
                this.setPreviewVertex(false, drawingTypes.space)

                const vertexIntersects = this.raycaster.intersectObjects(this.currentDrawObject.vertexGroup.children)
                const edgeIntersects = this.raycaster.intersectObjects(this.currentDrawObject.edgeGroup.children, true)

                if (edgeIntersects.length > 0 && vertexIntersects.length === 0) {
                    this.setPreviewVertex(true, drawingTypes.space)
                }

                return
            default:
                break
        }

        this.showMagicGuideline(currentPosition, orientation)
    }

    onLeftMouseUpSpace() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragVertexSpace:
                if (this.currentIntersectionPosition.distanceTo(this.lastMousePositionLeftClickDown) === 0) {
                    this.currentDrawObject.selectVertex(this.currentHoveredVertex)
                    this.currentSelectedVertex = this.currentHoveredVertex

                    this.mouseAction = mouseActionTypes.selectVertexSpace
                } else {
                    this.storeChanges(this.currentDrawObject.geometryId)
                }
                this.showMagicGuideline()
                break
            case mouseActionTypes.dragFaceSpace:
                if (this.hasMouseMoved()) {
                    this.storeChanges(this.currentDrawObject.geometryId)
                }
                this.mouseAction = mouseActionTypes.selectSpace
                return
            default:
                break
        }
    }

    onBackspaceDownSpace() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexSpace:
                this.currentDrawObject.removeNewVertex()
                this.currentDrawObject.updateVertex(null, new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z))
                return
            case mouseActionTypes.selectVertexSpace:
                this.currentDrawObject.deleteVertex(this.currentSelectedVertex)
                this.storeChanges(this.currentDrawObject.geometryId)
                return
            case mouseActionTypes.selectSpace:
                this.currentDrawObject.toBeDeleted = true
                this.resetCurrentDrawObject()
                this.storeChanges()
                break
            default:
                return
        }
    }

    onEscapeDownSpace() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexSpace:
                this.resetCurrentDrawObject()
                this.setPreviewVertex(true, drawingTypes.space)

                this.mouseAction = mouseActionTypes.newSpace
                return
            case mouseActionTypes.selectVertexSpace:
                this.currentDrawObject.deselectVertices()
                this.mouseAction = mouseActionTypes.selectSpace
                return
            case mouseActionTypes.selectSpace:
                this.resetCurrentDrawObject()
                return
            default:
                return
        }
    }

    onEnterDownSpace() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexSpace:
                if (!this.currentDrawObject.closePolygon())
                    break
                this.onCloseSpace()
                return
            case mouseActionTypes.selectSpace:
                this.resetCurrentDrawObject()
                return
            case mouseActionTypes.selectVertexSpace:
                this.currentDrawObject.deselectVertices()
                this.mouseAction = mouseActionTypes.selectSpace
                return
            default:
                return
        }
    }

    onKeyDDownSpace(event) {
        if (this.mouseAction !== mouseActionTypes.selectSpace) {
            return
        }

        let vertexPositions = []
        this.currentDrawObject.vertexGroup.children.forEach(vertex => vertexPositions.push([vertex.position.x, vertex.position.z]))

        let minXValue = Number.MAX_VALUE
        let maxXValue = -Number.MAX_VALUE

        vertexPositions.forEach(position => {
            if (position[0] < minXValue) {
                minXValue = position[0]
            }
            if (position[0] > maxXValue) {
                maxXValue = position[0]
            }
        })

        const newPolygon = new DrawPolygon()
        newPolygon.init(vertexPositions, drawingTypes.space)
        this.setHandlerScale(newPolygon)
        newPolygon.reInit(vertexPositions, drawingTypes.space)

        newPolygon.moveDelta(new THREE.Vector3(maxXValue - minXValue, 0, 0))
        this.drawSpaces.add(newPolygon)

        this.currentDrawObject = newPolygon
        this.storeChanges(this.currentDrawObject.geometryId)

    }

    onCloseSpace() {
        this.mouseAction = mouseActionTypes.selectSpace
        this.setState({showSpaceInfo: true})
    }

    closeSpaceAndSaveChanges = () => {

        this.currentDrawObject.space = this.props.creationObject
        this.drawSpaces.add(this.currentDrawObject)

        this.setPreviewVertex(true, drawingTypes.space)
        this.storeChanges()
        this.resetAfterSaveSpace()
        this.mouseAction = mouseActionTypes.newSpace
    }

    updateSpaceAndSaveChanges = (object) => {
        this.currentDrawObject.updated = true

        this.currentDrawObject.space = object
        this.drawSpaces.add(this.currentDrawObject)

        this.storeChanges(this.currentDrawObject.geometryId)
    }

    resetAfterSaveSpace() {
        this.resetCurrentDrawObject()
        this.props.setElement({})
        this.setState({showSpaceInfo: false})
    }

    onMouseMoveSpaceSelect(event) {
        if (!this.currentDrawObject)
            return

        switch (this.currentDrawObject.drawingRepresentationType) {
            case drawingTypes.space:
                this.onMouseMoveSpace(event)
                return
            default:
                return
        }
    }

    getCurrentPositionAndOrientation(event, vertices, autoSnap = false, snapDistance = this.props.settings.snapDistance) {
        let currentPosition = new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z)

        if (autoSnap && (event.ctrlKey || event.metaKey))
            return {currentPosition: currentPosition, orientation: null}
        else if (autoSnap || (event.ctrlKey || event.metaKey)) {
            return this.getNearestVerticesValuesAsPosition(currentPosition, vertices, snapDistance)
        } else {
            return {currentPosition: currentPosition, orientation: null}
        }
    }

    getNearestVerticesValuesAsPosition(currentPosition, vertices, snapDistance = this.props.settings.snapDistance) {
        let nearestVerticalDistance = Number.MAX_VALUE
        let nearestVerticalPosition = null
        let nearestHorizontalDistance = Number.MAX_VALUE
        let nearestHorizontalPosition = null
        let direction = null

        vertices
            .forEach(v => {
                const verticalDistance = Math.abs(currentPosition.x - v.position.x)
                if (verticalDistance < nearestVerticalDistance) {
                    nearestVerticalDistance = verticalDistance
                    nearestVerticalPosition = v.position
                }

                const horizontalDistance = Math.abs(currentPosition.z - v.position.z)
                if (horizontalDistance < nearestHorizontalDistance) {
                    nearestHorizontalDistance = horizontalDistance
                    nearestHorizontalPosition = v.position
                }
            })

        snapDistance = snapDistance / (this.camera.zoom > orbitControlsConstants.initMinZoomOut ? this.camera.zoom : .1)

        if (nearestHorizontalPosition && nearestHorizontalDistance < snapDistance &&
            Math.abs(nearestHorizontalPosition.z - currentPosition.z) < snapDistance) {
            currentPosition.z = nearestHorizontalPosition.z
            direction = orientation.horizontal
        }

        if (nearestVerticalPosition && nearestVerticalDistance < snapDistance &&
            Math.abs(nearestVerticalPosition.x - currentPosition.x) < snapDistance) {
            currentPosition.x = nearestVerticalPosition.x
            direction = direction ? orientation.horVer : orientation.vertical
        }

        return {currentPosition: currentPosition, orientation: direction}
    }

    getOrthogonalPosition(currentPosition, vertices) {
        if (vertices.length < 2) {
            return currentPosition
        } else if (vertices.length === 2) {
            const lastVertexPosition = vertices[vertices.length - 2].position
            const horizontal = Math.abs(lastVertexPosition.x - currentPosition.x)
            const vertical = Math.abs(lastVertexPosition.z - currentPosition.z)

            currentPosition = horizontal > vertical ? new THREE.Vector3(currentPosition.x, 0.05, lastVertexPosition.z) : new THREE.Vector3(lastVertexPosition.x, 0.05, currentPosition.z)
        } else {
            const startVertex = vertices[vertices.length - 3].position
            const endVertex = vertices[vertices.length - 2].position

            const startVector2 = new THREE.Vector2(startVertex.x, startVertex.z)
            const endVector2 = new THREE.Vector2(endVertex.x, endVertex.z)

            const diff = startVector2.clone()
                .sub(endVector2)
                .normalize()

            const point = endVector2.clone()
                .add(diff)
                .rotateAround(endVector2, -Math.PI / 2)

            const direction = point.clone()
                .sub(endVector2)
                .normalize()
            let n = 1
            if (direction.y) {
                n = (currentPosition.z - point.y) / direction.y
            } else {
                n = (currentPosition.x - point.x) / direction.x
            }

            point.addScaledVector(direction, n)

            currentPosition = new THREE.Vector3(point.x, 0.05, point.y)
        }

        return currentPosition
    }

    getAllSpaceVertices() {
        let vertices = []

        this.drawSpaces.children.forEach(polygon => vertices = vertices.concat(polygon.vertexGroup.children))

        return vertices
    }

    getAllSpaceFaces() {
        const spacesFaces = []

        this.drawSpaces.children.filter(child => child.face)
            .forEach(child => spacesFaces.push(child.face))

        return spacesFaces
    }

    getSpaceIntersects() {
        let outlines = []

        this.drawSpaces.children.forEach(polygon => outlines = outlines.concat(polygon.edgeGroup.children))

        return this.raycaster.intersectObjects(outlines, true)
    }

    showMagicGuideline(position, direction) {
        switch (direction) {
            case orientation.horizontal:
                this.drawMagicGuidelineHorizontal.update(position, direction)
                this.drawMagicGuidelineHorizontal.setActive(true)
                this.drawMagicGuidelineVertical.setActive(false)
                break
            case orientation.vertical:
                this.drawMagicGuidelineVertical.update(position, direction)
                this.drawMagicGuidelineHorizontal.setActive(false)
                this.drawMagicGuidelineVertical.setActive(true)
                break
            case orientation.horVer:
                this.drawMagicGuidelineHorizontal.update(position, orientation.horizontal)
                this.drawMagicGuidelineVertical.update(position, orientation.vertical)
                this.drawMagicGuidelineHorizontal.setActive(true)
                this.drawMagicGuidelineVertical.setActive(true)
                break
            default:
                this.drawMagicGuidelineHorizontal.setActive(false)
                this.drawMagicGuidelineVertical.setActive(false)
                break
        }
    }

    handleSaveSpaces = () => {
        const {buildingId, floorId} = this.props.match.params

        let spacesToCreate = []
        let spacesToUpdate = []
        let spacesToDelete = []

        this.props.storedGeometries[this.props.currentStoreIndex]['drawSpaces'].forEach(element => {
            let space = element.space
            if (space && space.id !== null && space.id !== undefined && element.toBeDeleted) {
                spacesToDelete.push(space.id)
            } else if (space && space.id !== null && space.id !== undefined && element.updated) {
                space.outline = element
                space.outline.space = null
                space.outline.vertices.forEach((vertex, index) => {
                    vertex.index = index
                })
                spacesToUpdate.push(space)
            } else if (space && space.id === undefined) {
                space.outline = element
                space.outline.space = null
                space.outline.vertices.forEach((vertex, index) => {
                    vertex.index = index
                })
                spacesToCreate.push(space)
            }
        })

        if (spacesToCreate.length)
            this.props.createSpaces(buildingId, floorId, spacesToCreate)
        if (spacesToUpdate.length)
            this.props.updateSpaces(buildingId, floorId, spacesToUpdate)
        if (spacesToDelete.length)
            this.props.deleteSpaces(buildingId, floorId, spacesToDelete)
    }

    //endregion

    //region WORKPLACE

    onLeftMouseDownWorkplace() {
        switch (this.mouseAction) {
            case mouseActionTypes.newWorkplace:
                if (this.state.surroundingSpace)
                    this.setShowWorkplaceModalOpen(true)
                else
                    this.props.setError(this.props.t('no_valid_space'))
                break
            case mouseActionTypes.none:
            case mouseActionTypes.selectWorkplace:
                this.setState({selectedWorkplace: this.currentDrawObject.workplace})
                if (!this.props.editMode)
                    this.props.setSelectedElement(this.currentDrawObject)
                this.startDragElement(mouseActionTypes.dragWorkplace)
                break
            default:
                break
        }
    }

    onMouseMoveWorkplace(event) {
        switch (this.mouseAction) {
            case mouseActionTypes.newWorkplace:
                if (this.state.showWorkplaceInfo)
                    return
                this.moveWorkplace(event, false, false)
                this.setSurroundingSpace()
                return
            case mouseActionTypes.dragWorkplace:
                this.moveWorkplace(event, false, false)
                return
            default:
                break
        }
    }

    setSurroundingSpace() {
        const surroundingSpaces = this.raycaster.intersectObjects(this.drawSpaces.children, true);
        if (surroundingSpaces.length) {
            if (surroundingSpaces[0].object.parent instanceof DrawPolygon && surroundingSpaces[0].object.parent.space && surroundingSpaces[0].object.parent.space.typeOfUse !== TYPE_OF_USE_MEETING) {
                if (this.currentDrawObject)
                    this.currentDrawObject.setPlaceable(true)

                this.setState({surroundingSpace: surroundingSpaces[0].object.parent.space})
                return true
            } else if (surroundingSpaces[0].object.parent.parent instanceof DrawPolygon && surroundingSpaces[0].object.parent.parent.space && surroundingSpaces[0].object.parent.parent.space.typeOfUse !== TYPE_OF_USE_MEETING) {
                this.setState({surroundingSpace: surroundingSpaces[0].object.parent.parent.space})

                if (this.currentDrawObject)
                    this.currentDrawObject.setPlaceable(true)

                return true
            } else {
                this.setState({surroundingSpace: null})
                return false
            }
        } else {
            this.setState({surroundingSpace: null})
            return false
        }
    }

    onLeftMouseUpWorkplace() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragWorkplace:
                this.endDragWorkplace(mouseActionTypes.selectWorkplace, drawingTypes.workplace, false)
                break
            default:
                break
        }
    }

    onBackspaceDownWorkplace() {
        switch (this.mouseAction) {
            case mouseActionTypes.newWorkplace:
                this.drawWorkplaces.remove(this.drawWorkplaces.children[this.drawWorkplaces.children.length - 1])
                return
            case mouseActionTypes.selectWorkplace:
                this.currentDrawObject.toBeDeleted = true
                this.resetCurrentDrawObject()
                this.storeChanges()
                break
            default:
                return
        }
    }

    onEscapeDownWorkplace() {
        switch (this.mouseAction) {
            case mouseActionTypes.newWorkplace:
                this.resetAfterSaveWorkplace()
                this.initWorkplace(drawingTypes.workplace, TYPE_OF_ACTIVITY_UNDEFINED)
                this.mouseAction = mouseActionTypes.newWorkplace
                return
            case mouseActionTypes.selectWorkplace:
                this.resetCurrentDrawObject()
                this.mouseAction = mouseActionTypes.selectWorkplace
                return
            default:
                return
        }
    }

    initWorkplace(type, activity, radius = null) {
        this.currentDrawObject = new DrawWorkplace()
        this.currentDrawObject.init(new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z), drawingTypes.workplaceUndefined, activity)
        this.drawingFloor.add(this.currentDrawObject)
    }

    startDragElement(newMouseActionType) {
        this.lastPositionBeforeDrag = this.currentDrawObject.position.clone()
        this.mouseAction = newMouseActionType
    }

    moveWorkplace(event, checkOutline = true, autoSnap = true) {
        let currentPosition, orientation = null

        if (!event.altKey && this.currentDrawObject) {
            ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()
                    .concat(this.getAllMarkerVertices()),
                autoSnap, 1))

            this.currentDrawObject.updatePosition(currentPosition)

            const surroundingSpaces = this.raycaster.intersectObjects(this.drawSpaces.children, true);

            if (surroundingSpaces.length) {
                if (surroundingSpaces[0].object.parent instanceof DrawPolygon && surroundingSpaces[0].object.parent.space && surroundingSpaces[0].object.parent.space.typeOfUse !== TYPE_OF_USE_MEETING) {
                    this.currentDrawObject.setPlaceable(true)
                } else if (surroundingSpaces[0].object.parent.parent instanceof DrawPolygon && surroundingSpaces[0].object.parent.parent.space && surroundingSpaces[0].object.parent.parent.space.typeOfUse !== TYPE_OF_USE_MEETING) {
                    this.currentDrawObject.setPlaceable(true)
                } else {
                    this.currentDrawObject.setPlaceable(false)

                    this.drawDimensioningText.visible = false
                }
            } else {
                this.currentDrawObject.setPlaceable(false)

                this.drawDimensioningText.visible = false
            }

            this.lastMouseMovePosition = this.currentIntersectionPosition.clone()

            this.showMagicGuideline(currentPosition, orientation)
        }
    }

    endDragWorkplace(selectMouseActionType, drawingType) {
        if (this.currentIntersectionPosition.distanceTo(this.lastMousePositionLeftClickDown) === 0) {
            this.mouseAction = selectMouseActionType
        } else {
            if (!this.setSurroundingSpace()) {
                this.currentDrawObject.setPlaceable(true)
                this.currentDrawObject.updatePosition(this.lastPositionBeforeDrag)
                this.currentDrawObject = null
                this.mouseAction = mouseActionTypes.none
                return
            }

            let workplace = this.currentDrawObject.getGeometryAsJSON().workplace
            workplace.geometry = this.currentDrawObject.getGeometryAsJSON()
            workplace.geometry.workplace = null
            workplace.spaceId = this.state.surroundingSpace.id

            let storeChanges = true

            this.currentDrawObject.setPlaceable(true)
            this.currentDrawObject.setActive(true)
            this.drawWorkplaces.add(this.currentDrawObject)
            if (storeChanges) {
                this.storeChanges(this.currentDrawObject.geometryId)
                this.resetAfterSaveWorkplace()
            }
        }
    }

    getWorkplaceType(value) {
        if (value === TYPE_OF_ACTIVITY_CREATIVE) {
            return drawingTypes.workplaceCreative
        } else if (value === TYPE_OF_ACTIVITY_CALLS) {
            return drawingTypes.workplaceCalls
        } else if (value === TYPE_OF_ACTIVITY_TALK) {
            return drawingTypes.workplaceTalk
        } else if (value === TYPE_OF_ACTIVITY_CONCENTRATE) {
            return drawingTypes.workplaceConcentrate
        } else {
            return drawingTypes.workplaceUndefined
        }
    }

    addWorkplace = () => {
        let element = this.props.creationObject
        element.geometry = this.currentDrawObject.getGeometryAsJSON()
        element.geometry.workplace = null

        this.props.setElement(element)

        this.createNewWorkplace()
    }

    createNewWorkplace = () => {

        this.currentDrawObject.workplace = this.props.creationObject
        this.currentDrawObject.workplace.spaceId = this.state.surroundingSpace.id
        this.drawWorkplaces.add(this.currentDrawObject)

        this.storeChanges()
        this.resetAfterSaveWorkplace()
        this.mouseAction = mouseActionTypes.newWorkplace
        this.initWorkplace(drawingTypes.workplace, TYPE_OF_ACTIVITY_UNDEFINED)
    }

    updateWorkplace = (object) => {
        this.currentDrawObject.updated = true
        this.currentDrawObject.status = object.status
        this.currentDrawObject.workplace = object
        const elementToRemove = this.drawWorkplaces.getObjectByProperty('uuid', this.currentDrawObject.uuid)
        if (elementToRemove)
            this.drawWorkplaces.remove(elementToRemove)
        this.drawWorkplaces.add(this.currentDrawObject)

        this.storeChanges(this.currentDrawObject.geometryId)
    }

    setShowWorkplaceModalOpen = (value) => {
        this.setState({showWorkplaceInfo: value})
    }

    resetAfterSaveWorkplace() {
        this.resetCurrentDrawObject()
        this.props.setElement({})
        this.setShowWorkplaceModalOpen(false)
    }

    handleSaveWorkplaces = () => {
        const {buildingId, floorId} = this.props.match.params

        let workplacesToCreate = []
        let workplacesToUpdate = []
        let workplacesToDelete = []

        this.props.storedGeometries[this.props.currentStoreIndex]['drawWorkplaces'].forEach(element => {
            let workplace = element.workplace
            if (workplace && workplace.id !== null && workplace.id !== undefined && element.toBeDeleted) {
                workplacesToDelete.push(workplace.id)
            } else if (workplace && workplace.id !== null && workplace.id !== undefined && element.updated) {
                workplace.outline = element
                workplace.outline.workplace = null
                workplace.outline.vertices.forEach((vertex, index) => {
                    vertex.index = index
                })
                workplacesToUpdate.push(workplace)
            } else if (workplace && workplace.id === undefined) {
                workplace.outline = element
                workplace.outline.workplace = null
                workplace.outline.vertices.forEach((vertex, index) => {
                    vertex.index = index
                })
                workplacesToCreate.push(workplace)
            }
        })

        if (workplacesToCreate.length)
            this.props.createWorkplaces(buildingId, floorId, workplacesToCreate)
        if (workplacesToUpdate.length)
            this.props.updateWorkplaces(buildingId, floorId, workplacesToUpdate)
        if (workplacesToDelete.length)
            this.props.deleteWorkplaces(buildingId, floorId, workplacesToDelete)
    }

    //endregion

    //region TERMINALS
    onLeftMouseDownTerminal(event) {
        switch (this.mouseAction) {
            case mouseActionTypes.newTerminal:
                this.setState({showTerminalInfo: true})
                // this.initTerminal()
                break
            case mouseActionTypes.none:
            case mouseActionTypes.selectTerminal:
                this.startDragElement(mouseActionTypes.dragTerminal)
                this.setState({showTerminalInfo: true})
                this.props.setElement(this.currentDrawObject)

                if (!this.props.editMode)
                    this.props.setSelectedElement(this.currentDrawObject)

                break
            default:
                break

        }
    }

    initTerminal() {
        this.currentDrawObject = new DrawTerminal()
        this.currentDrawObject.init(new THREE.Vector3(this.currentIntersectionPosition.x, 0.05, this.currentIntersectionPosition.z))
        this.drawingFloor.add(this.currentDrawObject)
    }

    addTerminal = (values, update = false) => {
        let {name} = values
        let element = this.props.creationObject
        element.name = name
        element.homezone = this.props.floor.id
        element.geometry = this.currentDrawObject.getGeometryAsJSON()
        element.geometry.terminal = null
        element.updated = update

        this.props.setElement(element)
        this.storeChanges(element.geometryId)

        this.createNewTerminal()
    }

    updateTerminal = (values) => {
        this.currentDrawObject.updated = true
        this.currentDrawObject.terminal.name = values.name
        const elementToRemove = this.drawTerminals.getObjectByProperty('uuid', this.currentDrawObject.uuid)
        if (elementToRemove)
            this.drawTerminals.remove(elementToRemove)
        this.drawTerminals.add(this.currentDrawObject)

        this.storeChanges(this.currentDrawObject.geometryId)
    }

    onMouseMoveTerminal(event) {
        switch (this.mouseAction) {
            case mouseActionTypes.newTerminal:
                if (this.state.showTerminalInfo)
                    return
                this.moveTerminal(event)
                return
            case mouseActionTypes.dragTerminal:
                this.moveTerminal(event)
                return;
            default:
                return;
        }
    }

    moveTerminal(event) {
        let currentPosition, orientation = null

        if (this.currentDrawObject) {
            ({currentPosition, orientation} = this.getCurrentPositionAndOrientation(event, this.getAllSpaceVertices()
                .concat(this.getAllMarkerVertices())))

            this.currentDrawObject.updatePosition(currentPosition)

            this.lastMouseMovePosition = this.currentIntersectionPosition.clone()

            this.showMagicGuideline(currentPosition, orientation)
        }
    }

    onEscapeDownTerminal() {
        switch (this.mouseAction) {
            case mouseActionTypes.newTerminal:
                this.resetCurrentDrawObject(mouseActionTypes.newTerminal)
                this.props.setElement({})
                this.initTerminal()
                this.setState({showTerminalInfo: false})
                return
            default:
                return
        }
    }

    onBackspaceDownTerminal() {
        switch (this.mouseAction) {
            case mouseActionTypes.newTerminal:
                this.drawTerminals.remove(this.drawTerminals.children[this.drawTerminals.children.length - 1])
                return
            case mouseActionTypes.selectTerminal:
                this.currentDrawObject.toBeDeleted = true
                this.resetCurrentDrawObject()
                this.storeChanges()
                break
            default:
                return
        }
    }

    createNewTerminal() {
        this.currentDrawObject.terminal = this.props.creationObject
        this.drawTerminals.add(this.currentDrawObject)

        this.storeChanges()
        this.resetCurrentDrawObject()
        this.props.setElement({})
        this.mouseAction = mouseActionTypes.newTerminal
        this.initTerminal()
    }

    onLeftMouseUpTerminal() {
        switch (this.mouseAction) {
            case mouseActionTypes.dragTerminal:
                this.endDragTerminal(mouseActionTypes.selectTerminal)
                break
            default:
                break
        }
    }

    endDragTerminal(selectMouseActionType) {
        if (this.currentIntersectionPosition.distanceTo(this.lastMousePositionLeftClickDown) === 0) {
            this.mouseAction = selectMouseActionType
        } else {

            let terminal = this.currentDrawObject.getGeometryAsJSON().terminal
            terminal.geometry = this.currentDrawObject.getGeometryAsJSON()
            terminal.geometry.terminal = null

            let storeChanges = true

            this.drawTerminals.add(this.currentDrawObject)
            if (storeChanges) {
                this.storeChanges(this.currentDrawObject.geometryId)
                this.resetCurrentDrawObject()
                this.props.setElement({})
                this.setState({showTerminalInfo: false})
            }
        }
    }

    handleSaveTerminals = () => {
        let terminalsToCreate = []
        let terminalsToUpdate = []
        let terminalsToDelete = []


        this.props.storedGeometries[this.props.currentStoreIndex]['drawTerminals'].forEach(element => {
            let terminal = element.terminal
            if (terminal && terminal.id !== null && terminal.id !== undefined && element.toBeDeleted) {
                terminalsToDelete.push(terminal.id)
            } else if (terminal && terminal.id !== null && terminal.id !== undefined && element.updated) {
                terminal.geometry = element
                terminal.geometry.terminal = null
                terminalsToUpdate.push(terminal)
            } else if (terminal && terminal.id === undefined) {
                terminal.geometry = element
                terminal.geometry.terminal = null
                terminalsToCreate.push(terminal)
            }
        })


        if (terminalsToCreate.length)
            this.props.createTerminals(terminalsToCreate)
        if (terminalsToUpdate.length)
            this.props.updateTerminals(terminalsToUpdate)
        if (terminalsToDelete.length)
            this.props.deleteTerminals(terminalsToDelete)
    }


    //endregion

    //region SAVE & DISCARD

    handleSaveScale(userDistance) {
        const {buildingId, floorId} = this.props.match.params

        if (this.drawScale.children.length) {
            const threeDistance = this.drawScale.children[0].getDistance()
            const scaleFactor = userDistance / threeDistance

            const scaleGeometry = this.drawScale.children[0].getGeometryAsJSON()

            const scaledVertices = []

            scaleGeometry.vertices.forEach((vertex, index) => scaledVertices.push({
                x: vertex.x * scaleFactor,
                y: vertex.y * scaleFactor,
                index: index
            }))

            scaleGeometry.vertices = scaledVertices

            scaleGeometry.scaleFactor = scaleFactor * this.props.imageScale

            scaleGeometry.geometryType = GEOMETRY_TYPE_LINE

            scaleGeometry.drawingRepresentationType = DRAWING_REPRESENTATION_TYPE_SCALE
            this.props.setImageScale(buildingId, floorId, scaleGeometry)
        }
    }

    handleSaveDrawing = () => {
        this.handleSaveGeometries()

        this.setState({dirtyFlag: false})
    }

    handleSaveGeometries = () => {
        const {buildingId, floorId} = this.props.match.params

        const drawingsToSave = [];

        ['drawScale',
            'drawOutline',
            'drawWalls',
            'drawDoors',
            'drawPillars'].forEach(property => {
            this.props.storedGeometries[this.props.currentStoreIndex][property].forEach(element => {
                element.vertices.forEach((vertex, index) => {
                    vertex.index = index
                })
                drawingsToSave.push(element)
            })
        })

        this.props.saveFloorGeometries(buildingId, floorId, drawingsToSave)
            .then(() => {
                this.handleSaveWorkplaces()
                this.handleSaveSpaces()
                this.handleSaveTerminals()
            })
    }

    discardAllChanges = () => {
        this.props.resetGeometries()
    }

    //endregion

    //region UNDO & REDO

    storeChanges(geometryId) {
        const geometries = {
            drawScale: this.getGeometriesAsJsonList(this.drawScale),
            drawOutline: this.getGeometriesAsJsonList(this.drawOutline),
            drawWalls: this.getGeometriesAsJsonList(this.drawWalls),
            drawDoors: this.getGeometriesAsJsonList(this.drawDoors),
            drawPillars: this.getGeometriesAsJsonList(this.drawPillars),
            drawSpaces: this.getGeometriesAsJsonList(this.drawSpaces),
            drawWorkplaces: this.getGeometriesAsJsonList(this.drawWorkplaces),
            drawTerminals: this.getGeometriesAsJsonList(this.drawTerminals),
            selectedGeometryId: geometryId,
        }

        this.props.storeGeometryChanges(geometries)
    }

    undoGeometryChange() {
        if (this.isUndoRedoBlocked())
            return

        this.props.undoGeometryChange()
    }

    redoGeometryChange() {
        if (this.isUndoRedoBlocked())
            return

        this.props.redoGeometryChange()
    }

    isUndoRedoBlocked() {
        switch (this.mouseAction) {
            case mouseActionTypes.addVertexSpace:
            case mouseActionTypes.addVertexWall:
            default:
                return false
        }
    }

    getGeometriesAsJsonList(threeGroup) {
        if (!threeGroup)
            return
        let jsonList = []
        threeGroup.children.forEach(child => {
            jsonList = jsonList.concat(child.getGeometryAsJSON())
        })

        return jsonList
    }

    //endregion

    //region Inspector

    getInspectorContent() {
        switch (this.props.currentTab) {
            case tabsNames.SCALE:
                return (<DistanceInput withSaveButton label={this.props.t('scale')}
                                       setSaveCallback={(scale) => this.handleSaveScale(scale)}
                                       initialValue={this.drawScale && this.drawScale.children.length ? this.drawScale.children[0].getDistance() : 10}
                                       active={this.state.scaleInputActive}/>)
            case tabsNames.DOORS:
                return (<DistanceInput setValueCallback={(scale) => this.currentDrawObject.updateRadius(scale)}
                                       label={this.props.t('diameter')}
                                       initialValue={this.currentDrawObject && this.currentDrawObject.radius ? this.currentDrawObject.radius * 2 : this.props.doorSize * 2}
                                       active/>)
            case tabsNames.PILLARS:
                return (<DistanceInput setValueCallback={(scale) => this.currentDrawObject.updateRadius(scale)}
                                       label={this.props.t('diameter')}
                                       initialValue={this.currentDrawObject && this.currentDrawObject.radius ? this.currentDrawObject.radius * 2 : this.props.pillarSize * 2}
                                       active/>)
            case tabsNames.TERMINAL:
                return this.state.showTerminalInfo ?
                    <TerminalInspectorContent element={this.currentDrawObject?.terminal}
                                              onSave={this.addTerminal}/> : null
            case tabsNames.SPACES:
                return this.state.showSpaceInfo ?
                    <SpaceInfo onSave={this.closeSpaceAndSaveChanges}/> : null
            case tabsNames.WORKPLACES:
                return this.state.showWorkplaceInfo ?
                    <WorkplaceInfo onSave={this.addWorkplace}/> : null
            case tabsNames.SELECT:
                if (this.currentDrawObject && this.currentDrawObject.drawingRepresentationType === drawingTypes.space && this.currentDrawObject.space) {
                    if (!this.props.creationObject || this.currentDrawObject.space.id !== this.props.creationObject.id)
                        this.props.setElement(this.currentDrawObject.space)
                    return this.state.showSpaceInfo ?
                        <SpaceInfo onSave={this.updateSpaceAndSaveChanges}
                                   disableAutofocus update/>
                        : null
                } else if (this.currentDrawObject && this.currentDrawObject.drawingRepresentationType === drawingTypes.workplace && this.currentDrawObject.workplace) {
                    if (!this.props.creationObject || this.currentDrawObject.workplace.id !== this.props.creationObject.id)
                        this.props.setElement(this.currentDrawObject.workplace)
                    return this.state.showWorkplaceInfo ?
                        <WorkplaceInfo onSave={this.updateWorkplace}
                                       update
                                       disableAutofocus/> : null
                } else if (this.currentDrawObject && this.currentDrawObject.drawingRepresentationType === drawingTypes.terminal && this.currentDrawObject.terminal) {
                    if (!this.props.creationObject || this.currentDrawObject.terminal.id !== this.props.creationObject.id)
                        this.props.setElement(this.currentDrawObject.terminal)
                    return this.state.showTerminalInfo ?
                        <TerminalInspectorContent element={this.currentDrawObject?.terminal}
                                                  update autofocus={false}
                                                  onSave={this.updateTerminal}/> : null
                } else if (this.state.showWorkplaceInfo || this.state.showSpaceInfo || this.state.showTerminalInfo) {
                    this.setState({showWorkplaceInfo: false, showSpaceInfo: false, showTerminalInfo: false})
                }
                break
            default:
                return <div/>
        }
    }

    handleShowScaleInput(value) {
        this.setState({scaleInputActive: value})
    }

    //endregion

    checkMousePositionOnEditorGUI(event) {
        let result = true

        if (event.clientX < this.sidebar || event.clientY < this.topbar) {
            result = false
        }

        if (result) {
            result = this.checkHtmlElementsOverEditor(event)
        }

        this.onEditorGUI = result
    }

    checkHtmlElementsOverEditor(event) {
        let result = true

        htmlElementsOverEditor.forEach(htmlElementName => {
            let htmlElement = this.htmlElementsOverEditor[htmlElementName]

            if (!htmlElement) {
                htmlElement = document.getElementsByClassName(htmlElementName)[0]
                this.htmlElementsOverEditor[htmlElementName] = htmlElement
            }

            if (!htmlElement) {
                return
            }

            if (htmlElement.offsetLeft < event.clientX && event.clientX < (htmlElement.offsetLeft + htmlElement.offsetWidth) &&
                htmlElement.offsetTop < event.clientY && event.clientY < (htmlElement.offsetTop + htmlElement.offsetHeight)) {
                result = false
            }
        })

        return result
    }

    resize = () => {
        this.topbar = 152
        this.sidebar = this.props.editMode ? 64 : 0
        this.width = this.mount.clientWidth
        this.height = this.mount.clientHeight

        if (this.camera) {
            this.camera.left = this.width / -16
            this.camera.right = this.width / 16
            this.camera.top = this.height / 16
            this.camera.bottom = this.height / -16
            this.camera.updateProjectionMatrix()

            this.renderer.setSize(this.width, this.height)
        }
    }

    centerCamera() {
        let minX = 999999999
        let minY = 999999999
        let maxX = -999999999
        let maxY = -999999999

        if (this.props.base64Image) {
            const {width, height} = this.backgroundImage.geometry.parameters

            minX = this.backgroundImage.position.x - (width / 2)
            minY = this.backgroundImage.position.z - (height / 2)
            maxX = this.backgroundImage.position.x + (width / 2)
            maxY = this.backgroundImage.position.z + (height / 2)
        } else if (this.props.geometries.length) {
            const positions = []

            const {drawOutlines, drawSpaces} = this.props.geometries

            let geometries = []
            geometries = geometries.concat(drawOutlines)
            geometries = geometries.concat(drawSpaces)

            geometries.forEach(geometry => {
                if (geometry.vertices.length) {
                    geometry.vertices.forEach(vertex => {
                        positions.push(new THREE.Vector3(vertex.x, 0, -vertex.y))
                    })
                }
            })

            getBoundingBoxPositions(positions)
                .forEach(position => {
                    if (position.x < minX) {
                        minX = position.x
                    }
                    if (position.z < minY) {
                        minY = position.z
                    }
                    if (position.x > maxX) {
                        maxX = position.x
                    }
                    if (position.z > maxY) {
                        maxY = position.z
                    }
                })
        } else if (this.props.floorPlan && this.props.floorPlan.facadeLinesAsPosLists.length) {
            this.props.floorPlan.facadeLinesAsPosLists.forEach((row) => {
                row.forEach((value, index) => {
                    if (index % 2 === 0) {
                        if (value > maxX)
                            maxX = value
                        if (value < minX)
                            minX = value
                    } else {
                        if (value > maxY)
                            maxY = value
                        if (value < minY)
                            minY = value
                    }
                })
            })
        } else {
            return
        }

        const centerX = (maxX - minX) / 2 + minX
        const centerY = (maxY - minY) / 2 + minY

        this.background.position.x = centerX
        this.background.position.z = -centerY

        this.orbitControls.target.x = centerX
        this.orbitControls.target.z = -centerY

        this.camera.position.x = centerX
        this.camera.position.z = -centerY

        this.catchPlane.position.x = centerX
        this.catchPlane.position.z = -centerY

        const distanceX = Math.abs(maxX - minX)
        const distanceY = Math.abs(maxY - minY)

        let cameraZoom = 1

        if (distanceX > 0 || distanceY > 0) {
            if (distanceX > distanceY) {
                const screenHeightThree = this.height / 12

                cameraZoom = screenHeightThree / distanceY
            } else {
                const screenWidthThree = this.width / 12

                cameraZoom = screenWidthThree / distanceX
            }
        }

        if (cameraZoom > this.orbitControls.maxZoom) {
            this.orbitControls.maxZoom = cameraZoom
        }

        this.camera.zoom = cameraZoom
        this.camera.updateProjectionMatrix()

        this.orbitControls.update()
    }

    updateCameraZoom() {
        this.updateHandlerScale()
        this.updateDimensioningTextScale()
    }

    updateHandlerScale() {
        let handlerScale = 1

        if (this.camera.zoom < orbitControlsConstants.handlerScaleMinZoomLevel) {
            if (this.camera.zoom > orbitControlsConstants.handlerScaleMaxZoomLevel) {
                handlerScale = 1 / this.camera.zoom
            } else {
                handlerScale = 1 / orbitControlsConstants.handlerScaleMaxZoomLevel
            }
        } else {
            handlerScale = 1 / orbitControlsConstants.handlerScaleMinZoomLevel
        }

        if (this.previewVertex) {
            this.previewVertex.geometry.dispose()
            this.previewVertex.geometry = getCircleGeometry(drawingSize.edgeHandlerSize * handlerScale, drawingSegments.vertex)
        }

        [
            this.drawScale,
            this.drawOutline,
            this.drawSpaces]
            .forEach(group => group.children
                .forEach(threeObject =>
                    threeObject.setHandlerScale(handlerScale)),
            )

        if (this.currentDrawObject && typeof (this.currentDrawObject.setHandlerScale) === 'function') {
            this.currentDrawObject.setHandlerScale(handlerScale)
        }
    }

    setHandlerScale(threeObject) {
        threeObject.setHandlerScale(this.camera.zoom < orbitControlsConstants.handlerScaleMinZoomLevel ? 1 / this.camera.zoom : 1 / orbitControlsConstants.handlerScaleMinZoomLevel)
    }

    updateDimensioningTextScale() {
        updateTextScaleMesh(this.drawDimensioningText, 1 / this.camera.zoom)
    }

    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }

    stop = () => {
        cancelAnimationFrame(this.frameId)
    }

    animate = () => {
        this.renderScene()
        setTimeout(() => {
            this.frameId = window.requestAnimationFrame(this.animate);
        }, 1000 / 30);
    }

    renderScene = () => {
        this.renderer.clear('#ddd', true)
        this.renderer.render(this.scene, this.camera)
    }

    clearDrawings() {
        [
            this.drawScale,
            this.drawOutline,
            this.drawWalls,
            this.drawDoors,
            this.drawPillars,
            this.drawSpaces,
            this.drawWorkplaces,
            this.drawTerminals,
            this.drawMagicGuidelineHorizontal,
            this.drawDimensioningText,
            this.drawMagicGuidelineVertical
        ].forEach(group => {
            freeMemory(group)
            removeAllChildren(group)
        })
    }

    drawDrawings() {
        this.clearDrawings()

        this.drawDrawScale()

        this.drawDrawOutline()

        this.drawDrawWalls()

        this.drawMarkers()

        this.drawDrawSpaces()

        this.drawDrawWorkplaces()

        this.drawDrawTerminals()
    }

    drawDrawScale() {
        this.props.geometries.drawScale.forEach(json => {
            const threeObject = new DrawLine()
            threeObject.setGeometryFromJSON(json)
            this.setHandlerScale(threeObject)

            this.drawScale.add(threeObject)
        })
    }

    drawDrawOutline() {
        this.props.geometries.drawOutline.forEach(json => {
            const threeObject = new DrawPolygon()
            threeObject.setGeometryFromJSON(json)
            this.setHandlerScale(threeObject)

            this.drawOutline.add(threeObject)
        })
    }

    drawDrawWalls() {
        this.props.geometries.drawWalls.forEach(json => {
            const threeObject = new DrawPolyline()
            threeObject.setGeometryFromJSON(json)
            this.setHandlerScale(threeObject)

            this.drawWalls.add(threeObject)
        })
    }

    drawMarkers() {
        this.props.geometries.drawDoors.forEach(json => {
            const newMarker = new DrawMarker()
            newMarker.setGeometryFromJSON(json)
            this.drawDoors.add(newMarker)
        })
        this.props.geometries.drawPillars.forEach(json => {
            const newMarker = new DrawMarker()
            newMarker.setGeometryFromJSON(json)
            this.drawPillars.add(newMarker)
        })
    }

    drawDrawSpaces() {
        this.props.geometries.drawSpaces.forEach(json => {
            const threeObject = new DrawPolygon()
            threeObject.setGeometryFromJSON(json)
            this.setHandlerScale(threeObject)
            if (json.toBeDeleted)
                threeObject.setInvisible()

            this.drawSpaces.add(threeObject)
        })
    }

    drawDrawWorkplaces() {
        const assignmentsWithImages = this.matchProfilePicturesWithAssignments()

        assignmentsWithImages.forEach(element => {
                let newWorkplace = new DrawWorkplace()
                const scale = this.props.imageScale
                if (element.assignments[0].id)
                    newWorkplace.setFromWorkplace(element.assignments[0].workplace, scale, element.assignments, element.image)
                else
                    newWorkplace.setFromWorkplace(element.assignments[0].workplace, scale)

                if (newWorkplace.toBeDeleted) {
                    newWorkplace.setInvisible()
                }

                this.drawWorkplaces.add(newWorkplace)
            }
        )
    }

    drawDrawTerminals() {
        this.props.geometries.drawTerminals.forEach(json => {
            let terminal = new DrawTerminal()
            terminal.setFromTerminal(json)


            if (json.toBeDeleted) {
                terminal.setInvisible()
            }

            this.drawTerminals.add(terminal)
        })
    }

    matchProfilePicturesWithAssignments = () => {
        let workplaces = _.cloneDeep(this.props.geometries.drawWorkplaces)
        return matchProfilePicturesWithAssignments(this.props.workplaceAssignments, this.props.profilePictures, workplaces)
    }

    isUnsavedChanges(tab = this.props.currentTab) {

        let result = false

        if (!this.drawingFloor) {
            result = true
        } else {
            switch (tab) {
                case tabsNames.SCALE:
                case tabsNames.OUTLINE:
                case tabsNames.WALLS:
                case tabsNames.DOORS:
                case tabsNames.PILLARS:
                case tabsNames.SPACES:
                case tabsNames.WORKPLACES:
                case tabsNames.TERMINAL:
                    result = this.props.currentStoreIndex > 0
                    break
                default:
                    break

            }
        }

        result = Boolean(result)

        this.setState({dirtyFlag: result})

        return result
    }

    handleTerminateDrawingMode = (skipCheck) => {
        if (!skipCheck && this.props.currentStoreIndex > 0) {
            this.handleSavePopupOpenState(true)
            return
        }
        this.props.terminateDrawingMode()
    }

    handleSavePopupOpenState = (value) => {
        this.setState({savePopupOpen: value})
    }

    render() {
        const {classes, t} = this.props
        return (
            <div className={classes.root}>
                <div className={classes.backHeader}>
                    <HeaderWithTitleAndBackButton backLabel={t('floor')} white
                                                  onNavigateBack={() => this.handleTerminateDrawingMode()}
                                                  additionalElement={this.props.editMode ?
                                                      <AdminButton
                                                          onClick={() => {
                                                              this.handleSaveGeometries()
                                                              this.resetCurrentDrawObject(this.mouseAction)
                                                              this.props.setSelectedDrawingTool(tabsNames.SELECT)
                                                              this.props.setCurrentDrawingTab(tabsNames.SELECT)
                                                              this.props.setElement({})
                                                          }}
                                                          disabled={this.props.currentStoreIndex === 0}
                                                          text={t('save')} primary
                                                          className={classes.button}/>
                                                      : null}/>
                </div>
                <div className={classes.drawingToolContent}>
                    <div className={classes.content}>
                        <div className={classes.contentWrapper}>
                            {this.props.editMode &&
                                <DrawingToolsBar onSave={this.handleSaveDrawing}
                                                 openSavePopup={() => this.handleSavePopupOpenState(true)}
                                                 handlePopupState={(value) => this.handleSavePopupOpenState(value)}
                                                 terminateCallback={() => this.handleTerminateDrawingMode(true)}
                                                 popupOpen={this.state.savePopupOpen}
                                                 savePopupOnCloseCallback={() => this.handleSavePopupOpenState(false)}
                                                 savePopupOnThirdOptionCallback={this.discardAllChanges}

                                />}
                            <Measure
                                bounds
                                onResize={() => {
                                    if (this.renderer && this.width && this.height) {
                                        this.renderer.setSize(this.width, this.height)
                                        this.resize()
                                    }
                                }}>
                                {({measureRef}) => (
                                    <div ref={measureRef} id={htmlClassNames.buildingsPlanPanel}
                                         style={{width: this.props.editMode ? 'calc(100% - 300px - 64px)' : '100%'}}
                                         className={classes.drawingEditorPanel + " " + this.props.styleClassName}>
                                        <div
                                            className={classes.buildingsPlanContainer}
                                            ref={(mount) => {
                                                this.mount = mount
                                            }}/>
                                    </div>
                                )}

                            </Measure>
                            {this.props.base64Image
                                ? this.props.editMode && this.renderModificationView()
                                : <div className={classes.loadingIndicator}>
                                    <LoadingIndicator/>
                                </div>
                            }
                            {this.props.editMode &&
                                <DrawingToolInspector
                                    onDelete={this.onBackspaceDown}
                                    showDeleteButton={!!this.currentDrawObject && this.props.currentTab === tabsNames.SELECT}
                                    element={this.getInspectorContent()}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderModificationView() {
        return (
            <ModificationOverview
                onUndo={() => this.props.undoGeometryChange()}
                onRedo={() => this.props.redoGeometryChange()}
                undoAvailable={this.props.undoAvailable}
                redoAvailable={this.props.redoAvailable}
            />)
    }
}

EditOutlinePanel.propTypes = {
    floorplanHighlighted: PropTypes.number.isRequired,
    terminateDrawingMode: PropTypes.func.isRequired,
}

let mapStateToProps = (state) => {
    return {
        appState: state.appState,

        selectedTool: state.drawing.selectedTool,

        currentStoreIndex: state.drawing.currentStoreIndex,
        geometries: getCurrentGeometryObjects(state),
        base64Image: state.drawing.image.base64Image,
        imageScale: state.drawing.image.scale,
        currentTab: state.drawing.currentDrawingTab,
        settings: state.drawing.settings,

        doorSize: state.drawing.markerSize.door,
        pillarSize: state.drawing.markerSize.pillar,

        undoAvailable: state.drawing.undoAvailable,
        redoAvailable: state.drawing.redoAvailable,

        storedGeometries: state.drawing.storedGeometries,

        getFloorpending: state.floors.getFloorpending,
        floorPlanImages: state.floors.floorPlanImages,
        floor: state.floors.floor,

        workplaceAssignments: state.workplace.assignments,
        profilePictures: state.occupancy.profilePicturesBase64,
        personId: state.user.person.id,

        creationObject: state.modalElementSkeleton.element,


    }
}

let mapDispatchToProps = {
    setImageScale: setImageScale,
    setCurrentDrawingTab: setCurrentDrawingTab,
    setSelectedElement: setSelectedElement,

    setSelectedDrawingTool: setSelectedDrawingTool,
    setMarkerSize: setMarkerSize,

    storeGeometryChanges: storeGeometryChanges,
    undoGeometryChange: undoGeometryChange,
    redoGeometryChange: redoGeometryChange,
    resetGeometries: resetGeometries,

    setFloorImage: setFloorImage,
    getFloorPlanImage: getFloorPlanImage,
    saveFloorGeometries: saveFloorGeometries,

    getFloor: getFloor,

    createWorkplaces: createWorkplaces,
    updateWorkplaces: updateWorkplaces,
    deleteWorkplaces: deleteWorkplaces,

    createTerminals: createTerminals,
    updateTerminals: updateTerminals,
    deleteTerminals: deleteTerminals,

    createSpaces: createSpaces,
    updateSpaces: updateSpaces,
    deleteSpaces: deleteSpaces,

    setElement: setElement,

    setError: setError,

    getProfilePictures: getProfilePictures,
    getCoworkersAssignmentsAtDate: getCoworkersAssignmentsAtDate,
}

export default withRouter(compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(EditOutlinePanel)))
