export function resetError() {
    return {
        type: 'RESET_ERROR',
        payload: true,
    }
}

export function setError(message) {
    return {
        type: 'SET_ERROR',
        payload: message,
    }
}