import {isEqual} from "lodash";

const initialState = {
    totalWorkplaceAssignments: 0,
    totalHomeofficeAssignments: 0,
    totalOutOfOfficeAssignments: 0,
    notSharedWorkplaceAssignments: 0,
    totalVacation: 0,
    totalWorkplaceHomezoneBuildingAssignments: 0,
    totalWorkplaceHomezoneFloorAssignments: 0,
    totalWorkplaces: null,
    totalWorkplacesHomezoneBuilding: 0,
    totalWorkplacesHomezoneFloor: 0,
    filteredCoworkerAssignments: [],
    filteredCoworkerAssignmentsPaginationToken: 0,
    getFilteredAssignmentsPending: false,
    profilePicturesBase64: [],
    getProfilePicturesPending: [],
}

function occupancyReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_TOTAL_WORKPLACES_FULFILLED':
            return {
                ...state,
                totalWorkplaces: action.payload.data
            }

        case 'GET_COWORKER_ASSIGNMENTS_AT_DATE_FILTERED_PENDING':

            return {
                ...state,
                getFilteredAssignmentsPending: true,
                filteredCoworkerAssignments: action.meta.resetPaginationToken ? [] : state.filteredCoworkerAssignments,
                filteredCoworkerAssignmentsPaginationToken: action.meta.resetPaginationToken ? 0 : state.filteredCoworkerAssignmentsPaginationToken,
            }

        case 'GET_COWORKER_ASSIGNMENTS_AT_DATE_FILTERED_FULFILLED':
            if (action.meta.resetPaginationToken === true || (action.payload.data.paginationToken !== null && action.payload.data.paginationToken <= 1)) {
                return {
                    ...state,
                    filteredCoworkerAssignments: action.payload.data.assignments,
                    filteredCoworkerAssignmentsPaginationToken: action.payload.data.paginationToken === null ? null : parseInt(action.payload.data.paginationToken),
                    getFilteredAssignmentsPending: false
                }
            }

            return {
                ...state,
                filteredCoworkerAssignments: [...state.filteredCoworkerAssignments, ...action.payload.data.assignments],
                filteredCoworkerAssignmentsPaginationToken: action.payload.data.paginationToken === null ? null : parseInt(action.payload.data.paginationToken),
                getFilteredAssignmentsPending: false
            }

        case 'GET_COWORKER_ASSIGNMENTS_AT_DATE_FILTERED_REJECTED':
            return {
                ...state,
                getFilteredAssignmentsPending: false
            }

        case 'GET_WORKPLACE_ASSIGNMENTS_STATISTICS_FULFILLED':
            if (!action.payload.data)
                return {...state}

            if (action.payload.data.floorId !== -1) {
                return {
                    ...state,
                    totalWorkplaceHomezoneFloorAssignments: action.payload.data.totalWorkplaceAssignments ?? 0,
                    totalWorkplacesHomezoneFloor: action.payload.data.totalWorkplaces ?? 0,
                }

            } else if (action.payload.data.buildingId !== -1) {
                return {
                    ...state,
                    totalWorkplaceHomezoneBuildingAssignments: action.payload.data.totalWorkplaceAssignments ?? 0,
                    totalWorkplacesHomezoneBuilding: action.payload.data.totalWorkplaces ?? 0,
                }
            } else {

                let data = action.payload.data

                return {
                    ...state,
                    totalWorkplaceAssignments: data.totalWorkplaceAssignments ? data.totalWorkplaceAssignments : 0,
                    totalHomeofficeAssignments: data.totalHomeofficeAssignments ? data.totalHomeofficeAssignments : 0,
                    totalOutOfOfficeAssignments: data.totalOutOfOfficeAssignments ? data.totalOutOfOfficeAssignments : 0,
                    notSharedWorkplaceAssignments: data.totalNotSharing ? data.totalNotSharing : 0,
                    totalVacation: data.totalVacation ? data.totalVacation : 0,
                    totalWorkplaces: data.totalWorkplaces ?? 0,
                }
            }

        case 'TERMINAL_GET_PROFILE_PICTURES_FULFILLED':
        case 'GET_PROFILE_PICTURES_FULFILLED':
            let profilePicturesBase64 = []
            for (const [key, value] of Object.entries(action.payload.data)) {
                profilePicturesBase64.push({
                    personId: key,
                    profilePicture: 'data:image/png;base64,' + value
                })
            }
            return {
                ...state,
                profilePicturesBase64: profilePicturesBase64
            }

        case 'GET_PROFILE_PICTURE_OF_COWORKER_PENDING': {

            let newPendingState = [...state.getProfilePicturesPending]
            if (newPendingState.indexOf(action.meta.personId) === -1)
                newPendingState.push(action.meta.personId)

            return {
                ...state,
                getProfilePicturesPending: newPendingState
            }
        }

        case 'GET_PROFILE_PICTURE_OF_COWORKER_FULFILLED': {

            let profilePicturesBase64 = state.profilePicturesBase64
            const personId = Object.keys(action.payload.data)[0]
            const image = action.payload.data[Object.keys(action.payload.data)[0]]
            let profilePictureIndex = state.profilePicturesBase64.findIndex(element => isEqual(personId.toString(), element.personId.toString()))

            if (profilePictureIndex > -1) {
                profilePicturesBase64.splice(profilePictureIndex, 1);
            }
            profilePicturesBase64.push({
                personId: personId,
                profilePicture: 'data:image/png;base64,' + image
            })

            //reset loading state
            let newPendingState = [...state.getProfilePicturesPending].filter(value => Number(value) !== Number(personId))

            return {
                ...state,
                profilePicturesBase64: profilePicturesBase64,
                getProfilePicturesPending: newPendingState,
            }
        }

        case 'UPLOAD_PROFILE_PICTURE_FULFILLED': {
            let profilePicturesBase64 = [...state.profilePicturesBase64]
            const personId = action.meta.userId
            const image = action.payload.data
            let profilePictureIndex = state.profilePicturesBase64.findIndex(element => isEqual(personId.toString(), element.personId.toString()))

            if (profilePictureIndex > -1) {
                profilePicturesBase64.splice(profilePictureIndex, 1);
            }

            profilePicturesBase64.push({
                personId: personId,
                profilePicture: 'data:image/png;base64,' + image
            })
            return {
                ...state,
                profilePicturesBase64: profilePicturesBase64
            }
        }

        case 'DELETE_PROFILE_PICTURE_FULFILLED': {
            let profilePicturesBase64 = [...state.profilePicturesBase64]
            const personId = action.meta.userId
            let profilePictureIndex = state.profilePicturesBase64.findIndex(element => isEqual(personId.toString(), element.personId.toString()))

            if (profilePictureIndex > -1) {
                profilePicturesBase64.splice(profilePictureIndex, 1);
            }
            return {
                ...state,
                profilePicturesBase64: profilePicturesBase64
            }
        }

        case 'UPDATE_USER_FULFILLED': {
            let profilePicturesBase64 = [...state.profilePicturesBase64]
            const personId = action.payload.data.id
            let profilePictureIndex = state.profilePicturesBase64.findIndex(element => isEqual(personId.toString(), element.personId.toString()))

            if (profilePictureIndex > -1) {
                profilePicturesBase64.splice(profilePictureIndex, 1);
            }
            return {
                ...state,
                profilePicturesBase64: profilePicturesBase64
            }
        }

        default:
            return state

    }
}

export default occupancyReducer
