import React from "react";
import * as PropTypes from "prop-types";

import clsx from "clsx";

import {withStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import SelectableListItem from "./SelectableListItem";

const styles = ({
    root: {
        flexGrow: 1,
        overflow: 'auto',
        width: '100%',
        height: '100%',
    },
});

function SelectionList({options, onSelect, selected, customListItem, classes, className}) {
    return (
        <div className={clsx(classes.root, className)}>
            {options.map(option => {
                return (
                    <div key={option.element.id}>
                        {customListItem
                            ? React.cloneElement(customListItem(option.element), {
                                disabled: option.disabled,
                                selected: selected && option.element.id === selected.id,
                                onSelect: () => {
                                    onSelect(option.element)
                                }
                            })
                            : <SelectableListItem option={option.element}
                                                  icon={option.icon}
                                                  disabled={option.disabled}
                                                  selected={selected && option.element.id === selected.id}
                                                  onSelect={() => {
                                                      onSelect(option.element)
                                                  }}/>}
                        <Divider/>
                    </div>
                )
            })}
        </div>
    )
}

SelectionList.propTypes = {
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.any,
    customListItem: PropTypes.func
}

export default withStyles(styles)(SelectionList);
