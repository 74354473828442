import * as React from "react"
import PropTypes from "prop-types";

function BrainstormingSvgIcon({color, width, height, strokeWidth, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={color}
                strokeWidth={strokeWidth}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path
                    d="M15.125 25H13a6 6 0 110-12h.74l.124-.77a6.214 6.214 0 0112.272 0l.123.77H27a6 6 0 110 12h-2.737"/>
                <path
                    d="M21.491 16.513l.003-.002.006.005V23.5h3.99l.005.007-6.989 8.983h0l-.005-.002L18.5 26.5l-3.994-.001h0v-.007z"/>
            </g>
        </svg>
    )
}

BrainstormingSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    strokeWidth: PropTypes.number,
};

BrainstormingSvgIcon.defaultProps = {
    color: '#333',
    width: 40,
    height: 40,
    strokeWidth: 1.5,
};


export default BrainstormingSvgIcon