import axios from 'axios'

export function getAllWorkingGroups() {
    return {
        type: 'GET_GROUPS',
        payload: axios.get('/api/workingGroup')
    }
}

export function getWorkingGroup(groupId) {
    return {
        type: 'GET_GROUP',
        payload: axios.get('/api/workingGroup/' + groupId)
    }
}

export function getWorkingGroupsFromUser(userId) {
    return {
        type: 'GET_GROUPS_OF_USER',
        payload: axios.get(`/api/person/${userId}/workingGroup`)
    }
}

export function createWorkingGroup(name, members, color) {
    let personsObjectArray = []

    for (let person of members) {
        personsObjectArray.push({
            id: person.id,
        })
    }

    let object = {
        name: name,
        color: color,
        members: personsObjectArray
    }

    return {
        type: 'CREATE_WORKING_GROUP',
        payload: axios.put(`/api/workingGroup`, object)
    }
}

export function addPersonToWorkingGroup(groupId, persons) {
    let personsObjectArray = []

    for (let person of persons) {
        personsObjectArray.push({
            id: person.id,
        })
    }

    return {
        type: 'ADD_PERSON_TO_GROUP',
        payload: axios.put(`/api/workingGroup/${groupId}/person`, personsObjectArray)
    }
}

export function removePersonFromWorkingGroup(groupId, personId) {
    return {
        type: 'REMOVE_PERSON_FROM_GROUP',
        payload: axios.delete(`/api/workingGroup/${groupId}/person`, {
            params: {
                personId: personId,
            }
        })
    }
}

export function selectWorkingGroup(group) {
    return {
        type: 'SELECT_GROUP',
        payload: group,
    }
}

export function fetchSharedWorkingGroups(personId) {
    return {
        type: 'FETCH_SHARED_WORKING_GROUPS',
        payload: axios.get(`/api/person/${personId}/shareStatus/groups`)
    }
}

export function changeShareStatusOfGroups(status, groupList) {
    return {
        type: 'CHANGE_SHARED_WORKING_GROUPS',
        addGroups: status,
        payload: groupList,
    }
}