import React from 'react';
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import PropTypes from "prop-types";
import CardWrapper from "../../../common/elements/CardWrapper.js";
import WorkAndBreakTime from "./WorkAndBreakTime.js";
import NavigationArrowSvgIcon from "../../../common/icons/NavigationArrowSvgIcon.js";

const useStyle = props => makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.innerGap
    },
    info: {
        display: 'flex',
        gap: theme.innerSmallGap
    }
}))

function TimeTrackingEntryCard({
                                   onClickCallback,
                                   timeTrackings,
                                   dayDiff,
                                   timeUnitNumber,
                                   selectedMoment,
                                   ...props
                               }) {
    const theme = useTheme()
    const classes = useStyle(props)(theme);

    function handleOnClick() {
        let index = timeUnitNumber
        let date
        //year
        if (dayDiff >= 364) {
            date = selectedMoment.clone().month(timeUnitNumber - 1)
            onClickCallback(date.startOf('month').toDate(), date.endOf('month').toDate(), date.startOf('month'))
            //month
        } else if (dayDiff >= 27) {
            date = selectedMoment.clone().week(timeUnitNumber)
            onClickCallback(date.startOf('week').toDate(), date.endOf('week').toDate(), date.startOf('week'))
            //week
        } else if (dayDiff >= 6) {
            date = selectedMoment.clone().weekday(index)
            onClickCallback(date.startOf('day').toDate(), date.endOf('day').toDate(), date.startOf('day'))
        }
    }

    return (
        <CardWrapper fullWidth onClick={handleOnClick}>
            <div className={classes.root}>
                <div className={classes.info}>
                    <WorkAndBreakTime timeTrackings={timeTrackings} align hideWorkIcon/>
                </div>
                <NavigationArrowSvgIcon/>
            </div>
        </CardWrapper>
    );
}

TimeTrackingEntryCard.propTypes = {
    timeTrackings: PropTypes.array.isRequired,
    onClickCallback: PropTypes.func.isRequired,
    dayDiff: PropTypes.number.isRequired,
    timeUnitNumber: PropTypes.number.isRequired,
    selectedMoment: PropTypes.any,
};

export default TimeTrackingEntryCard
