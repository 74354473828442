import React from 'react'
import {makeStyles, Switch} from '@material-ui/core'
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme.js";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
    switch: {
        color: theme.colors.palette.neutral.greyMain,
        '& .Mui-checked': {
            color: `${theme.colors.palette.corporate.skinMain} !important`,
        },
        '& .Mui-checked + .MuiSwitch-track': {
            backgroundColor: `${theme.colors.palette.corporate.skinMain} !important`,
        },
    },
}))

function CustomizedSwitch({onChange, checked, className, ...props}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return <Switch className={clsx(classes.switch, className)} checked={checked}
                   onChange={onChange}/>
}

CustomizedSwitch.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
}

export default CustomizedSwitch

