import axios from 'axios'

export function createTerminal(terminal) {
    return {
        type: 'TERMINAL_CREATE_TERMINAL',
        payload: axios.post(`/api/terminal?floorId=${terminal.homezone}`, terminal)
    }
}

export function createTerminals(terminalList) {
    return {
        type: 'TERMINAL_CREATE_TERMINALS',
        payload: axios.post('/api/terminal/list', terminalList)
    }
}

export function updateTerminals(terminalList) {
    return {
        type: 'TERMINAL_UPDATE_TERMINALS',
        payload: axios.patch('/api/terminal/list', terminalList)
    }
}

export function deleteTerminals(terminalList) {
    return {
        type: 'TERMINAL_DELETE_TERMINALS',
        payload: axios.delete('/api/terminal/list', {data: terminalList})
    }
}

export function getTerminal(terminalAccessCode) {
    return {
        type: 'TERMINAL_GET_TERMINAL',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}`)
    }
}

export function getTerminalAnnouncements(terminalAccessCode) {
    return {
        type: 'TERMINAL_GET_ANNOUNCEMENTS',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/announcements`)
    }
}

export function addTerminalAnnouncement(terminalAccessCode, announcement) {
    return {
        type: 'TERMINAL_ADD_ANNOUNCEMENT',
        payload: axios.post(`/api/terminal/${terminalAccessCode}/announcements`, {text: announcement})
    }
}

export function deleteTerminalAnnouncement(terminalAccessCode, announcementId) {
    return {
        type: 'TERMINAL_DELETE_ANNOUNCEMENT',
        payload: axios.delete(`/api/terminal/${terminalAccessCode}/announcements/${announcementId}`),
        meta: {
            id: announcementId
        }
    }
}

export function getTerminalStatistics(terminalAccessCode) {
    return {
        type: 'TERMINAL_GET_STATISTICS',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/homezoneStatistics`)
    }
}

export function getTerminalFloor(terminalAccessCode, floorId) {
    return {
        type: 'TERMINAL_GET_FLOOR',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/floor/${floorId}`)
    }
}

export function getTerminalFloorPlan(terminalAccessCode, floorId) {
    return {
        type: 'TERMINAL_GET_FLOOR_PLAN',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/floor/${floorId}/image`)
    }
}

export function getTerminalAssignments(terminalAccessCode, floorId, timePeriod) {
    return {
        type: 'TERMINAL_GET_FLOOR_ASSIGNMENTS',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/floor/${floorId}/assignments`, {
            params: {
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
            }
        })
    }
}

export function getTerminalPersonsWithAssignmentsFiltered(terminalAccessCode,
                                                          timePeriod,
                                                          searchString,
                                                          paginationToken,
                                                          resetPaginationToken,
                                                          loadOffice = true,
                                                          loadHomeoffice = true,
                                                          loadOutOfOffice = true,
                                                          loadVacation = true,
                                                          loadMeetings = true) {
    return {
        type: 'TERMINAL_GET_PERSONS_ASSIGNMENTS_FILTERED',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/personsWithAssignmentsAtDateFiltered`, {
            params: {
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
                searchString: searchString,
                pageToken: resetPaginationToken ? 0 : paginationToken,
                loadOffice: loadOffice,
                loadHomeoffice: loadHomeoffice,
                loadOutOfOffice: loadOutOfOffice,
                loadVacation: loadVacation,
                loadMeetings: loadMeetings,
            }
        }),
        meta: {
            resetPaginationToken: resetPaginationToken
        }
    }
}

export function getTerminalMeetingsFiltered(terminalAccessCode,
                                            timePeriod,
                                            searchString,
                                            paginationToken,
                                            resetPaginationToken) {
    return {
        type: 'TERMINAL_GET_MEETINGS_FILTERED',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/meetingsAtDateFiltered`, {
            params: {
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
                searchString: searchString,
                pageToken: resetPaginationToken ? 0 : paginationToken,
            }
        }),
        meta: {
            resetPaginationToken: resetPaginationToken
        }
    }
}

export function getTerminalProfilePictures(terminalAccessCode, floorId, timePeriod) {
    return {
        type: 'TERMINAL_GET_PROFILE_PICTURES',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/floor/${floorId}/pictures`, {
            params: {
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
            }
        })
    }
}

export function getTerminalBuildingNames(terminalAccessCode) {
    return {
        type: 'TERMINAL_GET_BUILDING_NAMES',
        payload: axios.get(`/api/terminal/code/${terminalAccessCode}/buildings`)
    }
}

export function setSelectedTerminal(terminal) {
    return {
        type: 'TERMINAL_SET_SELECTED',
        payload: terminal,
    }
}