import * as React from "react"
import PropTypes from 'prop-types'

function PhoneSvgIcon({color, width, height, strokeWidth, ...props}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={color}
                strokeWidth={strokeWidth}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path
                    d="M8.834 11.945c1.074-1.075 2.799-1.09 3.853-.036l5.728 5.728-1.945 1.944L20.29 23.4l1.944-1.945 5.728 5.728c1.054 1.054 1.039 2.78-.036 3.853l-1.944 1.945c-2.148 2.148-5.599 2.18-7.708.07L6.818 21.598c-2.108-2.11-2.077-5.56.071-7.708l1.945-1.944zM34.648 19.487c-.238-1.17-.593-2.3-1.052-3.377a17.98 17.98 0 00-4.015-5.838 18.563 18.563 0 00-6-3.906 18.682 18.682 0 00-3.47-1.023m8.661 15.315a11.964 11.964 0 00-3.287-6.143 12.015 12.015 0 00-3.892-2.605 11.99 11.99 0 00-2.251-.682"/>
            </g>
        </svg>
    )
}

PhoneSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    strokeWidth: PropTypes.number,
};

PhoneSvgIcon.defaultProps = {
    color: '#333',
    width: 40,
    height: 40,
    strokeWidth: 1.5,
};

export default PhoneSvgIcon