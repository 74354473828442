import * as React from "react"

function ListSvgIcon(props) {
    return (
        <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.5 14.795h14m-19 0h2m3 5h14m-19 0h2m3 5h14m-19 0h2"
                stroke={props.stroke || '#333'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
                {...props}
            />
        </svg>
    )
}

export default ListSvgIcon
