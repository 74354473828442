import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from "./store";
import * as Sentry from "@sentry/react";
import {createBrowserHistory} from 'history';

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";

const history = createBrowserHistory();

Sentry.init({
    enabled: process.env.NODE_ENV !== 'development' && !window.location.hostname.startsWith('staging'),
    dsn: "https://346ea94e79b34ee8ac81a0ebcb6e3820@o4505313129725952.ingest.sentry.io/4505313136214016",
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_VERSION,
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\//],
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App history={history}/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
    onUpdate: registration => store.dispatch({type: 'SERVICE_WORKER_UPDATE', payload: registration})
});