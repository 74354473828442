import React from 'react';
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import * as PropTypes from "prop-types";

const styles = theme => ({
    root: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 5px',
        border: 'solid ' + theme.colors.palette.neutral.white,
    },
    selected: {
        border: 'solid ' + theme.colors.palette.corporate.skinMain,
        borderRadius: '12px'
    },
    disabled: {
        color: theme.colors.palette.neutral.greyMain
    }
})

function SelectableListItem({option, icon, classes, onSelect, selected, disabled}) {
    return (
        <div className={`${classes.root} ${selected ? classes.selected : ''}`} onClick={() => onSelect(option)}>
            <Typography variant={'body1'} align={'left'}
                        className={disabled ? classes.disabled : ''}>{option.name}</Typography>
            {icon
                ? <div>{icon}</div>
                : <div/>}
        </div>
    );
}


SelectableListItem.propTypes = {
    option: PropTypes.any.isRequired,
    icon: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default compose(withStyles(styles), withTranslation())(SelectableListItem);