import * as React from "react"

function AttendanceStatusCreatorSvgIcon(props) {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <g strokeLinejoin="round" transform="translate(18 18)">
                    <rect
                        width={11.25}
                        height={11.25}
                        fill={props.stroke || '#58BCCC'}
                        stroke={props.stroke || '#58BCCC'}
                        strokeWidth={1.5}
                        rx={5.625}
                    />
                    <path
                        fill="#FFF"
                        stroke="#FFF"
                        d="M5.625 7.031L3.972 7.9l.316-1.84L2.95 4.756l1.848-.269.827-1.674.827 1.674 1.848.269L6.962 6.06l.316 1.84z"
                    />
                </g>
                <g stroke={props.stroke || '#58BCCC'} strokeWidth={1.5} transform="translate(4.875 4.5)">
                    <circle cx={4.5} cy={3} r={3}/>
                    <path d="M0 14.25v-3.38a2.62 2.62 0 012.62-2.62H4.5"/>
                    <circle cx={15.75} cy={3} r={3}/>
                    <path d="M15.75 8.25h1.875a2.625 2.625 0 012.625 2.625h0"/>
                    <circle cx={10.125} cy={9.375} r={3}/>
                    <path d="M5.625 20.625V17.25a2.625 2.625 0 012.625-2.625H12"/>
                </g>
            </g>
        </svg>
    )
}

export default AttendanceStatusCreatorSvgIcon
