import * as React from "react"

function GoogleSvgIcon(props) {
    return (
        <svg width={24} height={24} viewBox={'0 0 24 24'} {...props}>
            <g fillRule="nonzero" fill="none">
                <path
                    d="M24 12.27c0-.79-.072-1.54-.194-2.27H12.26v4.51h6.61c-.296 1.48-1.165 2.73-2.452 3.58v3h3.944C22.672 19 24 15.92 24 12.27Z"
                    fill="#4285F4"
                />
                <path
                    d="M12.26 24c3.31 0 6.08-1.08 8.103-2.91l-3.944-3c-1.104.72-2.503 1.16-4.158 1.16-3.198 0-5.906-2.11-6.877-4.96H1.318v3.09C3.331 21.3 7.468 24 12.261 24Z"
                    fill="#34A853"
                />
                <path
                    d="M5.384 14.29A6.83 6.83 0 0 1 4.996 12c0-.8.143-1.57.388-2.29V6.62H1.318A11.657 11.657 0 0 0 0 12c0 1.94.48 3.76 1.318 5.38l4.066-3.09Z"
                    fill="#FBBC05"
                />
                <path
                    d="M12.26 4.75c1.809 0 3.423.61 4.7 1.8l3.495-3.42C18.34 1.19 15.57 0 12.26 0 7.469 0 3.33 2.7 1.318 6.62l4.066 3.09c.971-2.85 3.679-4.96 6.877-4.96Z"
                    fill="#EA4335"
                />
            </g>
        </svg>
    )
}

export default GoogleSvgIcon
