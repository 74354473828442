import * as PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {usePersonWorkingHours} from "../customHooks/usePersonWorkingHours.js";
import moment from "moment";
import DaytimeSelection from "./DaytimeSelection.js";

function DaytimeSelectionWithUserWorkingHours({
                                                  date,
                                                  setTimePeriodCallback,
                                                  selectedCallback,
                                                  className,
                                                  disabledIntervals,
                                                  withCustomTimes,
                                                  errorCallback
                                              }) {
    let defaultTimeperiod = usePersonWorkingHours(date)

    const [startTime, setStartTime] = useState(moment(defaultTimeperiod.startDate))
    const [endTime, setEndTime] = useState(moment(defaultTimeperiod.endDate))
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        setSelected(selectedCallback(disabledIntervals, date, defaultTimeperiod))
        //eslint-disable-next-line
    }, [disabledIntervals])

    useEffect(() => {
        setStartTime(moment(defaultTimeperiod.startDate))
        setEndTime(moment(defaultTimeperiod.endDate))
        setSelected(selectedCallback(disabledIntervals, date, defaultTimeperiod))
        //eslint-disable-next-line
    }, [defaultTimeperiod])

    return (<DaytimeSelection setTimePeriodCallback={setTimePeriodCallback} date={date}
                              selected={selected}
                              disabledIntervals={disabledIntervals}
                              startTime={startTime} endTime={endTime}
                              withCustomTimes={withCustomTimes}
                              errorCallback={errorCallback}
                              className={className}/>
    )
}

DaytimeSelectionWithUserWorkingHours.propTypes = {
    setTimePeriodCallback: PropTypes.func.isRequired,
    date: PropTypes.any.isRequired,
    selectedCallback: PropTypes.func,
    className: PropTypes.string,
    disabledIntervals: PropTypes.array,
    withCustomTimes: PropTypes.bool,
    errorCallback: PropTypes.func,
}

DaytimeSelectionWithUserWorkingHours.defaultProps = {
    disabledIntervals: [],
    withCustomTimes: true
}

export default DaytimeSelectionWithUserWorkingHours
