import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
    root: {
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
}))

function RoundedIconWrapper({backgroundColor, icon, size, iconColor, onClickCallback}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={clsx(classes.root)}
             onClick={onClickCallback}
             style={{
                 background: backgroundColor ? backgroundColor : theme.colors.palette.neutral.greyMain,
                 width: size ? size + 'px' : '3.2rem',
                 height: size ? size + 'px' : '3.2rem'
             }}>
            {React.cloneElement(icon, {
                stroke: iconColor ? iconColor : theme.colors.palette.neutral.white,
                color: iconColor,
                width: size ? size * 2 / 3 : 24,
                height: size ? size * 2 / 3 : 24
            })}
        </div>
    )
}

RoundedIconWrapper.propTypes = {
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string,
    icon: PropTypes.element,
    size: PropTypes.number,
    onClickCallback: PropTypes.func
}

export default RoundedIconWrapper
