import * as React from "react"

function CheckboxIntermediateSvgIcon(props) {
    return (

        <svg width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M12 2C6.429 2 2 6.429 2 12s4.429 10 10 10 10-4.429 10-10S17.571 2 12 2Z"
                    fill="#000"
                />
                <path
                    stroke="#FFF"
                    strokeWidth={3}
                    strokeLinecap="round"
                    d="M5.5 12h13"
                />
            </g>
        </svg>
    )
}

export default CheckboxIntermediateSvgIcon
