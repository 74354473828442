import * as React from "react"

function ArrowForwardSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={props.stroke || 'black'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path stroke={props.stroke || 'black'} d="M30.5 20h-22M23.615 27.885l7-7.885-7-7.884"/>
            </g>
        </svg>
    )
}

export default ArrowForwardSvgIcon