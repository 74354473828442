import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

const styles = theme => ({
    root: {
        padding: '18px',
        position: 'absolute',
        bottom: '0px',
        background: theme.colors.palette.neutral.white,
        borderRadius: '18px 18px 0 0',
        left: 0,
        right: 0,
        margin: '0 auto',
        maxWidth: theme.gridSizes.maxWidth,
        boxShadow: '0 -4px 24px 0 rgb(51 51 51 / 11%)',
        overflowX: 'hidden',
        zIndex: 2,
        boxSizing: 'border-box',
    },
});

function BottomActionSheet({classes, content, ...props}) {
    return (
        <div className={classes.root}>
            {content}
        </div>
    );
}

BottomActionSheet.propTypes = {
    content: PropTypes.any.isRequired
};
BottomActionSheet.defaultPropTypes = {};
export default compose(withStyles(styles), withTranslation())(BottomActionSheet);