import React from 'react';
import {makeStyles, Typography, useTheme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import TrashIcon from "../../common/icons/TrashSvgIcon";
import CustomButton from "../CustomButton.js";
import ModalWrapper from "../../common/elements/ModalWrapper.js";
import VacationSvgIcon from "../../common/icons/VacationSvgIcon.js";

const useStyle = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem'
    },
    subContentGapContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
    svg: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        width: '80%'
    },
    homeOfficeTips: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
    headerIcon: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0px  !important',
        height: '24px !important',
        cursor: 'pointer',
        width: '24px !important',
        maxWidth: '24px !important',
    },
}))

function VacationInformationModal({
                                      onClose,
                                      onDelete,
                                      open,
                                      assignment
                                  }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    if (!open)
        return null

    return (
        <ModalWrapper open={open} onClose={onClose}
                      headerActions={<CustomButton textColor={theme.colors.palette.corporate.redMain}
                                                   icon={<TrashIcon/>}
                                                   onClick={() => {
                                                       onDelete()
                                                       onClose()
                                                   }}
                                                   customClassName={classes.headerIcon}/>}>

            <div className={classes.content}>
                <div className={classes.subContentGapContainer}>
                    <Typography variant={"h6"}>{t('booking_details')}</Typography>
                    <Typography variant={"h3"}>
                        {t('vacation')}
                    </Typography>
                    <div>
                        <Typography variant={"body1"}>
                            {
                                assignment ?
                                    moment(assignment.timePeriod.startDate).format(" dd, DD.MM.YYYY HH:mm")
                                    : null
                            }
                        </Typography>
                        <Typography variant={"body1"}>
                            {
                                assignment ?
                                    moment(assignment.timePeriod.endDate).format(" dd, DD.MM.YYYY HH:mm")
                                    : null
                            }
                        </Typography>
                    </div>
                </div>

                <Divider/>

                <VacationSvgIcon className={classes.svg} width={96} height={96}/>
            </div>
        </ModalWrapper>
    )
}

VacationInformationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.any.isRequired,
    assignment: PropTypes.any.isRequired,
    onDelete: PropTypes.func.isRequired,
}

export default VacationInformationModal
