export const WORKDURATION_FULL_DAY = 'full-day'

export const WORKDURATION_AFTERNOON = 'afternoon'

export const WORKDURATION_MORNING = 'morning'

//Types of workplace
export const TYPE_OF_WORKPLACE_SHARED = 'SHARED'
export const TYPE_OF_WORKPLACE_PRIVATE = 'PRIVATE'

export const TYPES_OF_WORKPLACE = [TYPE_OF_WORKPLACE_SHARED, TYPE_OF_WORKPLACE_PRIVATE]

//Types of workplace booking
export const TYPE_OF_BOOKING_RANDOM = 'RANDOM'
export const TYPE_OF_BOOKING_VACATION = 'VACATION'
export const TYPE_OF_BOOKING_RESOURCE = 'RESOURCE'
export const TYPE_OF_BOOKING_MEETING_ROOM = 'MEETING_ROOM'
export const TYPE_OF_BOOKING_WORKING_GROUP = 'WORKING_GROUP'
export const TYPE_OF_BOOKING_CHOOSE = 'CHOOSE'

export const TYPE_OF_BOOKING_HOMEOFFICE = 'HOMEOFFICE'
export const TYPE_OF_BOOKING_OUT_OF_OFFICE = 'OUT_OF_OFFICE'

export const TYPES_OF_REMOTE = [TYPE_OF_BOOKING_HOMEOFFICE, TYPE_OF_BOOKING_OUT_OF_OFFICE]

//Types of space usage
export const TYPE_OF_USE_MEETING = 'MEETING'

//Types of activities
export const TYPE_OF_ACTIVITY_HOMEOFFICE = "HOMEOFFICE"
export const TYPE_OF_ACTIVITY_OUT_OF_OFFICE = "OUT_OF_OFFICE"

export const TYPE_OF_ACTIVITY_CONCENTRATE = "CONCENTRATE"
export const TYPE_OF_ACTIVITY_CALLS = "CALLS"
export const TYPE_OF_ACTIVITY_CREATIVE = "CREATIVE"
export const TYPE_OF_ACTIVITY_TALK = "TALK"
export const TYPE_OF_ACTIVITY_UNDEFINED = "UNDEFINED"

export const TYPES_OF_ACTIVITIES = [TYPE_OF_ACTIVITY_CONCENTRATE, TYPE_OF_ACTIVITY_CALLS, TYPE_OF_ACTIVITY_CREATIVE, TYPE_OF_ACTIVITY_TALK, TYPE_OF_ACTIVITY_UNDEFINED]

export const WORKPLACE_BOOKED = "WORKPLACE_BOOKED"
export const WORKPLACE_BLOCKED = "WORKPLACE_BLOCKED"


//Types of activity attribute
export const ACTIVITY_ATTRIBUTE_VOLUME = "VOLUME"
export const ACTIVITY_ATTRIBUTE_PLACE = "PLACE"
export const ACTIVITY_ATTRIBUTE_HYGGE = "HYGGE"
export const ACTIVITY_ATTRIBUTE_FOCUS = "FOCUS"

//Types of meeting attendance status
export const ATTENDANCE_STATUS_INITIATOR = "INITIATOR"
export const ATTENDANCE_STATUS_CANCELED = "CANCELED"
export const ATTENDANCE_STATUS_ATTENDING_ONLINE = "ATTENDING_ONLINE"
export const ATTENDANCE_STATUS_ATTENDING_IN_PERSON = "ATTENDING_IN_PERSON"
export const ATTENDANCE_STATUS_UNKNOWN = "UNKNOWN"

//Types of meeting attendance status
export const ATTENDANCE_TYPE_ORGANISATION_INTERN = "ORGANISATION_INTERN"
export const ATTENDANCE_TYPE_EXTERN = "EXTERN"

//Types of workplace status
export const WORKPLACE_STATUS_ACTIVE_AUTO = "ACTIVE_AUTO"
export const WORKPLACE_STATUS_ACTIVE_MANUAL = "ACTIVE_MANUAL"
export const WORKPLACE_STATUS_INACTIVE_AUTO = "INACTIVE_AUTO"
export const WORKPLACE_STATUS_INACTIVE_MANUAL = "INACTIVE_MANUAL"

//Types of geometry
export const GEOMETRY_TYPE_LINE = "LINE"
export const GEOMETRY_TYPE_POLYLINE = "POLYLINE"
export const GEOMETRY_TYPE_POLYGON = "POLYGON"
export const GEOMETRY_TYPE_POINT = "POINT"
export const GEOMETRY_TYPE_RECT = 'RECT'

//Types of geometry
export const DRAWING_REPRESENTATION_TYPE_SCALE = "SCALE"
export const DRAWING_REPRESENTATION_TYPE_OUTLINE = "OUTLINE"
export const DRAWING_REPRESENTATION_TYPE_WALL = "WALL"
export const DRAWING_REPRESENTATION_TYPE_DOOR = "DOOR"
export const DRAWING_REPRESENTATION_TYPE_PILLAR = "PILLAR"
export const DRAWING_REPRESENTATION_TYPE_SPACE = "SPACE"
export const DRAWING_REPRESENTATION_TYPE_WORKPLACE = "WORKPLACE"
export const DRAWING_REPRESENTATION_TYPE_TERMINAL = "TERMINAL"

//Types of share status
export const SHARE_STATUS_PUBLIC = "PUBLIC"
export const SHARE_STATUS_WORKINGGROUPS = "WORKINGGROUPS"
export const SHARE_STATUS_PRIVATE = "PRIVATE"

//Days to select for working hours
export const DAY_MON = "MONDAY"
export const DAY_TUE = "TUESDAY"
export const DAY_WED = "WEDNESDAY"
export const DAY_THU = "THURSDAY"
export const DAY_FRI = "FRIDAY"
export const DAY_SAT = "SATURDAY"
export const DAY_SUN = "SUNDAY"

export const WEEK_DAYS = [DAY_MON, DAY_TUE, DAY_WED, DAY_THU, DAY_FRI, DAY_SAT, DAY_SUN]

//Types of Timer for resetting Status
export const STATUS_TIMER_DO_NOT_DELETE = "DO_NOT_DELETE"
export const STATUS_TIMER_30_MINUTES = "30_MINUTES"
export const STATUS_TIMER_1_HOUR = "1_HOUR"
export const STATUS_TIMER_4_HOURS = "4_HOUR"
export const STATUS_TIMER_TODAY = "TODAY"

//Types of Timer for resetting Status in seconds
export const STATUS_TIMER_30_MINUTES_SECONDS = 1800
export const STATUS_TIMER_1_HOUR_SECONDS = 3600
export const STATUS_TIMER_4_HOURS_SECONDS = 14400

//Types of availability Status
export const AVAILABILITY_STATUS_ONLINE = "ONLINE"
export const AVAILABILITY_STATUS_ABSENT = "ABSENT"
export const AVAILABILITY_STATUS_MEETING = "MEETING"
export const AVAILABILITY_STATUS_OFFLINE = "OFFLINE"
export const AVAILABILITY_STATUS_HOMEOFFICE = "HOMEOFFICE"
export const AVAILABILITY_STATUS_OUTOFOFFICE = "OUT_OF_OFFICE"

//Types of time clock state
export const ON_BREAK = "ON_BREAK"
export const CLOCKED_OUT = "CLOCKED_OUT"
export const CLOCKED_IN = "CLOCKED_IN"

//Events names to dispatch
export const STORAGE_OCCUPANCY_TIME = "STORAGE OCCUPANCY TIME"
export const LOCAL_STORAGE_TERMINAL_ACCESS_CODE = 'terminalAccessCode';
export const LOCAL_STORAGE_TERMINAL_ERROR_COUNTER = 'terminalLoadingError';

//Types of movability of fresources
export const RESOURCE_MOVABLE_NONE = "NONE"
export const RESOURCE_MOVABLE_LIMITED = "LIMITED"
export const RESOURCE_MOVABLE_MOVABLE = "MOVABLE"
