const initialState = {
    workingGroups: [],
    personGroups: [],
    selected: null,
    sharedWorkingGroups: [],
    createGroupPending: false,
    fetchUsersWorkingGroupsPending: false
}

function workingGroupsReducer(state = initialState, action) {
    let personGroups;
    let workingGroups;
    let group;
    let sharedGroups;

    switch (action.type) {

        case 'GET_GROUPS_FULFILLED':
            return {
                ...state,
                workingGroups: action.payload.data
            }
        case 'GET_GROUP_FULFILLED':
            return {
                ...state,
                selected: action.payload.data
            }

        case 'GET_GROUPS_OF_USER_PENDING':
            return {
                ...state,
                fetchUsersWorkingGroupsPending: true
            }

        case 'GET_GROUPS_OF_USER_REJECTED':
            return {
                ...state,
                fetchUsersWorkingGroupsPending: false
            }

        case 'GET_GROUPS_OF_USER_FULFILLED':
            return {
                ...state,
                personGroups: action.payload.data ? action.payload.data : [],
                fetchUsersWorkingGroupsPending: false
            }
        case 'SELECT_GROUP':
            return {
                ...state,
                selected: action.payload
            }
        case 'ADD_PERSON_TO_GROUP_FULFILLED': {
            personGroups = state.personGroups ? [...state.personGroups] : []
            group = action.payload.data

            let indexOfGroup = personGroups.findIndex(workingGroup => workingGroup.id === group.id)

            if (indexOfGroup >= 0) {
                personGroups[indexOfGroup] = group
            } else {
                personGroups.push(action.payload.data)
            }

            return {
                ...state,
                personGroups: personGroups,
                selected: group
            }
        }
        case 'REMOVE_PERSON_FROM_GROUP_FULFILLED':
            group = action.payload.data
            personGroups = state.personGroups ? [...state.personGroups] : []
            sharedGroups = [...state.sharedWorkingGroups]

            personGroups = personGroups.filter(personGroup => personGroup.id !== group.id)
            sharedGroups = sharedGroups.filter(shared => shared.id !== group.id)

            return {
                ...state,
                personGroups: personGroups,
                sharedWorkingGroups: sharedGroups,
                selected: group
            }

        case 'CREATE_WORKING_GROUP_PENDING':
            return {
                ...state,
                createGroupPending: true
            }

        case 'CREATE_WORKING_GROUP_REJECTED':
            return {
                ...state,
                createGroupPending: false
            }

        case 'CREATE_WORKING_GROUP_FULFILLED':
            workingGroups = [...state.personGroups]
            workingGroups.push(action.payload.data)


            return {
                ...state,
                personGroups: workingGroups,
                selected: action.payload.data,
                createGroupPending: false
            }

        case 'FETCH_SHARED_WORKING_GROUPS_FULFILLED':
            return {
                ...state,
                sharedWorkingGroups: action.payload.data,
            }

        case 'CHANGE_SHARED_WORKING_GROUPS':

            sharedGroups = [...state.sharedWorkingGroups]

            let updatedGroups

            if (action.addGroups) {
                updatedGroups = [...sharedGroups, ...action.payload]
            } else {
                updatedGroups = sharedGroups.filter(group => !action.payload.find(g => g.id === group.id))
            }

            return {
                ...state,
                sharedWorkingGroups: updatedGroups,
            }

        default:
            return state
    }

}

export default workingGroupsReducer