import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {makeStyles, Tab, Tabs} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import CustomTab from "./CustomTab.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap,
    },
    tabsFlexContainer: {
        gap: '2px',
    },
    indicator: {
        backgroundColor: theme.colors.palette.corporate.skinMain,
        top: 0,
    },
    tab: {
        minWidth: '30px',
        flex: 1,
        textTransform: 'none',
        color: theme.colors.palette.neutral.greyMain,
        backgroundColor: theme.colors.palette.neutral.greyMedium,
    },
    tabSelected: {
        textTransform: 'none',
        color: theme.colors.palette.neutral.black,
        backgroundColor: theme.colors.palette.neutral.white,
    }
}));

function CustomTabs({initialSelected, onChange, children, ...props}) {

    const theme = useTheme();
    const classes = useStyle(theme);

    const [selected, setSelected] = useState(initialSelected);

    if (!Array.isArray(children)) {
        children = [children]
    }
    
    const handleChange = (event, newValue) => {
        setSelected(newValue);
    };

    useEffect(() => {
        if (onChange && typeof onChange === 'function')
            onChange(selected)
    }, [selected, onChange]);


    return (
        <div className={classes.root}>
            <Tabs value={selected}
                  className={classes.tabList}
                  classes={{
                      indicator: classes.indicator,
                      flexContainer: classes.tabsFlexContainer,
                  }}
                  {...props}
                  onChange={handleChange}
            >
                {children && children.map((child, index) => <Tab key={index} value={index} wrapped
                                                                 className={classes.tab}
                                                                 icon={child.props.icon}
                                                                 label={child.props.label}
                                                                 disabled={child.props.disabled}
                                                                 classes={{
                                                                     selected: classes.tabSelected,
                                                                 }}/>
                )}
            </Tabs>
            {children && children.map((child, index) => (
                React.cloneElement(child, {...child.props, key: index, value: selected, index: index})
            ))}
        </div>
    );
}

CustomTabs.propTypes = {
    initialSelected: PropTypes.number,
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(CustomTab),
        CustomTab
    ])
};

CustomTabs.defaultProps = {
    initialSelected: 0,
};

export default CustomTabs;