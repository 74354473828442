import * as React from "react"
import PropTypes from 'prop-types'
import theme from "../../theme.js";

function StopSvgIcon({color, width, height, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            {...props}>
            <title>{"Icons/Other/Timetracking/Stop"}</title>
            <rect
                x={6}
                y={6}
                width={12}
                height={12}
                rx={1}
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}

StopSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

StopSvgIcon.defaultProps = {
    color: theme.colors.palette.corporate.skinMain,
    width: 24,
    height: 24
};

export default StopSvgIcon
