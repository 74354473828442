import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
    },
    description: {
        textAlign: 'left',
    },
    radio: {
        color: theme.colors.palette.neutral.greyMain,
        '&.Mui-checked': {
            color: theme.colors.palette.corporate.skinMain,
        }
    },
    reverse: {
        flexDirection: 'row-reverse'
    }
});

const RadioButtonWithDescription = ({title, value, selected, className, titleVariant, reverseOrder, ...props}) => {

    const {classes} = props;

    return (
        <div className={classes.root}>
            <div className={clsx(classes.header, className, reverseOrder ? classes.reverse : '')}>
                <Typography variant={titleVariant ?? 'h5'} style={{cursor: 'pointer'}}
                            onClick={() => props.onChange(value)}>
                    {title}
                </Typography>
                <Radio checked={selected}
                       onChange={() => props.onChange(value)}
                       name={title} color={'default'}
                       value={value} className={classes.radio}/>
            </div>
            <div className={classes.content}>
                <Typography variant={'caption'} className={classes.description}>
                    {props.description}
                </Typography>

                {selected ? props.children : null}
            </div>
        </div>
    );
};

RadioButtonWithDescription.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    titleVariant: PropTypes.string,
    reverseOrder: PropTypes.bool
};

RadioButtonWithDescription.defaultProps = {
    selected: false,
    reverseOrder: false
}

export default withStyles(styles)(RadioButtonWithDescription);
