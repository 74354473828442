import * as React from "react"

function RemoteSvgIcon(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fillRule="nonzero" stroke={props.stroke || '#333'} fill="none" {...props}>
                <path d="M20 20c0-8.837-7.163-16-16-16M14 20c0-5.523-4.477-10-10-10M8 20a4 4 0 0 0-4-4"/>
            </g>
        </svg>
    )
}

export default RemoteSvgIcon