export function sortByName(a, b) {
    if ((!a || !a.name) && (!b || !b.name)) {
        return 0;
    } else if (!a || !a.name) {
        return 1;
    } else if (!b || !b.name) {
        return -1;
    }

    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

export function sortPersonsByEmail(a, b) {
    if ((!a || !a.email) && (!b || !b.email)) {
        return 0;
    } else if (!a || !a.email) {
        return 1;
    } else if (!b || !b.email) {
        return -1;
    }

    if (a.email.toLowerCase() < b.email.toLowerCase()) {
        return -1;
    }
    if (a.email > b.email) {
        return 1;
    }
    return 0;
}

export function sortPersonsAndWorkingGroups(a, b) {

    const nameA = a.name
        ? a.name.toLowerCase()
        : a.email
            ? a.email.toLowerCase()
            : '';
    const nameB = b.name
        ? b.name.toLowerCase()
        : b.email
            ? b.email.toLowerCase()
            : ''

    return nameA.localeCompare(nameB);
}
