import React, {useEffect} from 'react';
import {makeStyles, Typography, useTheme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import {withTranslation} from "react-i18next";
import ToggleButton from "../ToggleButton";
import CustomCheckbox from "../CustomCheckbox";
import ActivateNotificationDialog from "./ActivateNotificationDialog";
import {
    deleteSubscription,
    getNotificationGroups,
    sendNotification,
    setNotificationGroups
} from "../../actions/user-action";
import {connect} from "react-redux";
import CustomButton from "../CustomButton.js";
import {
    NOTIFICATION_MEETING_CHANGE,
    NOTIFICATION_MEETING_INVITATION,
    NOTIFICATION_MEETING_START,
    NOTIFICATION_WORKING_GROUP_INVITATION
} from "../../common/utils/NotificationUtils.js";
import {useHistory} from "react-router-dom";

const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.paddingContentContainer.padding,
    },
    containerBox: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
        gap: theme.outerGap
    },
    greyText: {
        textAlign: 'left',
    },
    checkboxWithLabel: {
        width: '100%',
        paddingRight: '9px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    section: {
        textAlign: 'left',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap
    }
}));

function Notification({
                          t,
                          personId,
                          getNotificationGroups,
                          setNotificationGroups,
                          notificationGroups,
                          onCloseCallback,
                          ...props
                      }) {

    const theme = useTheme()
    const classes = useStyle(theme);
    const history = useHistory()

    const [notificationsEnabled, setNotificationsEnabled] = React.useState(notificationGroups.length > 0)
    const [showActivateNotificationsDialog, setShowActivateNotificationsDialog] = React.useState(false);
    const [meetingInvitation, setMeetingInvitation] = React.useState(false);
    const [meetingChange, setMeetingChange] = React.useState(false);
    const [workingGroupInvitation, setWorkingGroupInvitation] = React.useState(false);
    const [meetingStart, setMeetingStart] = React.useState(false);

    useEffect(() => {
        if (personId) {
            getNotificationGroups(personId)
        }
    }, [personId, getNotificationGroups])

    useEffect(() => {
        if (notificationGroups.length)
            setNotificationsEnabled(true)

        if (notificationGroups.includes(NOTIFICATION_MEETING_INVITATION))
            setMeetingInvitation(true)

        if (notificationGroups.includes(NOTIFICATION_MEETING_CHANGE))
            setMeetingChange(true)

        if (notificationGroups.includes(NOTIFICATION_WORKING_GROUP_INVITATION))
            setWorkingGroupInvitation(true)
        if (notificationGroups.includes(NOTIFICATION_MEETING_START))
            setMeetingStart(true)


    }, [notificationGroups])


    const handleClose = () => {
        setShowActivateNotificationsDialog(false);
    };

    const toggleNotification = () => {
        if (!notificationsEnabled) {
            //subscribe user
            setNotificationsEnabled(true)
            setShowActivateNotificationsDialog(true);
        } else {
            //unsubscribe user
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration().then(registration => {
                    registration.pushManager.getSubscription().then(function (subscription) {
                        if (subscription) {
                            return subscription.unsubscribe()
                        }
                    }).catch(function (error) {
                        console.log("Error unsubscribing the user: " + error)
                    }).then(function () {
                        deleteSubscription(personId)
                        setNotificationsEnabled(false)
                    })
                })
            } else {
                return null
            }
        }
    }

    const handleMeetingInvitationClick = () => {
        setMeetingInvitation(!meetingInvitation)
        updateNotificationGroups(NOTIFICATION_MEETING_INVITATION)
    }

    const handleMeetingChangeClick = () => {
        setMeetingChange(!meetingChange)
        updateNotificationGroups(NOTIFICATION_MEETING_CHANGE)
    }

    const handleWorkingGroupInvitationClick = () => {
        setWorkingGroupInvitation(!workingGroupInvitation)
        updateNotificationGroups(NOTIFICATION_WORKING_GROUP_INVITATION)
    }

    const handleMeetingStartClick = () => {
        setMeetingStart(!meetingStart)
        updateNotificationGroups(NOTIFICATION_MEETING_START)
    }

    function updateNotificationGroups(group) {
        let newGroups = [...notificationGroups]

        if (newGroups.includes(group)) {
            newGroups = newGroups.filter(g => g !== group)
        } else {
            newGroups.push(group)
        }
        if (!newGroups.length)
            toggleNotification()
        else
            setNotificationGroups(personId, newGroups)
    }

    const handleClick = () => {
        sendNotification(personId)
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item sm={12} className={classes.containerBox}>
                <HeaderWithTitleAndBackButton title={t("notifications")}
                                              backLabel={t("settings")}
                                              onNavigateBack={() => history.goBack()}/>

                <div className={classes.content}>
                    <Typography variant={"h6"} className={classes.greyText}>
                        {t("dont_miss_anything")}
                    </Typography>
                    <div className={classes.section}>
                        <ToggleButton title={t("push_notification")} value={notificationsEnabled}
                                      onChange={toggleNotification}/>
                        {notificationsEnabled === false &&
                            <Typography variant={"caption"}>
                                {t("manage_notifications")}
                            </Typography>
                        }
                    </div>

                    <div className={classes.section}>
                        <Typography variant={"h5"} style={{opacity: notificationsEnabled ? '1' : '0.5'}}>
                            {t("meetings")}
                        </Typography>
                        <div className={classes.checkboxWithLabel}>
                            <Typography style={{opacity: notificationsEnabled ? '1' : '0.5'}}>
                                {t("meeting_invitation_notification")}
                            </Typography>
                            <CustomCheckbox selected={meetingInvitation} disabled={!notificationsEnabled}
                                            onClick={handleMeetingInvitationClick}/>
                        </div>
                        <div className={classes.checkboxWithLabel}>
                            <Typography align={'left'} style={{opacity: notificationsEnabled ? '1' : '0.5'}}>
                                {t("meeting_updates")}
                            </Typography>
                            <CustomCheckbox selected={meetingChange} disabled={!notificationsEnabled}
                                            onClick={handleMeetingChangeClick}/>
                        </div>

                        <div className={classes.checkboxWithLabel}>
                            <Typography align={'left'} style={{opacity: notificationsEnabled ? '1' : '0.5'}}>
                                {t("meeting_start_notification")}
                            </Typography>
                            <CustomCheckbox selected={meetingStart} disabled={!notificationsEnabled}
                                            onClick={handleMeetingStartClick}/>
                        </div>
                    </div>


                    <div className={classes.section}>
                        <Typography variant={'h5'}
                                    style={{opacity: notificationsEnabled ? '1' : '0.5'}}>
                            {t("working_groups")}
                        </Typography>
                        <div className={classes.checkboxWithLabel}>
                            <Typography style={{opacity: notificationsEnabled ? '1' : '0.5'}}>
                                {t("workinggroup_invitation_notification")}</Typography>
                            <CustomCheckbox selected={workingGroupInvitation} disabled={!notificationsEnabled}
                                            onClick={handleWorkingGroupInvitationClick}/>
                        </div>
                    </div>
                </div>
            </Grid>

            <Grid item style={{width: '100%'}}>
                <CustomButton text={'Send test notification'} primary fullWidth
                              disabled={!notificationsEnabled} onClick={handleClick}
                />
            </Grid>

            <ActivateNotificationDialog open={showActivateNotificationsDialog}
                                        handleClose={handleClose}
                                        onDecline={() => setNotificationsEnabled(false)}/>

        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        personId: state.user.person.id,
        notificationGroups: state.user.notificationGroups
    }
}

const mapDispatchToProps = {
    getNotificationGroups: getNotificationGroups,
    setNotificationGroups: setNotificationGroups,
}


Notification.propTypes = {};
Notification.defaultPropTypes = {};
export default (withTranslation())(connect(mapStateToProps, mapDispatchToProps)(Notification));