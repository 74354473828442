import * as THREE from 'three'
import {
    createHexagon,
    drawingOpacity,
    drawingSegments,
    drawingSize,
    drawingTypes,
    getCircleGeometry,
    getDrawingTypesMaterial,
    threeMaterial,
} from "./drawConstants";
import {DRAWING_REPRESENTATION_TYPE_TERMINAL, GEOMETRY_TYPE_POINT} from "../../../common/utils/NameUtils";

import terminalImage from "../../../common/img/Terminal.png";
import * as _ from "lodash";

function DrawTerminal() {
    this.loader = new THREE.TextureLoader();
    this.toBeDeleted = false
    this.updated = false

    THREE.Mesh.apply(this, arguments)

    this.init = function (position) {
        if (!this.geometryType)
            this.geometryType = GEOMETRY_TYPE_POINT
        if (!this.drawingRepresentationType)
            this.drawingRepresentationType = DRAWING_REPRESENTATION_TYPE_TERMINAL

        this.reInit(position)
    }

    this.reInit = function (position) {
        let material = new THREE.MeshBasicMaterial({map: this.loader.load(terminalImage)}).clone()
        let hexMesh = createHexagon(drawingSize.terminal, drawingSegments.terminal, material)
        hexMesh.name = 'hexMesh'
        const wrapperGeometry = new THREE.CircleGeometry(drawingSize.workplaceCatchplane, drawingSegments.marker)
        let transparentMaterial = _.cloneDeep(getDrawingTypesMaterial(drawingTypes.terminal))
        transparentMaterial.opacity = drawingOpacity.transparent
        this.wrapperMesh = new THREE.Mesh(wrapperGeometry, transparentMaterial)
        this.wrapperMesh.name = 'wrapperMesh'
        this.wrapperMesh.add(hexMesh)

        let activeMarker = getCircleGeometry(drawingSize.terminal / 4)
        let activeMarkerMesh = new THREE.Mesh(activeMarker, material)
        activeMarkerMesh.name = 'activeMarker'
        activeMarkerMesh.position.set(0, 0, -0.01)
        this.wrapperMesh.add(activeMarkerMesh)
        this.wrapperMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))

        this.wrapperMesh.position.set(0, 0.05, 0)

        this.position.set(position.x, 0.05, position.z)

        this.add(this.wrapperMesh)
    }

    this.updatePosition = function (position) {
        this.position.set(position.x, 0.05, position.z)
        this.updated = true
    }

    this.setActive = function (active) {
        if (this.wrapperMesh && !this.active === active) {
            this.active = active

            let indexWorkplaceMarkerMesh = this.wrapperMesh.children.findIndex(mesh => mesh.name === 'hexMesh')
            const activeMovementY = 1.5 * drawingSize.terminal

            if (active) {
                if (indexWorkplaceMarkerMesh > -1)
                    this.wrapperMesh.children[indexWorkplaceMarkerMesh].position.setY(this.wrapperMesh.children[indexWorkplaceMarkerMesh].position.y + activeMovementY)
            } else {
                if (indexWorkplaceMarkerMesh > -1)
                    this.wrapperMesh.children[indexWorkplaceMarkerMesh].position.setY(this.wrapperMesh.children[indexWorkplaceMarkerMesh].position.y - activeMovementY)
            }
        }
    }

    this.setInvisible = function () {
        this.wrapperMesh.children.forEach(vertex => vertex.material = threeMaterial.transparent)
    }

    this.getGeometryAsJSON = function () {
        return {
            geometryId: this.geometryId,
            vertices: [{
                x: this.position.x,
                y: this.position.z
            }],
            size: this.size,
            terminal: this.terminal,
            geometryType: this.geometryType,
            updated: this.updated,
            toBeDeleted: this.toBeDeleted,
            drawingRepresentationType: this.drawingRepresentationType,
        }
    }

    this.setFromTerminal = function (json) {
        this.updated = json.updated ? json.updated : false
        this.toBeDeleted = json.toBeDeleted
        this.geometryId = json.geometry && json.geometry.geometryId ? json.geometry.geometryId : json.geometryId
        this.geometryType = json.geometryType ? json.geometryType : GEOMETRY_TYPE_POINT
        this.terminal = json.terminal ? json.terminal : json
        this.vertices = json.vertices
        this.drawingRepresentationType = json.drawingRepresentationType ? json.drawingRepresentationType : DRAWING_REPRESENTATION_TYPE_TERMINAL

        let vertex = this.terminal.vertices ? this.terminal.vertices[0] : this.terminal.geometry.vertices[0]

        this.reInit({x: vertex.x, y: 0.01, z: vertex.y},)
    }
}

DrawTerminal.prototype = Object.create(THREE.Mesh.prototype)
DrawTerminal.prototype.constructor = DrawTerminal
export {DrawTerminal}