import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {useSelector} from "react-redux";


const useStyle = props => makeStyles(theme => ({
    root: {
        position: 'sticky',
        bottom: 0,
        width: '100%',
        height: theme.staticBottomActionSheet.height,
        padding: '1.8rem',
        boxSizing: 'border-box',
        borderRadius: `${props.radius}px ${props.radius}px 0 0`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 150,
    },
}));

function StaticBottomSheet({children, ...props}) {

    const theme = useTheme()
    const classes = useStyle(props)(theme);
    const showShadow = useSelector(state => !state.uiState.scrollPositionBottom)

    return (
        <Grid component={Paper} className={classes.root} style={
            {boxShadow: showShadow ? `0 -4px 24px 0 ${theme.colors.palette.neutral.darkMain}33` : 'none'}}
        >
            {children}
        </Grid>
    );
}

StaticBottomSheet.propTypes = {
    radius: PropTypes.number,
    showShadow: PropTypes.bool,
};

StaticBottomSheet.defaultProps = {
    radius: 18,
    showShadow: true,
};

export default StaticBottomSheet;