import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useTranslation} from "react-i18next";
import CrossCircledSvgIcon from "../../common/icons/CrossCircledSvgIcon.js";
import CheckCircledSvgIcon from "../../common/icons/CheckCircledSvgIcon.js";
import {digitRegex, lowercaseRegex, uppercaseRegex} from "../../common/utils/PasswordAndEmailUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    requirement: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        width: '2.4rem',
        height: '2.4rem',
    }
}))

function PasswordRequirements({password, passwordConfirmation}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);

    function isValid(validationRegex) {
        return password.match(validationRegex)
    }

    return (
        <div className={classes.root}>
            <div className={classes.requirement}>
                {password && password.length >= 8
                    ? <CheckCircledSvgIcon className={classes.icon}/>
                    : <CrossCircledSvgIcon className={classes.icon}/>}
                <Typography variant={'caption'}>
                    {t('password_length')}
                </Typography>
            </div>

            <div className={classes.requirement}>
                {password && isValid(uppercaseRegex)
                    ? <CheckCircledSvgIcon className={classes.icon}/>
                    : <CrossCircledSvgIcon className={classes.icon}/>}
                <Typography variant={'caption'}>
                    {t('password_uppercase')}
                </Typography>
            </div>

            <div className={classes.requirement}>
                {password && isValid(lowercaseRegex)
                    ? <CheckCircledSvgIcon className={classes.icon}/>
                    : <CrossCircledSvgIcon className={classes.icon}/>}
                <Typography variant={'caption'}>
                    {t('password_lowercase')}
                </Typography>
            </div>

            <div className={classes.requirement}>
                {password && isValid(digitRegex)
                    ? <CheckCircledSvgIcon className={classes.icon}/>
                    : <CrossCircledSvgIcon className={classes.icon}/>}
                <Typography variant={'caption'}>
                    {t('password_digit')}
                </Typography>
            </div>

            <div className={classes.requirement}>
                {password !== '' && password === passwordConfirmation
                    ? <CheckCircledSvgIcon className={classes.icon}/>
                    : <CrossCircledSvgIcon className={classes.icon}/>}
                <Typography variant={'caption'}>
                    {t('password_similar')}
                </Typography>
            </div>
        </div>
    )
}

export default PasswordRequirements