import React from 'react';
import {Modal, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ReactMarkdown from "react-markdown";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.palette.neutral.white,
        borderRadius: '18px',
        padding: '28px 5px 20px 5px',
        maxWidth: '800px',
        width: '80%',
        height: '80%',
        outline: 'none',

    },
    scrollContainer: {
        overflow: 'auto',
        margin: '5px 0px',
        padding: '0px 20px',
    },
    caption: {
        marginBottom: theme.spacing(3),
    },
    markdown: {
        color: 'black',
        fontSize: '15px'
    },
    footer: {
        alignSelf: 'flex-end',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        marginRight: '10px',
    }
}));


function UpdateModal(props) {

    const classes = useStyles()
    const [markdown, setMarkdown] = React.useState()
    const modalOpen = useSelector(state => state.serviceWorker.serviceWorkerUpdated)
    const serviceWorkerRegistration = useSelector(state => state.serviceWorker.serviceWorkerRegistration)
    const dispatch = useDispatch()

    React.useEffect(() => {
        axios('/updateLog.md')
            .then((r) => r.data)
            .then((text) => {
                setMarkdown(text)
            })
    }, [])

    const activateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            //Send message to service worker that it should skip waiting and enter state activated
            registrationWaiting.postMessage({type: 'SKIP_WAITING'});
        }
    }

    const activateUpdate = () => {
        activateServiceWorker()
        window.location.reload()
    }

    const closeModal = () => {
        activateServiceWorker()
        dispatch({type: 'RESET_SERVICE_WORKER_UPDATE'})
    }

    return (
        <Modal className={classes.root} open={modalOpen} disableAutoFocus={true}>
            <div className={classes.paper}>
                <div className={classes.scrollContainer}>
                    <Typography variant={'body1'}>
                        Update
                    </Typography>

                    <Typography variant={'h3'} className={classes.caption}>
                        {'New in goconut ' + process.env.REACT_APP_VERSION}
                    </Typography>

                    <ReactMarkdown children={markdown} className={classes.markdown}/>

                </div>
                <div className={classes.footer}>
                    <Button className={classes.submitButton} onClick={activateUpdate}>{'Reload'}</Button>
                    <Button className={classes.cancelButton} onClick={closeModal}>{'Cancel'}</Button>
                </div>
            </div>
        </Modal>
    );
}

export default UpdateModal;