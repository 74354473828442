import * as React from "react"

function AttendanceStatusOnlineSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <defs>
                <circle id="prefix__a" cx={5.625} cy={5.625} r={5.625}/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(18 18)">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a"/>
                    </mask>
                    <use
                        stroke={props.stroke || '#60BF82'}
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        xlinkHref="#prefix__a"
                    />
                    <circle
                        cx={-2.25}
                        cy={5.625}
                        r={10.125}
                        stroke={props.stroke || '#60BF82'}
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        mask="url(#prefix__b)"
                    />
                    <circle
                        cx={13.5}
                        cy={5.625}
                        r={10.125}
                        stroke={props.stroke || '#60BF82'}
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        mask="url(#prefix__b)"
                    />
                    <circle
                        cx={5.625}
                        cy={-6}
                        r={10.125}
                        stroke={props.stroke || '#60BF82'}
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        mask="url(#prefix__b)"
                    />
                    <circle
                        cx={5.625}
                        cy={17.25}
                        r={10.125}
                        stroke={props.stroke || '#60BF82'}
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        mask="url(#prefix__b)"
                    />
                </g>
                <g stroke={props.stroke || '#58BCCC'} strokeWidth={1.5} transform="translate(4.875 4.5)">
                    <circle cx={4.5} cy={3} r={3}/>
                    <path d="M0 14.25v-3.38a2.62 2.62 0 012.62-2.62H4.5"/>
                    <circle cx={15.75} cy={3} r={3}/>
                    <path d="M15.75 8.25h1.875a2.625 2.625 0 012.625 2.625h0"/>
                    <circle cx={10.125} cy={9.375} r={3}/>
                    <path d="M5.625 20.625V17.25a2.625 2.625 0 012.625-2.625H12"/>
                </g>
            </g>
        </svg>
    )
}

export default AttendanceStatusOnlineSvgIcon
