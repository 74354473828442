import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import FloorplanWithWorkplacesComponent from "../floorplan/FloorplanWithWorkplaces.js";
import {connect} from "react-redux";
import {getFloor} from "../../actions/floors-actions.js";

const useStyle = makeStyles(theme => ({
    centerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        minHeight: '420px',
        maxHeight: '650px',
        [theme.breakpoints.down('lg')]: {
            maxHeight: '420px',
        },
    },
}));

function SeatModalContentAssignment({assignment, loadFloor, floor}) {

    const theme = useTheme();
    const classes = useStyle(theme);

    useEffect(() => {
        loadFloor(-1, assignment.workplace.floorId)
        //eslint-disable-next-line
    }, [])

    return (
        <div className={classes.centerContent}>
            {floor && floor.id === assignment.workplace.floorId &&
                <FloorplanWithWorkplacesComponent
                    assignments={[assignment]}
                    centerInitially
                    disableSelection
                    showZoomButtons/>}
        </div>
    );
}

SeatModalContentAssignment.propTypes = {
    assignment: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        floor: state.floors.floor,
    }
}

const mapDispatchToProps = {
    loadFloor: getFloor,
}

export default connect(mapStateToProps, mapDispatchToProps)(SeatModalContentAssignment)