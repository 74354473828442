import React from 'react';

import {isObject, isString} from "lodash";
import {QrReader} from 'react-qr-reader';

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import {navigate} from "../../common/utils/NavigationUtils.js";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";


const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    scanOverlay: {
        zIndex: 1,
        top: '76px',
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column'
    },
    scanSquare: {
        boxSizing: 'border-box',
        border: '13px solid ' + theme.colors.palette.corporate.skinMain,
        borderRadius: '36px',
        margin: 'auto',
        width: '80vw',
        height: '80vw',
    },
    bottomSheet: {
        border: '1px solid ' + theme.colors.palette.neutral.greyNeutral + '40',
        borderBottom: 'none',
        borderRadius: '36px 36px 0 0',
        background: theme.colors.palette.neutral.white + '40',
        width: '100%',
        height: '200px',
        backdropFilter: 'blur(12px)',
        boxSizing: 'border-box',
        padding: theme.paddingContentContainer.padding,
        display: 'flex'
    },
}));

function QrCodeReader() {
    const theme = useTheme();
    const history = useHistory();
    const {t} = useTranslation();
    const classes = useStyle(theme);

    const handleResult = (data) => {
        if (data && isObject(data) && data.text) {
            let path = getRelativePath(data.text)

            if (!path)
                return
            navigate(history, path)
        }
    }

    const getRelativePath = (url) => {

        if (!url || !isString(url)) {
            return null
        }

        if (url.includes('goconut.app')) {
            return url.split('.app')[1]
        }

        if (url.includes('localhost:3000')) {
            return url.split('3000')[1]
        }

        console.log('No goconut QR code, so it will be ignored')
        return null
    }

    function getOverlay() {
        return (<div className={classes.scanOverlay}>
            <div className={classes.scanSquare}></div>
            <div className={classes.bottomSheet}>
                <Typography variant={'body1'} style={{textAlign: 'center', alignSelf: 'center'}}>
                    {t('scan.qr.book')}
                </Typography>
            </div>
        </div>)
    }

    return (
        <div className={classes.root}>
            <QrReader onResult={handleResult}
                      videoStyle={{width: 'auto', height: '100vh'}}
                      videoContainerStyle={{width: '100%', height: '100%', position: 'static'}}
                      constraints={{facingMode: 'environment'}}
                      ViewFinder={() => getOverlay()}
            />
        </div>
    );
}

QrCodeReader.propTypes = {};

QrCodeReader.defaultProps = {};

export default QrCodeReader;