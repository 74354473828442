import * as React from "react"

function TrophySvgIcon(props) {
    return (
        <svg width={24} height={24} viewBox={'0 0 24 24'} {...props}>
            <title>{"Icons/Main/Trophy"}</title>
            <g {...props} fillRule="nonzero" stroke="#333" fill="none" strokeLinejoin="round">
                <path
                    d="M8.5 22a.5.5 0 0 1-.5-.5v-1A1.5 1.5 0 0 1 9.5 19h1v-3c-3-2.426-4.5-4.426-4.5-6V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6c0 1.574-1.5 3.574-4.5 6v3h1a1.5 1.5 0 0 1 1.5 1.5v1a.5.5 0 0 1-.5.5Z"/>
                <path
                    d="M17.884 5.25H19a2 2 0 0 1 2 2v.996a3.845 3.845 0 0 1-2.905 3.728L17 12.25M6.116 5.25H5a2 2 0 0 0-2 2v.996a3.845 3.845 0 0 0 2.905 3.728L7 12.25"/>
            </g>
        </svg>
    )
}

export default TrophySvgIcon
