import * as React from "react"
import PropTypes from "prop-types";

function ResourceSvgIcon({color, size, ...props}) {
    return (
        <svg width={size}
             height={size}
             fill="none"
             viewBox="0 0 24 24"
             {...props}>
            <g stroke={color}>
                <rect width={15} height={13} x={4.5} y={7.5} rx={0.5}/>
                <rect width={17} height={4} x={3.5} y={3.5} rx={0.5}/>
                <path d="M8.5 10.5h7v2h-7z"/>
            </g>
        </svg>
    )
}

ResourceSvgIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

ResourceSvgIcon.defaultProps = {
    color: '#333',
    size: 30,
};


export default ResourceSvgIcon
