import * as React from "react"

const TimeTrackingEmptyStateSvgIcon = (props) => (
    <svg width={340} height={215} viewBox={'0 0 330 215'} xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <clipPath id="b_timeTracking">
                <path
                    d="M276.138 0A7.862 7.862 0 0 1 284 7.862v32.276A7.862 7.862 0 0 1 276.138 48H7.862A7.862 7.862 0 0 1 0 40.138V7.862A7.862 7.862 0 0 1 7.862 0h268.276Z"/>
            </clipPath>
            <clipPath id="c_timeTracking">
                <path
                    d="M85.037 0A3.963 3.963 0 0 1 89 3.963v8.074A3.963 3.963 0 0 1 85.037 16H3.963A3.963 3.963 0 0 1 0 12.037V3.963A3.963 3.963 0 0 1 3.963 0h81.074Z"/>
            </clipPath>
            <clipPath id="d_timeTracking">
                <path
                    d="M27.358 0A2.642 2.642 0 0 1 30 2.642v5.716A2.642 2.642 0 0 1 27.358 11H2.642A2.642 2.642 0 0 1 0 8.358V2.642A2.642 2.642 0 0 1 2.642 0h24.716Z"/>
            </clipPath>
            <clipPath id="e_timeTracking">
                <path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Z"/>
            </clipPath>
            <filter
                id="a_timeTracking"
                x="-19.136%"
                y="-68.182%"
                filterUnits="userSpaceOnUse"
                width="125.926%"
                height="195.455%"
            >
                <feDropShadow stdDeviation={10} dx={0} dy={2} floodColor="#D9D9D8"/>
            </filter>
            <filter
                id="f_timeTracking"
                x="-19.136%"
                y="-68.182%"
                filterUnits="userSpaceOnUse"
                width="125.926%"
                height="195.455%"
            >
                <feDropShadow stdDeviation={10} dx={0} dy={2} floodColor="#D9D9D8"/>
            </filter>
            <filter
                id="g_timeTracking"
                x="-19.136%"
                y="-68.182%"
                filterUnits="userSpaceOnUse"
                width="125.926%"
                height="195.455%"
            >
                <feDropShadow stdDeviation={10} dx={0} dy={2} floodColor="#D9D9D8"/>
            </filter>
        </defs>
        <g filter="url(#a_timeTracking)" transform="translate(46 24)">
            <g clipPath="url(#b_timeTracking)">
                <path fill="#FAFAFA" d="M0 0h284v48H0V0z"/>
                <path
                    d="M7.862 0h268.276A7.862 7.862 0 0 1 284 7.862v32.276A7.862 7.862 0 0 1 276.138 48H7.862A7.862 7.862 0 0 1 0 40.138V7.862A7.862 7.862 0 0 1 7.862 0Z"
                    stroke="#E5E5E5"
                    strokeWidth={4}
                    fill="none"
                    strokeMiterlimit={5}
                />
            </g>
            <path
                d="m262.084 20.468 4 3.97-4 4.015"
                stroke="#E6E6E5"
                strokeWidth={3.874}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g clipPath="url(#c_timeTracking)" transform="translate(89 40)">
            <path fill="#B2B2B3" opacity="81.814%" d="M0 0h89v16H0V0z"/>
        </g>
        <g clipPath="url(#d_timeTracking)" transform="translate(0 24)">
            <path fill="#D9D9D8" opacity="65.739%" d="M0 0h30v11H0V0z"/>
        </g>
        <g clipPath="url(#e_timeTracking)" transform="translate(61 38)">
            <path fill="#B2B2B3" opacity="81.814%" d="M0 0h20v20H0V0z"/>
        </g>
        <g filter="url(#f_timeTracking)" transform="translate(46 84)">
            <g clipPath="url(#b_timeTracking)">
                <path fill="#FAFAFA" d="M0 0h284v48H0V0z"/>
                <path
                    d="M7.862 0h268.276A7.862 7.862 0 0 1 284 7.862v32.276A7.862 7.862 0 0 1 276.138 48H7.862A7.862 7.862 0 0 1 0 40.138V7.862A7.862 7.862 0 0 1 7.862 0Z"
                    stroke="#E5E5E5"
                    strokeWidth={4}
                    fill="none"
                    strokeMiterlimit={5}
                />
            </g>
            <path
                d="m262.084 20.468 4 3.97-4 4.015"
                stroke="#E6E6E5"
                strokeWidth={3.874}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g clipPath="url(#c_timeTracking)" transform="translate(89 100)">
            <path fill="#B2B2B3" opacity="81.814%" d="M0 0h89v16H0V0z"/>
        </g>
        <g clipPath="url(#d_timeTracking)" transform="translate(0 84)">
            <path fill="#D9D9D8" opacity="65.739%" d="M0 0h30v11H0V0z"/>
        </g>
        <g clipPath="url(#e_timeTracking)" transform="translate(61 98)">
            <path fill="#B2B2B3" opacity="81.814%" d="M0 0h20v20H0V0z"/>
        </g>
        <g filter="url(#g_timeTracking)" transform="translate(46 144)">
            <g clipPath="url(#b_timeTracking)">
                <path fill="#FAFAFA" d="M0 0h284v48H0V0z"/>
                <path
                    d="M7.862 0h268.276A7.862 7.862 0 0 1 284 7.862v32.276A7.862 7.862 0 0 1 276.138 48H7.862A7.862 7.862 0 0 1 0 40.138V7.862A7.862 7.862 0 0 1 7.862 0Z"
                    stroke="#E5E5E5"
                    strokeWidth={4}
                    fill="none"
                    strokeMiterlimit={5}
                />
            </g>
            <path
                d="m262.084 20.468 4 3.97-4 4.015"
                stroke="#E6E6E5"
                strokeWidth={3.874}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g clipPath="url(#c_timeTracking)" transform="translate(89 160)">
            <path fill="#B2B2B3" opacity="81.814%" d="M0 0h89v16H0V0z"/>
        </g>
        <g clipPath="url(#d_timeTracking)" transform="translate(0 144)">
            <path fill="#D9D9D8" opacity="65.739%" d="M0 0h30v11H0V0z"/>
        </g>
        <g clipPath="url(#e_timeTracking)" transform="translate(61 158)">
            <path fill="#B2B2B3" opacity="81.814%" d="M0 0h20v20H0V0z"/>
        </g>
    </svg>
)

export default TimeTrackingEmptyStateSvgIcon
