import React, {useState} from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {navigate, PATH_BOOKINGS} from "../../common/utils/NavigationUtils.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import {useHistory} from "react-router-dom";
import useTheme from "@material-ui/core/styles/useTheme.js";
import Paper from "@material-ui/core/Paper/Paper.js";
import Grid from "@material-ui/core/Grid";
import MeetingSuggestionCard from "./MeetingSuggestionCard.js";
import hash from 'object-hash'
import StaticBottomSheet from "../../common/elements/StaticBottomSheet.js";
import CustomButton from "../CustomButton.js";
import {connect} from "react-redux";
import {assignMeetingToPerson} from "../../actions/user-action.js";
import MeetingRoomEmptyState from "./MeetingRoomEmptyState.js";
import MultipleFilterSvgIcon from "../../common/icons/MultipleFilterSvgIcon.js";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        width: '100%',
        display: 'contents',
        minHeight: '100%',
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap,
        minHeight: `calc(100% - ${theme.staticBottomActionSheet.height})`,
        padding: theme.paddingContentContainer.padding,
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
    },
    caption: {
        textAlign: 'left',
        marginBottom: '0.8rem',
    },
    buttonContainer: {
        gap: '1.2rem',
    },
    icon: {
        width: '2.4rem',
        height: '2.4rem'
    },
}))

function MeetingSuggestionPage({
                                   meetingSuggestions,
                                   assignMeetingToPerson,
                                   meetingAttendees,
                                   externalAttendees,
                                   meetingGroups,
                                   userId,
                                   assignMeetingPending
                               }) {
    const theme = useTheme()
    const {t} = useTranslation()
    const history = useHistory()
    const classes = useStyle(theme);

    const [selectedMeeting, setSelectedMeeting] = useState(null);

    function bookMeetingRoom() {
        let meeting = {
            space: selectedMeeting.onlineOnly ? null : selectedMeeting.space,
            ...selectedMeeting
        }

        let invitedPersons = [...meetingAttendees]
        meetingGroups.forEach(group => {
            group.members.forEach(person => {
                if (!invitedPersons.some(p => p.id === person.id)) {
                    invitedPersons.push(person)
                }
            })
        })

        assignMeetingToPerson(userId, meeting, invitedPersons, externalAttendees)
            .then(() => navigate(history, PATH_BOOKINGS))
    }

    const meetingsAvailable = () => {
        return meetingSuggestions && meetingSuggestions.length
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item container direction={'column'} wrap={'nowrap'} justifyContent={'space-between'}
                  className={classes.container}>
                <HeaderWithTitleAndBackButton title={t('search_results')} backLabel={t("meeting_details")}
                                              onNavigateBack={() => history.goBack()}/>
                {meetingsAvailable() ? <div className={classes.content}>
                    <Typography variant={'h6'} className={classes.caption}>
                        {t('available_rooms')}
                    </Typography>
                    {meetingSuggestions
                        .map((meetingSuggestion) => {
                            return <MeetingSuggestionCard meeting={meetingSuggestion}
                                                          key={hash(meetingSuggestion)}
                                                          selected={hash(selectedMeeting) === hash(meetingSuggestion)}
                                                          selectMeetingCallback={() => setSelectedMeeting(meetingSuggestion)}/>
                        })}
                </div> : <Grid item container direction={'column'} className={classes.content}>
                    <MeetingRoomEmptyState/>
                </Grid>}
            </Grid>

            <StaticBottomSheet>
                <Grid item container wrap={'nowrap'} className={classes.buttonContainer}>
                    {meetingsAvailable() ? <CustomButton text={t('confirm')} primary
                                                         disabled={!selectedMeeting}
                                                         isLoading={assignMeetingPending}
                                                         onClick={bookMeetingRoom}
                    /> : <CustomButton onClick={history.goBack} customClassName={classes.modifyButton}
                                       primary
                                       text={t('modify_search')}
                                       icon={<MultipleFilterSvgIcon className={classes.icon}
                                                                    stroke={theme.colors.palette.neutral.white}
                                       />}
                    />}
                </Grid>
            </StaticBottomSheet>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        meetingSuggestions: state.meetings.meetingSuggestions,
        meetingAttendees: state.meetings.meetingAttendees,
        externalAttendees: state.meetings.externalAttendees,
        meetingGroups: state.meetings.meetingGroups,
        assignMeetingPending: state.user.assignMeetingPending,
    }
}

const mapDispatchToProps = {
    assignMeetingToPerson: assignMeetingToPerson
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingSuggestionPage)
