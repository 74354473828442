import React, {useMemo, useState} from 'react';

import clsx from "clsx";
import _ from "lodash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";

import Radio from "@material-ui/core/Radio";
import CustomButton from "../CustomButton.js";
import Tag from "../../common/elements/Tag.js";
import useTheme from "@material-ui/core/styles/useTheme";
import SearchableList from "../../common/elements/SearchableList";
import ActionListItemAccordion from "../../common/elements/ActionListItemAccordion";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import SearchSvgIcon from "../../common/icons/SearchSvgIcon.js";
import FilterSvgIcon from "../../common/icons/FilterSvgIcon.js";

import {navigate, PATH_BOOKINGS} from "../../common/utils/NavigationUtils.js";
import {getTimePeriodAtDate} from "../../common/utils/TimeUtils.js";
import {RESOURCE_MOVABLE_LIMITED, RESOURCE_MOVABLE_NONE} from "../../common/utils/NameUtils.js";
import Typography from "@material-ui/core/Typography";
import {assignResourceToUser} from "../../actions/resource-actions.js";
import BookingsEmptyStateSvg from "../../common/icons/BookingsEmptyStateSvg.js";
import StaticBottomSheet from "../../common/elements/StaticBottomSheet.js";
import SearchBar from "../../common/elements/Searchbar.js";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        padding: theme.paddingContentContainer.padding,
        flexDirection: 'column',
        gap: theme.outerGap,
        minHeight: `calc(100% - ${theme.staticBottomActionSheet.height})`,
        boxSizing: 'border-box',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap
    },
    categoryContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    categoryBanner: {
        height: 60,
        display: 'flex',
        alignItems: 'center',
    },
    tags: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.innerSmallGap
    },
    tagsPadding: {
        padding: '0 0 ' + theme.innerSmallGap,
    },
    resourceInfoAccordion: {
        display: 'flex', flexDirection: 'column',
        gap: theme.innerSmallGap,
    },
    emptyState: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.innerGap,
    }
}))

function ResourceSelectionPage({
                                   userId,
                                   timePeriod,
                                   selectedDate,
                                   bookableFreeResources,
                                   assignResourceToUser,
                                   assignResourcePending,
                               }) {
    const theme = useTheme()
    const {t} = useTranslation()
    const history = useHistory();
    const classes = useStyle(theme);

    const [selectedResource, setSelectedResource] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])
    const [activeFilter, setActiveFilter] = useState({search: false, tags: false})
    const [searchString, setSearchString] = useState('');

    const [categories, tagsInCategory] = useMemo(() => {
        const uniqueTags = new Map();
        const categories = new Set();

        bookableFreeResources
            .filter(resource => !searchString || resource.name.toLowerCase().includes(searchString.toLowerCase()))
            .forEach(resource => {

                if (resource.tags && selectedTags.every(tag => resource.tags.findIndex(t => _.isEqual(tag, t)) >= 0))
                    categories.add(resource.categoryName)

                resource.tags.forEach(tag => {
                    uniqueTags.set(tag.displayName, tag)
                })
            })

        return [Array.from(categories), [...uniqueTags.values()]]
    }, [bookableFreeResources, searchString, selectedTags])

    const routeToBefore = () => {
        history.goBack()
    }

    const getOptions = (categoryName) => {
        if (!bookableFreeResources) return []

        if (!_.isEmpty(selectedTags)) {
            return bookableFreeResources
                .filter(resource => _.isEmpty(categoryName) || resource.categoryName === categoryName)
                .filter(resource => resource.tags && selectedTags.every(tag => resource.tags.findIndex(t => _.isEqual(tag, t)) >= 0))
                .map(resource => ({element: resource}))
        }

        return bookableFreeResources
            .filter(resource => resource.name.toLowerCase().includes(searchString.toLowerCase()))
            .filter(resource => _.isEmpty(categoryName) || resource.categoryName === categoryName)
            .map(resource => ({element: resource}))
    }

    function handleBookResourceButton() {
        let tp = getTimePeriodAtDate(timePeriod, selectedDate)
        const assignment = {
            timePeriod: {
                startDate: tp.startDate.toISOString(), endDate: tp.endDate.toISOString(),
            }, resource: selectedResource
        }

        assignResourceToUser(userId, assignment)
            .then(() => navigate(history, PATH_BOOKINGS))
    }

    function onClickOnTag(tag) {
        if (isTagSelected(tag)) {
            setSelectedTags(selectedTags.filter(t => t !== tag))
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }

    function isTagSelected(tag) {
        return selectedTags.includes(tag)
    }

    const headerActions = () => {
        return <div style={{display: 'flex', gap: theme.innerSmallGap}}>
            <CustomButton
                icon={<SearchSvgIcon
                    stroke={activeFilter.search ? theme.colors.palette.neutral.white : theme.colors.palette.neutral.darkMain}/>}
                primary={activeFilter.search}
                onClick={() => {
                    setSelectedTags([])
                    setActiveFilter({search: !activeFilter.search, tags: false})
                }}/>
            <CustomButton
                icon={<FilterSvgIcon
                    stroke={activeFilter.tags ? theme.colors.palette.neutral.white : theme.colors.palette.neutral.darkMain}/>}
                primary={activeFilter.tags}
                onClick={() => {
                    setActiveFilter({search: false, tags: !activeFilter.tags})
                    if (activeFilter.tags)
                        setSelectedTags([])
                }}/>
        </div>
    }

    const movabilityText = (resource) => {
        switch (resource.movable) {
            case RESOURCE_MOVABLE_LIMITED:
                return t('resource.mobility.limited')
            case RESOURCE_MOVABLE_NONE:
                return t('resource.mobility.none')
            default:
                return ''
        }
    }

    const movabilityTextColor = (resource) => {
        switch (resource.movable) {
            case RESOURCE_MOVABLE_NONE:
                return theme.colors.palette.corporate.redMain
            case RESOURCE_MOVABLE_LIMITED:
                return theme.colors.palette.indicator.yellowMain
            default:
                return ''
        }
    }

    const getListItem = (resource) => {
        return <ActionListItemAccordion text={resource.name}
                                        textSecondRow={movabilityText(resource)}
                                        textColorSecondRow={movabilityTextColor(resource)}
                                        textSecondRowVariant={'caption'}
                                        height={72}
                                        marginChildren={30}
                                        hideDivider={true}
                                        endAdornment={<Radio
                                            checked={selectedResource === resource}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                            }}
                                            onChange={() => {
                                                setSelectedResource(resource)
                                            }}
                                            color={'primary'}
                                            value={resource}
                                            className={classes.radio}
                                        />}>
            <div className={classes.resourceInfoAccordion}>
                <Typography variant={'caption'} align={'left'}>
                    {t('resource.resourceCode') + ': ' + resource.resourceCode}
                </Typography>

                {resource.description && <Typography variant={'caption'} align={'left'}>
                    {t('description') + ': ' + resource.description}
                </Typography>}

                <Typography variant={'caption'} align={'left'}>
                    {t('resource.location') + ': ' + resource.buildingName + ', ' + resource.floorName + ", " + resource.location.name}
                </Typography>

                <div className={clsx(classes.tags, classes.tagsPadding)}>
                    {resource && resource.tags && resource.tags.map(tag => {
                        return <Tag tag={tag} key={tag.displayName}/>
                    })}
                </div>
            </div>

        </ActionListItemAccordion>
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <Grid item container direction={'column'} wrap={'nowrap'} justifyContent={'space-between'}
                  className={classes.container}>
                <HeaderWithTitleAndBackButton title={t('resources')}
                                              backLabel={t('back')}
                                              additionalElement={headerActions()}
                                              onNavigateBack={routeToBefore}/>

                <Grid item container direction={'column'} className={classes.content}>
                    {tagsInCategory && activeFilter.tags && <div className={classes.tags}>
                        {tagsInCategory.map(tag => {
                            return <Tag tag={tag}
                                        key={tag.displayName}
                                        onClick={() => onClickOnTag(tag)}
                                        selected={isTagSelected(tag)}/>
                        })}
                    </div>}

                    {activeFilter.search ?
                        <SearchBar placeholder={t('resources.search')}
                                   searchString={searchString}
                                   setSearchString={setSearchString}
                                   autofocus={true}
                                   className={classes.searchContainer}
                        />
                        : null
                    }

                    {!_.isEmpty(categories) ?

                        <div className={classes.categoryContainer}>
                            {categories.map((category, index) => {
                                return <div key={category} style={{height: '100%'}}>
                                    <Typography variant={'h5'} align={'left'} className={classes.categoryBanner}>
                                        {category}
                                    </Typography>
                                    <SearchableList options={getOptions(category)}
                                                    hideSearchBar
                                                    onSelect={r => setSelectedResource(r)}
                                                    selected={selectedResource}
                                                    customListItem={(resource) => getListItem(resource)}
                                    />
                                </div>
                            })}
                        </div> :

                        <div>
                            <div className={classes.emptyState}>
                                <BookingsEmptyStateSvg className={classes.marginBottom}/>
                                <Typography variant={'h5'} align={'center'}>
                                    {t("general.no_entries_found")}
                                </Typography>
                            </div>
                        </div>
                    }
                </Grid>
            </Grid>
            <StaticBottomSheet>
                <CustomButton text={t('book')}
                              onClick={handleBookResourceButton}
                              disabled={!selectedResource}
                              isLoading={assignResourcePending}
                              fullWidth primary/>
            </StaticBottomSheet>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        timePeriod: state.assignmentBuilder.timePeriod,
        selectedDate: state.assignmentBuilder.selectedDate,
        bookableFreeResources: state.resource.bookableFreeResources,
        selectedResource: state.resource.selectedResource,
        assignResourcePending: state.resource.assignResourcePending,
        selectedCategory: state.resource.selectedCategory,
    }
}

const mapDispatchToProps = {
    assignResourceToUser: assignResourceToUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSelectionPage)