import React from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

const useStyle = makeStyles(theme => ({
    tool: {
        height: '64px',
        width: '64px',
        display: 'flex',
        gap: '.4rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toolActive: {
        backgroundColor: theme.colors.palette.corporate.greenLight,
        borderRadius: '6px',
    },
    iconSelection: {
        height: '3.6rem',
        width: '3.6rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        height: '18px',
        width: '18px',
        stroke: theme.colors.palette.neutral.darkNeutral,
    },
    iconActive: {
        fill: theme.colors.palette.neutral.white,
        color: theme.colors.palette.neutral.white,
        stroke: theme.colors.palette.neutral.white,
    }
}));

function ToolBarItem({toolName, icon, selected, onClick}) {

    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyle(theme);

    return (
        <div className={classes.tool}
             onClick={() => onClick ? onClick(toolName) : console.log('tool clicked ', toolName)}>
            <div
                className={clsx({
                    [classes.iconSelection]: true,
                    [classes.toolActive]: selected
                })}>
                {icon ? React.cloneElement(icon, {
                    className: clsx({
                        [classes.icon]: true,
                        [classes.iconActive]: selected
                    })
                }) : null}
            </div>
            <Typography variant={'subtitle1'}>
                {t(toolName)}
            </Typography>
        </div>
    );
}

ToolBarItem.propTypes = {
    toolName: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.object,

};

ToolBarItem.defaultProps = {
    toolName: 'Tool',
    selected: false,
};

export default ToolBarItem;