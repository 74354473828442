import React, {useEffect, useState} from 'react';
import {makeStyles, Typography, useTheme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import TrashIcon from "../../common/icons/TrashSvgIcon";
import CustomButton from "../CustomButton.js";
import ModalWrapper from "../../common/elements/ModalWrapper.js";
import Tag from "../../common/elements/Tag.js";
import {getMeetingLocationAsString} from "../../common/utils/AssignmentUtils.js";
import {getDateAsTimePeriod, getTimePeriodAsString} from "../../common/utils/TimeUtils.js";
import {setTimePeriodForAssignment} from "../../actions/workplaceAssignment-action.js";
import {getResourceAssignments, updateResourceAssignment} from "../../actions/resource-actions.js";
import ClickableText from "../../common/elements/ClickableText.js";
import EditSvgIcon from "../../common/icons/EditSvgIcon.js";
import EditAssignmentsTimePeriod from "./EditAssignmentsTimePeriod.js";
import {connect} from "react-redux";

const useStyle = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem'
    },
    subContentGapContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
    headerIcon: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0px  !important',
        height: '24px !important',
        cursor: 'pointer',
        width: '24px !important',
        maxWidth: '24px !important',
    },
    headerActionsRight: {
        display: 'flex',
        gap: theme.innerGap,
    },
    skinMain: {
        color: theme.colors.palette.corporate.skinMain,
    },
    info: {
        display: 'flex',
        flexDirection: "column",
        gap: theme.innerGap
    },
    centerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        minHeight: '420px',
        maxHeight: '650px',
        [theme.breakpoints.down('lg')]: {
            maxHeight: '420px',
        },
    },
}))

function ResourceInformationModal({
                                      onClose,
                                      onDelete,
                                      open,
                                      assignment,
                                      updateAssignment,
                                      setTimePeriod,
                                      personId,
                                      timePeriod,
                                      getResourceAssignments,
                                      resourceAssignments
                                  }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [editError, setEditError] = useState(false);

    useEffect(() => {
        if (editMode) {
            const tp = getDateAsTimePeriod(assignment.timePeriod.startDate)
            getResourceAssignments(assignment.resource.id, {
                startDate: tp.startDate.toISOString(),
                endDate: tp.endDate.toISOString()
            })
        }
    }, [editMode, assignment, getResourceAssignments])

    if (!open)
        return null

    const onEditSubmit = () => {
        updateAssignment(personId, {...assignment, timePeriod: timePeriod})
        setEditMode(false)
    }

    const closePopup = () => {
        setEditMode(false)
        onClose()
    }

    const getHeaderAction = () => {
        return (
            <div>
                {editMode
                    ? <div className={classes.headerActionsRight}>
                        <ClickableText text={t('done')}
                                       disabled={editError}
                                       textColor={theme.colors.palette.corporate.skinMain}
                                       onClick={onEditSubmit}/>
                    </div>
                    : <div className={classes.headerActionsRight}>
                        <CustomButton icon={<EditSvgIcon width={24} height={24}/>}
                                      onClick={() => setEditMode(true)}
                                      customClassName={classes.headerIcon}
                        />
                        <CustomButton icon={<TrashIcon/>}
                                      onClick={() => {
                                          onDelete()
                                          onClose()
                                      }}
                                      textColor={theme.colors.palette.corporate.redMain}
                                      customClassName={classes.headerIcon}
                        />
                    </div>
                }
            </div>
        )
    }
    return (
        <ModalWrapper open={open} onClose={closePopup}
                      headerActions={getHeaderAction()}>

            <div className={classes.content}>
                <div className={classes.subContentGapContainer}>
                    <Typography variant={"h6"}>{t('booking_details')}</Typography>
                    <Typography variant={"h3"}>
                        {assignment.resource.name}
                    </Typography>
                    <div>
                        <Typography variant={"body1"} className={classes.skinMain} align={'left'}>
                            {getTimePeriodAsString(assignment.timePeriod)}
                        </Typography>
                        <Typography variant={"body1"} className={classes.skinMain}>
                            {assignment ? moment(assignment.timePeriod.startDate).format("dd, DD.MM.YYYY") : null}
                        </Typography>
                    </div>
                    <Typography variant={"h6"}>
                        {getMeetingLocationAsString(assignment, t) + ", " + assignment.resource.location.name}
                    </Typography>
                </div>

                <Divider/>
                {editMode
                    ? <EditAssignmentsTimePeriod assignment={assignment}
                                                 allAssignments={resourceAssignments}
                                                 onEditError={setEditError} onChange={setTimePeriod}/>
                    : <div>
                        <div className={classes.info}>

                            <div className={classes.headerActionsRight}>
                                {assignment.resource.tags && assignment.resource.tags.map(tag => {
                                    return <Tag tag={tag} key={assignment.id + tag.id}/>
                                })}
                            </div>

                            <div className={classes.subContentGapContainer}>
                                <Typography variant={"h6"}>{t('resource.resourceCode')}</Typography>
                                <Typography variant={"body1"}>{assignment.resource.resourceCode}</Typography>
                            </div>

                            <div className={classes.subContentGapContainer}>
                                <Typography variant={"h6"}>{t('resource.mobility')}</Typography>
                                <Typography
                                    variant={"body1"}>{t('resource.mobility.' + assignment.resource.movable.toLowerCase())}</Typography>
                            </div>

                            {assignment.resource.description &&
                                <div className={classes.subContentGapContainer}>
                                    <Typography variant={"h6"}>{t('description')}</Typography>
                                    <Typography variant={"body1"}>{assignment.resource.description}</Typography>
                                </div>
                            }

                        </div>
                    </div>
                }
            </div>
        </ModalWrapper>
    )
}

ResourceInformationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.any.isRequired,
    assignment: PropTypes.any.isRequired,
    onDelete: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        personId: state.user.person.id,
        timePeriod: state.assignmentBuilder.timePeriod,
        resourceAssignments: state.resource.resourceAssignments,
    }
};

let mapDispatchToProps = {
    setTimePeriod: setTimePeriodForAssignment,
    updateAssignment: updateResourceAssignment,
    getResourceAssignments: getResourceAssignments
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceInformationModal)
