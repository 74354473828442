import React from 'react'
import {makeStyles} from '@material-ui/core'
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme.js";
import CustomizedSwitch from "./CustomizedSwitch.js";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}))

function CustomizedSwitchWithLabel({onChange, checked, className, label, labelVariant, ...props}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return <div className={clsx(classes.root, className)}>
        <Typography variant={labelVariant ?? 'body1'}>{label}</Typography>
        <CustomizedSwitch onChange={onChange} checked={checked}/>
    </div>
}

CustomizedSwitchWithLabel.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string,
    labelVariant: PropTypes.string,
}

export default CustomizedSwitchWithLabel

