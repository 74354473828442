import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'
import ChainedBackend from "i18next-chained-backend";
import detector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import moment from "moment";

window.i18n = i18n

function genRandonNumber(length) {
    const chars = '0123456789';
    const charLength = chars.length;
    let result = '';
    for (var i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
}

i18n
    .use(ChainedBackend)
    .use(detector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        detection: {
            lookupCookie: 'language',
            cookieOptions: {path: '/'},
            caches: ['cookie', 'localStorage'],
        },
        backend: {
            backends: [
                LocalStorageBackend,
                Backend
            ],
            backendOptions: [{
                expirationTime: 24 * 60 * 60 * 1000 * 3, // 3 days
                defaultVersion: 'v' + genRandonNumber(10),
                store: window.location.hostname !== "localhost" ? window.localStorage : null
            }, {
                loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
            }]
        },
        fallbackLng: 'en',
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true,
            useSuspense: false
        }
    }, () => {
        moment.locale(i18n.language)
    })

i18n.defaultLocale = 'en'

export default i18n