import React, {useEffect, useState} from 'react';

import {Divider, makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import RadioButtonWithDescription from "../RadioButtonWithDescription";
import {getTimeAtDate, inOneHour} from "../../../common/utils/TimeUtils.js";
import ModalWrapper from "../../../common/elements/ModalWrapper.js";
import {setSelectedDate} from "../../../actions/workplaceAssignment-action.js";
import {
    addTimeTracking,
    addTimeTrackingBreak,
    updateTimeTracking,
    updateTimeTrackingBreak
} from "../../../actions/timetracking-actions.js";
import CustomDateRangePicker from "../../CustomDateRangePicker.js";
import moment from "moment";
import ClickableText from "../../../common/elements/ClickableText";
import TimeperiodSelectionWithClocksAndTimeline
    from "../../../common/elements/TimeperiodSelectionWithClocksAndTimeline.js";
import {usePersonWorkingHours} from "../../../common/customHooks/usePersonWorkingHours.js";

const useStyle = makeStyles(theme => ({
    content: {
        display: 'flex',
        justifyContent: 'start',
        gap: theme.outerGap,
        flexDirection: 'column'
    },
    section: {
        display: 'flex',
        flexDirection: "column",
        gap: theme.innerGap
    }
}))

function EditTimeTrackingModal({
                                   userId,
                                   entry,
                                   open,
                                   updateTimeTrackingBreak,
                                   updateTimeTracking,
                                   addTimeTracking,
                                   addTimeTrackingBreak,
                                   onClose,
                                   initialDate,
                                   setSelectedDate
                               }) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);

    const [isTimeTracking, setIsTimeTracking] = useState(entry ? !!entry.clockInTime : true);
    const [currentSelectedDate, setCurrentSelectedDate] = useState(initialDate);

    const defaultTimeperiod = usePersonWorkingHours(currentSelectedDate)

    const [objectToUpdate, setObjectToUpdate] = useState(entry
        ? {
            id: entry.id,
            startDate: entry.clockInTime ? entry.clockInTime : entry.startTime ? entry.startTime : new Date(),
            endDate: entry.clockOutTime ? entry.clockOutTime : entry.endTime ? entry.endTime : new Date(),
        }
        : {
            startDate: defaultTimeperiod.startDate,
            endDate: defaultTimeperiod.endDate,
        });

    const [showTypeSelection, setShowTypeSelection] = useState(!entry);

    const [saveDisabled, setSaveDisabled] = useState(false)
    const [endTimeChanged, setEndTimeChanged] = useState(false)

    useEffect(() => {
        if (!entry) {
            if (!isTimeTracking) {
                let start = moment(defaultTimeperiod.startDate)
                start.set({hour: 12, minute: 30, second: 0})

                setObjectToUpdate({startDate: start, endDate: inOneHour(start, false)})
            } else {
                setObjectToUpdate({startDate: defaultTimeperiod.startDate, endDate: defaultTimeperiod.endDate})
            }
        }
    }, [defaultTimeperiod, isTimeTracking, entry]);


    function setDate(date) {
        setSelectedDate(date)
        setCurrentSelectedDate(date)
    }

    function setStartTime(time) {
        let start = getTimeAtDate(time, objectToUpdate.startDate)
        setObjectToUpdate({...objectToUpdate, startDate: start, endDate: objectToUpdate.endDate})
    }

    function setEndTime(time) {
        setEndTimeChanged(true)
        let end = getTimeAtDate(time, objectToUpdate.startDate)
        setObjectToUpdate({...objectToUpdate, startDate: objectToUpdate.startDate, endDate: end})
    }

    function onSaveClick() {
        const start = objectToUpdate.startDate
        const end = objectToUpdate.endDate

        if (isTimeTracking) {
            if (entry) {
                let timeTracking = {
                    id: entry.id,
                    clockInTime: start,
                    clockOutTime: endTimeChanged ? end : null
                }

                updateTimeTracking(userId, timeTracking, !entry.clockInTime)
            } else {
                let timeTracking = {
                    clockInTime: start,
                    clockOutTime: end
                }
                addTimeTracking(userId, timeTracking)
            }
        } else {
            if (entry) {
                let timeTrackingBreak = {
                    id: entry.id,
                    startTime: start,
                    endTime: endTimeChanged ? end : null
                }
                updateTimeTrackingBreak(userId, timeTrackingBreak, !entry.startTime)
            } else {
                let timeTrackingBreak = {
                    startTime: start,
                    endTime: end
                }

                addTimeTrackingBreak(userId, timeTrackingBreak)
            }
        }
        onClose()
    }

    return (
        <ModalWrapper open={open}
                      onClose={onClose}
                      headerActions={<ClickableText disabled={saveDisabled}
                                                    onClick={() => {
                                                        showTypeSelection
                                                            ? setShowTypeSelection(false)
                                                            : onSaveClick()
                                                    }}
                                                    text={showTypeSelection ? t('continue') : t('save')}/>}>
            <div className={classes.content}>

                <div className={classes.section}>
                    <Typography variant={'h6'}>{t('type')}</Typography>
                    {showTypeSelection
                        ? <div>
                            <RadioButtonWithDescription title={t('work_time')}
                                                        selected={isTimeTracking}
                                                        onChange={() => setIsTimeTracking(!isTimeTracking)}/>
                            <RadioButtonWithDescription title={t('break_time')}
                                                        selected={!isTimeTracking}
                                                        onChange={() => setIsTimeTracking(!isTimeTracking)}/>
                        </div>
                        : <Typography variant={'body1'}>{isTimeTracking ? t('work_time') : t('break_time')}</Typography>
                    }

                    <Divider/>
                </div>

                {showTypeSelection &&
                    <div className={classes.section}>
                        <Typography variant={'h6'}>{t('date')}</Typography>
                        <CustomDateRangePicker rangeMode={false}
                                               onDateChange={(value) => {
                                                   setDate(value.startDate)
                                               }}
                                               centered
                                               maxDate={moment()}
                                               disableAfterNow
                                               initialSelected={objectToUpdate.startDate}/>
                    </div>}


                {!showTypeSelection &&
                    <TimeperiodSelectionWithClocksAndTimeline
                        startTime={objectToUpdate.startDate}
                        endTime={objectToUpdate.endDate}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                        errorCallback={setSaveDisabled}
                    />}
            </div>
        </ModalWrapper>
    )
}

EditTimeTrackingModal.propTypes = {
    entry: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    initialDate: PropTypes.object,
    open: PropTypes.bool,
    isBreak: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
    }
}

const mapDispatchToProps = {
    updateTimeTracking: updateTimeTracking,
    updateTimeTrackingBreak: updateTimeTrackingBreak,
    addTimeTracking: addTimeTracking,
    addTimeTrackingBreak: addTimeTrackingBreak,
    setSelectedDate: setSelectedDate

}

export default connect(mapStateToProps, mapDispatchToProps)(EditTimeTrackingModal)