import * as React from "react"
import PropTypes from 'prop-types'
import theme from "../../theme.js";

function StartSvgIcon({color, width, height, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            {...props}>
            <path
                d="m18.252 12.832-9.197 6.132A1 1 0 0 1 7.5 18.13V5.87a1 1 0 0 1 1.555-.833l9.197 6.132a1 1 0 0 1 0 1.664Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}

StartSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

StartSvgIcon.defaultProps = {
    color: theme.colors.palette.corporate.skinMain,
    width: 24,
    height: 24
};

export default StartSvgIcon
