import React from 'react';
import PropTypes from 'prop-types';

import {MAX_DAYS_OF_BOOKING} from "../common/utils/AssignmentUtils.js";
import {isWorkingdayOfUser} from "../common/customHooks/usePersonWorkingHours.js";
import SingleLineDatePicker from "./SingleLineDatePicker.js";
import {connect} from "react-redux";


function SingleLineDatePickerWithUsersWorkingHours({
                                                       startDate,
                                                       numberOfDays,
                                                       selected,
                                                       caption,
                                                       local,
                                                       onChange,
                                                       preventInitialCallback,
                                                       className,
                                                       showDate,
                                                       skipDate,
                                                       showIndicator,
                                                       disableWeekend,
                                                       workingHours,
                                                       ...props
                                                   }) {
    return (
        <SingleLineDatePicker startDate={startDate}
                              numberOfDays={numberOfDays}
                              showIndicator={showIndicator}
                              onChange={onChange}
                              dateFormat={props.dateFormat}
                              local={local}
                              selected={selected}
                              caption={caption}
                              color={props.color}
                              skipDate={date => !isWorkingdayOfUser(date, workingHours) || skipDate(date)}
                              greyOutDate={date => !isWorkingdayOfUser(date, workingHours)}
                              disableDate={props.disableDate}
                              disableWeekend={disableWeekend}
                              preventInitialCallback={preventInitialCallback}
                              showDate={showDate}/>
    );
}

SingleLineDatePicker.propTypes = {
    startDate: PropTypes.instanceOf(Date).isRequired,
    numberOfDays: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    local: PropTypes.string,
    selected: PropTypes.any,
    caption: PropTypes.string,
    color: PropTypes.string,
    disableDate: PropTypes.func,
    disableWeekend: PropTypes.bool,
    showIndicator: PropTypes.func,
    skipDate: PropTypes.func,
    preventInitialCallback: PropTypes.bool,
    showDate: PropTypes.bool,
    innerClasses: PropTypes.object,
};

SingleLineDatePicker.defaultProps = {
    numberOfDays: MAX_DAYS_OF_BOOKING,
    dateFormat: 'dddd, MMM D',
    caption: 'Date',
    local: 'en',
    preventInitialCallback: false,
    disableDate: () => {
        return false
    },
    showIndicator: () => {
        return false
    },
    skipDate: () => {
        return false
    },
    innerClasses: {}
};


const mapStateToProps = state => {
    return {
        workingHours: state.user.person.workingHours,
    }
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SingleLineDatePickerWithUsersWorkingHours);