import axios from 'axios';

export function getTypesOfActivity() {
    return {
        type: 'GET_TYPES_OF_ACTIVITIES',
        payload: axios.get(`/api/common/types-of-activities`)
    }
}

export function getActivityAttributes() {
    return {
        type: 'GET_ACTIVITY_ACTIONS',
        payload: axios.get(`/api/common/activity-attributes`)
    }
}

export function getTypesOfUse() {
    return {
        type: 'GET_TYPES_OF_USE',
        payload: axios.get(`/api/common/types-of-use`)
    }
}

export function downloadUsersExampleFile() {
    return {
        type: 'DOWNLOAD_USERS_EXAMPLE_FILE',
        payload: axios.get(`/api/common/userImport/example/xlsx`, {
            responseType: 'blob'
        })
            .then((response) => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers['content-disposition'].split('=')[1]);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error))
    }
}