import {isEmpty} from "lodash";

const initialState = {
    buildingList: [],
    building: null,
    buildingsPending: false,
}

function buildingsReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_BUILDINGS_FULFILLED':

            if (state.building) {

                const buildingToReplace = action.payload.data.find((b) => b.id === state.building.id)

                return {
                    ...state,
                    buildingList: action.payload.data,
                    buildingsPending: false,
                    building: buildingToReplace
                }
            }

            return {
                ...state,
                buildingList: action.payload.data,
                buildingsPending: false,
            }

        case 'GET_BUILDINGS_PENDING':
            return {
                ...state,
                buildingsPending: true,
            }

        case 'DELETE_BUILDING_FULFILLED':
            return {
                ...state,
                buildingList: [...state.buildingList.filter(building => building.id !== action.payload.data.id)]
            }

        case 'CREATE_BUILDING_FULFILLED':
            return {
                ...state,
                buildingList: [...state.buildingList, action.payload.data]
            }

        case 'UPDATE_BUILDING_FULFILLED': {
            let buildings = state.buildingList
            let buildingIndex = buildings.findIndex(building => building.id === action.payload.data.id)
            buildings[buildingIndex] = action.payload.data
            return {
                ...state,
                buildingList: [...buildings]
            }
        }

        case 'CREATE_FLOOR_FULFILLED': {
            let possibleBuildingWhereFloorCreated = state.building

            if (possibleBuildingWhereFloorCreated) {
                possibleBuildingWhereFloorCreated.floors.push(action.payload.data)
            } else {
                return {...state}
            }

            let newBuildings = [...state.buildingList.filter(building => building.id !== possibleBuildingWhereFloorCreated.id)]
            newBuildings.push(possibleBuildingWhereFloorCreated)

            return {
                ...state,
                buildingList: newBuildings,
                building: possibleBuildingWhereFloorCreated
            }
        }

        case 'DELETE_FLOOR_FULFILLED': {
            let possibleBuildingWhereFloorDeleted
            if (state.buildingList == null || isEmpty(state.buildingList)) {
                possibleBuildingWhereFloorDeleted = state.building
            } else {
                possibleBuildingWhereFloorDeleted = state.buildingList.find(building => building && building.floors.some(floor =>
                    floor.id === action.payload.data.id))
            }

            if (possibleBuildingWhereFloorDeleted) {
                possibleBuildingWhereFloorDeleted.floors = possibleBuildingWhereFloorDeleted.floors.filter(floor => floor.id !== action.payload.data.id)
            } else {
                return {...state}
            }

            let newBuildings = [...state.buildingList.filter(building => building.id !== possibleBuildingWhereFloorDeleted.id), possibleBuildingWhereFloorDeleted]

            return {
                ...state,
                buildingList: newBuildings,
                building: possibleBuildingWhereFloorDeleted
            }
        }
        case 'SAVE_FLOOR_GEOMETRIES_FULFILLED':
        case 'UPDATE_FLOOR_LEVELS_FULFILLED':
        case 'UPDATE_FLOORS_FULFILLED': {
            let updatedFloors = Array.isArray(action.payload.data)
                ? action.payload.data
                : [action.payload.data]

            let newBuildings = [...state.buildingList]
            let building

            for (let floorData of updatedFloors) {

                building = [...state.buildingList].find(building => building && building.floors.find(floor =>
                    floor.id === floorData.id))

                if (!building) {
                    let index
                    if ((index = state.building.floors.findIndex(floor => floor.id === floorData.id)) > -1) {
                        building = state.building
                        building.floors[index] = floorData
                        newBuildings.push(building)
                    } else {
                        return {...state}
                    }
                }

                let floorIndex = building.floors.findIndex(floor => floor.id === floorData.id)
                building.floors[floorIndex] = floorData
            }

            let buildingIndex = state.buildingList.findIndex(buildingFromList => buildingFromList.id === building.id)
            newBuildings[buildingIndex] = building

            return {
                ...state,
                buildingList: [...newBuildings],
                building: building
            }
        }

        case 'GET_BUILDING_FULFILLED':
            return {
                ...state,
                building: action.payload.data,
                buildingsPending: false,
            }

        case 'GET_BUILDING_PENDING':
            return {
                ...state,
                buildingsPending: true,
            }

        case 'SET_BUILDING':
            return {
                ...state,
                building: action.payload
            }

        case 'UNSET_BUILDING':
            return {
                ...state,
                building: null
            }

        case 'GET_HOMEZONE_FULFILLED':
        case 'SET_HOMEZONE_FULFILLED':
            return {
                ...state,
                building: action.payload.data ? action.payload.data.building : state.building
            }

        case 'CREATE_SPACES_FULFILLED': {
            let createdSpaces = action.payload.data
            if (!createdSpaces) {
                return state
            }

            let buildings = [...state.buildingList]
            buildings.forEach(building => {
                    let floor = building.floors.find(floor => floor.id === createdSpaces[0].floorId)
                    if (floor) {
                        floor.spaces.push(...createdSpaces)
                    }
                }
            )

            return {
                ...state,
                buildingList: buildings,
            }
        }

        case 'UPDATE_SPACES_FULFILLED': {
            let updatedSpaces = action.payload.data
            if (!updatedSpaces) {
                return state
            }

            let buildings = [...state.buildingList]
            buildings.forEach(building => {
                    let floor = building.floors.find(floor => floor.id === updatedSpaces[0].floorId)
                    if (floor) {
                        updatedSpaces.forEach(space => {
                            let spaceIndex = floor.spaces.indexOf(s => s.id === space.id);
                            floor.spaces[spaceIndex] = space
                        })
                    }
                }
            )

            return {
                ...state,
                buildingList: buildings,
            }
        }

        case 'DELETE_SPACES_FULFILLED': {
            let deletedSpaces = action.payload.data
            if (!deletedSpaces) {
                return state
            }

            let buildings = [...state.buildingList]
            buildings.forEach(building => {
                    let floor = building.floors.find(floor => floor.id === deletedSpaces[0].floorId)
                    if (floor) {
                        deletedSpaces.forEach(space => {
                            let spaceIndex = floor.spaces.indexOf(s => s.id === space.id);
                            delete floor.spaces[spaceIndex]
                        })
                    }
                }
            )

            return {
                ...state,
                buildingList: buildings,
            }
        }

        case 'CREATE_WORKPLACES_FULFILLED': {
            let createdWorkplaces = action.payload.data
            if (!createdWorkplaces) {
                return state
            }

            let buildings = [...state.buildingList]
            buildings.forEach(building => {
                    let floor = building.floors.find(floor => floor.id === createdWorkplaces[0].floorId)
                    if (floor) {
                        let space = floor.spaces.find(space => space.id === createdWorkplaces[0].spaceId)
                        if (space) {
                            space.workplaces.push(...createdWorkplaces)
                        }
                    }
                }
            )

            return {
                ...state,
                buildingList: buildings,
            }
        }

        case 'UPDATE_WORKPLACES_FULFILLED': {
            let updatedWorkplaces = action.payload.data
            if (!updatedWorkplaces) {
                return state
            }

            let buildings = [...state.buildingList]
            buildings.forEach(building => {
                    let floor = building.floors.find(floor => floor.id === updatedWorkplaces[0].floorId)
                    if (floor) {
                        updatedWorkplaces.forEach(workplace => {
                            let space = floor.spaces.find(space => space.id === workplace.spaceId)
                            if (space) {
                                let workplaceIndex = space.workplaces.indexOf(w => w.id === workplace.id);
                                space.workplaces[workplaceIndex] = space
                            }
                        })
                    }
                }
            )

            return {
                ...state,
                buildingList: buildings,
            }
        }

        case 'DELETE_WORKPLACES_FULFILLED': {
            let deletedWorkplaces = action.payload.data
            if (!deletedWorkplaces) {
                return state
            }

            let buildings = [...state.buildingList]
            buildings.forEach(building => {
                    let floor = building.floors.find(floor => floor.id === deletedWorkplaces[0].floorId)
                    if (floor) {
                        deletedWorkplaces.forEach(workplace => {
                            let space = floor.spaces.find(space => space.id === workplace.spaceId)
                            if (space) {
                                let workplaceIndex = space.workplaces.indexOf(w => w.id === workplace.id);
                                delete space.workplaces[workplaceIndex]
                            }
                        })
                    }
                }
            )

            return {
                ...state,
                buildingList: buildings,
            }
        }

        default:
            return state

    }
}

export default buildingsReducer
