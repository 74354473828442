import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useHistory} from "react-router-dom";
import {
    navigate,
    PATH_PROFILE,
    PATH_PROFILE_HOMEZONE,
    PATH_PROFILE_INTEGRATIONS,
    PATH_PROFILE_WORKING_DAYS
} from "../../common/utils/NavigationUtils.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import LevelPanel from "./LevelPanel";
import SimpleListItem from "../../common/elements/SimpleListItem.js";
import {
    CREATE_MEETING_ASSIGNMENT,
    CREATE_RESOURCE_ASSIGNMENT,
    CREATE_WORKPLACE_ASSIGNMENT,
    SET_ATTENDANCE,
    SET_HOMEZONE,
    SET_WORKING_HOURS,
    THIRD_PARTY_INTEGRATION,
    UPLOAD_PROFILE_IMAGE,
    WORKPLACE_RATING
} from "../../common/utils/GamificationUtils.js";
import {getGamificationPointUtils} from "../../actions/dashboard-actions.js";
import {getUserGameStatus} from "../../actions/user-action.js";
import Award from "./Award.js";

import HomezoneAward from "../../common/img/awards/HomezoneAward.png";
import ProfilePictureAward from "../../common/img/awards/ProfilePictureAward.png";
import ThirdPartyAward from "../../common/img/awards/ThirdPartyAward.png";
import WorkingHoursAward from "../../common/img/awards/WorkingHoursAward.png";
import HomezoneAwardAchieved from "../../common/img/awards/HomezoneAwardAchieved.png";
import ThirdPartyAwardAchieved from "../../common/img/awards/ThirdPartyAwardAchieved.png";
import ProfilePictureAwardAchieved from "../../common/img/awards/ProfilePictureAwardAchieved.png";
import WorkingHoursAwardAchieved from "../../common/img/awards/WorkingHoursAwardAchieved.png";


const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        width: '100%'
    },
    flex: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        rowGap: '1.8rem'
    }
}))

function AchievementsPage({gamificationPointUtils, getGamificationPointUtils, gameStatus, getUserGameStatus, userId}) {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);

    useEffect(() => {
        if (gamificationPointUtils == null)
            getGamificationPointUtils()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (userId) {
            getUserGameStatus(userId)
        }
    }, [userId, getUserGameStatus])

    function getGamificationPoints(pointsName) {
        if (!pointsName) return

        const rewardPoints = gamificationPointUtils && gamificationPointUtils[pointsName] ? gamificationPointUtils[pointsName] : 0
        const rewardAchievedCount = gameStatus && gameStatus.rewardsMap && gameStatus.rewardsMap[pointsName]
            ? gameStatus.rewardsMap[pointsName]
            : 0
        return rewardPoints * rewardAchievedCount
    }

    function awardAchievedYet(award) {
        return gameStatus && gameStatus.rewardsMap && gameStatus.rewardsMap[award]
    }

    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton title={t('achievements')} backLabel={t("profile")}
                                          onNavigateBack={() => navigate(history, PATH_PROFILE)}/>
            <div className={classes.content}>
                <LevelPanel/>
                <div>
                    <SimpleListItem title={t('workplaces_booked')} hideArrow topBorder={false}
                                    alternativeEndAdornment={
                                        <Typography variant={'h5'}>
                                            {getGamificationPoints(CREATE_WORKPLACE_ASSIGNMENT) + ' ' + t('pts')}
                                        </Typography>
                                    }
                    />

                    <SimpleListItem title={t('meetings_created')} hideArrow
                                    alternativeEndAdornment={
                                        <Typography variant={'h5'}>
                                            {getGamificationPoints(CREATE_MEETING_ASSIGNMENT) + ' ' + t('pts')}
                                        </Typography>
                                    }
                    />

                    <SimpleListItem title={t('gamification.resources.created')} hideArrow
                                    alternativeEndAdornment={
                                        <Typography variant={'h5'}>
                                            {getGamificationPoints(CREATE_RESOURCE_ASSIGNMENT) + ' ' + t('pts')}
                                        </Typography>
                                    }
                    />

                    <SimpleListItem title={t('workplaces_rated')} hideArrow
                                    alternativeEndAdornment={
                                        <Typography variant={'h5'}>
                                            {getGamificationPoints(WORKPLACE_RATING) + ' ' + t('pts')}
                                        </Typography>
                                    }
                    />

                    <SimpleListItem title={t('meetings_responded')} hideArrow
                                    alternativeEndAdornment={
                                        <Typography variant={'h5'}>
                                            {getGamificationPoints(SET_ATTENDANCE) + ' ' + t('pts')}
                                        </Typography>
                                    }
                    />

                </div>
                {gamificationPointUtils &&
                    <div className={classes.content}>
                        <Typography variant={'h5'} align={'left'}>{t('awards')}</Typography>
                        <div className={classes.flex}>
                            <Award title={t('homezone_reward')}
                                   points={gamificationPointUtils[SET_HOMEZONE]}
                                   onClick={awardAchievedYet(SET_HOMEZONE) ? null : () => navigate(history, PATH_PROFILE_HOMEZONE)}
                                   image={awardAchievedYet(SET_HOMEZONE) ? HomezoneAwardAchieved : HomezoneAward}/>
                            <Award title={t('third_party_reward')}
                                   points={gamificationPointUtils[THIRD_PARTY_INTEGRATION]}
                                   onClick={awardAchievedYet(THIRD_PARTY_INTEGRATION) ? null : () => navigate(history, PATH_PROFILE_INTEGRATIONS)}
                                   image={awardAchievedYet(THIRD_PARTY_INTEGRATION) ? ThirdPartyAwardAchieved : ThirdPartyAward}/>
                            <Award title={t('profile_picture_reward')}
                                   points={gamificationPointUtils[UPLOAD_PROFILE_IMAGE]}
                                   onClick={awardAchievedYet(UPLOAD_PROFILE_IMAGE) ? null : () => navigate(history, PATH_PROFILE)}
                                   image={awardAchievedYet(UPLOAD_PROFILE_IMAGE) ? ProfilePictureAwardAchieved : ProfilePictureAward}/>
                            <Award title={t('working_days_reward')}
                                   points={gamificationPointUtils[SET_WORKING_HOURS]}
                                   onClick={awardAchievedYet(SET_WORKING_HOURS) ? null : () => navigate(history, PATH_PROFILE_WORKING_DAYS)}
                                   image={awardAchievedYet(SET_WORKING_HOURS) ? WorkingHoursAwardAchieved : WorkingHoursAward}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )

}


const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        gameStatus: state.user.person.gameStatus,
        gamificationPointUtils: state.dashboard.gamificationPointUtils,
    }
}

const mapDispatchToProps = {
    getGamificationPointUtils: getGamificationPointUtils,
    getUserGameStatus: getUserGameStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(AchievementsPage)