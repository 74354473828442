import * as React from "react"
import SvgIcon from "@material-ui/core/SvgIcon";

function AlertRoundSvgIcon(props) {
    return (
        <SvgIcon className={props.className}   viewBox="0 0 40 40"
                 {...props}
        >
            <path
                d="M20 8c6.627 0 12 5.373 12 12s-5.373 12-12 12S8 26.627 8 20 13.373 8 20 8zm0 16.5a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0020 24.5zm.75-12h-1.5V23h1.5V12.5z"
                fill={props.fill || '#FF3E3E'}
                fillRule="evenodd"
            />
        </SvgIcon>
    )
}

export default AlertRoundSvgIcon
