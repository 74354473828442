import * as React from "react"
import PropTypes from 'prop-types'
import theme from "../../theme.js";

function BreakSvgIcon({color, width, height, ...props}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            {...props}>
            <title>{"Icons/Main/break 2"}</title>
            <g fillRule="nonzero" stroke={color} fill="none">
                <g transform="translate(6 6)">
                    <circle cx={6} cy={6} r={6}/>
                    <path d="M6 1.8V6l1.8 1.8"/>
                </g>
                <path d="M21.5 6.5a2 2 0 0 0-2 2v5h2v-7ZM21.5 13v5M3.5 6v12M5 6v2.5a1.5 1.5 0 0 1-3 0V6"/>
            </g>
        </svg>
    )
}

BreakSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

BreakSvgIcon.defaultProps = {
    color: theme.colors.palette.corporate.skinMain,
    width: 24,
    height: 24
};

export default BreakSvgIcon