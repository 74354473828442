import React from 'react';
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import AvatarWithOccupancy from "./AvatarWithOccupancy";

const useStyles = props => makeStyles(theme => ({
    root: {
        width: props.size + 'rem',
        height: props.size + 'rem',
        minWidth: props.size + 'rem',
        minHeight: props.size + 'rem',
        borderRadius: '1rem',
        backgroundColor: theme.colors.palette.neutral.greyMedium,
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
        gap: '2px',
        flexWrap: 'wrap',
        boxSizing: 'border-box'
    },
    inline: {
        display: 'flex',
        gap: '2px',
    }
}))

function MultipleAvatarsGroup({persons, ...props}) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);
    const avatarSize = ((props.size * 10) - 10 - 2) / 2

    if (!persons || !persons.length)
        return null

    return (
        <div className={classes.root}>
            <div className={classes.inline}>
                {persons[0] &&
                    <AvatarWithOccupancy person={persons[0]} size={avatarSize} hideAvailabilityStatus/>}
                {persons[1] &&
                    <AvatarWithOccupancy person={persons[1]} size={avatarSize} hideAvailabilityStatus/>}
            </div>
            <div className={classes.inline}>
                {persons[2] &&
                    <AvatarWithOccupancy person={persons[2]} size={avatarSize} hideAvailabilityStatus/>}
                {persons[3] && persons.length === 4 &&
                    <AvatarWithOccupancy person={persons[3]} size={avatarSize} hideAvailabilityStatus/>}
                {persons && persons.length > 4 &&
                    <Avatar style={{
                        width: avatarSize / 10 + 'rem',
                        height: avatarSize / 10 + 'rem',
                        color: theme.colors.palette.neutral.white,
                        fontSize: '.9rem',
                        backgroundColor: theme.colors.palette.corporate.skinMain
                    }}>{'+' + (persons.length - 3)}</Avatar>}
            </div>
        </div>
    )
}

MultipleAvatarsGroup.propTypes = {
    persons: PropTypes.array.isRequired,
    size: PropTypes.number,
}

MultipleAvatarsGroup.defaultProps = {
    size: 4.8
}

export default MultipleAvatarsGroup;