import React, {useEffect} from 'react';
import {connect} from "react-redux";

import {ICalendar} from "datebook";
import * as FileSaver from 'file-saver';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import LevelPanel from "./LevelPanel.js";
import CustomButton from "../CustomButton.js";
import StatisticsPanel from "./StatisticsPanel";
import ModalHandler from "../../common/elements/ModalHandler.js";
import TimeTrackingPanel from "./timeTracking/TimeTrackingPanel";
import SimpleListItem from "../../common/elements/SimpleListItem.js";
import LoadingIndicator from "../../common/elements/LoadingIndicator.js";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy.js";
import ChangeProfileImageOrColorModal from "./ChangeProfileImageOrColorModal.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";

import ExitSvgIcon from "../../common/icons/ExitSvgIcon.js";
import SettingsIconSvg from "../../common/icons/SettingsIconSvg.js";
import VacationSvgIcon from "../../common/icons/VacationSvgIcon.js";
import AnalyticsSvgIcon from "../../common/icons/AnalyticsSvgIcon.js";
import WorkingGroupSvgIcon from "../../common/icons/WorkingGroupSvgIcon.js";
import CalendarExportSvgIcon from "../../common/icons/CalendarExportSvgIcon.js";

import {setError} from "../../actions/error-actions.js";
import {getUserGameStatus, getUserRoles} from "../../actions/user-action.js";
import {getUserDistributionStatisticsAllTime, getUserMeetingStatistics} from "../../actions/dashboard-actions.js";

import {TYPE_OF_BOOKING_MEETING_ROOM} from "../../common/utils/NameUtils.js";
import {
    DOCUMENTATION_URL,
    navigate,
    PATH_ADMIN,
    PATH_HOME,
    PATH_PROFILE_SETTINGS,
    PATH_PROFILE_VACATION,
    PATH_WORKING_GROUPS
} from "../../common/utils/NavigationUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
        alignItems: 'center',
    },
    settingsContainer: {
        width: '100%',
    },
    buttonContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2rem',

    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap,
    },
    innerFooter: {
        display: 'flex',
        flexWrap: 'noWrap',
        gap: '1rem'
    },
    profilePictureAvatar: {
        width: '8rem',
        height: '8rem',
    },
    avatar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))

function ProfilePage({
                         userRoles,
                         getUserRoles,
                         uploadPicture,
                         user,
                         userId,
                         getUserGameStatus,
                         getUserDistributionStatistics,
                         getUserMeetingStatistics,
                         allTimeStatistics,
                         meetingStatistics,
                         setError,
                         assignments,
                         showTimeTracking
                     }) {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);

    const [profileImageModalOpen, setProfileImageModalOpen] = React.useState(false);

    useEffect(() => {
        if (userId) {
            getUserRoles()
            getUserGameStatus(userId)
            getUserDistributionStatistics()
            getUserMeetingStatistics()
        }
        //eslint-disable-next-line
    }, [userId])

    const isAdmin = () => {
        return userRoles && userRoles.includes('ROLE_ADMIN')
    }

    const exportAssignments = () => {
        let iCalendar = null
        if (assignments.length > 0) {
            for (let assignment of assignments) {
                if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_MEETING_ROOM) {
                    let event = {
                        title: assignment.title,
                        description: assignment.description + '\n' + assignment.onlineLink,
                        start: new Date(assignment.timePeriod.startDate),
                        end: new Date(assignment.timePeriod.endDate),
                        location: assignment.space.name,
                    }
                    if (iCalendar == null) {
                        iCalendar = new ICalendar(event)
                    } else {
                        let iCal = new ICalendar(event)
                        iCalendar.addEvent(iCal)
                    }
                }
            }

            const ics = iCalendar.render()
            const blob = new Blob([ics], {
                type: 'text/calendar'
            })

            FileSaver.saveAs(blob, 'meetings.ics')

        } else {
            setError(t("error_meetings_available"))
        }
    }

    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton title={t('profile')} backLabel={t("home")}
                                          onNavigateBack={() => navigate(history, PATH_HOME)}/>
            <div className={classes.avatar}>
                {uploadPicture
                    ? <LoadingIndicator/>
                    : <AvatarWithOccupancy person={user}
                                           size={80}
                                           hideAvailabilityStatus
                                           className={classes.profilePictureAvatar}
                                           onClick={() => setProfileImageModalOpen(true)}/>}

                <Typography variant={'body1'}
                            style={{fontWeight: 500, marginTop: theme.innerGap}}>{user.name} {user.surname}</Typography>
                <Typography variant={'caption'}>{user.email}</Typography>
            </div>
            <LevelPanel asCard/>
            <StatisticsPanel asCard withTitle statistics={{...allTimeStatistics, ...meetingStatistics}}/>

            {showTimeTracking ? <TimeTrackingPanel/> : null}

            <div className={classes.settingsContainer}>
                <SimpleListItem onClick={() => navigate(history, PATH_WORKING_GROUPS)} title={t('working_groups')}
                                topBorder={false}
                                icon={<WorkingGroupSvgIcon/>}/>
                {isAdmin() && <SimpleListItem title={t('admin_console')} icon={<AnalyticsSvgIcon/>}
                                              onClick={() => {
                                                  navigate(history, PATH_ADMIN)
                                              }}/>}
                <SimpleListItem onClick={() => navigate(history, PATH_PROFILE_VACATION)} title={t('vacation')}
                                icon={<VacationSvgIcon/>}/>
                <SimpleListItem onClick={() => navigate(history, PATH_PROFILE_SETTINGS)} title={t('settings')}
                                icon={<SettingsIconSvg/>}/>
            </div>
            <div className={classes.buttonContainer}>
                <CustomButton fullWidth
                              icon={<CalendarExportSvgIcon/>}
                              text={t('export_meetings')}
                              textColor={theme.colors.palette.neutral.darkMain}
                              onClick={() => exportAssignments()}/>

                <form action="/logout" method="post" style={{width: '100%'}}>
                    <CustomButton fullWidth
                                  icon={<ExitSvgIcon/>}
                                  text={t('logout')}
                                  textColor={theme.colors.palette.corporate.redMain}
                                  type={'submit'}/>
                </form>

            </div>

            <div className={classes.footer}>

                <Typography variant={'subtitle2'} color={'textSecondary'}>
                    {'made with'} &#10084; {' in Konstanz'}
                </Typography>

                <div className={classes.innerFooter}>
                    <Typography variant={'subtitle2'} color={'textSecondary'}>
                        {"Version " + process.env.REACT_APP_VERSION}
                    </Typography>

                    <Typography variant={'subtitle2'} color={'textSecondary'}
                                style={{textDecoration: 'underline', cursor: 'pointer'}}
                                onClick={() => {
                                    window.open(DOCUMENTATION_URL, "_blank")
                                }}>
                        {t('documentation')}
                    </Typography>
                </div>
                <ModalHandler onClose={() => setProfileImageModalOpen(false)}>
                    <ChangeProfileImageOrColorModal open={profileImageModalOpen}/>
                </ModalHandler>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.person,
        userId: state.user.person.id,
        userRoles: state.user.roles,
        uploadPicture: state.user.profilePictureLoading,
        allTimeStatistics: state.dashboard.userDistributionStatisticsAllTime,
        meetingStatistics: state.dashboard.userMeetingStatistics,
        assignments: state.user.userAssignments,
        showTimeTracking: state.userConfig.showTimeTracking,
    }
}

const mapDispatchToProps = {
    getUserRoles: getUserRoles,
    getUserGameStatus: getUserGameStatus,
    getUserDistributionStatistics: getUserDistributionStatisticsAllTime,
    getUserMeetingStatistics: getUserMeetingStatistics,
    setError: setError,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)