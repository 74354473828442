const initialState = {
    floorsList: [],
    selectedFloorId: null,
    floorPlanImages: [],
    uploadFloorplanPending: false,
    getFloorplanPending: false,
    getFloorPending: false,
    floor: null,
    error: null,
}

function floorsReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_BUILDING_FULFILLED':
            return {
                ...state,
                floorsList: action.payload.data.floors
            }

        case 'GET_FLOORS_FULFILLED':
            return {
                ...state,
                floorsList: action.payload.data
            }

        case 'GET_FLOOR_FULFILLED':
            return {
                ...state,
                floor: action.payload.data,
                getFloorPending: false
            }

        case 'GET_FLOOR_BY_SPACE_ID_PENDING':
        case 'GET_FLOOR_BY_WORKPLACE_ID_PENDING':
            return {
                ...state,
                getFloorPending: true,
            }

        case 'GET_FLOOR_BY_SPACE_ID_FULFILLED':
        case 'GET_FLOOR_BY_WORKPLACE_ID_FULFILLED':
            return {
                ...state,
                floor: action.payload.data,
                getFloorPending: false
            }

        case 'GET_FLOOR_BY_SPACE_ID_REJECTED':
        case 'GET_FLOOR_BY_WORKPLACE_ID_REJECTED':
            return {
                ...state,
                floor: null,
                getFloorPending: false
            }

        case 'GET_FLOOR_PENDING':
            return {
                ...state,
                getFloorPending: true
            }

        case 'GET_FLOOR_REJECTED':
            return {
                ...state,
                getFloorPending: false
            }

        case 'CREATE_FLOOR_FULFILLED':
            return {
                ...state,
                floorsList: [...state.floorsList, action.payload.data],
                selectedFloorId: action.payload.data.id
            }

        case 'SAVE_FLOOR_GEOMETRIES_FULFILLED': {
            let floors = [...state.floorsList]
            let floorIndex = floors.findIndex(floor => floor.id === action.payload.data.id)
            floors[floorIndex] = action.payload.data
            return {
                ...state,
                floorsList: [...floors],
                floor: action.payload.data
            }
        }

        case 'UPDATE_FLOOR_LEVELS_FULFILLED':
        case 'UPDATE_FLOORS_FULFILLED': {
            let floors = [...state.floorsList]
            for (let dataFloor of action.payload.data) {
                let floorIndex = floors.findIndex(floor => floor.id === dataFloor.id)
                floors[floorIndex] = dataFloor
            }
            return {
                ...state,
                floorsList: [...floors],
            }
        }

        case 'DELETE_FLOOR_FULFILLED':
            return {
                ...state,
                floorsList: [...state.floorsList.filter(floor => floor.id !== action.payload.data.id)],
                selectedFloorId: null
            }

        case 'GET_FLOORPLAN_IMAGE_FULFILLED':
            for (let item of state.floorPlanImages) {
                if (item.id === parseInt(action.meta)) {
                    item.floorplan = action.payload.data.image
                    item.imageScale = action.payload.data.scale

                    return {
                        ...state,
                        getFloorplanPending: false,
                        floorPlanImages: [...state.floorPlanImages.filter(image => image.id !== action.meta.floorId), {
                            id: parseInt(action.meta),
                            imageScale: action.payload.data.scale,
                            floorplan: action.payload.data.image
                        }]
                    }
                }
            }

            return {
                ...state,
                getFloorplanPending: false,
                floorPlanImages: [...state.floorPlanImages, {
                    id: parseInt(action.meta),
                    imageScale: action.payload.data.scale,
                    floorplan: action.payload.data.image
                }]
            }

        case 'GET_FLOORPLAN_IMAGE_PENDING':
            return {
                ...state,
                getFloorplanPending: true
            }

        case 'GET_FLOORPLAN_IMAGE_REJECTED':
            return {
                ...state,
                getFloorplanPending: false
            }

        case 'SET_UPLOAD_FLOORPLAN_PENDING':
        case 'UPLOAD_IMAGE_PENDING':
            return {
                ...state,
                uploadFloorplanPending: true
            }

        case 'UPLOAD_IMAGE_REJECTED':
            return {
                ...state,
                uploadFloorplanPending: false
            }

        case 'UPLOAD_IMAGE_FULFILLED':
            for (let item of state.floorPlanImages) {
                if (item.id === action.meta) {
                    item.floorplan = action.payload.data.image
                    return {
                        ...state,
                        uploadFloorplanPending: false,
                    }
                }
            }

            return {
                ...state,
                uploadFloorplanPending: false,
                floorPlanImages: [...state.floorPlanImages, {
                    id: action.meta,
                    floorplan: action.payload.data.image,
                    imageScale: action.payload.data.scale
                }]
            }

        case 'SET_FLOOR':
            return {
                ...state,
                floor: action.payload
            }

        case 'SET_BUILDING':
            if (!action.meta.reset)
                return {...state}

            let floor = action.payload?.floors ? action.payload.floors[0] : null
            return {
                ...state,
                floor: floor
            }

        case 'SET_IMAGE_SCALE_FULFILLED':
            let floors = [...state.floorsList]
            let floorIndex = floors.findIndex(floor => floor.id === action.payload.data.id)
            floors[floorIndex] = action.payload.data
            return {
                ...state,
                floorsList: [...floors],
                floor: action.payload.data,
            }

        case 'GET_HOMEZONE_FULFILLED':
        case 'SET_HOMEZONE_FULFILLED':
            return {
                ...state,
                floor: action.payload.data ? action.payload.data.floor : state.floor
            }

        default:
            return state

    }

}

export default floorsReducer
