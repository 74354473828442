import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import {IconButton, makeStyles, Tooltip, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import {getBackgroundColorDependingOnProps, getTextColorDependingOnProps} from "../common/utils/ColorUtils.js";
import Zoom from "@material-ui/core/Zoom";

const useStyles = (props, loadingColor) => makeStyles(theme => ({
    root: {
        width: '100%',
        flex: '1 1 auto',
        display: 'flex',
        boxSizing: 'border-box',
        height: '4.8rem',
        maxHeight: '4.8rem',
        borderRadius: '12px',
        border: props.primary || props.secondary ? null : `solid 1px ${props.outlined ? loadingColor : theme.colors.palette.neutral.greyMedium}`,
        backgroundColor: props.outlined ? loadingColor : theme.colors.palette.neutral.greyNeutral,
        boxShadow: props.outlined ? null : '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    },
    contentContainer: {
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        overflow: 'hidden',
        gap: '0.8rem',
    },
    icon: {
        width: '2.4rem',
        height: '2.4rem',
        overflow: 'hidden',
    },
    dotTyping: {
        position: 'relative',
        left: '-9999px',
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: '#fff',
        color: theme.colors.palette.neutral.white,
        boxShadow: '9984px 0 0 0 ' + loadingColor + ', 9999px 0 0 0 ' + loadingColor + ', 10014px 0 0 0 ' + loadingColor,
        animation: `$dotTyping 1.5s infinite linear`,
    },
    '@keyframes dotTyping': {
        '0%': {
            boxShadow: '9984px 0 0 0 ' + loadingColor + ', 9999px 0 0 0 ' + loadingColor + ', 10014px 0 0 0 ' + loadingColor,
        },
        '16.667%': {
            boxShadow: '9984px -10px 0 0 ' + loadingColor + ', 9999px 0 0 0 ' + loadingColor + ', 10014px 0 0 0 ' + loadingColor,
        },
        '33.333%': {
            boxShadow: '9984px 0 0 0 ' + loadingColor + ', 9999px 0 0 0 ' + loadingColor + ', 10014px 0 0 0 ' + loadingColor,
        },
        '50%': {
            boxShadow: '9984px 0 0 0 ' + loadingColor + ', 9999px -10px 0 0 ' + loadingColor + ', 10014px 0 0 0 ' + loadingColor,
        },
        '66.667%': {
            boxShadow: '9984px 0 0 0 ' + loadingColor + ', 9999px 0 0 0 ' + loadingColor + ', 10014px 0 0 0 ' + loadingColor,
        },
        '83.333%': {
            boxShadow: '9984px 0 0 0 ' + loadingColor + ', 9999px 0 0 0 ' + loadingColor + ', 10014px -10px 0 0 ' + loadingColor,
        },
        '100%': {
            boxShadow: '9984px 0 0 0 ' + loadingColor + ', 9999px 0 0 0 ' + loadingColor + ', 10014px 0 0 0 ' + loadingColor,
        }
    }
}));

function CustomButton({
                          textColor,
                          background,
                          icon,
                          text,
                          onClick,
                          fullWidth,
                          disabled,
                          halfWidth,
                          isLoading,
                          type,
                          customClassName,
                          tooltip,
                          variant,
                          ...props
                      }) {

    const theme = useTheme()
    const classes = useStyles(props, props.primary || props.secondary ? theme.colors.palette.neutral.white : textColor)(theme);

    const {primary, secondary} = props
    delete props.outlined
    delete props.innerClasses
    delete props.primary
    delete props.secondary

    textColor = getTextColorDependingOnProps(theme, disabled, primary, secondary, textColor)
    background = getBackgroundColorDependingOnProps(theme, disabled, primary, secondary, background)

    function handleOnClick(e) {
        if (e) {
            e.stopPropagation()
        }
        if (typeof onClick === 'function')
            onClick()
    }

    return (
        <Tooltip title={tooltip} TransitionComponent={Zoom}>
            <IconButton className={clsx(classes.root, customClassName)}
                        onClick={!isLoading ? handleOnClick : () => {
                        }}
                        disabled={disabled || isLoading}
                        type={type}
                        {...props}
                        style={{
                            background: background,
                            width: fullWidth ? '100%' : halfWidth ? '50%' : null,
                            ...props.style
                        }}>

                {isLoading ? <div className={classes.dotTyping}/> :
                    <div className={classes.contentContainer}>
                        {icon ? <div className={classes.icon}>
                            {React.cloneElement(icon, {color: textColor})}</div> : null}
                        {text ? <Typography variant={variant ?? 'body1'} style={{color: textColor}}
                                            noWrap>{text}</Typography> : null}
                    </div>
                }
            </IconButton>
        </Tooltip>
    );
}

CustomButton.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.element,
    textColor: PropTypes.string,
    background: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.any,
    type: PropTypes.string,
    customClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    outlined: PropTypes.bool,
    tooltip: PropTypes.string,
}

CustomButton.defaultProps = {
    type: 'button',
    background: '#ffffff',
    textColor: '#333333',
    tooltip: '',
}

export default CustomButton;