import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from "./ModalWrapper.js";
import ClickableText from "./ClickableText.js";
import {useTranslation} from "react-i18next";
import TimeperiodSelectionWithClocksAndTimeline from "./TimeperiodSelectionWithClocksAndTimeline.js";
import moment from "moment";

function CustomTimeperiodSelectionPopup({
                                            open,
                                            onClose,
                                            onSave,
                                            isLoading,
                                            initialTimeperiod,
                                            disabledIntervals,
                                            errorCallback,
                                            ...props
                                        }) {
    const {t} = useTranslation();

    const [startTime, setStartTime] = useState(moment(initialTimeperiod.startDate))
    const [endTime, setEndTime] = useState(moment(initialTimeperiod.endDate))
    const [saveDisabled, setSaveDisabled] = useState(false)

    return (
        <ModalWrapper open={open}
                      onClose={onClose}
                      headerActions={<ClickableText text={t('save')}
                                                    underlined
                                                    disabled={saveDisabled}
                                                    isLoading={isLoading}
                                                    onClick={() => {
                                                        onSave({startDate: moment(startTime), endDate: moment(endTime)})
                                                        onClose()
                                                    }}/>}>
            <div>
                <TimeperiodSelectionWithClocksAndTimeline setStartTime={setStartTime} startTime={startTime}
                                                          errorCallback={value => {
                                                              errorCallback(value)
                                                              setSaveDisabled(value)
                                                          }}
                                                          disabledIntervals={disabledIntervals}
                                                          setEndTime={setEndTime} endTime={endTime}/>
            </div>
        </ModalWrapper>
    );
}

CustomTimeperiodSelectionPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.any.isRequired,
    isLoading: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    initialTimeperiod: PropTypes.object.isRequired,
    disabledIntervals: PropTypes.array,
    errorCallback: PropTypes.func,
};

CustomTimeperiodSelectionPopup.defaultProps = {
    disabledIntervals: []
}

export default CustomTimeperiodSelectionPopup;