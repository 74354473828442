import * as React from "react"

function SliderZoomPlusSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <defs>
                <filter id="prefix__a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 0.596078 0 0 0 0 0.360784 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g
                filter="url(#prefix__a)"
                transform="translate(-326 -651)"
                fill="none"
                fillRule="evenodd"
            >
                <g stroke={props.stroke || '#FF985C'} strokeWidth={1.5} transform="translate(326 651)">
                    <rect width={18} height={18} x={6} y={6} rx={9}/>
                    <g strokeLinejoin="round">
                        <path d="M20.073 15H9.927M15 20.073V9.927"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SliderZoomPlusSvgIcon
