import axios from "axios";

//region resourceCategory
export function getAllResourceCategories() {
    return {
        type: 'GET_RESOURCE_CATEGORIES',
        payload: axios.get('/api/resources/category')
    }
}

export function createResourceCategory(categoryName) {
    return {
        type: 'CREATE_RESOURCE_CATEGORY',
        payload: axios.post('/api/resources/category', {}, {
            params: {
                name: categoryName
            }
        })
    }
}

export function updateResourceCategory(categoryId, categoryName) {
    return {
        type: 'UPDATE_RESOURCE_CATEGORY',
        payload: axios.patch(`/api/resources/category/${categoryId}`, {}, {
            params: {
                name: categoryName
            }
        }),
        meta: {
            updatedCategoryId: categoryId
        }
    }
}

export function deleteResourceCategory(categoryId) {
    return {
        type: 'DELETE_RESOURCE_CATEGORY',
        payload: axios.delete(`/api/resources/category/${categoryId}`)
    }
}

export function setSelectedResourceCategory(category) {
    return {
        type: 'SET_SELECTED_RESOURCE_CATEGORY',
        payload: category
    }
}

//endregion

//region resource

export function createResource(resource) {
    return {
        type: 'CREATE_RESOURCE',
        payload: axios.post('/api/resources', resource)
    }
}

export function updateResource(resourceId, resourceUpdate) {
    return {
        type: 'UPDATE_RESOURCE',
        payload: axios.patch(`/api/resources/${resourceId}`, resourceUpdate),
        meta: {
            updatedResourceId: resourceId
        }
    }
}

export function deleteResource(resourceId) {
    return {
        type: 'DELETE_RESOURCE',
        payload: axios.delete(`/api/resources/${resourceId}`)
    }
}

export function getResourceAssignments(resourceId, timePeriod) {
    return {
        type: 'GET_RESOURCE_ASSIGNMENTS',
        payload: axios.get(`/api/personResourceAssignment/resource/${resourceId}`, {
            params: {
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
            }
        })
    }
}

export function getBookableFreeResources(timePeriod, categoryId, buildingId, floorId) {
    return {
        type: 'GET_BOOKABLE_FREE_RESOURCES',
        payload: axios.get('/api/resources/bookable', {
            params: {
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
                floorId: floorId,
                buildingId: buildingId,
                categoryId: categoryId,
            }
        })
    }
}

export function assignResourceToUser(personId, assignment) {
    return {
        type: 'ASSIGN_RESOURCE_TO_USER',
        payload: axios.post(`/api/personResourceAssignment/${personId}`, assignment)
    }
}

export function deleteResourceAssignment(personId, assignmentId) {
    return {
        type: 'DELETE_RESOURCE_ASSIGNMENT',
        payload: axios.delete(`/api/personResourceAssignment/${personId}`, {
            params: {
                assignmentId: assignmentId
            }
        })
    }
}

export function updateResourceAssignment(personId, assignment) {
    return {
        type: 'UPDATE_RESOURCE_ASSIGNMENT',
        payload: axios.patch(`/api/personResourceAssignment/${personId}`, assignment)
    }
}

//endregion