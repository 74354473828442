import React from 'react';
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
    root: {
        width: '4.2rem',
        minWidth: '4.2rem',
        height: '4.2rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: '50%'
    },
    nonActive: {
        color: theme.colors.palette.neutral.black,
        background: theme.colors.palette.neutral.greyMedium,
    },
    active: {
        color: theme.colors.palette.neutral.white,
        background: theme.colors.gradients.skinMain,
    }
}))

function RoundedSelectionButton({title, active, onClick}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={clsx(classes.root, active ? classes.active : classes.nonActive)} onClick={onClick}>
            <Typography variant={'body1'} className={active ? classes.active : classes.nonActive}>{title}</Typography>
        </div>
    )
}

RoundedSelectionButton.propTypes = {
    title: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func
}

export default RoundedSelectionButton