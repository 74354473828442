import React from 'react';
import {Typography, withStyles} from "@material-ui/core";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import MeetingRoomEmptyStateSvg from "../../common/icons/MeetingRoomsEmptyStateSvg";

const styles = theme => ({
    emptyState: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.0rem',
        alignItems: 'center'
    },
})

class MeetingRoomEmptyState extends React.Component {

    render() {
        const {classes, t} = this.props;

        return (
            <div className={classes.emptyState}>
                <MeetingRoomEmptyStateSvg/>
                <Typography variant={'h5'}>
                    {t("all_meetingrooms_occupied")}
                </Typography>
                <Typography variant={'body1'}>
                    {t("modify_meeting")}
                </Typography>
            </div>
        )
    }
}

export default compose(withStyles(styles), withTranslation())(MeetingRoomEmptyState)