import React from 'react';
import PropTypes from 'prop-types';
import {Box, isWidthDown, makeStyles} from "@material-ui/core";
import {RadialChart} from "react-vis";
import withWidth from "@material-ui/core/withWidth";
import DonutLegendEntry from "../admin/Dashboard/charts/components/DonutLegendEntry.js";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyle = makeStyles(theme => ({
    chartContainer: {
        width: '100%',
        height: '100%',
    },
    chartContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        gap: theme.innerGap,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.innerSmallGap,
        boxSizing: 'border-box'
    },
    legendContainer: {
        display: 'flex',
        gap: theme.innerGap,
        justifyContent: 'space-evenly',
    },
}));

function PieChartWithLegend({width, chartData, legendPostfix}) {

    const theme = useTheme()
    const classes = useStyle(theme)

    const chartWidth = isWidthDown('sm', width) ? 130 : 160
    const chartRadius = isWidthDown('sm', width) ? 60 : 75

    return (
        <Box className={classes.chartContainer}>
            <div className={classes.chartContent}>

                <RadialChart width={chartWidth} height={chartWidth} data={chartData}
                             innerRadius={chartRadius * 0.75}
                             radius={chartRadius} colorType="literal"/>

                <div className={classes.legendContainer}>
                    {chartData ?
                        chartData.map(entry => {
                            return <DonutLegendEntry key={entry.label} title={entry.label}
                                                     value={Number(entry.value)}
                                                     postfix={legendPostfix}
                                                     icon={entry.icon}
                                                     color={entry.color}
                            />
                        }) : null
                    }
                </div>
            </div>
        </Box>
    );
}

PieChartWithLegend.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    headerTitle: PropTypes.string,
    title: PropTypes.string,
    legendPostfix: PropTypes.string,
    maxHeight: PropTypes.bool,
};

export default withWidth()(PieChartWithLegend)