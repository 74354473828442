import React from 'react';

import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import MultiSelectionDayWithTimePicker from "../../common/elements/MultiSelectionDayWithTimePicker.js";

import {setUserWorkingHours} from "../../actions/user-action.js";
import {PATH_PROFILE_SETTINGS} from "../../common/utils/NavigationUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        textAlign: 'left',
    },
}))

function WorkingDaysPage({userId, workingHours, setUserWorkingHours}) {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);

    const routeToAccount = () => {
        history.push(PATH_PROFILE_SETTINGS);
    }
    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton backLabel={t('settings')}
                                          title={t('working_days')}
                                          onNavigateBack={routeToAccount}/>
            <div className={classes.content}>
                <Typography variant={'body1'}>{t('working_days.info')}</Typography>
                <MultiSelectionDayWithTimePicker originalSelectedDays={workingHours}
                                                 setDaysAndTimes={(days) => {
                                                     setUserWorkingHours(userId, days)
                                                 }}/>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        workingHours: state.user.person.workingHours,
    }
}

const mapDispatchToProps = {
    setUserWorkingHours: setUserWorkingHours,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkingDaysPage)