import * as THREE from 'three'
import {Mesh, NearestFilter, Shape, ShapeGeometry} from 'three'

import {freeMemory} from "../three/common/memory";

import Talk from "../../../common/img/Talk.png";
import Calls from "../../../common/img/Calls.png";
import Creative from "../../../common/img/Creative.png";
import Undefined from "../../../common/img/Undefined.png";
import Concentrate from "../../../common/img/Concentrate.png";
import WorkplaceBlocked from "../../../common/img/icons-map-blocked@3x.png";

import fontIbm from '../../../common/fonts/IBM Plex Sans_Regular.json'

import {colors} from "../../../theme";

export const drawingColor = {
    scale: '#f58233',
    scaleHandler: '#f58233',

    outline: '#4a4a4a',
    outlineHandler: '#4a4a4a',

    vertex: '#f58233',
    edge: '#f58233',
    vertexHandler: '#f58233',
    resizeHandler: '#f58233',
    face: '#ffffff',

    door: '#63D184', //#C7F069

    wall: '#0f0faa',
    wallHandler: '#0f0faa',

    space: '#FF8C65',

    terminal: '#94A1BA',

    pillar: '#dccfa7',
    pillarHandler: '#dccfa7',

    workplaceConcentrate: colors.palette.corporate.skinMain,
    workplaceTalk: colors.palette.corporate.blueMain,
    workplaceCalls: colors.palette.indicator.greenMain,
    workplaceCreative: colors.palette.indicator.purpleMain,
    workplaceUndefined: colors.palette.indicator.pink,
    workplaceHomeoffice: colors.palette.indicator.yellowMain,
    workplaceOutOfOffice: colors.palette.indicator.plum,
    workplaceVacation: colors.palette.corporate.blueDarkest,

    blocked: '#2b2b2b',

    debug: '#ff00ff',
    disabled: '#e5e5e5',
    magicGuideLine: '#A47ECD',

    black: '#000',
    white: '#fff'
}

export const drawingSegments = {
    pillar: 32,
    vertex: 32,
    marker: 32,
    terminal: 6,
}

export const drawingOpacity = {
    scale: .5,

    outlineHandler: .6,

    vertex: .6,
    face: .1,
    resizeHandler: .6,

    backGroundImage: .2,
    backGroundImageHighlighted: .4,

    space: 1,
    spaceHandler: .2,

    terminalHandler: .2,

    wall: 1,
    wallHandler: .6,
    marker: .4,
    markerInner: .7,
    workplaceDisabled: .4,
    workplace: 1,

    transparent: 0,
    magicGuideLine: .7,
}

export const drawingSize = {
    markerMinSize: .4,
    iconMarkerSize: .6,
    edgeThickness: .05,
    outlineThickness: .1,
    edgeCatchThickness: 1,
    edgeHandlerSize: 1.0,
    edgeHandlerSizeSelected: 1.2,
    edgeHandlerSizeDeselected: .8,
    outlineHandlerSize: 1.2,
    outlineHandlerSizeSelected: 1.4,
    outlineHandlerSizeDeselected: 1.0,
    door: .7,
    window: .45,
    pillar: .5,
    terminal: .3,
    currentHereMarker: .24,
    workplace: .3,
    workplaceCatchplane: .6,
    workplaceLineThickness: .055,
    workplaceThinLineThickness: .025,
    magicGuideLineThickness: .02,
    magicGuideLineMax: 999999,
}

export const drawingTypes = {
    scale: 'SCALE',

    outline: 'OUTLINE',
    wall: 'WALL',

    door: 'DOOR',
    pillar: 'PILLAR',

    space: 'SPACE',
    terminal: 'TERMINAL',

    workplace: 'WORKPLACE',
    workplaceConcentrate: 'workplaceConcentrate',
    workplaceTalk: 'workplaceTalk',
    workplaceCalls: 'workplaceCalls',
    workplaceCreative: 'workplaceCreative',
    workplaceUndefined: 'workplaceUndefined',

    workplaceBlocked: 'workplaceBlocked',
    workplaceNotPermitted: 'workplaceNotPermitted',

    currentHereMarker: 'currentHereMarker',

    edge: 'edge',
    rectangle: 'rectangle',
}

export const orientation = {
    horizontal: 'horizontal',
    vertical: 'vertical',
    horVer: 'horVer',
}

const loader = new THREE.TextureLoader();

export const threeMaterial = {
    scale: new THREE.MeshBasicMaterial({color: drawingColor.scale, transparent: true}),
    scaleHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.scaleHandler,
        transparent: true,
        opacity: drawingOpacity.scale
    }),

    outline: new THREE.MeshBasicMaterial({color: drawingColor.outline}),
    outlineHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.outlineHandler,
        transparent: true,
        opacity: drawingOpacity.outlineHandler
    }),

    vertex: new THREE.MeshBasicMaterial({color: drawingColor.vertex}),
    vertexHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.vertexHandler,
        transparent: true,
        opacity: drawingOpacity.vertex
    }),

    edge: new THREE.MeshBasicMaterial({color: drawingColor.edge}),
    face: new THREE.MeshBasicMaterial({color: drawingColor.face, transparent: true, opacity: drawingOpacity.face}),
    resizeHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.resizeHandler,
        transparent: true,
        opacity: drawingOpacity.resizeHandler
    }),

    door: new THREE.MeshBasicMaterial({color: drawingColor.door}),
    doorHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.door,
        transparent: true,
        opacity: drawingOpacity.marker
    }),

    pillar: new THREE.MeshBasicMaterial({color: drawingColor.pillar}),
    pillarHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.pillarHandler,
        transparent: true,
        opacity: drawingOpacity.marker
    }),

    disabledMarker: new THREE.MeshBasicMaterial({
        color: drawingColor.disabled,
        transparent: true,
        opacity: drawingOpacity.marker
    }),
    disabledMarkerInner: new THREE.MeshBasicMaterial({
        color: drawingColor.disabled,
        transparent: true,
        opacity: drawingOpacity.markerInner
    }),
    currentHereMarker: new THREE.MeshBasicMaterial({
        color: drawingColor.door,
        transparent: false,
        opacity: .5,
    }),

    workplaceBlocked: new THREE.MeshBasicMaterial({map: loader.load(WorkplaceBlocked)}),
    workplaceNotPermitted: new THREE.MeshBasicMaterial({color: 'white'}),
    workplaceConcentrate: new THREE.MeshBasicMaterial({map: loader.load(Concentrate)}),
    workplaceTalk: new THREE.MeshBasicMaterial({map: loader.load(Talk)}),
    workplaceCalls: new THREE.MeshBasicMaterial({map: loader.load(Calls),}),
    workplaceCreative: new THREE.MeshBasicMaterial({map: loader.load(Creative),}),
    workplaceUndefined: new THREE.MeshBasicMaterial({map: loader.load(Undefined),}),
    workplaceBlockedHandler: new THREE.MeshBasicMaterial({
        map: loader.load(WorkplaceBlocked),
        transparent: true,
        opacity: drawingOpacity.workplace
    }),
    workplaceNotPermittedHandler: new THREE.MeshBasicMaterial({
        color: 'white',
        transparent: true,
        opacity: drawingOpacity.workplace
    }),
    workplaceConcentrateHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.workplaceConcentrate,
        transparent: true,
        opacity: drawingOpacity.workplace
    }),
    workplaceTalkHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.workplaceTalk,
        transparent: true,
        opacity: drawingOpacity.workplace
    }),
    workplaceCallsHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.workplaceCalls,
        transparent: true,
        opacity: drawingOpacity.workplace
    }),
    workplaceCreativeHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.workplaceCreative,
        transparent: true,
        opacity: drawingOpacity.workplace
    }),
    workplaceUndefinedHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.workplaceUndefined,
        transparent: true,
        opacity: drawingOpacity.workplace
    }),

    wall: new THREE.MeshBasicMaterial({color: drawingColor.wall, transparent: true, opacity: drawingOpacity.wall}),
    wallHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.wallHandler,
        transparent: true,
        opacity: drawingOpacity.wallHandler
    }),

    space: new THREE.MeshBasicMaterial({color: drawingColor.space, transparent: true, opacity: drawingOpacity.space}),
    spaceHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.space,
        transparent: true,
        opacity: drawingOpacity.spaceHandler
    }),

    terminal: new THREE.MeshBasicMaterial({
        color: drawingColor.terminal,
        transparent: true,
    }),
    terminalHandler: new THREE.MeshBasicMaterial({
        color: drawingColor.terminal,
        transparent: true,
        opacity: drawingOpacity.terminalHandler
    }),

    white: new THREE.MeshBasicMaterial({
        color: drawingColor.face,
        transparent: true,
        opacity: drawingOpacity.scale
    }),

    debug: new THREE.MeshBasicMaterial({color: drawingColor.debug}),
    transparent: new THREE.MeshBasicMaterial({transparent: true, opacity: drawingOpacity.transparent}),

    magicGuideLine: new THREE.MeshBasicMaterial({
        color: drawingColor.magicGuideLine,
        transparent: true,
        opacity: drawingOpacity.magicGuideLine
    }),
}

export function getDrawingTypesMaterial(type) {
    let innerMaterial = threeMaterial.transparent
    let material = threeMaterial.transparent
    switch (type) {
        case drawingTypes.outline:
            innerMaterial = threeMaterial.outline
            material = threeMaterial.outlineHandler
            break
        case drawingTypes.rectangle:
        case drawingTypes.space:
            innerMaterial = threeMaterial.vertex
            material = threeMaterial.vertexHandler
            break
        case drawingTypes.workplaceConcentrate:
            innerMaterial = threeMaterial.workplaceConcentrate
            material = threeMaterial.workplaceConcentrateHandler
            break
        case drawingTypes.workplaceBlocked:
            innerMaterial = threeMaterial.workplaceBlocked
            material = threeMaterial.workplaceBlockedHandler
            break
        case drawingTypes.workplaceNotPermitted:
            innerMaterial = threeMaterial.workplaceNotPermitted
            material = threeMaterial.workplaceNotPermitted
            break
        case drawingTypes.workplaceTalk:
            innerMaterial = threeMaterial.workplaceTalk
            material = threeMaterial.workplaceTalkHandler
            break
        case drawingTypes.workplaceCalls:
            innerMaterial = threeMaterial.workplaceCalls
            material = threeMaterial.workplaceCallsHandler
            break
        case drawingTypes.workplaceCreative:
            innerMaterial = threeMaterial.workplaceCreative
            material = threeMaterial.workplaceCreativeHandler
            break
        case drawingTypes.workplaceUndefined:
        case drawingTypes.workplace:
            innerMaterial = threeMaterial.workplaceUndefined
            material = threeMaterial.workplaceUndefinedHandler
            break
        case drawingTypes.door:
            innerMaterial = threeMaterial.door
            material = threeMaterial.doorHandler
            break
        case drawingTypes.pillar:
            innerMaterial = threeMaterial.pillar
            material = threeMaterial.pillarHandler
            break
        case drawingTypes.wall:
            innerMaterial = threeMaterial.wall
            material = threeMaterial.wallHandler
            break
        case drawingTypes.scale:
            innerMaterial = threeMaterial.scale
            material = threeMaterial.scaleHandler
            break
        case drawingTypes.terminal:
            material = threeMaterial.terminal
            break
        case drawingTypes.currentHereMarker:
            material = threeMaterial.currentHereMarker
            break
        default:
            break
    }
    return {innerMaterial: innerMaterial, material: material, inactive: threeMaterial.transparent}
}

const iconMaterialTypes = {
    door: drawingTypes.door,
    door_inactive: drawingTypes.door + '_inactive',
    pillar: drawingTypes.pillar,
    pillar_inactive: drawingTypes.pillar + '_inactive',
}

const doorMaterial = new THREE.MeshBasicMaterial({
    map: getIconTexture(iconMaterialTypes.door),
    transparent: true,
    opacity: 1,
    depthWrite: false
})
const doorInactiveMaterial = new THREE.MeshBasicMaterial({
    map: getIconTexture(iconMaterialTypes.door_inactive),
    transparent: true,
    opacity: 1,
    depthWrite: false
})
const pillarMaterial = new THREE.MeshBasicMaterial({
    map: getIconTexture(iconMaterialTypes.pillar),
    transparent: true,
    opacity: 1
})
const pillarInactiveMaterial = new THREE.MeshBasicMaterial({
    map: getIconTexture(iconMaterialTypes.pillar_inactive),
    transparent: true,
    opacity: 1
})

const iconMaterials = {
    door: doorMaterial.clone(),
    door_inactive: doorInactiveMaterial.clone(),
    pillar: pillarMaterial.clone(),
    pillar_inactive: pillarInactiveMaterial.clone(),
}

export function getDrawingTypesIconMaterial(iconMaterialType) {
    return {
        activeMaterial: iconMaterials[iconMaterialType],
        inactiveMaterial: iconMaterialType[iconMaterialType + '_inactive']
    }
}

function getIconTexture(iconMaterialType) {
    return
}

export function createMarker(radius = drawingSize.markerMinSize, material, innerMaterial, segments = drawingSegments.marker) {
    const markerGeometry = new THREE.CircleGeometry(radius, segments)
    const markerMesh = new THREE.Mesh(markerGeometry, material)
    markerMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))
    markerMesh.name = 'marker'

    const innerMarkerGeometry = getCircleGeometry(radius, segments)
    const innerMarkerMesh = new THREE.Mesh(innerMarkerGeometry, innerMaterial)

    markerMesh.add(innerMarkerMesh)

    return markerMesh
}

export function createCircleWithTextAndActiveMarker(radius, text, radians, showSurroundingIndicator, backgroundColor) {
    const geometry = getTextGeometry(text);

    let textMaterial = new THREE.MeshBasicMaterial({color: drawingColor.face}).clone();
    let childMesh = new THREE.Mesh(geometry, textMaterial);

    let newInnerMaterial = new THREE.MeshBasicMaterial({color: drawingColor.black, transparent: true}).clone()

    return createCircleWithActiveMarker(radius, newInnerMaterial, radians, showSurroundingIndicator, childMesh, backgroundColor)
}

export function getTextGeometry(text = 'Text', size = .16, lineHeight = 0.1) {
    const font = new THREE.Font(fontIbm);
    return new THREE.TextGeometry(text, {
        font: font,
        size: size,
        height: lineHeight,
    });
}

export function createCircleWithImageActiveMarker(radius, image, radians, showSurroundingIndicator, innerColor, backgroundColor) {
    let texture = loader.load(image)
    texture.minFilter = NearestFilter
    texture.magFilter = NearestFilter

    let newInnerMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        color: innerColor,
        transparent: true,
    }).clone()

    return createCircleWithActiveMarker(radius, newInnerMaterial, radians, showSurroundingIndicator, null, backgroundColor)
}

export function createCircleWithActiveMarker(radius, innerMaterial, radians, showSurroundingIndicator, childGeometryMesh, backgroundColor) {
    const wrapperGeometry = new THREE.CircleGeometry(radius, drawingSegments.marker)

    let imageMesh = createCircleWithImage(innerMaterial, radians ? radius - drawingSize.workplaceLineThickness : radius)

    const materialBackground = new THREE.MeshBasicMaterial({color: backgroundColor, opacity: 1}); // Set the background color here
    const transparentMaterial = new THREE.MeshBasicMaterial({transparent: true, opacity: 0}); // Set the background color here

    const imageBackgroundGeometry = new THREE.CircleGeometry(radians ? radius - drawingSize.workplaceLineThickness : radius, drawingSegments.marker)

    let imageBackgroundMesh = new THREE.Mesh(imageBackgroundGeometry, materialBackground.clone())
    imageBackgroundMesh.name = 'imageBackgroundMesh'

    let wrapperMesh = new THREE.Mesh(wrapperGeometry, transparentMaterial)
    wrapperMesh.name = 'wrapperMesh'

    imageMesh.add(imageBackgroundMesh)
    wrapperMesh.add(imageMesh)

    if (showSurroundingIndicator) {
        let m = new THREE.MeshBasicMaterial({
            color: 'white',
            opacity: 0.5,
            transparent: true,
        })
        let transparentCircle = new THREE.Mesh(createCirceledBorderByRadians(radius, Math.PI, 3 * Math.PI), m)
        transparentCircle.name = 'transparentCircle'
        imageMesh.add(transparentCircle)


        let mat = new THREE.MeshBasicMaterial({
            color: backgroundColor,
            opacity: 1,
            transparent: true,
        })
        for (let circle of radians) {
            let filledCircle = new THREE.Mesh(createCirceledBorderByRadians(radius, circle.startRadian, circle.endRadian), mat)
            filledCircle.name = 'filledCircle'
            imageMesh.add(filledCircle)
        }
    } else {
        let mat = new THREE.MeshBasicMaterial({
            color: innerMaterial.color,
            opacity: 1,
            transparent: true,
        })

        let filledCircle = new THREE.Mesh(createCirceledBorderByRadians(radius - drawingSize.workplaceLineThickness, 2 * Math.PI, Math.PI, drawingSize.workplaceThinLineThickness), mat)
        filledCircle.name = 'helperBorderCircle'
        let filledCircle2 = new THREE.Mesh(createCirceledBorderByRadians(radius - drawingSize.workplaceLineThickness, Math.PI, 0, drawingSize.workplaceThinLineThickness), mat)
        filledCircle2.name = 'helperBorderCircle2'
        imageMesh.add(filledCircle)
        imageMesh.add(filledCircle2)
    }

    let activeMarker = getCircleGeometry(radius / 4)
    let activeMarkerMesh = new THREE.Mesh(activeMarker, materialBackground.clone())
    activeMarkerMesh.name = 'activeMarker'
    activeMarkerMesh.position.set(0, 0, -0.01)
    wrapperMesh.add(activeMarkerMesh)

    if (childGeometryMesh) {
        childGeometryMesh.position.set(-0.15, -0.1, 0)
        imageMesh.add(childGeometryMesh)
    }

    wrapperMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))
    return wrapperMesh
}

export function createCirceledBorderByRadians(radius = drawingSize.workplace, startRadians, endRadians, lineThickness = drawingSize.workplaceLineThickness) {
    const face = new Shape()

    face.absarc(0, 0, radius, startRadians, endRadians, true)
    face.absarc(0, 0, radius - lineThickness, endRadians, startRadians, false)

    return new ShapeGeometry(face)
}

export function createCircleWithImage(innerMaterial, radius) {
    const geometry = new THREE.CircleGeometry(radius, drawingSegments.marker)
    let mesh = new Mesh(geometry, innerMaterial)
    mesh.name = 'circleMesh'

    return mesh
}

export function getCircleGeometry(radius, segments = drawingSegments.vertex) {
    return new THREE.CircleGeometry(radius, segments)
}

export function getIcon(material, width, height) {
    const iconGeometry = new THREE.PlaneBufferGeometry(width, height, 1, 1)
    const iconMesh = new THREE.Mesh(iconGeometry, material)
    iconMesh.name = 'icon'

    iconMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))

    return iconMesh
}

// 2D
export function createVertex(material = threeMaterial.transparent,
                             innerMaterial = threeMaterial.vertex,
                             handlerScale = 1,
                             handlerSize = drawingSize.edgeHandlerSize,
                             edgeThickness = drawingSize.edgeThickness) {
    const vertexGeometry = getCircleGeometry(handlerScale * handlerSize, drawingSegments.vertex)
    const vertexMesh = new THREE.Mesh(vertexGeometry, material)
    vertexMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))
    vertexMesh.edges = []
    vertexMesh.name = 'vertex'

    const innerVertexGeometry = getCircleGeometry(edgeThickness, drawingSegments.vertex)
    const innerVertexMesh = new THREE.Mesh(innerVertexGeometry, innerMaterial)

    vertexMesh.add(innerVertexMesh)

    return vertexMesh
}

export function createHexagon(size = drawingSize.terminal, segments = drawingSegments.terminal, material = threeMaterial.terminal) {
    const hexGeometry = new THREE.CircleGeometry(size, segments)
    return new THREE.Mesh(hexGeometry, material)
}

// 3D
export function createEdge(vertexA,
                           vertexB,
                           material = threeMaterial.edge,
                           edgeThickness = drawingSize.edgeThickness) {

    const scale = 1
    const path = new THREE.LineCurve3(vertexA.position, vertexB.position)
    const tubeGeometry = new THREE.TubeGeometry(path, 1, edgeThickness, 3)
    const tubeCatchGeometry = new THREE.TubeGeometry(path, 1, drawingSize.edgeCatchThickness * scale, 3)
    const edgeMesh = new THREE.Mesh(tubeGeometry, material)
    edgeMesh.name = 'edge'
    const edgeCatchMesh = new THREE.Mesh(tubeCatchGeometry, threeMaterial.transparent)
    edgeCatchMesh.name = 'edgeCatch'

    edgeMesh.add(edgeCatchMesh)
    edgeCatchMesh.position.set(0, -1, 0)
    edgeMesh.vertices = [vertexA.uuid, vertexB.uuid]

    if (!vertexA.edges.includes(edgeMesh.uuid))
        vertexA.edges.push(edgeMesh.uuid)
    if (!vertexB.edges.includes(edgeMesh.uuid))
        vertexB.edges.push(edgeMesh.uuid)

    return edgeMesh
}

export function createTubeGeometry(positionA, positionB, thickness) {
    const path = new THREE.LineCurve3(positionA, positionB)
    return new THREE.TubeGeometry(path, 1, thickness, 3)
}

// 2D
export function createFaceGeometry(vertices) {
    const face = new THREE.Shape()

    if (!vertices.length)
        return new THREE.BufferGeometry()

    face.moveTo(vertices[0].position.x, -vertices[0].position.z)

    for (let i = 1; i < vertices.length; i++) {
        face.lineTo(vertices[i].position.x, -vertices[i].position.z)
    }

    const faceGeometry = new THREE.ShapeGeometry(face)
    faceGeometry.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))


    return faceGeometry
}

export function insertChildIntoGroup(group, child, index) {
    const children = group.children

    group.children = []

    children.splice(index, 0, child)

    children.forEach(child => group.add(child))

    return group
}

export function removeItemFromArray(array, item) {
    let index = array.findIndex(i => i === item)
    if (index >= 0)
        array.splice(index, 1)
}

export function reorderGroup(group) {

    let list = []

    for (let i = group.children.length - 1; i >= 0; i--) {
        let element = group.children[i]
        group.remove(element)
        list.push(element)
    }

    list.forEach(element => group.add(element))
}

export function removeAllChildren(group) {
    for (let i = group.children.length; i >= 0; i--) {
        group.remove(group.children[i])
        freeMemory(group)
    }
}

// 2D
export function getBoundingBoxPositions(positions) {

    let left = Number.MAX_VALUE
    let right = -Number.MAX_VALUE
    let bottom = Number.MAX_VALUE
    let top = -Number.MAX_VALUE

    positions.forEach(position => {
        if (position.x < left) {
            left = position.x
        }
        if (position.x > right) {
            right = position.x
        }
        if (position.z < bottom) {
            bottom = position.z
        }
        if (position.z > top) {
            top = position.z
        }
    })

    return [
        new THREE.Vector3(left, 0.05, top),
        new THREE.Vector3(right, 0.05, top),
        new THREE.Vector3(right, 0.05, bottom),
        new THREE.Vector3(left, 0.05, bottom),
    ]

}

export function compareJSONGeometries(jsonObject0, jsonObject1) {
    if (jsonObject0.uuid !== jsonObject1.uuid) {
        return false
    }

    if (jsonObject0.type !== jsonObject1.type) {
        return false
    }

    if (jsonObject0.isValidate !== jsonObject1.isValidate) {
        return false
    }

    if (jsonObject0.error.length !== jsonObject1.error.length) {
        return false
    }

    if (jsonObject0.radius !== jsonObject1.radius) {
        return false
    }

    if ((jsonObject0.center && jsonObject1.center) && (!compareVertices(jsonObject0.center, jsonObject1.center))) {
        return false
    }

    if (!compareVertices(jsonObject0.vertices, jsonObject1.vertices)) {
        return false
    }

    return !((jsonObject0.edges && jsonObject1.edges) && (!compareVertices(jsonObject0.edges, jsonObject1.edges)))
}

export function compareVertices(vertices0, vertices1) {
    if (vertices0.length !== vertices1.length)
        return false

    let result = true

    vertices0.forEach((vertex, index) => {
        if (vertex[0] !== vertices1[index][0] || vertex[1] !== vertices1[index][1])
            result = false
    })

    return result
}