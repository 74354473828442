import * as THREE from "three";
import theme from "../../theme";

export function getRenderer() {
    const renderer = new THREE.WebGLRenderer({antialias: true})

    renderer.setClearColor(theme.colors.palette.neutral.greyNeutral)
    renderer.setPixelRatio(window.devicePixelRatio)

    return renderer
}

export function resize(mount, renderer, camera) {
    if (!mount || !renderer || !camera)
        return

    const {clientWidth, clientHeight} = mount.current

    if (camera) {
        camera.left = clientWidth / -16
        camera.right = clientWidth / 16
        camera.top = clientHeight / 16
        camera.bottom = clientHeight / -16
        camera.updateProjectionMatrix()
    }

    renderer.setSize(clientWidth, clientHeight)
}