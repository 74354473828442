import React, {Component, lazy, Suspense} from 'react';
import {Provider} from "react-redux";
import {CookiesProvider} from "react-cookie";
import {Route, Router, Switch} from 'react-router-dom'
import {withSentryRouting} from "@sentry/react";

import {ThemeProvider} from '@material-ui/core/styles';

import './il8n'
import store from './store'
import theme from "./theme";
import SnackbarContainer from "./components/Snackbar";
import AppContainer from "./components/AppContainer.js";
import InitialPasswordContainer from "./components/login/InitialPassword/InitialPasswordContainer.js";

import {PATH_ADMIN, PATH_INITIAL_PASSWORD, PATH_TERMINAL} from "./common/utils/NavigationUtils.js";

import 'moment/locale/es';
import 'moment/locale/de';

import './App.css';
import LoadingIndicator from "./common/elements/LoadingIndicator.js";

const AdminContainer = lazy(() => import("./components/admin/AdminContainer"));
const TerminalContainer = lazy(() => import("./components/terminal/TerminalContainer.js"))

// Create Custom Sentry Route component
const SentryRoute = withSentryRouting(Route);

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <div className="App">
                        <CookiesProvider>
                            <Suspense fallback={<LoadingIndicator/>}>
                                <Router history={this.props.history}>
                                    <Switch>
                                        <SentryRoute path={PATH_ADMIN} component={AdminContainer}/>
                                        <SentryRoute path={PATH_TERMINAL} component={TerminalContainer}/>
                                        <SentryRoute path={PATH_INITIAL_PASSWORD} component={InitialPasswordContainer}/>
                                        <SentryRoute component={AppContainer}/>
                                    </Switch>
                                    <SnackbarContainer/>
                                </Router>
                            </Suspense>
                        </CookiesProvider>
                    </div>
                </ThemeProvider>
            </Provider>
        )
    }
}


export default App;
