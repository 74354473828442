import * as React from 'react'

const DrawScaleSvgIcon = props =>
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fillRule="nonzero" stroke={props.stroke} fill="none">
            <path
                d="M2.63 16.062 16.067 2.627a1 1 0 0 1 1.414 0l4.243 4.243-14.85 14.849-4.242-4.243a1 1 0 0 1 0-1.414ZM5.813 17.123l3.182 3.182M8.641 14.294l3.182 3.182M11.47 11.466l3.182 3.182M14.298 8.638l3.182 3.181M17.127 5.81l3.182 3.181M7.934 16.416l1.768 1.767M10.763 13.587l1.767 1.768M13.591 10.759l1.768 1.768M16.42 7.93l1.767 1.768"/>
        </g>
    </svg>

export default DrawScaleSvgIcon