export const PATH_HOME = '/';
export const PATH_BOOKINGS = '/#bookings';
export const PATH_MEETING_OPEN = '/meeting/:meetingId';

export const PATH_LOGIN = '/login';
export const PATH_TERMINAL = '/terminal';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_RESET_PASSWORD = '/reset-password';
export const PATH_RESET_PASSWORD_SUCCESSFULL = '/reset-password-successfull';
export const PATH_INITIAL_PASSWORD = '/password/initial';

export const PATH_PROFILE = '/profile';
export const PATH_PROFILE_VACATION = PATH_PROFILE + '/vacation';
export const PATH_PROFILE_SETTINGS = PATH_PROFILE + '/settings';
export const PATH_PROFILE_LANGUAGE = PATH_PROFILE_SETTINGS + '/language';
export const PATH_PROFILE_INTEGRATIONS = PATH_PROFILE_SETTINGS + '/integrations';
export const PATH_PROFILE_INTEGRATIONS_EXCHANGE = PATH_PROFILE_SETTINGS + '/integrations/exchange';
export const PATH_PROFILE_PASSWORD = PATH_PROFILE_SETTINGS + '/change-password';
export const PATH_PROFILE_NOTIFICATION = PATH_PROFILE_SETTINGS + '/notification';
export const PATH_PROFILE_ACCOUNT = PATH_PROFILE + '/account';
export const PATH_PROFILE_HOMEZONE = PATH_PROFILE_ACCOUNT + '/homezone';
export const PATH_PROFILE_SHARING = PATH_PROFILE_ACCOUNT + '/share-settings';
export const PATH_PROFILE_WORKING_DAYS = PATH_PROFILE_ACCOUNT + '/working-days';

export const PATH_USER_STATISTICS = '/statistics';
export const PATH_USER_ACHIEVEMENTS = '/achievements';

export const PATH_USER_TIMETRACKINGS = '/time-trackings';

export const PATH_WORKING_GROUPS = '/profile/groups/';
export const PATH_WORKING_GROUP = '/profile/group/';
export const PATH_WORKING_GROUPS_CREATE = '/profile/group/create';

export const PATH_RATINGS = '/ratings';

export const PATH_OCCUPANCY = '/occupancy';

export const PATH_CHOOSE_SEAT = '/seat';
export const PATH_CHOOSE_SEAT_BUILDING = '/seat/building';
export const PATH_CHOOSE_SEAT_FLOOR = '/seat/floor';
export const PATH_CHOOSE_SEAT_SELECT = PATH_CHOOSE_SEAT + '/select';

export const PATH_SCAN_SEAT = '/scan/seat';

export const PATH_SCAN_QR = '/scan/qr';

export const PATH_BUILDING_SELECTION = '/select/building';
export const PATH_FLOOR_SELECTION = '/select/floor';
export const PATH_SPACE_SELECTION = '/select/space';

export const PATH_MEETING = '/meeting';
export const PATH_MEETING_SCAN = '/scan/meet';
export const PATH_MEETING_DETAILS = '/meeting/details';
export const PATH_MEETING_SUGGESTIONS = '/meeting/suggestions';

export const PATH_REMOTE = '/remote';

export const PATH_SOCIAL_BOOKING = '/social-booking';
export const PATH_SOCIAL_BOOKING_SELECT = PATH_SOCIAL_BOOKING + '/select';

export const PATH_BOOK_RESOURCE = '/resource';
export const PATH_RESOURCE_CATEGORY_SELECTION = PATH_BOOK_RESOURCE + '/category';
export const PATH_CHOOSE_RESOURCE = PATH_BOOK_RESOURCE + '/choose';

export const PATH_ADMIN = '/admin';
export const PATH_ADMIN_DASHBOARD = PATH_ADMIN + '/';
export const PATH_ADMIN_BUILDINGS = PATH_ADMIN + '/buildings';
export const PATH_ADMIN_RESOURCES = PATH_ADMIN + '/resources';
export const PATH_ADMIN_PEOPLE = PATH_ADMIN + '/people';
export const PATH_ADMIN_SETTINGS = PATH_ADMIN + '/settings';


export function navigate(history, path) {
    if (!history)
        return

    history.push(path)
}

export const meetingOpenRegex = /\/meeting\/[0-9]+/;

export const DOCUMENTATION_URL = 'https://docs.goconut.com/';

export const LOCALHOST = "localhost"

export const GOCONUT_DOMAIN_APPENDIX = ".goconut.app"
export const DEMO_DOMAIN = "demo" + GOCONUT_DOMAIN_APPENDIX
