import * as THREE from 'three'
import {drawingSize, orientation, threeMaterial} from './drawConstants'

function DrawStraight () {
  THREE.Mesh.apply(this, arguments)

  this.init = function (position, direction) {
    const edgeMesh = new THREE.Mesh(this.getStraightGeometry(position, direction), threeMaterial.magicGuideLine)

    this.edge = edgeMesh

    this.add(this.edge)
    this.name = 'magicalGuideline'

    this.scale.y = 0.01
    this.setActive(false)
  }

  this.update = function (position, direction) {
    this.edge.geometry.dispose()
    this.edge.geometry = this.getStraightGeometry(position, direction)
  }

  this.getStraightGeometry = function (position, direction) {
    let minPosition, maxPosition = null

    if (direction === orientation.vertical) {
      minPosition = new THREE.Vector3(position.x, .1, drawingSize.magicGuideLineMax)
      maxPosition = new THREE.Vector3(position.x, .1, -drawingSize.magicGuideLineMax)
    } else if (direction === orientation.horizontal) {
      minPosition = new THREE.Vector3(drawingSize.magicGuideLineMax, 0.1, position.z)
      maxPosition = new THREE.Vector3(-drawingSize.magicGuideLineMax, 0.1, position.z)
    }

    const path = new THREE.LineCurve3(minPosition, maxPosition)

    return new THREE.TubeGeometry(path, 1, drawingSize.magicGuideLineThickness)
  }

  this.setActive = function (active) {
    this.visible = active
  }
}

DrawStraight.prototype = Object.create(THREE.Mesh.prototype)
DrawStraight.prototype.constructor = DrawStraight
export { DrawStraight }