import React from 'react';

import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {navigate, PATH_LOGIN} from "../../../common/utils/NavigationUtils";
import CheckCircledSvgIcon from "../../../common/icons/CheckCircledSvgIcon.js";
import PropTypes from "prop-types";
import CustomButton from "../../CustomButton.js";

const useStyles = props => makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2rem'
    },
    buttons: {
        width: '100%',
        borderRadius: '8px',
    },
    underline: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    icon: {
        stroke: theme.colors.palette.corporate.skinMain,
        width: '127px',
        height: '127px',
    }
}));

function ForgotPasswordPage({className, actionButtonText, action, info, cancelAction, isLoading, ...props}) {

    const theme = useTheme()
    const {t} = useTranslation()
    const history = useHistory()
    const classes = useStyles(props)(theme);

    return (
        <Paper className={clsx(classes.root, className)}>
            <div className={classes.content}>
                <CheckCircledSvgIcon className={classes.icon}/>
                <Typography variant={'body1'}>
                    {info}
                </Typography>
            </div>
            <div className={classes.footer}>
                <CustomButton className={classes.buttons}
                              text={actionButtonText} primary
                              onClick={action}
                              isLoading={isLoading}
                />
                {cancelAction && <Typography variant={'body1'} className={classes.underline}
                                             onClick={() => navigate(history, PATH_LOGIN)}>
                    {t('cancel')}
                </Typography>}
            </div>
        </Paper>
    )

}

ForgotPasswordPage.propsTypes = {
    info: PropTypes.string.isRequired,
    actionButtonText: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    cancelAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
}

ForgotPasswordPage.defaultProps = {}

export default ForgotPasswordPage;