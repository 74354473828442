import React, {useEffect} from 'react';
import {useTheme} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import HomeSvgIcon from "../../common/icons/HomeSvgIcon.js";
import OutOfOfficeSvgIcon from "../../common/icons/OutOfOfficeSvgIcon.js";
import BuildingSvgIcon from "../../common/icons/BuildingSvgIcon.js";
import PieChartWithTitleAndLegend from "./PieChartWithTitleAndLegend.js";
import {getUserDistributionStatistics} from "../../actions/dashboard-actions.js";
import moment from "moment";
import PropTypes from "prop-types";
import LoadingIndicator from "../../common/elements/LoadingIndicator.js";
import {isEmpty} from "lodash";

function UserDistributionPieChart({
                                      t,
                                      startDate,
                                      endDate,
                                      getUserDistributionStatistics,
                                      statisticsPending,
                                      statistics,
                                  }) {
    const theme = useTheme()

    useEffect(() => {
        if (startDate && endDate) {
            getUserDistributionStatistics(moment(startDate).toDate(), moment(endDate).toDate())
        }
        //eslint-disable-next-line
    }, [endDate])

    const chartData = React.useMemo(
        () => {
            let totalOccupancies = statistics.company + statistics.out_of_office + statistics.homeoffice
            let totalOffice = statistics.company
            let totalOutOfOffice = statistics.out_of_office
            let totalHome = statistics.homeoffice

            return [
                {
                    label: t('company'),
                    angle: totalOccupancies === 0 ? 1 : totalOffice,
                    color: theme.colors.palette.corporate.skinMain,
                    value: totalOccupancies === 0 ? 0 : totalOffice,
                    icon: <BuildingSvgIcon width={18} height={18}/>
                },
                {
                    label: t('out_of_office'),
                    angle: totalOccupancies === 0 ? 1 : totalOutOfOffice,
                    color: theme.colors.palette.corporate.greenLightest,
                    value: totalOccupancies === 0 ? 0 : totalOutOfOffice,
                    icon: <OutOfOfficeSvgIcon width={24} height={24} stroke={theme.colors.palette.neutral.darkMain}/>
                },
                {
                    label: t('homeoffice') + '*',
                    angle: totalOccupancies === 0 ? 1 : totalHome,
                    color: theme.colors.palette.neutral.darkMain,
                    value: totalOccupancies === 0 ? 0 : totalHome,
                    icon: <HomeSvgIcon width={18} height={18}/>
                },
            ]
        },
        //eslint-disable-next-line
        [statistics]
    )

    return <div>
        {statisticsPending || isEmpty(statistics) ? <LoadingIndicator/> :
            <PieChartWithTitleAndLegend headerTitle={t('distribution')}
                                        title={t('distribution_headline_personal')}
                                        chartData={chartData}
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxHeight/>
        }
    </div>
}

UserDistributionPieChart.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        statistics: state.dashboard.userDistributionStatistics,
        statisticsPending: state.dashboard.userDistributionStatisticsPending,
    }
}

const mapDispatchToProps = {
    getUserDistributionStatistics: getUserDistributionStatistics,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserDistributionPieChart));