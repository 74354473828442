import * as React from "react";

import useTheme from "@material-ui/core/styles/useTheme";


function MeetingRoomEmptyStateSvg(props) {

    const theme = useTheme()

    return (
        <svg width={330} height={215} viewBox="0 0 330 215"{...props}>
            <defs>
                <filter id="prefix__a" width="113.4%" height="124.1%" x="-6.7%" y="-12.1%"
                        filterUnits="objectBoundingBox">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2.5}/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                                   values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.847058824 0 0 0 1 0"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <filter id="prefix__b" width="112.3%" height="122.1%" x="-6.2%" y="-11%"
                        filterUnits="objectBoundingBox">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={3}/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                                   values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.847058824 0 0 0 1 0"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g filter="url(#prefix__a)">
                    <rect width={199.016} height={110.016} x={0.992} y={0.992} fill="#FAFAFA" stroke="#E5E5E5"
                          strokeWidth={1.984} rx={9.856}/>
                    <path fill="#B2B2B2"
                          d="M16.25 12.885h45.788a3.315 3.315 0 013.314 3.315v4.273a3.315 3.315 0 01-3.314 3.315H16.25a3.315 3.315 0 01-3.314-3.315V16.2a3.315 3.315 0 013.314-3.315z"
                    />
                    <rect width={63.683} height={10.903} x={12.936} y={31.717} fill="#E5E5E5" rx={3.315}/>
                    <rect width={22.886} height={10.903} x={12.936} y={44.602} fill="#E5E5E5" rx={3.315}/>
                    <path fill="#FFF" stroke="#E5E5E5" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.992}
                          d="M47.779 65.561v28.41M117.525 65.561v28.41M82.926 65.561v28.41M152.672 65.561v28.41M187.27 65.561v28.41M13.18 65.561v28.41M30.754 65.561v28.41M169.697 65.561v28.41M100.5 65.561v28.41M65.352 65.561v28.41M135.098 65.561v28.41"
                          opacity={0.487}
                    />
                    <path fill="#E5E5E5"
                          d="M13.69 77.247h23.394c1.21 0 2.19.98 2.19 2.19v5.454c0 1.21-.98 2.19-2.19 2.19H13.69v-9.834zM186.758 77.247H85.597c-1.21 0-2.19.98-2.19 2.19v5.454c0 1.21.98 2.19 2.19 2.19h101.16v-9.834z"
                    />
                    <path fill={theme.colors.palette.corporate.greenMain}
                          d="M41.465 72.117h38.76c1.209 0 2.19.98 2.19 2.19v5.454a2.19 2.19 0 01-2.19 2.19h-38.76a2.19 2.19 0 01-2.19-2.19v-5.454c0-1.21.98-2.19 2.19-2.19z"
                    />
                </g>
                <g filter="url(#prefix__b)" transform="translate(70 70)">
                    <rect width={257.428} height={142.428} x={1.286} y={1.286} fill="#FAFAFA" stroke="#E5E5E5"
                          strokeWidth={2.572} rx={12.78}/>
                    <rect width={163.465} height={14.115} x={16.733} y={16.681} fill="#B2B2B2" rx={4.298}/>
                    <rect width={82.376} height={14.115} x={16.733} y={41.062} fill="#E5E5E5" rx={4.298}/>
                    <rect width={29.604} height={14.115} x={16.733} y={57.743} fill="#E5E5E5" rx={4.298}/>
                    <path fill="#FFF" stroke="#E5E5E5" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.286}
                          d="M61.803 84.878v36.781M152.022 84.878v36.781M107.268 84.878v36.781M197.486 84.878v36.781M242.24 84.878v36.781M17.049 84.878v36.781M39.781 84.878v36.781M219.508 84.878v36.781M130 84.878v36.781M84.536 84.878v36.781M174.754 84.878v36.781"
                          opacity={0.487}
                    />
                    <path fill="#E5E5E5"
                          d="M17.708 100.007h63.35a2.84 2.84 0 012.84 2.84v7.051a2.84 2.84 0 01-2.84 2.84h-63.35v-12.731zM241.576 100.007h-40.607a2.84 2.84 0 00-2.84 2.84v7.051a2.84 2.84 0 002.84 2.84h40.608v-12.731z"
                    />
                    <path fill={theme.colors.palette.corporate.greenMain}
                          d="M88.019 93.366h105.987a2.84 2.84 0 012.84 2.84v7.052a2.84 2.84 0 01-2.84 2.84H88.02a2.84 2.84 0 01-2.84-2.84v-7.052a2.84 2.84 0 012.84-2.84z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default MeetingRoomEmptyStateSvg
