import React from 'react';
import QRCodeGenerator from "./QRCodeGenerator.js";
import {makeStyles, Typography, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.innerGap
    },
    qrSection: {
        display: 'flex',
        gap: theme.innerGap,
        flexDirection: 'column'
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
        gap: theme.innerGap
    }
}));

function QRCodesToExportSectionWithName({elements, title}) {
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={classes.root}>
            {elements.length &&
                <div className={classes.qrSection}>
                    {title && <Typography variant={'h4'}>{title}</Typography>}
                    <div className={classes.grid}>
                        {elements.map((space, index) => {
                            return <QRCodeGenerator scannedObject={space} key={index}/>
                        })}
                    </div>
                </div>}
        </div>
    );
}

QRCodesToExportSectionWithName.propTypes = {
    elements: PropTypes.array.isRequired,
    title: PropTypes.string,
}

export default QRCodesToExportSectionWithName