const initialState = {
    exportTimetrackingPending: false,
}

function timetrackingReducer(state = initialState, action) {
    switch (action.type) {
        case 'TIME_TRACKING_EXPORT_PENDING':
            return {
                ...state,
                exportTimetrackingPending: true
            }
        case 'TIME_TRACKING_EXPORT_FULFILLED':
        case 'TIME_TRACKING_EXPORT_REJECTED':
            return {
                ...state,
                exportTimetrackingPending: false
            }

        default:
            return {...state}
    }
}

export default timetrackingReducer;