import React, {useEffect, useRef} from 'react';
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ActionListItem from "../../common/elements/ActionListItem";
import Divider from "@material-ui/core/Divider";
import {getCoworkersWithAssignmentAtDateFiltered, selectCoworker} from "../../actions/user-action";
import LoadingIndicator from "../../common/elements/LoadingIndicator";
import InfiniteLoaderList from "../../common/elements/InfiniteLoaderList.js";
import EmptyStateColleagueBookings from "../occupancy/EmptyStateColleagueBookings.js";
import HomeSvgIcon from "../../common/icons/HomeSvgIcon.js";
import PropTypes from "prop-types";
import {sortAssignments} from "../../common/utils/TimeUtils.js";
import {navigate, PATH_SOCIAL_BOOKING_SELECT} from "../../common/utils/NavigationUtils.js";
import {selectFavoriteWorkplace} from "../../actions/workplaceAssignment-action.js";
import {getBuildings, setBuilding} from "../../actions/buildings-actions.js";
import {setFloor} from "../../actions/floors-actions.js";
import {useHistory} from "react-router-dom";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy.js";
import {isHomeoffice, isNotInOffice, isRemote, isVacation} from "../../common/utils/AssignmentUtils.js";
import moment from "moment";
import {isNull} from "lodash";
import VacationSvgIcon from "../../common/icons/VacationSvgIcon.js";
import OutOfOfficeSvgIcon from "../../common/icons/OutOfOfficeSvgIcon.js";
import useTheme from "@material-ui/core/styles/useTheme";

const styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    emptyState: {
        width: '100%',
        margin: 'auto'
    },
    loadingIndicator: {
        paddingTop: '2.4rem'
    }
})

function ChooseColleagueDetails({
                                    classes,
                                    t,
                                    userId,
                                    filteredCoworkersWithAssignmentsToken,
                                    coworkersPending,
                                    filteredCoworkersWithAssignments,
                                    getCoworkersWithAssignmentAtDateFiltered,
                                    selectCoworker,
                                    selectedDate,
                                    searchString,
                                    setFavoriteWorkplace,
                                    getBuildings,
                                    setBuilding,
                                    setFloor,
                                    buildings,
                                    ...props
                                }) {
    const history = useHistory()
    const timer = useRef(0)
    const theme = useTheme()

    useDidMountEffect(() => {
        if (!userId)
            return
        loadNextPage(true, true)
        // eslint-disable-next-line
    }, [selectedDate, userId]);

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
            loadNextPage(true, true)
        }, 500)
        // eslint-disable-next-line
    }, [searchString]);

    function loadNextPage(resetPaginationToken, forceLoading) {
        if (!userId || (coworkersPending && !forceLoading))
            return

        let startDate = moment(selectedDate).startOf('day')
        let endDate = moment(selectedDate).endOf('day')

        getCoworkersWithAssignmentAtDateFiltered(userId, startDate, endDate, searchString, filteredCoworkersWithAssignmentsToken, resetPaginationToken)
    }

    function getItem([, assignments]) {
        const coworker = assignments[0].person
        const hideArrow = isNotInOffice(assignments[0]) &&
            (assignments.length === 1 || isNotInOffice(assignments[1]))
        const endAdornment = hideArrow && assignments[0]
            ? isVacation(assignments[0])
                ? <VacationSvgIcon height={32} width={32}/>
                : isHomeoffice(assignments[0])
                    ? <HomeSvgIcon height={32} width={32}/>
                    : <OutOfOfficeSvgIcon height={32} width={32} stroke={theme.colors.palette.neutral.black}/>
            : null

        return <ActionListItem startAdornment={<AvatarWithOccupancy person={coworker} key={coworker.id}/>}
                               text={coworker.name + " " + coworker.surname}
                               action={() => onCoworkerSelection(coworker)}
                               endAdornment={endAdornment}
                               disabled={!!endAdornment}
                               avatarHeight={40}
                               hideArrow={hideArrow}
                               key={coworker.id}/>
    }

    const onCoworkerSelection = (coworker) => {
        selectCoworker(coworker)
        const sortedAssignments = sortAssignments(filteredCoworkersWithAssignments[coworker.id])
        let assignment

        if (!isRemote(sortedAssignments[0]))
            assignment = sortedAssignments[0]
        else if (!isRemote(sortedAssignments[1]))
            assignment = sortedAssignments[1]

        if (!buildings || !buildings.length) {
            getBuildings()
                .then((res) => {
                    routeToFloorplan(res.value.data, assignment)
                })
        } else {
            routeToFloorplan(buildings, assignment)
        }
    }

    function routeToFloorplan(buildings, assignment) {
        const selectedBuilding = buildings.find(building => building.floors.some(floor => floor.id === assignment.workplace.floorId))
        setBuilding(selectedBuilding)
        const selectedFloor = selectedBuilding.floors.find(floor => floor.id === assignment.workplace.floorId)
        setFloor(selectedFloor)
        setFavoriteWorkplace(assignment.workplace)

        navigate(history, PATH_SOCIAL_BOOKING_SELECT)
    }

    return (
        <div className={classes.root}>
            <Divider/>
            {coworkersPending
                ? <LoadingIndicator className={classes.loadingIndicator}/>
                : <InfiniteLoaderList items={Object.entries(filteredCoworkersWithAssignments)}
                                      hasNextPage={!isNull(filteredCoworkersWithAssignmentsToken)}
                                      isNextPageLoading={!!coworkersPending}
                                      loadNextPage={loadNextPage}
                                      emptyState={<EmptyStateColleagueBookings/>}
                                      listItem={getItem}/>
            }
        </div>
    )
}

ChooseColleagueDetails.propTypes = {
    searchString: PropTypes.string
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        filteredCoworkersWithAssignments: state.user.filteredCoworkersWithAssignments,
        filteredCoworkersWithAssignmentsToken: state.user.filteredCoworkersWithAssignmentsToken,
        coworkersPending: state.user.coworkersPending,
        selectedDate: state.assignmentBuilder.selectedDate,
        buildings: state.buildings.buildingList,
    }
}

const mapDispatchToProps = {
    getCoworkersWithAssignmentAtDateFiltered: getCoworkersWithAssignmentAtDateFiltered,
    selectCoworker: selectCoworker,
    setFavoriteWorkplace: selectFavoriteWorkplace,
    getBuildings: getBuildings,
    setBuilding: setBuilding,
    setFloor: setFloor,
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(ChooseColleagueDetails))