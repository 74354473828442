import axios from 'axios'
import {getAssignmentsOfUser} from "./user-action.js";

export function createVacation(personId, timePeriod) {

    let vacation = {
        timePeriod: timePeriod,
    }

    return {
        type: 'CREATE_VACATION',
        payload: axios.post(`/api/vacation/${personId}`, vacation)
    }
}

export function createVacationAndLoadAssignments(personId, timePeriod) {

    return (dispatch) => {
        return dispatch(createVacation(personId, timePeriod))
            .then(() => dispatch(getAssignmentsOfUser(personId, false, true)))
    }
}

export function fetchVacationOfPersonAtTime(personId, startDate, endDate) {
    return {
        type: 'FETCH_VACATION',
        payload: axios.get(`/api/vacation/${personId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
            }
        })
    }
}