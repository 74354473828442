import React from 'react';
import PropTypes from 'prop-types';

import {isEmpty} from "lodash";

import {makeStyles, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: '1.7rem',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '3px',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

function ActivityExplanation({icon, title, description}) {

    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                {icon}
            </div>
            <div className={classes.textContainer}>
                <Typography variant={'h5'}>{title}</Typography>
                {!isEmpty(description) ?
                    <Typography variant={'h4'}>
                        {description}
                    </Typography>
                    : null}
            </div>
        </div>
    );
}

ActivityExplanation.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
};

ActivityExplanation.defaultProps = {
    title: 'Activity Title',
};

export default ActivityExplanation;