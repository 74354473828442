import * as React from "react"
import useTheme from "@material-ui/core/styles/useTheme.js";

function NavigationBarBackgroundHomescreenSvg({r, ...props}) {
    const theme = useTheme()

    return (
        <svg
            viewBox="0 0 300 323"
            preserveAspectRatio="xMidYMax slice"
            {...props}
        >
            <defs>
                <filter id="a_header">
                    <feGaussianBlur in="SourceGraphic"/>
                </filter>
            </defs>
            <rect
                fill={theme.colors.palette.corporate.greenDark}
                width={300}
                height={323}
                filter="url(#a_header)"
                rx={r || 22}
                ry={r || 22}
            />
            <path fill={theme.colors.palette.corporate.greenDark} d="M0 0h414v30H0z"/>
        </svg>
    )
}

export default NavigationBarBackgroundHomescreenSvg
