import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef} from 'react'
import Measure from 'react-measure'

import {resize} from './Renderer'
import {cleanUp, setUp} from './SetUp'
import {useMediaQuery, withStyles} from "@material-ui/core";
import {addDrawingObjects, clearDrawingObjects} from "./DrawingObjects.js";
import {Raycaster, Vector2, Vector3} from "three";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import {addOrUpdateBackgroundImage} from "./Background.js";
import useTheme from "@material-ui/core/styles/useTheme";
import {
    DRAWING_REPRESENTATION_TYPE_SPACE,
    DRAWING_REPRESENTATION_TYPE_TERMINAL,
    DRAWING_REPRESENTATION_TYPE_WORKPLACE
} from "../../common/utils/NameUtils.js";
import store from "../../store.js";
import {selectFavoriteWorkplace} from "../../actions/workplaceAssignment-action.js";
import {selectCoworker} from "../../actions/user-action.js";
import {DrawWorkplace} from "../drawingTool/drawing/DrawWorkplace.js";
import {setSelectedSpace} from "../../actions/space-actions.js";
import {setSelectedTerminal} from "../../actions/terminal-actions.js";
import {SpaceObject} from "./drawingObjects/SpaceObject.js";
import {DrawTerminal} from "../drawingTool/drawing/DrawTerminal.js";
import {mouseActionTypes} from "../drawingTool/EditOutlinePanel.js";

const styles = theme => ({
    floorplaner: {
        width: '100%',
        height: '100%'
    },
    canvasContainer: {
        width: '100%',
        height: '100%',
    }
})

function FloorPlaner(props, ref) {
    let {classes} = props
    const mount = useRef(null)
    const rendererRef = useRef(null)
    const cameraRef = useRef(null)
    const orbitControlsRef = useRef(null)
    const initialZoomRef = useRef(null)
    const zoomFactor = useRef(null)
    const sceneRef = useRef(null)

    const theme = useTheme()
    const xxxl = useMediaQuery(theme.breakpoints.up('xxxl'));

    let currentDrawObject
    let threshold = 5
    let mouseDownPosition = {x: null, y: null}
    const raycaster = new Raycaster(new Vector3(0, 0, 20), new Vector3(0, 0, -1), 0, 100)

    const {onZoomChange} = props

    useEffect(() => {
        const {
            scene,
            camera,
            orbitControls,
            renderer,
            frameId,
        } = setUp(mount, props.image, props.drawingObjects, initialZoomRef, props.imageScale, props.onZoomChange, xxxl ? 12 : 10)

        rendererRef.current = renderer
        cameraRef.current = camera
        orbitControlsRef.current = orbitControls
        zoomFactor.current = .5
        sceneRef.current = scene

        return () => {
            cleanUp(mount, renderer, frameId, props.drawingObjects, sceneRef.current)
            store.dispatch(selectCoworker(null))
            store.dispatch(setSelectedSpace(null))
            store.dispatch(setSelectedTerminal(null))
            store.dispatch(selectFavoriteWorkplace(null))
        }
        // eslint-disable-next-line
    }, [])

    useDidMountEffect(() => {
        clearDrawingObjects(sceneRef.current)
        addDrawingObjects(sceneRef.current, props.drawingObjects)

        return () => {
            clearDrawingObjects(sceneRef.current)
        }
        // eslint-disable-next-line
    }, [props.drawingObjects])

    useEffect(() => {
        if (orbitControlsRef.current)
            orbitControlsRef.current.maxZoom = xxxl ? 16 : 10
    }, [xxxl]);

    useDidMountEffect(() => {
        addOrUpdateBackgroundImage(sceneRef.current, props.image, props.imageScale, mount, cameraRef.current, initialZoomRef, orbitControlsRef.current)
    }, [props.image])

    useImperativeHandle(ref, () => ({
        zoomIn() {
            if (cameraRef.current.zoom + zoomFactor.current > orbitControlsRef.current.maxZoom)
                cameraRef.current.zoom = orbitControlsRef.current.maxZoom
            else
                cameraRef.current.zoom = cameraRef.current.zoom + zoomFactor.current;

            cameraRef.current.updateProjectionMatrix()

            if (typeof onZoomChange === 'function')
                onZoomChange(cameraRef.current.zoom)

        },
        zoomOut() {
            if (cameraRef.current.zoom - zoomFactor.current < orbitControlsRef.current.minZoom)
                cameraRef.current.zoom = orbitControlsRef.current.minZoom
            else
                cameraRef.current.zoom = cameraRef.current.zoom - zoomFactor.current;

            cameraRef.current.updateProjectionMatrix()

            if (typeof onZoomChange === 'function')
                onZoomChange(cameraRef.current.zoom)

        },
        resetZoom() {
            cameraRef.current.zoom = initialZoomRef.current;
            cameraRef.current.position.x = 0
            cameraRef.current.position.y = 0
            orbitControlsRef.current.target.x = 0
            orbitControlsRef.current.target.y = 0
            cameraRef.current.updateProjectionMatrix()
        },
        centerCameraOnObject(position) {
            if (!cameraRef.current)
                return
            cameraRef.current.zoom = orbitControlsRef.current.maxZoom;
            cameraRef.current.position.x = position.x
            cameraRef.current.position.y = position.y
            orbitControlsRef.current.target.x = position.x
            orbitControlsRef.current.target.y = position.y
            cameraRef.current.updateProjectionMatrix()
        },
        getZoomValue() {
            return cameraRef.current.zoom
        },
    }), [onZoomChange])

    function onDocumentMouseDown(event) {
        if (event.button === 0 && event.detail === 1) {
            mouseDownPosition = {x: event.x, y: event.y}
        }

        if (typeof props.onClick === 'function') {
            props.onClick(null)
        }
    }

    const onDocumentMouseUp = useCallback((event) => {
        if (event.button === 0 && event.detail === 1) {
            //fire only if mouse didn't move since mouseDown
            if (Math.abs(event.x - mouseDownPosition.x) <= threshold && Math.abs(event.y - mouseDownPosition.y) <= threshold) {
                onLeftMouseUp(event)
            }
        }
        // eslint-disable-next-line
    }, [props.drawingObjects])

    useEffect(() => {
        const mountCurr = mount.current
        mountCurr.addEventListener(mouseActionTypes.mouseDown, onDocumentMouseDown)
        mountCurr.addEventListener(mouseActionTypes.mouseUp, onDocumentMouseUp)

        return () => {
            mountCurr.removeEventListener(mouseActionTypes.mouseDown, onDocumentMouseDown);
            mountCurr.removeEventListener(mouseActionTypes.mouseUp, onDocumentMouseUp);
        };
        // eslint-disable-next-line
    }, [onDocumentMouseUp]);

    function onLeftMouseUp(event) {
        if (currentDrawObject) {
            currentDrawObject.setActive(false)
        }

        let x = ((event.clientX - mount.current.offsetLeft) / mount.current.clientWidth) * 2 - 1
        let y = -(((event.clientY - mount.current.offsetTop) / mount.current.clientHeight) * 2 - 1)
        raycaster.setFromCamera(new Vector2(x, y), cameraRef.current)

        let selectedElement = getSelectedElement()
        if (selectedElement && selectedElement.drawingRepresentationType === DRAWING_REPRESENTATION_TYPE_WORKPLACE) {
            onSelectWorkplace(selectedElement)
        } else if (selectedElement && selectedElement.drawingRepresentationType === DRAWING_REPRESENTATION_TYPE_SPACE) {
            onSelectSpace(selectedElement)
        } else if (selectedElement && selectedElement.drawingRepresentationType === DRAWING_REPRESENTATION_TYPE_TERMINAL) {
            onSelectTerminal(selectedElement)
        } else {
            currentDrawObject = null
        }

        if (selectedElement && typeof props.onClick === 'function') {
            props.onClick({position: {x: event.clientX, y: event.clientY}, element: currentDrawObject})
        }
    }

    function onSelectWorkplace(selectedElement) {
        if (selectedElement && currentDrawObject && selectedElement.geometryId === currentDrawObject.geometryId) {
            store.dispatch(selectFavoriteWorkplace(null))
            store.dispatch(selectCoworker(null))
            currentDrawObject = null
        } else if (selectedElement && selectedElement.geometryId) {
            selectedElement.setActive(true)
            currentDrawObject = selectedElement
            if (selectedElement instanceof DrawWorkplace) {
                store.dispatch(selectCoworker(null))
                store.dispatch(setSelectedSpace(null))
                store.dispatch(setSelectedTerminal(null))
                store.dispatch(selectFavoriteWorkplace(selectedElement.workplace))
            }
        }
    }

    function onSelectSpace(selectedElement) {
        if (selectedElement && currentDrawObject && selectedElement.uuid === currentDrawObject.uuid) {
            store.dispatch(setSelectedSpace(null))
            selectedElement.setActive(false)
            currentDrawObject = null
        } else if (selectedElement) {
            selectedElement.setActive(true)
            currentDrawObject = selectedElement
            if (selectedElement instanceof SpaceObject) {
                store.dispatch(setSelectedSpace(selectedElement.space))
                store.dispatch(selectFavoriteWorkplace(null))
                store.dispatch(setSelectedTerminal(null))
            }
        }
    }

    function onSelectTerminal(selectedElement) {
        if (selectedElement && currentDrawObject && selectedElement.uuid === currentDrawObject.uuid) {
            store.dispatch(setSelectedTerminal(null))
            selectedElement.setActive(false)
            currentDrawObject = null
        } else if (selectedElement && selectedElement.geometryId) {
            selectedElement.setActive(true)
            currentDrawObject = selectedElement
            if (selectedElement instanceof DrawTerminal) {
                store.dispatch(setSelectedTerminal(selectedElement.terminal))
                store.dispatch(selectFavoriteWorkplace(null))
                store.dispatch(setSelectedSpace(null))
            }
        }
    }

    function getSelectedElement() {
        let objects = []
        props.drawingObjects.forEach(object => objects = objects.concat(object.children))
        const objectsIntersects = raycaster.intersectObjects(objects, true);

        if (objectsIntersects.length > 0) {
            return objectsIntersects[0].object.parent
        } else {
            store.dispatch(selectFavoriteWorkplace(null))
            store.dispatch(setSelectedSpace(null))
            store.dispatch(setSelectedTerminal(null))
        }

        return null
    }

    return (
        <Measure
            bounds
            onResize={() => {
                resize(mount, rendererRef.current, cameraRef.current)
            }}>
            {({measureRef}) => (
                <div className={classes.floorplaner} ref={measureRef}>
                    <div className={classes.canvasContainer} ref={mount} tabIndex={0}/>
                </div>
            )}
        </Measure>
    )
}

export default withStyles(styles)(forwardRef(FloorPlaner))
