import React, {useEffect} from 'react';
import QRCode from "easyqrcodejs";
import {makeStyles, Typography} from "@material-ui/core";
import {navigate, PATH_MEETING_SCAN, PATH_SCAN_SEAT} from "../../common/utils/NavigationUtils.js";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme.js";
import GoconutSvgLogo from "../../common/icons/GoconutSvgLogo.js";
import Goconut from "../../common/img/goconut_signet_qrcode.png";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '16.4px',
        width: '18.4rem',
        maxWidth: '18.4rem',
        height: '24.7rem',
        backgroundColor: theme.colors.palette.corporate.greenMain,
        padding: theme.innerGap,
        gap: theme.innerGap,
        justifyContent: 'center',
        boxSizing: 'border-box',
    },
    qrWrapper: {
        backgroundColor: theme.colors.palette.neutral.white,
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    qrCode: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    nameWrapper: {
        backgroundColor: theme.colors.palette.neutral.white,
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '2.8rem',
        minHeight: '2.8rem',
        maxWidth: '16rem',
        padding: '0 1rem',
        boxSizing: 'border-box',
    },
    name: {
        fontWeight: 700,
        fontSize: '11px',
        color: theme.colors.palette.corporate.greenMain,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }
}))

function QRCodeGenerator({scannedObject, ...props}) {
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyle(theme);
    const isMeeting = !!scannedObject.typeOfUse
    const httpsPrefix = 'https://'

    const qrcode = React.createRef()

    const domain = () => {
        let hostname = window.location.hostname
        if (hostname.toLowerCase() === 'localhost')
            hostname = hostname + ':3000'
        return hostname
    }

    const url = () => {
        return httpsPrefix + domain() + urlAppendix()
    }

    const urlAppendix = () => {
        return (isMeeting ? PATH_MEETING_SCAN : PATH_SCAN_SEAT) + '/' + scannedObject.id
    }

    const options = {
        text: url(),
        backgroundImage: Goconut,
        backgroundImageAlpha: .85,
        colorDark: theme.colors.palette.corporate.greenMain,
        colorLight: theme.colors.palette.neutral.white,
        width: 125,
        height: 125
    }

    useEffect(() => {
        new QRCode(qrcode.current, options);
        //eslint-disable-next-line
    }, []);

    if (!scannedObject)
        return null

    return (
        <div className={classes.root}>
            <div className={classes.qrWrapper}>
                <div className={classes.qrCode} ref={qrcode} onClick={() => navigate(history, urlAppendix())}/>
            </div>
            <div className={classes.nameWrapper}>
                <Typography className={classes.name}>{scannedObject.name}</Typography>
            </div>
            <GoconutSvgLogo/>
        </div>
    )
}

QRCodeGenerator.propTypes = {
    scannedObject: PropTypes.object.isRequired
}

export default QRCodeGenerator