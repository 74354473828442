import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '12.6rem',
        alignItems: 'center',
        gap: '.4rem'
    },
    image: {
        width: '9.2rem',
        height: '9.2rem',
    },
    title: {
        height: '3.8rem'
    }
}))

function Award({title, points, image, onClick, ...props}) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={classes.root} onClick={onClick}>
            <img src={image} alt={''} className={classes.image}/>
            <Typography variant={'h4'} className={classes.title}>{title}</Typography>
            <Typography variant={'caption'}>{points}{' '}{t('pts')}</Typography>
        </div>
    )
}

Award.propType = {
    title: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    image: PropTypes.any.isRequired,
    onClick: PropTypes.func
}

export default Award