import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles, useTheme} from "@material-ui/core";

import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import {isBlocked} from "../../../common/utils/AssignmentUtils.js";
import CustomButton from "../../CustomButton.js";
import {
    getDateAtEndOfWorkingDay,
    getDateAtStartOfWorkingDay,
    getTimePeriodAtDate
} from "../../../common/utils/TimeUtils.js";
import {TYPE_OF_BOOKING_CHOOSE} from "../../../common/utils/NameUtils.js";
import {navigate, PATH_BOOKINGS} from "../../../common/utils/NavigationUtils.js";
import {assignWorkplaceToPerson} from "../../../actions/workplaceAssignment-action.js";

const useStyle = makeStyles(theme => ({
    bottomContent: {
        padding: '1.8rem',
        paddingBottom: '2.8rem',
    }
}))

function WorkplaceSelectionSpreadsheetBottom({
                                                 userAssignments,
                                                 userId,
                                                 timePeriod,
                                                 selectedWorkplace,
                                                 assignWorkplaceToPersonPending,
                                                 assignWorkplaceToPerson,
                                                 assignments,
                                                 selectedDate,
                                                 detailsView,
                                                 confirmationButtonsDisabled,
                                                 workingGroups
                                             }) {
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    function assignWorkplace(deleteExistingAssignmentsAtDate) {
        let timePeriodAtDate
        if (deleteExistingAssignmentsAtDate) {
            timePeriodAtDate = {
                startDate: getDateAtStartOfWorkingDay(selectedDate),
                endDate: getDateAtEndOfWorkingDay(selectedDate)
            }
        } else {
            timePeriodAtDate = getTimePeriodAtDate(timePeriod, selectedDate)
        }

        let assignment = {
            timePeriod: timePeriodAtDate,
            workplace: selectedWorkplace,
            typeOfWorkplaceBooking: TYPE_OF_BOOKING_CHOOSE
        }

        assignWorkplaceToPerson(userId, assignment, null, deleteExistingAssignmentsAtDate)
            .then(() =>
                navigate(history, PATH_BOOKINGS)
            )
    }

    if (!selectedWorkplace || isBlocked(selectedWorkplace) || detailsView || !selectedWorkplace.userAllowedToBook) {
        return null
    } else {
        return <div className={classes.bottomContent}>
            <CustomButton text={t('book')}
                          onClick={assignWorkplace}
                          fullWidth primary
                          disabled={confirmationButtonsDisabled}
                          isLoading={assignWorkplaceToPersonPending}/>
        </div>

    }
}

WorkplaceSelectionSpreadsheetBottom.propTypes = {
    detailsView: PropTypes.bool,
    confirmationButtonsDisabled: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        timePeriod: state.assignmentBuilder.timePeriod,
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedWorkplace: state.workplace.favoredWorkplace,
        userAssignments: state.user.userAssignments,
        assignWorkplaceToPersonPending: state.workplace.assignWorkplaceToPersonPending,
        assignments: state.workplace.assignments,
        workingGroups: state.workingGroups.personGroups,
    }
}

const mapDispatchToProps = {
    assignWorkplaceToPerson: assignWorkplaceToPerson,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSelectionSpreadsheetBottom)