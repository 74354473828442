import React from 'react';
import PropTypes from 'prop-types';

import {ListItem, ListItemIcon, ListItemText, makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import clsx from "clsx";
import NavigationArrowSvgIcon from "../icons/NavigationArrowSvgIcon.js";

const useStyle = props => makeStyles(theme => ({
    root: {
        alignItems: 'center',
        width: '100%',
        height: '7.2rem',
        '& .MuiListItem-root': {
            padding: '0px 0px'
        },
        '& .MuiListItemIcon-root': {
            minWidth: 0
        },
        '& .MuiListItemIcon-alignItemsFlexStart': {
            margin: 0
        }
    },
    leftListItem: {
        height: '100%',
        borderTop: props.topBorder ? '1px solid rgba(0, 0, 0, 0.12)' : null,
        borderBottom: props.bottomBorder ? '1px solid rgba(0, 0, 0, 0.12)' : null,
        textAlign: 'left',
        alignItems: 'center',
    },
    rightListItem: {
        textAlign: 'right',
        display: 'flex',
        gap: '1.2rem',
        justifyContent: 'end',
        alignItems: 'center'
    },
    startIcon: {
        width: '3rem',
        height: '3rem',
        stroke: theme.colors.palette.neutral.black,
    },
    iconWrapper: {
        flexShrink: 0,
        paddingRight: '1.2rem',
    },
    pointerCursor: {
        cursor: 'pointer'
    }
}));

function SimpleListItem({
                            onClick,
                            title,
                            icon,
                            alternativeEndAdornment,
                            hideArrow,
                            textColor,
                            className,
                            ...props
                        }) {

    const theme = useTheme()
    const classes = useStyle(props)(theme);

    return (
        <div className={clsx(classes.root, hideArrow ? '' : classes.pointerCursor, className)}>
            <ListItem alignItems="flex-start" className={classes.leftListItem}
                      onClick={onClick} disableGutters>
                <ListItemIcon className={icon && classes.iconWrapper}>
                    {icon && React.cloneElement(icon, {className: clsx(classes.startIcon, icon.props ? icon.props.className : '')})}</ListItemIcon>
                <ListItemText>
                    <Typography variant='body1' style={{color: textColor}}>{title}</Typography>
                </ListItemText>
                <div className={classes.rightListItem}>
                    <ListItemText className={classes.rightListItem}>
                        {alternativeEndAdornment}
                    </ListItemText>
                    <ListItemIcon className={classes.rightListItem}>
                        {!hideArrow && <NavigationArrowSvgIcon/>}
                    </ListItemIcon>
                </div>
            </ListItem>
        </div>
    );
}

SimpleListItem.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element,
    bottomBorder: PropTypes.bool,
    topBorder: PropTypes.bool,
    hideArrow: PropTypes.bool,
    alternativeEndAdornment: PropTypes.any,
    textColor: PropTypes.string,
};

SimpleListItem.defaultProps = {
    bottomBorder: false,
    topBorder: true,
}

export default SimpleListItem;
