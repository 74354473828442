import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {getWorkingGroupsFromUser, selectWorkingGroup} from "../../actions/workingGroups-action";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
import InfiniteLoaderList from "../../common/elements/InfiniteLoaderList";
import EmptyStateWorkingGroups from "../WorkingGroup/EmptyStateWorkingGroups.js";
import LoadingIndicator from "../../common/elements/LoadingIndicator.js";
import BottomActionSheet from "../../common/elements/BottomActionSheet.js";
import CustomButton from "../CustomButton.js";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import {navigate, PATH_BOOKINGS} from "../../common/utils/NavigationUtils.js";
import {TYPE_OF_BOOKING_WORKING_GROUP} from "../../common/utils/NameUtils.js";
import {useHistory} from "react-router-dom";
import {assignWorkplaceToPerson} from "../../actions/workplaceAssignment-action.js";
import ActionListItem from "../../common/elements/ActionListItem.js";
import MultipleAvatarsGroup from "../../common/elements/MultipleAvatarsGroup.js";
import {sortByName} from "../../common/utils/CommonUtils.js";

const useStyle = makeStyles(theme => ({
    container: {
        height: '100%',
        display: 'flex',
    },
    content: {
        height: 'calc(100% - 72px)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingIndicator: {
        paddingTop: '2.4rem'
    }
}));

function ChooseWorkingGroupDetails({
                                       personId,
                                       getUserWorkingGroups,
                                       myGroups,
                                       selectGroup,
                                       selectedGroup,
                                       workingGroupsPending,
                                       assignWorkplaceToPersonPending,
                                       searchString,
                                       assignWorkplaceToPerson,
                                       timePeriod,
                                       confirmationButtonsDisabled,
                                       t,
                                   }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const history = useHistory()

    const [items, setItems] = useState([]);
    const [selectedWorkingGroup, setSelectedWorkingGroup] = React.useState(null)

    useEffect(() => {
        if (personId !== undefined) {
            getUserWorkingGroups(personId)
        }
        // eslint-disable-next-line
    }, [personId]);

    useEffect(() => {
        setGroupsAsItems()
        // eslint-disable-next-line
    }, [myGroups, searchString]);

    function setGroupsAsItems() {
        searchString
            ? setItems(myGroups.filter(filterGroups).sort(sortByName))
            : setItems(myGroups)
    }

    const filterGroups = (group) => {
        if (!searchString)
            return true
        let name = group.name
        return name.toLowerCase().includes(searchString.toLowerCase())
    }

    function getItem(group) {
        return <ActionListItem startAdornment={<MultipleAvatarsGroup persons={group.members}/>}
                               key={group.id}
                               text={group.name}
                               action={() => handleGroupSelected(group)}
                               selected={selectedGroup && selectedGroup.id === group.id}
                               height={70}
                               avatarHeight={40}
                               hideArrow
        />
    }

    const handleGroupSelected = (group) => {
        if (selectedWorkingGroup && selectedWorkingGroup.id === group.id) {
            selectGroup(null)
            setSelectedWorkingGroup(null)
        } else {
            selectGroup(group)
            setSelectedWorkingGroup(group)
        }
    }

    function getBottomSpreadsheetContent() {
        return <CustomButton text={t('book_nearest_workplace')}
                             disabled={selectedGroup === null || confirmationButtonsDisabled}
                             fullWidth
                             primary
                             isLoading={assignWorkplaceToPersonPending}
                             onClick={bookByWorkingGroup}
        />
    }

    function bookByWorkingGroup() {
        const assignment = {
            timePeriod: timePeriod,
            typeOfWorkplaceBooking: TYPE_OF_BOOKING_WORKING_GROUP,
        }

        assignWorkplaceToPerson(personId, assignment, selectedWorkingGroup && selectedWorkingGroup.id)
            .then(() =>
                navigate(history, PATH_BOOKINGS)
            )
    }

    return (
        <Grid item sm={12} className={classes.container}>
            <div className={classes.content}>
                <Divider/>
                {workingGroupsPending
                    ? <LoadingIndicator className={classes.loadingIndicator}/>
                    : <InfiniteLoaderList items={items} listItem={getItem} itemSize={70}
                                          emptyState={<EmptyStateWorkingGroups/>}/>
                }
            </div>
            <BottomActionSheet content={getBottomSpreadsheetContent()}/>
        </Grid>
    );
}

ChooseWorkingGroupDetails.propTypes = {
    searchString: PropTypes.string,
    confirmationButtonsDisabled: PropTypes.bool
}

const mapStateToProps = state => {
    return {
        selectedGroup: state.workingGroups.selected,
        myGroups: state.workingGroups.personGroups,
        personId: state.user.person.id,
        userAssignments: state.user.userAssignments,
        workingGroupsPending: state.workingGroups.fetchUsersWorkingGroupsPending,
        assignWorkplaceToPersonPending: state.workplace.assignWorkplaceToPersonPending,
        timePeriod: state.assignmentBuilder.timePeriod,
    }
};

const mapDispatchToProps = {
    selectGroup: selectWorkingGroup,
    getUserWorkingGroups: getWorkingGroupsFromUser,
    assignWorkplaceToPerson: assignWorkplaceToPerson,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChooseWorkingGroupDetails));
