import React from 'react';
import PropTypes from 'prop-types';

import {compose} from "redux";
import {withTranslation} from "react-i18next";

import {Typography, withStyles} from "@material-ui/core";
import NavigationBackSvgIcon from "../icons/NavigationBackSvgIcon";
import useTheme from "@material-ui/core/styles/useTheme.js";

const styles = ({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    elements: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    navBackContainer: {
        display: 'flex',
    },
    navigateBack: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '5px',
        width: '18px',
        height: '18px',
    },
    title: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
})

function HeaderWithTitleAndBackButton({
                                          classes,
                                          t,
                                          title,
                                          backLabel,
                                          onNavigateBack,
                                          additionalElement,
                                          white,
                                          ...props
                                      }) {
    const theme = useTheme()

    delete props.tReady

    return (
        <div className={classes.root} style={{...props.style}}>
            <div className={classes.elements}>
                <div className={classes.navBackContainer}>
                    <div className={classes.navigateBack} onClick={onNavigateBack}>
                        <NavigationBackSvgIcon className={classes.icon}
                                               fill={white && theme.colors.palette.neutral.white}/>
                        <Typography variant={'body1'} style={{color: white ? theme.colors.palette.neutral.white : ''}}>
                            {backLabel ? backLabel : t("back")}
                        </Typography>
                    </div>
                </div>
                {title &&
                    <Typography variant={'h3'} className={classes.title}>
                        {title}
                    </Typography>
                }
            </div>
            {additionalElement && React.cloneElement(additionalElement, {...props})}
        </div>
    );
}

HeaderWithTitleAndBackButton.propTypes = {
    title: PropTypes.string,
    backLabel: PropTypes.string,
    onNavigateBack: PropTypes.func.isRequired,
    white: PropTypes.bool,
    additionalElement: PropTypes.element,
};

export default compose(withStyles(styles), withTranslation())(HeaderWithTitleAndBackButton)

