import * as React from "react"
import PropTypes from "prop-types";

import SvgIcon from "@material-ui/core/SvgIcon";

function ShowPasswordSvgIcon({color, strokeWidth, ...props}) {
    return (
        <SvgIcon className={props.className} viewBox="0 0 24 24" {...props}>
            <g
                stroke={color}
                strokeWidth={strokeWidth}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <circle cx="12" cy="12" r="3"/>
                <path
                    d="M12 18c2.957 0 5.665-1.593 8.122-4.778a2 2 0 0 0 0-2.444C17.665 7.593 14.957 6 12 6s-5.665 1.593-8.122 4.778a2 2 0 0 0 0 2.444C6.335 16.407 9.043 18 12 18z"/>
            </g>
        </SvgIcon>
    )
}

ShowPasswordSvgIcon.propTypes = {
    color: PropTypes.string,
    strokeWidth: PropTypes.number,
};

ShowPasswordSvgIcon.defaultProps = {
    color: '#333',
    strokeWidth: 1.5,
}


export default ShowPasswordSvgIcon
