import React, {useEffect} from 'react';

import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

import CustomButton from "../CustomButton.js";
import CustomCheckbox from "../CustomCheckbox.js";
import EmptyStateWorkingGroups from "./EmptyStateWorkingGroups.js";
import ActionListItem from "../../common/elements/ActionListItem.js";
import LoadingIndicator from "../../common/elements/LoadingIndicator";
import StaticBottomSheet from "../../common/elements/StaticBottomSheet.js";
import MultipleAvatarsGroup from "../../common/elements/MultipleAvatarsGroup.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import ShareLocationActiveSvgIcon from "../../common/icons/ShareLocationActiveSvgIcon.js";
import CheckboxIntermediateSvgIcon from "../../common/icons/CheckboxIntermediateSvgIcon.js";

import {fetchShareStatus, postShareStatus} from "../../actions/user-action.js";
import {SHARE_STATUS_WORKINGGROUPS} from "../../common/utils/NameUtils.js";
import {
    navigate,
    PATH_PROFILE,
    PATH_WORKING_GROUP,
    PATH_WORKING_GROUPS_CREATE
} from "../../common/utils/NavigationUtils.js";
import {
    changeShareStatusOfGroups,
    fetchSharedWorkingGroups,
    getWorkingGroupsFromUser,
    selectWorkingGroup
} from "../../actions/workingGroups-action.js";


const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.palette.neutral.white,
    },
    container: {
        minHeight: `calc(100% - ${theme.staticBottomActionSheet.height})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    shareLocationHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '1rem'
    },
    loadingIndicator: {
        flexGrow: 1,
        alignItems: 'center'
    }
}))

function WorkingGroupsOverview({
                                   t,
                                   shareStatus,
                                   personId,
                                   workingGroups,
                                   selectGroup,
                                   sharedGroups,
                                   getUserWorkingGroups,
                                   fetchSharedWorkingGroups,
                                   postShareStatus,
                                   changeShareStatusOfGroups,
                                   fetchShareStatus,
                                   fetchUsersWorkingGroupsPending
                               }) {
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyle(theme);

    useEffect(() => {
        if (personId) {
            if (isEmpty(workingGroups))
                getUserWorkingGroups(personId)

            if (isEmpty(sharedGroups))
                fetchSharedWorkingGroups(personId)

            if (shareStatus == null || shareStatus.status == null)
                fetchShareStatus(personId)
        }
        // eslint-disable-next-line
    }, [personId]);

    const routeToProfile = () => {
        navigate(history, PATH_PROFILE)
    }

    const routeToWorkingGroupPage = (group) => {
        selectGroup(group)
        navigate(history, PATH_WORKING_GROUP + group.id)
    }

    const routeToCreateWorkingGroup = () => {
        navigate(history, PATH_WORKING_GROUPS_CREATE)
    }

    const showSharestatusCheckbox = () => {
        return !isEmpty(workingGroups) && shareStatus && shareStatus.status === SHARE_STATUS_WORKINGGROUPS
    }

    function getGroupSharedState(group) {
        const present = sharedGroups && sharedGroups
            .find(shared => shared.id === group.id)

        return !!present
    }

    const getGroupsCheckboxIndeterminate = () => {
        return !(isEmpty(sharedGroups) || ((sharedGroups && workingGroups) &&
            sharedGroups.length === workingGroups.length))
    }

    function onCheckboxChange(event) {
        const value = event.target.checked

        if (!value)
            postShareStatus(personId, shareStatus.status, []) //clear working groups
        else
            postShareStatus(personId, shareStatus.status, workingGroups)

        changeShareStatusOfGroups(value, workingGroups)
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <HeaderWithTitleAndBackButton title={t('working_groups')} backLabel={t('profile')}
                                              onNavigateBack={routeToProfile}/>
                {!fetchUsersWorkingGroupsPending
                    ? <div className={classes.content}>
                        {showSharestatusCheckbox() &&
                            <div className={classes.shareLocationHeader}>
                                <CustomCheckbox name={'shareState'}
                                                indeterminate={getGroupsCheckboxIndeterminate()}
                                                indeterminateIcon={<CheckboxIntermediateSvgIcon
                                                    className={classes.icon}/>}
                                                onChange={onCheckboxChange}
                                                selected={!isEmpty(sharedGroups)}/>
                                <Typography variant={'body1'}>
                                    {t('share_location_all_groups')}
                                </Typography>
                            </div>}
                        {workingGroups && workingGroups.length
                            ? workingGroups.map(group => {
                                return <ActionListItem startAdornment={<MultipleAvatarsGroup persons={group.members}/>}
                                                       key={group.id}
                                                       text={group.name}
                                                       action={() => routeToWorkingGroupPage(group)}
                                                       height={70}
                                                       avatarHeight={40}
                                                       endAdornment={
                                                           getGroupSharedState(group) ?
                                                               <ShareLocationActiveSvgIcon/> : null
                                                       }
                                />
                            })
                            : <EmptyStateWorkingGroups/>}
                    </div>
                    : <LoadingIndicator className={classes.loadingIndicator}/>}
            </div>
            <StaticBottomSheet>
                <CustomButton text={t('create_group')} primary
                              onClick={routeToCreateWorkingGroup}
                />
            </StaticBottomSheet>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        shareStatus: state.user.shareStatus,
        workingGroups: state.workingGroups.personGroups,
        sharedGroups: state.workingGroups.sharedWorkingGroups,
        fetchUsersWorkingGroupsPending: state.workingGroups.fetchUsersWorkingGroupsPending,
        personId: state.user.person.id,
    }
}

const mapDispatchToProps = {
    selectGroup: selectWorkingGroup,
    fetchShareStatus: fetchShareStatus,
    fetchSharedWorkingGroups: fetchSharedWorkingGroups,
    getUserWorkingGroups: getWorkingGroupsFromUser,
    postShareStatus: postShareStatus,
    changeShareStatusOfGroups: changeShareStatusOfGroups
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WorkingGroupsOverview));
