import * as React from 'react'

const DrawDoorSvgIcon = props =>
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fillRule="nonzero" stroke={props.stroke} fill="none">
            <path d="M2.5 21h19M7 3h10a1 1 0 0 1 1 1v17H6V4a1 1 0 0 1 1-1Z"/>
            <path d="M15 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"/>
        </g>
    </svg>

export default DrawDoorSvgIcon