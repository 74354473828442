import React from "react";

import moment from "moment";
import _, {isEmpty, isEqual} from "lodash";

import {getHalfTime, isSameDay, isToday, sortAssignments} from "./TimeUtils";

import theme from "../../theme.js";
import RoundedIconWrapper from "../elements/RoundedIconWrapper.js";

import {getColorByActivity} from "../elements/AvatarWithOccupancy.js";
import {getActivityIcon, getImageForAvatar} from "./ActivityUtils.js";
import {drawingColor, drawingTypes} from "../../components/drawingTool/drawing/drawConstants.js";

import HomeSvgIcon from "../icons/HomeSvgIcon.js";
import VacationSvgIcon from "../icons/VacationSvgIcon.js";
import ChooseSeatSvgIcon from "../icons/ChooseSeatSvgIcon.js";
import OutOfOfficeSvgIcon from "../icons/OutOfOfficeSvgIcon.js";
import CheckCircledSvgIcon from "../icons/CheckCircledSvgIcon.js";
import CrossCircledSvgIcon from "../icons/CrossCircledSvgIcon.js";
import WorkingGroupSvgIcon from "../icons/WorkingGroupSvgIcon.js";
import MeetingCreatorSvgIcon from "../icons/MeetingCreatorSvgIcon";
import AttendanceStatusNoSvgIcon from "../icons/AttendanceStatusNoSvgIcon.js";
import AttendanceStatusYesSvgIcon from "../icons/AttendanceStatusYesSvgIcon.js";
import MeetingAttendingOnlineSvgIcon from "../icons/MeetingAttendingOnlineSvgIcon";
import AttendanceStatusMaybeSvgIcon from "../icons/AttendanceStatusMaybeSvgIcon.js";
import AttendanceStatusOnlineSvgIcon from "../icons/AttendanceStatusOnlineSvgIcon.js";
import AttendanceStatusCreatorSvgIcon from "../icons/AttendanceStatusCreatorSvgIcon.js";
import OutstandingMeetingInvitationSvgIcon from "../icons/OutstandingMeetingInvitationSvgIcon";

import Talk from "../img/Talk.png";
import Calls from "../img/Calls.png";
import Creative from "../img/Creative.png";
import Undefined from "../img/Undefined.png";
import Concentrate from "../img/Concentrate.png";
import Anonym from "../img/icons-map-anonym-user@3x.png"
import WorkplaceBlocked from "../img/icons-map-blocked@3x.png";
import AssignmentBackgroundImg from "../img/AssignmentBackgroundImg.png";

import ResourceSvgIcon from "../icons/ResourceSvgIcon.js";

import {
    ATTENDANCE_STATUS_ATTENDING_IN_PERSON,
    ATTENDANCE_STATUS_ATTENDING_ONLINE,
    ATTENDANCE_STATUS_CANCELED,
    ATTENDANCE_STATUS_INITIATOR,
    ATTENDANCE_STATUS_UNKNOWN,
    TYPE_OF_ACTIVITY_CALLS,
    TYPE_OF_ACTIVITY_CONCENTRATE,
    TYPE_OF_ACTIVITY_CREATIVE,
    TYPE_OF_ACTIVITY_HOMEOFFICE,
    TYPE_OF_ACTIVITY_OUT_OF_OFFICE,
    TYPE_OF_ACTIVITY_TALK,
    TYPE_OF_ACTIVITY_UNDEFINED,
    TYPE_OF_BOOKING_CHOOSE,
    TYPE_OF_BOOKING_HOMEOFFICE,
    TYPE_OF_BOOKING_MEETING_ROOM,
    TYPE_OF_BOOKING_OUT_OF_OFFICE,
    TYPE_OF_BOOKING_RANDOM,
    TYPE_OF_BOOKING_RESOURCE,
    TYPE_OF_BOOKING_VACATION,
    TYPE_OF_BOOKING_WORKING_GROUP,
    TYPES_OF_REMOTE,
    WORKDURATION_AFTERNOON,
    WORKDURATION_FULL_DAY,
    WORKDURATION_MORNING,
    WORKPLACE_BLOCKED,
    WORKPLACE_BOOKED,
    WORKPLACE_STATUS_INACTIVE_AUTO,
    WORKPLACE_STATUS_INACTIVE_MANUAL
} from "./NameUtils";

export const MAX_DAYS_OF_BOOKING = 8;

/**
 *
 * @param date
 * @param assignments
 * @param workingHoursTimeperiod the workingHours specified by the user for that day
 * @returns {boolean} true if there is no assignment slot free for a workplace at the specified date, otherwise false
 */
export function isDateBookedByUserAllDay(date, assignments, workingHoursTimeperiod) {
    const filteredAssignments = sortAssignments([...assignments]
        .filter(assignment => (
                isSameDay(assignment.timePeriod.startDate, date)
                || moment(date).isBetween(assignment.timePeriod.startDate, assignment.timePeriod.endDate, undefined, '[]'))
            && isBlockingAssignment(assignment)))
    if (!filteredAssignments.length)
        return false

    let morning = assignmentOverlappingDaytime(WORKDURATION_MORNING, filteredAssignments, date, workingHoursTimeperiod)
    let noon = assignmentOverlappingDaytime(WORKDURATION_AFTERNOON, filteredAssignments, date, workingHoursTimeperiod)

    return morning && noon
}

export function assignmentOverlappingDaytime(dayTimeString, assignments, date, workingHoursTimeperiod) {
    if (!assignments || !assignments.length || !workingHoursTimeperiod)
        return false

    const startTime = workingHoursTimeperiod.startDate
    const endTime = workingHoursTimeperiod.endDate
    const halfTime = getHalfTime(startTime, endTime)

    const filteredAssignments = [...assignments].filter(a => isSameDay(a.timePeriod.startDate, date) || moment(date).isBetween(a.timePeriod.startDate, a.timePeriod.endDate, undefined, '[]'))
    if (dayTimeString === WORKDURATION_MORNING) {
        return filteredAssignments.some(assignment => moment(assignment.timePeriod.startDate).isBefore(halfTime) && moment(assignment.timePeriod.endDate).isAfter(startTime))
    } else if (dayTimeString === WORKDURATION_AFTERNOON) {
        return filteredAssignments.some(assignment => moment(assignment.timePeriod.startDate).isBefore(endTime) && moment(assignment.timePeriod.endDate).isAfter(halfTime))
    } else if (dayTimeString === WORKDURATION_FULL_DAY) {
        return filteredAssignments.some(assignment => moment(assignment.timePeriod.startDate).isBefore(endTime) && moment(assignment.timePeriod.endDate).isAfter(startTime))
    }
    return false
}

export function getNextFreeDay(assignments, date) {
    let missingDate
    let sortedAssignments = sortAssignments(assignments)
    let lastAssignment = _.last(sortedAssignments)

    let start = moment(date).hour() < process.env.REACT_APP_HOUR_FOR_LAST_TODAYS_BOOKINGS ?
        moment(date) : moment(date).add(1, 'days')

    let end = lastAssignment ? moment(lastAssignment.timePeriod.endDate) : start

    for (let i = start.dayOfYear(); i <= end.dayOfYear(); i++) {
        let dateToProve = moment().dayOfYear(i)
        if (dateToProve.weekday() === 5 || dateToProve.weekday() === 6)
            continue
        let filteredAssignments = sortedAssignments
            .filter(assign => (moment(assign.timePeriod.startDate).dayOfYear() === i ||
                moment(assign.timePeriod.endDate).dayOfYear() === i) && isBlockingAssignment(assign))

        if (filteredAssignments.length === 0) {
            missingDate = i
            break
        }
    }

    if (!missingDate) {
        end.add(1, 'days')

        if (end.weekday() === 5) {
            end.add(2, 'days').dayOfYear()
        }

        if (end.weekday() === 6) {
            end.add(1, 'days').dayOfYear()
        }

        missingDate = end.dayOfYear()
    }
    return missingDate
}

export function getUsersAttendanceStatus(assignment, userId) {
    if (!assignment || !assignment.attendances)
        return
    let meetingAttendance = assignment.attendances.find(attendance => attendance.person && attendance.person.id === userId)
    if (!meetingAttendance)
        return

    return meetingAttendance.meetingAttendanceStatus
}

export function getAttendanceStatusIcon(status) {
    if (status === ATTENDANCE_STATUS_INITIATOR) {
        return <MeetingCreatorSvgIcon/>
    } else if (status === ATTENDANCE_STATUS_ATTENDING_IN_PERSON) {
        return <CheckCircledSvgIcon/>
    } else if (status === ATTENDANCE_STATUS_ATTENDING_ONLINE) {
        return <MeetingAttendingOnlineSvgIcon/>
    } else if (status === ATTENDANCE_STATUS_UNKNOWN) {
        return <OutstandingMeetingInvitationSvgIcon/>
    } else if (status === ATTENDANCE_STATUS_CANCELED) {
        return <CrossCircledSvgIcon/>
    }
}

export function isInitiator(assignment, userId) {
    return getUsersAttendanceStatus(assignment, userId) === ATTENDANCE_STATUS_INITIATOR
}

export function isAttendingInPerson(assignment, userId) {
    return getUsersAttendanceStatus(assignment, userId) === ATTENDANCE_STATUS_ATTENDING_IN_PERSON
}

export function isAttendingOnline(assignment, userId) {
    let usersAttendanceStatus = getUsersAttendanceStatus(assignment, userId);
    return usersAttendanceStatus === ATTENDANCE_STATUS_ATTENDING_ONLINE || (assignment.onlineOnly && usersAttendanceStatus === ATTENDANCE_STATUS_ATTENDING_IN_PERSON)
}

export function isAttendanceCanceled(assignment, userId) {
    return getUsersAttendanceStatus(assignment, userId) === ATTENDANCE_STATUS_CANCELED
}

export function getAssignmentColor(assignment, theme) {
    if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_MEETING_ROOM) {
        return theme.colors.palette.corporate.greenLightest
    } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION) {
        return theme.colors.palette.corporate.blueDarkest
    } else if (TYPES_OF_REMOTE.includes(assignment.typeOfWorkplaceBooking)) {
        return theme.colors.palette.neutral.darkMain
    } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_RESOURCE) {
        return theme.colors.palette.neutral.greyMain
    } else {
        return theme.colors.palette.corporate.skinMain
    }
}

export function getAssignmentsLocationAsString(assignment, t) {
    if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_HOMEOFFICE) {
        return t('private')
    } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_OUT_OF_OFFICE) {
        return t('out_of_office')
    } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_MEETING_ROOM) {
        return assignment.onlineOnly ? t('online') : assignment.space.name
    } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_RESOURCE) {
        return assignment.resource.location.name
    } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_CHOOSE || assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_RANDOM
        || assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_WORKING_GROUP) {
        return assignment.buildingName + ", " + assignment.floorName
    } else {
        return t('location_not_available')
    }
}

export function getMeetingLocationAsString(assignment, t) {
    if (assignment.onlineOnly) {
        return t('online_meeting')
    }
    let location = "";

    if (assignment.buildingName) {
        location += assignment.buildingName
    }

    if (assignment.floorName) {
        if (!isEmpty(location))
            location += ", "
        location += assignment.floorName
    }

    if (assignment.space && assignment.space.name) {
        if (!isEmpty(location))
            location += ", "
        location += assignment.space.name
    }

    if (!isEmpty(location)) {
        return location;
    } else {
        return t('location_not_available')
    }
}

export function getBackgroundImg() {
    return AssignmentBackgroundImg
}

export function getTypeOfBookingIcon(assignment, userId, size) {
    switch (assignment.typeOfWorkplaceBooking) {
        case TYPE_OF_BOOKING_WORKING_GROUP:
            return <WorkingGroupSvgIcon style={{width: size ? size : '3rem', height: size ? size : '3rem'}}
                                        stroke={getAssignmentColor(assignment, theme)}/>
        case TYPE_OF_BOOKING_CHOOSE:
            return <ChooseSeatSvgIcon style={{width: size ? size : '3rem', height: size ? size : '3rem'}}
                                      stroke={getAssignmentColor(assignment, theme)}/>

        case TYPE_OF_BOOKING_VACATION:
            return <VacationSvgIcon style={{width: size ? size : '3rem', height: size ? size : '3rem'}}
                                    stroke={getAssignmentColor(assignment, theme)}/>

        case TYPE_OF_BOOKING_RANDOM:
            if (assignment.activity) {
                return getActivityIcon(assignment.activity, assignment.id + assignment.activity + '-icon', null, {
                    width: size ? size : '3rem',
                    height: size ? size : '3rem'
                })
            }
            break
        case TYPE_OF_BOOKING_HOMEOFFICE:
            return <HomeSvgIcon style={{width: size ? size : '3rem', height: size ? size : '3rem'}}
                                fill={getAssignmentColor(assignment, theme)}/>
        case TYPE_OF_BOOKING_OUT_OF_OFFICE:
            return <OutOfOfficeSvgIcon style={{width: size ? size : '3rem', height: size ? size : '3rem'}}
                                       stroke={getAssignmentColor(assignment, theme)}/>
        case TYPE_OF_BOOKING_MEETING_ROOM:
            return getMeetingAttendanceIcon(getAssignmentColor(assignment, theme), assignment, userId)
        case TYPE_OF_BOOKING_RESOURCE:
            return <ResourceSvgIcon style={{width: size ? size : '3rem', height: size ? size : '3rem'}}
                                    color={getAssignmentColor(assignment, theme)}/>
        default:
            return null
    }
}

export function getTypeOfWorkplaceSymbol(workplace, size) {
    switch (workplace.activityBestMatch) {
        case TYPE_OF_ACTIVITY_HOMEOFFICE:
            return <RoundedIconWrapper size={size}
                                       icon={<HomeSvgIcon
                                           style={{width: size ? size + 'px' : '32px', height: '20px'}}/>}
                                       backgroundColor={getColorByActivity(workplace.activityBestMatch)}/>
        case TYPE_OF_ACTIVITY_OUT_OF_OFFICE:
            return <RoundedIconWrapper size={size}
                                       icon={<OutOfOfficeSvgIcon
                                           style={{width: size ? size + 'px' : '32px', height: '20px'}}/>}
                                       backgroundColor={getColorByActivity(workplace.activityBestMatch)}/>
        case TYPE_OF_BOOKING_VACATION:
            return <RoundedIconWrapper size={size}
                                       icon={<VacationSvgIcon
                                           style={{width: size ? size + 'px' : '32px', height: '20px'}}/>}
                                       backgroundColor={theme.colors.palette.corporate.blueDarkest}/>
        default:
            return getImageForAvatar(null, workplace.activityBestMatch, size ?? 32)
    }
}

export function getMeetingAttendanceIcon(color, assignment, userId) {
    let meetingAttendance = assignment.attendances.find(attendance => attendance.person && attendance.person.id === userId)
    if (!meetingAttendance)
        return
    if (meetingAttendance.meetingAttendanceStatus === ATTENDANCE_STATUS_INITIATOR) {
        return <AttendanceStatusCreatorSvgIcon stroke={color}/>
    } else if (meetingAttendance.meetingAttendanceStatus === ATTENDANCE_STATUS_ATTENDING_IN_PERSON) {
        return <AttendanceStatusYesSvgIcon stroke={color}/>
    } else if (meetingAttendance.meetingAttendanceStatus === ATTENDANCE_STATUS_ATTENDING_ONLINE) {
        return <AttendanceStatusOnlineSvgIcon stroke={color}/>
    } else if (meetingAttendance.meetingAttendanceStatus === ATTENDANCE_STATUS_UNKNOWN) {
        return <AttendanceStatusMaybeSvgIcon stroke={color}/>
    } else if (meetingAttendance.meetingAttendanceStatus === ATTENDANCE_STATUS_CANCELED) {
        return <AttendanceStatusNoSvgIcon stroke={color}/>
    }
}

export function getActivityColor(activity) {
    switch (activity) {
        case TYPE_OF_BOOKING_HOMEOFFICE:
            return drawingColor.workplaceHomeoffice
        case TYPE_OF_BOOKING_OUT_OF_OFFICE:
            return drawingColor.workplaceOutOfOffice
        case TYPE_OF_ACTIVITY_CREATIVE:
            return drawingColor.workplaceCreative
        case TYPE_OF_ACTIVITY_TALK:
            return drawingColor.workplaceTalk
        case TYPE_OF_ACTIVITY_CALLS:
            return drawingColor.workplaceCalls
        case TYPE_OF_ACTIVITY_CONCENTRATE:
            return drawingColor.workplaceConcentrate
        case TYPE_OF_ACTIVITY_UNDEFINED:
            return drawingColor.workplaceUndefined
        default:
            return 'transparent'

    }
}

export function getBackgroundColorForWorkplace(activity, workplaceType) {
    if (workplaceType) {
        if (workplaceType === drawingTypes.workplaceBlocked)
            return drawingColor.disabled
        if (workplaceType === drawingTypes.workplaceNotPermitted)
            return drawingColor.white
    }

    return getActivityColor(activity)
}

export function getWorkplaceImage(status, activityBestMatch) {
    if (status === WORKPLACE_STATUS_INACTIVE_AUTO || status === WORKPLACE_STATUS_INACTIVE_MANUAL)
        return WorkplaceBlocked
    switch (activityBestMatch) {
        case TYPE_OF_ACTIVITY_UNDEFINED:
            return Undefined
        case TYPE_OF_ACTIVITY_CALLS:
            return Calls
        case TYPE_OF_ACTIVITY_CONCENTRATE:
            return Concentrate
        case TYPE_OF_ACTIVITY_CREATIVE:
            return Creative
        case TYPE_OF_ACTIVITY_TALK:
            return Talk
        case WORKPLACE_BOOKED:
            return Anonym
        case WORKPLACE_BLOCKED:
            return WorkplaceBlocked
        default:
            return Undefined
    }
}

export function matchProfilePicturesWithAssignments(assignments, profilePictures, workplaces) {
    let workplacesWithImages = []

    for (let workplace of workplaces) {
        let relevantAssignments = assignments?.filter(element => element.workplace && isEqual(element.workplace.id, workplace.id))

        if (relevantAssignments?.length) {
            let assignmentsForWorkplace = []
            let imagesForWorkplace = []

            for (let assignment of relevantAssignments) {
                let profilePictureIndex = profilePictures.findIndex(element => isEqual(assignment.person.id.toString(), element.personId.toString()))
                let profilePicture = profilePictureIndex > -1 ? profilePictures[profilePictureIndex].profilePicture : null
                assignmentsForWorkplace.push(assignment)
                imagesForWorkplace.push(profilePicture)
            }

            //get the profilePicture that is to be displayed at the very moment
            let currentShowedProfilePicture
            if (assignmentsForWorkplace?.length === 1) {
                currentShowedProfilePicture = imagesForWorkplace[0]
            } else {
                if (isToday(assignmentsForWorkplace[0].timePeriod.startDate) && isToday(assignmentsForWorkplace[1].timePeriod.startDate)) {
                    if (moment(assignmentsForWorkplace[0].timePeriod.endDate).toDate().valueOf() > new Date().valueOf()) {
                        currentShowedProfilePicture = imagesForWorkplace[0]
                    } else {
                        currentShowedProfilePicture = imagesForWorkplace[1]
                    }
                } else {
                    if (moment(assignmentsForWorkplace[0].timePeriod.startDate).toDate().valueOf() < moment(assignmentsForWorkplace[1].timePeriod.startDate).toDate().valueOf()) {
                        currentShowedProfilePicture = imagesForWorkplace[0]
                    } else {
                        currentShowedProfilePicture = imagesForWorkplace[1]
                    }
                }
            }
            workplacesWithImages.push({assignments: assignmentsForWorkplace, image: currentShowedProfilePicture})
        } else {
            workplace.seatAvailable = true
            const workplaceImage = getWorkplaceImage(workplace.status, workplace.activityBestMatch)
            workplacesWithImages.push({assignments: [{workplace: workplace}], image: workplaceImage})
        }
    }
    return workplacesWithImages
}

export function isHomeoffice(assignment) {
    return assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_HOMEOFFICE
}

export function isOutOfOffice(assignment) {
    return assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_OUT_OF_OFFICE
}

export function isVacation(assignment) {
    return assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION
}

export function isRemote(assignment) {
    return TYPES_OF_REMOTE.includes(assignment.typeOfWorkplaceBooking)
}

export function isNotInOffice(assignment) {
    return isRemote(assignment) || isVacation(assignment)
}

export function filterAssignmentsByDateAndWorkplaceIdAndSort(assignments, workplaceId, date) {
    let relevantAssignments = []

    if (!assignments || !workplaceId || !date)
        return relevantAssignments
    for (let assignment of assignments) {
        if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_MEETING_ROOM)
            continue
        if (assignment.workplace.id === workplaceId && isSameDay(assignment.timePeriod.startDate, date))
            relevantAssignments.push(assignment)
    }
    return sortAssignments(relevantAssignments)
}

export function filterAssignmentsByDateAndMeetingroomIdAndSort(assignments, spaceId, date) {
    let relevantAssignments = []

    if (!assignments || !spaceId || !date)
        return relevantAssignments
    for (let assignment of assignments) {
        if (assignment.typeOfWorkplaceBooking !== TYPE_OF_BOOKING_MEETING_ROOM)
            continue
        if (assignment.workplace.id === spaceId && isSameDay(assignment.timePeriod.startDate, date))
            relevantAssignments.push(assignment)
    }
    return sortAssignments(relevantAssignments)
}

export function filterForAssignmentsBlockingNewBooking(assignments) {
    if (!assignments)
        return []
    return assignments.filter(assignment => isBlockingAssignment(assignment))
}

export function isBlockingAssignment(assignment) {

    if (!assignment)
        return false

    return assignment.typeOfWorkplaceBooking !== TYPE_OF_BOOKING_MEETING_ROOM && assignment.typeOfWorkplaceBooking !== TYPE_OF_BOOKING_RESOURCE
}

export function filterForMeetingAssignments(assignments) {
    if (!assignments)
        return []
    return assignments.filter(assignment => assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_MEETING_ROOM)
}

export function isBlocked(workplace) {
    if (!workplace || !workplace.status)
        return false
    return workplace.status === WORKPLACE_STATUS_INACTIVE_MANUAL || workplace.status === WORKPLACE_STATUS_INACTIVE_AUTO
}

export function hasUserPermissionToBook(workplace, userId, usersWorkingGroups) {
    if (!workplace)
        return false

    if (!workplace.bookingPermissions || isEmpty(workplace.bookingPermissions))
        return true

    for (const permission of workplace.bookingPermissions) {
        if (permission.person != null && permission.person.id === userId) {
            return true
        } else if (permission.workingGroup != null && usersWorkingGroups != null) {
            for (const group of usersWorkingGroups) {
                if (permission.workingGroup.id === group.id) {
                    return true
                }
            }
        }
    }

    return false
}

export function getAssignmentsAsIntervals(assignments) {

    let intervals = []

    assignments.forEach((assignment) => {
        let start, end
        start = new Date(assignment.timePeriod.startDate)
        start.setSeconds(0, 0)
        end = new Date(assignment.timePeriod.endDate)
        end.setSeconds(0, 0)

        if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION) {
            if (start.getDay() !== end.getDay()) {
                for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {

                    let endDate = new Date(date)
                    endDate.setHours(23, 59, 59, 0)

                    intervals.push({
                        start: new Date(date),
                        end: new Date(endDate),
                    })

                    date.setDate(endDate.getDate())
                }
            }
        }

        intervals.push({
            start: start,
            end: end
        })
    })

    return intervals
}
