import * as THREE from 'three'
import {
    createEdge,
    createFaceGeometry,
    createVertex,
    drawingSegments,
    drawingSize,
    drawingTypes,
    getCircleGeometry,
    getDrawingTypesMaterial,
    insertChildIntoGroup,
    removeItemFromArray,
    reorderGroup,
    threeMaterial,
} from './drawConstants'

function DrawPolyline() {
    THREE.Mesh.apply(this, arguments)
    this.vertexGroup = new THREE.Group()
    this.vertexGroup.name = 'vertices'
    this.edgeGroup = new THREE.Group()
    this.edgeGroup.name = 'edges'

    this.lastVertex = null

    this.handlerScale = 1

    this.add(this.vertexGroup)
    this.add(this.edgeGroup)

    this.init = function (position, drawingRepresentationType) {
        this.drawingRepresentationType = drawingRepresentationType

        const {material, innerMaterial} = getDrawingTypesMaterial(drawingRepresentationType)
        const vertexMesh = createVertex(material, innerMaterial, this.handlerScale)
        vertexMesh.position.set(position.x, 0.05, position.z)
        vertexMesh.index = this.vertexGroup.children.length

        this.vertexGroup.add(vertexMesh)

        this.addVertex(position)

        this.scale.y = 0.01
    }

    this.reInit = function (vertices, drawingRepresentationType) {
        this.drawingRepresentationType = drawingRepresentationType

        const {innerMaterial} = getDrawingTypesMaterial(this.drawingRepresentationType)

        vertices.forEach((position, index) => {
            const vertexMesh = createVertex(threeMaterial.transparent, innerMaterial, this.handlerScale)
            vertexMesh.position.set(position[0], 0.05, position[1])
            vertexMesh.index = index

            this.vertexGroup.add(vertexMesh)
        })

        for (let i = 0; i < this.vertexGroup.children.length - 1; i++) {
            const startVertex = this.vertexGroup.children[i]
            const endVertex = this.vertexGroup.children[i + 1]

            const edgeMesh = createEdge(startVertex, endVertex)
            edgeMesh.material = innerMaterial

            this.edgeGroup.add(edgeMesh)
        }

        this.scale.y = 0.01
        const faceGeometry = createFaceGeometry(this.vertexGroup.children)
        const faceMesh = new THREE.Mesh(faceGeometry, threeMaterial.transparent)
        faceMesh.name = 'face'
        faceMesh.position.y -= .01

        this.face = faceMesh

        this.add(this.face)

        this.setActive(false)
        this.closed = true
        this.lastVertex = null
    }

    this.addVertex = function (position) {
        const {material, innerMaterial} = getDrawingTypesMaterial(this.drawingRepresentationType)
        const vertexMesh = createVertex(material, innerMaterial, this.handlerScale)
        vertexMesh.position.set(position.x, 0.05, position.z)
        vertexMesh.index = this.vertexGroup.children.length

        this.lastVertex = vertexMesh
        this.vertexGroup.add(vertexMesh)

        if (this.drawingRepresentationType === drawingTypes.axes && this.vertexGroup.children.length > 2) {
            this.vertexGroup.children[this.vertexGroup.children.length - 2].geometry.dispose()
            this.vertexGroup.children[this.vertexGroup.children.length - 2].geometry = getCircleGeometry(drawingSize.edgeThickness, drawingSegments.vertex)
        }

        const previousVertex = this.vertexGroup.children[this.vertexGroup.children.length - 2]
        const edgeMesh = createEdge(previousVertex, vertexMesh)
        edgeMesh.material = innerMaterial

        this.edgeGroup.add(edgeMesh)
    }

    this.removeNewVertex = function () {
        if (this.vertexGroup.children.length <= 2) {
            return
        }

        this.vertexGroup.remove(this.vertexGroup.children[this.vertexGroup.children.length - 1])
        this.edgeGroup.remove(this.edgeGroup.children[this.edgeGroup.children.length - 1])

        this.lastVertex = this.vertexGroup.children[this.vertexGroup.children.length - 1]
        this.lastVertex.geometry.dispose()
        this.lastVertex.geometry = getCircleGeometry(drawingSize.edgeHandlerSize * this.handlerScale, drawingSegments.vertex)
    }

    this.endPolyline = function () {
        if (this.vertexGroup.children.length <= 2)
            return false

        this.vertexGroup.remove(this.lastVertex)
        this.edgeGroup.remove(this.edgeGroup.children[this.edgeGroup.children.length - 1])

        this.vertexGroup.children.forEach(vertex => vertex.material = threeMaterial.transparent)
    }

    this.updateVertex = function (vertex, position) {
        if (!vertex) {
            vertex = this.lastVertex
        }

        vertex.position.set(position.x, 0.05, position.z)

        let previousVertex
        let nextVertex

        for (let i = 0; i < this.vertexGroup.children.length; i++) {
            if (this.vertexGroup.children[i].uuid === vertex.uuid) {
                previousVertex = this.vertexGroup.children[((i + this.vertexGroup.children.length) - 1) % this.vertexGroup.children.length]
                nextVertex = this.vertexGroup.children[(i + 1) % this.vertexGroup.children.length]
            }
        }

        if (previousVertex) {
            let previousEdge = this.edgeGroup.children.find(edge => edge.vertices.includes(vertex.uuid) && edge.vertices.includes(previousVertex.uuid))

            if (previousEdge) {
                const path = new THREE.LineCurve3(previousVertex.position, vertex.position)
                previousEdge.geometry.dispose()
                previousEdge.geometry = new THREE.TubeGeometry(path, 1, drawingSize.edgeThickness)
                previousEdge.children[0].geometry.dispose()
                previousEdge.children[0].geometry = new THREE.TubeGeometry(path, 1, drawingSize.edgeCatchThickness)
            }
        }

        if (nextVertex) {
            let nextEdge = this.edgeGroup.children.find(edge => edge.vertices.includes(vertex.uuid) && edge.vertices.includes(nextVertex.uuid))

            if (nextEdge) {
                const path = new THREE.LineCurve3(nextVertex.position, vertex.position)
                nextEdge.geometry.dispose()
                nextEdge.geometry = new THREE.TubeGeometry(path, 1, drawingSize.edgeThickness)
                nextEdge.children[0].geometry.dispose()
                nextEdge.children[0].geometry = new THREE.TubeGeometry(path, 1, drawingSize.edgeCatchThickness)
            }
        }
    }

    this.deleteVertex = function (vertex) {
        if (this.vertexGroup.children.length <= 2)
            return

        let index = this.vertexGroup.children.indexOf(vertex)

        if (index < 0)
            return

        if (index === 0) {
            this.vertexGroup.remove(this.vertexGroup.children[0])
            this.edgeGroup.remove(this.edgeGroup.children[0])
        } else if (index === this.vertexGroup.children.length - 1) {
            this.vertexGroup.remove(this.vertexGroup.children[this.vertexGroup.children.length - 1])
            this.edgeGroup.remove(this.edgeGroup.children[this.edgeGroup.children.length - 1])
        } else {
            const previousVertex = this.vertexGroup.children[(index + this.vertexGroup.children.length - 1) % this.vertexGroup.children.length]
            const nextVertex = this.vertexGroup.children[(index + this.vertexGroup.children.length + 1) % this.vertexGroup.children.length]

            const neighborEdges = this.edgeGroup.children.filter(edge => vertex.edges.includes(edge.uuid))

            const {innerMaterial} = getDrawingTypesMaterial(this.drawingRepresentationType)

            const newEdge = createEdge(previousVertex, nextVertex)
            newEdge.material = innerMaterial

            this.edgeGroup = insertChildIntoGroup(this.edgeGroup, newEdge, index)

            this.edgeGroup.remove(neighborEdges[0])
            this.edgeGroup.remove(neighborEdges[1])

            this.vertexGroup.remove(vertex)

            this.vertexGroup.children.forEach(vertex => {
                removeItemFromArray(vertex.edges, neighborEdges[0].uuid)
                removeItemFromArray(vertex.edges, neighborEdges[1].uuid)
            })
        }

        this.deselectVertices()
    }

    this.selectVertex = function (vertex) {
        if (this.vertexGroup.children.length <= 2)
            return false

        if (this.drawingRepresentationType !== drawingTypes.axes) {
            this.vertexGroup.children.filter(v => v !== vertex)
                .forEach(v => {
                    v.geometry.dispose()
                    v.geometry = getCircleGeometry(drawingSize.edgeHandlerSizeDeselected * this.handlerScale, drawingSegments.vertex)
                })
        } else {
            this.vertexGroup.children.forEach(vertex => {
                vertex.geometry.dispose()
                vertex.geometry = getCircleGeometry(drawingSize.edgeThickness, drawingSegments.vertex)
            })
            this.vertexGroup.children[0].geometry.dispose()
            this.vertexGroup.children[0].geometry = getCircleGeometry(drawingSize.edgeHandlerSizeDeselected * this.handlerScale, drawingSegments.vertex)
            this.vertexGroup.children[this.vertexGroup.children.length - 1].geometry.dispose()
            this.vertexGroup.children[this.vertexGroup.children.length - 1].geometry = getCircleGeometry(drawingSize.edgeHandlerSizeDeselected * this.handlerScale, drawingSegments.vertex)
            vertex.geometry.dispose()
            vertex.geometry = getCircleGeometry(drawingSize.edgeHandlerSize * this.handlerScale, drawingSegments.vertex)
        }

        return true
    }

    this.deselectVertices = function () {
        if (this.drawingRepresentationType !== drawingTypes.axes) {
            this.vertexGroup.children.forEach(vertex => {
                vertex.geometry.dispose()
                vertex.geometry = getCircleGeometry(drawingSize.edgeHandlerSize * this.handlerScale, drawingSegments.vertex)
            })
        } else {
            this.vertexGroup.children.forEach(vertex => {
                vertex.geometry.dispose()
                vertex.geometry = getCircleGeometry(drawingSize.edgeThickness, drawingSegments.vertex)
            })
            this.vertexGroup.children[0].geometry.dispose()
            this.vertexGroup.children[0].geometry = getCircleGeometry(drawingSize.edgeHandlerSize * this.handlerScale, drawingSegments.vertex)
            this.vertexGroup.children[this.vertexGroup.children.length - 1].geometry.dispose()
            this.vertexGroup.children[this.vertexGroup.children.length - 1].geometry = getCircleGeometry(drawingSize.edgeHandlerSize * this.handlerScale, drawingSegments.vertex)
        }
    }

    this.reorderVertices = function (endVertex) {
        if (this.vertexGroup.children[0] === endVertex) {
            reorderGroup(this.vertexGroup)
            reorderGroup(this.edgeGroup)
        }
    }

    this.getSecondLastVertexPosition = function () {
        if (this.vertexGroup.children.length > 1)
            return this.vertexGroup.children[this.vertexGroup.children.length - 2].position.clone()
    }

    this.getPreviousVertexPosition = function (vertex) {
        if (this.vertexGroup.length < 2)
            return new THREE.Vector3(0, 0, 0)

        if (this.vertexGroup.children[0] === vertex)
            return this.vertexGroup.children[1].position

        if (this.vertexGroup.children[this.vertexGroup.children.length - 1] === vertex)
            return this.vertexGroup.children[this.vertexGroup.children.length - 2].position

        return new THREE.Vector3(0, 0, 0)
    }

    this.moveDelta = function (delta) {
        this.vertexGroup.children.forEach(vertex => {
            this.updateVertex(vertex, new THREE.Vector3(delta.x + vertex.position.x, 0.05, delta.z + vertex.position.z))
        })
    }

    this.setHandlerScale = function (handlerScale, update = true) {
        this.handlerScale = handlerScale

        if (update) {
            this.vertexGroup.children.forEach(v => {
                v.geometry.dispose()
                v.geometry = getCircleGeometry(drawingSize.edgeHandlerSize * this.handlerScale, drawingSegments.vertex)
            })
        }
    }

    this.setActive = function (active) {
        const {material, inactive} = getDrawingTypesMaterial(this.drawingRepresentationType)

        this.vertexGroup.children.forEach(vertex => vertex.material = active ? material : inactive)

        if (active) {
            this.deselectVertices()
        }
    }

    this.getGeometryAsJSON = function () {
        const vertices = []
        const geometryObject = {
            geometryId: this.geometryId,
            uuid: this.uuid,
            drawingRepresentationType: this.drawingRepresentationType,
            vertices: vertices,
            isValidate: this.isValidate,
            error: this.errorMessage,
            radius: this.radius,
            geometryType: this.geometryType
        }

        this.vertexGroup.children.forEach((vertex) => {
            const position = vertex.position
            vertices.push({x: position.x, y: -position.z})
        })

        return geometryObject
    }

    this.setGeometryFromJSON = function (json) {
        this.uuid = json.uuid
        this.drawingRepresentationType = json.drawingRepresentationType
        this.isValidate = json.isValidate
        this.errorMessage = json.error
        this.radius = json.radius
        this.geometryId = json.geometryId
        this.geometryType = json.geometryType
        const vertices = json.vertices
        const flippedVertices = []
        vertices
            .sort((a, b) => a.index - b.index)
            .forEach(vertex => flippedVertices.push([vertex.x, -vertex.y]))

        this.reInit(flippedVertices, json.drawingRepresentationType)
    }
}

DrawPolyline.prototype = Object.create(THREE.Mesh.prototype)
DrawPolyline.prototype.constructor = DrawPolyline
export {DrawPolyline}