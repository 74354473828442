import * as React from "react"

function MeetingCreatorSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinecap="square"
                strokeLinejoin="round"
            >
                <rect
                    width={18}
                    height={18}
                    x={6}
                    y={6}
                    fill="#58BCCC"
                    stroke={props.stroke || '#58BCCC'}
                    rx={9}
                />
                <path
                    fill="#FFF"
                    stroke={props.stroke || '#fff'}
                    d="M15 17.25l-2.645 1.391.505-2.946-2.14-2.086 2.957-.429L15 10.5l1.323 2.68 2.957.429-2.14 2.086.505 2.946z"
                />
            </g>
        </svg>


    )
}

export default MeetingCreatorSvgIcon
