import React from "react";

import clsx from "clsx";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles, MenuItem, Select} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const useStyle = (props) => makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap,
    },
    select: {
        height: '37px',
        borderRadius: props.admin ? theme.admin.borderRadius : '12px',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px ' + theme.colors.palette.neutral.greyMedium,
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0
        },
        '& .MuiSelect-select:focus': {
            background: 0
        },
        '& .MuiListItem-gutters': {
            paddingLeft: '12px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '0 6px'
        },
        '& .MuiListItem-root': {
            fontSize: theme.typography.caption.fontSize,
        },
    },
    arrow: {
        transform: 'rotate(-90deg)',
        marginRight: '12px',
        width: '20px',
        height: '20px',
        pointerEvents: 'none',
    }
}));

function Dropdown({title, onClick, disabled, halfWidth, fullWidth, background, options, initialSelected, ...props}) {

    const theme = useTheme();
    const classes = useStyle(props)(theme)
    const {className} = props

    function handleOnClick(e) {
        if (e) {
            e.stopPropagation()
        }
        if (typeof onClick === 'function' && e.target.value)
            onClick(e.target.value)
    }

    return (
        <div className={classes.root}
             style={{width: fullWidth ? '100%' : halfWidth ? '50%' : ''}}>
            {title &&
                <div style={{display: 'flex'}}>
                    <Typography align={'left'} variant={'caption'}>
                        {title}
                    </Typography>
                </div>
            }
            <Select className={clsx(classes.select, className)}
                    onClick={handleOnClick}
                    disabled={disabled}
                    variant={'outlined'}
                    value={(initialSelected && options.length) ? initialSelected : ''}
                    IconComponent={KeyboardArrowDownIcon}
                    renderValue={value =>
                        <MenuItem key={value.id} value={value}>
                            {value.name}
                        </MenuItem>
                    }
                    style={{
                        background: disabled ? theme.colors.palette.neutral.greyMedium : background,
                        width: fullWidth ? '100%' : halfWidth ? '50%' : '',
                    }}>
                {options && options.length
                    ? options.map(option => {
                        return <MenuItem value={option} key={option.id}>{option.name}</MenuItem>
                    })
                    : <MenuItem value={''}/>
                }
            </Select>
        </div>
    )
}

Dropdown.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    background: PropTypes.string,
    onClick: PropTypes.func,
    initialSelected: PropTypes.any,
    admin: PropTypes.bool,
}

export default Dropdown;