import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

import SearchSvgIcon from "../icons/SearchSvgIcon";
import CustomTextField from "../../components/CustomTextField";
import useTheme from "@material-ui/core/styles/useTheme";


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        '& .MuiOutlinedInput-input': {
            padding: '13.5px'
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px !important',
            minHeight: '54px',

            '& fieldset': {
                borderColor: theme.colors.palette.neutral.greyMedium,
            },
            '&:hover fieldset': {
                borderColor: theme.colors.palette.neutral.greyMain,
            },
        },
        flexGrow: 1
    },
    searchIcon: {
        height: '20px',
        width: '20px',
    },
    clickable: {
        cursor: 'pointer'
    }
}));

function SearchBar({searchString, setSearchString, placeholder, autofocus, className, ...props}) {

    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme)

    return (
        <CustomTextField variant={'outlined'} className={clsx(classes.textField, className)}
                         onChange={(event) => setSearchString(event.target.value)}
                         placeholder={placeholder || ''}
                         value={searchString}
                         autoFocus={autofocus}
                         contentLeft={<InputAdornment position="start">
                             <SearchSvgIcon className={classes.searchIcon}/>
                         </InputAdornment>}
                         contentRight={<Typography variant={'h5'} className={classes.clickable} onMouseDown={e => {
                             e.preventDefault();
                             setSearchString('')
                         }}>
                             {searchString && t('clear')}
                         </Typography>}
                         {...props}
        />
    )
}

SearchBar.propTypes = {
    placeholder: PropTypes.string,
    searchString: PropTypes.string.isRequired,
    setSearchString: PropTypes.func.isRequired,
    autofocus: PropTypes.bool,
    className: PropTypes.string,
    borderRadius: PropTypes.number,
}

SearchBar.defaultProps = {
    searchString: '',
    borderRadius: 12,
}

export default SearchBar
