import * as React from "react";

import useTheme from "@material-ui/core/styles/useTheme";

function EmptyStateWorkingGroupSvg(props) {

    const theme = useTheme()

    return (
        <svg width={330} height={215} viewBox="0 0 330 215"{...props}>
            <defs>
                <filter id="prefix__emptyWorkinggroup_a" width="114.7%" height="127%" x="-7.3%" y="-13.5%"
                        filterUnits="objectBoundingBox">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2.5}/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                                   values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 1 0"/>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <filter id="prefix__emptyWorkinggroup_b" width="142.4%" height="178.9%" x="-21.2%" y="-39.4%"
                        filterUnits="objectBoundingBox">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={10}/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                                   values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 1 0"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <filter id="prefix__emptyWorkinggroup_c" width="128.2%" height="152.9%" x="-14.1%" y="-26.4%"
                        filterUnits="objectBoundingBox">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation={5}
                    />
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                        values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 1 0"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g filter="url(#prefix__emptyWorkinggroup_a)" transform="translate(0 53)">
                    <rect width={181.953} height={97.013} x={1} y={1} fill="#FAFAFA" stroke="#E5E5E5" strokeWidth={2}
                          rx={9.047}/>
                    <g stroke="#FAFAFA" strokeWidth={1.005} opacity={0.75}>
                        <path
                            fill={theme.colors.palette.corporate.greenMain}
                            d="M27.14 59.307c2.314 0 4.481.626 6.343 1.716a12.64 12.64 0 014.507 4.507 12.517 12.517 0 011.716 6.342c0 2.314-.626 4.481-1.716 6.343a12.64 12.64 0 01-4.507 4.507 12.518 12.518 0 01-6.343 1.716c-2.313 0-4.48-.626-6.342-1.716a12.632 12.632 0 01-4.507-4.507 12.518 12.518 0 01-1.716-6.343c0-2.313.626-4.48 1.716-6.342a12.64 12.64 0 014.507-4.507 12.517 12.517 0 016.342-1.716z"
                        />
                        <path
                            fill="#FF985C"
                            d="M46.239 59.307c2.314 0 4.481.626 6.343 1.716a12.64 12.64 0 014.507 4.507 12.516 12.516 0 011.715 6.342c0 2.314-.625 4.481-1.715 6.343a12.64 12.64 0 01-4.507 4.507 12.518 12.518 0 01-6.343 1.716c-2.313 0-4.48-.626-6.342-1.716a12.624 12.624 0 01-4.507-4.507 12.518 12.518 0 01-1.716-6.343c0-2.313.626-4.48 1.716-6.342a12.632 12.632 0 014.507-4.507 12.514 12.514 0 016.342-1.716z"
                        />
                        <path
                            fill="#161616"
                            d="M65.338 59.307c2.314 0 4.48.626 6.342 1.716a12.627 12.627 0 014.508 4.507 12.516 12.516 0 011.715 6.342c0 2.314-.625 4.481-1.715 6.343a12.635 12.635 0 01-4.508 4.507 12.509 12.509 0 01-6.342 1.716c-2.313 0-4.48-.626-6.342-1.716a12.624 12.624 0 01-4.507-4.507 12.518 12.518 0 01-1.716-6.343c0-2.313.625-4.48 1.716-6.342a12.632 12.632 0 014.507-4.507 12.514 12.514 0 016.342-1.716z"
                        />
                    </g>
                    <rect width={57} height={15.078} x={15.078} y={14.575} fill="#B2B2B2" opacity={0.593} rx={3.016}/>
                    <rect width={38.198} height={8.042} x={15.512} y={43.435} fill="#D9D9D9" opacity={0.657} rx={2.01}/>
                </g>
                <g filter="url(#prefix__emptyWorkinggroup_b)" transform="translate(96 106)">
                    <rect width={201} height={107} x={1} y={1} fill="#FAFAFA" stroke="#E5E5E5" strokeWidth={2}
                          rx={10.025}/>
                    <g stroke="#FAFAFA" strokeWidth={1.114} opacity={0.75}>
                        <path
                            fill="#161616"
                            d="M30.075 65.719a13.88 13.88 0 019.845 4.078c2.52 2.52 4.078 6 4.078 9.846a13.88 13.88 0 01-4.078 9.845c-2.52 2.52-6 4.078-9.845 4.078a13.88 13.88 0 01-9.846-4.078c-2.52-2.52-4.078-6-4.078-9.845a13.88 13.88 0 014.078-9.846c2.52-2.52 6-4.078 9.846-4.078z"
                        />
                        <path
                            fill={theme.colors.palette.corporate.greenMain}
                            d="M51.238 65.719a13.88 13.88 0 019.846 4.078c2.52 2.52 4.078 6 4.078 9.846 0 3.844-1.559 7.325-4.078 9.845a13.88 13.88 0 01-9.846 4.078 13.876 13.876 0 01-9.845-4.078c-2.52-2.52-4.078-6-4.078-9.845a13.88 13.88 0 014.078-9.846c2.52-2.52 6-4.078 9.845-4.078z"
                        />
                        <path
                            fill="#FF985C"
                            d="M72.402 65.719c3.845 0 7.326 1.558 9.845 4.078 2.52 2.52 4.078 6 4.078 9.846a13.88 13.88 0 01-4.078 9.845c-2.52 2.52-6 4.078-9.845 4.078a13.88 13.88 0 01-9.846-4.078c-2.52-2.52-4.078-6-4.078-9.845 0-3.845 1.559-7.326 4.078-9.846 2.52-2.52 6-4.078 9.846-4.078z"
                        />
                        <path
                            fill="#B2B2B2"
                            d="M93.565 65.719a13.88 13.88 0 019.846 4.078c2.52 2.52 4.078 6 4.078 9.846 0 3.844-1.559 7.325-4.078 9.845-2.52 2.52-6 4.078-9.846 4.078a13.88 13.88 0 01-9.845-4.078c-2.52-2.52-4.078-6-4.078-9.845a13.88 13.88 0 014.078-9.846c2.52-2.52 6-4.078 9.845-4.078z"
                        />
                    </g>
                    <rect width={149} height={16.708} x={16.708} y={16.151} fill="#B2B2B2" opacity={0.593} rx={3.342}/>
                    <rect width={42.327} height={8.911} x={16.708} y={48.454} fill="#D9D9D9" opacity={0.657}
                          rx={2.228}/>
                </g>
                <g filter="url(#prefix__emptyWorkinggroup_c)" transform="translate(167)">
                    <rect width={161} height={85} x={1} y={1} fill="#FAFAFA" stroke="#E5E5E5" strokeWidth={2}
                          rx={8.02}/>
                    <g stroke="#FAFAFA" strokeWidth={0.891} opacity={0.75}>
                        <path
                            fill="#FF985C"
                            d="M24.06 52.574c3.076 0 5.86 1.247 7.876 3.263s3.263 4.8 3.263 7.876c0 3.076-1.247 5.86-3.263 7.876a11.101 11.101 0 01-7.876 3.263c-3.076 0-5.86-1.247-7.876-3.263a11.095 11.095 0 01-3.263-7.876c0-3.076 1.247-5.86 3.263-7.876s4.8-3.263 7.876-3.263z"
                        />
                        <path
                            fill={theme.colors.palette.corporate.greenMain}
                            d="M40.991 52.574c3.076 0 5.86 1.247 7.876 3.263s3.263 4.8 3.263 7.876c0 3.076-1.247 5.86-3.263 7.876a11.101 11.101 0 01-7.876 3.263c-3.076 0-5.86-1.247-7.876-3.263a11.101 11.101 0 01-3.263-7.876c0-3.076 1.247-5.86 3.263-7.876s4.8-3.263 7.876-3.263z"
                        />
                        <path
                            fill="#161616"
                            d="M57.922 52.574c3.076 0 5.86 1.247 7.876 3.263s3.263 4.8 3.263 7.876c0 3.076-1.247 5.86-3.263 7.876a11.101 11.101 0 01-7.876 3.263c-3.076 0-5.86-1.247-7.876-3.263a11.101 11.101 0 01-3.263-7.876c0-3.076 1.247-5.86 3.263-7.876a11.097 11.097 0 017.876-3.263z"
                        />
                        <path
                            fill="#B2B2B2"
                            d="M74.853 52.574c3.076 0 5.86 1.247 7.876 3.263s3.263 4.8 3.263 7.876c0 3.076-1.247 5.86-3.263 7.876a11.101 11.101 0 01-7.876 3.263c-3.076 0-5.86-1.247-7.876-3.263a11.101 11.101 0 01-3.263-7.876c0-3.076 1.247-5.86 3.263-7.876a11.097 11.097 0 017.876-3.263z"
                        />
                    </g>
                    <rect width={96} height={13.367} x={13.367} y={12.921} fill="#B2B2B2" opacity={0.593} rx={2.673}/>
                    <rect width={33.862} height={7.129} x={13.367} y={38.763} fill="#D9D9D9" opacity={0.657}
                          rx={1.782}/>
                </g>
            </g>
        </svg>
    )
}

export default EmptyStateWorkingGroupSvg
