import axios from "axios";

export function setUserTimezone(personId, timezone) {
    return {
        type: 'SET_USER_TIMEZONE',
        payload: axios.post(`/api/timezone/${personId}`, {}, {
            params: {
                timezone: timezone
            }
        })
    }
}