import * as React from "react"
import PropTypes from "prop-types";

function PeopleSvgIcon({width, height, color, ...props}) {
    return (
        <svg width={width || '100%'} height={height || '100%'} viewBox="0 0 40 40"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <g
                transform="translate(6.5 6)"
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
                stroke={color}
            >
                <circle cx={6} cy={4} r={4}/>
                <path d="M0 19v-4a4 4 0 014-4h2"/>
                <circle cx={21} cy={4} r={4}/>
                <path d="M21 11h2a4 4 0 014 4v4"/>
                <circle cx={13.5} cy={12.5} r={4}/>
                <path d="M7.5 27.5v-4a4 4 0 014-4h4a4 4 0 014 4v4"/>
            </g>
        </svg>
    )
}

PeopleSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

PeopleSvgIcon.defaultProps = {
    color: '#333',
};


export default PeopleSvgIcon
