import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";

import ActionListItem from "../../common/elements/ActionListItem";
import AvatarWithOccupancy from "../../common/elements/AvatarWithOccupancy";
import {sortAttendees} from "../../common/utils/MeetingUtils.js";
import {getAttendanceStatusIcon} from "../../common/utils/AssignmentUtils";
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'start',
        textAlign: 'left',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
}))

function MeetingParticipants({
                                 attendances,
                                 externalAttendances,
                                 ...props
                             }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    return (
        <div className={classes.root}>

            <Typography variant={"h6"}>
                {t('participants')}
            </Typography>
            {attendances && attendances.filter(attendance => attendance.person !== null).sort((a, b) => sortAttendees(a, b)).map(attendance => {
                return (
                    <ActionListItem text={attendance.person.name + ' ' + attendance.person.surname}
                                    avatarHeight={40}
                                    height={40}
                                    key={attendance.person.id}
                                    hideDivider
                                    startAdornment={<AvatarWithOccupancy size={40} person={attendance.person}
                                                                         hideAvailabilityStatus/>}
                                    endAdornment={getAttendanceStatusIcon(attendance.meetingAttendanceStatus)}/>
                )
            })}
            {externalAttendances && externalAttendances.sort((a, b) => {
                return a.email.localeCompare(b.email)
            })
                .map(attendance => {
                    return (
                        <ActionListItem text={attendance.email}
                                        textSecondRow={t('not_in_organization')}
                                        avatarHeight={40}
                                        height={40}
                                        key={attendance.email}
                                        hideDivider
                                        startAdornment={<AvatarWithOccupancy size={40} person={attendance}
                                                                             hideAvailabilityStatus/>}
                                        endAdornment={getAttendanceStatusIcon(attendance.meetingAttendanceStatus)}/>
                    )
                })}
        </div>
    )
}

MeetingParticipants.propTypes = {
    attendances: PropTypes.array.isRequired,
    externalAttendances: PropTypes.array,
}

MeetingParticipants.defaultProps = {
    attendances: [],
    externalAttendances: [],
}

export default MeetingParticipants
