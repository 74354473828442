import * as React from 'react'

const DrawInnerWallSvgIcon = props =>
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fillRule="nonzero" fill="none">
            <g fill="#333">
                <path
                    d="M11.25 6a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM17.25 7a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM17.25 12.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM14.25 16.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM6.75 16.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM6.75 8.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM11.25 11.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"/>
            </g>
            <rect stroke={props.stroke} x={2.5} y={3} width={19} height={18} rx={1}/>
        </g>
    </svg>

export default DrawInnerWallSvgIcon