const initialState = {
    element: {
        timePeriod: {}
    },
    modalResult: {}
}

function modalElementSkeletonReducer(state = initialState, action) {

    switch (action.type) {

        case 'SET_ELEMENT':
            return {
                ...state,
                element: action.payload
            }

        case 'SET_MODALRESULT':
            return {
                ...state,
                modalResult: action.payload
            }




        default:
            return state

    }

}

export default modalElementSkeletonReducer