import * as React from "react"
import PropTypes from 'prop-types'

function HomeSvgIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 40 40"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <g
                {...props}
                stroke={props.stroke}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path d="M3.5 20L20 7l16.5 13"/>
                <path d="M31.5 16.5v16h-23v-16"/>
                <path d="M16 32.5v-10h8v10"/>
            </g>
        </svg>
    )
}

HomeSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    stroke: PropTypes.string,
}

HomeSvgIcon.defaultProps = {
    width: 24,
    height: 24,
    stroke: '#333',
}


export default HomeSvgIcon
