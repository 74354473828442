import React, {useCallback} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles, Typography, useTheme} from "@material-ui/core";

import {setSelectedDate} from "../../../actions/workplaceAssignment-action.js";
import {isSameDay} from "../../../common/utils/TimeUtils.js";
import PropTypes from "prop-types";
import NavigationArrowSvgIcon from "../../../common/icons/NavigationArrowSvgIcon.js";
import SingleLineDatePicker from "../../SingleLineDatePicker.js";
import NavigationBackSvgIcon from "../../../common/icons/NavigationBackSvgIcon.js";
import clsx from "clsx";
import {isDateBookedByUserAllDay} from "../../../common/utils/AssignmentUtils.js";
import {getWorkingHoursOrDefaultHoursForDate} from "../../../common/customHooks/usePersonWorkingHours.js";

const useStyle = makeStyles(theme => ({
    header: {
        display: 'flex',
        gap: theme.innerGap,
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'column',
    },
    inline: {
        minHeight: '2.3rem',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },
    details: {
        display: 'flex',
        gap: theme.innerSmallGap,
        alignItems: 'center',
        cursor: 'pointer'
    },
    detailsHeader: {
        minHeight: '2.3rem',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingRight: '2.4rem',
        boxSizing: 'border-box',
        justifyContent: 'center'
    },
    topBottomPadding: {
        padding: theme.innerGap + ' 0'
    },
}))

function WorkplaceSelectionSpreadsheetHeader({
                                                 onDateChange,
                                                 selectedWorkplace,
                                                 detailsView,
                                                 setDetailsView,
                                                 setSelectedDate,
                                                 selectedDate,
                                                 assignments
                                             }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    function setDate(date) {
        setSelectedDate(date)
        if (!isSameDay(date, selectedDate)) {
            onDateChange(date)
        }
    }

    const showDateIndicator = useCallback((date) => {
        return isDateBookedByUserAllDay(date, assignments, getWorkingHoursOrDefaultHoursForDate(date))
    }, [assignments])

    if (selectedWorkplace && detailsView) {
        return <div className={clsx(classes.inline, classes.topBottomPadding)}>
            <NavigationBackSvgIcon size={24} onClick={() => setDetailsView(false)} className={classes.details}/>
            <div className={classes.detailsHeader}>
                <Typography variant={'h5'}>{selectedWorkplace.name}</Typography>
            </div>
        </div>
    } else {
        return <div className={classes.header}>
            <div className={classes.inline}>
                {selectedWorkplace && <Typography variant={'h5'}>{selectedWorkplace.name}</Typography>}
                {selectedWorkplace && <div className={classes.details} onClick={() => setDetailsView(true)}>
                    <Typography variant={'body1'}>{t('details')}</Typography>
                    <NavigationArrowSvgIcon reverse style={{transform: 'scale(-1)'}}/>
                </div>}
            </div>
            <SingleLineDatePicker
                onChange={date => {
                    setDate(date)
                }}
                showIndicator={showDateIndicator}
                preventInitialCallback
                selected={selectedDate}
                startDate={new Date()}/>
        </div>
    }
}

WorkplaceSelectionSpreadsheetHeader.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    detailsView: PropTypes.bool,
    setDetailsView: PropTypes.func
}

const mapStateToProps = state => {
    return {
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedWorkplace: state.workplace.favoredWorkplace,
        assignments: state.user.userAssignments,
    }
}

const mapDispatchToProps = {
    setSelectedDate: setSelectedDate
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSelectionSpreadsheetHeader)