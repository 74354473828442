import React, {useEffect} from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import {List, Typography} from "@material-ui/core";

import CustomButton from "../CustomButton.js";
import {getHomezone} from "../../actions/user-action";
import {
    navigate,
    PATH_BUILDING_SELECTION,
    PATH_CHOOSE_SEAT_SELECT,
    PATH_FLOOR_SELECTION
} from "../../common/utils/NavigationUtils";
import SimpleListItem from "../../common/elements/SimpleListItem.js";


const styles = theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    icon: {
        marginLeft: '16px',
    },
    label: {
        textAlign: 'start',
        padding: '0',
    },
    divider: {
        marginLeft: '0',
        width: '100%'
    },
    listRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px !important',
        '& .MuiListItem-root': {
            padding: '18px 0px 18px 0px'
        }
    },
    leftListItem: {
        textAlign: 'left',
    },
    rightListItem: {
        textAlign: 'right'
    },
})

function ChooseSeatDetails({userId, homeZone, selectedBuilding, floor, getHomezone, classes, t, theme}) {

    let history = useHistory()

    useEffect(() => {
        if (userId && null === homeZone)
            getHomezone(userId)
    }, [userId, getHomezone, homeZone])

    function routeToBuildings() {
        navigate(history, PATH_BUILDING_SELECTION)
    }

    function routeToFloors() {
        navigate(history, PATH_FLOOR_SELECTION)
    }


    function handleSelectSeatButton() {
        navigate(history, PATH_CHOOSE_SEAT_SELECT)
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}} className={classes.listRoot}>
                    <SimpleListItem title={t('building')} onClick={routeToBuildings}
                                    alternativeEndAdornment={
                                        <Typography sx={{display: 'inline'}} component="span">
                                            {selectedBuilding ? selectedBuilding.name : ''}
                                        </Typography>
                                    }
                    />
                    <SimpleListItem title={t('floor')} onClick={routeToFloors}
                                    alternativeEndAdornment={
                                        <Typography sx={{display: 'inline'}} component="span">
                                            {floor ? floor.name : ''}
                                        </Typography>
                                    }
                    />
                </List>

            </div>
            <div className={classes.buttonPlace}>
                <CustomButton text={t('show_map')}
                              onClick={handleSelectSeatButton}
                              disabled={!floor || !selectedBuilding}
                              fullWidth primary
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        buildings: state.buildings.buildingList,
        selectedBuilding: state.buildings.building,
        floor: state.floors.floor,
        homeZone: state.user.homeZone,
    }
}

const mapDispatchToProps = {
    getHomezone: getHomezone,
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(ChooseSeatDetails))
