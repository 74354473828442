import * as React from "react";

import useTheme from "@material-ui/core/styles/useTheme";
import PropTypes from "prop-types";

function ListEmptyStateSvg({width, height, ...props}) {

    const theme = useTheme()

    return (
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink"{...props}>
            <defs>
                <filter x="-14.1%" y="-13.5%" width="128.2%" height="128.8%" filterUnits="objectBoundingBox" id="a">
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation={10} in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                    <feColorMatrix values="0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.847058824 0 0 0 0.5 0"
                                   in="shadowBlurOuter1"
                    />
                </filter>
                <rect id="b" x={55} y={0} width={220} height={215} rx={10.025}/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <use fill="#000" filter="url(#a)" xlinkHref="#b"/>
                <rect stroke="#E5E5E5" strokeWidth={2} strokeLinejoin="square" fill="#FAFAFA" x={56} y={1} width={218}
                      height={213} rx={10.025}/>
                <rect fill="#D9D9D8" opacity={0.657} x={76} y={21} width={142} height={10} rx={2.642}/>
                <g transform="translate(76 55)">
                    <rect fill="#CFCFCF" opacity={0.818} x={43.069} y={3.726} width={121.932} height={19.826}
                          rx={3.963}/>
                    <path
                        d="M13.359-.557c3.843 0 7.322 1.558 9.84 4.076a13.872 13.872 0 0 1 4.076 9.84c0 3.855-1.556 7.34-4.072 9.861a13.86 13.86 0 0 1-9.844 4.083 13.872 13.872 0 0 1-9.84-4.076 13.872 13.872 0 0 1-4.076-9.84c0-3.855 1.556-7.34 4.072-9.861A13.86 13.86 0 0 1 13.36-.557z"
                        stroke="#FAFAFA"
                        strokeWidth={1.114}
                        fill="#161616"
                    />
                </g>
                <g transform="translate(76 91.751)">
                    <rect fill="#CFCFCF" opacity={0.818} x={43.069} y={3.726} width={102.942} height={19.826}
                          rx={3.963}/>
                    <path
                        d="M13.359-.557c3.843 0 7.322 1.558 9.84 4.076a13.872 13.872 0 0 1 4.076 9.84c0 3.855-1.556 7.34-4.072 9.861a13.86 13.86 0 0 1-9.844 4.083 13.872 13.872 0 0 1-9.84-4.076 13.872 13.872 0 0 1-4.076-9.84c0-3.855 1.556-7.34 4.072-9.861A13.86 13.86 0 0 1 13.36-.557z"
                        stroke="#FAFAFA"
                        strokeWidth={1.114}
                        fill={theme.colors.palette.corporate.greenMain}
                    />
                </g>
                <g transform="translate(76 128.502)">
                    <rect
                        fill="#CFCFCF"
                        opacity={0.818}
                        x={44.068}
                        y={3.726}
                        width={73.959}
                        height={19.826}
                        rx={3.963}
                    />
                    <path
                        d="M13.359-.557c3.843 0 7.322 1.558 9.84 4.076a13.872 13.872 0 0 1 4.076 9.84c0 3.855-1.556 7.34-4.072 9.861a13.86 13.86 0 0 1-9.844 4.083 13.872 13.872 0 0 1-9.84-4.076 13.872 13.872 0 0 1-4.076-9.84c0-3.855 1.556-7.34 4.072-9.861A13.86 13.86 0 0 1 13.36-.557z"
                        stroke="#FAFAFA"
                        strokeWidth={1.114}
                        fill="#FF985C"
                    />
                </g>
                <g transform="translate(76 165.254)">
                    <rect
                        fill="#CFCFCF"
                        opacity={0.818}
                        x={44.068}
                        y={2.726}
                        width={121.932}
                        height={19.826}
                        rx={3.963}
                    />
                    <path
                        d="M13.359-.557c3.843 0 7.322 1.558 9.84 4.076a13.872 13.872 0 0 1 4.076 9.84c0 3.855-1.556 7.34-4.072 9.861a13.86 13.86 0 0 1-9.844 4.083 13.872 13.872 0 0 1-9.84-4.076 13.872 13.872 0 0 1-4.076-9.84c0-3.855 1.556-7.34 4.072-9.861A13.86 13.86 0 0 1 13.36-.557z"
                        stroke="#FAFAFA"
                        strokeWidth={1.114}
                        fill="#B2B2B2"
                    />
                </g>
            </g>
        </svg>
    )
}

ListEmptyStateSvg.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

ListEmptyStateSvg.defaultProps = {
    width: 330,
    height: 215,
}


export default ListEmptyStateSvg
