import React from 'react';
import PropTypes from "prop-types";
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import Tag from "./Tag.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        textAlign: 'left',
        gap: '.9rem'
    },
    tags: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '.6rem',
        overflow: 'hidden'
    },
}))

function TagsPanel({workplace, hideTitle, tagRows, ...props}) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);

    if (!workplace || !workplace.tags || workplace.tags.length === 0)
        return null

    //limit tags count
    workplace.tags.length = 10

    const maxHeight = () => {
        return tagRows * 34 + (tagRows - 1) * 6 + 'px'
    }

    return (
        <div className={classes.root}>
            {!hideTitle && <Typography variant={'h6'}>{t('tags')}</Typography>}
            <div className={classes.tags} style={{maxHeight: maxHeight()}}>
                {workplace.tags.map(tag => {
                    return <Tag tag={tag}/>
                })}
            </div>
        </div>
    )
}

TagsPanel.prototype = {
    workplace: PropTypes.any.isRequired,
    hideTitle: PropTypes.bool,
    tagRows: PropTypes.number
}

TagsPanel.defaultProps = {
    tagRows: 2
}

export default TagsPanel
