import React from 'react';
import {Card, Typography, withStyles} from "@material-ui/core";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import RatingsSvgIcon from "../../common/icons/RatingsSvgIcon";
import {getActivityIcon} from "../../common/utils/ActivityUtils.js";
import CustomButton from "../CustomButton.js";
import clsx from "clsx";

const styles = theme => ({
    root: {
        width: '100%',
        height: '18rem',
        padding: '2.4rem',
        borderRadius: '1.2rem',
        border: 'solid 1px ' + theme.colors.palette.neutral.greyMedium,
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        display: 'flex'
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'left'
    },
    leftContent: {
        flex: 1,
        paddingRight: '2.4rem'
    },
    rightContent: {
        borderLeft: '1px dashed ' + theme.colors.palette.neutral.greyMedium,
        alignItems: 'center',
        paddingLeft: '2.4rem',
        flex: 0
    },
    icon: {
        width: '2.4rem',
        height: '2.4rem',
        alignSelf: 'center'
    }
})

class RatingsCard extends React.Component {

    render() {
        const {classes, t, assignment, theme} = this.props;

        return (
            <Card variant="outlined" className={classes.root}>
                <div className={clsx(classes.leftContent, classes.vertical)}>
                    <div className={classes.vertical}>
                        <Typography variant={"body1"}>
                            {assignment ? moment(assignment.timePeriod.startDate).format("dd, DD.MM.YYYY") : null}
                        </Typography>
                        <Typography variant={'h5'}>
                            {assignment && assignment.workplace.name}
                        </Typography>
                    </div>
                    <CustomButton customClassName={classes.button}
                                  icon={<RatingsSvgIcon className={classes.icon}/>}
                                  text={t("rate")}
                                  textColor={theme.colors.palette.neutral.darkMain}
                                  onClick={this.props.onRate}/>
                </div>
                <div className={clsx(classes.rightContent, classes.vertical)}>
                    <div className={classes.vertical}>
                        <Typography variant={'caption'}>{t('activity')}</Typography>
                        {getActivityIcon(this.props.assignment.activity, assignment.id + this.props.assignment.activity + '-icon', classes.icon)}
                    </div>
                </div>
            </Card>
        );
    }
}

RatingsCard.propTypes = {
    assignment: PropTypes.object.isRequired,
    onRate: PropTypes.func.isRequired
};

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(RatingsCard)