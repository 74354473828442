import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import ChooseSeatSvgIcon from "../../common/icons/ChooseSeatSvgIcon.js";
import TimePickerSvgIcon from "../../common/icons/TimePickerSvgIcon.js";
import LocationFilledSvgIcon from "../../common/icons/LocationFilledSvgIcon.js";
import moment from "moment";
import {
    getDateAtEndOfWorkingDay,
    getDateAtStartOfWorkingDay,
    getDurationAsString
} from "../../common/utils/TimeUtils.js";
import PeopleSvgIcon from "../../common/icons/PeopleSvgIcon.js";
import CardWrapper from "../../common/elements/CardWrapper.js";
import TimelineWithHeaderAndError from "../../common/elements/TimelineWithHeaderAndError.js";


const useStyle = makeStyles(theme => ({
    card: {
        height: '23rem',
        padding: '2.4rem',
        boxSizing: 'border-box'
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.0rem',
    },
    info: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '.6rem',
    },
    inlineElement: {
        display: 'flex',
        gap: '.6rem',
        alignItems: 'center',

    },
    skinMain: {
        color: theme.colors.palette.corporate.skinMain,
        textTransform: 'Capitalize'
    },
    title: {
        display: 'flex'
    },
    locationIcon: {
        width: '1.2rem',
        height: '1.2rem',
        fill: theme.colors.palette.corporate.skinMain,
        stroke: theme.colors.palette.corporate.skinMain
    },
    icon: {
        width: '2.4rem',
        height: '2.4rem',
        stroke: theme.colors.palette.neutral.greyMain,
    },
    selected: {
        border: 'solid 2px !important',
        borderColor: theme.colors.palette.corporate.skinMain + '!important',
        borderRadius: '18px',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.3)',
    },
    warning: {
        color: theme.colors.palette.corporate.redMain
    }
}))

function MeetingSuggestionCard({meeting, selectMeetingCallback, selected}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);

    function getLocation() {
        if (meeting.onlineOnly || (!meeting.buildingName || !meeting.floorName))
            return t('not_available')
        return meeting.buildingName
            ? meeting.buildingName + ', ' + meeting.floorName
            : meeting.floorName
    }

    const showFrom = useMemo(() => {
        return getDateAtStartOfWorkingDay(meeting.timePeriod.startDate)
    }, [meeting])
    const showUntil = useMemo(() => {
        return getDateAtEndOfWorkingDay(meeting.timePeriod.startDate)
    }, [meeting])

    return (
        <CardWrapper className={classes.card} fullWidth isSelected={selected}
                     onClick={() => selectMeetingCallback(meeting)}>
            <div className={classes.content}>
                <div className={classes.inlineElement}>
                    <LocationFilledSvgIcon className={classes.locationIcon}/>
                    <Typography variant={'overline'} className={classes.skinMain}>
                        {getLocation()}
                    </Typography>
                </div>
                <div className={classes.info}>
                    <Typography variant={'h3'} className={classes.title}>
                        {meeting.space ? meeting.space.name : 'online_meeting'}
                    </Typography>

                    <div className={classes.inlineElement}>
                        <TimePickerSvgIcon className={classes.icon}/>
                        <Typography variant={'h6'}>
                            {moment(meeting.timePeriod.startDate).format('dd, DD.MM | HH:mm - ')
                                + moment(meeting.timePeriod.endDate).format(`HH:mm | ${getDurationAsString(meeting.timePeriod)}`)
                                + '\u{202F}h'
                            }
                        </Typography>
                    </div>
                    {!meeting.onlineOnly && meeting.space.name !== "Online Meeting"
                        ? <div className={classes.inlineElement}>
                            <ChooseSeatSvgIcon className={classes.icon}/>
                            <Typography variant={'h6'}
                                        className={(meeting.attendances.length + meeting.externalAttendances.length) > meeting.space.availableSpace && classes.warning}>
                                {meeting.attendances.length + meeting.externalAttendances.length}/{meeting.space.availableSpace} {t('places')}
                            </Typography>
                        </div>
                        : <div className={classes.inlineElement}>
                            <PeopleSvgIcon className={classes.icon} color={theme.colors.palette.neutral.greyMain}/>
                            <Typography variant={'h6'}>
                                {meeting.attendances.length + meeting.externalAttendances.length} {t('persons')}
                            </Typography>
                        </div>}
                </div>

                <TimelineWithHeaderAndError hideTimeHeader
                                            timePeriod={{
                                                startDate: new Date(meeting.timePeriod.startDate),
                                                endDate: new Date(meeting.timePeriod.endDate)
                                            }}
                                            showFrom={showFrom}
                                            showUntil={showUntil}/>
            </div>
        </CardWrapper>
    )
}

MeetingSuggestionCard.propTypes = {
    meeting: PropTypes.object.isRequired,
    selectMeetingCallback: PropTypes.func.isRequired,
    selected: PropTypes.bool
}

export default MeetingSuggestionCard
