import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from "react-i18next";

import {makeStyles, Slider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";


const useStyle = props => makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiSlider-rail': {
            height: 5,
            borderRadius: 8,
            background: props.color ? props.color : theme.colors.gradients.skinMain,
        },
        '& .MuiSlider-track': {
            height: 5,
            backgroundColor: theme.colors.palette.corporate.skinMain,
        },
        '& .MuiSlider-mark': {
            height: 5,
            width: 1,
            background: theme.colors.palette.corporate.skinDark,
        },
        '& .MuiSlider-thumb': {
            height: 20,
            width: 20,
            marginTop: '-8px',
            background: theme.colors.palette.corporate.skinMain,
        },
        '& .MuiSlider-valueLabel': {
            color: 'red',
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    sliderContainer: {
        padding: '0 1.2rem',
    },
}));

function DurationSlider({value, color, onChange, title, hours, ...props}) {

    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(props)(theme);

    const [selectedValue, setSelectedValue] = useState(value || 1);

    useEffect(() => {
        setSelectedValue(null !== value ? value : 1)
    }, [value])

    const marks = useMemo(() => {
        let marks = [...Array(hours * 2).keys()].map(h => {

            if (h === 0) {
                return {
                    value: 0.25, label: <Typography variant={'overline'}>
                        {15 + 'min'}
                    </Typography>
                }
            }

            if (h % hours / 2 === 0)
                return {
                    value: h / 2,
                    label: <Typography variant={'overline'}>
                        {h / 2 + 'h'}
                    </Typography>
                }

            return {value: h / 2}
        })

        marks.push({
            value: hours,
            label: <Typography variant={'overline'}>
                {hours.toString() + 'h'}
            </Typography>
        })

        marks.push({value: 0.75})

        return marks
    }, [hours])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant={"h6"}>{t('duration')}</Typography>
                <Typography>{selectedValue > 1 ? selectedValue + 'h' : (selectedValue * 60) + 'min'}</Typography>
            </div>
            <div className={classes.sliderContainer}>
                <Slider value={selectedValue}
                        step={null}
                        max={hours}
                        marks={marks}
                        onChange={onChange ? (e, value) => onChange(value) : (e, value) => {
                            setSelectedValue(value)
                        }}
                />
            </div>
        </div>
    );
}

DurationSlider.propTypes = {
    value: PropTypes.number,
    color: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.string,
    hours: PropTypes.number,
};

DurationSlider.defaultProps = {
    hours: 7,
};

export default DurationSlider;