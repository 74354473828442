import * as THREE from 'three'
import {
    createCircleWithImageActiveMarker,
    createCircleWithTextAndActiveMarker,
    drawingColor,
    drawingOpacity,
    drawingSize,
    drawingTypes,
    threeMaterial,
} from "./drawConstants";
import {
    DRAWING_REPRESENTATION_TYPE_WORKPLACE,
    GEOMETRY_TYPE_POINT,
    TYPE_OF_ACTIVITY_CALLS,
    TYPE_OF_ACTIVITY_CONCENTRATE,
    TYPE_OF_ACTIVITY_CREATIVE,
    TYPE_OF_ACTIVITY_TALK,
    WORKPLACE_STATUS_INACTIVE_AUTO,
    WORKPLACE_STATUS_INACTIVE_MANUAL
} from "../../../common/utils/NameUtils";
import {getInitials} from "../../../common/elements/AvatarWithOccupancy";
import AnonymUser from "../../../common/img/icons-map-anonym-user@3x.png";
import {
    getActivityColor,
    getBackgroundColorForWorkplace,
    getWorkplaceImage
} from "../../../common/utils/AssignmentUtils.js";
import {getRadiansOfTimeInCircle} from "../../../common/utils/DrawingUtils.js";
import {
    getTodayAtDefaultEndOfWorkingDay,
    getTodayAtDefaultStartOfWorkingDay,
    sortAssignments
} from "../../../common/utils/TimeUtils.js";

function DrawWorkplace() {
    this.loader = new THREE.TextureLoader();
    this.toBeDeleted = false
    this.updated = false

    THREE.Mesh.apply(this, arguments)

    this.init = function (position, workplaceType, activity) {
        if (!this.geometryType)
            this.geometryType = GEOMETRY_TYPE_POINT
        if (!this.drawingRepresentationType)
            this.drawingRepresentationType = DRAWING_REPRESENTATION_TYPE_WORKPLACE
        this.reInit(position, workplaceType, activity)
    }

    this.reInit = function (position, workplaceType, activity, radius = null) {
        this.workplaceType = isBlocked()
            ? drawingTypes.workplaceBlocked
            : workplaceType

        this.activityBestMatch = activity

        let image, initials


        if (this.profilePicture) {
            image = this.profilePicture
        } else if (this.assignments && this.assignments.length) {
            if (this.assignments[0].person.id !== -1) {
                const person = this.assignments[0].person
                initials = getInitials(person)
            } else {
                image = AnonymUser
            }
        } else {
            image = getWorkplaceImage(this.status, this.activityBestMatch)
        }

        this.radius = radius ? radius : drawingSize.workplace
        if (this.workplaceMesh && this.workplaceMesh.geometry)
            this.workplaceMesh.geometry.dispose()

        const timeSettings = localStorage.getItem('occupancyTimeSettings')
            ? JSON.parse(localStorage.getItem('occupancyTimeSettings'))
            : {startDate: getTodayAtDefaultStartOfWorkingDay(), endDate: getTodayAtDefaultEndOfWorkingDay()}
        let sortedAssignments = sortAssignments(this.assignments)

        let radians = []
        sortedAssignments.forEach(assignment => {
            let startTime = assignment?.timePeriod?.startDate
            let endTime = assignment?.timePeriod?.endDate

            let startRadians = getRadiansOfTimeInCircle(timeSettings.startDate, timeSettings.endDate, startTime)
            let endRadians = getRadiansOfTimeInCircle(timeSettings.startDate, timeSettings.endDate, endTime)

            radians.push({startRadian: startRadians, endRadian: endRadians})
        })

        const innerColor = this.workplaceType === drawingTypes.workplaceNotPermitted
            ? getActivityColor(this.activityBestMatch)
            : drawingColor.white

        const backgroundColor = getBackgroundColorForWorkplace(this.activityBestMatch, this.workplaceType)

        if (image) {
            if (!image.startsWith('data:image/png;base64,'))
                image = 'data:image/png;base64,' + image
            this.workplaceMesh = createCircleWithImageActiveMarker(this.radius, image, radians, this.isAssigned, innerColor, backgroundColor)
        } else if (initials) {
            this.workplaceMesh = createCircleWithTextAndActiveMarker(this.radius, initials, radians, this.isAssigned, backgroundColor)
        }

        this.workplaceMesh.position.set(0, 0.05, 0)

        this.position.set(position.x, 0.05, position.z)

        this.add(this.workplaceMesh)
    }

    this.updatePosition = function (position) {
        this.position.set(position.x, 0.05, position.z)
        this.updated = true
    }

    this.setPlaceable = function (placeable) {
        if (this.workplaceMesh && this.workplaceMesh.children.length && this.workplaceMesh.children[0].children.length) {
            const backgroundColor = getBackgroundColorForWorkplace(this.activityBestMatch, this.workplaceType)

            if (placeable) {
                this.workplaceMesh.children[0].children[0].material = new THREE.MeshBasicMaterial({
                    color: backgroundColor,
                    opacity: drawingOpacity.workplace
                })
            } else {
                this.workplaceMesh.children[0].children[0].material = new THREE.MeshBasicMaterial({
                    color: backgroundColor,
                    opacity: drawingOpacity.workplaceDisabled,
                    transparent: true,
                })
            }
        }
    }

    this.setActive = function (active) {
        if (this.workplaceMesh && !this.active === active) {
            this.active = active
            let indexWorkplaceMarkerMesh = this.workplaceMesh.children.findIndex(mesh => mesh.name === 'circleMesh')
            const activeMovementY = 1.5 * this.radius

            if (active) {
                if (indexWorkplaceMarkerMesh > -1)
                    this.workplaceMesh.children[indexWorkplaceMarkerMesh].position.setY(this.workplaceMesh.children[indexWorkplaceMarkerMesh].position.y + activeMovementY)
            } else {
                if (indexWorkplaceMarkerMesh > -1)
                    this.workplaceMesh.children[indexWorkplaceMarkerMesh].position.setY(this.workplaceMesh.children[indexWorkplaceMarkerMesh].position.y - activeMovementY)
            }
        }
    }

    this.setInvisible = function () {
        this.workplaceMesh.children.forEach(vertex => vertex.material = threeMaterial.transparent)
    }

    const isBlocked = () => {
        return this.status === WORKPLACE_STATUS_INACTIVE_MANUAL || this.status === WORKPLACE_STATUS_INACTIVE_AUTO
    }

    this.getGeometryAsJSON = function () {
        return {
            geometryId: this.geometryId,
            vertices: [{
                x: this.position.x,
                y: this.position.z
            }],
            activityBestMatch: this.activityBestMatch,
            seatAvailable: !this.isAssigned,
            status: this.status,
            workplace: this.workplace,
            geometryType: this.geometryType,
            updated: this.updated,
            toBeDeleted: this.toBeDeleted,
            drawingRepresentationType: this.drawingRepresentationType,
        }
    }

    this.setFromWorkplace = function (json, scale, assignments, profilePicture) {
        this.updated = json.updated ? json.updated : false
        this.toBeDeleted = json.toBeDeleted
        this.activityBestMatch = json.activityBestMatch
        this.status = json.status
        this.geometryId = json.geometry && json.geometry.geometryId ? json.geometry.geometryId : json.geometryId
        this.geometryType = json.geometryType ? json.geometryType : GEOMETRY_TYPE_POINT
        this.workplace = json.workplace ? json.workplace : json
        this.drawingRepresentationType = json.drawingRepresentationType ? json.drawingRepresentationType : DRAWING_REPRESENTATION_TYPE_WORKPLACE
        this.isBlocked = isBlocked()
        this.vertices = json.vertices
        this.assignments = assignments
        this.profilePicture = profilePicture
        this.active = false
        this.userAllowedToBook = json.userAllowedToBook

        if (this.isBlocked)
            this.isAssigned = false
        else
            this.isAssigned = !json.seatAvailable

        let workplaceType

        if (isBlocked()) {
            workplaceType = drawingTypes.workplaceBlocked
        } else if (!json.userAllowedToBook) {
            workplaceType = drawingTypes.workplaceNotPermitted
        } else if (this.activityBestMatch === TYPE_OF_ACTIVITY_CREATIVE) {
            workplaceType = drawingTypes.workplaceCreative
        } else if (this.activityBestMatch === TYPE_OF_ACTIVITY_CALLS) {
            workplaceType = drawingTypes.workplaceCalls
        } else if (this.activityBestMatch === TYPE_OF_ACTIVITY_TALK) {
            workplaceType = drawingTypes.workplaceTalk
        } else if (this.activityBestMatch === TYPE_OF_ACTIVITY_CONCENTRATE) {
            workplaceType = drawingTypes.workplaceConcentrate
        } else {
            workplaceType = drawingTypes.workplaceUndefined
        }
        let vertex = this.workplace.vertices ? this.workplace.vertices[0] : this.workplace.geometry.vertices[0]

        this.reInit({x: vertex.x, y: 0.01, z: vertex.y}, workplaceType, this.activityBestMatch)
    }
}

DrawWorkplace.prototype = Object.create(THREE.Mesh.prototype)
DrawWorkplace.prototype.constructor = DrawWorkplace
export {DrawWorkplace}