import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";
import {
    getDayDiffOfTimePeriod,
    getTotalBreakMinutes,
    getTrackingsBreakHoursAsString,
    getWorkedHoursAsString,
    getWorkingTimeInMinutes
} from "../../../common/utils/TimeUtils.js";
import hash from "object-hash";
import TimeTrackingEntryCard from "./TimeTrackingEntryCard.js";
import {useCookies} from "react-cookie";
import BreakSvgIcon from "../../../common/icons/BreakSvgIcon.js";
import TimeTrackingInfoModal from "./TimeTrackingInfoModal.js";
import WorkTimeSvgIcon from "../../../common/icons/WorkTimeSvgIcon.js";
import TimeTrackingEmptyStateSvgIcon from "../../../common/icons/TimeTrackingEmptyStateSvgIcon.js";
import EditTimeTrackingModal from "./EditTimeTrackingModal";
import StartAndEndTime from "../../../common/elements/StartAndEndTime.js";
import DayTrackingCard from "./DayTrackingCard.js";
import {deleteTimeTracking, deleteTimeTrackingBreak} from "../../../actions/timetracking-actions.js";

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        textAlign: 'left',
    },
    entryPanel: {
        display: 'flex',
        gap: theme.innerGap
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dayEntry: {
        display: 'flex',
        width: '100%',
        gap: theme.innerGap
    },
    dayTimes: {
        width: '4.4rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    timeSummedUp: {
        display: 'flex',
        gap: '6px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyStateWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}))

function TimeTrackingOverviewContent({
                                         timePeriod,
                                         userTimeTrackingStatistics,
                                         onCardClickCallback,
                                         selectedMoment,
                                         userId,
                                         deleteTimeTracking,
                                         deleteTimeTrackingBreak,
                                         newModalOpen,
                                         onCloseNewModal
                                     }) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);
    const cookies = useCookies(['language'])[0] //get cookie only

    const [selectedTimeTracking, setSelectedTimeTracking] = useState(null);
    const [selectedBreak, setSelectedBreak] = useState(null);
    const [editTimeTrackingMode, setEditTimeTrackingMode] = useState(false);

    const totalBookingsData = React.useMemo(
        () => {
            if (!timePeriod || !timePeriod.startDate || !timePeriod.endDate)
                return null

            let data = []
            let totalWorkingTime = 0, totalBreakTime = 0
            let isHourly = false, isMonthly = false, isWeekly = false, isDaily = false
            let lastMonth = -1, lastWeek = -1, lastDay = -1

            let dayDiff = getDayDiffOfTimePeriod(timePeriod)

            //year
            if (dayDiff >= 364) {
                isMonthly = true
                //month
            } else if (dayDiff >= 27) {
                isWeekly = true
                //week
            } else if (dayDiff >= 6) {
                isDaily = true
            } else if (dayDiff < 6) {
                isHourly = true
            }

            let timeTrackings = []
            for (const [key, value] of Object.entries(userTimeTrackingStatistics).sort()) {
                let dailyWorkTime = getWorkingTimeInMinutes(value)
                let dailyBreakTime = getTotalBreakMinutes(value)
                totalWorkingTime += dailyWorkTime
                totalBreakTime += dailyBreakTime

                if ((isMonthly && lastMonth === moment(key).locale(cookies['language']).month()) || (isWeekly && lastWeek === moment(key).locale(cookies['language']).week())) {
                    timeTrackings.push(...value)
                } else {
                    if ((isMonthly && lastMonth !== -1) || (isWeekly && lastWeek !== -1) || ((isDaily || isHourly) && lastDay !== -1)) {
                        let unit = isWeekly ? 'week' : isMonthly ? 'month' : 'day'
                        let format = isWeekly ? 'w' : isMonthly ? 'M' : 'e'
                        data.push({
                            key: moment(key).locale(cookies['language']).subtract(1, unit).format(format),
                            trackings: timeTrackings,
                            startDate: moment(key).subtract(1, 'day').startOf(unit),
                            endDate: moment(key).subtract(1, 'day').endOf(unit)
                        })
                    }

                    if (isMonthly)
                        lastMonth = moment(key).locale(cookies['language']).month()
                    if (isWeekly)
                        lastWeek = moment(key).locale(cookies['language']).week()
                    if (isDaily || isHourly)
                        lastDay = moment(key).locale(cookies['language']).weekday()

                    timeTrackings = value
                }
            }

            if ((isMonthly && lastMonth !== -1) || (isWeekly && lastWeek !== -1) || isDaily || isHourly) {
                data.push({
                    key: isMonthly ? lastMonth + 1 : isWeekly ? lastWeek : lastDay,
                    trackings: timeTrackings,
                })
            }

            return {
                trackings: data,
                totalWorkTime: totalWorkingTime,
                totalBreakTime: totalBreakTime,
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userTimeTrackingStatistics]
    )

    function getEntryCard(entry, dayDiff) {
        return <div className={classes.entryPanel} key={hash(entry)}>
            <Typography variant={'caption'} style={{flexShrink: 0, width: '4.4rem'}}>
                {getKeyString(entry.key)}
            </Typography>
            <TimeTrackingEntryCard onClickCallback={onCardClickCallback}
                                   timeUnitNumber={Number(entry.key)}
                                   timeTrackings={entry.trackings}
                                   dayDiff={dayDiff}
                                   selectedMoment={entry.startDate ? moment(entry.startDate) : selectedMoment}
                                   key={hash(entry)}/>
        </div>
    }

    function getDayEntryCard(tracking) {
        return (
            <div className={classes.dayEntry} key={hash(tracking)}>
                <StartAndEndTime startTime={tracking.clockInTime}
                                 endTime={tracking.clockOutTime ? tracking.clockOutTime : new Date()}/>
                <DayTrackingCard timeTracking={tracking}
                                 onTimeTrackingClick={() => {
                                     setSelectedTimeTracking(tracking)
                                     setSelectedBreak(null)
                                 }}
                                 onBreakClick={(b) => {
                                     setSelectedTimeTracking(null)
                                     setSelectedBreak(b)
                                 }}
                />
            </div>
        )
    }

    function getDayEntryCards(entry, entryIndex) {
        if (!totalBookingsData.trackings.map(value => value.trackings).flatMap(e => e).length && entryIndex === totalBookingsData.trackings.length - 1) {
            return getEmptyState()

        }
        return entry.trackings.sort((t1, t2) => t1.clockInTime > t2.clockInTime ? 1 : -1).map(tracking => getDayEntryCard(tracking))
    }

    function getKeyString(key) {
        let dayDiff = getDayDiffOfTimePeriod(timePeriod)
        if (dayDiff >= 364) {
            return selectedMoment.clone().month(key - 1).format('MMM')
        } else if (dayDiff >= 27) {
            return t('cw') + ' ' + selectedMoment.clone().week(key).format('ww')
        } else if (dayDiff >= 6) {
            return selectedMoment.locale(cookies['language']).clone().weekday(key).format('dd') + ', ' + selectedMoment.locale(cookies['language']).clone().weekday(key).format('DD') + '.'
        }
    }

    function getAllTrackingsAsArray(trackings) {
        return trackings.reduce((acc, val) => acc.concat(val.trackings), [])
    }

    function onCloseInfoModal() {
        setSelectedTimeTracking(null)
        setSelectedBreak(null)
    }

    function onCloseEditModal() {
        setSelectedTimeTracking(null)
        setSelectedBreak(null)
        setEditTimeTrackingMode(false)
        onCloseNewModal && onCloseNewModal()
    }

    const getEmptyState = () => {
        return <div className={classes.emptyStateWrapper}>
            <TimeTrackingEmptyStateSvgIcon/>
            <Typography variant={'h5'} align={'center'}>{t('no_timetrackings')}</Typography>
            <Typography variant={'body1'} align={'center'}>{t('no_timetrackings_info')}</Typography>
        </div>
    }

    return (
        <div className={classes.root}>
            {totalBookingsData &&
                <div className={classes.timeSummedUp}>
                    <WorkTimeSvgIcon color={theme.colors.palette.neutral.greyMain} width={18}/>
                    <Typography
                        variant={'caption'}>{getWorkedHoursAsString(getAllTrackingsAsArray(totalBookingsData.trackings))},</Typography>
                    <BreakSvgIcon color={theme.colors.palette.neutral.greyMain} width={18}/>
                    <Typography
                        variant={'caption'}>{getTrackingsBreakHoursAsString(getAllTrackingsAsArray(totalBookingsData.trackings))}</Typography>
                </div>}

            {userTimeTrackingStatistics && totalBookingsData
                && totalBookingsData.trackings.map((entry, i) => {
                    let dayDiff = getDayDiffOfTimePeriod(timePeriod)
                    let isHourly = dayDiff < 6
                    return isHourly ? getDayEntryCards(entry, i) : getEntryCard(entry, dayDiff)
                })}

            {(editTimeTrackingMode || newModalOpen) &&
                <EditTimeTrackingModal onClose={onCloseEditModal}
                                       initialDate={selectedMoment}
                                       open={true}
                                       entry={newModalOpen ? null : selectedTimeTracking ? selectedTimeTracking : selectedBreak}/>}

            {(selectedTimeTracking || selectedBreak) && !editTimeTrackingMode &&
                <TimeTrackingInfoModal entry={selectedTimeTracking ? selectedTimeTracking : selectedBreak}
                                       open={true}
                                       onClose={onCloseInfoModal}
                                       onDelete={() => selectedTimeTracking
                                           ? deleteTimeTracking(userId, selectedTimeTracking.id)
                                           : deleteTimeTrackingBreak(userId, selectedBreak.id)}
                                       onEdit={() => setEditTimeTrackingMode(true)}
                />}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        userTimeTrackingStatistics: state.dashboard.userTimeTrackingStatistics,
    }
}

const mapDispatchToProps = {
    deleteTimeTracking: deleteTimeTracking,
    deleteTimeTrackingBreak: deleteTimeTrackingBreak,
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingOverviewContent)
