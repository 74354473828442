import React from 'react';
import {compose} from "redux";
import {Typography, withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {tabsNames} from "../drawing/drawingTabsAndTools";
import DrawSelectSvgIcon from "../../../common/icons/DrawSelectSvgIcon.js";
import DrawScaleSvgIcon from "../../../common/icons/DrawScaleSvgIcon.js";
import DrawInnerWallSvgIcon from "../../../common/icons/DrawInnerWallSvgIcon.js";
import DrawWallSvgIcon from "../../../common/icons/DrawWallSvgIcon.js";
import DrawDoorSvgIcon from "../../../common/icons/DrawDoorSvgIcon.js";
import DrawPillarSvgIcon from "../../../common/icons/DrawPillarSvgIcon.js";
import DrawPolygonSvgIcon from "../../../common/icons/DrawPolygonSvgIcon.js";
import DrawSeatsSvgIcon from "../../../common/icons/DrawSeatsSvgIcon.js";
import Divider from "@material-ui/core/Divider";
import TrashSvgIcon from "../../../common/icons/TrashSvgIcon.js";
import useTheme from "@material-ui/core/styles/useTheme.js";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import AdminButton from "../../../common/elements/AdminButton.js";

const styles = theme => ({
    root: {
        height: '100%',
        maxHeight: '100%',
        width: '300px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: `${theme.outerGap} ${theme.innerGap}`,
        border: '1px solid ' + theme.colors.palette.neutral.greyMedium,
        backgroundColor: theme.colors.palette.neutral.white,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)'
    },
    container: {
        display: 'flex',
        gap: theme.outerGap,
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'left',
    },
    inspector: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    heading: {
        fontSize: '26px',
        marginBottom: theme.outerGap,
    },
    icon: {
        height: '30px',
        width: '30px',
        stroke: theme.colors.palette.neutral.darkNeutral,
    },
    declaration: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
        opacity: '0.5',
    },
    declarationText: {
        maxWidth: '190px'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    shortcut: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    action: {
        backgroundColor: theme.colors.palette.neutral.greyMedium,
        alignItems: 'center',
        borderRadius: '3px',
        display: 'flex',
        height: '1.8rem',
        padding: '2px 8px'
    }
})

function DrawingToolInspector({classes, currentTab, element, onDelete, showDeleteButton, ...props}) {
    const {t} = useTranslation();
    const theme = useTheme()

    const saveShortcut = {shortcut: t('alt+s'), text: t('save_geometries')}
    const cancelShortcut = {shortcut: t('alt+c'), text: t('cancel')}
    const deleteShortcut = {shortcut: t('alt+backspace'), text: t('remove')}
    const loopShortcut = {shortcut: t('alt+enter'), text: t('loop_polygon')}
    const confirmShortcut = {shortcut: t('alt+enter'), text: t('confirm_wall')}
    const duplicateShortcut = {shortcut: t('alt+d'), text: t('duplicate')}
    const addTagShortcut = {shortcut: t('shortcut.enter'), text: t('add_tag')}

    function getToolIcon() {
        switch (currentTab) {
            case tabsNames.SELECT:
                return <DrawSelectSvgIcon className={classes.icon}/>
            case tabsNames.SCALE:
                return <DrawScaleSvgIcon className={classes.icon}/>
            case tabsNames.OUTLINE:
                return <DrawWallSvgIcon className={classes.icon}/>
            case tabsNames.WALLS:
                return <DrawInnerWallSvgIcon className={classes.icon}/>
            case tabsNames.DOORS:
                return <DrawDoorSvgIcon className={classes.icon}/>
            case tabsNames.PILLARS:
                return <DrawPillarSvgIcon className={classes.icon}/>
            case tabsNames.SPACES:
                return <DrawPolygonSvgIcon className={classes.icon}/>
            case tabsNames.WORKPLACES:
                return <DrawSeatsSvgIcon className={classes.icon}/>
            case tabsNames.TERMINAL:
                return <DesktopWindowsIcon className={classes.icon}/>
            default:
                return null
        }
    }

    function getToolText() {
        switch (currentTab) {
            case tabsNames.SELECT:
                return t('select_objects')
            case tabsNames.SCALE:
                return t('define_scaling')
            case tabsNames.OUTLINE:
                return t('define_outerwalls')
            case tabsNames.WALLS:
                return t('define_innerwalls')
            case tabsNames.DOORS:
                return t('insert_doors')
            case tabsNames.PILLARS:
                return t('insert_pillars')
            case tabsNames.SPACES:
                return t('insert_spaces')
            case tabsNames.WORKPLACES:
                return t('define_workplaces')
            case tabsNames.TERMINAL:
                return t('define_terminal')
            default:
                return null
        }
    }

    function getShortcuts() {
        switch (currentTab) {
            case tabsNames.SELECT:
                return [cancelShortcut, saveShortcut, deleteShortcut]
            case tabsNames.SCALE:
                return [cancelShortcut]
            case tabsNames.OUTLINE:
                return [cancelShortcut, saveShortcut, loopShortcut, deleteShortcut, duplicateShortcut]
            case tabsNames.WALLS:
                return [cancelShortcut, saveShortcut, confirmShortcut, deleteShortcut]
            case tabsNames.DOORS:
                return [saveShortcut]
            case tabsNames.PILLARS:
                return [saveShortcut]
            case tabsNames.SPACES:
                return [cancelShortcut, saveShortcut, loopShortcut]
            case tabsNames.WORKPLACES:
                return [cancelShortcut, saveShortcut, addTagShortcut]
            case tabsNames.TERMINAL:
                return [cancelShortcut, saveShortcut]
            default:
                return []
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.inspector}>
                <div className={classes.container}>
                    <Typography variant={'h3'} className={classes.heading}>{t(currentTab)}</Typography>
                    <div className={classes.declaration}>
                        {getToolIcon()}
                        <Typography className={classes.declarationText}>{getToolText()}</Typography>
                    </div>

                    <Divider/>

                    {getShortcuts().length ?
                        <div className={classes.column}>
                            {getShortcuts().map(shortcut => {
                                return <div className={classes.shortcut} key={shortcut.shortcut}>
                                    <Typography variant={'overline'}>
                                        {shortcut.text}
                                    </Typography>
                                    <div className={classes.action}>
                                        <Typography variant={'h4'}>
                                            {shortcut.shortcut}
                                        </Typography>
                                    </div>
                                </div>
                            })}
                            <Divider/>
                        </div> : null
                    }
                </div>

                {element}
            </div>
            {showDeleteButton &&
                <AdminButton onClick={onDelete} text={t('delete')} fullWidth
                             customClassName={classes.button} outlined
                             icon={<TrashSvgIcon/>}
                             textColor={theme.colors.palette.corporate.redMain}/>}
        </div>);
}

DrawingToolInspector.propTypes = {
    element: PropTypes.any,
    onDelete: PropTypes.func,
    showDeleteButton: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        currentTab: state.drawing.currentDrawingTab, selectedTool: state.drawing.selectedTool,
    }
}

const mapDispatchToProps = {}

export default compose(withStyles(styles))(connect(mapStateToProps, mapDispatchToProps)(DrawingToolInspector))

