import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {Button, Card, CardContent, Typography, withStyles} from "@material-ui/core";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import RatingsButtonBackground from '../../common/img/RatingsButtonBackground.png'
import ArrowForwardSvgIcon from "../../common/icons/ArrowForwardSvgIcon";

const styles = theme => ({
    cardWrapper: {
        width: '100%',
        gap: '1.2rem',
    },
    content: {
        width: '100%',
        padding: '0px !important',
    },
    subHeading: {
        textAlign: 'start',
    },
    cardRatings: {
        display: 'flex',
        padding: '1.2rem',
        borderRadius: '12px',
        cursor: 'pointer',
        border: `solid 1px ${theme.colors.palette.neutral.greyMedium}`,
        backgroundImage: 'url(' + RatingsButtonBackground + ')',
        backgroundSize: '100%',
        backgroundPosition: 'top',
        backgroundColor: theme.colors.palette.corporate.greenMain,
    },
    cardTextWhite: {
        color: theme.colors.palette.neutral.white,
        textAlign: 'left',
    },
    button: {
        display: 'flex',
        minWidth: '48px',
        width: '48px',
        height: '48px',
        float: 'right',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px',
        borderColor: theme.colors.palette.neutral.greyMedium,
        borderRadius: '12px',
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    icon: {
        stroke: theme.colors.palette.neutral.darkMain,
    }
})

class RatingsButton extends React.Component {

    render() {
        const {classes, t} = this.props;

        return (
            <Grid container item direction={'column'} className={classes.cardWrapper}>
                <Typography className={classes.subHeading} variant={'h5'}>
                    {t("rate_your_last_workplaces")}
                </Typography>
                <Card variant="outlined" className={classes.cardRatings}
                      onClick={this.props.handleClick}>
                    <CardContent className={classes.content}>
                        <div className={classes.wrapper}>
                            <div>
                                <Typography className={classes.cardTextWhite}
                                            variant={'h5'}>{t("unrated_workplaces")}{this.props.value}</Typography>
                                <Typography variant={'body2'}
                                            className={classes.cardTextWhite}>{t("workplace_to_rate")}</Typography>
                            </div>
                            <div>
                                <Button className={classes.button}>
                                    <ArrowForwardSvgIcon className={classes.icon}/>
                                </Button>
                            </div>
                        </div>

                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

RatingsButton.propTypes = {
    value: PropTypes.number.isRequired,
    handleClick: PropTypes.func.isRequired,
}

RatingsButton.defaultProps = {
    value: 0,
}

export default compose(withStyles(styles), withTranslation())(RatingsButton)