import React, {useEffect, useMemo, useState} from 'react';

import moment from "moment";
import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {Form, withFormik} from "formik";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";

import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography";


import CustomList from "../CustomList";
import CustomButton from "../CustomButton.js";
import CustomAccordion from "../CustomAccordion";
import CustomDateRangePicker from "../CustomDateRangePicker.js";
import ActionListItem from "../../common/elements/ActionListItem";
import StaticBottomSheet from "../../common/elements/StaticBottomSheet";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import TimeperiodSelectionWithClocksAndTimeline
    from "../../common/elements/TimeperiodSelectionWithClocksAndTimeline.js";

import {setFloor} from "../../actions/floors-actions.js";
import {getEndOfWorkingDay} from "../../common/utils/TimeUtils.js";
import {getBuildings, setBuilding} from "../../actions/buildings-actions.js";
import {
    navigate,
    PATH_BUILDING_SELECTION,
    PATH_FLOOR_SELECTION,
    PATH_MEETING,
    PATH_MEETING_SUGGESTIONS,
    PATH_SPACE_SELECTION
} from "../../common/utils/NavigationUtils";

import {
    getAssignmentsOfSpaceAtDate,
    getSuggestedMeetingSlots,
    setMeetingDetails,
    setMeetingSpace
} from "../../actions/meeting-actions";


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        flex: 1,
    },
    container: {
        minHeight: `calc(100% - ${theme.staticBottomActionSheet.height})`,
        padding: theme.paddingContentContainer.padding,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        gap: '1.2rem',
    },
}));

function MeetingDetailsForm({
                                values,
                                building,
                                floor,
                                space,
                                title,
                                onlineOnly,
                                getAssignmentsOfSpaceAtDate,
                                setFieldValue,
                                handleSubmit,
                                timePeriod,
                                pending,
                                setBuilding,
                                setFloor,
                                setMeetingSpace,
                                buildingsList,
                                loadBuildings,
                            }) {

    const theme = useTheme()
    const {t} = useTranslation()
    const history = useHistory()
    const classes = useStyle(theme);

    const [dateExpanded, setDateExpanded] = useState(values.dateSelected);
    const [locationExpanded, setLocationExpanded] = useState(values.locationSelected);
    const [timeExpanded, setTimeExpanded] = useState(values.timeSelected);

    useEffect(() => {
        if (isEmpty(buildingsList))
            loadBuildings()

        if (!title)
            navigate(history, PATH_MEETING)
        // eslint-disable-next-line
    }, [])

    const toggleDate = () => {
        if (values.date) {
            setFieldValue('date', null);
        } else {
            setFieldValue('date', new Date());
        }

        setDateExpanded(!dateExpanded)
    }

    const toggleLocation = () => {
        if (locationExpanded) {
            setBuilding(null)
            setFloor(null)
            setMeetingSpace(null)
        }
        setFieldValue('locationSelected', !locationExpanded);
        setLocationExpanded(!locationExpanded)
    }

    const toggleTimePeriod = () => {
        const valuesTimeperiod = values.timePeriod
        if (valuesTimeperiod !== null && (valuesTimeperiod.startDate !== null || valuesTimeperiod.endDate !== null)) {
            setFieldValue('timePeriod', {startDate: null, endDate: null})
        } else {
            setFieldValue('timePeriod', {
                startDate: moment({
                    hours: process.env.REACT_APP_MORNING_WORKING_DAY_HOUR,
                    minutes: 0,
                    seconds: 0
                }).toDate(),
                endDate: moment({
                    hours: getEndOfWorkingDay(), minutes: 0, seconds: 0
                }).toDate()
            })
        }

        setTimeExpanded(!timeExpanded)
    }

    useEffect(() => {
        if (values.space && values.timePeriod) {
            getAssignmentsOfSpaceAtDate(values.space.id, values.timePeriod.startDate, values.timePeriod.endDate)
        }
        // eslint-disable-next-line
    }, [values.timePeriod, values.space]);


    const error = useMemo(() => {
        return values.timePeriod.startDate && values.timePeriod.endDate && !moment(values.timePeriod.startDate).isBefore(moment(values.timePeriod.endDate))
    }, [values.timePeriod])

    return (
        <Grid container component={Paper} className={classes.root}>
            <Form onSubmit={handleSubmit} style={{width: '100%'}}>
                <Grid item container direction={'column'} wrap={'nowrap'} justifyContent={'space-between'}
                      className={classes.container}>
                    <HeaderWithTitleAndBackButton title={t('meeting_details')} backLabel={t('meeting')}
                                                  onNavigateBack={() => navigate(history, PATH_MEETING)}/>

                    <Grid item container direction={'column'} className={classes.content}>

                        <CustomList dividerPaddingRight={false}>
                            <CustomAccordion title={t('date')} expanded={dateExpanded} unmount key={'date'}
                                             onChange={toggleDate} noPaddingLeft>

                                <CustomDateRangePicker rangeMode={false}
                                                       disableEarlierThanNow
                                                       onDateChange={(value) => {
                                                           setFieldValue("date", value.startDate)
                                                       }}
                                                       minDate={moment()}
                                                       maxDate={moment().add(1, 'year')}
                                                       initialSelected={values.date}/>
                            </CustomAccordion>

                            <CustomAccordion title={t('location')} expanded={locationExpanded} unmount key={'location'}
                                             disabled={onlineOnly}
                                             onChange={toggleLocation}>

                                <CustomList dividerPaddingRight={false} topDivider>
                                    <ActionListItem key={'building'} text={t('building')} hideDivider
                                                    action={() => navigate(history, PATH_BUILDING_SELECTION)}
                                                    endAdornment={<Typography variant={'h6'}>
                                                        {building ? building.name : t('select')}
                                                    </Typography>}
                                                    textColor={theme.colors.palette.neutral.greyMain}
                                    />
                                    <ActionListItem key={'floor'} text={t('floor')} hideDivider
                                                    action={() => navigate(history, PATH_FLOOR_SELECTION)}
                                                    endAdornment={<Typography variant={"h6"}>
                                                        {floor ? floor.name : t('select')}
                                                    </Typography>}
                                                    textColor={theme.colors.palette.neutral.greyMain}
                                    />
                                    <ActionListItem key={'space'} text={t('space')} hideDivider
                                                    action={() => navigate(history, PATH_SPACE_SELECTION)}
                                                    endAdornment={<Typography variant={"h6"}>
                                                        {space ? space.name : t('select')}
                                                    </Typography>}
                                                    textColor={theme.colors.palette.neutral.greyMain}/>
                                </CustomList>

                            </CustomAccordion>

                            <CustomAccordion title={t('time_period')} expanded={timeExpanded} key={'time'}
                                             onChange={toggleTimePeriod}>
                                <div>

                                    <TimeperiodSelectionWithClocksAndTimeline
                                        startTime={values.timePeriod.startDate ?? timePeriod.startDate ?? moment()}
                                        setStartTime={time => setFieldValue('timePeriod', {
                                            startDate: time,
                                            endDate: values.timePeriod.endDate
                                        })}
                                        endTime={values.timePeriod.endDate ?? timePeriod.endDate ?? moment()}
                                        setEndTime={(time) => {
                                            setFieldValue('timePeriod', {
                                                startDate: values.timePeriod.startDate,
                                                endDate: time
                                            })
                                        }}/>
                                </div>
                            </CustomAccordion>
                        </CustomList>

                    </Grid>
                </Grid>

                <StaticBottomSheet>
                    <Grid item container wrap={'nowrap'} className={classes.buttonContainer}>
                        <CustomButton text={t('find_meeting')} primary type={'submit'}
                                      disabled={error}
                                      isLoading={pending}
                        />
                    </Grid>
                </StaticBottomSheet>
            </Form>
        </Grid>
    );
}

const MeetingDetailsPage = withFormik({
    mapPropsToValues: props => {
        let locationSelected = !props.onlineOnly && !!props.building
        let dateSelected = !!props.date
        let timeSelected = !!props.timePeriod && !!props.timePeriod.startDate
        let date = props.date ? props.date : null
        let timePeriod = props.timePeriod ? props.timePeriod : null

        return ({
            locationSelected: locationSelected,
            dateSelected: dateSelected,
            timeSelected: timeSelected,
            timePeriod: timePeriod,
            date: date
        })
    },
    enableReinitialize: true,
    handleSubmit: (values, {props}) => {
        props.setMeetingDetails(values.date, values.timePeriod)

        let invitedPersons = [...props.meetingAttendees]
        props.meetingGroups.forEach(group => {
            group.members.forEach(person => {
                if (!invitedPersons.some(p => p.id === person.id)) {
                    invitedPersons.push(person)
                }
            })
        })

        const meetingInfo = {
            title: props.title,
            description: props.meetingDescription,
            onlineLink: props.onlineLink,
            duration: props.duration * 3600,
            requiredParticipants: invitedPersons,
            optionalParticipants: [],
            date: values.date,
            slot: values.timePeriod,
            onlineOnly: props.onlineOnly,
            shareStatus: props.shareStatus,
            buildingId: props.building && values.locationSelected ? props.building.id : null,
            floorId: props.floor && values.locationSelected ? props.floor.id : null,
            spaceId: props.space && values.locationSelected ? props.space.id : null,
        }

        props.getSuggestedMeetingSlots(meetingInfo, props.userId)
            .then(() =>
                navigate(props.history, PATH_MEETING_SUGGESTIONS)
            )
    }
})(MeetingDetailsForm)

const mapStateToProps = state => {
    return {
        timePeriod: state.meetings.timePeriod,
        buildingsList: state.buildings.buildingsList,
        building: state.buildings.building,
        space: state.meetings.space,
        floor: state.floors.floor,
        title: state.meetings.title,
        onlineLink: state.meetings.onlineLink,
        onlineOnly: state.meetings.onlineOnly,
        shareStatus: state.meetings.shareStatus,
        meetingDescription: state.meetings.description,
        meetingAttendees: state.meetings.meetingAttendees,
        meetingGroups: state.meetings.meetingGroups,
        duration: state.meetings.duration,
        userId: state.user.person.id,
        pending: state.meetings.suggestionsPending,
    }
}

const mapDispatchToProps = {
    setMeetingDetails: setMeetingDetails,
    getAssignmentsOfSpaceAtDate: getAssignmentsOfSpaceAtDate,
    getSuggestedMeetingSlots: getSuggestedMeetingSlots,
    setBuilding: setBuilding,
    setFloor: setFloor,
    setMeetingSpace: setMeetingSpace,
    loadBuildings: getBuildings,
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingDetailsPage);