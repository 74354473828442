import React from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import HomeSvgIcon from "../../common/icons/HomeSvgIcon.js";
import Searchbar from "../../common/elements/Searchbar.js";
import CardWrapper from "../../common/elements/CardWrapper.js";

import XSvgIcon from "../../common/icons/XSvgIcon.js";
import SearchSvgIcon from "../../common/icons/SearchSvgIcon.js";
import BuildingSvgIcon from "../../common/icons/BuildingSvgIcon.js";
import OutOfOfficeSvgIcon from "../../common/icons/OutOfOfficeSvgIcon.js";
import VacationSvgIcon from "../../common/icons/VacationSvgIcon.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '0 2px',
    },
    occupancyInfo: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.innerGap
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5.2rem',
        flex: 0
    },
    filter: {
        display: 'flex',
        gap: theme.innerGap,
        flexWrap: 'wrap',
    },
    selected: {
        backgroundColor: theme.colors.palette.corporate.skinMain,
        color: theme.colors.palette.neutral.white,
        fill: theme.colors.palette.neutral.white,
    },
    search: {
        height: '5.2rem',
        minHeight: '5.2rem',
        width: '5.4rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'grid',
    },
    card: {
        flex: 0,
        width: '6.9rem'
    },
    closeSearchbarButton: {
        width: '5.4rem',
    },
}));

function OccupancyFilterSearch({
                                   showSearchbar, searchString, onSearchChange, handleShowSearchbar, filter,
                                   totalWorkplaceAssignments, totalWorkplaces, totalHomeofficeAssignments,
                                   totalOutOfOfficeAssignments, totalVacationAssignments, ...props
                               }) {

    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(props.theme);

    const allFilterSelected = () => {
        for (const [value] of Object.entries(filter)) {
            if (!value['state'])
                return false
        }

        return true
    }

    return (
        <div className={classes.root}>
            {showSearchbar
                ? <div className={classes.occupancyInfo}>

                    <Searchbar className={classes.searchbar} placeholder={t('search_colleagues')}
                               searchString={searchString} setSearchString={onSearchChange}
                    />

                    <CardWrapper className={clsx(classes.flex, classes.closeSearchbarButton)}
                                 gridClassName={classes.search}
                                 onClick={() => {
                                     onSearchChange('')
                                     handleShowSearchbar(false)
                                 }}
                    >
                        <div className={clsx(classes.flex)} style={{width: '100%', height: '100%'}}>
                            <XSvgIcon className={classes.icon} width={26} height={26}/>
                        </div>
                    </CardWrapper>

                </div>
                : <div className={classes.occupancyInfo}>
                    <div className={classes.filter}>

                        <CardWrapper onClick={() => filter.officeAssignments.onChange(!filter.officeAssignments.state)}
                                     isFlex gridClassName={classes.card}>
                            <div
                                className={clsx(classes.flex, filter.officeAssignments.state && !allFilterSelected() ? classes.selected : '')}>
                                <BuildingSvgIcon
                                    color={filter.officeAssignments.state && !allFilterSelected() ? theme.colors.palette.neutral.white : theme.colors.palette.neutral.darkMain}
                                    className={classes.icon}/>
                                <Typography variant={'caption'}
                                            className={filter.officeAssignments.state && !allFilterSelected() ? classes.selected : ''}>
                                    {totalWorkplaceAssignments + '/' + totalWorkplaces}
                                </Typography>
                            </div>
                        </CardWrapper>

                        <CardWrapper onClick={() => filter.homeOffice.onChange(!filter.homeOffice.state)}
                                     isFlex gridClassName={classes.card}>
                            <div
                                className={clsx(classes.flex, filter.homeOffice.state && !allFilterSelected() ? classes.selected : '')}>
                                <HomeSvgIcon
                                    stroke={filter.homeOffice.state && !allFilterSelected() ? theme.colors.palette.neutral.white : theme.colors.palette.neutral.darkMain}
                                    className={classes.icon}/>
                                <Typography variant={'caption'}
                                            className={filter.homeOffice.state && !allFilterSelected() ? classes.selected : ''}>
                                    {totalHomeofficeAssignments}
                                </Typography>
                            </div>
                        </CardWrapper>

                        <CardWrapper onClick={() => filter.outOfOffice.onChange(!filter.outOfOffice.state)}
                                     isFlex gridClassName={classes.card}>
                            <div
                                className={clsx(classes.flex, filter.outOfOffice.state && !allFilterSelected() ? classes.selected : '')}>
                                <OutOfOfficeSvgIcon
                                    stroke={filter.outOfOffice.state && !allFilterSelected() ? theme.colors.palette.neutral.white : theme.colors.palette.neutral.darkMain}
                                    className={classes.icon}/>
                                <Typography variant={'caption'}
                                            className={filter.outOfOffice.state && !allFilterSelected() ? classes.selected : ''}>
                                    {totalOutOfOfficeAssignments}
                                </Typography>
                            </div>
                        </CardWrapper>

                        <CardWrapper onClick={() => filter.vacation.onChange(!filter.vacation.state)}
                                     isFlex gridClassName={classes.card}>
                            <div
                                className={clsx(classes.flex, filter.vacation.state && !allFilterSelected() ? classes.selected : '')}>
                                <VacationSvgIcon
                                    stroke={filter.vacation.state && !allFilterSelected() ? theme.colors.palette.neutral.white : theme.colors.palette.neutral.darkMain}
                                    className={classes.icon}/>
                                <Typography variant={'caption'}
                                            className={filter.vacation.state && !allFilterSelected() ? classes.selected : ''}>
                                    {totalVacationAssignments}
                                </Typography>
                            </div>
                        </CardWrapper>
                    </div>
                    <CardWrapper onClick={() => handleShowSearchbar(true)} className={classes.search}>
                        <SearchSvgIcon stroke={theme.colors.palette.neutral.darkMain}/>
                    </CardWrapper>
                </div>}
        </div>
    );
}

OccupancyFilterSearch.propTypes = {
    showSearchbar: PropTypes.bool,
    handleShowSearchbar: PropTypes.func,
    searchString: PropTypes.string,
    onSearchChange: PropTypes.func,
    filter: PropTypes.object,
};

OccupancyFilterSearch.defaultProps = {
    showSearchbar: false,
    filter: {
        officeAssignments: {
            state: true,
            onChange: () => console.log('officeAssignments change')
        },
        homeOffice: {
            state: true,
            onChange: () => console.log('homeOffice change')
        },
        outOfOffice: {
            state: true,
            onChange: () => console.log('outOfOffice change')
        },
        vacation: {
            state: true,
            onChange: () => console.log('vacation change')
        }
    },
};

export default OccupancyFilterSearch;