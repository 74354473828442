import * as React from "react"
import PropTypes from "prop-types";

function WorkingGroupSvgIcon({size, ...props}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 40 40"
            {...props}
        >
            <g stroke={props.stroke || '#333'} strokeWidth={1.5} fill="none" fillRule="evenodd">
                <g transform="translate(6 6)">
                    <circle cx={5.5} cy={3.5} r={3.5}/>
                    <path d="M0 15v-2a3 3 0 013-3h5a3 3 0 013 3v2h0"/>
                </g>
                <g transform="translate(23 19)">
                    <circle cx={5.5} cy={3.5} r={3.5}/>
                    <path d="M0 15v-2a3 3 0 013-3h5a3 3 0 013 3v2h0"/>
                </g>
                <path d="M21 8h2a7 7 0 017 7M19 32h-2a7 7 0 01-7-7"/>
            </g>
        </svg>
    )
}

WorkingGroupSvgIcon.propTypes = {
    size: PropTypes.number
}

WorkingGroupSvgIcon.defaultProps = {
    size: 24
}

export default WorkingGroupSvgIcon