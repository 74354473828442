const initialState = {
    userId: null,
    workplace: null,
    timePeriod: {
        startDate: null,
        endDate: null,
    },
    selectedDate: new Date()
}

function assignmentBuilderReducer(state = initialState, action) {

    switch (action.type) {

        case 'SET_SELECTED_DATE':
            return {
                ...state,
                selectedDate: action.payload
            }

        case 'SET_ASSIGNMENT_TIME_PERIOD':
            return {
                ...state,
                timePeriod: action.payload
            }
        case 'ASSIGN_WORKPLACE_TO_PERSON_FULFILLED':
            return {
                ...state,
                workplace: null,
                userId: {...action.payload.data.person.id},
                timePeriod: {
                    startDate: null,
                    endDate: null,
                }
            }

        default:
            return state

    }

}

export default assignmentBuilderReducer
