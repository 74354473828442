import * as React from 'react';
import {withStyles} from "@material-ui/core";
import clsx from "clsx";

const styles = theme => ({
    root: {
        display: 'flex',
        marginTop: '1.2rem',
        justifyContent: 'center',
    },
    dotTyping: {
        position: 'relative',
        left: '-9999px',
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        animation: `$dotTyping 1.5s infinite linear`,
    },
    '@keyframes dotTyping': {
        '0%': {
            boxShadow: '9984px 0 0 0 #FF8C65, 9999px 0 0 0 #FF8C65, 10014px 0 0 0 #FF8C65',
        },
        '16.667%': {
            boxShadow: '9984px -10px 0 0 #FF8C65, 9999px 0 0 0 #FF8C65, 10014px 0 0 0 #FF8C65',
        },
        '33.333%': {
            boxShadow: '9984px 0 0 0 #FF8C65, 9999px 0 0 0 #FF8C65, 10014px 0 0 0 #FF8C65',
        },
        '50%': {
            boxShadow: '9984px 0 0 0 #FF8C65, 9999px -10px 0 0 #FF8C65, 10014px 0 0 0 #FF8C65',
        },
        '66.667%': {
            boxShadow: '9984px 0 0 0 #FF8C65, 9999px 0 0 0 #FF8C65, 10014px 0 0 0 #FF8C65',
        },
        '83.333%': {
            boxShadow: '9984px 0 0 0 #FF8C65, 9999px 0 0 0 #FF8C65, 10014px -10px 0 0 #FF8C65',
        },
        '100%': {
            boxShadow: '9984px 0 0 0 #FF8C65, 9999px 0 0 0 #FF8C65, 10014px 0 0 0 #FF8C65',
        }
    }
})

function LoadingIndicator(props) {
    const {classes} = props

    return (
        <div className={clsx(classes.root, props.className)}>
            <div className={classes.dotTyping}/>
        </div>
    );
}

export default withStyles(styles)(LoadingIndicator)