import * as React from "react"
import PropTypes from 'prop-types'

function NavigationBackSvgIcon({size, fill, ...props}) {
    return (
        <svg width={size}
             height={size}
             viewBox="0 0 24 24"
             className={props.className}{...props}>
            <g stroke={fill} fill="none" fillRule="evenodd">
                <path d="m9 15.993-4-3.97 4-4.014"/>
                <path strokeWidth={1} strokeLinejoin="round" d="M5.448 12h14"/>
            </g>
        </svg>
    )
}

NavigationBackSvgIcon.propTypes = {
    size: PropTypes.number,
    fill: PropTypes.string
}

NavigationBackSvgIcon.defaultProps = {
    size: 24,
    fill: '#333'
}

export default NavigationBackSvgIcon
