import React from 'react';

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {getBreakHoursAsString, getTimeAsString, getWorkedHoursAsString} from "../../../common/utils/TimeUtils.js";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import EditSvgIcon from "../../../common/icons/EditSvgIcon.js";
import TrashIcon from "../../../common/icons/TrashSvgIcon.js";
import ModalWrapper from "../../../common/elements/ModalWrapper.js";
import moment from "moment";

const useStyle = makeStyles(theme => ({
    headerActionsRight: {
        display: 'flex',
        gap: theme.outerGap,
    },
    content: {
        display: 'flex',
        justifyContent: 'start',
        gap: theme.outerGap,
        flexDirection: 'column'
    },
    info: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column'
    },
    icon: {
        cursor: 'pointer'
    }
}))

function TimeTrackingInfoModal({entry, onDelete, onClose, onEdit, open}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);

    return (
        <ModalWrapper open={open} onClose={onClose}
                      headerActions={
                          <div className={classes.headerActionsRight}>
                              <EditSvgIcon color={theme.colors.palette.neutral.darkMain} size={24} onClick={onEdit}
                                           className={classes.icon}/>
                              <TrashIcon color={theme.colors.palette.corporate.redMain} size={24} onClick={() => {
                                  onClose()
                                  onDelete(entry)
                              }} className={classes.icon}/>
                          </div>}>

            <div className={classes.content}>
                <Typography variant={'h3'}>{entry.clockInTime ? t('work_time') : t('break_time')}</Typography>

                <div className={classes.info}>
                    <Typography variant={'body1'}>
                        {getTimeAsString(entry.clockInTime ? entry.clockInTime : entry.startTime) + ' - ' +
                            getTimeAsString(entry.clockOutTime ? entry.clockOutTime : entry.endTime ? entry.endTime : moment())}
                    </Typography>
                    <Typography variant={'h6'}>
                        {entry.clockInTime
                            ? getWorkedHoursAsString([entry])
                            : getBreakHoursAsString(entry)}
                    </Typography>
                </div>
            </div>
        </ModalWrapper>
    )
}

TimeTrackingInfoModal.propTypes = {
    entry: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    open: PropTypes.bool,
}

export default TimeTrackingInfoModal