import * as React from "react"

const CalendarTodaySvgIcon = (...props) => (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="a">
                <path d="M2.02 0v2H0V0h2.02Z"/>
            </clipPath>
        </defs>
        <path
            stroke="#333"
            fill="none"
            strokeMiterlimit={10}
            d="M4 5.16h16V21H4V5.16zM20 9.48H4h16zM8 3v4.32M16 3v4.32"
        />
        <g clipPath="url(#a)" transform="translate(6.99 12.08)">
            <path fill="#333" d="M0 0h2.02v2H0V0z"/>
        </g>
        <path
            stroke="#333"
            fill="none"
            strokeMiterlimit={10}
            d="M6.99 12.08h2.02v2H6.99v-2z"
        />
    </svg>
)

export default CalendarTodaySvgIcon