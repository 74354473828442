// at least 8 characters including 1 uppercase, 1 lowercase, 1 digit
import HidePasswordSvgIcon from "../icons/HidePasswordSvgIcon.js";
import ShowPasswordSvgIcon from "../icons/ShowPasswordSvgIcon.js";
import React from "react";

export const emailValidationRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

/**
 * min 8 characters
 * min 1 uppercase letter
 * min 1 lowercase letter
 * min 1 digit
 * optional special characters
 */
export const passwordValidationRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.()?"!@#%&/\\,><':;|_~`=+\- ])*[A-Za-z0-9^$*.()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

export const uppercaseRegex = /^(?=.*[A-Z])/;
export const lowercaseRegex = /^(?=.*[a-z])/;
export const digitRegex = /\d/;

export function isPasswordValid(password) {
    return password.match(passwordValidationRegex)
}

export function passwordMeetsRequirements(password) {
    return password.match(uppercaseRegex) && password.match(lowercaseRegex) && password.match(digitRegex) && password.length >= 8
}

export function isEmailValid(email) {
    return email.match(emailValidationRegex)
}

export function getEndAdornment(displayState, handleShowFunction, className) {
    return displayState
        ? <HidePasswordSvgIcon className={className}
                               onClick={() => handleShowFunction(false)}/>
        : <ShowPasswordSvgIcon className={className}
                               onClick={() => handleShowFunction(true)}/>
}