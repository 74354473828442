import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import clsx from "clsx";
import * as _ from 'lodash';
import moment from "moment";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import TimeRange from 'react-timeline-range-slider';

import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles.js";
import useTheme from "@material-ui/core/styles/useTheme.js";

import LoadingIndicator from "./LoadingIndicator";
import {
    dateDiffToDurationStringWithUnits,
    getTimeAtDate,
    isSameDay,
    isTimeperiodOverlappingDisabledIntervals
} from "../utils/TimeUtils.js";

const useStyles = props => makeStyles(theme => ({
    root: {
        width: '100%',
        '& .react_time_range__track__disabled': {
            height: '20px',
            boxShadow: '1px 0 0 0px #C8CACC',
            borderRight: 'none',
            cursor: 'default',
            background: 'repeating-linear-gradient(-45deg, transparent, transparent 3px, #363a40 4px, #363a40 2px)'
        },
        '& .react_time_range__track': {
            background: theme.colors.gradients.skinMain,// + ' !important',
            height: '18px',
            borderRight: '1px solid #C8CACC',
            zIndex: 0,
            cursor: 'default'
        },
        '& .react_time_range__handle_marker': {
            display: 'none',
            backgroundColor: theme.colors.palette.corporate.skinMain + ' !important',
        },
        '& .react_time_range__time_range_container': {
            width: '100%',
            padding: '0px',
            paddingRight: '8px',
            marginTop: `${!props.hideTimeHeader ? '25px' : '10px'}`,
            height: '55px'
        },
        '& .react_time_range__tick_label': {
            fontSize: '13px',
            marginLeft: '-6px !important'
        },
        '& .react_time_range__rail__inner': {
            backgroundColor: 'transparent'
        },
        '& .react_time_range__rail__outer': {
            cursor: 'default'
        },
        '& .react_time_range__handle_container, .react_time_range__handle_container__disabled': {
            display: 'none'
        },
        '& .react_time_range__handle_wrapper': {
            display: 'none'
        }
    },
    error: {
        '& .react_time_range__track': {
            background: theme.colors.palette.corporate.redMain + '33 !important',
        },
    },
    textWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'row',
    },
    errorMessage: {
        color: theme.colors.palette.corporate.redMain,
    },
    alertIcon: {
        height: '24px',
        width: '24px'
    },
    errorWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '66px',
        marginBottom: '-26px',
        alignItems: 'center',
        marginTop: '2px',
    },
    timeWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
}))

function TimelineWithHeaderAndError({
                                        t,
                                        showFrom,
                                        showUntil,
                                        timePeriod,
                                        hideTimeHeader,
                                        disableEarlierThanNow,
                                        errorCallback,
                                        disabledIntervals,
                                        className,
                                        stepSize,
                                        ...props
                                    }) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);

    const [error, setError] = useState(false);

    //because initial error is not detected by TimeRange
    useEffect(() => {
        const initialError = isTimeperiodOverlappingDisabledIntervals(timePeriod, disabledIntervals) || timePeriod.startDate > timePeriod.endDate

        setError(initialError)
        errorCallback && errorCallback(initialError)
        // eslint-disable-next-line
    }, [disabledIntervals, timePeriod]);

    return (
        <div className={clsx(classes.root, error && classes.error, !hideTimeHeader && classes.marginTop, className)}>
            {!hideTimeHeader && <div className={classes.textWrapper}>
                <Typography className={classes.heading} variant={'h6'}>{t("timeslot")}</Typography>
                <div className={classes.timeWrapper}>
                    <Typography className={error ? classes.errorMessage : ''} variant={'body1'}>
                        {moment(timePeriod.startDate).format("HH:mm")}
                    </Typography>
                    <Typography variant={'body1'}>-</Typography>
                    <Typography className={error ? classes.errorMessage : ''} variant={'body1'}>
                        {moment(timePeriod.endDate).format("HH:mm")}
                    </Typography>
                    <Typography variant={'h6'}>
                        &nbsp;({dateDiffToDurationStringWithUnits(timePeriod.startDate, timePeriod.endDate)})
                    </Typography>
                </div>
            </div>}

            {disabledIntervals === null
                ? <LoadingIndicator/>
                : <TimeRange
                    ticksNumber={24}
                    step={stepSize * 60000}
                    error={error}
                    selectedInterval={[new Date(timePeriod.startDate), new Date(timePeriod.endDate)]}
                    timelineInterval={[new Date(showFrom), new Date(showUntil)]}
                    disabledIntervals={disableEarlierThanNow ? [...disabledIntervals.filter(interval => isSameDay(showFrom, interval.start) || isSameDay(showFrom, interval.end)), {
                        start: getTimeAtDate(showFrom, new Date()),
                        end: moment().toDate()
                    }] : disabledIntervals.filter(interval => isSameDay(showFrom, interval.start) || isSameDay(showFrom, interval.end))}
                    onUpdateCallback={_.noop}
                    onChangeCallback={_.noop}
                    mode={(curr) => {
                        return curr
                    }}
                    formatTick={ms => moment(ms).format('HH')}
                />
            }
        </div>
    )
}

TimelineWithHeaderAndError.propTypes = {
    errorCallback: PropTypes.func,
    showFrom: PropTypes.any,
    showUntil: PropTypes.any,
    timePeriod: PropTypes.any,
    hideTimeHeader: PropTypes.bool,
    //to disable all times earlier than right now
    disableEarlierThanNow: PropTypes.bool,
    disabledIntervals: PropTypes.array,
    //granularity
    stepSize: PropTypes.number
}

TimelineWithHeaderAndError.defaultProps = {
    disabledIntervals: [],
    stepSize: 5
}

export default compose(withTranslation())(TimelineWithHeaderAndError)
