import React from "react";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

import EmptyStateWorkingGroupSvg from "../../common/icons/EmptyStateWorkingGroupSvg.js";

const useStyle = makeStyles(theme => ({
    root: {
        paddingTop: '1.8rem',
        paddingBottom: '1.8rem',
        alignItems: 'center',
        overflow: 'auto',
    },
    text: {
        marginTop: '1.8rem',
        marginBottom: '1.8rem',
    }
}));

function EmptyStateWorkingGroups({width, height}) {

    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation()

    return (
        <div className={classes.root} style={{width: width, height: height}}>
            <EmptyStateWorkingGroupSvg/>
            <div className={classes.text}>
                <Typography variant={'h5'}>
                    {t('start_creating_group')}
                </Typography>
                <Typography variant={'body1'}>
                    {t('groups_explaination')}
                </Typography>
            </div>
        </div>
    )
}

export default EmptyStateWorkingGroups
