import {getDateWithoutTime} from "../common/utils/TimeUtils.js";
import {cloneDeep} from "lodash";
import hash from "object-hash";

const initialState = {
    meetingRooms: [],
    meetingRoomsLoading: false,
    spaceAssignments: {},
    spaceAssignmentsPending: false,

    id: null,
    person: null,
    buildingName: '',
    floorName: '',
    space: null,
    title: "",
    description: "",
    onlineLink: '',
    onlineOnly: false,
    duration: 1,
    timePeriod: {
        startDate: null,
        endDate: null,
    },
    shareStatus: null,
    date: null,
    meetingAttendees: [],
    externalAttendees: [],
    meetingGroups: [],
    meetingSuggestions: [],
    suggestionsPending: false,
}

function meetingReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_MEETINGROOMS_FULFILLED':
            return {
                ...state,
                meetingRooms: action.payload.data,
                meetingRoomsLoading: false,
            }
        case 'GET_MEETINGROOMS_PENDING':
            return {
                ...state,
                meetingRoomsLoading: true
            }
        case 'GET_MEETINGROOMS_REJECTED':
            return {
                ...state,
                meetingRoomsLoading: false
            }

        case 'SET_SELECTED_MEETINGROOM':
            return {
                ...state,
                space: action.payload
            }

        case 'SET_BUILDING':
        case 'SET_FLOOR':
            return {
                ...state,
                space: null
            }

        case 'CLEAR_MEETINGROOMS':
            return {
                ...state,
                meetingRooms: [],
                space: null,
            }

        case 'GET_ASSIGNMENTS_OF_SPACE_AT_DATE_PENDING':
            return {
                ...state,
                spaceAssignmentsPending: true
            }

        case 'GET_ASSIGNMENTS_OF_SPACE_AT_DATE_FULFILLED': {
            let assignments = cloneDeep(state.spaceAssignments)
            let data = action.payload.data.data

            if (!data) {
                return {
                    ...state,
                    spaceAssignmentsPending: false
                }
            }

            const spaceId = action.meta.spaceId
            assignments[spaceId] = {}
            let assignmentsOfSpace = assignments[spaceId]

            for (let assignment of data) {
                const date = getDateWithoutTime(assignment.timePeriod.startDate)
                let spaceAssignmentsAtDate = assignmentsOfSpace[date] ?? []

                assignmentsOfSpace[date] = [...spaceAssignmentsAtDate.filter(existingAssignment => assignment.id ? existingAssignment.id !== assignment.id : hash(assignment) !== hash(existingAssignment)), assignment]
            }

            assignments[spaceId] = assignmentsOfSpace

            return {
                ...state,
                spaceAssignments: assignments,
                spaceAssignmentsPending: false
            }
        }

        case 'GET_ASSIGNMENTS_OF_SPACE_AT_DATE_REJECTED':
            return {
                ...state,
                spaceAssignmentsPending: false
            }

        case 'SET_MEETING_INFOS':
            return {
                ...state,
                title: action.payload.title,
                description: action.payload.description,
                onlineLink: action.payload.onlineLink,
                duration: action.payload.duration,
                onlineOnly: action.payload.onlineOnly,
                shareStatus: action.payload.shareStatus,
            }

        case 'SET_MEETING_DETAILS':
            return {
                ...state,
                date: action.payload.date,
                timePeriod: action.payload.timePeriod,
            }

        case 'SET_MEETING_SPACE':
            return {
                ...state,
                space: action.payload,
            }

        case 'ASSIGN_MEETING_TO_PERSON_FULFILLED':
        case 'UPDATE_MEETING_FULFILLED':
        case 'DELETE_MEETING_ASSIGNMENT_FULFILLED':
        case 'CLEAR_MEETING_DETAILS':
            return {
                ...state,
                id: null,
                person: null,
                buildingName: '',
                floorName: '',
                space: null,
                title: "",
                description: "",
                onlineLink: '',
                onlineOnly: false,
                timePeriod: {
                    startDate: null,
                    endDate: null,
                },
                shareStatus: null,
                meetingAttendees: [],
                externalAttendees: [],
                meetingGroups: [],
            }


        case 'SET_MEETING_ONLINE_ONLY':
            return {
                ...state,
                onlineOnly: action.payload
            }

        case 'UPDATE_TIME_PERIOD_MEETING':
            return {
                ...state,
                timePeriod: action.payload,
            }

        case 'UPDATE_MEETING_ATTENDEES':
            return {
                ...state,
                meetingAttendees: action.payload,
            }

        case 'UPDATE_EXTERNAL_MEETING_ATTENDEES':
            return {
                ...state,
                externalAttendees: action.payload,
            }

        case 'UPDATE_MEETING_GROUPS':
            return {
                ...state,
                meetingGroups: action.payload,
            }

        case 'GET_MEETING_SUGGESTIONS_PENDING':
            return {
                ...state,
                suggestionsPending: true,
            }

        case 'GET_MEETING_SUGGESTIONS_FULFILLED':
            return {
                ...state,
                suggestionsPending: false,
                meetingSuggestions: action.payload.data.data
            }

        case 'COPY_MEETING_ASSIGNMENT':
            return {
                ...state,
                id: action.payload.id,
                person: action.payload.person,
                title: action.payload.title,
                description: action.payload.description,
                onlineLink: action.payload.onlineLink,
                onlineOnly: action.payload.onlineOnly,
                shareStatus: action.payload.shareStatus,
                timePeriod: action.payload.timePeriod,
                meetingAttendees: action.payload.attendances,
                externalAttendees: action.payload.externalAttendances,
                space: action.payload.space,
                buildingName: action.payload.buildingName,
                floorName: action.payload.floorName,
            }

        default:
            return state

    }


}

export default meetingReducer
