import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles, Typography, useTheme} from "@material-ui/core";

import {
    filterAssignmentsByDateAndWorkplaceIdAndSort,
    filterForAssignmentsBlockingNewBooking,
    getAssignmentsAsIntervals,
    isBlocked
} from "../../../common/utils/AssignmentUtils.js";
import PropTypes from "prop-types";
import {setTimePeriodForAssignment} from "../../../actions/workplaceAssignment-action.js";
import {getFreeDaytimeFromDisabledIntervals, sortAssignments} from "../../../common/utils/TimeUtils.js";
import TagsPanel from "../../../common/elements/TagsPanel.js";
import SeatTakenSvgIcon from "../../../common/icons/SeatTakenSvgIcon.js";
import StartAndEndTime from "../../../common/elements/StartAndEndTime.js";
import ActionListItem from "../../../common/elements/ActionListItem.js";
import AvatarWithOccupancy from "../../../common/elements/AvatarWithOccupancy.js";
import DaytimeSelectionWithUserWorkingHours from "../../../common/elements/DaytimeSelectionWithUserWorkingHours.js";
import PeopleAndWorkingGroupList from "../../meetings/PeopleAndWorkingGroupList.js";
import BookingsEmptyStateSvg from "../../../common/icons/BookingsEmptyStateSvg.js";

const useStyle = makeStyles(theme => ({
    expandedContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.innerGap + ' ' + theme.outerGap,
        gap: theme.innerGap,
        boxSizing: 'border-box',
    },
    blocked: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.outerGap,
    },
    assignmentEntry: {
        display: 'flex',
        width: '100%',
        gap: theme.innerGap,
    },
    assignmentsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap,
    },
    permissionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: theme.outerGap,
    },
    permissions: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'start',
        gap: theme.innerGap,
    }
}))

function WorkplaceSelectionSpreadsheetContent({
                                                  userAssignments,
                                                  userId,
                                                  selectedWorkplace,
                                                  setTimePeriodForAssignment,
                                                  assignments,
                                                  detailsView,
                                                  selectedDate,
                                                  onTimeRangeErrorChangeCallback,
                                                  workingGroups
                                              }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const selectedWorkplacesAssignments = filterAssignmentsByDateAndWorkplaceIdAndSort(assignments, selectedWorkplace ? selectedWorkplace.id : null, selectedDate ? selectedDate : new Date())

    function getContent() {
        if (detailsView) {
            return <div className={classes.assignmentsContainer}>

                {selectedWorkplace.tags && selectedWorkplace.tags.length > 0 ?
                    <div className={classes.workplaceTagsSection}>
                        <TagsPanel workplace={selectedWorkplace} hideTitle tagRows={1}/>
                    </div> : null
                }

                {selectedWorkplacesAssignments.length
                    ? sortAssignments(selectedWorkplacesAssignments).map(assignment => {
                        return getAssignmentItem(assignment)
                    })
                    :
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: theme.innerGap
                    }}>
                        <BookingsEmptyStateSvg height={130}/>
                        <Typography variant={'caption'}>{t('no_bookings_yet')}</Typography>
                    </div>
                }

            </div>
        } else if (isBlocked(selectedWorkplace)) {
            return <div className={classes.blocked}>
                <Typography variant={'h6'}>{t('workplace_blocked')}</Typography>
                <SeatTakenSvgIcon/>
            </div>
        } else if (!selectedWorkplace.userAllowedToBook) {
            return <div className={classes.permissionWrapper}>
                <Typography variant={'body1'}>{t('workplace.noPermission')}</Typography>
                <div className={classes.permissions}>
                    <Typography variant={'h5'}>{t('workplace.permissions.for')}</Typography>
                    {selectedWorkplace.bookingPermissions &&
                        <PeopleAndWorkingGroupList
                            persons={selectedWorkplace.bookingPermissions.filter(p => !!p.person).map(p => p.person)}
                            workingGroups={selectedWorkplace.bookingPermissions.filter(p => !!p.workingGroup).map(p => p.workingGroup)}/>}
                </div>
            </div>

        } else {
            return <DaytimeSelectionWithUserWorkingHours date={selectedDate}
                                                         disabledIntervals={getAssignmentsAsIntervals([...filterForAssignmentsBlockingNewBooking(userAssignments), ...selectedWorkplacesAssignments])}
                                                         selectedCallback={getFreeDaytimeFromDisabledIntervals}
                                                         setTimePeriodCallback={setTimePeriodForAssignment}
                                                         errorCallback={onTimeRangeErrorChangeCallback}/>
        }
    }

    const getAssignmentItem = (assignment) => {
        return <div className={classes.assignmentEntry} key={assignment.id}>
            <StartAndEndTime startTime={assignment.timePeriod.startDate} endTime={assignment.timePeriod.endDate}/>
            <ActionListItem
                startDivider
                stretchDivider
                startAdornment={<AvatarWithOccupancy person={assignment.person} size={40}/>}
                text={getPersonsName(assignment.person)}
                height={60}
                hideArrow
                key={assignment.person.id}/>
        </div>
    }

    const getPersonsName = (person) => {
        if (person && person.name && person.surname) {
            return person.name + " " + person.surname
        }
        return t('anonymous_user')
    }

    if (!selectedWorkplace) {
        return null
    }

    return <div className={classes.expandedContent}>
        {getContent()}
    </div>
}

WorkplaceSelectionSpreadsheetContent.propTypes = {
    detailsView: PropTypes.bool,
    onTimeRangeErrorChangeCallback: PropTypes.func
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        selectedDate: state.assignmentBuilder.selectedDate,
        selectedWorkplace: state.workplace.favoredWorkplace,
        userAssignments: state.user.userAssignments,
        workingGroups: state.workingGroups.personGroups,
        assignments: state.workplace.assignments,
    }
}

const mapDispatchToProps = {
    setTimePeriodForAssignment: setTimePeriodForAssignment,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSelectionSpreadsheetContent)