import React from "react";

import {useTranslation} from "react-i18next";

import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles, Typography} from "@material-ui/core";

import ListEmptyStateSvg from "../../common/icons/ListEmptyStateSvg.js";
import PropTypes from "prop-types";


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '1.8rem',
        marginBottom: '8.6rem',
        overflow: 'auto',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    text: {
        marginTop: '1.8rem'
    },
    textMain: {
        textAlign: 'center',
        [theme.breakpoints.up('xlll')]: {
            fontSize: '17px',
        },
        [theme.breakpoints.up('xll')]: {
            fontSize: '22px',
            lineHeight: theme.typography.h3.lineHeight,
        },
        [theme.breakpoints.up('xxl')]: {
            fontSize: '28px',
            lineHeight: theme.typography.h2.lineHeight,
        },
        [theme.breakpoints.up('xxxl')]: {
            fontSize: '40px',
            lineHeight: theme.typography.h1.lineHeight,
        }
    },
    textSecondRow: {
        [theme.breakpoints.up('xlll')]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up('xll')]: {
            fontSize: '17px',
        },
        [theme.breakpoints.up('xxl')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.up('xxxl')]: {
            fontSize: '28px',
            lineHeight: theme.typography.h2.lineHeight,
        }
    }
}))

function EmptyStateColleagueBookings({width, height, textColor, secondaryTextKey = 'no_bookings_start'}) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation()

    return (
        <div className={classes.root} style={{width: width, height: height}}>
            <ListEmptyStateSvg/>
            <div className={classes.text}>
                <Typography variant={'h5'} style={{color: textColor}} className={classes.textMain}>
                    {t('no_bookings_yet')}
                </Typography>
                <Typography variant={'body1'} style={{color: textColor}} className={classes.textSecondRow}>
                    {t(secondaryTextKey)}
                </Typography>
            </div>
        </div>
    )
}

EmptyStateColleagueBookings.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    textColor: PropTypes.string,
    secondaryTextKey: PropTypes.string,
}


export default EmptyStateColleagueBookings