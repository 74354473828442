import * as React from "react"

function MeetingAttendingOnlineSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                stroke={props.stroke || '#60BF82'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path d="M14.382 23.978a9 9 0 119.598-9.597M18.478 11.079l-3.977 6.954-3.006-2.643"/>
                <circle cx={22.5} cy={22.5} r={6}/>
                <path
                    d="M23.224 28.644a10.817 10.817 0 001.901-6.144c0-2.204-.656-4.256-1.783-5.97m-1.65-.05a10.82 10.82 0 00-1.817 6.02c0 2.17.635 4.19 1.73 5.887"/>
                <path
                    d="M17.453 19.385a10.822 10.822 0 005.047 1.24c1.84 0 3.573-.457 5.092-1.263m.032 6.293a10.828 10.828 0 00-5.124-1.28c-1.796 0-3.49.435-4.984 1.207"/>
            </g>
        </svg>


    )
}

export default MeetingAttendingOnlineSvgIcon
