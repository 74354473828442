import * as React from "react"

function PictureSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                stroke="#333"
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <rect
                    width={21}
                    height={16.5}
                    rx={1}
                    transform="translate(4.5 6.749)"
                />
                <path d="M4.466 18.857l3.464-2.941 3.714 2.941 7-6.897 6.822 6.897"/>
                <circle cx={11.25} cy={12.75} r={1.5}/>
            </g>
        </svg>
    )
}

export default PictureSvgIcon