import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";
import moment from "moment/moment.js";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {IconButton, makeStyles, useMediaQuery} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import NavigationBackSvgIcon from "../common/icons/NavigationBackSvgIcon.js";
import useDidMountEffect from "../common/customHooks/useDidMountEffect.js";


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        gap: theme.innerGap,
    },
    calendars: {
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        gap: theme.bigOuterGap,
    },
    header: {
        width: '100%',
        height: '2.9rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    calendarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    calendarRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'nowrap',
    },
    dayItem: {
        width: '2.8rem',
        height: '2.1rem',
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dayItemText: {},
    selected: {
        background: `${theme.colors.gradients.skinMain} !important`,
        color: theme.colors.palette.neutral.white + '!important',
    },
    rangeSelected: {
        background: `${theme.colors.palette.corporate.skinLight} !important`,
        color: theme.colors.palette.neutral.white + '!important',
    },
    greyedOut: {
        color: theme.colors.palette.neutral.greyMain,
    },
    disabledDate: {
        color: theme.colors.palette.neutral.greyMain,
        cursor: 'auto'
    },
    blockedDate: {
        backgroundColor: theme.colors.palette.neutral.greyMedium,
    },
    error: {
        background: `${theme.colors.palette.corporate.redLight} !important`,
    },
    blockedDateError: {
        background: `${theme.colors.palette.corporate.redMain} !important`,
    },
    iconButton: {
        width: '3.5rem',
        height: '3.5rem',
        minWidth: '3.5rem',
        borderRadius: '50%',
        padding: 0,
    },
}));

function CustomDateRangePicker({
                                   minDate,
                                   maxDate,
                                   rangeMode,
                                   onDateChange,
                                   initialSelected,
                                   disabledIntervals,
                                   dateFormat,
                                   showHeader,
                                   centered,
                                   innerClassName,
                                   onError,
                                   disableEarlierThanNow,
                                   disableAfterNow,
                                   className
                               }) {

    const theme = useTheme();
    const classes = useStyle(theme);
    const [shownMonth, setShownMonth] = useState(moment(minDate).startOf('month'));
    const [selection, setSelection] = useState({
        startDate: initialSelected ? rangeMode && initialSelected.startDate ? initialSelected.startDate : moment(initialSelected).startOf('day') : moment().startOf('day'),
        endDate: initialSelected ? rangeMode && initialSelected.endDate ? initialSelected.endDate : moment(initialSelected).endOf('day') : moment().endOf('day')
    })
    const [isSecondClick, setIsSecondClick] = useState(null);
    const [error, setError] = useState(false);
    const xlBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        checkAndSetError(selection.startDate, selection.endDate)
        //eslint-disable-next-line
    }, [disabledIntervals])

    useDidMountEffect(() => {
        if (initialSelected) {
            if (rangeMode)
                setSelection(initialSelected)
            else
                setSelection({
                    startDate: moment(initialSelected).startOf('day'),
                    endDate: moment(initialSelected).endOf('day')
                })
            checkAndSetError(selection.startDate, selection.endDate)
        }
        //eslint-disable-next-line
    }, [initialSelected])

    useEffect(() => {
        if (onError)
            onError(error)
    }, [error, onError]);

    const getCalendarRow = (startDay, firstRow, lastRow) => {
        let rowArray = []
        let currentDate = startDay.clone().startOf('week')
        let endDate = startDay.clone().endOf('week')

        for (let i = 0; i <= 6; i++) { //iterate over one week
            if (xlBreakpoint && ((lastRow && currentDate.month() !== startDay.month()) || (firstRow && currentDate.month() !== endDate.month()))) {
                rowArray.push(<div/>)
            } else {
                rowArray.push(
                    <Button className={clsx(classes.iconButton, ...getSelectedClassnames(currentDate))}
                            data-date={currentDate.format()}
                            onClick={(event) => handleDateClick(event)}>
                        <Typography
                            variant={isSelectedStartOrEnd(currentDate) || isInSelectedRange(currentDate) ? 'body2' : outgrayDate(currentDate) || disabledDate(currentDate) ? 'h6' : 'body1'}>
                            {currentDate.format('D')}
                        </Typography>
                    </Button>
                )
            }
            currentDate.add(1, 'day')
        }

        return rowArray
    }

    const getCalendarGrid = (date) => {
        let monthArray = []
        let finishDate = date.clone().endOf('month')
        let dateToStart = date.clone().startOf('week')

        for (let i = dateToStart.clone(); i.diff(finishDate) < 0; i.add(1, 'week')) {
            let isLastRow = i.month() !== i.clone().add(1, 'week').month() && i.month() === date.month()
            monthArray.push(getCalendarRow(i, i.isSame(dateToStart.clone()), isLastRow));
        }

        while (monthArray.length < 6) {
            monthArray.push([<div/>])
        }

        return monthArray
    }

    const handleClickPrev = () => {
        let value = shownMonth.clone()
        value.subtract(1, 'month')
        setShownMonth(value)
    }

    const handleClickNext = () => {
        let value = shownMonth.clone()
        value.add(1, 'month')
        setShownMonth(value)
    }

    const handleDateClick = (event) => {

        let newSelection = moment(event.currentTarget.dataset.date)
        let dateSelection;

        if ((disableEarlierThanNow && newSelection.isBefore(moment.now(), 'day')) || (disableAfterNow && newSelection.isAfter(moment.now(), 'day'))) {
            return
        } else if (rangeMode) {
            if (isSecondClick) {
                if (selection.startDate.isSameOrBefore(newSelection) && !selection.endDate.isSame(newSelection, 'day')) {
                    dateSelection = {startDate: selection.startDate, endDate: newSelection.endOf('day')}
                    setIsSecondClick(false)
                } else {
                    dateSelection = {
                        startDate: newSelection.startOf('day'),
                        endDate: selection.endDate ? selection.endDate : selection.startDate.endOf('day')
                    }
                }

            } else {
                if (isSecondClick === null) {
                    dateSelection = {
                        startDate: moment.utc(newSelection.startOf('day')),
                        endDate: newSelection.endOf('day')
                    }
                    setIsSecondClick(true)
                } else if (selection.endDate.isAfter(newSelection) && !selection.startDate.isSame(newSelection, 'day')) {
                    dateSelection = {startDate: newSelection.startOf('day'), endDate: selection.endDate}
                    setIsSecondClick(true)
                } else {
                    dateSelection = {
                        startDate: selection.startDate ? selection.startDate : selection.endDate.startOf('day'),
                        endDate: newSelection.endOf('day')
                    }
                }
            }
        } else {
            dateSelection = {startDate: newSelection.startOf('day'), endDate: newSelection.endOf('day')}
        }

        //check for error
        checkAndSetError(dateSelection.startDate, dateSelection.endDate)

        setSelection(dateSelection)
        onDateChange && onDateChange(dateSelection)
    }

    function checkAndSetError(startDate, endDate) {
        let errValue = false
        for (let dateCheck = startDate.clone(); dateCheck.isBefore(endDate); dateCheck.add(1, 'day')) {
            if (blockedDate(dateCheck)) {
                errValue = true
                break
            }
        }
        setError(errValue)
    }

    const isSelectedStartOrEnd = (date) => {
        return date.isSame(selection.startDate, 'day') || date.isSame(selection.endDate, 'day');
    }

    const isInSelectedRange = (date) => {
        return date.isBetween(selection.startDate, selection.endDate, 'day')
    }

    const getSelectedClassnames = (date) => {
        let cssArray = []

        if (blockedDate(date)) {
            cssArray.push(classes.blockedDate)
        }
        if (outgrayDate(date)) {
            cssArray.push(classes.greyedOut)
        }

        if (isSelectedStartOrEnd(date)) {
            cssArray.push(classes.selected)
            if (error) {
                blockedDate(date) ? cssArray.push(classes.blockedDateError) : cssArray.push(classes.error)
            }
        } else if (isInSelectedRange(date)) {
            cssArray.push(classes.rangeSelected)
            if (error) {
                blockedDate(date) ? cssArray.push(classes.blockedDateError) : cssArray.push(classes.error)
            }
        }

        if (disabledDate(date)) {
            cssArray.push(classes.disabledDate)
        }

        return cssArray
    }

    //date where e.g. already exists a booking
    const blockedDate = (date) => {

        for (let interval of disabledIntervals) {
            if (moment(date).isBetween(moment(interval.startDate), moment(interval.endDate), 'hours', "[]")) {
                return true
            }
        }

        return false
    }

    //date that cannot be selected (not clickable)
    const disabledDate = (date) => {
        if (disableEarlierThanNow && moment(date).isBefore(moment.now(), 'day'))
            return true
        if (disableAfterNow && moment(date).isAfter(moment.now(), 'day'))
            return true
    }

    const outgrayDate = (date) => {
        if (xlBreakpoint) {
            return !(date.month() === shownMonth.month() || date.month() === shownMonth.clone().add(1, 'month').month());
        } else {
            return date.month() !== shownMonth.month();
        }
    }

    const getHeader = (date, isSecond) => {
        return <>
            <div className={classes.header}>
                {!isSecond ? <IconButton onClick={handleClickPrev} disabled={minDate && date.isSame(minDate, 'M')}>
                    <NavigationBackSvgIcon fill={minDate && date.isSame(minDate, 'M') ?
                        theme.colors.palette.neutral.greyMain
                        : theme.colors.palette.neutral.darkMain}/>
                </IconButton> : <div style={{width: '48px', height: '48px'}}/>
                }
                <Typography variant={'body1'} onClick={() => setShownMonth(minDate.startOf('month'))}
                            style={{cursor: 'pointer'}}>
                    {date.format('MMMM YYYY')}
                </Typography>
                {(!xlBreakpoint || isSecond) ?
                    <IconButton onClick={handleClickNext}
                                disabled={maxDate && (date.isSame(maxDate, 'M') || date.isAfter(maxDate, 'm'))}>
                        <NavigationBackSvgIcon style={{transform: 'rotate(180deg)'}}
                                               fill={maxDate && (date.isSame(maxDate, 'M') || date.isAfter(maxDate, 'm')) ?
                                                   theme.colors.palette.neutral.greyMain
                                                   : theme.colors.palette.neutral.darkMain}
                        />
                    </IconButton> : <div style={{width: '48px', height: '48px'}}/>
                }
            </div>
            <div style={{display: 'flex', flexWrap: 'nowrap', width: '100%', justifyContent: 'center'}}>
                {[...Array(7).keys()].map((day, index) =>
                    <Typography key={index} variant={'body1'} className={classes.dayItem} style={{fontWeight: 'bold'}}>
                        {moment().weekday(day).format('dd')}
                    </Typography>)
                }
            </div>
        </>
    }

    return (
        <div className={classes.root}>
            {showHeader &&
                <div className={clsx(classes.header, className)} style={{paddingLeft: xlBreakpoint ? null : '1rem'}}>
                    {rangeMode
                        ? <Typography>
                            {selection.startDate.format(dateFormat) + ' - ' +
                                selection.endDate.format(dateFormat)}
                        </Typography>
                        : <Typography>
                            {selection.startDate.format(dateFormat)}
                        </Typography>
                    }
                </div>}
            <div className={clsx(classes.calendars, innerClassName)}
                 style={{justifyContent: centered ? 'center' : null}}>
                <div className={classes.calendarContainer}>
                    <div>
                        {getHeader(shownMonth)}
                        {
                            getCalendarGrid(shownMonth).map((row, i) => {
                                return <div key={i} className={classes.calendarRow}>
                                    {row.map((item, j) => {
                                        return <div className={classes.dayItem} key={j}>{item}</div>
                                    })}
                                </div>
                            })
                        }
                    </div>
                </div>

                {xlBreakpoint &&
                    <div className={classes.calendarContainer}>
                        {getHeader(shownMonth.clone().add(1, 'month'), true)}
                        {
                            getCalendarGrid(shownMonth.clone().add(1, 'month')).map((row, i) => {
                                return <div key={i} className={classes.calendarRow}>
                                    {row.map((item, j) => {
                                        return <div className={classes.dayItem} key={j}>{item}</div>
                                    })}
                                </div>
                            })
                        }
                    </div>
                }
            </div>
        </div>
    );
}

CustomDateRangePicker.propTypes = {
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    color: PropTypes.object,
    rangeMode: PropTypes.bool,
    onDateChange: PropTypes.func,
    onError: PropTypes.func,
    initialSelected: PropTypes.object,
    disabledIntervals: PropTypes.arrayOf(
        PropTypes.shape({
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        })),
    disableEarlierThanNow: PropTypes.bool,
    disableAfterNow: PropTypes.bool,
    dateFormat: PropTypes.string,
    showHeader: PropTypes.bool,
    className: PropTypes.string,
    centered: PropTypes.bool,
    innerClassName: PropTypes.string,
};

CustomDateRangePicker.defaultProps = {
    rangeMode: true,
    dateFormat: 'dd, D MMM',
    showHeader: true,
    centered: false,
    disabledIntervals: [],
    disableEarlierThanNow: false,
    disableAfterNow: false,
};

export default CustomDateRangePicker;