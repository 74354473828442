import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {DAY_MON, WEEK_DAYS} from "../utils/NameUtils.js";
import DayAndTimeperiodSelectionItem from "./DayAndTimeperiodSelectionItem.js";
import useDidMountEffect from "../customHooks/useDidMountEffect.js";
import CustomButton from "../../components/CustomButton.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: theme.outerGap,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap
    }
}))

function MultiSelectionDayWithTimePicker({setDaysAndTimes, originalSelectedDays}) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);

    const initialStartHour = 9
    const initialEndHour = 18
    const initialStartTime = new Date()
    initialStartTime.setHours(initialStartHour, 0, 0)
    const initialEndTime = new Date()
    initialEndTime.setHours(initialEndHour, 0, 0);

    const [selectedDays, setSelectedDays] = useState(originalSelectedDays && Object.keys(originalSelectedDays).length ? new Map(Object.entries(originalSelectedDays)) :
        new Map([[DAY_MON, {
            startDate: initialStartTime,
            endDate: initialEndTime
        }]]));
    const [saveDisabled, setSaveDisabled] = useState(originalSelectedDays && Object.keys(originalSelectedDays).length);

    useDidMountEffect(() => {
        if (originalSelectedDays && Object.keys(originalSelectedDays).length) {
            setSaveDisabled(true)
            setSelectedDays(new Map(Object.entries(originalSelectedDays)))
        }
    }, [originalSelectedDays]);

    function onSelectDay(selectedDay, start, end) {
        let daysState = new Map(selectedDays)

        if (!start && !end && !!selectedDays.get(selectedDay)) {
            daysState.delete(selectedDay)
            setSelectedDays(daysState)
        } else {
            daysState.set(selectedDay, {startDate: start, endDate: end})
            setSelectedDays(daysState)
        }
        setSaveDisabled(false)
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                {WEEK_DAYS.map(day => {
                    return <DayAndTimeperiodSelectionItem day={day}
                                                          key={day}
                                                          dayLabel={t(day.toLowerCase()).slice(0, 2)}
                                                          selected={selectedDays.has(day)}
                                                          timeStartLabel={t('start')} timeEndLabel={t('end')}
                                                          initialStartTime={selectedDays.get(day)?.startDate ?? initialStartTime}
                                                          initialEndTime={selectedDays.get(day)?.endDate ?? initialEndTime}
                                                          onChange={onSelectDay}/>
                })}
            </div>
            <CustomButton text={t('save')} onClick={() => setDaysAndTimes(selectedDays)} primary
                          disabled={saveDisabled}
            />
        </div>
    )
}

MultiSelectionDayWithTimePicker.propTypes = {
    setDaysAndTimes: PropTypes.func,
    originalSelectedDays: PropTypes.object
}

export default MultiSelectionDayWithTimePicker