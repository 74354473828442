import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {exportTimeTrackings} from "../../../actions/timetracking-actions.js";
import {connect} from "react-redux";
import ModalWrapper from "../../../common/elements/ModalWrapper.js";
import PropTypes from "prop-types";
import CustomDateRangePicker from "../../CustomDateRangePicker.js";
import moment from "moment/moment.js";
import Typography from "@material-ui/core/Typography";
import ClickableText from "../../../common/elements/ClickableText";

const useStyle = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        textAlign: 'center'

    },
    datePicker: {
        justifyContent: 'center'
    },
}))

function ExportTimeTrackingModal({
                                     open,
                                     exportTimeTracking,
                                     userId,
                                     onClose,
                                     exportTimetrackingPending,
                                     ...props
                                 }) {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = useStyle(theme);

    const [range, setRange] = useState({startDate: moment().startOf('month'), endDate: moment().endOf('month')});

    return (
        <ModalWrapper open={open}
                      onClose={onClose}
                      headerActions={<ClickableText text={t('export')}
                                                    underlined
                                                    isLoading={exportTimetrackingPending}
                                                    onClick={() => exportTimeTracking(userId, range.startDate.toDate(), range.endDate.toDate())}/>}>
            <div className={classes.content}>
                <CustomDateRangePicker onDateChange={setRange} initialSelected={range} className={classes.datePicker}/>
                <Typography variant={'body1'}>{t('select_range_for_timetracking')}</Typography>
            </div>

        </ModalWrapper>
    )
}

ExportTimeTrackingModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        exportTimetrackingPending: state.timetracking.exportTimetrackingPending,
    }
}

const mapDispatchToProps = {
    exportTimeTracking: exportTimeTrackings,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportTimeTrackingModal)