import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import {useTranslation} from "react-i18next";
import ChooseWorkingGroupDetails from "./ChooseWorkingGroupDetails";
import ChooseColleagueDetails from "./ChooseColleagueDetails";
import Searchbar from "../../common/elements/Searchbar.js";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem'
    },
    searchBar: {
        boxSizing: 'border-box',
    },
}));

function ChooseWorkTogether({
                                pageSelection,
                                confirmationButtonsDisabled
                            }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation()

    const [searchString, setSearchString] = useState('');

    return (
        <div className={classes.content}>
            <Searchbar placeholder={pageSelection === 2 ? t('search_groups') : t('search_colleagues')}
                       searchString={searchString} className={classes.searchBar}
                       setSearchString={setSearchString}/>

            {pageSelection === 2
                ? <ChooseWorkingGroupDetails searchString={searchString}
                                             confirmationButtonsDisabled={confirmationButtonsDisabled}/>
                : <ChooseColleagueDetails searchString={searchString}/>}
        </div>
    );
}

ChooseWorkTogether.propTypes = {
    confirmationButtonsDisabled: PropTypes.bool.isRequired,
    pageSelection: PropTypes.number.isRequired
}

export default ChooseWorkTogether