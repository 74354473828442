import {Mesh, PlaneBufferGeometry} from 'three'
import {getMaterial, materialTypes} from "../drawingTool/three/common/materials";

export function addCatchPlane(scene) {
    const geometry = new PlaneBufferGeometry(2000, 2000, 1, 1)
    const material = getMaterial(materialTypes.transparent)

    const mesh = new Mesh(geometry, material)
    mesh.name = 'catchPlane'
    mesh.position.set(0, 0, -.9)

    scene.add(mesh)
}