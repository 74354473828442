import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const useStyle = makeStyles(theme => ({
    root: {
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
        borderRadius: '9px',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
        width: '48px',
        height: '100px',
        zIndex: 1,
        '& .MuiButtonGroup-grouped': {
            border: 'none'
        },
        '& .MuiButton-outlined.Mui-disabled': {
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            color: theme.colors.palette.neutral.greyMedium
        },
        '& .MuiButton-root:hover': {
            backgroundColor: 'none'
        },
        '& .MuiTouchRipple-root': {
            display: 'none'
        },
        '& .MuiButton-label': {
            width: 'auto'
        }

    },
}));

function FloorplanButtonGroup({buttons, ...props}) {
    const classes = useStyle(props.theme);

    return (
        <ButtonGroup className={classes.root}
                     orientation="vertical"
                     aria-label="vertical contained button group"
        >
            {buttons}
        </ButtonGroup>
    );
}

FloorplanButtonGroup.propTypes = {
    buttons: PropTypes.array.isRequired
};

FloorplanButtonGroup.defaultPropTypes = {};

export default FloorplanButtonGroup;