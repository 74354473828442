import * as React from "react"
import PropTypes from 'prop-types'

const LanguageSvgIcon = ({width, height, color, ...props}) => (
    <svg width={width || '100%'} height={height || '100%'} viewBox="0 0 30 30"
         xmlns="http://www.w3.org/2000/svg" {...props}>
        <g stroke={color} fill="none">
            <path
                d="M13.125 22.125v-9.25a1 1 0 0 1 1-1h11.75a1 1 0 0 1 1 1v8.365a4 4 0 0 1-1.454 3.085l-3.306 2.728a.125.125 0 0 1-.202-.072v-.05l.664-3.205a.5.5 0 0 0-.49-.601h-7.962a1 1 0 0 1-1-1z"/>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.725 20.895 20 14.33l2.275 6.564"
            />
            <path
                d="M18.433 18.75h2.991m-7.299-6.875h2.75v-7.75a1 1 0 0 0-1-1H4.125a1 1 0 0 0-1 1v8.365a4 4 0 0 0 1.454 3.085l3.306 2.728a.125.125 0 0 0 .202-.072v-.05l-.664-3.205a.5.5 0 0 1 .49-.601h5.212v-1.5a1 1 0 0 1 1-1z"/>
            <g strokeLinecap="round" strokeLinejoin="round">
                <path d="M10 5.25v2.064m2.5 0h-5m4.104 0C11.538 10.51 10.17 12.11 7.5 12.11"/>
                <path d="M8.678 9.095c.857 2.01 2.218 3.015 4.084 3.015"/>
            </g>
        </g>
    </svg>
)

LanguageSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string,
}

LanguageSvgIcon.defaultProps = {
    color: '#333',
    fill: 'none',
}

export default LanguageSvgIcon