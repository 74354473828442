import React from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {withStyles} from "@material-ui/core";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import SearchableList from "../../common/elements/SearchableList";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";

import {getBuildings, setBuilding} from "../../actions/buildings-actions";

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
    },
    container: {
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-around',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
})

class ChooseBuildingPage extends React.Component {

    componentDidMount() {
        this.props.getBuildings(true)
    }

    handleBuildingSelected = (building) => {
        this.props.setBuilding(building)
        setTimeout(() => {
            this.routeToBefore()
        }, 250);
    }

    routeToBefore = () => {
        this.props.history.goBack()
    }

    getRelevantBuildings = () => {
        if (!this.props.buildings)
            return []
        return this.props.buildings?.map(building => ({element: building}))
    }

    render() {
        const {classes, t, selectedBuilding} = this.props;
        return (
            <Grid container component={Paper} className={classes.root}>
                <Grid item sm={12} className={classes.container}>
                    <div className={classes.content}>
                        <HeaderWithTitleAndBackButton title={t('buildings')} backLabel={t('back')}
                                                      onNavigateBack={this.routeToBefore}/>


                        <SearchableList options={this.getRelevantBuildings()}
                                        onSelect={this.handleBuildingSelected}
                                        selected={selectedBuilding}
                                        placeholder={t('search_buildings')}
                        />
                    </div>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        buildings: state.buildings.buildingList,
        selectedBuilding: state.buildings.building,
    }
}

const mapDispatchToProps = {
    getBuildings: getBuildings,
    setBuilding: setBuilding,
}

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(ChooseBuildingPage))