import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";

import {compose} from "redux";
import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import {Grid, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import withStyles from "@material-ui/core/styles/withStyles";

import CustomButton from "../CustomButton.js";
import CardComponent from "../../common/elements/CardComponent.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import SingleLineDatePickerWithUsersWorkingHours from "../SingleLineDatePickerWithUsersWorkingHours";
import {getWorkingHoursOrDefaultHoursForDate} from "../../common/customHooks/usePersonWorkingHours.js";
import DaytimeSelectionWithUserWorkingHours from "../../common/elements/DaytimeSelectionWithUserWorkingHours.js";

import HomeSvgIcon from "../../common/icons/HomeSvgIcon.js";
import OutOfOfficeSvgIcon from "../../common/icons/OutOfOfficeSvgIcon.js";

import {getAssignmentsOfUser} from "../../actions/user-action";
import {
    assignWorkplaceToPerson,
    setSelectedDate,
    setTimePeriodForAssignment
} from "../../actions/workplaceAssignment-action";

import {
    getActivityColor,
    getAssignmentsAsIntervals,
    isBlockingAssignment,
    isDateBookedByUserAllDay
} from "../../common/utils/AssignmentUtils";
import {PATH_BOOKINGS} from "../../common/utils/NavigationUtils.js";
import {getFreeDaytimeFromDisabledIntervals, getTimePeriodAtDate, isSameDay} from "../../common/utils/TimeUtils";
import {TYPE_OF_BOOKING_HOMEOFFICE, TYPE_OF_BOOKING_OUT_OF_OFFICE} from "../../common/utils/NameUtils";


const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        margin: '0 auto',
        padding: theme.paddingContentContainer.padding,
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: theme.gridSizes.maxWidth,
        },
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1
    },
    info: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap
    },
    icon: {
        borderRadius: '50%',
        height: '28px',
        width: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        stroke: theme.colors.palette.neutral.white
    },
    remoteContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2rem'
    },
    cardsContainer: {
        display: 'flex',
        gap: theme.innerGap,
    },
    heading: {
        textAlign: 'left'
    }
})

function RemoteBooking({
                           classes,
                           t,
                           userAssignments,
                           userId,
                           timePeriod,
                           assignWorkplaceToPerson,
                           getAssignmentsOfUser,
                           setTimePeriodForAssignment,
                           setSelectedDate,
                           selectedDate,
                           assignWorkplaceToPersonPending,
                           onChange,
                           ...props
                       }) {
    const theme = useTheme()
    let history = useHistory()

    const [selectedBookingOption, setSelectedBookingOption] = useState(TYPE_OF_BOOKING_HOMEOFFICE);
    const [confirmationButtonsDisabled, setConfirmationButtonsDisabled] = useState(false);
    const [disabledIntervals, setDisabledIntervals] = useState([]);

    const isBookingOptionSelected = (typeOfWorkplaceBooking) => {
        return selectedBookingOption === typeOfWorkplaceBooking
    }

    useEffect(() => {
        if (isEmpty(userAssignments) && userId) {
            getAssignmentsOfUser(userId, false, true)
        }
        // eslint-disable-next-line
    }, [userId])

    useEffect(() => {
        setDisabledIntervals(getAssignmentsAsIntervals(userAssignments.filter((assignment) =>
            isSameDay(selectedDate, assignment.timePeriod.startDate) && isBlockingAssignment(assignment))))
    }, [userAssignments, selectedDate])

    //callback function for SingleLineDatePicker to set the date of the assignment
    function setDate(date) {
        setSelectedDate(date)
    }

    function routeBack() {
        history.push('/')
    }

    function bookRemote() {
        let timePeriodAtDate = getTimePeriodAtDate(timePeriod, selectedDate)
        const assignment = {
            timePeriod: timePeriodAtDate,
            typeOfWorkplaceBooking: selectedBookingOption
        }

        assignWorkplaceToPerson(userId, assignment)
            .then(() =>
                history.push(PATH_BOOKINGS)
            )
    }

    const showIndicator = useCallback((date) => {
        return isDateBookedByUserAllDay(date, userAssignments, getWorkingHoursOrDefaultHoursForDate(date))
    }, [userAssignments])

    return (
        <Grid container component={Paper} className={classes.root}>

            <HeaderWithTitleAndBackButton title={t('remote')} backLabel={t('home')} onNavigateBack={routeBack}/>

            <div className={classes.content}>
                <div className={classes.info}>

                    <SingleLineDatePickerWithUsersWorkingHours onChange={(date) => setDate(date)}
                                                               startDate={new Date()}
                                                               color={theme.colors.gradients.skinMain}
                                                               showIndicator={showIndicator}
                                                               skipDate={showIndicator}
                                                               selected={selectedDate ? selectedDate : null}
                    />

                    <DaytimeSelectionWithUserWorkingHours setTimePeriodCallback={setTimePeriodForAssignment}
                                                          date={selectedDate}
                                                          disabledIntervals={disabledIntervals}
                                                          errorCallback={setConfirmationButtonsDisabled}
                                                          selectedCallback={getFreeDaytimeFromDisabledIntervals}
                                                          className={classes.daytimeSelection}
                    />

                    <Typography variant={'h6'} className={classes.heading}>{t("Type")}</Typography>

                    <div className={classes.cardsContainer}>
                        <CardComponent
                            halfWidth
                            icon={<HomeSvgIcon className={classes.icon}
                                               style={{backgroundColor: getActivityColor(TYPE_OF_BOOKING_HOMEOFFICE)}}/>}
                            boldText={t("Home")}
                            text={t("office")}
                            isFlex
                            isSelected={isBookingOptionSelected(TYPE_OF_BOOKING_HOMEOFFICE)}
                            onClickCallback={() => setSelectedBookingOption(TYPE_OF_BOOKING_HOMEOFFICE)}>
                        </CardComponent>
                        <CardComponent
                            halfWidth
                            icon={<OutOfOfficeSvgIcon className={classes.icon}
                                                      style={{backgroundColor: getActivityColor(TYPE_OF_BOOKING_OUT_OF_OFFICE)}}/>}
                            boldText={t("out_of")}
                            text={t("out_of_house")}
                            isFlex
                            isSelected={isBookingOptionSelected(TYPE_OF_BOOKING_OUT_OF_OFFICE)}
                            onClickCallback={() => setSelectedBookingOption(TYPE_OF_BOOKING_OUT_OF_OFFICE)}>
                        </CardComponent>
                    </div>
                </div>

                <CustomButton text={t('book')}
                              onClick={bookRemote}
                              fullWidth
                              primary
                              disabled={confirmationButtonsDisabled}
                              isLoading={assignWorkplaceToPersonPending}
                />
            </div>
        </Grid>
    )
}

RemoteBooking.propTypes = {
    onChange: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        timePeriod: state.assignmentBuilder.timePeriod,
        selectedDate: state.assignmentBuilder.selectedDate,
        userAssignments: state.user.userAssignments,
        assignWorkplaceToPersonPending: state.workplace.assignWorkplaceToPersonPending,
    }
};

const mapDispatchToProps = {
    setTimePeriodForAssignment: setTimePeriodForAssignment,
    getAssignmentsOfUser: getAssignmentsOfUser,
    assignWorkplaceToPerson: assignWorkplaceToPerson,
    setSelectedDate: setSelectedDate
};

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(RemoteBooking))