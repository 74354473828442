import React from 'react';
import {
    navigate,
    PATH_PROFILE,
    PATH_PROFILE_HOMEZONE,
    PATH_PROFILE_INTEGRATIONS,
    PATH_PROFILE_LANGUAGE,
    PATH_PROFILE_NOTIFICATION,
    PATH_PROFILE_PASSWORD,
    PATH_PROFILE_SHARING,
    PATH_PROFILE_WORKING_DAYS
} from "../../common/utils/NavigationUtils.js";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ProfileMenuSettings from "./ProfileMenuSettings.js";
import LanguageSvgIcon from "../../common/icons/LanguageSvgIcon.js";
import IntegrationsSvgIcon from "../../common/icons/IntegrationsSvgIcon.js";
import PasswordSvgIcon from "../../common/icons/PasswordSvgIcon.js";
import NotificationSvgIcon from "../../common/icons/NotificationSvgIcon.js";
import ListSubheader from '@material-ui/core/ListSubheader';
import ShareLocationSvgIcon from "../../common/icons/ShareLocationSvgIcon.js";
import HomezoneSvgIcon from "../../common/icons/HomezoneSvgIcon.js";
import CalendarSvgIcon from "../../common/icons/CalendarSvgIcon.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
        alignItems: 'center',
    },
    settingsBlock: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap,
    },
    subheader: {
        display: 'flex',
        alignSelf: 'start',
        textTransform: 'uppercase',
        width: '100%',
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
        borderRadius: '8px',
        height: '50px'
    }
}))

function ProfileSettings() {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);

    const settingsMenuItems = [
        {title: t('language'), icon: <LanguageSvgIcon/>, path: PATH_PROFILE_LANGUAGE},
        {title: t('integrations'), icon: <IntegrationsSvgIcon/>, path: PATH_PROFILE_INTEGRATIONS},
        {title: t('password'), icon: <PasswordSvgIcon/>, path: PATH_PROFILE_PASSWORD},
        {title: t('notifications'), icon: <NotificationSvgIcon/>, path: PATH_PROFILE_NOTIFICATION},
    ]

    const accountMenuItems = [
        {title: t('share_settings'), icon: <ShareLocationSvgIcon/>, path: PATH_PROFILE_SHARING},
        {title: t('homezone'), icon: <HomezoneSvgIcon/>, path: PATH_PROFILE_HOMEZONE},
        {title: t('working_days'), icon: <CalendarSvgIcon/>, path: PATH_PROFILE_WORKING_DAYS},
    ]


    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton title={t('settings')} backLabel={t("profile")}
                                          onNavigateBack={() => navigate(history, PATH_PROFILE)}/>
            <div className={classes.settingsBlock}>
                <ListSubheader className={classes.subheader}>{t('account')}</ListSubheader>
                <ProfileMenuSettings title={t('settings')} menuItems={settingsMenuItems}/>
            </div>
            <div className={classes.settingsBlock}>
                <ListSubheader className={classes.subheader}>{t('preferences')}</ListSubheader>
                <ProfileMenuSettings title={t('account')} menuItems={accountMenuItems}/>
            </div>
        </div>
    )
}

export default ProfileSettings