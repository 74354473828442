import React from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = props => makeStyles(theme => ({
    root: {
        width: props.size ? `${props.size}px` : '5.9rem',
        height: props.size ? `${props.size}px` : '5.9rem',
        padding: props.selectable ? '3px' : '0px',
        borderRadius: '50%',
    },
    selected: {
        border: `solid 3px ${theme.colors.palette.corporate.skinMain}`
    },
    innerButton: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${props.color || theme.colors.palette.neutral.greyMedium}`
    }
}));

function ActivityButtonRound({selected, onButtonClick, index, ...props}) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);

    const onButtonClicked = () => {
        if (onButtonClick)
            onButtonClick(index)

    }

    return (
        <div className={selected ? clsx(classes.root, classes.selected) : classes.root} onClick={onButtonClicked}>
            <div className={classes.innerButton}>
                {props.children}
            </div>
        </div>
    );
}

ActivityButtonRound.propTypes = {
    color: PropTypes.string,
    selected: PropTypes.bool,
    onButtonClick: PropTypes.func,
    index: PropTypes.number,
    size: PropTypes.number,
    selectable: PropTypes.bool,
};

ActivityButtonRound.defaultProps = {
    color: null,
    selected: false,
    size: null,
    selectable: true,
};

export default ActivityButtonRound;