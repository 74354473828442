import React, {useEffect, useState} from 'react';
import useTheme from "@material-ui/core/styles/useTheme.js";
import {makeStyles} from "@material-ui/core";
import RoundedSelectionButton from "./RoundedSelectionButton.js";
import PropTypes from "prop-types";
import CustomTimePicker from "./CustomTimePicker.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        gap: theme.outerGap,
        justifyContent: 'space-between',
    },
}))

function DayAndTimeperiodSelectionItem({
                                           day,
                                           dayLabel,
                                           selected,
                                           timeStartLabel,
                                           timeEndLabel,
                                           initialStartTime,
                                           initialEndTime,
                                           onChange
                                       }) {
    const theme = useTheme()
    const classes = useStyle(theme);

    const [error, setError] = useState(initialStartTime > initialEndTime)

    useEffect(() => {
        setError(new Date(initialStartTime) > new Date(initialEndTime))
    }, [initialStartTime, initialEndTime])

    function onChangeStart(time) {
        onChange(day, time, initialEndTime)
    }

    function onChangeEnd(time) {
        onChange(day, initialStartTime, time)
    }

    return (
        <div className={classes.root}>
            <RoundedSelectionButton title={dayLabel} active={selected}
                                    onClick={() => selected ? onChange(day) : onChange(day, initialStartTime, initialEndTime)}
            />

            <CustomTimePicker label={timeStartLabel} onSubmit={onChangeStart} disabled={!selected}
                              submitOnChange
                              showNowButton={false}
                              initialDate={new Date(initialStartTime)} error={error}
            />

            <CustomTimePicker label={timeEndLabel} onSubmit={onChangeEnd} disabled={!selected}
                              submitOnChange
                              showNowButton={false}
                              initialDate={new Date(initialEndTime)} error={error}
            />
        </div>
    )
}

DayAndTimeperiodSelectionItem.propTypes = {
    day: PropTypes.string,
    selected: PropTypes.bool,
    dayLabel: PropTypes.string,
    timeStartLabel: PropTypes.string,
    timeEndLabel: PropTypes.string,
    initialStartTime: PropTypes.any,
    initialEndTime: PropTypes.any,
    onChange: PropTypes.func
}

export default DayAndTimeperiodSelectionItem