import * as React from "react"
import PropTypes from 'prop-types'

function HomezoneSvgIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 40 40"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle cx={20} cy={12} r={6} fill={props.fill || 'transparent'} stroke={props.color}
                        strokeWidth={1.5}/>
                <path
                    stroke={props.color}
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M20 25c-7.18 0-13 1.79-13 4s5.82 4 13 4 13-1.79 13-4c0-1.105-1.455-2.105-3.808-2.828-1.197-.369-2.627-.666-4.216-.869M20 17v12"
                />
            </g>
        </svg>
    )
}

HomezoneSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
}

HomezoneSvgIcon.defaultProps = {
    width: 24,
    height: 24,
    color: '#333',
}

export default HomezoneSvgIcon
