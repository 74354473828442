import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper/Paper.js";
import useTheme from "@material-ui/core/styles/useTheme";
import {List, makeStyles, Tooltip, Typography} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline.js";

import CustomButton from "../CustomButton.js";
import SingleLineDatePicker from "../SingleLineDatePicker.js";
import SimpleListItem from "../../common/elements/SimpleListItem.js";
import {usePersonWorkingHours} from "../../common/customHooks/usePersonWorkingHours.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import CustomTimeperiodSelectionPopup from "../../common/elements/CustomTimeperiodSelectionPopup.js";


import {getHomezone} from "../../actions/user-action.js";
import XSvgIcon from "../../common/icons/XSvgIcon.js";

import {setFloor} from "../../actions/floors-actions.js";
import {getBookableFreeResources, setSelectedResourceCategory} from "../../actions/resource-actions.js";
import {setSelectedDate, setTimePeriodForAssignment} from "../../actions/workplaceAssignment-action.js";
import {getTimeAsString, getTimePeriodAtDate} from "../../common/utils/TimeUtils.js";

import {
    navigate,
    PATH_BUILDING_SELECTION,
    PATH_CHOOSE_RESOURCE,
    PATH_FLOOR_SELECTION,
    PATH_HOME,
    PATH_RESOURCE_CATEGORY_SELECTION
} from "../../common/utils/NavigationUtils.js";
import {setBuilding} from "../../actions/buildings-actions.js";
import IconButton from "@material-ui/core/IconButton";


const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.paddingContentContainer.padding,
        [theme.breakpoints.between('lg', 'xl')]: {
            padding: theme.paddingContentContainer.padding,
        },
    },
    container: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: theme.gridSizes.maxWidth,
        }
    },
    content: {
        display: 'flex',
        gap: theme.outerGap,
        flexDirection: "column"
    },
    items: {
        display: 'flex',
        flexDirection: "column"
    },
    oneLine: {
        display: 'flex',
        justifyContent: "space-between",
        gap: theme.innerSmallGap,
        height: '7.2rem',
        alignItems: 'center'
    },
    box: {
        border: 'solid 1px ' + theme.colors.palette.neutral.greyMedium,
        borderRadius: '9px',
        width: '12rem',
        height: '4rem',
        justifyContent: 'center',
        display: "flex",
        alignItems: 'center'
    },
    listRoot: {
        padding: 0
    }
}));

function ResourceBookingDetailsPage({
                                        userId,
                                        selectedDate,
                                        homeZone,
                                        selectedBuilding,
                                        setTimeperiod,
                                        floor,
                                        getHomezone,
                                        selectedCategory,
                                        setCategory,
                                        setSelectedDate,
                                        getBookableFreeResources,
                                        timePeriod,
                                        setSelectedFloor,
                                        setSelectedBuilding,
                                    }) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation()
    const history = useHistory();
    const defaultTimeperiod = usePersonWorkingHours(selectedDate)

    const [timeModalOpen, setTimeModalOpen] = useState(false);
    const [invalidTimeperiod, setInvalidTimeperiod] = useState(false);

    useEffect(() => {
        if (userId && null === homeZone)
            getHomezone(userId)
    }, [userId, getHomezone, homeZone])

    useEffect(() => {
        setTimeperiod({startDate: defaultTimeperiod.startDate, endDate: defaultTimeperiod.endDate})
    }, [defaultTimeperiod, setTimeperiod])

    const xIconWithTooltip = (callback) => {
        return (
            <Tooltip title={t('delete')}>
                <IconButton size={'small'}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                callback()
                            }}>
                    <XSvgIcon height={24} width={24}/>
                </IconButton>
            </Tooltip>
        );
    }

    const routeToHome = () => {
        navigate(history, PATH_HOME)
    }

    const routeToResourceCategories = () => {
        navigate(history, PATH_RESOURCE_CATEGORY_SELECTION)
    }

    const routeToBuildings = () => {
        navigate(history, PATH_BUILDING_SELECTION)
    }

    const routeToFloors = () => {
        navigate(history, PATH_FLOOR_SELECTION)
    }

    function handleSelectResourceButton() {
        let tp = getTimePeriodAtDate(timePeriod, selectedDate)
        let isoTp = {
            startDate: tp.startDate.toISOString(),
            endDate: tp.endDate.toISOString(),
        }
        getBookableFreeResources(isoTp, selectedCategory?.id, selectedBuilding?.id, floor?.id)
        navigate(history, PATH_CHOOSE_RESOURCE)
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <CssBaseline/>
            <Grid item sm={12} className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.content}>
                        <HeaderWithTitleAndBackButton title={t('resource.book')}
                                                      backLabel={t("home")}
                                                      onNavigateBack={routeToHome}/>

                        <SingleLineDatePicker startDate={new Date()}
                                              onChange={(date) => setSelectedDate(date)}
                                              caption={t('date')}
                                              color={theme.colors.palette.corporate.skinMain}
                                              selected={selectedDate ? selectedDate : new Date()}/>
                    </div>
                    <div className={classes.items}>
                        <Divider/>
                        <div className={classes.oneLine}>
                            <Typography>{t('from')}</Typography>
                            <div className={classes.box} onClick={() => setTimeModalOpen(true)}>
                                <Typography variant={'body1'}>
                                    {getTimeAsString(timePeriod.startDate)}
                                </Typography>
                            </div>
                            <Typography>{t('to')}</Typography>
                            <div className={classes.box} onClick={() => setTimeModalOpen(true)}>
                                <Typography variant={'body1'}>
                                    {getTimeAsString(timePeriod.endDate)}
                                </Typography>
                            </div>
                        </div>
                        <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                              className={classes.listRoot}>

                            <SimpleListItem title={t('resource.category')}
                                            onClick={routeToResourceCategories}
                                            alternativeEndAdornment={
                                                <div className={classes.oneLine}>
                                                    {selectedCategory && xIconWithTooltip(() => setCategory(null))}
                                                    <Typography sx={{display: 'inline'}} component="span">
                                                        {selectedCategory ? selectedCategory.name : ''}
                                                    </Typography>
                                                </div>
                                            }
                            />
                            <SimpleListItem title={t('building')} onClick={routeToBuildings}
                                            alternativeEndAdornment={
                                                <div className={classes.oneLine}>
                                                    {selectedBuilding && xIconWithTooltip(() => setSelectedBuilding(null))}
                                                    <Typography sx={{display: 'inline'}} component="span">
                                                        {selectedBuilding ? selectedBuilding.name : ''}
                                                    </Typography>
                                                </div>
                                            }
                            />
                            <SimpleListItem title={t('floor')} onClick={routeToFloors}
                                            alternativeEndAdornment={
                                                <div className={classes.oneLine}>
                                                    {floor && xIconWithTooltip(() => setSelectedFloor(null))}
                                                    <Typography sx={{display: 'inline'}} component="span">
                                                        {floor ? floor.name : ''}
                                                    </Typography>
                                                </div>
                                            }

                            />
                        </List>

                    </div>
                </div>

                <CustomButton text={t('resource.choose')}
                              onClick={handleSelectResourceButton}
                              disabled={invalidTimeperiod}
                              fullWidth primary/>
            </Grid>
            {timeModalOpen &&
                <CustomTimeperiodSelectionPopup
                    onSave={tp => {
                        setTimeperiod(tp)
                    }}
                    open={timeModalOpen}
                    initialTimeperiod={{
                        startDate: timePeriod.startDate,
                        endDate: timePeriod.endDate,
                    }}
                    errorCallback={setInvalidTimeperiod}
                    onClose={() => {
                        setTimeModalOpen(false)
                    }}/>}
        </Grid>
    );
}

ResourceBookingDetailsPage.propTypes = {}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        selectedDate: state.assignmentBuilder.selectedDate,
        timePeriod: state.assignmentBuilder.timePeriod,
        buildings: state.buildings.buildingList,
        selectedBuilding: state.buildings.building,
        floor: state.floors.floor,
        homeZone: state.user.homeZone,
        selectedCategory: state.resource.selectedCategory,
    }
}

const mapDispatchToProps = {
    setSelectedDate: setSelectedDate,
    setCategory: setSelectedResourceCategory,
    setTimeperiod: setTimePeriodForAssignment,
    getHomezone: getHomezone,
    getBookableFreeResources: getBookableFreeResources,
    setSelectedFloor: setFloor,
    setSelectedBuilding: setBuilding,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceBookingDetailsPage)