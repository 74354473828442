import React from 'react';
import {useHistory} from "react-router-dom";

import {makeStyles, useTheme} from "@material-ui/core";
import {connect} from "react-redux";
import {thirdPartyExchangeUserLogin} from "../../actions/thirdPartyAuthentication-actions.js";
import {navigate, PATH_PROFILE_INTEGRATIONS} from "../../common/utils/NavigationUtils.js";
import {useTranslation} from "react-i18next";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import {useFormik} from "formik";
import CustomTextField from "../CustomTextField.js";
import {isEmpty} from "lodash";
import CustomButton from "../CustomButton.js";
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    }
}));

function ConnectExchangePage({
                                 loginExchange,
                                 ...props
                             }) {

    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme)
    const {t} = useTranslation()

    const formik = useFormik({
        validate: (values) => {
            const errors = {};

            if (!values.server.startsWith("https://")) {
                errors.server = t('integrations.exchange.server.error')
            }

            return errors
        },
        onSubmit: (values) => {
            loginExchange(values.server, values.email, values.pass)
                .then(res => {
                    navigate(history, PATH_PROFILE_INTEGRATIONS)
                })
        },
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: {
            server: '',
            email: '',
            password: '',
        }
    })


    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton title={t("integrations.exchange.headline")}
                                          backLabel={t("integrations")}
                                          onNavigateBack={() => navigate(history, PATH_PROFILE_INTEGRATIONS)}/>

            <div className={classes.content}>

                <Typography variant={"body1"} align={'left'}>
                    {t("integrations.exchange.text")}
                </Typography>

                <form id={'form'} onSubmit={formik.handleSubmit} className={classes.form}>
                    <CustomTextField name={'server'} title={t('integrations.exchange.server.url')}
                                     placeholder={'https://www...'}
                                     autoComplete={false}
                                     value={formik.values.server}
                                     error={!!formik.errors.server}
                                     onChange={formik.handleChange}
                    />

                    <CustomTextField name={'email'} title={t('integrations.exchange.email')}
                                     autoComplete={false}
                                     value={formik.values.email}
                                     onChange={formik.handleChange}
                    />

                    <CustomTextField name={'pass'} title={t('integrations.exchange.pass')}
                                     autoComplete={false}
                                     type={'password'}
                                     value={formik.values.pass}
                                     onChange={formik.handleChange}
                    />
                    <CustomButton disabled={!isEmpty(formik.errors)} primary fullWidth type={'submit'}
                                  text={t('integrations.exchange.confirm')}
                    />
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {
    loginExchange: thirdPartyExchangeUserLogin,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectExchangePage)