import * as React from "react"

function CrossCircledSvgIcon(props) {
    return (
        <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                stroke={props.stroke || '#F74E44'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <rect width={18} height={18} x={6} y={6} rx={9}/>
                <path d="M18.587 11.413l-7.174 7.174M18.587 18.587l-7.174-7.174"/>
            </g>
        </svg>
    )
}

export default CrossCircledSvgIcon
