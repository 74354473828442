import {isEqual} from "lodash";

const initialState = {
    favoredWorkplace: null,
    assignments: [],
    workplaces: [],
    workplace: null,
    selectedActivity: null,
    unratedAssignments: [],
    getAssignmentsPending: false,
    assignWorkplaceToPersonPending: false,
    workplaceAssignments: [],
}

function workplaceReducer(state = initialState, action) {
    let assignments;

    switch (action.type) {
        case 'CREATE_WORKPLACES_FULFILLED':
            return {
                ...state,
                workplaces: [...state.workplaces, ...action.payload.data],
            }

        case 'DELETE_WORKPLACES_FULFILLED': {
            let workplaces = [...state.workplaces]

            if (action.payload.data) {
                for (let deletedWorkplace of action.payload.data) {
                    workplaces = workplaces.filter(w => w.id !== deletedWorkplace.id)
                }
            }

            return {
                ...state,
                workplaces: workplaces,
                workplace: null,
            }
        }

        case 'SET_FAVORED_WORKPLACE':
            return {
                ...state,
                favoredWorkplace: action.payload
            }

        case 'SET_BUILDING':
            if (!action.meta.reset)
                return {...state}

            return {
                ...state,
                favoredWorkplace: null
            }

        case 'GET_FLOOR_BY_WORKPLACE_ID_FULFILLED': {
            let selectedWorkplace
            action.payload.data.spaces.forEach(space => {
                space.workplaces.forEach(workplace => {
                    if (Number(workplace.id) === Number(action.meta.workplaceId)) {
                        selectedWorkplace = workplace
                    }
                })
            })

            return {
                ...state,
                favoredWorkplace: selectedWorkplace
            }
        }

        case 'SET_FLOOR':
            return {
                ...state,
                favoredWorkplace: null
            }

        case 'SET_ASSIGNMENTS':
            return {
                ...state,
                assignments: action.payload
            }

        case 'LOAD_ASSIGNMENTS_OF_WORKPLACE_PENDING':
            return {
                ...state,
                workplaceAssignments: [],
                getAssignmentsPending: true,
            }

        case 'LOAD_ASSIGNMENTS_OF_WORKPLACE_FULFILLED':
            return {
                ...state,
                workplaceAssignments: action.payload.data,
                getAssignmentsPending: false,
            }

        case 'GET_COWORKERS_ASSIGNMENTS_AT_DATE_FULFILLED':
            return {
                ...state,
                assignments: action.payload.data.assignments,
            }

        case 'ASSIGN_WORKPLACE_TO_PERSON_PENDING':
            return {
                ...state,
                assignWorkplaceToPersonPending: true,
            }

        case 'ASSIGN_WORKPLACE_TO_PERSON_FULFILLED':
            assignments = [...state.assignments]
            assignments.push(action.payload.data)

            return {
                ...state,
                assignments: assignments,
                assignWorkplaceToPersonPending: false,
            }

        case 'ASSIGN_WORKPLACE_TO_PERSON_REJECTED':
            return {
                ...state,
                assignWorkplaceToPersonPending: false,
            }

        case 'UPDATE_WORKPLACE_ASSIGNMENT_FULFILLED':
            assignments = [...state.assignments]
            assignments.filter(assign => assign.id !== action.payload.data.id)
            assignments.push(action.payload.data)

            return {
                ...state,
                assignments: assignments,
            }


        case 'DELETE_WORKPLACE_ASSIGNMENTS_AT_DATE_FULFILLED':
        case 'DELETE_WORKPLACE_ASSIGNMENT_FULFILLED': {
            let deletedAssignments = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data]
            deletedAssignments = deletedAssignments.map(a => a.id)
            let newAssignments = state.assignments
            newAssignments = newAssignments.filter(assignment =>
                !deletedAssignments.includes(assignment.id))
            return {
                ...state,
                assignments: [...newAssignments],
                workplaceAssignments: state.workplaceAssignments.filter(assign => !deletedAssignments.includes(assign.id))
            }
        }

        case 'GET_WORKPLACES_FULFILLED':
            return {
                ...state,
                workplaces: action.payload.data
            }

        case 'GET_WORKPLACE_FULFILLED':
            return {
                ...state,
                favoredWorkplace: action.payload.data
            }

        case 'SET_SELECTED_ACTIVITY':

            return {
                ...state,
                selectedActivity: action.payload
            }

        case 'GET_UNRATED_ASSIGNMENTS_FROM_PERSON_FULFILLED':
            return {
                ...state,
                unratedAssignments: action.payload.data
            }

        case 'RATE_WORKPLACE_FULFILLED':
            assignments = [...state.unratedAssignments]

            return {
                ...state,
                unratedAssignments: assignments.filter((assign) => !isEqual(assign.id, action.payload.data.id))
            }

        default:
            return state
    }
}


export default workplaceReducer