import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles, Typography} from "@material-ui/core";
import DashboardTimeSelection from "../admin/Dashboard/DashboardTimeSelection.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import UserDistributionPieChart from "./UserDistributionPieChart.js";
import UserMeetingPieChart from "./UserMeetingPieChart";
import useTheme from "@material-ui/core/styles/useTheme.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        gap: '1.8rem',
        boxSizing: 'border-box',
        padding: theme.paddingContentContainer.padding,
    },
    statistics: {
        flexDirection: 'column',
        gap: '1.8rem',
        display: 'inline-grid'
    },
    split: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%',
        gap: '1.8rem',
        justifyContent: 'space-between',
    },
    pieWrapper: {
        flex: '1 1 0px',
        minWidth: '327px'

    }
}))

function UserStatisticsPage() {

    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation();
    const history = useHistory()

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    function handleTimeSelection(startDate, endDate) {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    function routeToBefore() {
        history.goBack()
    }

    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton backLabel={t('profile')}
                                          onNavigateBack={routeToBefore}/>
            <div className={classes.statistics}>
                <DashboardTimeSelection onTimeSelect={(startDate, endDate) => handleTimeSelection(startDate, endDate)}/>

                <div className={classes.split}>
                    <div className={classes.pieWrapper}>
                        <UserDistributionPieChart startDate={startDate}
                                                  endDate={endDate}/>
                        <Typography variant={'caption'} align={'left'}>
                            {t('days_homeoffice_info')}
                        </Typography>
                    </div>
                    <UserMeetingPieChart startDate={startDate} endDate={endDate}/>
                </div>
            </div>
        </div>
    )
}

export default UserStatisticsPage