import * as React from "react"

function CalendarExportSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={'100%'}
            height={'100%'}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke={props.stroke || '#333'}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path d="M14.75 31.5H9v-22h22v6m0 0H9h22zM15 6.5v6M25 6.5v6"/>
                <path d="M20 26l5-5 5 5m-5-5v13"/>
            </g>
        </svg>
    )
}

export default CalendarExportSvgIcon