import * as React from "react"

function NotificationSvgIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g stroke="#333" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinejoin="round">
                <path
                    d="M22.046 9.313c-4.616-1.146-9.349 1.904-10.281 5.62l-.036.142c-.65 2.612-2.082 5.07-4.252 7.308a3.79 3.79 0 0 0-.976 1.736c-.117.468.26.964.84 1.11l20.213 5.039c.581.145 1.147-.117 1.263-.585a3.785 3.785 0 0 0-.046-1.99c-.865-2.99-.972-5.83-.321-8.44l.037-.151c.922-3.719-1.826-8.635-6.44-9.79zM9.578 28.343a1.062 1.062 0 0 0-1.295.762 2.938 2.938 0 0 0 .838 2.84 3.046 3.046 0 0 0 2.911.725 2.938 2.938 0 0 0 2.073-2.113 1.062 1.062 0 0 0-.785-1.28l-3.742-.934zM23.89 3.916l-.967 3.882"/>
            </g>
        </svg>
    )
}

export default NotificationSvgIcon