import * as React from "react"
import PropTypes from 'prop-types'

function VacationSvgIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M19.857 18.215c-4.34-4.3-11.375-4.3-15.714 0"
                stroke={props.stroke}
                fill="none"
                strokeMiterlimit={10}
            />
            <path
                d="M11.877 15.097a16.377 16.377 0 0 0-3.934-8.783M12.5 4.547a4.468 4.468 0 0 0-4.697 1.597M7.734 6.034a4.381 4.381 0 0 0-1.406 5.138"
                stroke={props.stroke}
                fill="none"
                strokeMiterlimit={10}
            />
            <path
                d="M8.203 6.098c-1.936-.181-3.835.92-4.566 2.808"
                stroke={props.stroke}
                fill="none"
                strokeMiterlimit={10}
            />
            <path
                d="M8.374 6.847a4.47 4.47 0 0 0-5.133-1.572"
                stroke={props.stroke}
                fill="none"
                strokeMiterlimit={10}
            />
            <path
                d="M9.728 2c-1.723.893-2.71 2.842-2.286 4.819"
                stroke={props.stroke}
                fill="none"
                strokeMiterlimit={10}
            />
            <path
                d="M16.45 7.412c1.72.66 2.583 2.575 1.929 4.278l-6.224-2.389c.654-1.703 2.577-2.549 4.296-1.889ZM15.267 10.496l-1.858 4.84M16.845 6.384l-.366.955"
                stroke={props.stroke}
                strokeWidth={1.111}
                fill="none"
                strokeMiterlimit={10}
            />
            <path
                d="M2 19.316c1.667 0 1.667 1.1 3.334 1.1 1.666 0 1.666-1.1 3.333-1.1 1.666 0 1.666 1.1 3.333 1.1 1.666 0 1.666-1.1 3.333-1.1 1.667 0 1.667 1.1 3.334 1.1 1.666 0 1.666-1.1 3.333-1.1"
                stroke={props.stroke}
                fill="none"
                strokeMiterlimit={10}
            />
        </svg>
    )
}

VacationSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    stroke: PropTypes.string,
}

VacationSvgIcon.defaultProps = {
    width: 24,
    height: 24,
    stroke: '#333',
}


export default VacationSvgIcon
