import {DrawWorkplace} from "../../components/drawingTool/drawing/DrawWorkplace.js";
import * as THREE from "three";
import {SpaceObject} from "../../components/threejs/drawingObjects/SpaceObject.js";
import moment from "moment";
import {getTimeAtDate} from "./TimeUtils";
import {DrawTerminal} from "../../components/drawingTool/drawing/DrawTerminal.js";
import {DrawCurrentHereMarker} from "../../components/drawingTool/drawing/DrawCurrentHereMarker.js";

export function getWorkplaceAsDrawingObject(workplace, imageScale) {
    const workplaceObject = new DrawWorkplace()
    const assignments = workplace.assignments[0].id ? workplace.assignments : null
    workplaceObject.setFromWorkplace(workplace.assignments[0].workplace, imageScale, assignments, workplace.image)
    workplaceObject.children[0].applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2))
    workplaceObject.position.y = -workplaceObject.position.clone().z
    workplaceObject.position.z = 1
    return workplaceObject
}

export function getSpaceAsDrawingObject(space, active) {
    let geometry = space.outline
    geometry.space = space

    const threeObject = new SpaceObject()

    threeObject.initialize(space)

    threeObject.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2))

    if (active)
        threeObject.setActive(true)

    return threeObject
}

export function getTerminalAsDrawingObject(terminal) {
    let geometry = terminal.geometry
    geometry.terminal = terminal

    const threeObject = new DrawTerminal()
    threeObject.setFromTerminal(terminal)
    threeObject.children[0].applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2))
    threeObject.position.y = -threeObject.position.clone().z
    threeObject.position.z = 1

    return threeObject
}

export function getCurrentHereMarker(position, text) {
    const threeObject = new DrawCurrentHereMarker()
    threeObject.init(position, text)
    threeObject.children[0].applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2))
    threeObject.position.y = -threeObject.position.clone().y

    return threeObject
}

export function getFaceCentroidMatrix(faceGeometry, faceMesh) {
    faceGeometry.computeBoundingBox();

    var centroid = new THREE.Vector3();
    centroid.addVectors(faceGeometry.boundingBox.min, faceGeometry.boundingBox.max);
    centroid.multiplyScalar(0.5);

    return centroid.applyMatrix4(faceMesh.matrixWorld);
}

export function getDegreeOfTimeInCircle(timeAt0Deg, timeAt360Deg, time, selectedDate) {
    if (!time || !timeAt0Deg || !timeAt360Deg) {
        return 0
    }

    const requiredTime = moment(time)

    const start = getTimeAtDate(timeAt0Deg, selectedDate ?? requiredTime)
    start.set({second: 0, millisecond: 0})

    if (!requiredTime.isAfter(start)) {
        return 0
    }

    const end = getTimeAtDate(timeAt360Deg, selectedDate ?? requiredTime)
    end.set({second: 0, millisecond: 0})

    if (!requiredTime.isBefore(end)) {
        return 360
    }

    const durationWholeCircleAsMinutes = moment.duration(end.diff(start)).asMinutes();
    const durationStartToTimeAsMinutes = moment.duration(requiredTime.diff(moment(start))).asMinutes();

    return Math.round(durationStartToTimeAsMinutes / durationWholeCircleAsMinutes * 360 * 10) / 10
}

export function getRadiansOfTimeInCircle(timeAt0Deg, timeAt360Deg, time) {
    const radiansFactor = 2 * Math.PI / 360
    let degree = getDegreeOfTimeInCircle(timeAt0Deg, timeAt360Deg, time)
    return radiansFactor * (-degree + 90)
}
