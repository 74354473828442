import React from 'react';
import {makeStyles, useTheme} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import XSvgIcon from "../../common/icons/XSvgIcon";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import ModalHandler from "./ModalHandler.js";


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        '& .MuiDialog-paper': {
            height: '70%',
            margin: 0,
            boxSizing: 'border-box',
            borderRadius: '18px 18px 0 0',
            width: '100%',
            maxWidth: theme.gridSizes.maxWidth,
            bottom: 0,
            position: 'absolute',
        },
    },
    headerActions: {
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '18px 18px 12px 18px',
    },
    scrollContainer: {
        overflowY: 'auto',
        height: '100%',
        boxSizing: 'border-box',
        padding: '10px 18px 0px 18px',
        '& > div:last-child': {
            boxSizing: 'border-box',
        }
    },
    exitIcon: {
        cursor: 'pointer',
        width: '28px',
        height: '28px',
    },
}));

function ModalWrapper({
                          onClose,
                          children,
                          open,
                          headerActions,
                          ...props
                      }) {

    const theme = useTheme()
    const classes = useStyle(theme);

    if (!open)
        return null

    return (
        <ModalHandler open={open} onClose={onClose}>
            <Dialog open={open}
                    className={classes.root}
                    onClose={onClose}>
                <div className={classes.headerActions}>
                    <XSvgIcon className={classes.exitIcon}
                              onClick={onClose}/>
                    {headerActions && React.cloneElement(headerActions, {...props})}
                </div>

                <Divider style={{margin: '0 1.8rem'}}/>

                <div className={classes.scrollContainer}>
                    {children && React.cloneElement(children, {onClose: onClose, ...props})}
                </div>
            </Dialog>
        </ModalHandler>
    )
}

ModalWrapper.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.any.isRequired,
    headerActions: PropTypes.any
}

export default ModalWrapper
