// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import {setSubscription} from "./actions/user-action";
import {PATH_TERMINAL} from "./common/utils/NavigationUtils.js";

// eslint-disable-next-line no-unused-expressions


// function base64ToUint8(base64) {
//     let binary_string = window.atob(base64);
//     let len = binary_string.length;
//     let bytes = new Uint8Array(len);
//     for (let i = 0; i < len; i++) {
//         bytes[i] = binary_string.charCodeAt(i);
//     }
//     return bytes.buffer;
// }

export const VAPID_KEY = 'BKLIpSqzOcHOHRROTPauxAfa3Esrz8sfQ4uk6FjInXS_0OM9z7-xNhVKrRXV_pepNqDqdrwsDIA-TQ7_USEyd1Y'

export function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        if (window.location.pathname === PATH_TERMINAL) {
            //disable serviceworker for terminals
            return;
        }


        // Adds a listener that, when called, forces the new service worker to stop waiting for
        // older service worker versions to finish (e.g. respective tabs closed).
        // By this way the new service worker enters the active status and is serving the updated app to the user.
        // IMPORTANT: comment "/* eslint-disable-next-line no-restricted-globals */" is necessary for this to work
        window.self.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'SKIP_WAITING') {
                /* eslint-disable-next-line no-restricted-globals */
                self.skipWaiting();
            }
        });

        window.self.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        'This web app is being served cache-first by a service ' +
                        'worker. To learn more, visit https://bit.ly/CRA-PWA'
                    );
                });
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config);
            }
        });
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            if (navigator.vendor === 'Apple Computer, Inc.') {
                console.log("Apple device");
                if (registration.waiting) {
                    if (config && config.onUpdate) {
                        config.onUpdate(registration);
                    }
                }
            }
            registration.onupdatefound = () => {
                console.log('Serviceworker update found.')

                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    console.log('Serviceworker state changed.')

                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed
                            console.log(
                                'New content is available and will be used when all ' +
                                'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                            );

                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log('Content is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: {'Service-Worker': 'script'},
    })
        .then(response => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister();
            })
            .catch(error => {
                console.error(error.message);
            });
    }
}

//create subscription object and send it to the server
function createNotificationSubscription(personId) {
    //wait for service worker installation to be ready
    navigator.serviceWorker.ready.then((registration) => {
        // subscribe and return the subscription
        registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(VAPID_KEY)
        }).then((subscription) => {
            let sub = {
                endpoint: subscription.endpoint,
                auth: subscription.toJSON().keys.auth.toString(),
                p256dh: subscription.toJSON().keys.p256dh.toString()
            }
            setSubscription(personId, sub)
        })
    })
}

//This function doesnt work in this service worker. Its implemented in public/custom-sw.js
// eslint-disable-next-line no-restricted-globals
/*
self.addEventListener('push', function(event) {
    console.log('[Service Worker] Push Received.');
    console.log(`[Service Worker] Push had this data: "${event.data.json()}"`);

    const title = event.data.json().title;
    const options = {
        body: event.data.json().body,
    };

    // eslint-disable-next-line no-restricted-globals
    event.waitUntil(self.registration.showNotification(title, options));
});
*/

//Listens for switch in profile component
// eslint-disable-next-line no-restricted-globals
self.addEventListener('notification', function (e) {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            createNotificationSubscription(e.detail)
            console.log("Permission for Notifications granted")
        } else {
            console.log("Permission for Notifications declined")
        }
    })
})






