export const tabsNames = {
    SELECT: 'select',
    SCALE: 'scale',
    OUTLINE: 'outline',
    WALLS: 'walls',
    DOORS: 'doors',
    PILLARS: 'pillars',
    SPACES: 'spaces',
    WORKPLACES: 'seats',
    TERMINAL: 'terminals',
}

export const tools = {
    SELECT: 'SELECT',
    SCALE: 'SCALE',
    OUTLINE: 'OUTLINE',
    WALL: 'WALL',
    DOOR: 'DOOR',
    PILLAR: 'PILLAR',
    SPACE: 'SPACE',
    WORKPLACE: 'WORKPLACE',
    TERMINAL: 'TERMINAL',
}

export const toolsTree = [
    {
        tab: tabsNames.SELECT,
        tools: [
            tools.SELECT
        ],
    },
    {
        tab: tabsNames.SCALE,
        tools: [
            tools.SCALE
        ],
    },
    {
        tab: tabsNames.OUTLINE,
        tools: [
            tools.OUTLINE
        ],
    },
    {
        tab: tabsNames.WALLS,
        tools: [
            tools.WALL
        ],
    },
    {
        tab: tabsNames.DOORS,
        tools: [
            tools.DOOR
        ],
    },
    {
        tab: tabsNames.PILLARS,
        tools: [
            tools.PILLAR
        ],
    },
    {
        tab: tabsNames.SPACES,
        tools: [
            tools.SPACE
        ],
    },
    {
        tab: tabsNames.WORKPLACES,
        tools: [
            tools.WORKPLACE
        ],
    },
    {
        tab: tabsNames.TERMINAL,
        tools: [
            tools.TERMINAL
        ],
    },
]