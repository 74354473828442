import React, {useEffect, useRef, useState} from 'react';

import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import StopSvgIcon from "../icons/StopSvgIcon.js";
import StartSvgIcon from "../icons/StartSvgIcon.js";
import PauseSvgIcon from "../icons/PauseSvgIcon.js";
import BreakSvgIcon from "../icons/BreakSvgIcon.js";
import RoundedIconWrapper from "./RoundedIconWrapper.js";

import {navigate, PATH_USER_TIMETRACKINGS} from "../utils/NavigationUtils.js";
import {getTrackingsBreakHoursAsString, getWorkedHoursAsString, isClockedOut, isOnBreak} from "../utils/TimeUtils";
import moment from "moment-timezone";
import LoadingIndicator from "./LoadingIndicator";
import {clockBreak, clockUserIn, clockUserOut} from "../../actions/timetracking-actions.js";

const useStyle = props => makeStyles(theme => ({
    root: {
        width: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'start'
    },
    flex: {
        display: 'flex',
        gap: '1.2rem'
    },
    flexNoGap: {
        display: 'flex',
    },
    loadingIndicator: {
        paddingRight: theme.outerGap
    }
}))

function TimeTrackingInfoAndActions({
                                        className,
                                        person,
                                        userClockState,
                                        clockUserIn,
                                        clockUserOut,
                                        clockBreak,
                                        clockInPending,
                                        usersTodaysTimeTrackings,
                                        withoutHistory,
                                        ...props
                                    }) {
    const {t} = useTranslation()
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyle(props)(theme);
    const [workingTime, setWorkingTime] = useState(null);
    const [breakTime, setBreakTime] = useState(null);
    const timeTrackingsRef = useRef(usersTodaysTimeTrackings)

    useEffect(() => {
        setWorkingTime(getWorkedHoursAsString(usersTodaysTimeTrackings, true))
        setBreakTime(getTrackingsBreakHoursAsString(usersTodaysTimeTrackings, true))
        timeTrackingsRef.current = usersTodaysTimeTrackings
    }, [usersTodaysTimeTrackings]);

    useEffect(() => {
        let interval
        if (!userClockState)
            return

        if (isClockedOut(userClockState)) {
            clearInterval(interval)
        } else if (!interval) {
            interval = setInterval(() => {
                if (isOnBreak(userClockState)) {
                    setBreakTime(getTrackingsBreakHoursAsString(timeTrackingsRef.current, true))
                } else {
                    setWorkingTime(getWorkedHoursAsString(timeTrackingsRef.current, true))
                }
            }, 60 * 1000);
        }
        return () => {
            clearInterval(interval)
        }
        //eslint-disable-next-line
    }, [userClockState]);

    function routeToTimeTrackingOverview() {
        if (withoutHistory)
            return
        navigate(history, PATH_USER_TIMETRACKINGS + '?shortcut=true')
    }

    return (
        <div onClick={routeToTimeTrackingOverview} className={classes.root}>
            <div className={classes.content}>
                <div className={classes.info}>
                    <Typography variant={isOnBreak(userClockState) ? 'caption' : 'h5'}>
                        {isClockedOut(userClockState) && usersTodaysTimeTrackings.length === 0 ? t('tap_to_start') : workingTime}
                    </Typography>
                    <div className={classes.flexNoGap}>
                        {usersTodaysTimeTrackings.length !== 0 &&
                            <BreakSvgIcon height={isOnBreak(userClockState) ? 24 : 18}
                                          color={isOnBreak(userClockState) ? theme.colors.palette.neutral.darkMain : theme.colors.palette.neutral.greyMain}/>}
                        <Typography variant={isOnBreak(userClockState) ? 'h5' : 'caption'} align={'center'}>
                            {isClockedOut(userClockState) && usersTodaysTimeTrackings.length === 0
                                ? withoutHistory
                                    ? ''
                                    : t('press_history')
                                : breakTime}
                        </Typography>
                    </div>
                </div>
                {isClockedOut(userClockState)
                    ? clockInPending
                        ? <LoadingIndicator className={classes.loadingIndicator}/>
                        : <RoundedIconWrapper icon={<StartSvgIcon/>}
                                              backgroundColor={theme.colors.palette.corporate.skinLight}
                                              size={44}
                                              onClickCallback={(event) => {
                                                  event.stopPropagation()
                                                  clockUserIn(person.id, moment.tz.guess())
                                              }}
                                              iconColor={theme.colors.palette.corporate.skinMain}/>
                    : <div className={classes.flex}>
                        <RoundedIconWrapper icon={isOnBreak(userClockState) ? <StartSvgIcon/> : <PauseSvgIcon/>}
                                            size={44}
                                            backgroundColor={isOnBreak(userClockState) ? theme.colors.palette.corporate.skinMain : theme.colors.palette.corporate.skinLight}
                                            iconColor={isOnBreak(userClockState) ? theme.colors.palette.neutral.white : theme.colors.palette.corporate.skinMain}
                                            onClickCallback={(event) => {
                                                event.stopPropagation()
                                                clockBreak(person.id, !isOnBreak(userClockState))
                                            }}/>
                        <RoundedIconWrapper icon={<StopSvgIcon/>}
                                            size={44}
                                            backgroundColor={isOnBreak(userClockState) ? theme.colors.palette.corporate.skinLight : theme.colors.palette.corporate.skinMain}
                                            iconColor={isOnBreak(userClockState) ? theme.colors.palette.corporate.skinMain : theme.colors.palette.neutral.white}
                                            onClickCallback={(event) => {
                                                event.stopPropagation()
                                                clockUserOut(person.id)
                                            }}/>
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        person: state.user.person,
        userClockState: state.user.clockState,
        usersTodaysTimeTrackings: state.user.todaysTimeTrackings,
        clockInPending: state.user.clockInPending,
    }
};

const mapDispatchToProps = {
    clockUserIn: clockUserIn,
    clockUserOut: clockUserOut,
    clockBreak: clockBreak,
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingInfoAndActions)
