import React, {useEffect, useState} from 'react';

import hash from "object-hash";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";
import {useParams} from "react-router-dom";
import getUuidByString from "uuid-by-string";
import {useTranslation} from "react-i18next";

import Grid from '@material-ui/core/Grid'
import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles, Typography} from "@material-ui/core";

import MeetingPopUp from "../meetings/MeetingModal.js";
import AssignmentCard from "../assignments/AssignmentCard.js";
import VacationInformationModal from "./VacationInformationModal.js";
import LoadingIndicator from "../../common/elements/LoadingIndicator";
import useDidMountEffect from "../../common/customHooks/useDidMountEffect.js";
import BookingsEmptyStateSvg from "../../common/icons/BookingsEmptyStateSvg.js";

import {selectFavoriteWorkplace, setAssignments} from "../../actions/workplaceAssignment-action";
import {deleteMeetingRoomAssignment, deleteWorkplaceAssignment, getAssignmentsOfUser,} from "../../actions/user-action";

import {isToday, sortAssignments} from "../../common/utils/TimeUtils";
import {
    TYPE_OF_BOOKING_MEETING_ROOM,
    TYPE_OF_BOOKING_RESOURCE,
    TYPE_OF_BOOKING_VACATION,
    TYPES_OF_REMOTE
} from "../../common/utils/NameUtils.js";
import WorkplaceBookingModal from "./WorkplaceBookingModal.js";
import {deleteResourceAssignment} from "../../actions/resource-actions.js";
import ResourceInformationModal from "./ResourceInformationModal.js";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.outerGap,
        justifyContent: 'space-around',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2rem'
    },

    subHeading: {
        textAlign: 'start',
    },
    cardsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap
    },

    emptyState: {
        margin: '0 auto',
    },
    marginBottom: {
        marginBottom: '1rem',
    }
}))

function Bookings({
                      assignments, userId,
                      loadAssignments,
                      assignmentsPending,
                      deleteWorkplaceAssignment,
                      deleteMeetingRoomAssignment,
                      deleteResourceAssignment,
                      selectFavoriteWorkplace,
                      setAssignments,
                  }) {
    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme);
    const {meetingId} = useParams()

    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [meetingModalOpen, setMeetingModalOpen] = useState(false);
    const [workplaceBookingModalOpen, setWorkplaceBookingModalOpen] = useState(false);
    const [vacationModalOpen, setVacationModalOpen] = useState(false);
    const [resourceModalOpen, setResourceModalOpen] = useState(false);

    const todaysAssignments = assignments.filter((assignment) => {
        return isToday(assignment.timePeriod.startDate)
    });

    const upcomingAssignments = sortAssignments(assignments
        .filter((assignment) => {
            return !isToday(assignment.timePeriod.startDate)
        }))

    useEffect(() => {
        if (userId && isEmpty(assignments))
            loadAssignments(userId, false, true)
                .catch(error => {
                    console.log(error)
                })
        //eslint-disable-next-line
    }, [userId])

    useDidMountEffect(() => {
        if (meetingId) {
            let urlMeeting = assignments.find(assignment => isEqual(assignment.id.toString(), meetingId.toString()))
            if (urlMeeting) {
                onSelectAssignment(urlMeeting)
            }
        } else if (selectedAssignment) {
            let changedAssignment = assignments.find(a => a.id === selectedAssignment.id)
            if (changedAssignment)
                setSelectedAssignment(changedAssignment)
        }
        //eslint-disable-next-line
    }, [meetingId, assignments])

    function onDeleteWorkplaceAssignment(assignmentId) {
        deleteWorkplaceAssignment(userId, assignmentId)
            .catch(error => {
                console.log(error)
            })
    }

    function onDeleteResourceAssignment(assignmentId) {
        deleteResourceAssignment(userId, assignmentId)
            .catch(error => {
                console.log(error)
            })
    }

    function onDeleteMeetingRoomAssignment(assignmentId) {
        deleteMeetingRoomAssignment(userId, assignmentId)
            .catch(error => {
                console.log(error)
            })
    }

    function onSelectAssignment(assignment) {
        setSelectedAssignment(assignment)
        if (TYPES_OF_REMOTE.includes(assignment.typeOfWorkplaceBooking)) {
            setWorkplaceBookingModalOpen(true)
        } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_MEETING_ROOM) {
            setMeetingModalOpen(true)
        } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_VACATION) {
            setVacationModalOpen(true)
        } else if (assignment.typeOfWorkplaceBooking === TYPE_OF_BOOKING_RESOURCE) {
            setResourceModalOpen(true)
        } else {
            selectFavoriteWorkplace(assignment.workplace)
            setAssignments([assignment])
            setWorkplaceBookingModalOpen(true)
        }
    }

    return (
        <Grid item sm={12} className={classes.root}>
            {assignmentsPending ?
                <LoadingIndicator/> :
                <div className={classes.content}>

                    {!assignments.length ?
                        <div className={classes.emptyState}>
                            <BookingsEmptyStateSvg className={classes.marginBottom}/>
                            <Typography variant={'h5'} className={classes.marginBottom}>
                                {t("no_workplace_booked")}
                            </Typography>
                            <Typography className={classes.marginBottom}>
                                {t("niceDay")}
                            </Typography>
                        </div> :

                        <div className={classes.content}>
                            {!isEmpty(todaysAssignments) &&
                                <div className={classes.content}>
                                    <Typography id={'#bookings'} className={classes.subHeading} variant={'h5'}>
                                        {t("bookings_today")}
                                    </Typography>

                                    <Grid container className={classes.cardsContainer}>
                                        {sortAssignments(todaysAssignments).map(assignment =>
                                            <AssignmentCard assignment={assignment}
                                                            key={hash(assignment.person) + ' ' + hash(assignment.timePeriod) + ' ' + getUuidByString(assignment.title + ' ')}
                                                            userId={userId}
                                                            active
                                                            onSelectAssignment={() => onSelectAssignment(assignment)}
                                            />
                                        )}
                                    </Grid>
                                </div>
                            }

                            {!isEmpty(upcomingAssignments) &&
                                <div className={classes.content}>

                                    <Typography className={classes.subHeading} variant={'h5'}>
                                        {t("upcoming_bookings")}
                                    </Typography>

                                    <Grid container className={classes.cardsContainer}>
                                        {upcomingAssignments.map(assignment => {
                                            return <AssignmentCard assignment={assignment}
                                                                   key={(assignment.person ? assignment.person.id : assignment.email) + ' ' + getUuidByString(assignment.title + ' ' + assignment.timePeriod.startDate)}
                                                                   userId={userId}
                                                                   onSelectAssignment={() => onSelectAssignment(assignment)}/>
                                        })}
                                    </Grid>
                                </div>
                            }

                        </div>
                    }
                </div>
            }

            {selectedAssignment && meetingModalOpen &&
                <MeetingPopUp onClose={() => setMeetingModalOpen(false)}
                              open={meetingModalOpen}
                              assignment={selectedAssignment}
                              onDelete={() => onDeleteMeetingRoomAssignment(selectedAssignment.id)}/>}

            <WorkplaceBookingModal onClose={() => {
                setWorkplaceBookingModalOpen(false)
            }}
                                   open={workplaceBookingModalOpen}
                                   assignment={selectedAssignment}
                                   allAssignments={assignments}
                                   onDelete={() => onDeleteWorkplaceAssignment(selectedAssignment.id)}/>

            {selectedAssignment && vacationModalOpen &&
                <VacationInformationModal onClose={() => setVacationModalOpen(false)}
                                          open={vacationModalOpen}
                                          assignment={selectedAssignment}
                                          onDelete={() => onDeleteWorkplaceAssignment(selectedAssignment.id)}/>}
            {selectedAssignment && resourceModalOpen &&
                <ResourceInformationModal onClose={() => setResourceModalOpen(false)}
                                          open={resourceModalOpen}
                                          assignment={selectedAssignment}
                                          onDelete={() => onDeleteResourceAssignment(selectedAssignment.id)}/>}
        </Grid>
    )
}


const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        assignments: state.user.userAssignments,
        assignmentsPending: state.user.getAssignmentsPending,
    }
}


const mapDispatchToProps = {
    setAssignments: setAssignments,
    loadAssignments: getAssignmentsOfUser,
    selectFavoriteWorkplace: selectFavoriteWorkplace,
    deleteWorkplaceAssignment: deleteWorkplaceAssignment,
    deleteMeetingRoomAssignment: deleteMeetingRoomAssignment,
    deleteResourceAssignment: deleteResourceAssignment,
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings)