import React, {Component} from 'react';
import {CardContent, Typography, withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import CardWrapper from "./CardWrapper.js";

const styles = theme => ({
    card: {
        minWidth: '130px',
        width: '100%',
        padding: theme.innerGap
    },
    cardContent: {
        padding: '0 !important',
        textAlign: 'start',
    },
    icon: {
        height: '30px',
        width: '30px',
        display: 'flex',
        alignSelf: 'flex-start'
    },
    flexAlignment: {
        display: 'flex',
        gap: '1.2rem',
        maxHeight: '72px'
    }
})

class CardComponent extends Component {
    onClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!this.props.isDisabled)
            this.props.onClickCallback()
    }

    render() {
        const {
            classes,
            icon,
            boldText,
            text,
            fullWidth,
            halfWidth,
            isSelected,
            isDisabled,
            isFlex
        } = this.props;

        return (
            <CardWrapper fullWidth={fullWidth}
                         halfWidth={halfWidth}
                         onClick={this.onClick}
                         isDisabled={isDisabled}
                         isSelected={isSelected}
                         className={classes.card}>

                <CardContent className={`${classes.cardContent} ${isFlex && classes.flexAlignment}`}>
                    {icon ?
                        <div>
                            {icon}
                        </div> : null
                    }
                    <div>
                        <Typography variant={'h5'}>{boldText}</Typography>
                        <Typography variant={'body1'}>{text}</Typography>
                    </div>
                </CardContent>
            </CardWrapper>
        )
    }
}

CardComponent.propTypes = {
    icon: PropTypes.any,
    boldText: PropTypes.string,
    text: PropTypes.string,
    fullWidth: PropTypes.bool,
    halfWidth: PropTypes.bool,
    onClickCallback: PropTypes.func,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isFlex: PropTypes.bool,
}

export default withRouter(compose(withStyles(styles), withTranslation())(CardComponent))