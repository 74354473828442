export function setCurrentDrawingTab(currentDrawingTab) {
    return {
        type: 'SET_CURRENT_DRAWING_TAB',
        payload: currentDrawingTab,
    }
}

export function setSelectedDrawingTool(tool) {
    return {
        type: 'SET_SELECTED_DRAWING_TOOL',
        selectedTool: tool,
    }
}

export function setMarkerSize(markerType, size) {
    return {
        type: 'SET_MARKER_SIZE',
        markerType: markerType,
        size: size,
    }
}

export function setFloorImage(base64Image) {
    return {
        type: 'SET_FLOORPLAN_BASE64',
        payload: base64Image,
    }
}

export function storeGeometryChanges(geometries) {
    return {
        type: 'STORE_GEOMETRY_CHANGES',
        payload: geometries,
    }
}

export function undoGeometryChange() {
    return {
        type: 'UNDO_GEOMETRY_CHANGE',
    }
}

export function redoGeometryChange() {
    return {
        type: 'REDO_GEOMETRY_CHANGE',
    }
}

export function resetGeometries() {
    return {
        type: 'RESET_GEOMETRIES',
    }
}

export function setSelectedElement(element) {
    return {
        type: 'DRAWING_SET_SELECTED_ELEMENT',
        payload: element,
    }
}