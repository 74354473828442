import React, {useState} from 'react';

import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme.js";

import CustomButton from "../CustomButton.js";
import CustomTextField from "../CustomTextField.js";
import PasswordRequirements from "./PasswordRequirements.js";
import StaticBottomSheet from "../../common/elements/StaticBottomSheet.js";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";

import {changePassword, resetPasswordIncorrect} from "../../actions/user-action.js";
import {getEndAdornment, isPasswordValid, passwordMeetsRequirements} from "../../common/utils/PasswordAndEmailUtils.js";

const useStyle = makeStyles(theme => ({
    root: {
        flex: 1,
        width: '100%',
    },
    container: {
        minHeight: `calc(100% - ${theme.staticBottomActionSheet.height})`,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        padding: theme.paddingContentContainer.padding,
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap
    },
    icon: {
        stroke: theme.colors.palette.neutral.darkMain,
        cursor: 'pointer'
    },
    error: {
        color: theme.colors.palette.corporate.redMain,
        textAlign: 'start'
    }
}))

function ChangePasswordPage({userId, changePasswordRequest, passwordIncorrect, resetPasswordIncorrect}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const history = useHistory()
    const classes = useStyle(theme);

    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

    function changePassword() {
        changePasswordRequest(userId, currentPassword, newPassword)
    }

    function submitEnabled() {
        return isPasswordValid(newPassword) && newPasswordConfirmation === newPassword && currentPassword.length
    }

    return (
        <Grid container component={Paper} className={classes.root}>
            <form style={{width: '100%'}}>
                <Grid item sm={12} className={classes.container}>
                    <HeaderWithTitleAndBackButton title={t('password')} backLabel={t("settings")}
                                                  onNavigateBack={() => history.goBack()}/>

                    <div className={classes.content}>
                        <CustomTextField title={t('current_password')} autoFocus
                                         autoComplete={false}
                                         value={currentPassword}
                                         type={showPassword ? 'text' : 'password'}
                                         onChange={e => {
                                             e.preventDefault();
                                             const {value} = e.target;
                                             if (passwordIncorrect)
                                                 resetPasswordIncorrect()
                                             setCurrentPassword(value)
                                         }}
                                         error={passwordIncorrect || currentPassword === ''}
                                         contentRight={getEndAdornment(showPassword, setShowPassword, classes.icon)}
                        />
                        <Divider/>

                        <CustomTextField title={t('new_password')}
                                         value={newPassword}
                                         autoComplete={false}
                                         type={showNewPassword ? 'text' : 'password'}
                                         onChange={e => {
                                             e.preventDefault();
                                             const {value} = e.target;
                                             setNewPassword(value)
                                         }}
                                         error={!isPasswordValid(newPassword) && newPassword !== ''}
                                         contentRight={getEndAdornment(showNewPassword, setShowNewPassword, classes.icon)}
                        />
                        <CustomTextField title={t('confirm_new_password')}
                                         autoComplete={false}
                                         value={newPasswordConfirmation}
                                         type={showNewPasswordConfirmation ? 'text' : 'password'}
                                         onChange={e => {
                                             e.preventDefault();
                                             const {value} = e.target;
                                             setNewPasswordConfirmation(value)
                                         }}
                                         error={newPasswordConfirmation !== '' && (!isPasswordValid(newPasswordConfirmation) || newPasswordConfirmation !== newPassword)}
                                         contentRight={getEndAdornment(showNewPasswordConfirmation, setShowNewPasswordConfirmation, classes.icon)}
                        />
                        {passwordMeetsRequirements(newPassword) && !isPasswordValid(newPassword) &&
                            <Typography variant={'caption'} className={classes.error}>
                                {t('invalid_character')}
                            </Typography>}
                        <PasswordRequirements password={newPassword} passwordConfirmation={newPasswordConfirmation}/>
                    </div>
                </Grid>
                <StaticBottomSheet>
                    <CustomButton text={t('change_password')}
                                  primary
                                  disabled={!submitEnabled()}
                                  onClick={changePassword}
                    />
                </StaticBottomSheet>
            </form>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        passwordIncorrect: state.user.passwordIncorrect,
    }
}

const mapDispatchToProps = {
    changePasswordRequest: changePassword,
    resetPasswordIncorrect: resetPasswordIncorrect
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);