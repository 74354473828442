import * as React from "react"

function ZoomOutFloorplanSvgIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#333"
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <rect width={24} height={24} x={8} y={8} rx={12}/>
                <path d="M26.763 20H13.236"/>
            </g>
        </svg>
    )
}

export default ZoomOutFloorplanSvgIcon
