import React from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";

import Checkbox from "@material-ui/core/Checkbox";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Zoom from "@material-ui/core/Zoom";

const useStyles = props => makeStyles(theme => ({
    root: {
        padding: '0px 2px',
    },
    icon: {
        width: 20,
        height: 20,
        borderRadius: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.colors.palette.neutral.white,
        //backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: `2px auto ${props.color || theme.colors.palette.corporate.blueDarkest}`,
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: props.color ? props.color : theme.colors.palette.neutral.darkMain,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
    },
}));

function CustomCheckbox({selected, color, disabled, onClick, className, ...props}) {

    const theme = useTheme()
    const classes = useStyles(props)(theme);

    return (

        <Checkbox
            className={clsx(classes.root, className)}
            disableRipple
            color="default"
            checkedIcon={<Zoom in={selected}><span className={clsx(classes.icon, classes.checkedIcon)}/></Zoom>}
            icon={<span className={classes.icon}/>}
            inputProps={{'aria-label': 'decorative checkbox'}}
            value={selected}
            checked={selected}
            disabled={disabled}
            onClick={onClick ? onClick : () => {
            }}
            {...props}
        />
    )
}

CustomCheckbox.propsTypes = {
    selected: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
}

CustomCheckbox.defaultProps = {
    selected: false,
    color: null,
    disabled: false,
}

export default CustomCheckbox;