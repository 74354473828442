import React from 'react';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme.js";

import VacationTable from "./VacationTable.js";


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        paddingTop: theme.outerGap,
    },
}));


function VacationOverview({vacationList}) {

    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyle(theme);

    return (
        <div className={classes.root}>
            <Typography variant={'body1'} align={'left'}>{t('vacation_description')}</Typography>

            <VacationTable vacationList={vacationList}/>
        </div>
    );
}

const mapStateToProps = state => {
    return {}
};

let mapDispatchToProps = {
    //TODO: deleteVacationAction
}

VacationOverview.propTypes = {
    vacationList: PropTypes.array,
};

VacationOverview.defaultProps = {
    vacationList: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(VacationOverview);