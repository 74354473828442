import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import {useTranslation} from "react-i18next";

import {makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

import ToolBarItem from "./ToolBarItem.js";
import SimpleModal from "../../common/elements/SimpleModal";

import DrawSelectSvgIcon from "../../common/icons/DrawSelectSvgIcon.js";
import DrawSeatsSvgIcon from "../../common/icons/DrawSeatsSvgIcon.js";
import DrawPolygonSvgIcon from "../../common/icons/DrawPolygonSvgIcon.js";
import DrawPillarSvgIcon from "../../common/icons/DrawPillarSvgIcon.js";
import DrawDoorSvgIcon from "../../common/icons/DrawDoorSvgIcon.js";
import DrawInnerWallSvgIcon from "../../common/icons/DrawInnerWallSvgIcon.js";
import DrawWallSvgIcon from "../../common/icons/DrawWallSvgIcon.js";
import DrawScaleSvgIcon from "../../common/icons/DrawScaleSvgIcon.js";

import {tabsNames} from "./drawing/drawingTabsAndTools";
import {setCurrentDrawingTab, setSelectedDrawingTool} from "../../actions/drawing-actions.js";
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '64px',
        display: 'flex',
        flexDirection: 'column',
    },
    toolBar: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid ' + theme.colors.palette.neutral.greyMedium,
        height: '100%',
        backgroundColor: theme.colors.palette.neutral.white,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)'
    },
    divider: {
        margin: '0 8px',
    }
}));

function DrawingToolsBar(props) {
    const {
        floor,
        setCurrentDrawingTab,
        setSelectedDrawingTool,
        currentStoreIndex,
        onSave,
        currentDrawingTab,
        savePopupOnCloseCallback,
        savePopupOnThirdOptionCallback,
        terminateCallback,
        handlePopupState,
        popupOpen,
    } = props

    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyle(theme);

    const [showScaleWarningPopup, setShowScaleWarningPopup] = useState(false);

    let requestedTab = useRef(null);

    useEffect(() => {
        console.log('useEffect')
        let tab = floor.scaleGeometry ? tabsNames.SELECT : tabsNames.SCALE

        async function setCurrentTabAndTool() {
            await setCurrentDrawingTab(tab);
        }

        setCurrentTabAndTool()
    }, [floor.scaleGeometry, setCurrentDrawingTab, setSelectedDrawingTool]);

    async function onSelectTab(tab) {
        if (tab === props.currentDrawingTab)
            return

        if (tab === tabsNames.SCALE) {
            setShowScaleWarningPopup(true)
        } else {
            saveAndChangeTab(tab)
        }
    }

    function saveAndChangeTab(tab) {
        if (currentStoreIndex > 0) {
            handlePopupState(true)
            requestedTab.current = tab
        } else {
            setCurrentDrawingTab(tab)
        }
    }

    async function cancelScaleTab() {
        setShowScaleWarningPopup(false)
    }

    async function setScaleTab() {
        setShowScaleWarningPopup(false)
        await setCurrentDrawingTab(tabsNames.SCALE)
    }

    if (!currentDrawingTab) {
        return <div/>
    }

    return (
        <div className={classes.root}>
            <div className={classes.toolBar}>
                <ToolBarItem toolName={tabsNames.SELECT} icon={<DrawSelectSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.SELECT}
                />

                <Divider className={classes.divider}/>

                <ToolBarItem toolName={tabsNames.SCALE} icon={<DrawScaleSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.SCALE}
                />

                <Divider className={classes.divider}/>

                <ToolBarItem toolName={tabsNames.OUTLINE} icon={<DrawWallSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.OUTLINE}
                />

                <ToolBarItem toolName={tabsNames.WALLS} icon={<DrawInnerWallSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.WALLS}
                />

                <ToolBarItem toolName={tabsNames.DOORS} icon={<DrawDoorSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.DOORS}
                />

                <ToolBarItem toolName={tabsNames.PILLARS} icon={<DrawPillarSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.PILLARS}
                />

                <Divider className={classes.divider}/>


                <ToolBarItem toolName={tabsNames.SPACES} icon={<DrawPolygonSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.SPACES}
                />

                <ToolBarItem toolName={tabsNames.WORKPLACES} icon={<DrawSeatsSvgIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.WORKPLACES}
                />

                <ToolBarItem toolName={tabsNames.TERMINAL} icon={<DesktopWindowsIcon/>} onClick={onSelectTab}
                             selected={currentDrawingTab === tabsNames.TERMINAL}
                />

            </div>

            <SimpleModal open={showScaleWarningPopup} onSubmit={setScaleTab} onClose={cancelScaleTab}
                         title={t("warning")}>
                <Typography variant={'body1'}>{t("scale_will_delete_warning")}</Typography>
            </SimpleModal>

            <SimpleModal open={popupOpen} title={t("warning")} thirdOptionText={t('discard')}
                         onSubmit={() => {
                             onSave()
                             setCurrentDrawingTab(requestedTab.current)
                             if (!requestedTab.current) {
                                 terminateCallback()
                             }
                         }}
                         onClose={() => {
                             savePopupOnCloseCallback()
                             requestedTab.current = null
                         }}
                         onThirdOption={() => {
                             savePopupOnThirdOptionCallback()
                             setCurrentDrawingTab(requestedTab.current)
                             if (!requestedTab.current) {
                                 terminateCallback()
                             }
                         }}>
                <Typography variant={'body1'}>
                    {t('unsaved_changed')}
                </Typography>
            </SimpleModal>
        </div>
    );
}

DrawingToolsBar.propTypes =
    {
        onSave: PropTypes.func.isRequired,
        openSavePopup: PropTypes.func,
        handlePopupState: PropTypes.func,
        popupOpen: PropTypes.bool,
        savePopupOnCloseCallback: PropTypes.func,
        savePopupOnThirdOptionCallback: PropTypes.func,
        terminateCallback: PropTypes.func,
    }
;

const mapStateToProps = state => {
    return {
        currentDrawingTab: state.drawing.currentDrawingTab,
        currentStoreIndex: state.drawing.currentStoreIndex,
        selectedTool: state.drawing.selectedTool,
        floor: state.floors.floor
    }
}

const mapDispatchToProps = {
    setSelectedDrawingTool: setSelectedDrawingTool,
    setCurrentDrawingTab: setCurrentDrawingTab,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawingToolsBar))

